import { Injectable } from '@angular/core';
import { ApiFeatureSelector, Filter, Pagination } from '@iot-platform/models/common';
import { I4BGrid, I4BGridData, I4BGridOptions } from '@iot-platform/models/grid-engine';
import * as fromBusinessProfilesGrids from '../reducers/business-profiles-grids.reducer';

@Injectable({
  providedIn: 'root'
})
export class AdminBusinessProfilesGridsSelectors extends ApiFeatureSelector<
  fromBusinessProfilesGrids.State,
  I4BGrid<I4BGridOptions, I4BGridData>,
  Pagination,
  Filter
> {
  constructor() {
    super(fromBusinessProfilesGrids.adminBusinessProfilesGridsFeature.selectAdminBusinessProfilesGridsState, fromBusinessProfilesGrids.adapter);
  }
}
