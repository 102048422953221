import { JsonPipe, NgClass } from '@angular/common';
import { Component, inject, input } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatMiniFabButton } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { AbstractMasterViewDrawerDetailsComponent } from '@iot-platform/feature/master-view';
import { CardLoaderModule } from '@iot-platform/iot-platform-ui';
import { HistoryLogDetail } from '@iot-platform/models/audit-trail';
import { DateFormatPipe, UserNamePipe } from '@iot-platform/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { get } from 'lodash';
import { AbstractAuditTrailService } from '../../services';
import { AUDIT_TRAIL_SETTINGS } from '../../settings/audit-trail.provider';

@Component({
    selector: 'iot-platform-audit-trail-log-details',
    imports: [
        FlexLayoutModule,
        MatCardModule,
        NgClass,
        TranslateModule,
        UserNamePipe,
        MatTooltip,
        DateFormatPipe,
        MatMiniFabButton,
        JsonPipe,
        CardLoaderModule,
        MatIconModule
    ],
    providers: [UserNamePipe, DateFormatPipe],
    templateUrl: './log-details.component.html',
    styleUrls: ['./log-details.component.scss']
})
export class LogDetailsComponent extends AbstractMasterViewDrawerDetailsComponent {
  private readonly abstractAuditTrailService = inject(AbstractAuditTrailService);
  loading = input<boolean>(false);
  entity = input<HistoryLogDetail>();
  settings = inject(AUDIT_TRAIL_SETTINGS);

  export() {
    this.abstractAuditTrailService.exportLogDetails(this.entity());
  }

  getParsedAttribute(object: unknown, attribute: string) {
    return get(object, attribute);
  }
}
