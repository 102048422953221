import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component, computed, Signal, signal, WritableSignal } from '@angular/core';
import { FollowedVariable } from '@iot-platform/models/i4b';
import { InfoDisplayPipe } from '@iot-platform/pipes';
import { get } from 'lodash';
import { CustomCellParams } from '../../../models/custom-cell.params';

@Component({
    selector: 'grid-engine-asset-variable-cell',
    templateUrl: './asset-variable-cell.component.html',
    imports: [InfoDisplayPipe],
    providers: [InfoDisplayPipe],
    styleUrls: ['./asset-variable-cell.component.scss']
})
export class AssetVariableCellComponent implements ICellRendererAngularComp {
  params: WritableSignal<CustomCellParams> = signal(null);
  followedVariable: Signal<FollowedVariable> = computed(() => {
    const params = this.params();
    return this.getFollowedVariableByField(params?.data, params?.colDef?.field);
  });

  agInit(params: CustomCellParams): void {
    this.params.set(params);
  }

  refresh(): boolean {
    return false;
  }

  getFollowedVariableByField(obj, path: string): FollowedVariable {
    let current = obj;
    path
      ?.split('.')
      ?.slice(0, -1)
      ?.forEach((p) => (current = get(current, p)));
    return current;
  }

  onDispatchEvent(event: MouseEvent): void {
    const params = this.params();
    event.stopPropagation();
    params.dispatchEvent({
      type: params.eventConfiguration.type,
      options: params.eventConfiguration.options,
      rawData: params.data
    });
  }
}
