import { Component, input, output } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatRippleModule } from '@angular/material/core';
import { MatIcon } from '@angular/material/icon';

import { Device } from '@iot-platform/models/i4b';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    imports: [FlexLayoutModule, TranslateModule, MatIcon, MatRippleModule],
    selector: 'iot-platform-ui-detail-devices-card',
    templateUrl: './detail-devices-card.component.html',
    styleUrls: ['./detail-devices-card.component.scss']
})
export class DetailDevicesCardComponent {
  total = input<number>(0);
  devices = input<Device[]>([]);

  selectItem = output<Device>();

  onClick(device: Device) {
    this.selectItem.emit(device);
  }
}
