import { Component, input } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { Device } from '@iot-platform/models/i4b';
import { TranslateModule } from '@ngx-translate/core';
import { DetailPopupCardsModule } from '../../detail-popup-cards/detail-popup-cards.module';
import { DetailPopupModule } from '../../detail-popup/detail-popup.module';

@Component({
    imports: [FlexLayoutModule, TranslateModule, DetailPopupModule, DetailPopupCardsModule],
    selector: 'iot-platform-ui-device-details-connectors',
    templateUrl: './device-details-connectors.component.html',
    styleUrls: ['./device-details-connectors.component.scss']
})
export class DeviceDetailsConnectorsComponent {
  device = input<Device | null>(null);
}
