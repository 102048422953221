import { Component } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { InfoDisplayPipe } from '@iot-platform/pipes';
import { AbstractTableEngineCellComponent } from '../abstract-table-engine-cell.component';

@Component({
  selector: 'i4b-table-engine-asset-variable-cell',
  templateUrl: './asset-variable-cell.component.html',
  styleUrls: ['./asset-variable-cell.component.scss'],
  imports: [InfoDisplayPipe, MatTooltip]
})
export class AssetVariableCellComponent extends AbstractTableEngineCellComponent<AssetVariableCellComponent> {
  handleDispatchEvent(event: MouseEvent): void {
    const clickEvent = this.clickEvent();
    const rawData = this.rawData();
    event.stopPropagation();
    this.dispatchCellEvent({
      type: clickEvent.type,
      options: clickEvent.options,
      rawData
    });
  }
}
