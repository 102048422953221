import { Component, computed, Signal } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { ProcessMultipleStateVariablesUtil } from '@iot-platform/iot-platform-utils';
import { DateFormatPipe, InfoDisplayPipe, NumberFormatPipe } from '@iot-platform/pipes';
import { AbstractTableEngineCellComponent } from '../abstract-table-engine-cell.component';

@Component({
  selector: 'i4b-table-engine-variable-value-cell',
  templateUrl: './variable-value-cell.component.html',
  styleUrls: ['./variable-value-cell.component.scss'],
  imports: [MatTooltip, DateFormatPipe, NumberFormatPipe, InfoDisplayPipe]
})
export class VariableValueCellComponent extends AbstractTableEngineCellComponent<VariableValueCellComponent> {
  processedUnit: Signal<string | null> = computed(() => {
    const data = this.data();
    const cellOptions = this.cellOptions();
    if (data && cellOptions?.value && cellOptions?.unit && data[cellOptions.unit] && data[cellOptions.unit][0] === '/') {
      const lastRecord = data[cellOptions.value] !== null ? [{ value: data[cellOptions.value], datetime: '' }] : [];
      return ProcessMultipleStateVariablesUtil.getProcessedUnit({
        ...data,
        lastRecords: lastRecord
      });
    }
    return null;
  });
}
