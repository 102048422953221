import { NgClass } from '@angular/common';
import { Component, input, output, signal } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AbstractControl, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { EmailValidators } from '@iot-platform/iot-platform-utils';
import { TranslateModule } from '@ngx-translate/core';
// import { ProgressBarButtonModule } from '@iot-platform/iot-platform-ui';
// FIXME this dependency should be reviewed it causes an app crash
import { ProgressBarButtonModule } from 'libs/iot-platform/iot-platform-ui/src/lib/components/progress-bar-button/progress-bar-button.module';

@Component({
  imports: [
    FlexLayoutModule,
    TranslateModule,
    NgClass,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInput,
    MatButtonModule,
    ProgressBarButtonModule
  ],
  selector: 'iot-platform-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss', '../../scss/style.scss']
})
export class LoginFormComponent {
  hasError = input<boolean>(false);
  isI4BLogin = input<boolean>(false);

  enableGauge = signal(false);
  hide = signal(true);

  resetPassword = output<void>();
  signIn = output<{ username: string; password: string }>();
  // eslint-disable-next-line  @angular-eslint/no-output-native
  cancel = output<void>();

  form: UntypedFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl(null, [Validators.required, EmailValidators.isValid()]),
    password: new UntypedFormControl(null, [Validators.required])
  });

  get email() {
    return this.form.get('email') as AbstractControl;
  }

  get password() {
    return this.form.get('password') as AbstractControl;
  }

  onHide() {
    this.hide.update((h) => !h);
  }

  onChangeEmail(value: string) {
    this.email.setValue(value.toLowerCase());
  }

  onResetPassword() {
    this.resetPassword.emit();
  }

  onSignIn() {
    if (this.form.valid) {
      this.signIn.emit({ username: this.email.value, password: this.password.value });
    }
  }
}
