<mat-card class="dialog-card">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar class="dialog-toolbar" color="accent" fxLayout="row" fxLayoutAlign="space-between center">
      <h1 class="dialog-card-title">{{ title$ | async }}</h1>
      <button (click)="close()" class="regular-round-button" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar>
  </mat-card-title>

  <mat-card-content class="dialog-card-content">
    <form [formGroup]="graphForm" class="full-width p-20" fxLayout="column" fxLayoutGap="10px">
      <div fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
          <mat-label>{{ 'GRAPH.CREATE_FORM.TYPE_PLACEHOLDER' | translate }}</mat-label>
          <mat-radio-group (change)="onTypeChange($event)" class="" color="accent" formControlName="type" fxLayout="row">
            @for (graphType of GraphTypes | keyvalue; track graphType.key) {
              <mat-radio-button [value]="graphType.value">{{ 'GRAPH.CREATE_FORM.TYPES.' + graphType.key | translate }} </mat-radio-button>
            }
          </mat-radio-group>
        </div>

        <div fxFlex fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
          <mat-label>{{ 'GRAPH.CREATE_FORM.SIZE_PLACEHOLDER' | translate }}</mat-label>
          <mat-radio-group class="" color="accent" formControlName="size" fxLayout="row">
            @for (graphSize of GraphSizes | keyvalue; track graphSize.key) {
              <mat-radio-button [value]="graphSize.value">{{ 'IOT_DICTIONARY.' + graphSize.key | translate }} </mat-radio-button>
            }
          </mat-radio-group>
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
        <mat-form-field color="accent" fxFlex>
          <mat-label>{{ 'GRAPH.CREATE_FORM.NAME_PLACEHOLDER' | translate }}</mat-label>
          <input #nameInput [maxLength]="nameMaxLength" formControlName="name" matInput required />
          <mat-hint align="end">{{ nameInput.value?.length || 0 }}/{{ nameMaxLength }}</mat-hint>
        </mat-form-field>

        <mat-form-field color="accent" fxFlex>
          <mat-label>{{ 'GRAPH.CREATE_FORM.SITE_PLACEHOLDER' | translate }}</mat-label>
          <input formControlName="site" matInput required />
        </mat-form-field>

        <mat-form-field color="accent" fxFlex>
          <mat-label>{{ 'GRAPH.CREATE_FORM.DESCRIPTION_PLACEHOLDER' | translate }}</mat-label>
          <input #descriptionInput formControlName="description" matInput maxlength="100" />
          <mat-hint align="end">{{ descriptionInput.value?.length || 0 }}/100</mat-hint>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px">
        <iot-platform-dashboards-asset-variable-selector
          (initialAssetVariablesState)="setAssetVariablesInitialState($event)"
          [allowMultiSelection]="allowMultiSelection()"
          [assets]="data.assets"
          [control]="variables"
          [disabled]="!!data.graph?.asset?.id"
          [events]="eventsSubject$ | async"
          [site]="data.site"
          [variablesLoading]="variablesLoading"
        >
        </iot-platform-dashboards-asset-variable-selector>

        <div class="full-width">
          <iot-platform-dashboards-asset-variable-selector-list
            (removeAssetVariable)="removeAssetVariable()"
            [canDeleteVariables]="allowMultiSelection()"
            [control]="variables"
            [disabled]="!!data.graph?.asset?.id"
            [highlightFirstItem]="true"
            [variablesLoading]="variablesLoading()"
            [withDragAndDrop]="true"
          >
          </iot-platform-dashboards-asset-variable-selector-list>
          @if (typeControl.value === GraphTypes.line_chart) {
            <div class="reference-info">{{ 'GRAPH.CREATE_FORM.REFERENCE_VARIABLE' | translate }}</div>
          }
        </div>
      </div>

      <p class="dialog-form-tips">{{ 'IOT_DICTIONARY.MANDATORY_FIELDS' | translate }}</p>
    </form>
  </mat-card-content>

  <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
    <button (click)="close()" class="button-regular" mat-button>{{ 'IOT_DICTIONARY.CANCEL' | translate }}</button>
    <button
      (click)="save()"
      [disabled]="!isFormStateChanged() || !variables.value || !graphForm.valid"
      class="button-regular"
      color="accent"
      mat-raised-button
    >
      {{ actionButtonLabel$ | async | translate }}
    </button>
  </mat-card-actions>
</mat-card>
