import { I4BCellType, I4BColumnConfiguration } from './configurations/i4b-column-configuration';
import { getDefaultColumnsByConcept } from './default-grids.definitions';
import { EventDurationColumn } from './definitions';
import { I4BBasicColumn } from './definitions/i4b-basic.column';
import { I4BColumn } from './definitions/i4b-column';
import { I4BDeviceEventsButtonColumn } from './definitions/i4b-device-events-button.column';
import { I4BDeviceStatusColumn } from './definitions/i4b-device-status.column';
import { DynamicIconColumn } from './definitions/i4b-dynamic-icon.column';
import { EventVariableGroupColumn } from './definitions/i4b-event-variable-group.column';
import { IdColumn } from './definitions/i4b-id.column';
import { NameColumn } from './definitions/i4b-name.column';
import { I4BSelectionColumn } from './definitions/i4b-selection.column';
import { TotalColumn } from './definitions/i4b-total.column';
import { I4bCellConcept } from './enums/i4b-cell-concept.enum';
import { HeaderType, I4BColumnHeader } from './models/i4b-column-header.model';
import { DeviceEventsGridData } from './models/i4b-grid-data.model';
import { I4BGridOptions } from './models/i4b-grid-options.model';
import { I4BGrid } from './models/i4b-grid.model';
import { I4BColumnOptions } from './options/i4b-column-options';

export const DEFAULT_SITES_COLUMNS_FOR_DEVICE_EVENTS_VIEW: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] = [
  new IdColumn(
    { displayName: 'SITE TECHNICAL ID' },
    {
      id: 'context.site.id',
      concept: I4bCellConcept.SITES,
      width: 150
    }
  ),
  new NameColumn(
    { displayName: 'SITE NAME', type: HeaderType.ICON_AND_TEXT, icon: 'site' },
    {
      id: 'context.site.name',
      sortProperty: 'context.site.name',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.SITE_NAME',
      concept: I4bCellConcept.SITES,
      isDefault: true,
      isConfigurable: true,
      isLink: true,
      cell: { type: I4BCellType.BASIC_LINK, event: { type: 'navigateToSite' } }
    },
    { order: 4 }
  ),
  new I4BBasicColumn(
    { displayName: 'SITE TYPE' },
    {
      id: 'context.site.type',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.SITE_TYPE',
      cell: { type: I4BCellType.TRANSLATED_CELL, options: { translateKey: 'SITES.CARD.TYPES.' } },
      isDefault: false,
      sortProperty: 'context.site.type',
      concept: I4bCellConcept.SITES,
      width: 120
    },
    {
      order: 12
    }
  ),
  new I4BBasicColumn(
    { displayName: 'ENTITY' },
    {
      id: 'context.entity.name',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.ENTITY_NAME',
      cell: { type: I4BCellType.BASIC },
      isDefault: false,
      sortProperty: 'context.entity.name',
      concept: I4bCellConcept.SITES,
      width: 120
    },
    {
      order: 15
    }
  ),
  new I4BBasicColumn(
    { displayName: 'SITE BUSINESS ID' },
    {
      id: 'context.site.businessId',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.BUSINESS_ID',
      cell: { type: I4BCellType.BASIC_LINK, event: { type: 'navigateToSite' } },
      isDefault: false,
      concept: I4bCellConcept.SITES,
      width: 160
    }
  ),
  new I4BBasicColumn(
    { displayName: 'CITY' },
    {
      id: 'context.site.city',
      isDefault: false,
      sortProperty: 'context.site.city',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.CITY',
      cell: { type: I4BCellType.BASIC },
      concept: I4bCellConcept.SITES,
      width: 120
    },
    {
      order: 17
    }
  )
];

export const DEFAULT_DEVICES_COLUMNS_FOR_DEVICE_EVENTS_VIEW: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] = [
  new IdColumn(
    { displayName: 'TECHNICAL DEVICE ID' },
    {
      id: 'context.device.id',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DEVICE_ID',
      concept: I4bCellConcept.DEVICES,
      width: 170,
      cell: { type: I4BCellType.BASIC_LINK, event: { type: 'navigateToDevice' } }
    },
    {
      order: 2
    }
  ),
  new EventVariableGroupColumn({ displayName: 'DEVICE VARIABLE' }, { id: 'deviceVariable', isDefault: true, concept: I4bCellConcept.DEVICES }, { order: 7 }),
  new IdColumn(
    { displayName: 'DEVICE IDENTIFIER' },
    {
      id: 'context.device.identifier',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DEVICE_IDENTIFIER',
      concept: I4bCellConcept.DEVICES,
      width: 150,
      cell: { type: I4BCellType.BASIC_LINK, event: { type: 'navigateToDevice' } }
    }
  ),
  new NameColumn(
    { displayName: 'DEVICE NAME', type: HeaderType.ICON_AND_TEXT, icon: 'device' },
    {
      id: 'context.device.name',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DEVICE_NAME',
      concept: I4bCellConcept.DEVICES,
      isDefault: true,
      cell: { type: I4BCellType.BASIC_LINK, event: { type: 'navigateToDevice' } },
      width: 170
    },
    { order: 6 }
  ),
  new I4BDeviceStatusColumn(
    {
      displayName: 'DEVICE STATUS',
      type: HeaderType.ICON_AND_TEXT,
      icon: 'device'
    },
    {
      id: 'context.device.status',
      sortProperty: 'context.device.status',
      isDefault: false,
      concept: I4bCellConcept.DEVICES,
      cell: {
        type: I4BCellType.TRANSLATED_CELL,
        options: {
          withStatusPadding: true,
          class: {
            running: { color: '#262836', 'background-color': 'rgba(164, 255, 0, 0.1)', padding: '9px' },
            maintenance: { color: '#262836', 'background-color': 'rgba(0, 121, 255, 0.4)', padding: '9px' },
            standby: { color: '#262836', 'background-color': 'rgba(0, 121, 255, 0.1)', padding: '9px' },
            test_mode: { color: '#262836', 'background-color': 'rgba(255, 158, 0, 0.1)', padding: '9px' },
            decommissioned: { color: '#262836', 'background-color': 'rgba(255, 0, 99, 0.1)', padding: '9px' }
          }
        }
      },
      width: 160
    },
    { order: 20 }
  ),
  new I4BBasicColumn(
    { displayName: 'FAMILY' },
    {
      id: 'context.device.family',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DEVICE_TYPE_FAMILY',
      cell: { type: I4BCellType.BASIC },
      isDefault: false,
      sortProperty: 'context.device.family',
      concept: I4bCellConcept.DEVICES,
      width: 120
    },
    {
      order: 14
    }
  ),
  new I4BBasicColumn(
    { displayName: 'MODEL' },
    {
      id: 'context.device.model',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DEVICE_TYPE_MODEL',
      cell: { type: I4BCellType.BASIC },
      isDefault: false,
      sortProperty: 'context.device..model',
      concept: I4bCellConcept.DEVICES,
      width: 120
    },
    {
      order: 15
    }
  ),
  new I4BBasicColumn(
    { displayName: 'FIRMWARE' },
    {
      id: 'context.device.firmware',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DEVICE_TYPE_FIRMWARE',
      cell: { type: I4BCellType.BASIC },
      isDefault: false,
      sortProperty: 'context.device.firmware',
      concept: I4bCellConcept.DEVICES,
      width: 120
    },
    {
      order: 16
    }
  ),
  new I4BBasicColumn(
    { displayName: 'SUPPORT' },
    {
      id: 'context.device.support',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.COMMUNICATION_SUPPORT',
      cell: { type: I4BCellType.BASIC },
      isDefault: false,
      sortProperty: 'communication.support',
      concept: I4bCellConcept.DEVICES,
      width: 120
    },
    {
      order: 11
    }
  ),
  new I4BBasicColumn(
    { displayName: 'INCOMING CONNECTOR ID', type: HeaderType.BASIC },
    {
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.INCOMING_CONNECTOR_ID',
      id: 'context.device.incomingConnectorId',
      isDefault: false,
      sortProperty: 'context.device.incomingConnectorId',
      concept: I4bCellConcept.DEVICES,
      width: 120,
      cell: {
        type: I4BCellType.BASIC
      }
    },
    {
      order: 15
    }
  ),
  new I4BBasicColumn(
    { displayName: 'OUTGOING CONNECTOR ID', type: HeaderType.BASIC },
    {
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.OUTGOING_CONNECTOR_ID',
      id: 'context.device.outgoingConnectorId',
      isDefault: false,
      sortProperty: 'context.device.outgoingConnectorId',
      concept: I4bCellConcept.DEVICES,
      width: 120,
      cell: {
        type: I4BCellType.BASIC
      }
    },
    {
      order: 15
    }
  ),
  new I4BBasicColumn(
    { displayName: 'DEVICE VARIABLE COMMENT', type: HeaderType.BASIC },
    {
      id: 'context.deviceVariable.comment',
      isDefault: false,
      sortProperty: 'context.deviceVariable.comment',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DEVICE_VARIABLE_COMMENT',
      cell: { type: I4BCellType.BASIC },
      concept: I4bCellConcept.DEVICES,
      width: 120
    },
    {
      order: 16
    }
  ),
  new I4BBasicColumn(
    { displayName: 'DEVICE SERVICE ID', type: HeaderType.BASIC },
    {
      id: 'context.device.serviceId',
      isDefault: false,
      sortProperty: 'context.device.serviceId',
      cell: { type: I4BCellType.BASIC },
      concept: I4bCellConcept.DEVICES,
      width: 160
    },
    { order: 17 }
  )
];

export const DEFAULT_ASSET_COLUMNS_FOR_DEVICE_EVENTS_VIEW: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] = [
  new NameColumn(
    { displayName: 'ASSET NAME', type: HeaderType.ICON_AND_TEXT, icon: 'asset' },
    {
      id: 'context.asset.name',
      isDefault: false,
      sortProperty: 'context.asset.name',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.ASSET_NAME',
      concept: I4bCellConcept.ASSETS,
      cell: { type: I4BCellType.BASIC_LINK, event: { type: 'navigateToAsset' } },
      width: 150
    },
    { order: 21 }
  ),
  new I4BBasicColumn(
    { displayName: 'ASSET BUSINESS ID' },
    {
      id: 'context.asset.businessId',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.BUSINESS_ID',
      cell: { type: I4BCellType.BASIC_LINK, event: { type: 'navigateToAsset' } },
      isDefault: false,
      concept: I4bCellConcept.ASSETS,
      width: 170
    }
  ),
  new I4BBasicColumn(
    { displayName: 'SHIPTO', type: HeaderType.BASIC },
    {
      id: 'context.asset.shipTo',
      isDefault: false,
      sortProperty: 'context.asset.shipTo',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.SHIP_TO',
      concept: I4bCellConcept.ASSETS,
      cell: { type: I4BCellType.BASIC },
      width: 120
    },
    { order: 23 }
  )
];

export const DEFAULT_DEVICE_VARIABLES_COLUMNS_FOR_DEVICE_EVENTS_VIEW: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] = [
  new IdColumn(
    { displayName: 'VARIABLE ID' },
    {
      id: 'context.deviceVariable.id',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DEVICE_VARIABLE_ID',
      concept: I4bCellConcept.DEVICE_VARIABLES,
      width: 150,
      cell: { type: I4BCellType.BASIC_LINK, event: { type: 'openChart' } }
    },
    {
      order: 7
    }
  ),
  new NameColumn(
    { displayName: 'VARIABLE NAME', type: HeaderType.ICON_AND_TEXT, icon: 'asset' },
    {
      id: 'context.deviceVariable.name',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.VARIABLE_NAME',
      concept: I4bCellConcept.DEVICE_VARIABLES,
      isDefault: true,
      cell: { type: I4BCellType.BASIC_LINK, event: { type: 'openChart' } }
    },
    { order: 8 }
  )
];

export const DEFAULT_EVENTS_COLUMNS_FOR_DEVICE_EVENTS_VIEW: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] = [
  new DynamicIconColumn({ displayName: 'DELAY', type: HeaderType.ICON, icon: 'delay' }, {}, { order: 0 }),
  new IdColumn(
    { displayName: 'EVENT ID' },
    {
      id: 'id',
      isDefault: false,
      concept: I4bCellConcept.EVENTS,
      width: 120
    },
    {
      order: 25
    }
  ),
  new I4BBasicColumn(
    { displayName: 'OCCURRENCE TIME', type: HeaderType.BASIC },
    { id: 'occurrenceTime', isDefault: true, sortProperty: 'occurrenceTime', cell: { type: I4BCellType.DATE }, concept: I4bCellConcept.EVENTS },
    { order: 2 }
  ),
  new I4BBasicColumn(
    { displayName: 'RECEPTION TIME', type: HeaderType.BASIC },
    { id: 'receptionTime', isDefault: true, sortProperty: 'receptionTime', cell: { type: I4BCellType.DATE }, concept: I4bCellConcept.EVENTS },
    { order: 3 }
  ),
  new I4BBasicColumn(
    { displayName: 'SEVERITY', type: HeaderType.BASIC },
    {
      id: 'severity',
      isDefault: true,
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.SEVERITY',
      sortProperty: 'severity',
      cell: { type: I4BCellType.SEVERITY_CELL },
      concept: I4bCellConcept.EVENTS
    },
    { order: 9 }
  ),
  new TotalColumn(
    {
      displayName: 'COMMENTS',
      type: HeaderType.EMPTY
    },
    {
      id: 'totalComments',
      isDefault: true,
      isConfigurable: false,
      cell: { type: I4BCellType.COMMENT },
      concept: I4bCellConcept.EVENTS
    },
    {
      order: 11
    }
  ),
  new I4BBasicColumn(
    { displayName: 'EVENT STATUS', type: HeaderType.ICON, icon: 'event_alarm' },
    {
      id: 'status',
      isDefault: true,
      isConfigurable: false,
      sortProperty: 'status',
      concept: I4bCellConcept.EVENTS,
      cell: { type: I4BCellType.ICON, options: { tooltip: 'EVENTS.STATUS' } }
    },
    { order: 1 }
  ),
  new I4BBasicColumn(
    {
      displayName: 'ACKNOWLEDGE TIME',
      type: HeaderType.BASIC
    },
    {
      id: 'acknowledge.datetime',
      isDefault: false,
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.ACKNOWLEDGE_DATETIME',
      sortProperty: 'acknowledge.datetime',
      concept: I4bCellConcept.EVENTS,
      cell: { type: I4BCellType.DATE },
      width: 170
    },
    {
      order: 1
    }
  ),
  new I4BBasicColumn(
    {
      displayName: 'ACKNOWLEDGE USER',
      type: HeaderType.BASIC
    },
    {
      id: 'acknowledge.user',
      isDefault: false,
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.ACKNOWLEDGE_USER',
      sortProperty: 'acknowledge.user',
      concept: I4bCellConcept.EVENTS,
      cell: { type: I4BCellType.USER_CELL }
    },
    {
      order: 31
    }
  ),
  new I4BBasicColumn(
    {
      displayName: 'CLOSE TIME',
      type: HeaderType.BASIC
    },
    {
      id: 'close.datetime',
      isDefault: false,
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.CLOSE_DATETIME',
      sortProperty: 'close.datetime',
      concept: I4bCellConcept.EVENTS,
      cell: { type: I4BCellType.DATE }
    },
    {
      order: 40
    }
  ),
  new I4BBasicColumn(
    {
      displayName: 'CLOSE USER',
      type: HeaderType.BASIC
    },
    {
      id: 'close.user',
      isDefault: false,
      concept: I4bCellConcept.EVENTS,
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.CLOSE_USER',
      sortProperty: 'close.user',
      cell: { type: I4BCellType.USER_CELL }
    },
    {
      order: 41
    }
  ),
  new I4BBasicColumn(
    {
      displayName: 'LABEL',
      type: HeaderType.BASIC
    },
    {
      id: 'label',
      concept: I4bCellConcept.EVENTS,
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.LABEL',
      isDefault: false,
      sortProperty: 'label',
      cell: { type: I4BCellType.BASIC }
    },
    { order: 42 }
  ),
  new I4BBasicColumn(
    {
      displayName: 'EVENT NOTE',
      type: HeaderType.BASIC
    },
    {
      id: 'eventNote',
      concept: I4bCellConcept.EVENTS,
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.EVENT_NOTE',
      isDefault: false,
      sortProperty: 'eventNote',
      cell: { type: I4BCellType.BASIC }
    },
    { order: 43 }
  ),
  new I4BBasicColumn(
    {
      displayName: 'EVENT TYPE',
      type: HeaderType.BASIC
    },
    {
      id: 'type',
      concept: I4bCellConcept.EVENTS,
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.EVENT_NOTE',
      isDefault: true,
      sortProperty: 'type',
      cell: { type: I4BCellType.TRANSLATED_CELL, options: { translateKey: 'EVENTS.EVENT_TYPES.' } }
    },
    { order: 8 }
  ),
  new EventDurationColumn(
    {
      displayName: 'EVENT DURATION'
    },
    {
      id: 'occurrenceTime',
      concept: I4bCellConcept.EVENTS,
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.EVENT_DURATION'
    },
    { order: 9 }
  )
];

export class DefaultDeviceEventsGrid implements I4BGrid<I4BGridOptions, DeviceEventsGridData> {
  masterview = 'device-events';
  isDefault = false;
  isUserDefault = false;
  isAppDefault = false;
  name: string = null;
  userId: string = null;
  data: DeviceEventsGridData;
  gridOptions: I4BGridOptions = {
    pageSize: 100,
    multipleSelection: false,
    autoRefresh: { enabled: true, delay: 120 },
    gridSort: [],
    buttonColumn: { enabled: true, className: new I4BDeviceEventsButtonColumn() },
    selectionColumn: {
      enabled: true,
      className: new I4BSelectionColumn()
    }
  };
  columns: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] = [...getDefaultColumnsByConcept(this.masterview)];

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  export(): void {}
}
