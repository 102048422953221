import { KeyValuePipe } from '@angular/common';
import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AbstractControl, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { AsyncAutocompleteModule, TimezoneAutocompleteModule } from '@iot-platform/iot-platform-ui';
import { NameValidators } from '@iot-platform/iot-platform-utils';

import { BusinessProfile, Entity, Organization, TimeseriesDisplayMode } from '@iot-platform/models/common';

import { EntitiesService } from '@iot-platform/shared';

import { TranslateModule, TranslateService } from '@ngx-translate/core';

import * as momentTimezones from 'moment-timezone';

import { BehaviorSubject } from 'rxjs';

import { BusinessProfilesService } from '../../../features/admin-business-profiles/services/business-profiles.service';

@Component({
    imports: [
        FlexLayoutModule,
        MatCardModule,
        MatToolbarModule,
        MatIcon,
        AsyncAutocompleteModule,
        MatFormFieldModule,
        MatInputModule,
        TimezoneAutocompleteModule,
        MatButtonModule,
        TranslateModule,
        MatRadioModule,
        MatSlideToggleModule,
        ReactiveFormsModule,
        KeyValuePipe
    ],
    selector: 'iot4bos-ui-backoffice-add-business-profile-dialog',
    templateUrl: './add-business-profile-dialog.component.html',
    styleUrls: ['./add-business-profile-dialog.component.scss']
})
export class AddBusinessProfileDialogComponent implements OnInit {
  public readonly businessProfilesService: BusinessProfilesService = inject(BusinessProfilesService);
  public data: {
    organization: Organization;
    businessProfile: BusinessProfile;
    type?: string;
  } = inject(MAT_DIALOG_DATA);
  businessProfileFrom: UntypedFormGroup;
  sortedEntities: Entity[] = [];
  initialName = '';
  initialEntity?: Entity;
  initialTZ?: string;
  entitiesLoading = true;
  isDisabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  timezones = momentTimezones.tz.names();
  defaultChartPeriod = 1;
  defaultDisplayMode = TimeseriesDisplayMode.line_chart;
  private readonly dialogRef: MatDialogRef<AddBusinessProfileDialogComponent> = inject(MatDialogRef<AddBusinessProfileDialogComponent>);
  private readonly entitiesService: EntitiesService = inject(EntitiesService);
  private readonly translateService: TranslateService = inject(TranslateService);
  private readonly destroy: DestroyRef = inject(DestroyRef);

  get title(): string {
    return this.data.businessProfile
      ? this.translateService.instant('ADMIN.DIALOG.ADD_BP.TITLE_EDIT', {
          businessProfileName: this.data.businessProfile.name
        })
      : this.translateService.instant('ADMIN.DIALOG.ADD_BP.TITLE_ADD');
  }

  get nameControl(): AbstractControl {
    return this.businessProfileFrom.get('nameControl');
  }

  get organizationControl(): AbstractControl {
    return this.businessProfileFrom.get('entity');
  }

  get timezoneControl(): AbstractControl {
    return this.businessProfileFrom.get('timezoneControl');
  }

  get chartPeriodControl(): AbstractControl {
    return this.businessProfileFrom.get('chartPeriodControl');
  }

  get timeseriesDisplayModeControl(): AbstractControl {
    return this.businessProfileFrom.get('timeseriesDisplayModeControl');
  }

  get stockVisibilityControl(): AbstractControl {
    return this.businessProfileFrom.get('stockVisibilityControl');
  }

  ngOnInit() {
    this.businessProfileFrom = new UntypedFormGroup({
      entity: new UntypedFormControl(
        this.data.organization || this.data.businessProfile
          ? {
              value: '',
              disabled: true
            }
          : { value: '', disabled: false },
        [Validators.required]
      ),
      nameControl: new UntypedFormControl(this.data.businessProfile ? this.data.businessProfile.name : '', {
        validators: [Validators.required, Validators.maxLength(50), Validators.pattern('\\S.*')],
        asyncValidators: [NameValidators.asyncUniqueNameValidatorByEntity(this.businessProfilesService, this.data.businessProfile?.name ?? '')]
      }),
      timezoneControl: new UntypedFormControl(
        this.data.businessProfile && this.data.businessProfile.timezoneDetails ? this.data.businessProfile.timezoneDetails.name : '',
        [Validators.required]
      ),
      chartPeriodControl: new UntypedFormControl(this.data.businessProfile ? this.data.businessProfile.chartPeriod : this.defaultChartPeriod),
      timeseriesDisplayModeControl: new UntypedFormControl(
        this.data.businessProfile ? this.data.businessProfile.timeseriesDisplayMode : this.defaultDisplayMode
      ),
      stockVisibilityControl: new UntypedFormControl(this.data.businessProfile ? this.data.businessProfile.siteStocksVisible : true, [Validators.required])
    });

    this.entitiesService
      .getHierarchicallySortedEntities()
      .pipe(takeUntilDestroyed(this.destroy))
      .subscribe((entities) => {
        this.sortedEntities = entities;
        this.entitiesLoading = false;
        if (this.data.organization) {
          this.initialEntity = this.sortedEntities.find((e) => e.id === this.data.organization.id);
          this.organizationControl.setValue(this.initialEntity);
        } else if (this.data.businessProfile) {
          this.initialEntity = this.sortedEntities.find((e) => e.id === this.data.businessProfile.entityId);
          this.organizationControl.setValue(this.initialEntity);
        }
      });

    if (this.data.businessProfile) {
      this.initialName = this.data.businessProfile.name.trim();
      this.initialTZ = this.data.businessProfile.timezoneDetails?.name.trim();
    }
  }

  onEntitySelection(entity: Entity) {
    if (entity) {
      this.organizationControl.setValue(entity);
      this.nameControl.updateValueAndValidity({ onlySelf: true, emitEvent: true });
    }
  }

  resetEntity() {
    this.organizationControl.reset();
    this.nameControl.updateValueAndValidity({ onlySelf: true, emitEvent: true });
  }

  onTimezoneSelection(timezone: string): void {
    if (timezone) {
      this.timezoneControl.setValue(timezone);
    }
  }

  resetTimezone() {
    this.timezoneControl.reset();
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    const newBP: BusinessProfile = {
      name: this.nameControl.value.trim(),
      entityName: this.organizationControl.value.name,
      entityId: this.organizationControl.value.id,
      timezoneDetails: {
        name: this.timezoneControl.value,
        offset: momentTimezones().tz(this.timezoneControl.value).format('Z')
      },
      chartPeriod: this.chartPeriodControl.value,
      timeseriesDisplayMode: this.timeseriesDisplayModeControl.value,
      siteStocksVisible: this.stockVisibilityControl.value
    };
    this.dialogRef.close(newBP);
  }

  protected readonly TimeseriesDisplayMode = TimeseriesDisplayMode;
}
