import { BaseActions, CommonApiResponse, FavoriteView, Pagination } from '@iot-platform/models/common';
import { I4BGrid, I4BGridData, I4BGridOptions } from '@iot-platform/models/grid-engine';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

class BusinessProfilesFavoriteViewsBaseActions extends BaseActions<FavoriteView> {}

const baseActions = new BusinessProfilesFavoriteViewsBaseActions();

export const AdminBusinessProfilesFavoriteViewActions = createActionGroup({
  source: 'Admin Business Profiles Favorite Views',
  events: {
    ...baseActions.getDefaultEvents(),
    'Load Favorite Views by Business Profile': props<{ businessProfileId: string }>(),
    'Load Favorite Views by Business Profile Success': props<{
      response: CommonApiResponse<FavoriteView, Pagination>;
    }>(),
    'Load Favorite Views by Business Profile Failure': props<{ error: any }>(),
    'Duplicate Favorite View': props<{ favoriteViewToDuplicate: FavoriteView }>(),
    'Duplicate Favorite View Success': props<{ duplicatedFavoriteView: FavoriteView }>(),
    'Duplicate Favorite View Failure': props<{ error: any }>(),
    'Add Favorite View in another Business Profile Success': emptyProps(),
    'Duplicate Favorite View in another Business Profile Success': emptyProps(),
    'Duplicate Grid and Favorite View': props<{
      gridToDuplicate: I4BGrid<I4BGridOptions, I4BGridData>;
      favoriteViewToDuplicate: FavoriteView;
    }>(),
    'Duplicate Grid then Add Favorite View': props<{
      grid: I4BGrid<I4BGridOptions, I4BGridData>;
      favoriteViewToAdd: FavoriteView;
    }>(),
    'Duplicate Grid then Add Favorite View Success': emptyProps(),
    'Duplicate Grid then Add Favorite View Failure': props<{ error: any }>(),
    'Duplicate Grid then Update Favorite View': props<{
      grid: I4BGrid<I4BGridOptions, I4BGridData>;
      favoriteViewToUpdate: FavoriteView;
    }>(),
    'Duplicate Grid then Update Favorite View Success': emptyProps(),
    'Duplicate Grid then Update Favorite View Failure': props<{ error: any }>()
  }
});
