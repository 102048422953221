import { Component, computed, input, Signal } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DateFormatPipe, UserNamePipe } from '@iot-platform/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { get } from 'lodash';

@Component({
    imports: [FlexLayoutModule, TranslateModule, DateFormatPipe, UserNamePipe],
    selector: 'iot-platform-ui-detail-popup-metadata-footer',
    templateUrl: './detail-popup-metadata-footer.component.html',
    styleUrls: ['./detail-popup-metadata-footer.component.scss'],
    providers: [DateFormatPipe, UserNamePipe]
})
export class DetailPopupMetadataFooterComponent {
  data = input<{ createdAt: string; updatedAt: string; createdBy: string; updatedBy: string }>();
  createdBy: Signal<string> = computed(() => get(this.data(), 'createdBy'));
  updatedBy: Signal<string> = computed(() => get(this.data(), 'updatedBy'));
  createdAt: Signal<string> = computed(() => get(this.data(), 'createdAt'));
  updatedAt: Signal<string> = computed(() => get(this.data(), 'updatedAt'));
}
