import { NgStyle } from '@angular/common';
import { Component, computed, Signal } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { AbstractTableEngineCellComponent } from '../abstract-table-engine-cell.component';

@Component({
  selector: 'i4b-table-engine-translated-cell',
  templateUrl: './translated-cell.component.html',
  styleUrls: ['./translated-cell.component.scss'],
  imports: [NgStyle, MatTooltip, TranslateModule]
})
export class TranslatedCellComponent extends AbstractTableEngineCellComponent<TranslatedCellComponent> {
  translateKey: Signal<string> = computed(() => {
    const cellOptions = this.cellOptions();
    return cellOptions?.translateKey ? cellOptions.translateKey : 'IOT_DICTIONARY.';
  });
  translateStyle: Signal<any> = computed(() => {
    const cellOptions = this.cellOptions();
    const data = this.data();
    return cellOptions?.class ? cellOptions.class[this.snakeCaseToCamelCase(data)] : '';
  });

  snakeCaseToCamelCase(snakeCaseString: string): string {
    const parts = snakeCaseString.split('_');
    return parts.reduce((acc: string, value: string, index) => {
      if (index === 0) {
        acc = value.toLowerCase();
      } else {
        acc = `${acc}${value[0].toUpperCase()}${value.slice(1)}`;
      }
      return acc;
    }, '');
  }
}
