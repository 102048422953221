import { NgComponentOutlet } from '@angular/common';
import { Component, computed, inject, input, Signal, Type } from '@angular/core';
import { MasterViewComponentFactory } from '../../master-view-engine/master-view-table/master-view-factory';

@Component({
  imports: [NgComponentOutlet],
  selector: 'i4b-table-engine-master-view-header-container',
  templateUrl: './master-view-header-container.component.html'
})
export class MasterViewHeaderContainerComponent {
  private readonly masterViewComponentFactory: MasterViewComponentFactory = inject(MasterViewComponentFactory);
  column = input<any>();
  componentRef: Signal<{
    ref: Type<any>;
    inputs: any;
  } | null> = computed(() => {
    const column = this.column();
    if (column) {
      return this.createHeaderCell();
    }
    return null;
  });

  createHeaderCell() {
    const column = this.column();
    const componentRef: any = {
      ref: this.masterViewComponentFactory.createHeaderComponent(column.headerType),
      inputs: {}
    };
    componentRef.inputs.data = column;
    return componentRef;
  }
}
