<mat-card class="drawer-card device-call-log-details">
  <mat-card-header class="drawer-card__header drawer-card__header--sticky" fxLayout="row" fxLayoutAlign="space-between center">
    <mat-card-title class="drawer-card__header_title">
      <iot-platform-ui-section-header
        (dispatchEvent)="onDispatchSectionHeaderEvent($event)"
        [actions]="sectionHeaderActions()"
        [showLine]="false"
        [title]="deviceCallLog()?.timestamp | dateFormat"
      />
    </mat-card-title>

    <div fxFlexAlign="flex-end">
      <button (click)="toggleDrawerDetails(false)" class="regular-round-button drawer-card__header_close-btn light-button" color="primary" mat-mini-fab>
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </mat-card-header>

  <mat-card-content class="drawer-card__content" fxLayout="column" fxFlex>
    @if (deviceCallLogMessageLoading()) {
      <iot-platform-ui-card-loader fxFlex fxLayoutAlign="center center" />
    } @else {
      <pre class="device-call-log-details__message"><code>{{ deviceCallLogMessage() | json }}</code></pre>
    }
  </mat-card-content>
</mat-card>
