import { Component, input } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { Contact } from '@iot-platform/models/common';
import { TranslateModule } from '@ngx-translate/core';
import { DetailContactCardComponent } from '../../detail-popup-cards/detail-contact-card/detail-contact-card.component';
import { DetailPopupSectionHeaderComponent } from '../../detail-popup/detail-popup-section-header/detail-popup-section-header.component';

@Component({
    imports: [FlexLayoutModule, TranslateModule, DetailPopupSectionHeaderComponent, DetailContactCardComponent],
    selector: 'iot-platform-ui-site-detail-contacts',
    templateUrl: './site-detail-contacts.component.html',
    styleUrls: ['./site-detail-contacts.component.scss']
})
export class SiteDetailContactsComponent {
  contacts = input<Contact[]>([]);
  canReadContacts = input<boolean>(false);
}
