import { AuthBusinessProfilesPageActions } from '@iot-platform/auth';
import { CommonIndexedPagination } from '@iot-platform/models/common';
import { I4BGrid, I4BGridData, I4BGridOptions, I4BGridSort } from '@iot-platform/models/grid-engine';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { get } from 'lodash';
import { GridsDbActions } from '../actions';

export const gridsDbFeatureKey = 'gridsDb';

export interface State extends EntityState<I4BGrid<I4BGridOptions, I4BGridData>> {
  refreshActivated: boolean;
  selectedId;
  selectedMasterView: string;
  defaultGridByConcept: { [concept: string]: { gridId: string } };
  selectedIds: {
    [gridId: string]: {
      selectedItemId: string;
      checkedIds: string[];
      dataLoading: boolean;
      dataLoaded: boolean;
      sort: I4BGridSort[];
    };
  };
}

export const adapter: EntityAdapter<I4BGrid<I4BGridOptions, I4BGridData>> = createEntityAdapter<I4BGrid<I4BGridOptions, I4BGridData>>({
  selectId: (grid: I4BGrid<I4BGridOptions, I4BGridData>) => grid.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  refreshActivated: true,
  selectedId: null,
  defaultGridByConcept: null,
  selectedIds: null,
  selectedMasterView: null
});

export const reducer = createReducer(
  initialState,
  on(AuthBusinessProfilesPageActions.selectBusinessProfile, () => initialState),
  on(
    GridsDbActions.toggleRefreshActivated,
    (state: State, { refreshActivated }): State => ({
      ...state,
      refreshActivated
    })
  ),
  // GLOBAL GRIDS OPERATIONS,
  on(GridsDbActions.selectCurrentMaterView, (state: State, { masterview }) => ({
    ...state,
    selectedMasterView: masterview
  })),

  on(GridsDbActions.selectGrid, (state: State, { gridId, masterview }) => {
    const grid = get(state, ['entities', gridId]);
    return {
      ...state,
      selectedMasterView: masterview,
      selectedId: gridId,
      selectedIds: {
        ...state.selectedIds,
        [gridId]: {
          selectedItemId: null,
          checkedIds: [],
          nodes: [],
          dataLoaded: true,
          dataLoading: false,
          sort: state.selectedIds?.[gridId]?.sort ?? grid?.gridOptions?.gridSort
        }
      },
      defaultGridByConcept: { ...state.defaultGridByConcept, [masterview]: { gridId } }
    };
  }),

  on(GridsDbActions.sortGridData, (state: State, { gridId, gridSort }) => ({
    ...state,
    selectedIds: {
      ...state.selectedIds,
      [gridId]: { ...get(state, ['selectedIds', gridId]), sort: gridSort }
    }
  })),
  on(
    GridsDbActions.resetState,
    (): State => ({
      ...initialState
    })
  ),
  on(GridsDbActions.loadGridsSuccess, (state: State, { response }) => adapter.setAll(response.data, { ...state })),
  on(GridsDbActions.getDefaultGridByConceptSuccess, (state: State, { defaultGrid }) =>
    adapter.setOne(defaultGrid, {
      ...state,
      selectedMasterView: defaultGrid.masterview,
      selectedId: defaultGrid.id,
      defaultGridByConcept: { ...state.defaultGridByConcept, [defaultGrid.masterview]: { gridId: defaultGrid.id } },
      selectedIds: {
        ...state.selectedIds,
        [defaultGrid.id]: {
          selectedItemId: null,
          checkedIds: [],
          nodes: [],
          dataLoaded: false,
          dataLoading: false,
          sort: defaultGrid.gridOptions.gridSort
        }
      }
    })
  ),
  // GRID CRUD - START
  on(GridsDbActions.addGridSuccess, (state: State, { grid }) => adapter.setOne(grid as I4BGrid<I4BGridOptions, I4BGridData>, { ...state })),
  on(GridsDbActions.updateGridSuccess, GridsDbActions.updateDefaultGridSuccess, (state: State, { grid }) =>
    adapter.updateOne(
      { id: grid.id, changes: { ...(grid as I4BGrid<I4BGridOptions, I4BGridData>) } },
      {
        ...state,
        selectedIds: {
          ...state.selectedIds,
          [grid.id]: {
            ...get(state, ['selectedIds', grid.id]),
            sort: (grid as I4BGrid<I4BGridOptions, I4BGridData>).gridOptions.gridSort
          }
        }
      }
    )
  ),
  on(GridsDbActions.updateSilentGridSuccess, (state: State, { grid }) =>
    adapter.updateOne({ id: grid.id, changes: { ...(grid as I4BGrid<I4BGridOptions, I4BGridData>) } }, { ...state })
  ),
  on(GridsDbActions.removeGridSuccess, (state: State, { removed }) => adapter.removeOne(removed.id, { ...state })),
  // GRID CRUD - END

  // SPECIFIC GRID OPERATIONS
  on(GridsDbActions.loadGridDetailsSuccess, (state: State, { grid }) =>
    adapter.updateOne({ id: state.selectedId, changes: { columns: grid.columns } }, { ...state })
  ),
  // GET DATA - START
  on(GridsDbActions.loadGridData, (state: State, { request }) => {
    if (state.selectedIds) {
      const gridStatus = {
        ...state.selectedIds[state.defaultGridByConcept[request.concept].gridId],
        dataLoaded: false,
        dataLoading: true
      };
      const pagination: CommonIndexedPagination = {
        total: 0,
        hasMore: true,
        limit: request.limit,
        currentPage: request.page,
        maxPage: 0
      };
      return adapter.updateOne(
        {
          id: state.defaultGridByConcept[request.concept].gridId,
          changes: {
            gridOptions: {
              ...state.entities[state.defaultGridByConcept[request.concept].gridId].gridOptions,
              filters: request.filters
            },
            data: {
              response: {
                pagination,
                data: state.refreshActivated // if auto refresh is enabled do not clean the grid. if user has loaded a lot of lines, ti is annoying to reload all the data
                  ? state.entities[state.defaultGridByConcept[request.concept].gridId]?.data?.response?.data
                  : []
              }
            }
          }
        },
        {
          ...state,
          selectedIds: { ...state.selectedIds, [state.defaultGridByConcept[request.concept].gridId]: gridStatus }
        }
      );
    }
    return { ...state };
  }),
  on(GridsDbActions.loadGridDataSuccess, (state: State, { gridData, masterView }) => {
    if (state.selectedIds) {
      const gridStatus = {
        ...state.selectedIds[state.defaultGridByConcept[masterView].gridId],
        dataLoaded: true,
        dataLoading: false
      };
      return adapter.updateOne(
        { id: state.defaultGridByConcept[masterView].gridId, changes: { data: gridData } },
        { ...state, selectedIds: { ...state.selectedIds, [state.defaultGridByConcept[masterView].gridId]: gridStatus } }
      );
    }
    return { ...state };
  }),
  on(GridsDbActions.changeGridPage, (state: State, { request }) => {
    const gridStatus = {
      ...state.selectedIds[state.defaultGridByConcept[request.concept].gridId],
      dataLoaded: false,
      dataLoading: true
    };
    return {
      ...state,
      selectedIds: { ...state.selectedIds, [state.defaultGridByConcept[request.concept].gridId]: gridStatus }
    };
  }),
  // GET DATA - END

  // MANAGE ITEMS IN GRIDS - START
  on(GridsDbActions.selectItemInGridData, (state: State, { gridId, itemId }) => {
    if (gridId) {
      return {
        ...state,
        selectedId: gridId,
        selectedIds: { ...state.selectedIds, [gridId]: { ...state.selectedIds[gridId], selectedItemId: itemId } }
      };
    } else {
      return state;
    }
  }),
  on(GridsDbActions.checkItemsInGridData, (state: State, { gridId, itemIds }) => ({
    ...state,
    selectedIds: { ...state.selectedIds, [gridId]: { ...state.selectedIds[gridId], checkedIds: itemIds } }
  })),
  on(GridsDbActions.addItemInGridData, (state: State, { gridId, item }) => {
    const data = state.entities[gridId].data;
    const newData = [...data.response.data, item];
    const oldPagination = data.response.pagination as CommonIndexedPagination;
    const newPagination: CommonIndexedPagination = { ...oldPagination, total: oldPagination.total + 1 };
    const gridData: I4BGridData = { response: { data: newData, pagination: newPagination } };
    return adapter.updateOne({ id: gridId, changes: { data: gridData } }, { ...state });
  }),
  on(GridsDbActions.updateItemInGridDataSuccess, (state: State, { gridId, item }) => {
    const data = state.entities[gridId].data;
    const toUpdateIdx = data.response.data.findIndex((responseData) => responseData.id === item.id);
    data.response.data[toUpdateIdx] = { ...item };
    return adapter.updateOne({ id: gridId, changes: { data: { ...data } } }, { ...state });
  }),
  on(GridsDbActions.updateItemInAllGridsDataSuccess, (state: State, { updatedItem }) => {
    const updates = Object.keys(state.entities).reduce((acc: I4BGrid<I4BGridOptions, I4BGridData>[], gridId) => {
      const data = get(state, ['entities', gridId, 'data']);
      if (!!data) {
        const toUpdateIdx = get(data, ['response', 'data'], [])?.findIndex((currentItem) => currentItem.id === updatedItem.id);
        if (toUpdateIdx !== -1) {
          data.response.data.splice(toUpdateIdx, 1, updatedItem);
        }
        acc.push({ ...state.entities[gridId], data: { ...data } });
      }
      return acc;
    }, []);
    return adapter.upsertMany(updates, { ...state });
  }),
  on(GridsDbActions.removeItemInGridData, (state: State, { gridId, item: deletedItem }) => {
    const data = state.entities[gridId].data;
    const newData = data.response.data.filter((item) => item.id !== deletedItem.id);
    const oldPagination = data.response.pagination as CommonIndexedPagination;
    const newPagination: CommonIndexedPagination = { ...oldPagination, total: oldPagination.total - 1 };
    const gridData: I4BGridData = { response: { data: newData, pagination: newPagination } };
    return adapter.updateOne({ id: gridId, changes: { data: gridData } }, { ...state });
  }),
  on(GridsDbActions.resetDataInDefaultGrid, (state: State, { gridConcept }) => {
    const defaultGridId = state.defaultGridByConcept ? state.defaultGridByConcept[gridConcept]?.gridId : undefined;
    if (defaultGridId) {
      return adapter.updateOne(
        {
          id: defaultGridId,
          changes: {
            data: {
              response: {
                data: [],
                pagination: { ...state.entities[defaultGridId].data.response.pagination }
              }
            }
          }
        },
        { ...state }
      );
    } else {
      return state;
    }
  })
  // MANAGE ITEMS IN GRIDS - END
);

export const getSelectedGridId = (state: State) => state.selectedId;
export const getSelectedIds = (state: State) => state.selectedIds;
export const getDefaultGrids = (state: State) => state.defaultGridByConcept;
export const selectRefreshActivated = (state: State) => state.refreshActivated;
