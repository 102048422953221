import { Component, inject } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TranslateModule } from '@ngx-translate/core';
import { AUDIT_TRAIL_MASTER_VIEW_NAME } from '../../grids/audit-trail-grid-definition';
import { LogsComponent } from '../logs/logs.component';

@Component({
    selector: 'iot-platform-audit-trail-logs-popup',
    imports: [FlexLayoutModule, MatCardModule, TranslateModule, MatToolbarModule, MatIconModule, MatButtonModule, LogsComponent],
    templateUrl: './logs-popup.component.html',
    styleUrls: ['./logs-popup.component.scss']
})
export class LogsPopupComponent {
  readonly dialogRef: MatDialogRef<LogsPopupComponent> = inject(MatDialogRef<LogsPopupComponent>);
  readonly data: { concept: string; elementId: string; elementName: string } = inject(MAT_DIALOG_DATA);
  readonly masterView = AUDIT_TRAIL_MASTER_VIEW_NAME;

  close(): void {
    this.dialogRef.close();
  }
}
