import { Component, computed, Signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { AbstractTableEngineCellComponent } from '../abstract-table-engine-cell.component';
import { TranslatedCellComponent } from '../translated-cell/translated-cell.component';

@Component({
  selector: 'i4b-table-engine-boolean-cell',
  templateUrl: './boolean-cell.component.html',
  styleUrls: ['./boolean-cell.component.scss'],
  imports: [MatCheckbox, FormsModule, TranslatedCellComponent],
  standalone: true
})
export class BooleanCellComponent extends AbstractTableEngineCellComponent<BooleanCellComponent> {
  value: Signal<any> = computed(() => {
    const cellOptions = this.cellOptions();
    const data = this.data();
    if (cellOptions?.mode === 'booleanAsText') {
      return data ? cellOptions.dataToDisplayIfTrue : cellOptions.dataToDisplayIfFalse;
    } else if (cellOptions?.mode === 'dataComparison') {
      return data === cellOptions.comparisonKey ? cellOptions.dataToDisplayIfTrue : cellOptions.dataToDisplayIfFalse;
    }
    return null;
  });
}
