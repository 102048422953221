import { CdkDragHandle } from '@angular/cdk/drag-drop';
import { Component, input, TemplateRef, ViewChild } from '@angular/core';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'iot-platform-ui-drag-drop-indicator',
  imports: [CdkDragHandle, MatIcon],
  template: `
    <ng-template>
      <mat-icon cdkDragHandle class="dd-handle-icon">{{ icon() }}</mat-icon>
      <ng-content />
    </ng-template>
  `
})
export class DragDropIndicatorComponent {
  icon = input<string>('drag_indicator');
  @ViewChild(TemplateRef) public template!: TemplateRef<any>;
}
