import { UpperCasePipe } from '@angular/common';
import { Component, computed, inject, signal, Signal, WritableSignal } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';
import { SortUtil } from '@iot-platform/iot-platform-utils';

import { BusinessProfile, Role } from '@iot-platform/models/common';
import { TranslateModule } from '@ngx-translate/core';

import { BusinessProfilesService } from '../../../features/admin-business-profiles/services/business-profiles.service';
import { AdminBusinessProfilesRolesFacade } from '../../../features/admin-business-profiles/state/facades/admin-business-profiles-roles.facade';

@Component({
    imports: [
        FlexLayoutModule,
        MatCardModule,
        MatToolbarModule,
        MatIcon,
        MatListModule,
        MatFormFieldModule,
        MatButtonModule,
        TranslateModule,
        UpperCasePipe,
        MatProgressSpinner
    ],
    selector: 'iot4bos-ui-backoffice-manage-business-profile-roles-dialog',
    templateUrl: './manage-business-profile-roles-dialog.component.html',
    styleUrls: ['./manage-business-profile-roles-dialog.component.scss', '../../../style/admin.style.scss']
})
export class ManageBusinessProfileRolesDialogComponent {
  public data: { businessProfile: BusinessProfile } = inject(MAT_DIALOG_DATA);
  private readonly adminBusinessProfilesRolesFacade: AdminBusinessProfilesRolesFacade = inject(AdminBusinessProfilesRolesFacade);
  private dialogRef: MatDialogRef<ManageBusinessProfileRolesDialogComponent> = inject(MatDialogRef<ManageBusinessProfileRolesDialogComponent>);
  private businessProfileService: BusinessProfilesService = inject(BusinessProfilesService);
  //
  allRoles: WritableSignal<Role[]> = signal([]);
  currentRoles: Signal<Role[]> = this.adminBusinessProfilesRolesFacade.all;
  unselectedRoles = computed(() => {
    const currentRoles = this.currentRoles();
    const allRoles = this.allRoles();
    return this.checkAvailableRoles(allRoles, currentRoles).sort(SortUtil.sortByName);
  });
  param = { bPName: '' };
  unselectedRolesPending = true;

  constructor() {
    this.businessProfileService.getRolesByOrganization(this.data.businessProfile.entityId).subscribe((rolesByOrganization: Role[]) => {
      this.allRoles.set(rolesByOrganization);
      this.unselectedRolesPending = false;
    });
  }

  removeRoleFromProfile(roleToRemove: Role) {
    this.adminBusinessProfilesRolesFacade.removeRoleFromBusinessProfile(this.data.businessProfile.id, roleToRemove);
  }

  addRoleToProfile(roleToAdd: Role) {
    this.adminBusinessProfilesRolesFacade.addRoleToBusinessProfile(this.data.businessProfile.id, roleToAdd);
  }

  exit() {
    this.dialogRef.close(true);
  }

  private checkAvailableRoles(rolesByOrganization: Role[], currentRoles: Role[]): Role[] {
    const available: Role[] = [];
    rolesByOrganization.forEach((role) => {
      if (currentRoles.indexOf(currentRoles.find((r) => role.id === r.id)) === -1) {
        available.push(role);
      }
    });
    return available;
  }
}
