import { I4BCellType, I4BColumnConfiguration, I4BColumnRowGroupConfiguration } from '../configurations';
import { HeaderType, I4BColumnHeader } from '../models';
import { I4BVariableColumnOptions } from '../options';
import { I4BBasicColumn } from './i4b-basic.column';
import { RichVariableColumn } from './i4b-rich-variable.column';

interface EventVariableGroupColumnConfiguration extends I4BColumnConfiguration {
  rowGroupParams: Partial<{
    name: Partial<I4BColumnRowGroupConfiguration>;
    value: Partial<I4BColumnRowGroupConfiguration>;
  }>;
}

export class EventVariableGroupColumn extends I4BBasicColumn {
  columnId = '89aad5ee-ba72-11ec-b2ff-acde48001122-event-variable-group';
  className = EventVariableGroupColumn;

  constructor(
    customHeader?: Partial<I4BColumnHeader>,
    customConfig?: Partial<EventVariableGroupColumnConfiguration>,
    customOptions?: Partial<I4BVariableColumnOptions>
  ) {
    super(
      {
        displayName: customOptions?.overrideHeaderName ? customOptions?.overrideHeaderName : customHeader?.displayName ? customHeader?.displayName : 'VARIABLE',
        type: HeaderType.BASIC
      },
      {
        cell: { type: I4BCellType.BASIC },
        id: customConfig.id,
        concept: customConfig.concept,
        isDefault: true,
        marryChildren: true,
        rowGroupParams: customConfig?.rowGroupParams,
        children: [
          new I4BBasicColumn(
            {
              displayName: customHeader?.displayName ? customHeader?.displayName + ' NAME' : 'VARIABLE NAME',
              type: HeaderType.ICON_AND_TEXT,
              icon: 'variable'
            },
            {
              id: 'context.' + customConfig.id + '.name',
              isDefault: true,
              sortProperty: 'context.' + customConfig.id + '.name',
              cell: {
                type: I4BCellType.BASIC_LINK,
                options: { datatype: 'device' },
                event: { type: 'openGraph', options: {} }
              },
              autoSize: true,
              rowGroupParams: customConfig?.rowGroupParams?.name
            },
            { order: 0, customId: 'context.' + customConfig.id + '.name', isDisplayed: customOptions.displayDateTime }
          ),
          new RichVariableColumn(
            { displayName: customHeader?.displayName ? customHeader?.displayName + ' VALUE' : 'VARIABLE VALUE' },
            {
              id: 'context.' + customConfig.id,
              isDefault: true,
              sortProperty: 'context.' + customConfig.id + 'value',
              autoSize: true,
              cell: {
                type: I4BCellType.RICH_VARIABLE,
                options: { dataType: 'context', unit: 'unit', value: 'value', tooltip: 'datetime' },
                event: { type: 'openGraph', options: {} },
                valueGetter: 'context.' + customConfig.id + '.value'
              },
              rowGroupParams: customConfig?.rowGroupParams?.value
            },
            {
              order: 1,
              customId: 'context.' + customConfig.id,
              sortable: true,
              isDisplayed: customOptions.displayDateTime
            }
          )
        ]
      },
      { displayDateTime: false, ...customOptions, customId: customConfig.id }
    );
  }
}
