import { NgClass } from '@angular/common';
import { Component, input } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  imports: [NgClass, TranslateModule, MatTooltipModule],
  selector: 'iot-platform-ui-event-severity-display',
  templateUrl: './event-severity-display.component.html',
  styleUrls: ['./event-severity-display.component.scss']
})
export class EventSeverityDisplayComponent {
  severity = input<string>();
  tooltip = input<string | null>();
}
