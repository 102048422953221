export enum I4BGridFilterOptions {
  LESS_THAN = 'lessThan',
  GREATER_THAN = 'greaterThan',
  LESS_THAN_OR_EQUAL = 'lessThanOrEqual',
  GREATER_THAN_OR_EQUAL = 'greaterThanOrEqual',
  IN_RANGE = 'inRange',
  IN_RANGE_START = 'inRangeStart',
  IN_RANGE_END = 'inRangeEnd',
  CONTAINS = 'contains',
  NOT_CONTAINS = 'notContains',
  START_WITH = 'startsWith',
  END_WITH = 'endsWith',
  EQUALS = 'equals',
  NOT_EQUAL = 'notEqual',
  BLANK = 'blank',
  NOT_BLANK = 'notBlank',
  EMPTY = 'empty'
}

export enum I4BGridFilterButtonType {
  APPLY = 'apply',
  RESET = 'reset',
  CLEAR = 'clear',
  CANCEL = 'cancel'
}

export enum I4BGridFilterType {
  TEXT_COLUMN_FILTER = 'TEXT_COLUMN_FILTER',
  SET_COLUMN_FILTER = 'SET_COLUMN_FILTER',
  BOOLEAN_COLUMN_FILTER = 'BOOLEAN_COLUMN_FILTER',
  NUMBER_COLUMN_FILTER = 'NUMBER_COLUMN_FILTER',
  DATE_COLUMN_FILTER = 'DATE_COLUMN_FILTER',
  ICON_COLUMN_FILTER = 'ICON_COLUMN_FILTER',
  RICH_VALUE_COLUMN_FILTER = 'RICH_VALUE_COLUMN_FILTER',
  LINKED_COLUMN_FILTER = 'LINKED_COLUMN_FILTER',
  AUDIT_TRAIL_ATTRIBUTES_COLUMN_TEXT_FILTER = 'AUDIT_TRAIL_ATTRIBUTES_TEXT_FILTER',
  USER_COLUMN_TEXT_FILTER = 'USER_COLUMN_TEXT_FILTER',
  USER_COLUMN_SET_FILTER = 'USER_COLUMN_SET_FILTER',
}

export interface I4BGridFilterParams {
  enabled: boolean;
  field: string;
  filterType: I4BGridFilterType;
  filterOptions: I4BGridFilterOptions[];
  filterButtons: I4BGridFilterButtonType[];
}
