import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component, signal, WritableSignal } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { InfoDisplayPipe, NumberFormatPipe } from '@iot-platform/pipes';
import { CustomNumberCellParams } from '../../../models/custom-number-cell.params';

@Component({
    imports: [FlexLayoutModule, NumberFormatPipe, InfoDisplayPipe],
    providers: [NumberFormatPipe, InfoDisplayPipe],
    selector: 'grid-engine-address-accuracy-cell',
    templateUrl: './address-accuracy-cell.component.html',
    styleUrls: ['./address-accuracy-cell.component.scss']
})
export class AddressAccuracyCellComponent implements ICellRendererAngularComp {
  params: WritableSignal<CustomNumberCellParams> = signal(null);

  agInit(params: CustomNumberCellParams): void {
    this.params.set(params);
  }

  refresh(): boolean {
    return false;
  }

  getApplicableScore(matchScore: number): { min: number; max: number; color: string } {
    const mScoreRed: { min: number; max: number; color: string } = { min: 0, max: 0.4, color: '#E75D70' };
    const mScoreOrange: { min: number; max: number; color: string } = { min: 0.4, max: 0.7, color: '#F58C2D' };
    const mScoreGreen: { min: number; max: number; color: string } = { min: 0.7, max: 0.9, color: '#7CCA6F' };
    const mScoreBlue: { min: number; max: number; color: string } = { min: 0.9, max: 1.01, color: '#375F9B' };
    const mScoreGrey: { min: number; max: number; color: string } = { min: 0, max: 0, color: '#96A5AF' };
    const scores: { min: number; max: number; color: string }[] = [mScoreRed, mScoreOrange, mScoreGreen, mScoreBlue];
    let applicableScore: { min: number; max: number; color: string } | undefined = mScoreGrey;
    if (matchScore) {
      applicableScore = scores.find((score: { min: number; max: number; color: string }) => matchScore < score.max && matchScore >= score.min);
    }
    return applicableScore ?? mScoreGrey;
  }
}
