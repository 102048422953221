// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import tinyColor from 'tinycolor2';

/*
const printF = (msg, v) => {
  console.log(`%c ${msg} `, `background: ${v};`);
};
*/

export class ColorsUtils {
  static getShadesOfColor = (
    color: string | null,
    numShades: number,
    brightnessRatio = 0.2,
    readabilityRatio = 4.5 /* 4.5: WCAG AA standard for readability */
  ) => {
    if (!color) {
      color = tinyColor.random().toHexString();
    }
    const shades = [];
    const baseColor = tinyColor(color);
    const hsl = baseColor.toHsl();
    for (let i = numShades - 1; i >= 0; i -= 1) {
      hsl.l = (i + brightnessRatio) / numShades;
      const c = tinyColor(hsl);
      const readability = tinyColor.readability(c.toHexString(), color);
      const isReadable = readability <= readabilityRatio;
      /*
      if (!isReadable) {
        c = c.clone().brighten(20);
      }
      */
      shades.push({
        tinyColor: c,
        isReadable,
        hex: c.toHexString(),
        rgb: c.toRgbString(),
        hsl: c.toHslString(),
        hsv: c.toHsvString()
      });
    }
    return shades;
  };
}
