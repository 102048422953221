import { inject } from '@angular/core';
import { NotificationService } from '@iot-platform/notification';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { BusinessProfilesService } from '../../services/business-profiles.service';
import { AdminBusinessProfilesMembersActions } from '../actions/business-profiles-members.actions';

const loadMembersByBusinessProfile$ = createEffect(
  (actions$ = inject(Actions), businessProfilesService = inject(BusinessProfilesService)) =>
    actions$.pipe(
      ofType(AdminBusinessProfilesMembersActions.loadMembersByBusinessProfile),
      mergeMap((action) =>
        businessProfilesService.loadMembersByBusinessProfileId(action.businessProfileId).pipe(
          map((members) => AdminBusinessProfilesMembersActions.loadMembersByBusinessProfileSuccess({ members })),
          catchError((error) => of(AdminBusinessProfilesMembersActions.loadMembersByBusinessProfileFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const addMemberToBusinessProfile$ = createEffect(
  (actions$ = inject(Actions), businessProfilesService = inject(BusinessProfilesService)) =>
    actions$.pipe(
      ofType(AdminBusinessProfilesMembersActions.addMemberToBusinessProfile),
      mergeMap((action) =>
        businessProfilesService.addMemberToBusinessProfile(action.businessProfileId, action.memberToAdd).pipe(
          map((addedMember) => AdminBusinessProfilesMembersActions.addMemberToBusinessProfileSuccess({ addedMember })),
          catchError((error) => of(AdminBusinessProfilesMembersActions.addMemberToBusinessProfileFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const removeMemberFromBusinessProfile$ = createEffect(
  (actions$ = inject(Actions), businessProfilesService = inject(BusinessProfilesService)) =>
    actions$.pipe(
      ofType(AdminBusinessProfilesMembersActions.removeMemberFromBusinessProfile),
      mergeMap((action) =>
        businessProfilesService.removeMemberFromBusinessProfile(action.businessProfileId, action.memberToRemove).pipe(
          map((removedMember) => AdminBusinessProfilesMembersActions.removeMemberFromBusinessProfileSuccess({ removedMember })),
          catchError((error) => of(AdminBusinessProfilesMembersActions.removeMemberFromBusinessProfileFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const showLoader$ = createEffect(
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(
        AdminBusinessProfilesMembersActions.loadMembersByBusinessProfile,
        AdminBusinessProfilesMembersActions.addMemberToBusinessProfile,
        AdminBusinessProfilesMembersActions.removeMemberFromBusinessProfile
      ),
      tap(() => notificationService.showLoader())
    ),
  { functional: true, dispatch: false }
);

const hideLoader$ = createEffect(
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(
        AdminBusinessProfilesMembersActions.loadMembersByBusinessProfileSuccess,
        AdminBusinessProfilesMembersActions.loadMembersByBusinessProfileFailure,
        AdminBusinessProfilesMembersActions.addMemberToBusinessProfileSuccess,
        AdminBusinessProfilesMembersActions.addMemberToBusinessProfileFailure,
        AdminBusinessProfilesMembersActions.removeMemberFromBusinessProfileSuccess,
        AdminBusinessProfilesMembersActions.removeMemberFromBusinessProfileFailure
      ),
      tap(() => notificationService.hideLoader())
    ),
  { functional: true, dispatch: false }
);

const displaySuccess$ = createEffect(
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(AdminBusinessProfilesMembersActions.addMemberToBusinessProfileSuccess, AdminBusinessProfilesMembersActions.removeMemberFromBusinessProfileSuccess),
      tap((action: Action) => notificationService.displaySuccess(action.type))
    ),
  { functional: true, dispatch: false }
);

const displayError$ = createEffect(
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(
        AdminBusinessProfilesMembersActions.loadMembersByBusinessProfileFailure,
        AdminBusinessProfilesMembersActions.addMemberToBusinessProfileFailure,
        AdminBusinessProfilesMembersActions.removeMemberFromBusinessProfileFailure
      ),
      tap(({ error }) => notificationService.displayError(error))
    ),
  { functional: true, dispatch: false }
);
export const adminBusinessProfilesMembersEffects = {
  loadMembersByBusinessProfile$,
  addMemberToBusinessProfile$,
  removeMemberFromBusinessProfile$,
  showLoader$,
  hideLoader$,
  displaySuccess$,
  displayError$
};
