import { CommonApiResponse, CommonIndexedPagination } from '@iot-platform/models/common';
import {
  HeaderType,
  I4BBasicColumn,
  I4BButtonColumn,
  I4bCellConcept,
  I4BCellType,
  I4BColumn,
  I4BColumnConfiguration,
  I4BColumnHeader,
  I4BColumnOptions,
  I4BDynamicDataCell,
  I4BGrid,
  I4BGridData,
  I4BGridOptions
} from '@iot-platform/models/grid-engine';

export class BusinessProfileGridsGridData implements I4BGridData {
  response!: CommonApiResponse<I4BGrid<I4BGridOptions, I4BGridData>, CommonIndexedPagination>;
}

export const BUSINESS_PROFILE_GRIDS_MASTER_VIEW_NAME = 'business-profile-grids';

class BusinessProfileGridsGridButtonColumn extends I4BButtonColumn {
  constructor() {
    super(
      {
        type: HeaderType.CALL_TO_ACTION,
        displayName: 'BUTTON',
        icon: ''
      },
      {
        concept: I4bCellConcept.GRIDS,
        isConfigurable: false,
        isDefault: true,
        isLink: false,
        position: '',
        sortProperty: '',
        width: 10,
        id: 'button',
        cell: {
          type: I4BCellType.CALL_TO_ACTION,
          singleActions: [
            {
              order: 10,
              key: 'edit',
              label: 'IOT_DICTIONARY.EDIT',
              enableConditions: [],
              visibleConditions: [[{ key: 'canUpdateBusinessProfile', value: true }]]
            },
            {
              order: 20,
              key: 'delete',
              label: 'IOT_DICTIONARY.DELETE',
              enableConditions: [],
              visibleConditions: [[{ key: 'canUpdateBusinessProfile', value: true }]]
            }
          ],

          visibleConditions: [[{ key: 'canUpdateBusinessProfile', value: true }]],
          enableConditions: [],
          bulkActions: []
        }
      },
      { order: 1000 }
    );
  }
}

export class BusinessProfileGridsGrid implements I4BGrid<I4BGridOptions, BusinessProfileGridsGridData> {
  masterview = BUSINESS_PROFILE_GRIDS_MASTER_VIEW_NAME;
  isDefault = true;
  isUserDefault = false;
  isAppDefault = false;
  name = 'business-profile-grids';
  userId: string | null = null;
  data: BusinessProfileGridsGridData = {
    response: {
      data: [],
      pagination: null
    }
  };
  gridOptions: I4BGridOptions = {
    pageSize: 10,
    multipleSelection: false,
    autoRefresh: { enabled: false },
    buttonColumn: { enabled: true, className: new BusinessProfileGridsGridButtonColumn() }
  };
  columns: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] = [
    new I4BBasicColumn({ displayName: 'NAME' }, { id: 'name', sortProperty: 'name', isDefault: true }, { order: 0 }),
    new I4BBasicColumn(
      { displayName: 'MASTERVIEW' },
      { id: 'masterview', sortProperty: 'masterview', isDefault: true, cell: { type: I4BCellType.TRANSLATED_CELL } },
      { order: 1 }
    ),
    new I4BBasicColumn(
      { displayName: 'CREATED BY' },
      {
        translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.CREATED_BY',
        id: 'createdBy',
        isDefault: true,
        sortProperty: 'createdBy',
        cell: { type: I4BCellType.USER_CELL }
      },
      { order: 2, width: 165 }
    ),
    new I4BBasicColumn(
      { displayName: 'FAVORITE VIEWS', enableSorting: false },
      {
        id: 'id',
        isDefault: true,
        cell: {
          type: I4BCellType.DYNAMIC_DATA,
          options: {
            api: {
              version: 2,
              extendedEndpoint: true,
              endpoint: 'grid/{{id}}/favorite-views?limit=10',
              params: ['id']
            },
            expressions: [
              {
                type: 'function',
                value: 'simpleJoin',
                field: 'name'
              }
            ]
          }
        } as I4BDynamicDataCell
      },
      { order: 3 }
    )
  ];
}
