import { BaseUser } from '@iot-platform/models/common';
import { createActionGroup, props } from '@ngrx/store';

export const AdminBusinessProfilesMembersActions = createActionGroup({
  source: 'Admin Business Profiles Members',
  events: {
    'Load Members by Business Profile': props<{ businessProfileId: string }>(),
    'Load Members by Business Profile Success': props<{ members: BaseUser[] }>(),
    'Load Members by Business Profile Failure': props<{ error: any }>(),
    'Add Member To Business Profile': props<{ businessProfileId: string; memberToAdd: BaseUser }>(),
    'Add Member To Business Profile Success': props<{ addedMember: BaseUser }>(),
    'Add Member To Business Profile Failure': props<{ error: any }>(),
    'Remove Member From Business Profile': props<{ businessProfileId: string; memberToRemove: BaseUser }>(),
    'Remove Member From Business Profile Success': props<{ removedMember: BaseUser }>(),
    'Remove Member From Business Profile Failure': props<{ error: any }>()
  }
});
