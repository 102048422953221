import { BaseActions, Filter } from '@iot-platform/models/common';
import { Calendar } from '@iot-platform/models/ocm';
import { createActionGroup, props } from '@ngrx/store';

class CalendarsBaseActions extends BaseActions<Calendar> {}

const baseActions = new CalendarsBaseActions();

export const CalendarsActions = createActionGroup({
  source: 'Calendars',
  events: {
    ...baseActions.getDefaultEvents(),
    'Load Calendars': props<{ calendarId: string }>(),
    'Load Calendars Success': props<{ daysOff: Calendar[] }>(),
    'Load Calendars Failure': props<{ error: unknown }>(),
    'Navigate To Calendar Details': props<{ calendarToNavigate: Calendar }>(),
    'Set Filters': props<{ filters: Filter[] }>()
  }
});
