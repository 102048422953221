import { createSelector } from '@ngrx/store';
import { State, adapter, assetEventsCommentsFeature } from '../reducers/asset-events-comments.reducer';

const selectEventCommentsState = assetEventsCommentsFeature.selectAssetEventsCommentsState;

export const {
  selectIds: selectEventCommentsIds,
  selectEntities: selectEventCommentsDictionary,
  selectAll: selectAllEventComments,
  selectTotal: selectTotalEventComments
} = adapter.getSelectors(selectEventCommentsState);

export const selectEventCommentsError = createSelector(selectEventCommentsState, (state: State) => state.error);
export const selectEventCommentsLoading = createSelector(selectEventCommentsState, (state: State) => state.loading);
