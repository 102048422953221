import { Component, effect, input, output, signal, WritableSignal } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBar } from '@angular/material/progress-bar';
import { MatTooltip } from '@angular/material/tooltip';

import { BusinessProfile } from '@iot-platform/models/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  imports: [MatProgressBar, FlexLayoutModule, TranslateModule, MatButtonModule, MatTooltip, MatIconModule],
  selector: 'iot-platform-login-select-profile',
  templateUrl: './login-select-profile.component.html',
  styleUrls: ['./login-select-profile.component.scss']
})
export class LoginSelectProfileComponent {
  businessProfiles = input<BusinessProfile[]>([]);
  loading = input<boolean>(false);
  selectProfile = output<any>();

  bpByEntities: WritableSignal<
    {
      entityId: string;
      entityName: string;
      businessProfiles: BusinessProfile[];
    }[]
  > = signal([]);

  constructor() {
    effect(() => {
      const businessProfiles = this.businessProfiles();
      this.bpByEntities.set([]);
      if (businessProfiles && businessProfiles.length > 0) {
        this.bpByEntities.set(this.getBpByEntity(this.sortBpByLevel(businessProfiles)));
      }
    });
  }

  onSelectProfile(profile: BusinessProfile): void {
    this.selectProfile.emit(profile);
  }

  private sortBpByLevel(businessProfiles: BusinessProfile[]): BusinessProfile[] {
    return businessProfiles.sort((a, b) => a.level - b.level);
  }

  private sortBpByAlphabeticalOrder(businessProfiles: BusinessProfile[]): BusinessProfile[] {
    return businessProfiles.sort((a, b) => a.name.localeCompare(b.name));
  }

  private getBpByEntity(businessProfiles: BusinessProfile[]): {
    entityId: string;
    entityName: string;
    businessProfiles: BusinessProfile[];
  }[] {
    const bpByEntities = businessProfiles.map((businessProfile) => ({
      entityId: businessProfile.entityId,
      entityName: businessProfile.entityName,
      businessProfiles: this.sortBpByAlphabeticalOrder(
        businessProfiles.filter((curBusinessProfile) => curBusinessProfile.entityId === businessProfile.entityId)
      )
    }));
    return bpByEntities.reduce(
      (resBpByEntities, bpByEntity) =>
        !resBpByEntities.find((curBpByEntity) => curBpByEntity.entityId === bpByEntity.entityId) ? [...resBpByEntities, bpByEntity] : resBpByEntities,
      [bpByEntities[0]]
    );
  }
}
