import { Component } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { AbstractTableEngineCellComponent } from '../abstract-table-engine-cell.component';

@Component({
  selector: 'i4b-table-engine-variable-dispatch-cell',
  templateUrl: './variable-dispatch-cell.component.html',
  styleUrls: ['./variable-dispatch-cell.component.scss'],
  imports: [MatTooltip, MatIcon, TranslateModule]
})
export class VariableDispatchCellComponent extends AbstractTableEngineCellComponent<VariableDispatchCellComponent> {}
