<span
  (click)="onClick($event)"
  [matTooltipPosition]="cellOptions()?.tooltipPosition ? cellOptions()?.tooltipPosition : 'above'"
  [matTooltip]="data() | translate"
  [ngClass]="{ visited: cellOptions() && cellOptions()?.linkStyling, link_padding: cellOptions() && cellOptions()?.padding }"
  class="basic-click-cell"
  data-cy="i4b-table-engine-basic-link-cell"
  matTooltipClass="regular-tooltip"
>
  {{ data() | infoDisplay | translate }}
</span>
