import { Component, input, output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TagCategory } from '@iot-platform/models/common';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { UpperCasePipe } from '@angular/common';
import { TagLabelListModule } from '../tag-label-list/tag-label-list.module';

@Component({
    imports: [MatButtonModule, MatIconModule, MatTooltipModule, TranslateModule, FlexLayoutModule, TagLabelListModule, UpperCasePipe],
    selector: 'iot-platform-ui-tag-category-list-by-concept',
    templateUrl: './tag-category-list-by-concept.component.html',
    styleUrls: ['./tag-category-list-by-concept.component.scss']
})
export class TagCategoryListByConceptComponent {
  canManageTags = input<boolean>(false);
  canCreate = input<boolean>(false);
  canUpdate = input<boolean>(false);
  canDelete = input<boolean>(false);
  labelOnly = input<boolean>(false);
  concept = input<string>();
  tagCategoriesByConcept = input<TagCategory[]>();

  manageTags = output<string>();

  onManageTags(): void {
    this.manageTags.emit(this.concept() as string);
  }
}
