import { BaseState, Filter, INITIAL_BASE_STATE, Pagination, DeviceCallLog } from '@iot-platform/models/common';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createFeature, createReducer, on } from '@ngrx/store';
import { DeviceCallLogsActions } from '../actions';

export interface State extends BaseState<DeviceCallLog, Pagination, Filter> {
  deviceCallLogMessage: string;
}

export const adapter: EntityAdapter<DeviceCallLog> = createEntityAdapter<DeviceCallLog>({
  selectId: (entity: DeviceCallLog) => entity.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  ...INITIAL_BASE_STATE,
  deviceCallLogMessage: '',
  pagination: { currentPage: 0, hasMore: false, limit: 1000, maxPage: 0, total: 0 }
});

export const deviceCallLogsFeature = createFeature({
  name: 'deviceCallLogs',
  reducer: createReducer(
    initialState,
    on(DeviceCallLogsActions.loadDeviceCallLogs, (state: State): State => ({ ...state, entity: null })),
    on(DeviceCallLogsActions.loadDeviceCallLogsSuccess, (state: State, { response }): State => adapter.setAll(response, { ...state })),
    on(
      DeviceCallLogsActions.loadDeviceCallLogMessage,
      (state: State, { deviceCallLog: entity }): State => ({ ...state, entity, loading: true, loaded: false })
    ),
    on(
      DeviceCallLogsActions.loadDeviceCallLogMessageSuccess,
      (state: State, { deviceCallLogMessage }): State => ({ ...state, deviceCallLogMessage, loading: false, loaded: true })
    ),
    on(DeviceCallLogsActions.loadDeviceCallLogMessageFailure, (state: State, { error }): State => ({ ...state, error, loading: false, loaded: true }))
  )
});
