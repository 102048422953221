import { IHeaderAngularComp } from '@ag-grid-community/angular';
import { IHeaderParams } from '@ag-grid-community/core';
import { Component, signal, WritableSignal } from '@angular/core';
import { HeaderShellComponent } from '../header-shell/header-shell.component';

@Component({
    selector: 'grid-engine-basic-header-cell',
    templateUrl: './empty-header-cell.component.html',
    imports: [HeaderShellComponent],
    styleUrls: ['./empty-header-cell.component.scss']
})
export class EmptyHeaderCellComponent implements IHeaderAngularComp {
  params: WritableSignal<IHeaderParams> = signal(null);

  agInit(params: IHeaderParams): void {
    this.params.set(params);
  }

  refresh(): boolean {
    return false;
  }
}
