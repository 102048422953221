import { animate, state, style, transition, trigger } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { NgClass, NgTemplateOutlet, UpperCasePipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  effect,
  inject,
  Injector,
  input,
  model,
  OnInit,
  output,
  signal,
  Signal,
  untracked,
  ViewChild,
  ViewContainerRef,
  WritableSignal
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatSort, MatSortHeader, Sort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { GridExportDialogComponent, GridExportService } from '@iot-platform/grid-engine';
import { ConditionProcessorUtil, SortModelAdapter, SortUtil } from '@iot-platform/iot-platform-utils';
import { MasterViewEngineEvent, Pagination } from '@iot-platform/models/common';
import { ExportParams } from '@iot-platform/models/grid-engine';
import { MasterViewBluePrint } from '@iot-platform/models/i4b';
import { TranslateModule } from '@ngx-translate/core';
import { CallToActionCellComponent } from '../../table/cells/call-to-action-cell/call-to-action-cell.component';
import { MasterViewCellContainerComponent } from '../../table/cells/master-view-cell-container.component';
import { MasterViewHeaderContainerComponent } from '../../table/header-cells/master-view-header-container.component';
import { TableFullHeightDirective } from './master-view-table-full-height.directive';

const detailExpand = trigger('detailExpand', [
  state('collapsed', style({ height: '0px', minHeight: '0' })),
  state('expanded', style({ height: '*' })),
  transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
]);

@Component({
  selector: 'i4b-table-engine-master-view-table',
  templateUrl: './master-view-table.component.html',
  styleUrls: ['./master-view-table.component.scss'],
  animations: [detailExpand],
  imports: [
    FlexLayoutModule,
    TranslateModule,
    MatTableModule,
    NgClass,
    MatSort,
    TableFullHeightDirective,
    MatCheckbox,
    MatSortHeader,
    MasterViewHeaderContainerComponent,
    MasterViewCellContainerComponent,
    MatMenuModule,
    MatIcon,
    MatIconButton,
    UpperCasePipe,
    CallToActionCellComponent,
    MatProgressSpinner,
    MatButton,
    NgTemplateOutlet,
    MatPaginator
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MasterViewTableComponent implements OnInit, AfterViewInit {
  @ViewChild('exportViewRef', { read: ViewContainerRef }) private readonly exportViewRef: ViewContainerRef;
  private readonly gridExportService: GridExportService = inject(GridExportService);
  private readonly dialog: MatDialog = inject(MatDialog);
  private readonly injector: Injector = inject(Injector);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  bluePrint = input<MasterViewBluePrint>();
  sticky = input<boolean>(false);
  tableData = input<any>();
  checkedElements = input<any[]>([]);
  useFullyLoadedDataset = input<boolean>(false);
  userPermissions = input<{ key: string; value: boolean }[]>([]);
  selectedElement = model<any>({});
  pendingRequest = input<boolean>(false);
  pageSizeOptions = input<number[]>([]);
  metadata = input<any>();
  withLoadMoreButton = input<boolean>(false);
  loading = input<boolean>(false);
  observerSelectors = input<string[]>([]);

  dispatchCellEvent = output<MasterViewEngineEvent>();
  pageChange = output<Pagination>();
  sortChange = output<Sort>();

  @ViewChild(MatPaginator) fullDatasetPaginator: MatPaginator;
  @ViewChild(MatPaginator) onePageDatasetPaginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  masterViewDataSource: MatTableDataSource<any[]> = new MatTableDataSource<any[]>([]);

  pagination: WritableSignal<Pagination> = signal<Pagination>({
    limit: 10,
    currentPage: 0,
    total: 0,
    maxPage: 0,
    hasMore: false
  });
  columnsToDisplay: Signal<any[]> = computed(() => {
    const bluePrint = this.bluePrint();
    if (bluePrint?.columns) {
      return Object.assign(bluePrint.columns.sort(SortUtil.sortByProperty('order')), []);
    }
    return [];
  });
  columnsToDisplayIds: Signal<string[]> = computed(() => {
    const bluePrint = this.bluePrint();
    let columnsToDisplayIds = [];
    if (bluePrint) {
      const buttonColumn = bluePrint.buttonColumn;
      if (bluePrint.multipleSelection) {
        columnsToDisplayIds = [];
        columnsToDisplayIds.push(bluePrint.selectionColumn.id);
        columnsToDisplayIds = [...columnsToDisplayIds, ...bluePrint.columns.map((column) => column.id).sort(SortUtil.sortByProperty('order'))];
      } else if (bluePrint.columns) {
        columnsToDisplayIds = bluePrint.columns.map((column) => column.id).sort(SortUtil.sortByProperty('order'));
      }
      if (buttonColumn) {
        columnsToDisplayIds.push(buttonColumn.id);
      }
    }
    return columnsToDisplayIds;
  });

  selection = new SelectionModel<any>(true, []);

  isCallToActionVisible: Signal<boolean> = computed(() => {
    const userPermissions = this.userPermissions();
    const bluePrint = this.bluePrint();
    if (userPermissions && bluePrint?.buttonColumn) {
      return ConditionProcessorUtil.processConditionsWithPermission(bluePrint.buttonColumn.visibleConditions, userPermissions);
    }
    return false;
  });

  bulkActionVisibility = computed(() => {
    const userPermissions = this.userPermissions();
    const bluePrint = this.bluePrint();
    if (userPermissions && bluePrint?.multipleSelection && bluePrint?.buttonColumn) {
      return bluePrint.buttonColumn.bulkActions.reduce((acc, value: any) => {
        acc[value.key] = ConditionProcessorUtil.processConditionsWithPermission(value.visibleConditions, userPermissions);
        return acc;
      }, {});
    }
    return false;
  });

  get totalSelected() {
    return this.selection.selected.length;
  }

  ngOnInit() {
    this.tableDataEffects();
    this.checkedElementsEffects();
    this.setPaginator();
    this.masterViewDataSource.sortingDataAccessor = (data, sortHeaderId) => this.getSortingDataAccessor(sortHeaderId, data);
  }

  ngAfterViewInit(): void {
    this.gridExportService.onExport$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((params: ExportParams) => {
      this.openExportForm(params);
    });
    this.gridExportService.setViewRef(this.exportViewRef);
  }

  setPaginator(): void {
    if (this.useFullyLoadedDataset()) {
      setTimeout(() => (this.masterViewDataSource.paginator = this.fullDatasetPaginator));
    }
  }

  getSortingDataAccessor(path: string, data: any): any {
    const result = path.split('.').reduce((acc, value) => {
      if (acc) {
        acc = acc[value];
      }
      return acc;
    }, data);

    if (typeof result === 'string') {
      return result.toLowerCase();
    }
    return result;
  }

  onDispatchEvent(event: MasterViewEngineEvent): void {
    this.dispatchCellEvent.emit(event);
  }

  openDetail(element: any) {
    this.selectedElement.set(element);
    this.dispatchCellEvent.emit({ type: 'open', options: { selected: element }, rawData: element });
  }

  isAllSelected() {
    if (this.masterViewDataSource && this.masterViewDataSource.data.length !== 0) {
      const numSelected = this.selection.selected.length;
      const numRows = this.masterViewDataSource.data.length;
      return numSelected === numRows;
    }
  }

  toggleSelection(element: any) {
    this.selection.toggle(element);
    this.dispatchCellEvent.emit({ type: 'checkElements', rawData: this.selection.selected, options: {} });
  }

  toggleAllSelection() {
    if (this.isAllSelected()) {
      this.selection.clear();
      this.dispatchCellEvent.emit({ type: 'checkElements', rawData: this.selection.selected, options: {} });
    } else {
      this.masterViewDataSource.data.forEach((element: any) => this.selection.select(element));
      this.dispatchCellEvent.emit({ type: 'checkElements', rawData: this.selection.selected, options: {} });
    }
  }

  setCheckedElements(data: any[]) {
    const checkedElements = this.checkedElements();
    if (checkedElements) {
      this.selection.clear();
      data
        .filter((element) => !!checkedElements.find((checked) => checked.id === element.id))
        .forEach((element) => {
          this.selection.select(element);
        });
    }
  }

  isChecked(element: any): boolean {
    return this.checkedElements() ? !!this.checkedElements().filter((checked) => checked.id === element.id).length : false;
  }

  isRowSelected(element: any): boolean {
    return this.selectedElement() ? this.selectedElement().id === element.id : false;
  }

  onSingleActionClick(event: MasterViewEngineEvent): void {
    this.dispatchCellEvent.emit(event);
  }

  onBulkActionClick(type: string): void {
    this.dispatchCellEvent.emit({ type, rawData: this.selection.selected, options: {} });
  }

  onPageChange(event: PageEvent) {
    const next: string = event.pageIndex > event.previousPageIndex ? 'next' : null;
    const prev: string = event.pageIndex < event.previousPageIndex ? 'prev' : null;
    this.pageChange.emit({
      ...this.pagination(),
      limit: event.pageSize,
      currentPage: event.pageIndex,
      prev,
      next
    });
  }

  onSortChange($event: Sort) {
    this.sortChange.emit($event);
  }

  onLoadMore(): void {
    this.dispatchCellEvent.emit({ type: 'loadMore', rawData: null, options: {} });
  }

  private checkedElementsEffects() {
    effect(
      () => {
        const checkedElements = this.checkedElements();
        if (checkedElements) {
          this.selection.clear();
          checkedElements.forEach((element) => {
            this.selection.select(element);
          });
        }
      },
      { injector: this.injector }
    );
  }

  private tableDataEffects() {
    effect(
      () => {
        const tableData = this.tableData();
        if (tableData) {
          untracked(() => {
            this.pagination.update((pagination) => ({
              ...pagination,
              total: tableData.total,
              currentPage: tableData.currentPage,
              hasMore: tableData.hasMore,
              maxPage: tableData.maxPage,
              limit: tableData.limit
            }));
            this.setPaginator();
            this.setCheckedElements(tableData.data);
            setTimeout(() => {
              tableData.initialSort = SortModelAdapter.toTableSortModel(tableData.initialSort);
              if (tableData.initialSort) {
                this.sort.active = tableData.initialSort.active;
                this.sort.direction = tableData.initialSort.direction;
              }
              this.masterViewDataSource.sort = this.sort;
              this.masterViewDataSource.data = tableData.data;
            }, 0);
          });
        }
      },
      { injector: this.injector }
    );
  }

  private openExportForm(params: ExportParams): void {
    this.gridExportService.setPagination(this.pagination());
    this.gridExportService.setRowData([...this.tableData().data]);
    this.gridExportService.setGridMeta(this.metadata());
    this.gridExportService.setParams(params);
    this.dialog.open(GridExportDialogComponent, {
      width: '500px',
      disableClose: true,
      data: { totalElements: params.totalElements }
    });
  }
}
