import { Component, Input } from '@angular/core';
import { Topic } from '@iot-platform/models/ocm';

@Component({
    selector: 'iot4bos-ui-event-topic-list',
    templateUrl: './event-topic-list.component.html',
    styleUrls: ['./event-topic-list.component.scss'],
    standalone: false
})
export class EventTopicListComponent {
  @Input() topics?: Topic[];
}
