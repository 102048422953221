import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component, DestroyRef, inject, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { EmailTemplate } from '@iot-platform/models/common';
import { InfoDisplayPipe } from '@iot-platform/pipes';
import { finalize } from 'rxjs/operators';
import { CustomCellParams } from '../../../models/custom-cell.params';
import { EmailTemplateCellService } from './email-template-cell.service';

@Component({
  selector: 'grid-engine-email-template-cell',
  templateUrl: './email-template-cell.component.html',
  imports: [MatProgressSpinner, InfoDisplayPipe],
  providers: [InfoDisplayPipe],
  styleUrls: ['./email-template-cell.component.scss']
})
export class EmailTemplateCellComponent implements ICellRendererAngularComp {
  private readonly emailTemplateCellService: EmailTemplateCellService = inject(EmailTemplateCellService);
  params: WritableSignal<CustomCellParams> = signal(null);
  templateLoading: WritableSignal<boolean> = signal(false);
  emailTemplate: WritableSignal<EmailTemplate> = signal(null);
  destroyRef: DestroyRef = inject(DestroyRef);

  agInit(params: CustomCellParams): void {
    this.params.set(params);
    if (!!params.value) {
      this.templateLoading.set(true);
      this.emailTemplateCellService
        .getEmailTemplateById(params.value)
        .pipe(
          finalize(() => this.templateLoading.set(false)),
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe((template: EmailTemplate) => {
          this.emailTemplate.set(template);
        });
    }
  }

  refresh(): boolean {
    return false;
  }
}
