import { BaseActions } from '@iot-platform/models/common';
import { OCMDayOff, OCMDayOffType } from '@iot-platform/models/ocm';
import { createActionGroup, props } from '@ngrx/store';

class CalendarsDaysOffBaseActions extends BaseActions<OCMDayOff> {}

const baseActions = new CalendarsDaysOffBaseActions();

export const CalendarsDaysOffActions = createActionGroup({
  source: 'Calendars Days Off',
  events: {
    ...baseActions.getDefaultEvents(),
    'Load Days Off': props<{ calendarId: string }>(),
    'Load Days Off Success': props<{ daysOff: OCMDayOff[] }>(),
    'Load Days Off Failure': props<{ error: unknown }>(),
    'Import Days Off': props<{ sourceCalendarId: string; calendarId: string; dayOffType: OCMDayOffType }>(),
    'Import Days Off Success': props<{ importedDaysOff: OCMDayOff[] }>(),
    'Import Days Off Failure': props<{ error: unknown }>(),
    'Add One': props<{ toAdd: OCMDayOff; calendarId: string }>(),
    'Update One': props<{ toUpdate: OCMDayOff; calendarId: string }>(),
    'Delete One': props<{ toDelete: OCMDayOff; calendarId: string }>()
  }
});
