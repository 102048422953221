import { NgClass, NgStyle } from '@angular/common';
import { Component, computed, effect, OnInit, Signal, signal, WritableSignal } from '@angular/core';
import { DateIntervalUtils } from '@iot-platform/iot-platform-utils';
import { DateFormatPipe } from '@iot-platform/pipes';
import * as moment from 'moment';
import { Moment } from 'moment-timezone';
import { AbstractTableEngineCellComponent } from '../abstract-table-engine-cell.component';

@Component({
  selector: 'i4b-table-engine-date-cell',
  templateUrl: './date-cell.component.html',
  styleUrls: ['./date-cell.component.scss'],
  imports: [NgStyle, NgClass, DateFormatPipe],
  standalone: true
})
export class DateCellComponent extends AbstractTableEngineCellComponent<DateCellComponent> implements OnInit {
  dateFormat: Signal<string> = computed(() => this.cellOptions()?.columnFormat ?? '');
  isRecentDay: WritableSignal<boolean> = signal(false);
  isRecentHour: WritableSignal<boolean> = signal(false);
  isFutureDay: WritableSignal<boolean> = signal(false);
  isNextDay: WritableSignal<boolean> = signal(false);
  removeTimezone: Signal<boolean> = computed(() => this.cellOptions()?.removeTimezone === true);

  ngOnInit() {
    this.processDateIntervalsEffect(moment());
  }

  onClick(event: MouseEvent): void {
    event.stopPropagation();
    const clickEvent = this.clickEvent();
    const rawData = this.rawData();
    if (clickEvent && clickEvent.type) {
      this.dispatchCellEvent({ type: clickEvent.type, options: clickEvent.options, rawData });
    }
  }

  processDateIntervalsEffect(now: Moment): void {
    effect(
      () => {
        const date = this.data();
        this.isRecentDay.set(DateIntervalUtils.isDateInThePastDay(now, date));
        this.isRecentHour.set(DateIntervalUtils.isDateInThePastHour(now, date));
        this.isNextDay.set(DateIntervalUtils.isDateInTheNextDay(now, date));
        this.isFutureDay.set(DateIntervalUtils.isDateInTheFutureDays(now, date));
      },
      { injector: this.injector }
    );
  }
}
