import { Component, inject, input, output } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { ENVIRONMENT } from '@iot-platform/core';
import { DetailPopupModule } from '@iot-platform/iot-platform-ui';
import { Environment, UserAccount } from '@iot-platform/models/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    imports: [TranslateModule, FlexLayoutModule, MatIconModule, MatTooltip, MatButtonModule, DetailPopupModule],
    selector: 'iot-platform-users-user-profile-api-keys',
    templateUrl: './user-profile-api-keys.component.html',
    styleUrls: ['./user-profile-api-keys.component.scss']
})
export class UserProfileApiKeysComponent {
  private environment: Environment = inject(ENVIRONMENT);

  apiKeys = input<any[]>([]);
  maxApiKeysReached = input<boolean>();
  currentUser = input<UserAccount>();

  addApiKey = output<void>();
  getApiKey = output<string>();
  deleteApiKey = output<string>();

  get synapseApiCatalogUrl() {
    return this.environment.synapseApiCatalog;
  }

  onAddApiKey() {
    this.addApiKey.emit();
  }

  onGetApikey(apiKeyIdToGet: string) {
    this.getApiKey.emit(apiKeyIdToGet);
  }

  onDeleteApiKey(apiKeyIdToDelete: string) {
    this.deleteApiKey.emit(apiKeyIdToDelete);
  }
}
