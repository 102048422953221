import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Log } from '@iot-platform/models/i4b';
import { DateFormatPipe } from '@iot-platform/pipes';
import { TranslateModule } from '@ngx-translate/core';
import * as moment from 'moment';

@Component({
  imports: [FlexLayoutModule, MatIconModule, DateFormatPipe, TranslateModule, MatTooltipModule],
  selector: 'iot4bos-ui-end-maintenance-comment',
  templateUrl: './end-maintenance-comment.component.html',
  styleUrls: ['./end-maintenance-comment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EndMaintenanceCommentComponent {
  log = null;
  isMaintenanceEnd = false;

  @Input() set comment(value: Log) {
    this.log = value;
    this.isMaintenanceEnd = moment(value?.datetime).isBefore(moment());
  }
}
