import { UpperCasePipe } from '@angular/common';
import { Component, computed, DestroyRef, inject, signal, Signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AbstractControl, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatOptionModule } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatStepperModule } from '@angular/material/stepper';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AuthFacade } from '@iot-platform/auth';
import { CardLoaderModule, ChipModule, DetailPopupModule } from '@iot-platform/iot-platform-ui';
import { SortUtil } from '@iot-platform/iot-platform-utils';
import { Entity } from '@iot-platform/models/common';
import { Asset, AssetModel, AssetTemplate, AssetTemplateVariable, AssetVariable, Site } from '@iot-platform/models/i4b';
import { AssetsService } from '@iot-platform/shared/services';
import { TranslateModule } from '@ngx-translate/core';
import { finalize, map } from 'rxjs';
import { AssetModelFormComponent } from '../asset-model-form/asset-model-form.component';
import { DateFormatPipe, InfoDisplayPipe, TruncatePipe } from '@iot-platform/pipes';

@Component({
    imports: [
        TranslateModule,
        FlexLayoutModule,
        UpperCasePipe,
        InfoDisplayPipe,
        TruncatePipe,
        DateFormatPipe,
        ChipModule,
        DetailPopupModule,
        ReactiveFormsModule,
        CardLoaderModule,
        MatCardModule,
        MatIconModule,
        MatToolbarModule,
        MatStepperModule,
        MatFormFieldModule,
        MatOptionModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        MatInputModule,
        MatChipsModule,
        AssetModelFormComponent
    ],
    providers: [UpperCasePipe, InfoDisplayPipe, TruncatePipe, DateFormatPipe],
    selector: 'iot4bos-ui-create-asset-from-template-dialog',
    templateUrl: './create-asset-from-template-dialog.component.html',
    styleUrl: './create-asset-from-template-dialog.component.scss'
})
export class CreateAssetFromTemplateDialogComponent {
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  private readonly assetsService: AssetsService = inject(AssetsService);
  private readonly authFacade: AuthFacade = inject(AuthFacade);
  readonly data: { site: Site } = inject(MAT_DIALOG_DATA);
  readonly dialogRef: MatDialogRef<CreateAssetFromTemplateDialogComponent> = inject(MatDialogRef<CreateAssetFromTemplateDialogComponent>);

  firstStepForm!: UntypedFormGroup;
  secondStepForm!: UntypedFormGroup;
  templates: WritableSignal<AssetTemplate[] | undefined> = signal([]);
  templatesLoading: WritableSignal<boolean> = signal(false);
  templateFollowedVariables: WritableSignal<AssetTemplateVariable[]> = signal([]);
  filterValueChanges!: Signal<string | undefined>;
  filteredTemplates: Signal<AssetTemplate[] | undefined> = computed(() => {
    const templates = this.templates();
    const serachKey = this.filterValueChanges();
    if (templates && serachKey) {
      return templates.filter(
        (template) => template.name.toLowerCase().includes(serachKey.toLowerCase()) || template.description.toLowerCase().includes(serachKey.toLowerCase())
      );
    }
    return templates;
  });
  assets: WritableSignal<Asset[] | undefined> = signal([]);
  templateMissingMandatoryTags: WritableSignal<boolean> = signal(false);
  duplicatedAsset: WritableSignal<boolean> = signal(false);
  dateFormat: Signal<string | undefined> = computed(() => {
    const user = this.authFacade.currentUser();
    return user.preferences.appDateFormats?.momentNoTime;
  });

  constructor() {
    this.initFirstStepForm();
    this.initSecondStepForm();
    this.initTemplatesFilter();
  }

  get model(): AbstractControl {
    return this.firstStepForm.get('model') as AbstractControl;
  }

  get entity(): AbstractControl {
    return this.firstStepForm.get('entity') as AbstractControl;
  }

  get site(): AbstractControl {
    return this.firstStepForm.get('site') as AbstractControl;
  }

  get template(): AbstractControl {
    return this.secondStepForm.get('template') as AbstractControl;
  }

  get filter(): AbstractControl {
    return this.secondStepForm.get('filter') as AbstractControl;
  }

  onStepChange(step: number): void {
    if (step === 1) {
      this.initTemplates();
      this.initAssets();
    }
  }

  onAssetModelFilled(model: { model: AssetModel; entity: Entity; site: Site } | null): void {
    this.model.setValue(model?.model);
    this.entity.setValue(model?.entity);
    this.site.setValue(model?.site);
  }

  onTemplateSelectionChange(assetTemplate: AssetTemplate): void {
    this.checkIfTemplateMissingMandatoryTags(assetTemplate.id as string, assetTemplate.entity.id as string);
    this.duplicatedAsset.set(this.isAssetDuplicated(assetTemplate.template.name.toLowerCase()));
    this.templateFollowedVariables.set(assetTemplate.template.variables.filter((v) => !!v.followedNumber).sort(SortUtil.sortByProperty('followedNumber')));
    assetTemplate.template.variables.sort(SortUtil.sortByName);
    this.template.setValue(assetTemplate);
  }

  save(): void {
    this.dialogRef.close({
      siteId: this.site.getRawValue()?.id,
      templateId: this.template.getRawValue()?.id,
      configureVariablesAfterCreation: !!this.template.getRawValue()?.template.variables?.find((v: AssetVariable) => !!v.formula)
    });
  }

  private initFirstStepForm() {
    this.firstStepForm = new UntypedFormGroup({
      model: new UntypedFormControl(null, [Validators.required]),
      entity: new UntypedFormControl(null, [Validators.required]),
      site: new UntypedFormControl(null, [Validators.required])
    });
  }

  private initSecondStepForm() {
    this.secondStepForm = new UntypedFormGroup({
      template: new UntypedFormControl(null, [Validators.required]),
      filter: new UntypedFormControl(null, [])
    });
  }

  private initTemplates(): void {
    this.templatesLoading.set(true);
    this.assetsService
      .getAssetTemplatesVisibleByEntityId(this.entity.getRawValue().id as string)
      .pipe(
        map((templates: AssetTemplate[]) => templates.filter((elem: AssetTemplate) => elem.category === this.model.getRawValue().name)),
        finalize(() => this.templatesLoading.set(false)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((templates: AssetTemplate[]) => this.templates.set(templates));
  }

  private initTemplatesFilter() {
    this.filterValueChanges = toSignal(this.filter.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)));
  }

  private checkIfTemplateMissingMandatoryTags(assetTemplateId: string, entityId: string): void {
    this.assetsService
      .checkIfTemplateMissingMandatoryTags(assetTemplateId, entityId)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value: boolean) => this.templateMissingMandatoryTags.set(value));
  }

  private initAssets(): void {
    this.assetsService
      .getManyBySiteId(this.site.getRawValue().id as string)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((assets: Asset[]) => this.assets.set(assets));
  }

  private isAssetDuplicated(name: string): boolean {
    return !!this.assets()?.find((asset: Asset) => asset.name?.toLowerCase() === name.toLowerCase());
  }
}
