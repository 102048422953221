<div class="ag-theme-material grid-wrapper">
  <ag-grid-angular
    (firstDataRendered)="onFirstDataRendered()"
    (gridReady)="onGridReady($event)"
    (newColumnsLoaded)="onGridColumnsChanged()"
    (rowClicked)="onRowClicked($event)"
    (rowSelected)="onRowSelected($event)"
    (selectionChanged)="onSelectionChanged($event)"
    (sortChanged)="onSortChanged($event)"
    [accentedSort]="true"
    [colResizeDefault]="'shift'"
    [columnDefs]="columnDefs()"
    [debug]="false"
    [defaultColDef]="defaultColDef"
    [getLocaleText]="getLocaleText"
    [gridOptions]="gridOptions"
    [navigateToNextCell]="navigateToNextCell"
    [navigateToNextHeader]="navigateToNextHeader"
    [rowClassRules]="rowClassRules()"
    [rowData]="rowData()"
    [sideBar]="sideBar"
    [tabToNextCell]="tabToNextCell"
    [tabToNextHeader]="tabToNextHeader"
    style="flex: 1"
  />
  @if (displayPagination()) {
    <grid-engine-paginator (pageChange)="onPageChange($event)" [pagination]="pagination()" />
  }
</div>
<ng-template #exportViewRef />
