@if (device()) {
  <div class="device-info-wrapper">
    <iot-platform-ui-overview-card [overviewCard]="overviewCard()" [loading]="!site()" (cardButtonClicked)="onCardActions($event)">
      <ng-container cardNameMeta>
        <iot-platform-ui-event-severity-display [severity]="device()?.status?.name" [tooltip]="statusTooltip()" />
      </ng-container>
    </iot-platform-ui-overview-card>

    <div class="device-info-status-area" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="20px">
      <iot-platform-ui-device-latest-command-status [device]="device()" />
      <iot-platform-ui-device-info-status [device]="device()" />
    </div>
  </div>
}
