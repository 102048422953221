import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { AuthorizationConcept, AuthorizationService, AuthorizationType, fromAuth } from '@iot-platform/auth';
import { AnalyticsService } from '@iot-platform/core';
import { GridExportService } from '@iot-platform/grid-engine';
import {
  ADD_BUTTON_CONFIG,
  EXPORT_BUTTON_CONFIG,
  FilterEngineMode,
  IotToolbarDefaultButton,
  IotToolbarDispatchActionType,
  PopupComponent,
  REFRESH_BUTTON_CONFIG,
  TOGGLE_CATALOGS_BUTTON_CONFIG,
  TOGGLE_FILTER_ENGINE_BUTTON_CONFIG
} from '@iot-platform/iot-platform-ui';
import {
  FavoriteView,
  Filter,
  IotToolbarEvent,
  MasterViewEngineEvent,
  Pagination,
  PlatformRequest,
  PlatformResponse,
  Product,
  ProductCatalog,
  ToolbarSize,
  UserAccount
} from '@iot-platform/models/common';
import { fromUserPreferences, PreferencesActions, UserPreferencesService } from '@iot-platform/users';
import { Store } from '@ngrx/store';
import { get } from 'lodash';
import { combineLatest, Observable, of, Subject, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AdminCatalogsEditionPopupComponent } from '../../components/admin-catalogs-edition-popup/admin-catalogs-edition-popup.component';
import { AdminProductCreationFormComponent } from '../../components/admin-product-creation-form/admin-product-creation-form.component';
import { AdminProductsService } from '../../services/admin-products.service';
import { ProductCatalogsUiActions, ProductsUiActions } from '../../state/actions';
import * as fromProducts from '../../state/reducers';
import { ProductsCustomExportWrapper } from './products-custom-export-wrapper';

@Component({
    selector: 'iot4bos-ui-backoffice-admin-product-catalogs-shell',
    templateUrl: './admin-product-catalogs-shell.component.html',
    styleUrls: ['./admin-product-catalogs-shell.component.scss'],
    standalone: false
})
export class AdminProductCatalogsShellComponent implements OnInit, OnDestroy {
  analytics: AnalyticsService = new AnalyticsService('admin_product_catalogs_shell');

  adminProductsCatalogsButtonList!: IotToolbarDefaultButton[];
  totalProducts$: Observable<number>;
  productsAsPlatformResponse$: Observable<PlatformResponse>;
  productsAsPlatformResponse: PlatformResponse;
  catalogs$: Observable<ProductCatalog[]>;
  catalogs: ProductCatalog[] = [];
  allProducts: Product[] = [];
  productsLoaded$ = this.store.select(fromProducts.getProductsLoaded);
  pageSize: number;
  currentPage: number;
  tokenNext: string;
  tokenPrev: string;

  currentFilters: Filter[] = [];
  currentFilters$: Observable<Filter[]>;
  currentFavoriteView$: Observable<FavoriteView> = of(null);
  filterEngineOpened = false;

  catalogPanelOpened = false;

  userPermissions: any;
  canCreateProduct: boolean;
  canUpdateProduct: boolean;
  canDeleteProduct: boolean;

  subscriptions: Subscription[] = [];
  businessProfile$ = this.store.select(fromAuth.selectSelectedBusinessProfileForAccount);
  mvSettings$: Observable<any>;

  products$ = new Subject<PlatformResponse>();
  maxPage: number;
  user!: UserAccount;
  toolbarSize: string = ToolbarSize.SMALL;
  FilterEngineMode = FilterEngineMode;

  @ViewChild('sidenav', { static: true }) sidenav: MatSidenav;

  constructor(
    private readonly dialog: MatDialog,
    private readonly authz: AuthorizationService,
    private readonly store: Store,
    private readonly userPrefService: UserPreferencesService,
    private readonly gridExportService: GridExportService,
    private readonly adminProductsService: AdminProductsService
  ) {
    this.canCreateProduct = this.authz.applyAuthorization(AuthorizationConcept.PRODUCT_CATALOG, AuthorizationType.CREATE);
    this.canUpdateProduct = this.authz.applyAuthorization(AuthorizationConcept.PRODUCT_CATALOG, AuthorizationType.UPDATE);
    this.canDeleteProduct = this.authz.applyAuthorization(AuthorizationConcept.PRODUCT_CATALOG, AuthorizationType.DELETE);
    this.userPermissions = [
      { key: 'canCreateProduct', value: this.canCreateProduct },
      { key: 'canUpdateProduct', value: this.canUpdateProduct },
      { key: 'canDeleteProduct', value: this.canDeleteProduct }
    ];

    this.initToolbarButtonList();
  }

  ngOnInit(): void {
    this.productsAsPlatformResponse$ = this.store.select(fromProducts.getProductsAsPlatformResponse);
    this.totalProducts$ = this.store.select(fromProducts.getFilteredProductTotal);
    this.catalogs$ = this.store.select(fromProducts.getAllCatalogs);
    this.currentFilters$ = this.store.select(fromProducts.getProductFilters);
    this.mvSettings$ = this.businessProfile$.pipe(
      switchMap((businessProfile) => {
        if (businessProfile) {
          return this.userPrefService.loadSpecificDefaultSettingsWithDefaultColumnsOnly('productCatalogs').pipe(
            map((settings) => ({
              ...settings,
              metadata: {
                url: '/products'
              }
            }))
          );
        } else {
          return of(null);
        }
      })
    );
    this.subscriptions.push(
      this.store.select(fromProducts.getAllCatalogs).subscribe((cats) => (this.catalogs = cats)),
      this.productsLoaded$.subscribe((dataLoaded: boolean) => {
        this.adminProductsCatalogsButtonList.map((button) => {
          if (
            button.dispatchAction.type === IotToolbarDispatchActionType.REFRESH_PAGE ||
            button.dispatchAction.type === IotToolbarDispatchActionType.TOGGLE_CATALOGS
          ) {
            button.disabled = !dataLoaded;
          }
          return button;
        });
      }),
      this.store.select(fromUserPreferences.getCurrentUser).subscribe((user) => {
        if (user && user.id) {
          this.user = user;
          this.filterEngineOpened = get(user, 'preferences.filterEngineOpenByDefault', false);
        }
      })
    );
    this.reloadOnFilterChange();
    this.addPaginationInfo();
  }

  initToolbarButtonList(): void {
    this.adminProductsCatalogsButtonList = [
      new IotToolbarDefaultButton({ ...TOGGLE_CATALOGS_BUTTON_CONFIG }, 0),
      new IotToolbarDefaultButton({ ...ADD_BUTTON_CONFIG, displayButton: this.canCreateProduct }, 1),
      new IotToolbarDefaultButton(REFRESH_BUTTON_CONFIG, 4),
      new IotToolbarDefaultButton(EXPORT_BUTTON_CONFIG, 5),
      new IotToolbarDefaultButton(TOGGLE_FILTER_ENGINE_BUTTON_CONFIG, 3)
    ];
  }

  reloadOnFilterChange() {
    this.subscriptions.push(
      combineLatest([this.businessProfile$, this.store.select(fromProducts.getProductFilters)]).subscribe(([businessProfile, filters]) => {
        if (businessProfile) {
          this.currentFilters = filters;
          this.reLoadMasterView();
        }
        this.adminProductsCatalogsButtonList.map((button) => {
          if (button.dispatchAction.type === IotToolbarDispatchActionType.CLEAR_FILTERS_AND_RELOAD_DATA) {
            button.disabled = !this.currentFilters.length;
          }
          return button;
        });
      })
    );
  }

  addPaginationInfo() {
    this.subscriptions.push(
      this.productsAsPlatformResponse$.subscribe((pR) => {
        this.productsAsPlatformResponse = pR;
        this.maxPage = Math.ceil(pR.data.length / 100) - 1;
        this.products$.next({ ...pR, data: pR.data.slice(0, 100), currentPage: 0, maxPage: this.maxPage });
        if (pR) {
          this.allProducts = pR.data;
        }
      })
    );
  }

  onToolbarEvent(event: IotToolbarEvent) {
    switch (event.type) {
      case IotToolbarDispatchActionType.ADD_ELEMENT:
        this.addProduct();
        break;
      case IotToolbarDispatchActionType.EXPORT_DATA:
        this.onExportData();
        break;
      case IotToolbarDispatchActionType.REFRESH_PAGE:
        this.onRefreshClicked();
        break;
      case IotToolbarDispatchActionType.TOGGLE_FILTER_ENGINE:
        this.onShowFilter();
        break;
      case IotToolbarDispatchActionType.TOGGLE_CATALOGS:
        this.onShowCatalogs();
        break;
      default:
        break;
    }
  }

  addProduct(): void {
    this.analytics.log('toolbar_actions', 'open_add_product');
    this.dialog
      .open(AdminProductCreationFormComponent, {
        width: '500px',
        disableClose: true,
        data: { product: null, catalogs: this.catalogs, allProducts: this.allProducts }
      })
      .afterClosed()
      .subscribe((productToAdd) => {
        if (productToAdd) {
          this.analytics.log('toolbar_actions', 'add_product');
          this.store.dispatch(ProductsUiActions.addProduct({ productToAdd }));
        }
      });
  }

  onExportData(): void {
    this.analytics.log('toolbar_actions', 'export_data');
    const request: PlatformRequest = {
      page: 0,
      limit: 1000,
      filters: this.currentFilters
    };
    const products$: Observable<PlatformResponse> = this.adminProductsService.getAllProducts(request);
    this.gridExportService.export({
      filters: this.currentFilters,
      customExportWrapper: new ProductsCustomExportWrapper([...this.catalogs], products$)
    });
  }

  onShowFilter() {
    this.analytics.log('toolbar_actions', this.filterEngineOpened ? 'close_filter_engine' : 'open_filter_engine');
    if (this.user) {
      this.filterEngineOpened = !this.filterEngineOpened;
      this.user.preferences = { ...this.user.preferences, filterEngineOpenByDefault: this.filterEngineOpened };
      this.store.dispatch(
        PreferencesActions.saveUserPreferences({
          user: this.user,
          preferences: this.user.preferences
        })
      );
    }
  }

  onShowCatalogs() {
    this.analytics.log('toolbar_actions', this.catalogPanelOpened ? 'close_catalog_panel' : 'open_catalog_panel');
    if (this.catalogPanelOpened) {
      this.sidenav.close();
    } else {
      this.sidenav.open();
    }
    this.catalogPanelOpened = !this.catalogPanelOpened;
  }

  onRefreshClicked(): void {
    this.analytics.log('toolbar_actions', 'refresh_data');
    this.reLoadMasterView();
  }

  reLoadMasterView(): void {
    const request: PlatformRequest = {
      page: 0,
      limit: 100,
      filters: this.currentFilters
    };
    this.store.dispatch(ProductsUiActions.listProducts({ request }));
    this.store.dispatch(ProductCatalogsUiActions.listProductCatalogs({ request }));
  }

  onApplyFilters(filters: Filter[]) {
    const gaLog = Object.entries(filters)
      .map(([_, value]) => `${value.criteriaKey}`)
      .join(', ');
    this.analytics.log('filters_actions', 'apply_filters', `Applied filters : ${gaLog}`);
    this.currentFilters = filters;
    this.store.dispatch(ProductsUiActions.saveProductFilters({ filters }));
  }

  onEventDispatched(event: MasterViewEngineEvent) {
    switch (event.type) {
      case 'navigate':
        break;
      case 'edit':
        this.editProduct(event.rawData);
        break;
      case 'delete':
        this.deleteProduct(event.rawData);
        break;
      default:
        break;
    }
  }

  editProduct(product: Product): void {
    this.analytics.log('grid_actions', 'open_edit_product');
    this.dialog
      .open(AdminProductCreationFormComponent, {
        width: '500px',
        disableClose: true,
        data: { product, catalogs: this.catalogs, allProducts: this.allProducts }
      })
      .afterClosed()
      .subscribe((productToUpdate) => {
        if (productToUpdate) {
          this.analytics.log('grid_actions', 'update_product');
          this.store.dispatch(ProductsUiActions.updateProduct({ productToUpdate }));
        }
      });
  }

  deleteProduct(productToDelete: Product): void {
    this.analytics.log('grid_actions', 'open_delete_product');
    this.dialog
      .open(PopupComponent, {
        width: '500px',
        disableClose: true,
        data: { type: 'delete', value: productToDelete.identifier + ' - ' + productToDelete.name }
      })
      .afterClosed()
      .subscribe((validation: boolean) => {
        if (validation) {
          this.analytics.log('grid_actions', 'delete_product');
          this.store.dispatch(ProductsUiActions.deleteProduct({ productToDelete }));
        }
      });
  }

  onEditCatalogs(): void {
    this.analytics.log('catalog_panel_actions', 'open_edit_catalog');
    this.dialog.open(AdminCatalogsEditionPopupComponent, {
      width: '1000px',
      disableClose: true,
      data: { canUpdate: this.canUpdateProduct, canDelete: this.canDeleteProduct }
    });
  }

  onPageChange({ currentPage, limit }: Pagination) {
    this.products$.next({
      ...this.productsAsPlatformResponse,
      data: this.productsAsPlatformResponse.data.slice(currentPage * limit, (currentPage + 1) * limit),
      total: this.productsAsPlatformResponse.data.length,
      hasMore: currentPage < this.maxPage,
      currentPage,
      maxPage: this.maxPage
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }
}
