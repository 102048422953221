import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { CustomEncoder, ENVIRONMENT } from '@iot-platform/core';
import { ApiHelpers } from '@iot-platform/iot-platform-utils';

import {
  BaseUser,
  BusinessProfile,
  CommonApiListResponse,
  CommonApiRequest,
  CommonApiResponse,
  Environment,
  FavoriteView,
  Pagination,
  PlatformRequest,
  PlatformResponse,
  Role,
  TagCategory
} from '@iot-platform/models/common';
import { I4BGrid, I4BGridData, I4BGridOptions } from '@iot-platform/models/grid-engine';
import * as moment from 'moment';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BusinessProfilesService {
  private environment: Environment = inject(ENVIRONMENT);
  private http: HttpClient = inject(HttpClient);

  public getBusinessProfiles(): Observable<BusinessProfile[]> {
    return this.http
      .get<{
        page: any;
        content: any;
      }>(`${this.environment.api.url}/business-profiles`)
      .pipe(map((response) => response.content));
  }

  public getBusinessProfilesWithParams(request: PlatformRequest): Observable<PlatformResponse> {
    let params: HttpParams = new HttpParams({ encoder: new CustomEncoder() });
    params = params.set('limit', request.limit.toString(10));
    params = params.set('page', request.page.toString(10));

    if (request.filters) {
      request.filters.forEach((filter) => {
        params = params.append(filter.criteriaKey, filter.value);
      });
    }

    return this.http.get<{ page: any; content: any }>(`${this.environment.api.url}/business-profiles`, { params }).pipe(
      map((data: any) => ({
        data: data.content,
        currentPage: data.page.curPage,
        hasMore: data.page.hasMore,
        limit: data.page.limit,
        maxPage: data.page.maxPage,
        total: data.page.total
      }))
    );
  }

  isNameUnique(initialName: string, entityId: string): Observable<boolean> {
    let params: HttpParams = new HttpParams({ encoder: new CustomEncoder() });
    params = params.set('page', '0');
    params = params.append('name', initialName);
    params = params.append('entityId', entityId);
    return this.http
      .get<CommonApiListResponse<BusinessProfile>>(`${this.environment.api.url}${this.environment.api.endpoints?.businessProfiles}`, { params })
      .pipe(map((data: CommonApiListResponse<BusinessProfile>) => data.page.total === 0));
  }

  public getBusinessProfileById(businessProfiledId: string): Observable<BusinessProfile> {
    return this.http.get<BusinessProfile>(`${this.environment.api.url}${this.environment.api.endpoints.businessProfiles}/${businessProfiledId}`).pipe(
      map((bp) => {
        const zone = bp.timezoneDetails && bp.timezoneDetails.name ? moment().tz(bp.timezoneDetails.name).format('Z') : '';
        const updatedBP: BusinessProfile = { ...bp, timezoneDetails: { ...bp.timezoneDetails, offset: zone } };
        return updatedBP;
      })
    );
  }

  public saveBusinessProfile(newProfile: BusinessProfile): Observable<BusinessProfile> {
    return this.http.post<BusinessProfile>(`${this.environment.api.url}/business-profiles/`, newProfile);
  }

  public updateBusinessProfile(updatedProfile: BusinessProfile): Observable<BusinessProfile> {
    return this.http.put<BusinessProfile>(`${this.environment.api.url}/business-profiles/${updatedProfile.id}`, {
      name: updatedProfile.name,
      chartPeriod: updatedProfile.chartPeriod,
      timeseriesDisplayMode: updatedProfile.timeseriesDisplayMode,
      timezoneDetails: updatedProfile.timezoneDetails,
      siteStocksVisible: updatedProfile.siteStocksVisible
    });
  }

  public deleteBusinessProfile(businessProfileToDelete: BusinessProfile): Observable<BusinessProfile> {
    return this.http.delete(`${this.environment.api.url}/business-profiles/${businessProfileToDelete.id}`).pipe(map((_) => businessProfileToDelete));
  }

  public loadRolesByBusinessProfileId(businessProfileId: string): Observable<Role[]> {
    return this.http
      .get<{ page: any; content: any }>(`${this.environment.api.url}/roles?businessProfileId=${businessProfileId}`)
      .pipe(map((response) => response.content));
  }

  public loadTagsByBusinessProfileId(businessProfileId: string): Observable<TagCategory[]> {
    return this.http
      .get<{ page: any; content: any }>(`${this.environment.api.url}/business-profiles/${businessProfileId}/tags`)
      .pipe(map((response) => (response && response.content ? response.content : [])));
  }

  getRolesByOrganization(organizationId: string): Observable<Role[]> {
    return this.http
      .get<{
        page: any;
        content: any;
      }>(`${this.environment.api.url}/roles?entityId=${organizationId}`)
      .pipe(map((response) => response.content));
  }

  public loadMembersByBusinessProfileId(businessProfileId: string): Observable<BaseUser[]> {
    return this.http
      .get<{ page: any; content: any }>(`${this.environment.api.url}/users?businessProfileId=${businessProfileId}`)
      .pipe(map((response) => response.content));
  }

  public addRoleToBusinessProfile(businessProfileId: string, roleToAdd: Role): Observable<Role> {
    return this.http.post<Role>(`${this.environment.api.url}/business-profiles/${businessProfileId}/roles/${roleToAdd.id}`, {}).pipe(map((_) => roleToAdd));
  }

  public removeRoleFromBusinessProfile(businessProfileId: string, roleToDelete: Role): Observable<Role> {
    return this.http.delete(`${this.environment.api.url}/business-profiles/${businessProfileId}/roles/${roleToDelete.id}`).pipe(map((_) => roleToDelete));
  }

  public addMemberToBusinessProfile(businessProfileId: string, memberToAdd: BaseUser): Observable<BaseUser> {
    return this.http
      .post<BaseUser>(`${this.environment.api.url}/business-profiles/${businessProfileId}/users/${memberToAdd.id}`, {})
      .pipe(map((_) => memberToAdd));
  }

  public removeMemberFromBusinessProfile(businessProfileId: string, memberToDelete: BaseUser): Observable<BaseUser> {
    return this.http.delete(`${this.environment.api.url}/business-profiles/${businessProfileId}/users/${memberToDelete.id}`).pipe(map((_) => memberToDelete));
  }

  public updateTagsForBusinessProfile(businessProfileId: string, tagsToAdd: TagCategory[]): Observable<TagCategory[]> {
    return this.http
      .put<{ page: any; content: any }>(`${this.environment.api.url}/business-profiles/${businessProfileId}/tags`, {
        tags: tagsToAdd
      })
      .pipe(map((response) => response.content));
  }

  public loadFavoriteViewsByBusinessProfileId(businessProfileId: string): Observable<CommonApiResponse<FavoriteView, Pagination>> {
    return this.http
      .get<CommonApiListResponse<FavoriteView>>(`${this.environment.api.url_v2}/business-profiles/${businessProfileId}/favorite-views`)
      .pipe(map((data) => ApiHelpers.getHttpResponse<FavoriteView>(data)));
  }

  public loadGridsByBusinessProfileId(request: CommonApiRequest): Observable<CommonApiResponse<I4BGrid<I4BGridOptions, I4BGridData>, Pagination>> {
    const params: HttpParams = ApiHelpers.getHttpParams(request);
    return this.http
      .get<CommonApiListResponse<I4BGrid<I4BGridOptions, I4BGridData>>>(`${this.environment.api.url}${this.environment.api.endpoints.grids}`, { params })
      .pipe(
        map((response: CommonApiListResponse<I4BGrid<I4BGridOptions, I4BGridData>>) =>
          ApiHelpers.getHttpResponse<I4BGrid<I4BGridOptions, I4BGridData>>(response)
        )
      );
  }

  public getGridNameById(masterView: string, gridId: string): Observable<string> {
    return this.http
      .get<I4BGrid<I4BGridOptions, I4BGridData>>(`${this.environment.api.url}${this.environment.api.endpoints.grids}/${masterView}/${gridId}`)
      .pipe(map((g) => g.name));
  }

  public getGridsByMasterView(masterView: string): Observable<I4BGrid<I4BGridOptions, I4BGridData>[]> {
    return this.http
      .get<CommonApiListResponse<I4BGrid<I4BGridOptions, I4BGridData>>>(`${this.environment.api.url}${this.environment.api.endpoints.grids}/${masterView}`)
      .pipe(map((response) => response.content));
  }

  public addGrid(grid: I4BGrid<I4BGridOptions, I4BGridData>): Observable<I4BGrid<I4BGridOptions, I4BGridData>> {
    return this.http.post<I4BGrid<I4BGridOptions, I4BGridData>>(`${this.environment.api.url}${this.environment.api.endpoints.grids}/${grid.masterview}`, grid);
  }

  public updateGrid(grid: I4BGrid<I4BGridOptions, I4BGridData>): Observable<I4BGrid<I4BGridOptions, I4BGridData>> {
    return this.http.put<I4BGrid<I4BGridOptions, I4BGridData>>(
      `${this.environment.api.url}${this.environment.api.endpoints.grids}/${grid.masterview}/${grid.id}`,
      grid
    );
  }

  public deleteGrid(grid: I4BGrid<I4BGridOptions, I4BGridData>): Observable<I4BGrid<I4BGridOptions, I4BGridData>> {
    return this.http
      .delete<I4BGrid<I4BGridOptions, I4BGridData>>(`${this.environment.api.url}${this.environment.api.endpoints.grids}/${grid.masterview}/${grid.id}`)
      .pipe(map(() => grid));
  }
}
