import { UpperCasePipe } from '@angular/common';
import { Component, DestroyRef, inject, Signal, ViewEncapsulation } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { ReleaseNotesService } from './services/release-notes.service';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';

@Component({
    imports: [MatCardModule, MatToolbarModule, MatProgressBarModule, UpperCasePipe, MatButtonModule, FlexLayoutModule, MatIconModule, TranslateModule],
    selector: 'iot-platform-ui-release-notes',
    templateUrl: './release-notes.component.html',
    styleUrls: ['./release-notes.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ReleaseNotesComponent {
  public readonly data: { appName: string } = inject(MAT_DIALOG_DATA);
  private readonly dialogRef: MatDialogRef<ReleaseNotesComponent> = inject(MatDialogRef);
  private readonly releaseNotesService: ReleaseNotesService = inject(ReleaseNotesService);
  private readonly sanitizer: DomSanitizer = inject(DomSanitizer);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  currentVersion: Signal<string | undefined> = toSignal(this.releaseNotesService.getCurrentVersion().pipe(takeUntilDestroyed(this.destroyRef)));
  releaseNote: Signal<SafeHtml | undefined> = toSignal(
    this.releaseNotesService.getReleaseNotes().pipe(
      map((unsafeHtml: string) => this.sanitizer.bypassSecurityTrustHtml(unsafeHtml)),
      takeUntilDestroyed(this.destroyRef)
    )
  );

  close() {
    this.dialogRef.close();
  }
}
