import { UpperCasePipe } from '@angular/common';
import { Component, input, output } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIcon } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TagCategoryListByConceptModule } from '@iot-platform/iot-platform-ui';
import { TagCategory } from '@iot-platform/models/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  imports: [
    FlexLayoutModule,
    MatToolbarModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatMenuModule,
    MatCardModule,
    TagCategoryListByConceptModule,
    MatIcon,
    TranslateModule,
    UpperCasePipe
  ],
  selector: 'iot4bos-ui-backoffice-business-profile-tags',
  templateUrl: './business-profile-tags.component.html',
  styleUrls: ['./business-profile-tags.component.scss']
})
export class BusinessProfileTagsComponent {
  canUpdateBusinessProfile = input<boolean>(false);
  tags = input<TagCategory[]>([]);
  tagsPendingStatus = input<boolean>(false);

  addTagsToProfile = output<string>();

  menuItems = [
    { concept: 'site', translationKey: 'MANAGE_TAGS_FORM.SITE' },
    { concept: 'asset', translationKey: 'MANAGE_TAGS_FORM.ASSET' },
    { concept: 'device', translationKey: 'MANAGE_TAGS_FORM.DEVICE' },
    { concept: 'event', translationKey: 'MANAGE_TAGS_FORM.EVENT' }
  ];

  conceptList = ['site', 'asset', 'device', 'event'];

  getTagsTotal(tags: TagCategory[]) {
    return tags.reduce((acc, value) => (acc = acc + value.labels.length), 0);
  }

  getTagCategoriesByConcept(concept: string): TagCategory[] {
    return this.tags().filter((t) => t.concept.toLowerCase() === concept);
  }

  onAddTagsToProfile(concept: string) {
    this.addTagsToProfile.emit(concept);
  }
}
