import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component, signal, WritableSignal } from '@angular/core';
import { NumberFormatPipe } from '@iot-platform/pipes';
import { CustomCellParams } from '../../../models/custom-cell.params';

@Component({
    imports: [NumberFormatPipe],
    providers: [NumberFormatPipe],
    selector: 'grid-engine-asset-variable-threshold-cell',
    templateUrl: './asset-variable-threshold-cell.component.html',
    styleUrls: ['./asset-variable-threshold-cell.component.scss']
})
export class AssetVariableThresholdCellComponent implements ICellRendererAngularComp {
  params: WritableSignal<CustomCellParams> = signal(null);

  agInit(params: CustomCellParams): void {
    this.params.set(params);
  }

  refresh(): boolean {
    return false;
  }
}
