import { Component, effect, input, output, signal, ViewEncapsulation, WritableSignal } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepicker, MatDatepickerInput, MatDatepickerToggle } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import * as moment from 'moment';

const CUSTOM_DATE_FORMATS = {
  parse: {
    dateInput: 'YYYY'
  },
  display: {
    dateInput: 'YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY'
  }
};

@Component({
    imports: [MatFormFieldModule, MatDatepickerToggle, MatDatepicker, ReactiveFormsModule, MatDatepickerInput, MatInput, TranslateModule],
    selector: 'iot-platform-ui-year-picker',
    templateUrl: './year-picker.component.html',
    styleUrls: ['./year-picker.component.scss'],
    providers: [{ provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS }],
    encapsulation: ViewEncapsulation.None
})
export class YearPickerComponent {
  label = input<string>('DATE_RANGE.PLACEHOLDER_YEAR');
  initialYear = input<number>(moment().year());
  max = input<moment.Moment>(moment());

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  onYearChange = output<string>();

  yearControl: WritableSignal<FormControl> = signal(new FormControl());

  constructor() {
    this.initInitialYearEffect();
  }

  initInitialYearEffect(): void {
    effect(
      () => {
        const year = this.initialYear();
        this.yearControl.update((value) => {
          value.setValue(moment().year(year));
          return value;
        });
        this.onYearChange.emit(year.toString());
      }
    );
  }

  onYearSelection(year: number, datepicker: any): void {
    datepicker.close();
    this.yearControl.update((value) => {
      value.setValue(moment().year(year));
      return value;
    });
    this.onYearChange.emit(this.yearControl().value?.year().toString());
  }
}
