import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { NgClass } from '@angular/common';
import { Component, computed, effect, inject, Signal, signal, WritableSignal } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { get } from 'lodash';
import { CustomOptionsCellParams } from '../../../models/custom-options-cell.params';

@Component({
  selector: 'grid-engine-icon-cell',
  templateUrl: './icon-cell.component.html',
  imports: [MatIcon, MatTooltip, NgClass, TranslateModule],
  styleUrls: ['./icon-cell.component.scss']
})
export class IconCellComponent implements ICellRendererAngularComp {
  params: WritableSignal<CustomOptionsCellParams> = signal(null);

  cellOptions: Signal<any> = computed(() => {
    const params = this.params();
    return get(params, ['colDef', 'cellRendererParams', 'cellOptions', 'tooltip']);
  });
  value: WritableSignal<any> = signal(null);
  isIcon: WritableSignal<boolean> = signal(false);

  protected readonly translateService: TranslateService = inject(TranslateService);

  constructor() {
    effect(() => {
      const params = this.params();
      if (!params.value) {
        this.isIcon.set(false);
        this.value.set((params as any).isFilterRenderer ? this.translateService.instant('AG_GRID.blanks') : params.value);
      } else if (params.value === this.translateService.instant('AG_GRID.selectAll')) {
        this.isIcon.set(false);
        this.value.set(params.value);
      } else {
        this.isIcon.set(true);
        this.value.set(params.value);
      }
    });
  }

  agInit(params: CustomOptionsCellParams & { isFilterRenderer?: boolean }): void {
    this.params.set(params);
  }

  refresh(): boolean {
    return false;
  }
}
