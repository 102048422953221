import { Component, input, output } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MasterViewEngineEvent } from '@iot-platform/models/common';
import { AbstractTableEngineCellComponent } from '../abstract-table-engine-cell.component';
import { CallToActionMenuItemComponent } from '../call-to-action-menu-item/call-to-action-menu-item.component';

@Component({
  selector: 'i4b-table-engine-call-to-action-cell',
  templateUrl: './call-to-action-cell.component.html',
  styleUrls: ['./call-to-action-cell.component.scss'],
  imports: [MatIcon, MatMenuModule, MatIconButton, CallToActionMenuItemComponent]
})
export class CallToActionCellComponent extends AbstractTableEngineCellComponent<CallToActionCellComponent> {
  element = input<any>();
  actions = input<any>();

  actionClicked = output<MasterViewEngineEvent>();
  isCallToActionVisible = true;
}
