import { A11yModule } from '@angular/cdk/a11y';
import { NgClass } from '@angular/common';
import { Component, inject, signal, ViewChild, WritableSignal } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconButton } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatToolbar } from '@angular/material/toolbar';
import { MatTooltip } from '@angular/material/tooltip';
import { LocalStorageKeys, LocalStorageService } from '@iot-platform/core';
import { TimeseriesDisplayMode } from '@iot-platform/models/common';
import { AssetVariable, DeviceVariable } from '@iot-platform/models/i4b';
import { TimeseriesWidgetOptions } from '@iot-platform/models/widgets';
import { DateFormatPipe } from '@iot-platform/pipes';
import { TranslateModule } from '@ngx-translate/core';

import { VariableChartComponent } from '../variable-chart.component';

@Component({
    selector: 'iot-platform-ui-variable-chart-dialog',
    templateUrl: './variable-chart-dialog.component.html',
    styleUrls: ['./variable-chart-dialog.component.scss'],
    imports: [MatCardModule, FlexLayoutModule, TranslateModule, MatToolbar, MatTooltip, NgClass, MatIconButton, MatIcon, VariableChartComponent, A11yModule],
    providers: [DateFormatPipe]
})
export class VariableChartDialogComponent {
  private readonly storage: LocalStorageService = inject(LocalStorageService);
  @ViewChild(VariableChartComponent) variableChart!: VariableChartComponent;
  fullscreen: WritableSignal<boolean> = signal(false);
  displayTable: WritableSignal<boolean> = signal(
    JSON.parse(this.storage.get(LocalStorageKeys.STORAGE_BUSINESS_PROFILE_KEY))?.timeseriesDisplayMode === TimeseriesDisplayMode.table
  );

  dialogRef: MatDialogRef<VariableChartDialogComponent> = inject(MatDialogRef<VariableChartDialogComponent>);
  data: {
    variables: (DeviceVariable | AssetVariable)[];
    variableType: string;
    title?: string;
    options?: TimeseriesWidgetOptions;
  } = inject(MAT_DIALOG_DATA);

  constructor() {
    this.data.options = {
      variables: this.data.variables,
      chart: {
        legend: {
          enabled: true,
          labelFormat: (params: any) => {
            if (params.asset?.name) {
              return `${params.asset.name} - ${params.name}`;
            }
            if (params.device?.name) {
              return `${params.device.name} - ${params.name}`;
            }
            return params.name;
          }
        }
      },
      ...this.data.options
    };
  }

  close() {
    this.dialogRef.close();
  }

  enterFullscreen(): void {
    this.fullscreen.set(true);
    this.dialogRef.addPanelClass('fullscreen-panel');
    this.variableChart.setFullscreenOn(true);
    setTimeout(() => this.variableChart.reflow(), 0);
  }

  exitFullscreen(): void {
    this.fullscreen.set(false);
    this.dialogRef.removePanelClass('fullscreen-panel');
    this.variableChart.setFullscreenOn(false);
    setTimeout(() => this.variableChart.reflow(), 0);
  }

  toggleTableView(): void {
    this.displayTable.update((value) => !value);
  }
}
