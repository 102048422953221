import { CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { NgClass, NgTemplateOutlet } from '@angular/common';

import { Component, input, output } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AbstractControl } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Asset, AssetVariable, DeviceVariable } from '@iot-platform/models/i4b';
import { DateFormatPipe, ValueUnitFormatPipe } from '@iot-platform/pipes';
import { TranslateModule } from '@ngx-translate/core';

interface AssetWithVariables extends Asset {
  variables: DeviceVariable[] | AssetVariable[];
}

@Component({
    selector: 'iot-platform-dashboards-asset-variable-selector-list',
    templateUrl: './asset-variable-selector-list.component.html',
    styleUrls: ['./asset-variable-selector-list.component.scss'],
    imports: [
        TranslateModule,
        MatProgressSpinnerModule,
        MatIconModule,
        ValueUnitFormatPipe,
        CdkDropList,
        NgTemplateOutlet,
        CdkDrag,
        DateFormatPipe,
        FlexLayoutModule,
        MatButtonModule,
        NgClass
    ]
})
export class AssetVariableSelectorListComponent {
  control = input<AbstractControl>();
  withDragAndDrop = input<boolean>(false);
  withCounter = input<boolean>(false);
  highlightFirstItem = input<boolean>(false);
  nbSelectedLabel = input<string>();
  emptyMessage = input<string>();
  canDeleteVariables = input<boolean>(true);
  disabled = input<boolean>(false);
  removeAssetVariable = output();

  variablesLoading = input<boolean>();

  get value(): AssetVariable[] {
    return this.control()?.value;
  }

  set value(value: AssetVariable[]) {
    this.control()?.setValue(value);
  }

  drop(event: CdkDragDrop<AssetWithVariables[]>) {
    moveItemInArray(this.value, event.previousIndex, event.currentIndex);
  }

  removeSelectedVariable(selectedVar): void {
    this.value = this.value.filter((v) => v.id !== selectedVar.id);
    this.removeAssetVariable.emit();
  }
}
