import { inject } from '@angular/core';
import { NotificationService } from '@iot-platform/notification';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { BusinessProfilesService } from '../../services/business-profiles.service';
import { AdminBusinessProfilesTagsActions } from '../actions/business-profiles-tags.actions';

const loadTagsByBusinessProfile$ = createEffect(
  (actions$ = inject(Actions), businessProfilesService = inject(BusinessProfilesService)) =>
    actions$.pipe(
      ofType(AdminBusinessProfilesTagsActions.loadTagsByBusinessProfile),
      mergeMap((action) =>
        businessProfilesService.loadTagsByBusinessProfileId(action.businessProfileId).pipe(
          map((tags) => AdminBusinessProfilesTagsActions.loadTagsByBusinessProfileSuccess({ tags })),
          catchError((error) => of(AdminBusinessProfilesTagsActions.loadTagsByBusinessProfileFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const updateTagsForBusinessProfile$ = createEffect(
  (actions$ = inject(Actions), businessProfilesService = inject(BusinessProfilesService)) =>
    actions$.pipe(
      ofType(AdminBusinessProfilesTagsActions.updateTagsForBusinessProfile),
      mergeMap((action) =>
        businessProfilesService.updateTagsForBusinessProfile(action.businessProfileId, action.tagsToAdd).pipe(
          map((addedTags) => AdminBusinessProfilesTagsActions.updateTagsForBusinessProfileSuccess({ addedTags })),
          catchError((error) => of(AdminBusinessProfilesTagsActions.updateTagsForBusinessProfileFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const showLoader$ = createEffect(
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(AdminBusinessProfilesTagsActions.updateTagsForBusinessProfile, AdminBusinessProfilesTagsActions.loadTagsByBusinessProfile),
      tap(() => notificationService.showLoader())
    ),
  { functional: true, dispatch: false }
);

const hideLoader$ = createEffect(
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(
        AdminBusinessProfilesTagsActions.updateTagsForBusinessProfileSuccess,
        AdminBusinessProfilesTagsActions.updateTagsForBusinessProfileFailure,
        AdminBusinessProfilesTagsActions.loadTagsByBusinessProfileSuccess,
        AdminBusinessProfilesTagsActions.loadTagsByBusinessProfileFailure
      ),
      tap(() => notificationService.hideLoader())
    ),
  { functional: true, dispatch: false }
);

const displaySuccess$ = createEffect(
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(AdminBusinessProfilesTagsActions.updateTagsForBusinessProfileSuccess),
      tap((action: Action) => notificationService.displaySuccess(action.type))
    ),
  { functional: true, dispatch: false }
);

const displayError$ = createEffect(
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(AdminBusinessProfilesTagsActions.updateTagsForBusinessProfileFailure, AdminBusinessProfilesTagsActions.loadTagsByBusinessProfileFailure),
      tap(({ error }) => notificationService.displayError(error))
    ),
  { functional: true, dispatch: false }
);

export const adminBusinessProfilesTagsEffects = {
  loadTagsByBusinessProfile$,
  updateTagsForBusinessProfile$,
  showLoader$,
  hideLoader$,
  displaySuccess$,
  displayError$
};
