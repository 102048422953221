import { NgModule } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@iot-platform/auth';
import { SharedModule } from '@iot-platform/shared';

const routes: Routes = [
  {
    path: 'calendars',
    loadChildren: () => import('./features/calendars/calendars.routes'),
    canActivate: [AuthGuard]
  },
  {
    path: 'topics',
    loadChildren: () => import('./features/topics/topics.module').then((module) => module.TopicsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'escalation-protocols',
    loadChildren: () => import('./features/escalation-protocol/escalation-protocol.module').then((module) => module.EscalationProtocolModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'team-plannings',
    loadChildren: () => import('./features/team-planning/team-planning.module').then((module) => module.TeamPlanningModule),
    canActivate: [AuthGuard]
  },
  { path: '', pathMatch: 'full', redirectTo: '/home' },
  { path: '**', redirectTo: '/home' }
];

@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'en-EN' }]
})
export class OnCallManagementModule {}
