@if (!!assetTemplateVariableFormula) {
  <form [formGroup]="assetVariableFormulaForm">
    <section
      class="asset-variable-formula-configure--section"
      [ngClass]="{
        'asset-variable-formula-configure--success': formulaTypeControl.value,
        'asset-variable-formula-configure--error': !formulaTypeControl.value
      }"
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="20px"
    >
      <span>{{ 'ASSETS.FORMULA_CONFIGURATION.FORMULA_TYPE' | translate }}</span>
      @if (readonly) {
        <mat-form-field color="accent">
          <input matInput formControlName="formulaType" required />
        </mat-form-field>
      } @else {
        <mat-form-field color="accent">
          <mat-select formControlName="formulaType">
            @for (type of formulaTypes; track type) {
              <mat-option [value]="type">{{ type }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      }
    </section>

    @if (assetTemplateVariableFormula.model !== FormulaType.EQUAL) {
      <section
        class="asset-variable-formula-configure--section"
        [ngClass]="{
          'asset-variable-formula-configure--success': formulaTypeControl.value,
          'asset-variable-formula-configure--error': !formulaTypeControl.value
        }"
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="20px"
      >
        <iot4bos-ui-asset-formula-parameters-configuration
          [readonly]="readonly"
          [formulaType]="assetTemplateVariableFormula.model"
          [initialFormula]="formula"
        ></iot4bos-ui-asset-formula-parameters-configuration>
      </section>
    }
    @if (formulaTypeControl.value !== FormulaType.CONSTANT) {
      <section
        class="asset-variable-formula-configure--section"
        [ngClass]="{ 'asset-variable-formula-configure--success': isSourceVariableValid, 'asset-variable-formula-configure--error': !isSourceVariableValid }"
        fxLayout="column"
      >
        <span>{{ 'ASSETS.FORMULA_CONFIGURATION.SOURCE_VARIABLE' | translate }}</span>
        @if (sourceVariableControl.valid) {
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
            <span fxFlex> {{ sourceVariableControl.value.name }} [{{ sourceVariableControl.value.device.name }}] </span>
            @if (!sourceVariableControl.value?.lastValue) {
              <span class="capitalize-first-letter">{{ 'ASSETS.FORMULA_CONFIGURATION.NO_LAST_RECORD_AVAILABLE' | translate }}</span>
            } @else {
              <span>
                {{ sourceVariableControl.value | valueUnitFormat }} -
                {{ sourceVariableControl.value.lastValue.datetime | dateFormat }}
              </span>
            }
            <button mat-icon-button color="warn" (click)="removeSourcedVariable.emit()"><mat-icon>delete</mat-icon></button>
          </div>
        } @else if (!!assetTemplateVariableFormula?.srcVariables?.['0']?.match) {
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
            @if (!assetTemplateVariableFormula?.srcVariables?.['0']?.match?.matchingVariables) {
              <span fxFlex class="capitalize-first-letter">
                @switch (assetTemplateVariableFormula?.srcVariables?.['0']?.match.type) {
                  @case (TemplateSuggestedVariableMatchingType.BEGINS_WITH) {
                    {{
                      'ASSETS.FORMULA_CONFIGURATION.' +
                        (assetTemplateVariableFormula.evaluation?.sourceVariable !== undefined
                          ? 'MATCH_WITH_SOURCE_VARIABLE_BEGINS_WITH'
                          : 'NO_SUGGESTED_VARIABLE_FOUND')
                        | translate: { suggestedVariables: assetTemplateVariableFormula?.srcVariables?.['0']?.match?.suggestedNames?.sort()?.join(', ') }
                    }}
                  }
                  @case (TemplateSuggestedVariableMatchingType.EXACT) {
                    {{
                      'ASSETS.FORMULA_CONFIGURATION.' +
                        (assetTemplateVariableFormula.evaluation?.sourceVariable !== undefined
                          ? 'MATCH_WITH_SOURCE_VARIABLE_EXACT'
                          : 'NO_SUGGESTED_VARIABLE_FOUND')
                        | translate: { suggestedVariables: assetTemplateVariableFormula?.srcVariables?.['0']?.match?.suggestedNames?.sort()?.join(', ') }
                    }}
                  }
                }
              </span>
            } @else {
              <mat-form-field color="accent" fxFlex >
                <mat-label>{{ 'ASSETS.FORMULA_CONFIGURATION.SELECT_VARIABLE' | translate }}</mat-label>
                <mat-select (selectionChange)="onMatchingVariableSelection($event)">
                  @for (matchingVar of assetTemplateVariableFormula?.srcVariables?.['0']?.match?.matchingVariables; track matchingVar.id) {
                    <mat-option [value]="matchingVar"
                      >{{ matchingVar.name }} - {{ matchingVar | valueUnitFormat }} - {{ matchingVar.lastValue?.datetime | dateFormat }}</mat-option
                    >
                  }
                </mat-select>
              </mat-form-field>
            }
            <button
              mat-icon-button
              color="accent"
              [disabled]="disableMatching || assetTemplateVariableFormula.evaluation?.sourceVariable === undefined || !!assetTemplateVariableFormula?.srcVariables?.['0']?.match?.matchingVariables"
              (click)="assignSuggestedVariable.emit()"
            >
              <mat-icon [svgIcon]="'variables_matching'"></mat-icon>
            </button>
          </div>
        } @else {
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
            <span fxFlex class="capitalize-first-letter">
              {{ 'ASSETS.FORMULA_CONFIGURATION.NO_SUGGESTED_VARIABLE_DEFINED' | translate }}
            </span>
          </div>
        }
      </section>
    }

    <section
      class="asset-variable-formula-configure--section"
      [ngClass]="{
        'asset-variable-formula-configure--success': assetTemplateVariableFormula?.evaluation,
        'asset-variable-formula-configure--error': !assetTemplateVariableFormula?.evaluation
      }"
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="20px"
    >
      <span fxFlex>{{ 'ASSETS.VARIABLE_FORM.FORMULA_EVALUATION' | translate }}</span>
      @if (assetTemplateVariableFormula?.evaluation) {
        <span> {{ assetTemplateVariableFormula.evaluation?.lastRecords?.[0]?.value | numberFormat }}&nbsp;{{ unit }} </span>
      }
    </section>

    @if (thresholds?.values?.length) {
      <section
        class="asset-variable-formula-configure--section"
        [ngClass]="{
          'asset-variable-formula-configure--success': areThresholdsCorrect,
          'asset-variable-formula-configure--error': !areThresholdsCorrect
        }"
      >
        <iot4bos-ui-asset-variable-thresholds-configuration [readonly]="true" [thresholds]="thresholds" (thresholdsChange)="onThresholdsChange($event)">
        </iot4bos-ui-asset-variable-thresholds-configuration>
      </section>
    }
  </form>
}
