import { get } from 'lodash';

/* eslint-disable @typescript-eslint/no-explicit-any */
export class FlattenUtils {
  static flattenDeep = (element: any, key: string, predicate: (params: any) => boolean = () => true): any[] => {
    let flatArray: any[] = [];
    const flattenData = (data) => {
      if (predicate(data)) {
        flatArray.push(data);
        if (get(data, key)) {
          flatArray = flatArray.concat(this.flattenDeep(get(data, key), key, predicate));
        }
      }
    };
    if (element instanceof Array) {
      element.forEach((item) => {
        flattenData(item);
      });
    } else {
      flattenData(element);
    }
    return flatArray;
  };
}
