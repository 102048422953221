import { IHeaderGroupAngularComp } from '@ag-grid-community/angular';
import { IHeaderGroupParams } from '@ag-grid-community/core';
import { NgClass } from '@angular/common';
import { Component, Signal, signal, WritableSignal } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'grid-engine-group-header-cell',
    templateUrl: './group-header-cell.component.html',
    imports: [NgClass, TranslateModule],
    styleUrls: ['./group-header-cell.component.scss']
})
export class GroupHeaderCellComponent implements IHeaderGroupAngularComp {
  params: WritableSignal<IHeaderGroupParams> = signal(null);
  expandState: WritableSignal<string> = signal('');
  showExpandArrow: Signal<boolean> = signal(false);

  agInit(params: IHeaderGroupParams): void {
    this.params.set(params);
    this.params().columnGroup.getProvidedColumnGroup().addEventListener('expandedChanged', this.syncExpandButtons.bind(this));
  }

  expandOrCollapse() {
    const params = this.params();
    const currentState = params.columnGroup.getProvidedColumnGroup().isExpanded();
    params.setExpanded(!currentState);
  }

  syncExpandButtons(): void {
    const params = this.params();
    const expanded = params.columnGroup.getProvidedColumnGroup().isExpanded();
    this.expandState.set(expanded ? 'expanded' : 'collapsed');
  }
}
