import { TagCategory } from '@iot-platform/models/common';
import { createActionGroup, props } from '@ngrx/store';

export const AdminBusinessProfilesTagsActions = createActionGroup({
  source: 'Admin Business Profiles Tags',
  events: {
    'Load Tags by Business Profile': props<{ businessProfileId: string }>(),
    'Load Tags by Business Profile Success': props<{ tags: TagCategory[] }>(),
    'Load Tags by Business Profile Failure': props<{ error: any }>(),
    'Update Tags For Business Profile': props<{ businessProfileId: string; tagsToAdd: TagCategory[] }>(),
    'Update Tags For Business Profile Success': props<{ addedTags: TagCategory[] }>(),
    'Update Tags For Business Profile Failure': props<{ error: any }>()
  }
});
