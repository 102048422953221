import { NgClass } from '@angular/common';
import { Component } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { InfoDisplayPipe } from '@iot-platform/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { AbstractTableEngineCellComponent } from '../abstract-table-engine-cell.component';

@Component({
  selector: 'i4b-table-engine-basic-link-cell',
  templateUrl: './basic-link-cell.component.html',
  styleUrls: ['./basic-link-cell.component.scss'],
  imports: [NgClass, MatTooltip, TranslateModule, InfoDisplayPipe],
  standalone: true
})
export class BasicLinkCellComponent extends AbstractTableEngineCellComponent<BasicLinkCellComponent> {
  onClick(event: MouseEvent): void {
    event?.stopPropagation();
    const clickEvent = this.clickEvent();
    const rawData = this.rawData();
    this.dispatchCellEvent({ type: clickEvent?.type, options: clickEvent?.options, rawData });
  }
}
