import { BaseActions, CommonApiRequest, CommonApiResponse, Pagination } from '@iot-platform/models/common';
import { I4BGrid, I4BGridData, I4BGridOptions } from '@iot-platform/models/grid-engine';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

class BusinessProfilesGridsBaseActions extends BaseActions<I4BGrid<I4BGridOptions, I4BGridData>> {}

const baseActions = new BusinessProfilesGridsBaseActions();

export const AdminBusinessProfilesGridsActions = createActionGroup({
  source: 'Admin Business Profiles Grids',
  events: {
    ...baseActions.getDefaultEvents(),
    'Load Business Profile Grids': props<{ request: CommonApiRequest }>(),
    'Load Business Profile Grids Success': props<{
      response: CommonApiResponse<I4BGrid<I4BGridOptions, I4BGridData>, Pagination>;
    }>(),
    'Load Business Profile Grids Failure': props<{ error: unknown }>(),
    'Clear state': emptyProps()
  }
});
