import { Component, inject } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AbstractControl, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';

import { Organization, Role } from '@iot-platform/models/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    imports: [FlexLayoutModule, MatCardModule, MatIcon, ReactiveFormsModule, MatInput, MatFormFieldModule, TranslateModule, MatButtonModule, MatToolbarModule],
    selector: 'iot4bos-ui-backoffice-add-role-dialog',
    templateUrl: './add-role-dialog.component.html',
    styleUrls: ['./add-role-dialog.component.scss']
})
export class AddRoleDialogComponent {
  public dialogRef: MatDialogRef<AddRoleDialogComponent> = inject(MatDialogRef<AddRoleDialogComponent>);
  public data: { organization: Organization; roleToUpdate: Role } = inject(MAT_DIALOG_DATA);

  bpForm: UntypedFormGroup = new UntypedFormGroup({
    nameControl: new UntypedFormControl(this.data.roleToUpdate ? this.data.roleToUpdate.name : '', [Validators.required, Validators.maxLength(50)])
  });

  get nameControl(): AbstractControl {
    return this.bpForm.get('nameControl');
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    if (this.data.roleToUpdate) {
      const toUpdate: Role = { ...this.data.roleToUpdate, name: this.nameControl.value.trim() };
      this.dialogRef.close(toUpdate);
    } else {
      const newRole: Role = {
        name: this.nameControl.value.trim(),
        entityName: this.data.organization.name,
        entityId: this.data.organization.id
      };
      this.dialogRef.close(newRole);
    }
  }
}
