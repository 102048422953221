import { CommonApiResponse, CommonIndexedPagination, FavoriteView } from '@iot-platform/models/common';
import {
  HeaderType,
  I4BBasicColumn,
  I4BBooleanCell,
  I4BButtonColumn,
  I4bCellConcept,
  I4BCellType,
  I4BColumn,
  I4BColumnConfiguration,
  I4BColumnHeader,
  I4BColumnOptions,
  I4BGrid,
  I4BGridData,
  I4BGridOptions
} from '@iot-platform/models/grid-engine';

export class BusinessProfileFavoriteViewsGridData implements I4BGridData {
  response!: CommonApiResponse<FavoriteView, CommonIndexedPagination>;
}

export const BUSINESS_PROFILE_FAVORITE_VIEWS_MASTER_VIEW_NAME = 'business-profile-favorite-views';

class BusinessProfileFavoriteViewsGridButtonColumn extends I4BButtonColumn {
  constructor() {
    super(
      {
        type: HeaderType.CALL_TO_ACTION,
        displayName: 'BUTTON',
        icon: ''
      },
      {
        concept: I4bCellConcept.FAVORITE_VIEWS,
        isConfigurable: false,
        isDefault: true,
        isLink: false,
        position: '',
        sortProperty: '',
        width: 10,
        id: 'button',
        cell: {
          type: I4BCellType.CALL_TO_ACTION,
          singleActions: [
            {
              order: 10,
              key: 'edit',
              label: 'IOT_DICTIONARY.EDIT',
              enableConditions: [],
              visibleConditions: [[{ key: 'canUpdateBusinessProfile', value: true }]]
            },
            {
              order: 15,
              key: 'duplicate',
              label: 'IOT_DICTIONARY.DUPLICATE',
              enableConditions: [],
              visibleConditions: [[{ key: 'canUpdateBusinessProfile', value: true }]]
            },
            {
              order: 20,
              key: 'delete',
              label: 'IOT_DICTIONARY.DELETE',
              enableConditions: [],
              visibleConditions: [[{ key: 'canUpdateBusinessProfile', value: true }]]
            }
          ],

          visibleConditions: [[{ key: 'canUpdateBusinessProfile', value: true }]],
          enableConditions: [],
          bulkActions: []
        }
      },
      { order: 1000 }
    );
  }
}

export class BusinessProfileFavoriteViewsGrid implements I4BGrid<I4BGridOptions, BusinessProfileFavoriteViewsGridData> {
  masterview = BUSINESS_PROFILE_FAVORITE_VIEWS_MASTER_VIEW_NAME;
  isDefault = true;
  isUserDefault = false;
  isAppDefault = false;
  name = 'business-profile-grids';
  userId: string | null = null;
  data: BusinessProfileFavoriteViewsGridData = {
    response: {
      data: [],
      pagination: null
    }
  };
  gridOptions: I4BGridOptions = {
    pageSize: 10,
    multipleSelection: false,
    autoRefresh: { enabled: false },
    buttonColumn: { enabled: true, className: new BusinessProfileFavoriteViewsGridButtonColumn() }
  };
  columns: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] = [
    new I4BBasicColumn(
      { type: HeaderType.EMPTY, enableSorting: false },
      {
        id: 'color',
        isDefault: true,
        cell: { type: I4BCellType.COLOR_CELL }
      },
      { order: 0, width: 70 }
    ),
    new I4BBasicColumn({ displayName: 'NAME' }, { id: 'name', sortProperty: 'name', isDefault: true }, { order: 10 }),
    new I4BBasicColumn(
      { displayName: 'MASTERVIEW' },
      { id: 'masterView', sortProperty: 'masterview', isDefault: true, cell: { type: I4BCellType.TRANSLATED_CELL } },
      { order: 20 }
    ),
    new I4BBasicColumn(
      { displayName: 'CREATED BY' },
      {
        translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.CREATED_BY',
        id: 'createdBy',
        isDefault: true,
        sortProperty: 'createdBy',
        width: 165,
        cell: {
          type: I4BCellType.USER_CELL
        }
      },
      { order: 30 }
    ),
    new I4BBasicColumn(
      { displayName: 'DESCRIPTION' },
      {
        id: 'description',
        sortProperty: 'description',
        isDefault: true
      },
      { order: 40 }
    ),
    new I4BBasicColumn(
      { displayName: 'HOME', enableSorting: false },
      {
        id: 'pinned',
        isDefault: true,
        cell: { type: I4BCellType.BOOLEAN_CELL, options: { mode: 'checkbox', disabled: true } } as I4BBooleanCell
      },
      { order: 50, width: 100 }
    ),
    new I4BBasicColumn(
      { displayName: 'GRID' },
      {
        id: 'gridId',
        isDefault: true,
        cell: { type: I4BCellType.GRID_NAME_CELL }
      },
      { order: 60 }
    )
  ];
}
