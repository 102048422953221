import { Injectable } from '@angular/core';
import { ApiFeatureSelector, BaseUser, Filter, Pagination } from '@iot-platform/models/common';
import * as fromBusinessProfilesMembers from '../reducers/business-profiles-members.reducer';

@Injectable({
  providedIn: 'root'
})
export class AdminBusinessProfilesMembersSelectors extends ApiFeatureSelector<fromBusinessProfilesMembers.State, BaseUser, Pagination, Filter> {
  constructor() {
    super(fromBusinessProfilesMembers.adminBusinessProfilesMembersFeature.selectAdminBusinessProfilesMembersState, fromBusinessProfilesMembers.adapter);
  }
}
