import { NgClass } from '@angular/common';
import { Component, computed, inject, Signal } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateService } from '@ngx-translate/core';
import { AbstractTableEngineCellComponent } from '../abstract-table-engine-cell.component';

@Component({
  selector: 'i4b-table-engine-linked-cell',
  templateUrl: './linked-cell.component.html',
  styleUrls: ['./linked-cell.component.scss'],
  imports: [MatTooltip, MatIcon, NgClass]
})
export class LinkedCellComponent extends AbstractTableEngineCellComponent<LinkedCellComponent> {
  private readonly translateService: TranslateService = inject(TranslateService);
  tooltip: Signal<string> = computed(() => this.translateService.instant(`ASSETS.VARIABLE.TOOLTIPS.${this.data() ? 'LINKED' : 'NOT_LINKED'}`));
}
