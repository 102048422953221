import { ChangeDetectionStrategy, Component, DestroyRef, effect, inject, Injector, OnInit, Signal, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AbstractControl, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { AsyncAutocompleteModule } from '@iot-platform/iot-platform-ui';
import { Product, ProductCatalog } from '@iot-platform/models/common';
import { TranslateModule } from '@ngx-translate/core';
import { AbstractConfigureProductsFormService } from './abstract-configure-products-form.service';

@Component({
  imports: [
    TranslateModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    AsyncAutocompleteModule,
    MatIconModule,
    MatToolbarModule,
    MatCardModule,
    MatSelectModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressBarModule,
    MatAutocompleteModule
  ],
  selector: 'iot4bos-ui-asset-configure-products-form',
  templateUrl: './configure-products-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfigureProductsFormComponent implements OnInit {
  private readonly configureProductsFormService: AbstractConfigureProductsFormService = inject(AbstractConfigureProductsFormService);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  private readonly injector: Injector = inject(Injector);
  public dialogRef: MatDialogRef<ConfigureProductsFormComponent> = inject(MatDialogRef<ConfigureProductsFormComponent>);
  public data: {
    id: string;
    name: string;
    product1: Product;
    configureProduct1: boolean;
    product2: Product;
    configureProduct2: boolean;
    disableCatalogs: boolean;
  } = inject(MAT_DIALOG_DATA);
  form: UntypedFormGroup = new UntypedFormGroup({});
  product1List: WritableSignal<Product[]> = signal([]);
  product2List: WritableSignal<Product[]> = signal([]);
  catalogs: Signal<ProductCatalog[]> = toSignal(this.configureProductsFormService.getCatalogs(this.data.id).pipe(takeUntilDestroyed(this.destroyRef)));
  products: Signal<Product[]> = toSignal(this.configureProductsFormService.getProducts(this.data.id).pipe(takeUntilDestroyed(this.destroyRef)));

  get catalog1(): AbstractControl {
    return this.form.get('catalog1');
  }

  get catalog2(): AbstractControl {
    return this.form.get('catalog2');
  }

  get product1(): AbstractControl {
    return this.form.get('product1');
  }

  get product2(): AbstractControl {
    return this.form.get('product2');
  }

  ngOnInit(): void {
    this.initForm();
    this.filterProductsByCatalogEffect();
  }

  onSelectCatalog1(): void {
    this.filterProductListByCatalog(this.product1List, this.catalog1.getRawValue().id as string);
    this.product1.reset();
  }

  onSelectCatalog2(): void {
    this.filterProductListByCatalog(this.product2List, this.catalog2.getRawValue().id as string);
    this.product2.reset();
  }

  filterProductListByKeyword(keyword: string, productList: WritableSignal<Product[]>, catalog: AbstractControl): void {
    productList.set(
      this.products().filter(
        (product: Product) => product.catalog?.id === catalog.getRawValue()?.id && (product.identifier + ': ' + product.name).includes(keyword)
      )
    );
  }

  save(): void {
    this.dialogRef.close({
      product1: this.data.configureProduct1
        ? { ...this.product1.getRawValue(), catalog: this.catalog1.getRawValue() }
        : this.data.product1
          ? this.data.product1
          : null,
      product2: this.data.configureProduct2
        ? { ...this.product2.getRawValue(), catalog: this.catalog2.getRawValue() }
        : this.data.product2
          ? this.data.product2
          : null
    });
  }

  displayFn(product: Product): string {
    return product ? product.identifier + ': ' + product.name : '';
  }

  compareFn(catalog1: ProductCatalog, catalog2: ProductCatalog): boolean {
    return catalog1?.id === catalog2?.id;
  }

  close(): void {
    this.dialogRef.close();
  }

  private initForm(): void {
    this.form = new UntypedFormGroup({
      catalog1: new UntypedFormControl(
        { value: this.data.product1?.catalog ?? null, disabled: this.data.disableCatalogs },
        this.data.configureProduct1 ? [Validators.required] : []
      ),
      catalog2: new UntypedFormControl(
        { value: this.data.product2?.catalog ?? null, disabled: this.data.disableCatalogs },
        this.data.configureProduct2 ? [Validators.required] : []
      ),
      product1: new UntypedFormControl(this.data.product1 ?? null, this.data.configureProduct1 ? [Validators.required] : []),
      product2: new UntypedFormControl(this.data.product2 ?? null, this.data.configureProduct2 ? [Validators.required] : [])
    });
  }

  private filterProductsByCatalogEffect() {
    effect(
      () => {
        if (this.products() && this.data.product1?.catalog) {
          this.filterProductListByCatalog(this.product1List, this.data.product1?.catalog?.id);
        }
        if (this.products() && this.data.product2?.catalog) {
          this.filterProductListByCatalog(this.product2List, this.data.product2?.catalog?.id);
        }
      },
      { injector: this.injector }
    );
  }

  private filterProductListByCatalog(productsList: WritableSignal<Product[]>, catalogId: string): void {
    productsList.set(this.products().filter((product: Product) => product.catalog?.id === catalogId));
  }
}
