import { EnvironmentProviders, InjectionToken, makeEnvironmentProviders, Type } from '@angular/core';
import { AuthFacade } from '@iot-platform/auth';
import { provideFeatureMasterViewSettingsFactory } from '@iot-platform/feature/master-view';
import { FeatureDeviceCallLogsSettings } from '../models/device-call-logs-settings.model';
import { AbstractDeviceCallLogsService } from '../services/abstract-device-call-logs.service';
import { AbstractDeviceCallLogsFacade } from '../services/abstract-device-call-logs-facade.service';
import { DeviceCallLogsMasterViewService } from '../services/device-call-logs-master-view.service';
import { getDeviceCallLogsMasterViewSettings } from './device-call-logs-master-view.provider';

export const FEATURE_DEVICE_CALL_LOGS_SETTINGS = new InjectionToken<FeatureDeviceCallLogsSettings>('FEATURE_DEVICE_CALL_LOGS_SETTINGS');

export function provideFeatureDeviceCallLogsSettings(
  settings: FeatureDeviceCallLogsSettings & {
    deviceCallLogsService: Type<AbstractDeviceCallLogsService>;
    deviceCallLogsFacade: Type<AbstractDeviceCallLogsFacade<any>>;
  }
): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: AbstractDeviceCallLogsService,
      useClass: settings.deviceCallLogsService
    },
    {
      provide: AbstractDeviceCallLogsFacade,
      useClass: settings.deviceCallLogsFacade
    },
    {
      provide: FEATURE_DEVICE_CALL_LOGS_SETTINGS,
      useValue: {
        ...settings
      }
    },
    provideFeatureMasterViewSettingsFactory({
      authFacade: AuthFacade,
      masterViewFacade: settings.deviceCallLogsFacade,
      masterViewService: DeviceCallLogsMasterViewService,
      settingsFactory: () => getDeviceCallLogsMasterViewSettings(settings)
    })
  ]);
}
