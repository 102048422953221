import { Component, OnInit } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AbstractControl, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { MatSelectModule } from '@angular/material/select';
import { GetUtils } from '@iot-platform/iot-platform-utils';
import { FormulaType } from '@iot-platform/models/common';
import { AssetVariable, DefaultFormulaSourceVariable, Formula } from '@iot-platform/models/i4b';
import { TranslateModule } from '@ngx-translate/core';
import { MultipleVariablesParametersComponent } from '../multiple-variables-parameters/multiple-variables-parameters.component';
import { VariableParameterComponent } from '../variable-parameter/variable-parameter.component';

@Component({
    imports: [
        FlexLayoutModule,
        TranslateModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatOptionModule,
        MatFormFieldModule,
        MatInputModule,
        VariableParameterComponent
    ],
    selector: 'iot4bos-ui-asset-sum-parameters-form',
    templateUrl: './sum-parameters-form.component.html'
})
export class SumParametersFormComponent extends MultipleVariablesParametersComponent implements OnInit {
  formulaParameters: { name: string; unit?: string }[] = [{ name: 'firstVariable' }, { name: 'secondVariable' }];

  toleranceValues: { value: number; min: number; label: string }[] = [
    { value: 0, min: 0, label: '' },
    { value: 1, min: 1, label: 'min' },
    { value: 1, min: 60, label: 'hr' },
    { value: 2, min: 120, label: 'hrs' }
  ];

  assetVariableFormulaSrcVariables: DefaultFormulaSourceVariable = {
    '0': {
      name: '0',
      variableId: '',
      type: 'asset-variable',
      originId: ''
    },
    '1': {
      name: '1',
      variableId: '',
      type: 'asset-variable',
      originId: ''
    }
  };
  assetVariableFormula: Formula = {
    model: FormulaType.SUM,
    parameters: { toleranceInMinutes: null },
    srcVariables: { ...this.assetVariableFormulaSrcVariables }
  };

  invalidUnitTooltip = 'ASSETS.VARIABLE_FORM.SUM_FORM.INVALID_UNIT_TOOLTIP';
  placeholder = 'ASSETS.VARIABLE_FORM.SUM_FORM.PLACEHOLDERS.';

  get firstVariable(): AbstractControl {
    return this.parametersForm.get('firstVariable');
  }

  get secondVariable(): AbstractControl {
    return this.parametersForm.get('secondVariable');
  }

  get tolerance(): AbstractControl {
    return this.parametersForm.get('tolerance');
  }

  ngOnInit(): void {
    this.initForm();
    this.listenForFormChanges();
    this.sendFormula();
  }

  initForm(): void {
    const sumSrcVariables = this.initialFormula?.srcVariables as DefaultFormulaSourceVariable;

    this.parametersForm = new UntypedFormGroup({
      tolerance: new UntypedFormControl(
        this.toleranceValues.find((e) => e.min === GetUtils.get(this.initialFormula, 'parameters.toleranceInMinutes', 1)),
        [Validators.required]
      ),
      firstVariable: new UntypedFormControl(
        this.initialFormula ? this.initialSelectedVariables?.find((assetVar) => assetVar.id === sumSrcVariables['0']?.variableId) : null,
        [Validators.required, this.checkFirstVariableUnit.bind(this)]
      ),
      secondVariable: new UntypedFormControl(
        this.initialFormula ? this.initialSelectedVariables?.find((assetVar) => assetVar.id === sumSrcVariables['1']?.variableId) : null,
        [Validators.required, this.checkSecondVariableUnit.bind(this)]
      )
    });
    this.parametersForm.markAsUntouched();
    this.setFirstEmptyParameter();
  }

  listenForFormChanges(): void {
    this.subscriptions.push(
      this.parametersForm.valueChanges.subscribe(() => {
        this.sendFormula();
      }),
      this.firstVariable.valueChanges.subscribe(() => {
        this.updateFromValueAndValidity(['firstVariable']);
      }),
      this.secondVariable.valueChanges.subscribe(() => {
        this.updateFromValueAndValidity(['secondVariable']);
      })
    );
  }

  checkFirstVariableUnit(parameter: AbstractControl): { invalidUnit: boolean } | null {
    return this.checkVariableUnit(parameter, this.secondVariable);
  }

  checkSecondVariableUnit(parameter: AbstractControl): { invalidUnit: boolean } | null {
    return this.checkVariableUnit(parameter, this.firstVariable);
  }

  assignAssetVariableToFirstEmptyParameter(assetVariable: AssetVariable): void {
    if (!!this.firstEmptyParameter && !this.formulaParameters.find((parameter) => this[parameter.name].value?.id === assetVariable.id)) {
      this[this.firstEmptyParameter.name].setValue(assetVariable);
    }
  }

  createFormula(): void {
    this.assetVariableFormula = {
      ...this.assetVariableFormula,
      parameters: {
        toleranceInMinutes: this.tolerance.value.min
      },
      srcVariables: {
        '0': {
          ...this.assetVariableFormulaSrcVariables['0'],
          variableId: this.firstVariable.value.id,
          originId: this.firstVariable.value.asset?.id
        },
        '1': {
          ...this.assetVariableFormulaSrcVariables['1'],
          variableId: this.secondVariable.value.id,
          originId: this.secondVariable.value.asset?.id
        }
      }
    };
  }

  updateFromValueAndValidity(excludedKeys: string[]): void {
    Object.keys(this.parametersForm.controls)
      .filter((key: string) => !excludedKeys.includes(key))
      .forEach((key: string) => {
        if (this.isNotEmpty(this.parametersForm.get(key)?.value) && this.parametersForm.controls[key].invalid) {
          this.parametersForm.controls[key].updateValueAndValidity();
        }
      });
  }

  private checkVariableUnit(parameter: AbstractControl, field: AbstractControl): { invalidUnit: boolean } | null {
    if (this.isNotEmpty(field?.value) && parameter.value?.unit !== field.value.unit) {
      return { invalidUnit: true };
    }
    return null;
  }

  private isNotEmpty(value): boolean {
    return value !== null && value !== undefined;
  }
}
