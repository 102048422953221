import { IDoesFilterPassParams } from '@ag-grid-community/core';
import { Component, inject } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { CachedUsersService } from '@iot-platform/core';
import { FormGroupComponent } from '@iot-platform/iot-platform-ui';
import { I4BGridFilterOptions } from '@iot-platform/models/grid-engine';
import { TranslateModule } from '@ngx-translate/core';
import { get } from 'lodash';
import { AbstractCellFilterComponent } from '../abstract-filter.component';

@Component({
  selector: 'grid-engine-user-cell-text-filter',
  imports: [FormsModule, MatFormFieldModule, MatSelectModule, MatInputModule, TranslateModule, FlexLayoutModule, MatButtonModule, FormGroupComponent],
  templateUrl: './user-cell-text-filter.component.html'
})
export class UserCellTextFilterComponent extends AbstractCellFilterComponent {
  protected readonly cachedUsersService: CachedUsersService = inject(CachedUsersService);

  handleDoesFilterPass(params: IDoesFilterPassParams): boolean {
    const field = get(this.filterParams, ['colDef', 'filterParams', 'field'], get(this.filterParams, ['colDef', 'field']));
    const userId = get(params.data, field, '').toString().toLowerCase().trim();
    if (userId?.length === 0) {
      return this.compare('system');
    } else {
      const users = this.cachedUsersService.cachedUsers$.getValue();
      const found = users.find((u) => u.id === userId);
      if (found) {
        const value = `${found?.firstname} ${found?.lastname}`.toLowerCase()?.trim();
        return this.compare(value);
      }
    }
    return false;
  }

  private compare = (v): boolean => {
    const selectedOperator = this.operatorControl?.getRawValue();
    if (selectedOperator?.value === I4BGridFilterOptions.NOT_CONTAINS) {
      return `${v}`.indexOf(`${this.lastValue?.toString()?.toLowerCase().trim()}`) === -1;
    } else if (selectedOperator?.value === I4BGridFilterOptions.START_WITH) {
      return `${v}`.startsWith(`${this.lastValue?.toString()?.toLowerCase().trim()}`);
    } else if (selectedOperator?.value === I4BGridFilterOptions.END_WITH) {
      return `${v}`.endsWith(`${this.lastValue?.toString()?.toLowerCase().trim()}`);
    }
    return `${v}`.indexOf(`${this.lastValue?.toString()?.toLowerCase()}`) !== -1;
  };
}
