import { AuthBusinessProfilesPageActions } from '@iot-platform/auth';
import { BaseState, BusinessProfile, Filter, INITIAL_BASE_STATE, Pagination } from '@iot-platform/models/common';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createFeature, createReducer, on } from '@ngrx/store';
import { AdminBusinessProfilesActions } from '../actions/business-profiles.actions';

export type State = BaseState<BusinessProfile, Pagination, Filter>;

export const adapter: EntityAdapter<BusinessProfile> = createEntityAdapter<BusinessProfile>({
  selectId: (businessProfile: BusinessProfile) => businessProfile.id as string,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  ...INITIAL_BASE_STATE,
  pagination: { currentPage: 0, total: 0, maxPage: 0, hasMore: false, limit: 1000 },
  searchString: ''
});

export const adminBusinessProfilesFeature = createFeature({
  name: 'adminBusinessProfiles',
  reducer: createReducer(
    initialState,
    on(AuthBusinessProfilesPageActions.selectBusinessProfile, () => initialState),
    on(AdminBusinessProfilesActions.selectOne, (state, { toSelect }) => ({ ...state, entity: toSelect })),
    on(AdminBusinessProfilesActions.loadBusinessProfilesSuccess, (state, { businessProfiles }) => adapter.setAll(businessProfiles, state)),
    on(AdminBusinessProfilesActions.addOneSuccess, (state, { added }) =>
      adapter.addOne(added, {
        ...state,
        entity: added
      })
    ),
    on(AdminBusinessProfilesActions.deleteOneSuccess, (state: State, { deleted }) =>
      adapter.removeOne(deleted.id, {
        ...state,
        selectedId: null,
        entity: null
      })
    ),
    on(AdminBusinessProfilesActions.updateOneSuccess, (state, { updated }) =>
      adapter.updateOne(
        {
          id: updated.id,
          changes: updated
        },
        { ...state, entity: updated }
      )
    ),
    on(AdminBusinessProfilesActions.filterBusinessProfiles, (state, { searchString }) => ({ ...state, searchString })),

    on(
      AdminBusinessProfilesActions.loadBusinessProfiles,
      AdminBusinessProfilesActions.addOne,
      AdminBusinessProfilesActions.updateOne,
      AdminBusinessProfilesActions.deleteOne,
      (state) => ({
        ...state,
        loading: true,
        loaded: false
      })
    ),
    on(
      AdminBusinessProfilesActions.loadBusinessProfilesSuccess,
      AdminBusinessProfilesActions.addOneSuccess,
      AdminBusinessProfilesActions.updateOneSuccess,
      AdminBusinessProfilesActions.deleteOneSuccess,
      (state) => ({
        ...state,
        loading: false,
        loaded: true
      })
    ),
    on(
      AdminBusinessProfilesActions.loadBusinessProfilesFailure,
      AdminBusinessProfilesActions.addOneFailure,
      AdminBusinessProfilesActions.updateOneFailure,
      AdminBusinessProfilesActions.deleteOneFailure,
      (state, { error }) => ({
        ...state,
        loading: false,
        loaded: false,
        error
      })
    )
  )
});
