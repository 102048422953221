import { Component, effect, input, output, signal, WritableSignal } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { SortUtil } from '@iot-platform/iot-platform-utils';

import { Organization } from '@iot-platform/models/common';
import { OrganizationConceptsSelectionCheckboxComponent } from './organization-concepts-selection-checkbox/organization-concepts-selection-checkbox.component';

@Component({
  imports: [FlexLayoutModule, OrganizationConceptsSelectionCheckboxComponent],
  selector: 'iot4bos-ui-backoffice-organization-concepts-selection',
  templateUrl: './organization-concepts-selection.component.html',
  styleUrls: ['./organization-concepts-selection.component.scss']
})
export class OrganizationConceptsSelectionComponent {
  organization = input<Organization>();
  adminConceptsFromEntitySession = input<string[]>([]);
  readonly = input<boolean>(true);

  updateConcepts = output<string[]>();

  allConceptsAvailable: WritableSignal<{ name: string; isSelected: boolean }[]> = signal([]);

  organizationEffect = effect(() => {
    const organization = this.organization();
    const adminConceptsFromEntitySession = this.adminConceptsFromEntitySession();

    if (adminConceptsFromEntitySession) {
      const availableConcepts = [...adminConceptsFromEntitySession];

      adminConceptsFromEntitySession.forEach((concept) => {
        if (organization && organization.adminConcepts && organization.adminConcepts.indexOf(concept) > -1) {
          availableConcepts.splice(availableConcepts.indexOf(concept), 1);
        }
      });

      const availableConceptsInBpOrganization = availableConcepts.reduce(
        (acc, currentValue) =>
          acc.concat([
            {
              name: currentValue,
              isSelected: false
            }
          ]),
        []
      );

      let adminConcepts = [];

      if (organization.adminConcepts) {
        adminConcepts = organization.adminConcepts.reduce(
          (acc, currentValue) =>
            acc.concat([
              {
                name: currentValue,
                isSelected: true
              }
            ]),
          []
        );
      }

      this.allConceptsAvailable.set(availableConceptsInBpOrganization.concat(adminConcepts).sort(SortUtil.sortByName));
    }
  });

  onToggleConceptSelection(event: { name: string; isSelected: boolean }) {
    if (event.isSelected) {
      this.addConceptToOrganization(event);
    } else {
      this.removeConceptFromOrganization(event);
    }
  }

  addConceptToOrganization(addedConcept: { name: string; isSelected: boolean }) {
    if (this.allConceptsAvailable().length) {
      this.allConceptsAvailable.update((value) => {
        value.splice(
          value.findIndex((concept) => concept.name === addedConcept.name),
          1,
          addedConcept
        );
        return value;
      });
    } else {
      this.allConceptsAvailable.update((value) => {
        value.push(addedConcept);
        return value;
      });
    }

    this.updateConceptsInOrganization(this.createNewConceptsArray());
  }

  removeConceptFromOrganization(removedConcept: { name: string; isSelected: boolean }) {
    this.allConceptsAvailable.update((value) => {
      value.splice(
        value.findIndex((concept) => concept.name === removedConcept.name),
        1,
        removedConcept
      );
      return value;
    });

    this.updateConceptsInOrganization(this.createNewConceptsArray());
  }

  createNewConceptsArray() {
    return this.allConceptsAvailable().reduce((acc, value) => {
      if (value.isSelected) {
        acc.push(value.name);
      }
      return acc;
    }, []);
  }

  private updateConceptsInOrganization(updatedConcepts: string[]) {
    this.updateConcepts.emit(updatedConcepts);
  }
}
