import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ENVIRONMENT } from '@iot-platform/core';
import { DynamicListFieldService } from '@iot-platform/iot-platform-ui';
import { CommonApiListResponse, Contact, EmailTemplate, Environment } from '@iot-platform/models/common';
import { ContactNotification, ExportSpreadsheet, I4BApiError, I4BBulkOperationApiResponse } from '@iot-platform/models/i4b';
import { concatMap, forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

// TODO should refactored and remove spécific logic
@Injectable({
  providedIn: 'root'
})
export class ContactsService {
  protected readonly environment: Environment = inject(ENVIRONMENT);
  protected readonly http: HttpClient = inject(HttpClient);
  protected readonly dynamicListFieldService: DynamicListFieldService = inject(DynamicListFieldService);

  public loadContacts(siteId: string): Observable<Contact[]> {
    return this.http
      .get<
        CommonApiListResponse<Contact>
      >(`${this.environment.api.url}${this.environment.api.endpoints.sites}/${siteId}${this.environment.api.endpoints.contacts}`)
      .pipe(map((response) => response.content));
  }

  public addContact(contact: Contact): Observable<Contact> {
    return this.http.post<Contact>(`${this.environment.api.url}${this.environment.api.endpoints.contacts}`, contact);
  }

  public updateContact(contact: Contact): Observable<Contact> {
    return this.http.put<Contact>(`${this.environment.api.url}${this.environment.api.endpoints.contacts}/${contact.id}`, contact);
  }

  public deleteContact(contact: Contact): Observable<Contact> {
    return this.http.delete<Contact>(`${this.environment.api.url}${this.environment.api.endpoints.contacts}/${contact.id}`).pipe(map(() => contact));
  }

  public getClassList() {
    return this.dynamicListFieldService
      .getDynamicList({
        url: '/api/v1/tag-categories?concept=EVENT&joinable=true',
        sortMethod: {
          property: 'name',
          type: 'alphabetically'
        }
      })
      .pipe(map((arr) => arr[0].labels.map((item) => item.name)));
  }

  public getSeverities(): Observable<string[]> {
    return this.http.get<string[]>('/assets/engines/lists/event-severities.json');
  }

  public getExportSpreadsheetsBySite(siteId: string): Observable<ExportSpreadsheet[]> {
    return this.http
      .get<
        CommonApiListResponse<ExportSpreadsheet>
      >(`${this.environment.api.url}${this.environment.api.endpoints.sites}/${siteId}${this.environment.api.endpoints.exportSpreadsheets}`)
      .pipe(map((response) => response.content));
  }

  public addExportSpreadsheet(exportSpreadsheet: ExportSpreadsheet, siteId: string): Observable<ExportSpreadsheet> {
    return this.http.post<ExportSpreadsheet>(
      `${this.environment.api.url}${this.environment.api.endpoints.sites}/${siteId}${this.environment.api.endpoints.exportSpreadsheets}`,
      exportSpreadsheet
    );
  }

  public updateExportSpreadsheet(exportSpreadsheet: ExportSpreadsheet, siteId: string): Observable<ExportSpreadsheet> {
    return this.http.patch<ExportSpreadsheet>(
      `${this.environment.api.url}${this.environment.api.endpoints.sites}/${siteId}${this.environment.api.endpoints.exportSpreadsheets}/${exportSpreadsheet.id}`,
      exportSpreadsheet
    );
  }

  public deleteExportSpreadsheet(exportSpreadsheet: ExportSpreadsheet, siteId: string): Observable<ExportSpreadsheet> {
    return this.http
      .delete<ExportSpreadsheet>(
        `${this.environment.api.url}${this.environment.api.endpoints.sites}/${siteId}${this.environment.api.endpoints.exportSpreadsheets}/${exportSpreadsheet.id}`
      )
      .pipe(map(() => exportSpreadsheet));
  }

  public updateExportSpreadsheets(exportSpreadsheets: ExportSpreadsheet[], siteId: string): Observable<ExportSpreadsheet[]> {
    return forkJoin(
      exportSpreadsheets.reduce((acc: Observable<ExportSpreadsheet>[], value) => {
        acc.push(this.updateExportSpreadsheet(value, siteId));
        return acc;
      }, [])
    );
  }

  public getSpreadsheetExportPreview(
    spreadsheetExport: ExportSpreadsheet,
    siteId: string
  ): Observable<{
    name: string;
    url: string;
  }> {
    return this.http.post<{ name: string; url: string }>(
      `${this.environment.api.url}${this.environment.api.endpoints.sites}/${siteId}${this.environment.api.endpoints.exportSpreadsheets}/preview`,
      spreadsheetExport
    );
  }

  public getEmailTemplatesByEntityId(entityId: string): Observable<EmailTemplate[]> {
    const queryParams = new HttpParams()
      .set('type', 'email')
      .set('workflow', 'event_notification')
      .set('includeParentEntities', true)
      .set('entityId', entityId);
    return this.http
      .get<{
        content: EmailTemplate[];
      }>(`${this.environment.api.url}${this.environment.api.endpoints.notificationTemplates}`, { params: queryParams })
      .pipe(map((data) => data.content));
  }

  public getEmailTemplatesById(emailTemplateId: string): Observable<EmailTemplate | null> {
    return emailTemplateId
      ? this.http.get<EmailTemplate>(`${this.environment.api.url}${this.environment.api.endpoints.notificationTemplates}/${emailTemplateId}`, {})
      : of(null);
  }

  getNotificationsBySite(siteId: string): Observable<ContactNotification[]> {
    return this.http
      .get<
        CommonApiListResponse<ContactNotification>
      >(`${this.environment.api.url}${this.environment.api.endpoints.sites}/${siteId}${this.environment.api.endpoints.topics}`)
      .pipe(map((response) => response.content));
  }

  addNotification(notificationToAdd: ContactNotification, contactIds: string[], siteId: string): Observable<ContactNotification> {
    return this.http
      .post<ContactNotification>(
        `${this.environment.api.url}${this.environment.api.endpoints.sites}/${siteId}${this.environment.api.endpoints.topics}`,
        notificationToAdd
      )
      .pipe(
        concatMap((addedNotification: ContactNotification) => {
          if (contactIds.length) {
            return this.bulkSubscribeManyContactsToOneNotification(contactIds, addedNotification.id).pipe(
              map((response: I4BBulkOperationApiResponse) => {
                if (response.status === 'partial_success' && response.subscribers) {
                  addedNotification.contactIds = Object.entries(response.subscribers)
                    .filter((key: [string, { success: boolean; error?: I4BApiError }]) => key[1].success)
                    .map((sub) => sub[0]);
                } else {
                  addedNotification.contactIds = response.status === 'success' ? [...contactIds] : [];
                }
                return addedNotification;
              })
            );
          } else {
            return of(addedNotification);
          }
        })
      );
  }

  updateNotification(
    notificationToUpdate: ContactNotification,
    siteId: string,
    contactIdsToSub: string[],
    contactIdsToUnsub: string[]
  ): Observable<ContactNotification> {
    return forkJoin([
      contactIdsToSub.length ? this.bulkSubscribeManyContactsToOneNotification(contactIdsToSub, notificationToUpdate.id) : of({}),
      contactIdsToUnsub.length ? this.bulkUnsubscribeManyContactsFromOneNotification(contactIdsToUnsub, notificationToUpdate.id) : of({})
    ]).pipe(
      concatMap(() =>
        this.http.patch<ContactNotification>(
          `${this.environment.api.url}${this.environment.api.endpoints.sites}/${siteId}${this.environment.api.endpoints.topics}/${notificationToUpdate.id}`,
          notificationToUpdate
        )
      )
    );
  }

  deleteNotification(notificationToDelete: ContactNotification, siteId: string): Observable<ContactNotification> {
    return this.http
      .delete<ContactNotification>(
        `${this.environment.api.url}${this.environment.api.endpoints.sites}/${siteId}${this.environment.api.endpoints.topics}/${notificationToDelete.id}`
      )
      .pipe(map(() => notificationToDelete));
  }

  updateContactNotifications(contact: Contact, notificationIdsToBeSubTo: string[], notificationIdsToBeUnsubFrom: string[]): Observable<ContactNotification[]> {
    return forkJoin([
      notificationIdsToBeSubTo.length ? this.bulkSubscribeOneContactToManyNotifications(contact.id, notificationIdsToBeSubTo) : of({}),
      notificationIdsToBeUnsubFrom.length ? this.bulkUnsubscribeOneContactToManyNotifications(contact.id, notificationIdsToBeUnsubFrom) : of({})
    ]).pipe(concatMap(() => this.getNotificationsBySite(contact.site?.id)));
  }

  bulkSubscribeManyContactsToOneNotification(subscriberIds: string[], notificationId: string): Observable<I4BBulkOperationApiResponse> {
    return this.http.post<I4BBulkOperationApiResponse>(`${this.environment.api.url}${this.environment.api.endpoints.topicsBulkSubscribe}`, {
      subscriberIds,
      topicId: notificationId
    });
  }

  bulkUnsubscribeManyContactsFromOneNotification(subscriberIds: string[], notificationId: string): Observable<I4BBulkOperationApiResponse> {
    return this.http.post<I4BBulkOperationApiResponse>(`${this.environment.api.url}${this.environment.api.endpoints.topicsBulkUnsubscribe}`, {
      subscriberIds,
      topicId: notificationId
    });
  }

  bulkSubscribeOneContactToManyNotifications(subscriberId: string, notificationIds: string[]): Observable<I4BBulkOperationApiResponse> {
    return this.http.post<I4BBulkOperationApiResponse>(`${this.environment.api.url}${this.environment.api.endpoints.topicsBulkSubscribe}`, {
      subscriberId,
      topicIds: notificationIds
    });
  }

  bulkUnsubscribeOneContactToManyNotifications(subscriberId: string, notificationIds: string[]): Observable<I4BBulkOperationApiResponse> {
    return this.http.post<I4BBulkOperationApiResponse>(`${this.environment.api.url}${this.environment.api.endpoints.topicsBulkUnsubscribe}`, {
      subscriberId,
      topicIds: notificationIds
    });
  }
}
