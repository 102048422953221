import { AsyncPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AbstractControl, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FormulaType } from '@iot-platform/models/common';
import { DeviceVariable, Formula, LinearizationParameters } from '@iot-platform/models/i4b';
import { AssetVariablesService } from '@iot-platform/shared/services';
import { TranslateModule } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';

@Component({
    imports: [AsyncPipe, FlexLayoutModule, TranslateModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatOptionModule, MatSelectModule],
    selector: 'iot4bos-ui-asset-linearization-parameters-form',
    templateUrl: './linearization-parameters-form.component.html'
})
export class LinearizationParametersFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() deviceVariable?: DeviceVariable;
  @Input() initialFormula!: Formula;
  @Input() readonly = false;

  @Output() dispatchFormula: EventEmitter<Formula | null> = new EventEmitter();

  parametersForm!: UntypedFormGroup;

  assetVariableFormula: Formula = { model: FormulaType.LINEARIZATION, parameters: {}, srcVariables: {} };
  gasList$!: Observable<{ key: string; value: number }[]>;
  subscriptions: Subscription[] = [];

  constructor(private assetVariableService: AssetVariablesService) {}

  get formulaLinearDiameter(): AbstractControl {
    return this.parametersForm.get('formulaLinearDiameter');
  }

  get formulaLinearHeadHeight(): AbstractControl {
    return this.parametersForm.get('formulaLinearHeadHeight');
  }

  get formulaLinearLength(): AbstractControl {
    return this.parametersForm.get('formulaLinearLength');
  }

  get formulaLinearDensity(): AbstractControl {
    return this.parametersForm.get('formulaLinearDensity');
  }

  get formulaLinearMaxDisplay(): AbstractControl {
    return this.parametersForm.get('formulaLinearMaxDisplay');
  }

  get formulaLinearMaxRead(): AbstractControl {
    return this.parametersForm.get('formulaLinearMaxRead');
  }

  ngOnInit() {
    this.gasList$ = this.assetVariableService.getGasList();

    const parameters = this.initialFormula?.parameters as LinearizationParameters;
    this.parametersForm = new UntypedFormGroup({
      formulaLinearHeadHeight: new UntypedFormControl(this.initialFormula ? parameters.headHeight : null),
      formulaLinearDiameter: new UntypedFormControl(this.initialFormula ? parameters.diameter : null),
      formulaLinearLength: new UntypedFormControl(this.initialFormula ? parameters.length : null),
      formulaLinearDensity: new UntypedFormControl(this.initialFormula ? parameters.density : null),
      formulaLinearMaxDisplay: new UntypedFormControl(this.initialFormula ? parameters.maxDisplay : null),
      formulaLinearMaxRead: new UntypedFormControl(this.initialFormula ? parameters.maxRead : null)
    });

    if (this.readonly) {
      this.parametersForm.disable();
    }

    if (this.deviceVariable) {
      this.assetVariableFormula.srcVariables = {
        '0': {
          name: '0',
          variableId: this.deviceVariable.id,
          originId: this.deviceVariable.device?.id,
          type: 'device-variable'
        }
      };
    }

    this.subscriptions.push(this.parametersForm.valueChanges.subscribe(() => this.sendFormula()));
    this.sendFormula();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.deviceVariable) {
      this.assetVariableFormula.srcVariables = {
        '0': {
          name: '0',
          type: 'device-variable',
          variableId: changes.deviceVariable.currentValue?.id ?? null,
          originId: changes.deviceVariable.currentValue?.device.id ?? null
        }
      };

      if (this.parametersForm) {
        this.sendFormula();
      }
    }
  }

  canCalculateLinearFormula(): boolean {
    return (
      this.formulaLinearDiameter.value > 0 &&
      this.formulaLinearHeadHeight.value > 0 &&
      this.formulaLinearLength.value > 0 &&
      this.formulaLinearDensity.value > 0 &&
      this.formulaLinearMaxDisplay.value > 0 &&
      this.formulaLinearMaxRead.value > 0 &&
      this.deviceVariable !== null
    );
  }

  createFormula(): void {
    this.assetVariableFormula.parameters = {
      diameter: this.formulaLinearDiameter.value,
      headHeight: this.formulaLinearHeadHeight.value,
      length: this.formulaLinearLength.value,
      density: this.formulaLinearDensity.value,
      maxDisplay: this.formulaLinearMaxDisplay.value,
      maxRead: this.formulaLinearMaxRead.value
    };
  }

  sendFormula(): void {
    if (this.canCalculateLinearFormula()) {
      this.createFormula();
      this.dispatchFormula.emit(this.assetVariableFormula);
    } else {
      this.dispatchFormula.emit(null);
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
