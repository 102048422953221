import { NgClass, UpperCasePipe } from '@angular/common';
import { Component, effect, input, output, signal, WritableSignal } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIcon } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

import { SortUtil } from '@iot-platform/iot-platform-utils';

import { CRUD, Role } from '@iot-platform/models/common';
import { TranslateModule } from '@ngx-translate/core';
import { RolesCrudCheckboxComponent } from './roles-crud-checkbox/roles-crud-checkbox.component';

@Component({
  imports: [FlexLayoutModule, NgClass, MatIcon, RolesCrudCheckboxComponent, TranslateModule, MatTooltipModule, UpperCasePipe],
  selector: 'iot4bos-ui-backoffice-roles-crud',
  templateUrl: './roles-crud.component.html',
  styleUrls: ['./roles-crud.component.scss']
})
export class RolesCrudComponent {
  role = input<Role>();
  isAdmin = input<boolean>();
  adminConceptsFromEntitySession = input<string[]>();
  readonly = input<boolean>();

  changeAuthorizations = output<{ [concept: string]: CRUD[] }>();

  enabledConcepts: WritableSignal<{ name: string; authorizations; enabled: boolean }[]> = signal([]);
  disabledConcepts: WritableSignal<{ name: string; authorizations; enabled: boolean }[]> = signal([]);
  availableConcepts: WritableSignal<{ name: string; authorizations; enabled: boolean }[]> = signal([]);

  hasAdminConcepts = signal(false);

  roleEffect = effect(() => {
    const role = this.role();
    const adminConceptsFromEntitySession = this.adminConceptsFromEntitySession();
    this.setMatrixRights(role, adminConceptsFromEntitySession);
  });

  enabledConceptsEffect = effect(() => {
    const enabledConcepts = this.enabledConcepts();
    this.changeAuthorizations.emit(RolesCrudComponent.getNewAuthorizations(enabledConcepts));
  });

  static getNewAuthorizations(enabledConcepts: { name: string; authorizations; enabled: boolean }[]): {
    [concept: string]: CRUD[];
  } {
    return enabledConcepts.reduce((acc, value) => {
      acc[value.name] = value.authorizations;
      return acc;
    }, {});
  }

  setMatrixRights(role: Role, adminConceptsFromEntitySession: string[]): void {
    const enabledConcepts = [];
    const disabledConcepts = [];
    const availableConcepts = [];

    Object.entries(role.rights).forEach(([concept, rights]) => {
      if (adminConceptsFromEntitySession && adminConceptsFromEntitySession.indexOf(concept) > -1) {
        enabledConcepts.push({ name: concept, authorizations: rights, enabled: true });
      }
      if ((adminConceptsFromEntitySession && adminConceptsFromEntitySession.indexOf(concept) === -1) || !adminConceptsFromEntitySession) {
        disabledConcepts.push({ name: concept, authorizations: rights, enabled: false });
      }
    });

    if (adminConceptsFromEntitySession) {
      this.hasAdminConcepts.set(true);

      adminConceptsFromEntitySession.forEach((concept) => {
        if (enabledConcepts.find((cpt) => cpt.name === concept) === undefined) {
          availableConcepts.push({ name: concept });
        }
      });
    } else {
      this.hasAdminConcepts.set(false);
    }

    this.enabledConcepts.set(enabledConcepts.sort(SortUtil.sortByName));
    this.disabledConcepts.set(disabledConcepts.sort(SortUtil.sortByName));
    this.availableConcepts.set(availableConcepts.sort(SortUtil.sortByName));
  }

  onToggleAuthorizations(
    newAuthorizations: string[],
    oldConcept: {
      name: string;
      authorizations;
      enabled: boolean;
    }
  ): void {
    this.enabledConcepts.update((value) => {
      const index = value.findIndex((concept) => concept.name === oldConcept.name);
      value[index] = { ...oldConcept, authorizations: newAuthorizations };
      return [...value];
    });
  }

  moveConceptToAvailable(concept): void {
    this.enabledConcepts.update((value) => {
      value.splice(value.indexOf(concept), 1);
      return [...value];
    });
    this.availableConcepts.update((value) => {
      value.push({ ...concept, authorizations: [], enabled: false });
      return [...value];
    });
  }

  moveConceptToEnabled(concept): void {
    this.enabledConcepts.update((value) => {
      value.push({ ...concept, authorizations: [], enabled: true });
      return [...value];
    });
    this.availableConcepts.update((value) => {
      value.splice(value.indexOf(concept), 1);
      return [...value];
    });
  }
}
