import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { AssetGroup } from '@iot-platform/models/i4b';
import { NotificationService } from '@iot-platform/notification';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { concatMap, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { AssetGroupsService } from '../../services/asset-groups.service';
import { AssetGroupsActions } from '../actions';

const getAssetGroup$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), assetGroupsService = inject(AssetGroupsService)) =>
    actions$.pipe(
      ofType(AssetGroupsActions.getAssetGroup),
      concatMap(({ assetGroupId }) =>
        assetGroupsService.getAssetGroup(assetGroupId).pipe(
          map((response: AssetGroup) => AssetGroupsActions.getAssetGroupSuccess({ assetGroup: response })),
          catchError((error) => of(AssetGroupsActions.getAssetGroupFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const addAssetGroup$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), assetGroupsService = inject(AssetGroupsService)) =>
    actions$.pipe(
      ofType(AssetGroupsActions.addAssetGroup),
      concatMap(({ assetGroup }) =>
        assetGroupsService.addAssetGroup(assetGroup).pipe(
          map((response: AssetGroup) => AssetGroupsActions.addAssetGroupSuccess({ assetGroup: response })),
          catchError((error) => of(AssetGroupsActions.addAssetGroupFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const addAssetGroupSuccess$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), router = inject(Router)) =>
    actions$.pipe(
      ofType(AssetGroupsActions.addAssetGroupSuccess),
      map((action) => action.assetGroup),
      tap((assetGroup: AssetGroup) => {
        router.navigate(['asset-groups', assetGroup.id]);
      })
    ),
  { functional: true, dispatch: false }
);

const editAssetGroup$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), assetGroupsService = inject(AssetGroupsService)) =>
    actions$.pipe(
      ofType(AssetGroupsActions.editAssetGroup),
      concatMap(({ assetGroup }) =>
        assetGroupsService.editAssetGroup(assetGroup).pipe(
          map((response: AssetGroup) => AssetGroupsActions.editAssetGroupSuccess({ assetGroup: response })),
          catchError((error) => of(AssetGroupsActions.editAssetGroupFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const updateRelatedAssets$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), assetGroupsService = inject(AssetGroupsService)) =>
    actions$.pipe(
      ofType(AssetGroupsActions.updateRelatedAssets),
      concatMap(({ assetGroupId, assetIds }) =>
        assetGroupsService.updateRelatedAssets(assetGroupId, assetIds).pipe(
          map((response: AssetGroup) => AssetGroupsActions.updateRelatedAssetsSuccess({ assetGroup: response })),
          catchError((error) => of(AssetGroupsActions.updateRelatedAssetsFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const showLoader$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(AssetGroupsActions.getAssetGroup, AssetGroupsActions.addAssetGroup, AssetGroupsActions.editAssetGroup, AssetGroupsActions.updateRelatedAssets),
      tap(() => notificationService.showLoader())
    ),
  { functional: true, dispatch: false }
);

const hideLoader$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(
        AssetGroupsActions.getAssetGroupSuccess,
        AssetGroupsActions.getAssetGroupFailure,
        AssetGroupsActions.addAssetGroupSuccess,
        AssetGroupsActions.addAssetGroupFailure,
        AssetGroupsActions.editAssetGroupSuccess,
        AssetGroupsActions.editAssetGroupFailure,
        AssetGroupsActions.updateRelatedAssetsSuccess,
        AssetGroupsActions.updateRelatedAssetsFailure
      ),
      tap(() => notificationService.hideLoader())
    ),
  { functional: true, dispatch: false }
);

const displayError$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(
        AssetGroupsActions.getAssetGroupFailure,
        AssetGroupsActions.addAssetGroupFailure,
        AssetGroupsActions.editAssetGroupFailure,
        AssetGroupsActions.updateRelatedAssetsFailure
      ),
      tap((action: Action) => notificationService.displayError(action))
    ),
  { functional: true, dispatch: false }
);

const displaySuccess$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(AssetGroupsActions.addAssetGroupSuccess, AssetGroupsActions.editAssetGroupSuccess, AssetGroupsActions.updateRelatedAssetsSuccess),
      tap((action: Action) => notificationService.displaySuccess(action.type))
    ),
  { functional: true, dispatch: false }
);

export const AssetGroupsEffects = {
  getAssetGroup$,
  addAssetGroup$,
  addAssetGroupSuccess$,
  editAssetGroup$,
  updateRelatedAssets$,
  showLoader$,
  hideLoader$,
  displayError$,
  displaySuccess$
};
