import { Injectable } from '@angular/core';
import { BaseFacade, CommonApiRequest, Filter, Pagination, TagCategory } from '@iot-platform/models/common';
import { Store } from '@ngrx/store';
import { noop } from 'rxjs';
import { AdminBusinessProfilesTagsActions } from '../actions/business-profiles-tags.actions';
import { AdminBusinessProfilesTagsSelectors } from '../selectors/admin-business-profiles-tags.selectors';

@Injectable({
  providedIn: 'root'
})
export class AdminBusinessProfilesTagsFacade extends BaseFacade<TagCategory, Pagination, Filter> {
  constructor(
    protected override store: Store,
    protected override selector: AdminBusinessProfilesTagsSelectors
  ) {
    super(store, selector);
  }

  getAll(request: CommonApiRequest) {
    noop();
  }

  setFilters() {
    noop();
  }

  updateTags(businessProfileId: string, tagsToAdd: TagCategory[]) {
    this.store.dispatch(
      AdminBusinessProfilesTagsActions.updateTagsForBusinessProfile({
        businessProfileId,
        tagsToAdd
      })
    );
  }

  allWithSortedLabels = this.store.selectSignal(this.selector.selectAllWithSortedLabels);
}
