import { Component, DestroyRef, inject, Injector, input, Input, model, output } from '@angular/core';
import { MasterViewEngineEvent } from '@iot-platform/models/common';

@Component({
  template: '',
  standalone: false
})
export abstract class AbstractTableEngineHeaderCellComponent<T> {
  data = model<any>();
  rawData = input<any>();
  columnFormat = input<any>();

  dispatchEvent = output<MasterViewEngineEvent>();
  @Input() onDispatchEvent!: (event: MasterViewEngineEvent) => void;

  protected injector: Injector = inject(Injector);
  protected destroyRef: DestroyRef = inject(DestroyRef);

  protected dispatchCellEvent(event: MasterViewEngineEvent) {
    this.dispatchEvent.emit(event);
    this.onDispatchEvent?.(event);
  }
}
