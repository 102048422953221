import { Component, computed, DestroyRef, effect, inject, input, Signal, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed, toObservable, toSignal } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DateIntervalUtils, SortUtil } from '@iot-platform/iot-platform-utils';
import { RichVariableColumn as VariablesHelper } from '@iot-platform/models/grid-engine';
import { AssetVariable, AssetVariableThreshold } from '@iot-platform/models/i4b';
import { LastValueWidgetOptions, WidgetInstance } from '@iot-platform/models/widgets';
import { DateFormatPipe, ValueUnitFormatPipe } from '@iot-platform/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { Moment } from 'moment-timezone';
import * as moment from 'moment/moment';
import { of } from 'rxjs';
import { catchError, filter, finalize, map, switchMap, tap } from 'rxjs/operators';
import { WidgetsService } from '../../services/widgets.service';

interface EventIcon {
  displayIcon: boolean;
  name: string;
  tooltip: string;
}

@Component({
    imports: [
        MatCardModule,
        MatToolbarModule,
        MatIconModule,
        MatTooltipModule,
        MatProgressSpinnerModule,
        FlexLayoutModule,
        TranslateModule,
        ValueUnitFormatPipe,
        DateFormatPipe
    ],
    selector: 'iot-platform-ui-last-value-widget',
    templateUrl: './last-value-widget.component.html',
    styleUrls: ['./last-value-widget.component.scss']
})
export class LastValueWidgetComponent {
  private readonly widgetsService: WidgetsService = inject(WidgetsService);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  widget = input<WidgetInstance>();

  widgetOptions = computed(() => {
    const widget = this.widget();
    if (widget) {
      return widget.options as LastValueWidgetOptions;
    }
    return null;
  });
  variableLoading: WritableSignal<boolean> = signal(true);
  variable: Signal<AssetVariable | undefined> = toSignal(
    toObservable(this.widgetOptions).pipe(
      filter((options) => !!options),
      tap((_) => this.variableLoading.set(true)),
      switchMap((options) =>
        this.widgetsService.getAssetVariableById(options?.variable?.id).pipe(
          map(({ thresholds, ...rest }: AssetVariable) => ({
            ...rest,
            thresholds: { ...thresholds, values: thresholds.values.sort(SortUtil.sortByProperty('position')) }
          })),
          catchError(() => of(undefined)),
          finalize(() => this.variableLoading.set(false))
        )
      ),
      takeUntilDestroyed(this.destroyRef)
    )
  );
  activatedThreshold: WritableSignal<AssetVariableThreshold | null> = signal(null);
  eventIconParameters: WritableSignal<EventIcon> = signal({ displayIcon: false, tooltip: '', name: '' });
  isRecentDay: WritableSignal<boolean> = signal(false);
  isRecentHour: WritableSignal<boolean> = signal(false);
  isFutureDay: WritableSignal<boolean> = signal(false);
  isNextDay: WritableSignal<boolean> = signal(false);

  initEffect = effect(
    () => {
      const variable = this.variable();
      if (variable) {
        this.activatedThreshold.set(LastValueWidgetComponent.getActivatedThreshold(variable));
        this.setEventIconParameters(variable);
        this.processDateIntervals(moment(), variable.lastValue?.datetime ?? '');
      }
    }
  );

  static getActivatedThreshold(variable: AssetVariable): AssetVariableThreshold | null {
    if (variable.lastValue) {
      const nearestThreshold = VariablesHelper.getNearestThreshold(variable, variable.lastValue?.value);
      return VariablesHelper.isValidThresholdCondition(nearestThreshold) ? nearestThreshold : null;
    } else {
      return null;
    }
  }

  setEventIconParameters(variable: AssetVariable): void {
    if (!!variable.totalActiveEvents && !variable.totalAcknowledgedEvents) {
      this.eventIconParameters.set({ displayIcon: true, name: 'active', tooltip: 'WIDGETS.LAST_VALUE.EVENT_ICON.TOOLTIPS.ACTIVE' });
    } else if (!!variable.totalActiveEvents && !!variable.totalAcknowledgedEvents) {
      this.eventIconParameters.set({ displayIcon: true, name: 'ongoing', tooltip: 'WIDGETS.LAST_VALUE.EVENT_ICON.TOOLTIPS.ONGOING' });
    } else if (!variable.totalActiveEvents && !!variable.totalAcknowledgedEvents) {
      this.eventIconParameters.set({ displayIcon: true, name: 'acknowledged', tooltip: 'WIDGETS.LAST_VALUE.EVENT_ICON.TOOLTIPS.ACKNOWLEDGED' });
    } else {
      this.eventIconParameters.set({ displayIcon: false, tooltip: '', name: '' });
    }
  }

  processDateIntervals(now: Moment, date: string): void {
    this.isRecentDay.set(DateIntervalUtils.isDateInThePastDay(now, date));
    this.isRecentHour.set(DateIntervalUtils.isDateInThePastHour(now, date));
    this.isNextDay.set(DateIntervalUtils.isDateInTheNextDay(now, date));
    this.isFutureDay.set(DateIntervalUtils.isDateInTheFutureDays(now, date));
  }
}
