import { inject, Injectable } from '@angular/core';
import { AbstractMasterViewService } from '@iot-platform/feature/master-view';
import { IotToolbarDefaultButton } from '@iot-platform/iot-platform-ui';
import { FavoriteView, Filter } from '@iot-platform/models/common';
import { I4BGrid, I4BGridData, I4BGridOptions } from '@iot-platform/models/grid-engine';
import { noop, Observable, of } from 'rxjs';
import { AbstractDeviceCallLogsService } from './abstract-device-call-logs.service';

@Injectable({
  providedIn: 'root'
})
export class DeviceCallLogsMasterViewService extends AbstractMasterViewService {
  protected readonly deviceCallLogsService: AbstractDeviceCallLogsService = inject(AbstractDeviceCallLogsService);

  getPermissions(): Observable<{ key: string; value: boolean }[]> {
    return of([]);
  }

  getInitialFilters(): Observable<Filter[]> {
    return of([]);
  }

  getStaticGridDefinition(): Observable<I4BGrid<I4BGridOptions, I4BGridData> | null> {
    return this.deviceCallLogsService.getStaticGridDefinition();
  }

  onMasterViewEngineEvent({ event }): void {
    switch (event.type) {
      case 'rowClicked':
      case 'ArrowUp':
      case 'ArrowDown': {
        if (event.data) {
          this.deviceCallLogsService.onOpen(event.data);
        }
        break;
      }
      case 'downloadMessage': {
        this.deviceCallLogsService.onDownloadMessage();
        break;
      }
    }
  }

  onToolbarEvent() {
    noop();
  }

  getGridConfiguration(): Observable<{
    sortedGridsWithoutAppDefault: I4BGrid<I4BGridOptions, I4BGridData>[];
    currentGrid: I4BGrid<I4BGridOptions, I4BGridData> | undefined;
    isGridsLoading: boolean;
  } | null> {
    return of(null);
  }

  getGrids(): Observable<I4BGrid<I4BGridOptions, I4BGridData>[]> {
    return of([]);
  }

  getToolbarButtons(): Observable<IotToolbarDefaultButton[]> {
    return of([]);
  }

  getFavoriteViewsConfiguration(): Observable<{
    sortedFavoriteViews: FavoriteView[];
    currentFavoriteView: FavoriteView | undefined;
    isFavoriteViewsLoading: boolean;
  } | null> {
    return of(null);
  }

  canUpdateBusinessProfile(): Observable<boolean> {
    return of(false);
  }

  onBusinessProfileChange() {
    noop();
  }
}
