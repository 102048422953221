import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthBusinessProfilesApiActions } from '@iot-platform/auth';
import { FavoriteView } from '@iot-platform/models/common';
import { NotificationService } from '@iot-platform/notification';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, mergeMap, of, switchMap, tap } from 'rxjs';
import { FavoriteViewsService } from '../../services/favorite-views.service';
import { FavoriteViewsActions } from '../actions';

@Injectable()
export class FavoriteViewsEffects {
  loadFavoriteViews$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FavoriteViewsActions.loadFavoriteViews, FavoriteViewsActions.deleteFavoriteViewSuccess),
      concatMap(() =>
        this.favoriteViewsService.getFavoriteViews().pipe(
          concatMap((favoriteViews) => {
            if (this.router.url === '/home') {
              const pinnedFavoriteViews: FavoriteView[] = favoriteViews.filter((fV) => fV.pinned);
              return [
                FavoriteViewsActions.loadFavoriteViewsSuccess({ favoriteViews }),
                ...pinnedFavoriteViews.map((favoriteView: FavoriteView) => FavoriteViewsActions.getCountForFavoriteView({ favoriteView }))
              ];
            } else {
              return [FavoriteViewsActions.loadFavoriteViewsSuccess({ favoriteViews })];
            }
          }),
          catchError((error) => of(FavoriteViewsActions.loadFavoriteViewsError({ error })))
        )
      )
    )
  );

  loadFavoriteViewsWithCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FavoriteViewsActions.loadFavoriteViewsWithCount),
      switchMap(() =>
        this.favoriteViewsService.getFavoriteViews().pipe(
          concatMap((favoriteViews) => {
            const pinnedFavoriteViews: FavoriteView[] = favoriteViews.filter((fV) => fV.pinned);
            return [
              FavoriteViewsActions.loadFavoriteViewsSuccess({ favoriteViews }),
              ...pinnedFavoriteViews.map((favoriteView: FavoriteView) => FavoriteViewsActions.getCountForFavoriteView({ favoriteView }))
            ];
          }),
          catchError((error) => of(FavoriteViewsActions.loadFavoriteViewsError({ error })))
        )
      )
    )
  );

  getCountForFavoriteView$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FavoriteViewsActions.getCountForFavoriteView),
      mergeMap((action) =>
        this.favoriteViewsService.getFavoriteViewCount(action.favoriteView).pipe(
          map((favoriteView: FavoriteView) => FavoriteViewsActions.getCountForFavoriteViewSuccess({ favoriteView })),
          catchError((error) => of(FavoriteViewsActions.getCountForFavoriteViewFailure({ error })))
        )
      )
    )
  );

  setCurrentFavoriteView$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FavoriteViewsActions.setCurrentFavoriteView),
        tap((action) => {
          if (action.favoriteView) {
            this.router.navigate(['/' + action.favoriteView.masterView]);
          }
        })
      ),
    { dispatch: false }
  );

  loadFavoriteViewsWhenSwitchingBusinessProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthBusinessProfilesApiActions.selectBusinessProfileSuccess),
      map(() => {
        if (this.router.url === '/home') {
          return FavoriteViewsActions.loadFavoriteViewsWithCount();
        } else {
          return FavoriteViewsActions.loadFavoriteViews();
        }
      })
    )
  );

  saveFavoriteViews$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FavoriteViewsActions.addFavoriteView),
      concatMap(({ favoriteView }) =>
        this.favoriteViewsService.saveFavoriteView(favoriteView).pipe(
          concatMap((fV) => [
            FavoriteViewsActions.addFavoriteViewSuccess({ favoriteView: fV }),
            FavoriteViewsActions.synchronizeFavoriteViewAfterSave({ favoriteView: fV })
          ]),
          catchError((error) => of(FavoriteViewsActions.addFavoriteViewError({ error })))
        )
      )
    )
  );

  updateFavoriteView$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FavoriteViewsActions.updateFavoriteView),
      concatMap(({ favoriteView }) =>
        this.favoriteViewsService.updateFavoriteView(favoriteView).pipe(
          map((updatedFavoriteView) => FavoriteViewsActions.updateFavoriteViewSuccess({ favoriteView: updatedFavoriteView })),
          catchError((error) => of(FavoriteViewsActions.updateFavoriteViewError({ error })))
        )
      )
    )
  );

  shareGridThenSaveFavoriteView$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FavoriteViewsActions.shareGridThenAddFavoriteView),
      concatMap((action) =>
        this.favoriteViewsService.shareGrid(action.grid, action.favoriteView).pipe(
          concatMap(({ grid, favoriteView }) => [
            FavoriteViewsActions.addFavoriteView({ favoriteView }),
            FavoriteViewsActions.shareGridThenUpdateGridSuccess({ grid })
          ]),
          catchError(() => of(FavoriteViewsActions.shareGridThenAddFavoriteViewError))
        )
      )
    )
  );

  shareGridThenUpdateFavoriteView$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FavoriteViewsActions.shareGridThenUpdateFavoriteView),
      concatMap((action) =>
        this.favoriteViewsService.shareGrid(action.grid, action.favoriteView).pipe(
          concatMap(({ grid, favoriteView }) => [
            FavoriteViewsActions.updateFavoriteView({ favoriteView }),
            FavoriteViewsActions.shareGridThenUpdateGridSuccess({ grid })
          ]),
          catchError(() => of(FavoriteViewsActions.shareGridThenUpdateFavoriteViewError))
        )
      )
    )
  );

  deleteFavoriteView$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FavoriteViewsActions.deleteFavoriteView),
      concatMap(({ favoriteView }) =>
        this.favoriteViewsService.deleteFavoriteView(favoriteView).pipe(
          concatMap((deletedFavoriteView) => [FavoriteViewsActions.deleteFavoriteViewSuccess({ deletedFavoriteView })]),
          catchError((error) => of(FavoriteViewsActions.deleteFavoriteViewError({ error })))
        )
      )
    )
  );

  addFavoriteViewFromMV$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FavoriteViewsActions.addFavoriteViewFromMV),
      concatMap(({ favoriteView }) =>
        this.favoriteViewsService.saveFavoriteView(favoriteView).pipe(
          concatMap((fV) => [
            FavoriteViewsActions.addFavoriteViewFromMVSuccess({ favoriteView: fV }),
            FavoriteViewsActions.synchronizeFavoriteViewAfterSave({ favoriteView: fV })
          ]),
          catchError((error) => of(FavoriteViewsActions.addFavoriteViewFromMVError({ error })))
        )
      )
    )
  );

  updateFavoriteViewFromMV$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FavoriteViewsActions.updateFavoriteViewFromMV),
      concatMap(({ favoriteView }) =>
        this.favoriteViewsService.updateFavoriteView(favoriteView).pipe(
          concatMap((fV) => [FavoriteViewsActions.updateFavoriteViewFromMVSuccess({ favoriteView: fV })]),
          catchError((error) => of(FavoriteViewsActions.updateFavoriteViewFromMVError({ error })))
        )
      )
    )
  );

  shareGridThenSaveFavoriteViewFromMV$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FavoriteViewsActions.shareGridThenAddFavoriteViewFromMV),
      concatMap((action) =>
        this.favoriteViewsService.shareGrid(action.grid, action.favoriteView).pipe(
          concatMap(({ grid, favoriteView }) => [
            FavoriteViewsActions.addFavoriteViewFromMV({ favoriteView }),
            FavoriteViewsActions.shareGridThenUpdateGridSuccess({ grid })
          ]),
          catchError(() => of(FavoriteViewsActions.shareGridThenAddFavoriteViewFromMVError))
        )
      )
    )
  );

  shareGridThenUpdateFavoriteViewFromMV$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FavoriteViewsActions.shareGridThenUpdateFavoriteViewFromMV),
      concatMap((action) =>
        this.favoriteViewsService.shareGrid(action.grid, action.favoriteView).pipe(
          concatMap(({ grid, favoriteView }) => [
            FavoriteViewsActions.updateFavoriteViewFromMV({ favoriteView }),
            FavoriteViewsActions.shareGridThenUpdateGridSuccess({ grid })
          ]),
          catchError(() => of(FavoriteViewsActions.shareGridThenUpdateFavoriteViewFromMVError))
        )
      )
    )
  );

  displaySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          FavoriteViewsActions.addFavoriteViewSuccess,
          FavoriteViewsActions.addFavoriteViewFromMVSuccess,
          FavoriteViewsActions.updateFavoriteViewSuccess,
          FavoriteViewsActions.updateFavoriteViewFromMVSuccess,
          FavoriteViewsActions.duplicateFavoriteViewSuccess,
          FavoriteViewsActions.deleteFavoriteViewSuccess
        ),
        tap((action) => this.notificationService.displaySuccess(action.type))
      ),
    { dispatch: false }
  );

  displayError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          FavoriteViewsActions.addFavoriteViewError,
          FavoriteViewsActions.addFavoriteViewFromMVError,
          FavoriteViewsActions.updateFavoriteViewError,
          FavoriteViewsActions.updateFavoriteViewFromMVError,
          FavoriteViewsActions.duplicateFavoriteViewError,
          FavoriteViewsActions.deleteFavoriteViewError
        ),
        tap((action) => this.notificationService.displayError(action))
      ),
    { dispatch: false }
  );

  showLoader$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FavoriteViewsActions.loadFavoriteViews),
        tap(() => this.notificationService.showLoader())
      ),
    { dispatch: false }
  );

  hideLoader$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FavoriteViewsActions.loadFavoriteViewsError, FavoriteViewsActions.loadFavoriteViewsSuccess),
        tap(() => this.notificationService.hideLoader())
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private favoriteViewsService: FavoriteViewsService,
    private notificationService: NotificationService,
    private router: Router
  ) {}
}
