import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { fromGrids, GridsDbActions } from '@iot-platform/grid-engine';
import { NotificationService } from '@iot-platform/notification';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action, Store } from '@ngrx/store';

import { mergeMap, of } from 'rxjs';
import { catchError, concatMap, map, tap } from 'rxjs/operators';
import { CalendarsService } from '../../services/calendars.service';
import { CalendarsActions, CalendarsDaysOffActions } from '../actions';

const getOne$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), calendarsService = inject(CalendarsService)) =>
    actions$.pipe(
      ofType(CalendarsActions.getOne),
      concatMap((action) =>
        calendarsService.getOne(action.id).pipe(
          map((element) => CalendarsActions.getOneSuccess({ element })),
          catchError((error) => of(CalendarsActions.getOneFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const addOne$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), calendarsService = inject(CalendarsService)) =>
    actions$.pipe(
      ofType(CalendarsActions.addOne),
      concatMap((action) =>
        calendarsService.addOne(action.toAdd).pipe(
          map((added) => CalendarsActions.addOneSuccess({ added })),
          catchError((error) => of(CalendarsActions.addOneFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const addOneSuccessThenOpenDetails$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(CalendarsActions.addOneSuccess),
      map((action) => CalendarsActions.navigateToCalendarDetails({ calendarToNavigate: action.added }))
    ),
  { functional: true }
);

const updateOne$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), calendarsService = inject(CalendarsService), store = inject(Store)) =>
    actions$.pipe(
      ofType(CalendarsActions.updateOne),
      concatLatestFrom(() => store.select(fromGrids.selectDefaultCalendarsGrid)),
      concatMap(([action, grid]) =>
        calendarsService.updateOne(action.toUpdate).pipe(
          concatMap((updated) => {
            const DEFAULT_ACTIONS = [CalendarsActions.updateOneSuccess({ updated })];
            return grid?.id
              ? [
                  ...DEFAULT_ACTIONS,
                  GridsDbActions.updateItemInGridData({
                    gridId: grid.id,
                    item: updated,
                    concept: 'calendars'
                  })
                ]
              : DEFAULT_ACTIONS;
          }),
          catchError((error) => of(CalendarsActions.updateOneFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const deleteOne$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), calendarsService = inject(CalendarsService)) =>
    actions$.pipe(
      ofType(CalendarsActions.deleteOne),
      concatMap((action) =>
        calendarsService.deleteOne(action.toDelete).pipe(
          map((deleted) => CalendarsActions.deleteOneSuccess({ deleted })),
          catchError((error) => of(CalendarsActions.deleteOneFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const deleteOneSuccessThenReturnToMV$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), router = inject(Router), store = inject(Store)) =>
    actions$.pipe(
      ofType(CalendarsActions.deleteOneSuccess),
      concatLatestFrom(() => store.select(fromGrids.selectDefaultCalendarsGrid)),
      mergeMap(([action, grid]) =>
        grid?.id
          ? of(
              GridsDbActions.removeItemInGridData({
                gridId: grid.id,
                item: action.deleted
              })
            )
          : of(null)
      ),
      tap(() => router.navigate(['on-call-management', 'calendars']))
    ),
  { functional: true }
);

const navigateToCalendarDetails$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), router = inject(Router)) =>
    actions$.pipe(
      ofType(CalendarsActions.navigateToCalendarDetails),
      tap((action) => router.navigate(['on-call-management', 'calendars', action.calendarToNavigate.id])),
      map((action) => CalendarsDaysOffActions.loadDaysOff({ calendarId: action.calendarToNavigate.id }))
    ),
  { functional: true }
);

const showLoader$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(CalendarsActions.loadCalendars, CalendarsActions.addOne, CalendarsActions.updateOne, CalendarsActions.deleteOne, CalendarsActions.getOne),
      tap(() => notificationService.showLoader())
    ),
  { functional: true, dispatch: false }
);

const hideLoader$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(
        CalendarsActions.loadCalendarsSuccess,
        CalendarsActions.loadCalendarsFailure,
        CalendarsActions.addOneSuccess,
        CalendarsActions.addOneFailure,
        CalendarsActions.updateOneSuccess,
        CalendarsActions.updateOneFailure,
        CalendarsActions.deleteOneSuccess,
        CalendarsActions.deleteOneFailure,
        CalendarsActions.getOneSuccess,
        CalendarsActions.getOneFailure
      ),
      tap(() => notificationService.hideLoader())
    ),
  { functional: true, dispatch: false }
);

const displayError$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(
        CalendarsActions.loadCalendarsFailure,
        CalendarsActions.getOneFailure,
        CalendarsActions.addOneFailure,
        CalendarsActions.updateOneFailure,
        CalendarsActions.deleteOneFailure
      ),
      tap((action: Action) => notificationService.displayError(action))
    ),
  { functional: true, dispatch: false }
);

const displaySuccess$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(CalendarsActions.addOneSuccess, CalendarsActions.updateOneSuccess, CalendarsActions.deleteOneSuccess, CalendarsActions.getOneSuccess),
      tap((action: Action) => notificationService.displaySuccess(action.type))
    ),
  { functional: true, dispatch: false }
);

export const CalendarsEffects = {
  getOne$,
  addOne$,
  updateOne$,
  deleteOne$,
  navigateToCalendarDetails$,
  addOneSuccessThenOpenDetails$,
  deleteOneSuccessThenReturnToMV$,
  showLoader$,
  hideLoader$,
  displayError$,
  displaySuccess$
};
