import { UpperCasePipe } from '@angular/common';
import { Component, DestroyRef, effect, inject, Signal, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed, toObservable, toSignal } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { AsyncAutocompleteModule } from '@iot-platform/iot-platform-ui';
import { SortUtil } from '@iot-platform/iot-platform-utils';
import { BaseUser, CommonApiRequest, Organization } from '@iot-platform/models/common';
import { DateFormatPipe } from '@iot-platform/pipes';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { filter, map, switchMap } from 'rxjs';
import { AdminOrganizationsAdministratorsPageActions } from '../../../features/admin-organizations/state/actions';
import * as fromOrganizations from '../../../features/admin-organizations/state/reducers/';
import { UsersService } from '../../../features/users/services/users.service';

@Component({
  imports: [
    FlexLayoutModule,
    MatCardModule,
    MatToolbarModule,
    MatIcon,
    AsyncAutocompleteModule,
    MatListModule,
    MatFormFieldModule,
    MatButtonModule,
    TranslateModule,
    DateFormatPipe,
    UpperCasePipe,
    MatInputModule
  ],
  selector: 'iot4bos-ui-backoffice-manage-administrators-dialog',
  templateUrl: './manage-administrators-dialog.component.html',
  styleUrls: ['./manage-administrators-dialog.component.scss', '../../../style/admin.style.scss']
})
export class ManageAdministratorsDialogComponent {
  private readonly dialogRef: MatDialogRef<ManageAdministratorsDialogComponent> = inject(MatDialogRef<ManageAdministratorsDialogComponent>);
  private readonly store: Store = inject(Store);
  private readonly usersService: UsersService = inject(UsersService);
  private readonly destroy: DestroyRef = inject(DestroyRef);
  private filterUnselected;
  private filterSelected;
  public data: { organization: Organization } = inject(MAT_DIALOG_DATA);
  filteredCurrentAdministrators: WritableSignal<BaseUser[]> = signal([]);
  getUsersRequest: WritableSignal<CommonApiRequest | null> = signal(null);
  usersLoading: WritableSignal<boolean> = signal(false);
  unselectedUsers: WritableSignal<BaseUser[]> = signal([]);
  filteredUnselectedUsers: WritableSignal<BaseUser[]> = signal([]);
  currentAdministrators: Signal<BaseUser[]> = this.store.selectSignal(fromOrganizations.getAllAdministrators);
  allUsers: Signal<BaseUser[]> = toSignal(
    toObservable(this.getUsersRequest).pipe(
      filter((request) => !!request),
      switchMap((request) => this.usersService.getUsers(request).pipe(map((response) => response.data))),
      takeUntilDestroyed(this.destroy)
    )
  );
  currentAdministratorsEffect = effect(() => {
    const administrators = this.currentAdministrators();
    const allUsers = this.allUsers();

    this.unselectedUsers.set(this.checkAvailableUsers(allUsers).sort(SortUtil.sortByProperty('lastname')));
    this.filteredUnselectedUsers.set(this.checkAvailableUsers(allUsers).sort(SortUtil.sortByProperty('lastname')));

    if (this.filterSelected) {
      this.filterUsers(this.filterSelected, administrators, true);
    } else {
      this.filteredCurrentAdministrators.set(administrators);
    }

    if (this.filterUnselected) {
      this.filterUsers(this.filterUnselected, this.unselectedUsers(), false);
    }
  });

  onSearchUsers(term: string): void {
    const request: CommonApiRequest = {
      limit: 1000,
      filters: [
        { criteriaKey: 'userEmail', value: term },
        { criteriaKey: 'isShared', value: false },
        { criteriaKey: 'userStatus', value: 'active' }
      ]
    };
    this.getUsersRequest.set(request);
  }

  displayUserWrapper(user: BaseUser): string {
    return user ? `${user.firstname} ${user.lastname}` : '';
  }

  removeAdministratorFromOrganization(administrator: BaseUser) {
    this.store.dispatch(
      AdminOrganizationsAdministratorsPageActions.removeAdministratorFromOrganization({
        organizationId: this.data.organization.id,
        administratorToRemove: administrator
      })
    );
  }

  addAdministratorToOrganization(administrator: BaseUser) {
    this.store.dispatch(
      AdminOrganizationsAdministratorsPageActions.addAdministratorToOrganization({
        organizationId: this.data.organization.id,
        administratorToAdd: administrator
      })
    );
  }

  filterUsers(event, listToFilter: BaseUser[], areUsersSelected: boolean): void {
    if (areUsersSelected) {
      this.filterSelected = event;
      this.filteredCurrentAdministrators.set(this.getFilteredUsers(listToFilter, this.filterSelected));
    } else {
      this.filterUnselected = event;
      this.filteredUnselectedUsers.set(this.getFilteredUsers(listToFilter, this.filterUnselected));
    }
  }

  getFilteredUsers(listToFilter: BaseUser[], filterEvent): BaseUser[] {
    return listToFilter.filter(
      (user) =>
        user.lastname.toLowerCase().includes(filterEvent.target.value.toLowerCase()) ||
        user.firstname.toLowerCase().includes(filterEvent.target.value.toLowerCase()) ||
        user.email.toLowerCase().includes(filterEvent.target.value.toLowerCase())
    );
  }

  exit() {
    this.dialogRef.close(true);
  }

  private checkAvailableUsers(usersByOrganization: BaseUser[]): BaseUser[] {
    const available: BaseUser[] = [];
    usersByOrganization?.forEach((user) => {
      if (this.currentAdministrators().indexOf(this.currentAdministrators().find((u) => user.id === u.id)) === -1) {
        available.push(user);
      }
    });
    return available;
  }
}
