<router-outlet></router-outlet>
@if (site()) {
  <div class="site-info site-info__wrapper" fxLayout="column" fxLayoutGap="30px">
    <iot-platform-ui-overview-card [overviewCard]="overviewCard()" [loading]="!site()" (cardButtonClicked)="onCardActions($event)" />

    <iot-platform-ui-section-header
      [title]="sectionHeaderTitle() | translate"
      [titleActions]="sectionHeaderTitleActions()"
      [actions]="sectionHeaderActions()"
      [actionsType]="SectionHeaderActionsType.MENU"
      (dispatchEvent)="onDispatchSectionHeaderEvent($event)"
    />

    @if (preferences()?.siteAssociationDisplay === 'devices') {
      <iot4bos-ui-site-association-by-device
        [association]="associationByDevice()"
        [laoding]="associationByDeviceLoading()"
        (navigateToAsset)="onNavigateToAsset($event)"
        (navigateToDevice)="onNavigateToDevice($event)"
      />
    } @else {
      <iot4bos-ui-site-association-by-asset
        [association]="associationByAsset()"
        [laoding]="associationByAssetLoading()"
        (navigateToAssetGroup)="onNavigateToAssetGroup($event)"
        (navigateToAsset)="onNavigateToAsset($event)"
        (navigateToDevice)="onNavigateToDevice($event)"
      />
    }
  </div>
}
