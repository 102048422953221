import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Directive, effect, ElementRef, inject, Injector, input, OnDestroy, Renderer2, signal, WritableSignal } from '@angular/core';
import { Pagination } from '@iot-platform/models/common';
import { debounce, get } from 'lodash';

/* eslint-disable  @angular-eslint/directive-selector */
@Directive({
  selector: '[i4b-table-engine-master-view-table-full-height]'
})
export class TableFullHeightDirective implements AfterViewInit, OnDestroy {
  private readonly defaultSelectors: string[] = ['body', 'iot-platform-ui-filter-engine-container', '.filter-engine-row'];
  private readonly elementRef: ElementRef = inject(ElementRef);
  private readonly document: Document = inject(DOCUMENT);
  private readonly renderer: Renderer2 = inject(Renderer2);
  private readonly injector: Injector = inject(Injector);
  pagination = input<Pagination>();
  sticky = input<boolean>(false);
  data = input<any[]>([]);
  observerSelectors = input<string[]>([]);
  elementSelectors: WritableSignal<(Element | null)[]> = signal([]);
  observer = new ResizeObserver(
    debounce(() => {
      this.resize();
    }, 250)
  );

  ngAfterViewInit(): void {
    effect(
      () => {
        const timeout = setTimeout(() => {
          const observerSelectors = this.observerSelectors();
          const elementSelectors = [...observerSelectors, ...this.defaultSelectors]
            .reduce((acc: Element[], selector: string) => {
              const elements: Element[] = Array.from(this.document.querySelectorAll(selector));
              return [...acc, ...elements];
            }, [])
            .filter((element: Element | null) => !!element);
          this.setElementSelectors(elementSelectors);
          elementSelectors.forEach((element: Element | null) => {
            this.observer.observe(element);
          });
          clearTimeout(timeout);
        }, 0);
      },
      { injector: this.injector }
    );

    effect(
      () => {
        const data = this.data();
        const pagination = this.pagination();
        if (data || pagination) {
          this.resize();
        }
      },
      { injector: this.injector }
    );
  }

  ngOnDestroy(): void {
    this.unobserve();
  }

  resize(): void {
    if (this.sticky()) {
      const timeout = setTimeout(() => {
        this.renderer.setStyle(this.elementRef.nativeElement, 'height', '100%');
        const element: HTMLElement = this.elementRef.nativeElement.parentElement.parentElement;
        if (element) {
          let height = element.getBoundingClientRect().height;
          const paginationHeight = element.querySelector('.mat-mdc-paginator')?.getBoundingClientRect()?.height || 20;
          const elementOffsetTop = this.calcTopOffset(element);
          const containerHeight = height + elementOffsetTop + paginationHeight;
          if (containerHeight >= window.innerHeight) {
            height = Math.abs(window.innerHeight - elementOffsetTop - paginationHeight);
          }
          this.renderer.setStyle(this.elementRef.nativeElement, 'height', `${Math.max(Math.round(height) - 10, 150)}px`);
        }
        clearTimeout(timeout);
      }, 0);
    }
  }

  calcTopOffset(element: HTMLElement): number {
    const rect = element.getBoundingClientRect();
    const scrollTop: number = get(window, 'pageYOffset') || get(this.document, 'documentElement.scrollTop') || 0;
    return rect.top + scrollTop;
  }

  private setElementSelectors(elementSelectors) {
    this.unobserve();
    this.elementSelectors.set([...elementSelectors]);
  }

  private unobserve() {
    this.elementSelectors()?.forEach((element: Element | null) => {
      this.observer.unobserve(element);
    });
  }
}
