import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { NgClass } from '@angular/common';
import { Component, signal, WritableSignal } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';

import { CustomOptionsCellParams } from '../../../models/custom-options-cell.params';

@Component({
    selector: 'grid-engine-dynamic-icon-cell',
    templateUrl: './dynamic-icon-cell.component.html',
    imports: [MatTooltip, MatIcon, NgClass],
    styleUrls: ['./dynamic-icon-cell.component.scss']
})
export class DynamicIconCellComponent implements ICellRendererAngularComp {
  params: WritableSignal<CustomOptionsCellParams> = signal(null);

  agInit(params: CustomOptionsCellParams): void {
    this.params.set(params);
  }

  refresh(): boolean {
    return false;
  }

  getTooltip() {
    const params = this.params();
    return params.value ? params.data[params.cellOptions.tooltip.key] : '';
  }
}
