import { NgClass } from '@angular/common';
import { Component, input } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { StatusIconComponent } from '@iot-platform/iot-platform-ui';
import { DataGuardCheck } from '@iot-platform/models/data-guard';

@Component({
    selector: 'iot-platform-feature-data-guard-overview-check',
    imports: [MatIconModule, FlexLayoutModule, StatusIconComponent, NgClass],
    templateUrl: './overview-check.component.html',
    styleUrl: './overview-check.component.scss'
})
export class OverviewCheckComponent {
  overviewCheck = input<DataGuardCheck>();
}
