import { Component, computed, inject, Signal } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIcon } from '@angular/material/icon';
import { AuthorizationConcept, AuthorizationService, AuthorizationType } from '@iot-platform/auth';

import { ProcessMultipleStateVariablesUtil } from '@iot-platform/iot-platform-utils';
import { InfoDisplayPipe, NumberFormatPipe } from '@iot-platform/pipes';
import { get } from 'lodash';
import { AbstractTableEngineCellComponent } from '../abstract-table-engine-cell.component';

@Component({
  selector: 'i4b-table-engine-rich-variable-value-cell',
  templateUrl: './rich-variable-value-cell.component.html',
  styleUrls: ['./rich-variable-value-cell.component.scss'],
  imports: [FlexLayoutModule, NumberFormatPipe, InfoDisplayPipe, MatIcon]
})
export class RichVariableValueCellComponent extends AbstractTableEngineCellComponent<RichVariableValueCellComponent> {
  private readonly authorizationService: AuthorizationService = inject(AuthorizationService);
  isMultipleStateVariable: Signal<boolean> = computed(() => {
    const cellOptions = this.cellOptions();
    const rawData = this.rawData();
    const data = this.data();
    if (cellOptions?.dataType === 'lastRecord' || cellOptions?.dataType === 'lastValue') {
      return ProcessMultipleStateVariablesUtil.isUnitMultipleState(rawData?.unit);
    }
    return ProcessMultipleStateVariablesUtil.isUnitMultipleState(data?.unit);
  });
  processedValue: Signal<string> = computed(() => {
    const isMultipleStateVariable = this.isMultipleStateVariable();
    const cellOptions = this.cellOptions();
    const rawData = this.rawData();
    const data = this.data();
    if (isMultipleStateVariable) {
      if (cellOptions?.dataType === 'lastRecord' || cellOptions?.dataType === 'lastValue') {
        return ProcessMultipleStateVariablesUtil.getProcessedUnit(rawData);
      } else {
        const lastRecord = data?.value !== null ? [{ value: data.value, datetime: '' }] : [];
        return ProcessMultipleStateVariablesUtil.getProcessedUnit({
          ...data,
          lastRecords: lastRecord
        });
      }
    }
    return null;
  });
  canReadGraph: Signal<boolean> = computed(() => {
    this.userPermissions();
    return !!this.authorizationService.applyAuthorization(AuthorizationConcept.GRAPH, AuthorizationType.READ);
  });
  minimalDisplay: Signal<boolean> = computed(() => !!this.cellOptions()?.minimalDisplay);

  onClick(event: MouseEvent): void {
    event.stopPropagation();
    const clickEvent = this.clickEvent();
    const rawData = this.rawData();
    const minimalDisplay = this.minimalDisplay();
    if (!minimalDisplay && clickEvent?.type) {
      this.dispatchEvent.emit({ type: clickEvent.type, options: clickEvent.options, rawData });
    }
  }

  openFollowedVariableEvents(event: MouseEvent) {
    event.stopPropagation();
    const clickEvent = this.clickEvent();
    const rawData = this.rawData();
    const data = this.data();
    this.dispatchEvent.emit({
      type: 'openFollowedVariableEvents',
      options: clickEvent.options,
      rawData: { asset: rawData, followedVariable: data }
    });
  }

  openGraphsByVariable(event: MouseEvent) {
    event.stopPropagation();
    const cellOptions = this.cellOptions();
    const clickEvent = this.clickEvent();
    const rawData = this.rawData();
    const data = this.data();

    let siteId = get(rawData, 'site.id', null);
    if (cellOptions.dataType === 'event' || !siteId) {
      siteId = get(rawData, 'context.site.id');
    }
    this.dispatchEvent.emit({
      type: 'openGraphsByVariable',
      options: clickEvent.options,
      rawData: { asset: rawData, followedVariable: data, siteId }
    });
  }
}
