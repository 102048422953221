<div>
<iot-platform-ui-device-info-toolbar
        (applyFilters)="applyFilters($event)"
        (clearFilters)="clearFilters()"
        [displayLoader]="false"
        [filters]="filters$ | async"
        [totalFiltered]="totalVariablesFiltered"
        [total]="totalVariables"
        [variableUnits]="variableUnits"
        [withNameFilter]="true"
        [withUnitFilter]="true"
>
</iot-platform-ui-device-info-toolbar>

<i4b-table-engine-master-view-engine
        (dispatchMasterViewEngineEvent)="onMasterViewEngineEvent($event)"
        [checkedElements]="selectedVariables"
        [data]="variablesAsPlatformResponse$ | async"
        [masterViewType]="masterViewType"
        [metadata]="variablesSettings$ | async"
        [selectedElement]="selectedVariable"
        [useExternalData]="true"
        [useFullyLoadedDataset]="true"
>
</i4b-table-engine-master-view-engine>
</div>
