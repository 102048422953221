import { Component, input, output } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';

@Component({
    imports: [MatIconModule, FlexLayoutModule],
    selector: 'iot4bos-ui-breadcrumb-item',
    templateUrl: './breadcrumb-item.component.html',
    styleUrls: ['./breadcrumb-item.component.scss']
})
export class BreadcrumbItemComponent {
  name = input.required<string>();
  icon = input.required<string>();
  navigate = output<void>();
}
