import { Injectable } from '@angular/core';
import { ApiFeatureSelector, Filter, Pagination } from '@iot-platform/models/common';
import { Event } from '@iot-platform/models/i4b';
import * as fromDeviceEvents from '../reducers/device-events.reducer';

@Injectable({
  providedIn: 'root'
})
export class DeviceEventsSelectors extends ApiFeatureSelector<fromDeviceEvents.State, Event, Pagination, Filter> {
  constructor() {
    super(fromDeviceEvents.deviceEventsFeature.selectDeviceEventsState, fromDeviceEvents.adapter);
  }
}
