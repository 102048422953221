import { NgClass, UpperCasePipe } from '@angular/common';
import { Component, computed, inject, input, output, Signal } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PopupComponent } from '@iot-platform/iot-platform-ui';
import { SortUtil } from '@iot-platform/iot-platform-utils';
import { Role } from '@iot-platform/models/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    imports: [MatListModule, MatMenuModule, MatIcon, MatTooltipModule, TranslateModule, UpperCasePipe, MatIconButton, NgClass],
    selector: 'iot4bos-ui-backoffice-roles-list',
    templateUrl: './roles-list.component.html',
    styleUrls: ['./roles-list.component.scss', '../../style/admin.style.scss']
})
export class RolesListComponent {
  dialog: MatDialog = inject(MatDialog);

  roles = input<Role[]>([]);
  selectedRole = input<Role>();
  showActionMenu = input<boolean>(false);

  selectRole = output<Role>();
  editRole = output<Role>();
  deleteRole = output<Role>();
  renameRole = output<Role>();

  sortedRoles: Signal<Role[]> = computed(() => {
    const roles = this.roles();
    return roles.sort(SortUtil.sortByName);
  });

  onDeleteRoleButtonClick(role: Role) {
    this.confirmDeletion(role);
  }

  getTooltip(role: Role): string {
    return role.businessProfileTotal !== 0 ? `Role is linked to ${role.businessProfileTotal} Business Profiles` : '';
  }

  isDeletePossible(role: Role): boolean {
    return role.businessProfileTotal !== 0;
  }

  onRenameRoleButtonClick(role: Role) {
    this.renameRole.emit(role);
  }

  onEditRoleButtonClick(role: Role) {
    this.editRole.emit(role);
  }

  private confirmDeletion(roleToDelete: Role) {
    const dialogRef = this.dialog.open(PopupComponent, {
      width: '500px',
      disableClose: true,
      data: { type: 'delete', value: roleToDelete.name }
    });

    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.deleteRole.emit(roleToDelete);
      }
    });
  }
}
