import { Injectable } from '@angular/core';
import { fromAuth } from '@iot-platform/auth';

import { CommonApiRequest, CommonApiResponse, CommonGenericModel, CommonIndexedPagination, CommonPagination } from '@iot-platform/models/common';
import { I4BGrid, I4BGridData, I4BGridOptions } from '@iot-platform/models/grid-engine';

import { NotificationService } from '@iot-platform/notification';
import { FavoriteViewsActions } from '@iot-platform/shared/components';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';

import { Observable, of, throwError, timer } from 'rxjs';
import { catchError, concatMap, finalize, map, mergeMap, retry, switchMap, tap } from 'rxjs/operators';
import { GridsService } from '../../services/grids.service';

import { GridsDbActions } from '../actions';
import * as fromGrids from '../reducers';

/* eslint-disable rxjs/no-unsafe-switchmap */
export const genericRetryStrategy =
  ({
    maxRetryAttempts = 3,
    scalingDuration = 1000,
    excludedStatusCodes = [401]
  }: {
    maxRetryAttempts?: number;
    scalingDuration?: number;
    excludedStatusCodes?: number[];
  } = {}) =>
  (attempts: Observable<any>) =>
    attempts.pipe(
      mergeMap((error, i) => {
        const retryAttempt = i + 1;
        if (retryAttempt > maxRetryAttempts || excludedStatusCodes.find((e) => e === error.status)) {
          return throwError(error);
        }
        console.warn(`[${error['name']}][${error['message']}] - Attempt ${retryAttempt}: retrying in ${retryAttempt * scalingDuration}ms`);
        return timer(retryAttempt * scalingDuration);
      }),
      finalize(() => console.log('Max attempts reached or no error thrown'))
    );

@Injectable()
export class GridsEffects {
  loadGrids$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GridsDbActions.loadGrids),
      switchMap(() =>
        this.gridsService.loadAllGrids().pipe(
          map((response: CommonApiResponse<I4BGrid<I4BGridOptions, I4BGridData>, CommonPagination>) => GridsDbActions.loadGridsSuccess({ response })),
          catchError((error) => of(GridsDbActions.loadGridsFailure({ error })))
        )
      )
    )
  );

  loadGridDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GridsDbActions.loadGridDetails),
      switchMap((action) =>
        this.gridsService.loadGridDetails(action.concept, action.gridId).pipe(
          map((loadedGrid: I4BGrid<I4BGridOptions, I4BGridData>) => GridsDbActions.loadGridDetailsSuccess({ grid: loadedGrid })),
          catchError((error) => of(GridsDbActions.loadGridDetailsFailure({ error })))
        )
      )
    )
  );

  selectGridAndLoadData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GridsDbActions.selectGridAndLoadData),
      concatLatestFrom(() => this.store.select(fromGrids.getAllGrids)),
      switchMap(([action, grids]) => {
        // TODO : On doit pouvoir faire mieux
        const founds = grids.filter((g) => g.id === action.gridId);
        if (action.gridId && founds.length > 0) {
          const pagination: CommonIndexedPagination = founds[0].data?.response.pagination as CommonIndexedPagination;
          const request: CommonApiRequest = {
            limit: pagination ? pagination.limit : founds[0].gridOptions.pageSize,
            page: 0,
            filters: action.filters ? action.filters : founds[0]?.gridOptions?.filters,
            concept: founds[0].masterview.toLowerCase(),
            variables: founds[0].gridOptions.variableNames,
            tags: founds[0].gridOptions.tagIds
          };
          request.endPoint = action.endPoint ?? founds[0].gridOptions.endPoint ?? undefined;
          const grid = grids.filter((g) => g.id === action.gridId)[0];
          return [
            GridsDbActions.selectGrid({
              gridId: grid.id,
              masterview: grid.masterview
            }),
            GridsDbActions.loadGridData({ request })
          ];
        } else {
          const userDefaultGrid = grids.filter((g) => g.masterview === action.masterview && g.isUserDefault)[0];
          if (!!userDefaultGrid) {
            const pagination: CommonIndexedPagination = userDefaultGrid.data?.response.pagination as CommonIndexedPagination;
            const request: CommonApiRequest = {
              limit: pagination ? pagination.limit : userDefaultGrid.gridOptions.pageSize,
              page: 0,
              filters: action.filters,
              concept: userDefaultGrid.masterview.toLowerCase(),
              variables: userDefaultGrid.gridOptions.variableNames,
              tags: userDefaultGrid.gridOptions.tagIds
            };
            request.endPoint = action.endPoint ?? userDefaultGrid.gridOptions.endPoint ?? undefined;
            return [
              GridsDbActions.selectGrid({ gridId: userDefaultGrid.id, masterview: userDefaultGrid.masterview }),
              GridsDbActions.loadGridData({ request })
            ];
          } else {
            const appDefaultGrid = grids.filter((g) => g.masterview === action.masterview && g.isAppDefault)[0];
            if (appDefaultGrid) {
              const pagination: CommonIndexedPagination = appDefaultGrid.data?.response.pagination as CommonIndexedPagination;
              const request: CommonApiRequest = {
                limit: pagination ? pagination.limit : appDefaultGrid.gridOptions.pageSize,
                page: 0,
                filters: action.filters,
                concept: appDefaultGrid.masterview.toLowerCase(),
                variables: appDefaultGrid.gridOptions.variableNames,
                tags: appDefaultGrid.gridOptions.tagIds
              };
              request.endPoint = action.endPoint ?? appDefaultGrid.gridOptions.endPoint ?? undefined;
              return [
                GridsDbActions.selectGrid({ gridId: appDefaultGrid.id, masterview: appDefaultGrid.masterview }),
                GridsDbActions.loadGridData({ request })
              ];
            }
            return [];
          }
        }
      })
    )
  );

  reactOnFavoriteViewCrud$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FavoriteViewsActions.addFavoriteViewSuccess, FavoriteViewsActions.updateFavoriteViewSuccess),
      switchMap((action) =>
        of(
          GridsDbActions.selectGrid({
            gridId: action.favoriteView.gridId,
            masterview: action.favoriteView.masterView
          })
        )
      )
    )
  );

  reactOnFavoriteViewCrudFromMV$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FavoriteViewsActions.addFavoriteViewFromMVSuccess, FavoriteViewsActions.updateFavoriteViewFromMVSuccess),
      switchMap((action) =>
        of(
          GridsDbActions.selectGridAndLoadData({
            gridId: action.favoriteView.gridId,
            masterview: action.favoriteView.masterView,
            filters: action.favoriteView.filters
          })
        )
      )
    )
  );

  loadGridData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GridsDbActions.loadGridData),
      switchMap((action) =>
        this.gridsService.loadGridData(action.request).pipe(
          map((response: CommonApiResponse<CommonGenericModel, CommonPagination>) =>
            GridsDbActions.loadGridDataSuccess({ gridData: { response }, masterView: action.request.concept })
          ),
          retry({
            delay: genericRetryStrategy({
              maxRetryAttempts: 3,
              scalingDuration: 1500,
              excludedStatusCodes: [401]
            })
          }),
          catchError((error) => of(GridsDbActions.loadGridDataFailure({ error })))
        )
      )
    )
  );

  changeGridPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GridsDbActions.changeGridPage),
      switchMap((action) =>
        this.gridsService.loadGridData(action.request).pipe(
          map((response: CommonApiResponse<CommonGenericModel, CommonPagination>) =>
            GridsDbActions.loadGridDataSuccess({ gridData: { response }, masterView: action.request.concept })
          ),
          retry({
            delay: genericRetryStrategy({
              maxRetryAttempts: 3,
              scalingDuration: 1500,
              excludedStatusCodes: [401]
            })
          }),
          catchError((error) => of(GridsDbActions.loadGridDataFailure({ error })))
        )
      )
    )
  );

  updateGrid$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GridsDbActions.updateGrid),
      switchMap((action) =>
        this.gridsService.updateGrid(action.toUpdate as I4BGrid<I4BGridOptions, I4BGridData>).pipe(
          map((grid: I4BGrid<I4BGridOptions, I4BGridData>) => GridsDbActions.updateGridSuccess({ grid })),
          catchError((error) => of(GridsDbActions.updateGridFailure({ error })))
        )
      )
    )
  );

  updateDefaultGrid$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GridsDbActions.updateDefaultGrid),
      concatMap((action) =>
        this.gridsService.updateGrid(action.grid as I4BGrid<I4BGridOptions, I4BGridData>).pipe(
          map((grid: I4BGrid<I4BGridOptions, I4BGridData>) => GridsDbActions.updateDefaultGridSuccess({ grid })),
          catchError((error) => of(GridsDbActions.updateDefaultGridFailure({ error })))
        )
      )
    )
  );

  updateSilentGrid$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GridsDbActions.updateSilentGrid),
      switchMap((action) =>
        this.gridsService.updateSilentGrid(action.toUpdate as I4BGrid<I4BGridOptions, I4BGridData>).pipe(
          map((response) => GridsDbActions.updateSilentGridSuccess({ grid: response })),
          catchError((error) => of(GridsDbActions.updateSilentGridFailure({ error })))
        )
      )
    )
  );

  /* getDefaultGridByConcept$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GridsDbActions.getDefaultGridByConcept),
      switchMap((action) =>
        this.gridsService.getDefaultGridByConcept(action.concept).pipe(
          map((response) => {
            return GridsDbActions.getDefaultGridByConceptSuccess({ defaultGrid: response });
          }),
          catchError((error) => of(GridsDbActions.getDefaultGridByConceptFailure({ error: error })))
        )
      )
    )
  );*/

  addGrid$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GridsDbActions.addGrid),
      switchMap((action) =>
        this.gridsService.saveGrid(action.toAdd as I4BGrid<I4BGridOptions, I4BGridData>).pipe(
          map((response) => GridsDbActions.addGridSuccess({ grid: response })),
          catchError((error) => of(GridsDbActions.addGridFailure({ error })))
        )
      )
    )
  );

  addOrUpdateGridSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GridsDbActions.addGridSuccess, GridsDbActions.updateGridSuccess),
      concatMap(({ grid }) => [
        GridsDbActions.selectGridAndLoadData({
          gridId: grid.id,
          masterview: (grid as I4BGrid<I4BGridOptions, I4BGridData>).masterview
        }),
        GridsDbActions.loadGrids({})
      ])
    )
  );

  removeGrid$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GridsDbActions.removeGrid),
      switchMap((action) =>
        this.gridsService.deleteGrid(action.toRemove as I4BGrid<I4BGridOptions, I4BGridData>).pipe(
          map(() => GridsDbActions.removeGridSuccess({ removed: action.toRemove })),
          catchError((error) => of(GridsDbActions.removeGridFailure({ error })))
        )
      )
    )
  );

  updateItemInGridData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GridsDbActions.updateItemInGridData),
      concatLatestFrom(() => this.store.select(fromAuth.selectSelectedEntityForSession)),
      map(([action, sessionEntity]) =>
        GridsDbActions.updateItemInGridDataSuccess({
          gridId: action.gridId,
          item: this.gridsService.enrichData(action.concept, [action.item], sessionEntity)[0]
        })
      )
    )
  );

  updateItemInAllGridsData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GridsDbActions.updateItemInAllGridsData),
      concatLatestFrom(() => this.store.select(fromAuth.selectSelectedEntityForSession)),
      map(([action, sessionEntity]) =>
        GridsDbActions.updateItemInAllGridsDataSuccess({ updatedItem: this.gridsService.enrichData(action.concept, [action.updatedItem], sessionEntity)[0] })
      )
    )
  );

  displaySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          GridsDbActions.addGridSuccess,
          GridsDbActions.updateGridSuccess,
          GridsDbActions.updateGridDataSuccess,
          GridsDbActions.removeGridSuccess,
          GridsDbActions.updateDefaultGridSuccess
        ),
        tap((action) => this.notificationService.displaySuccess(action.type))
      ),
    { dispatch: false }
  );

  displayError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          GridsDbActions.getDefaultGridByConceptFailure,
          GridsDbActions.removeGridFailure,
          GridsDbActions.updateGridFailure,
          GridsDbActions.updateGridDataFailure,
          GridsDbActions.updateDefaultGridFailure,
          GridsDbActions.loadGridDataFailure
        ),
        tap((action) => this.notificationService.displayError(action))
      ),
    { dispatch: false }
  );

  displayLoader$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          GridsDbActions.loadGrids,
          GridsDbActions.loadGridData,
          GridsDbActions.loadGridDetails,
          GridsDbActions.getDefaultGridByConcept,
          GridsDbActions.removeGrid,
          GridsDbActions.updateGrid,
          GridsDbActions.addGrid,
          GridsDbActions.updateGridData,
          GridsDbActions.updateDefaultGrid,
          GridsDbActions.changeGridPage
        ),
        tap(() => this.notificationService.showLoader())
      ),
    { dispatch: false }
  );

  hideLoaderAfterResponse$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          GridsDbActions.loadGridsSuccess,
          GridsDbActions.loadGridDetailsSuccess,
          GridsDbActions.loadGridDataSuccess,
          GridsDbActions.getDefaultGridByConceptSuccess,
          GridsDbActions.updateGridDataSuccess,
          GridsDbActions.updateGridSuccess,
          GridsDbActions.updateDefaultGridSuccess,
          GridsDbActions.updateDefaultGridFailure,
          GridsDbActions.addGridSuccess,
          GridsDbActions.removeGridSuccess,
          GridsDbActions.loadGridsFailure,
          GridsDbActions.loadGridDetailsFailure,
          GridsDbActions.loadGridDataFailure,
          GridsDbActions.getDefaultGridByConceptFailure,
          GridsDbActions.updateGridDataFailure,
          GridsDbActions.addGridFailure,
          GridsDbActions.updateGridFailure,
          GridsDbActions.removeGridFailure
        ),
        tap(() => this.notificationService.hideLoader())
      ),
    { dispatch: false }
  );

  shareGridThenAddFavoriteViewSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FavoriteViewsActions.shareGridThenUpdateGridSuccess),
      map(({ grid }) => GridsDbActions.updateGridSuccess({ grid }))
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly notificationService: NotificationService,
    private readonly gridsService: GridsService,
    private readonly store: Store
  ) {}
}
