@defer {
  @if (dragPlaceholder?.template) {
    <div *cdkDragPlaceholder class="cdk-drag-placeholder">
      <ng-container *ngTemplateOutlet="dragDropPlaceholderTmpl" />
    </div>
  } @else {
    <div *cdkDragPlaceholder class="cdk-drag-placeholder cdk-drag-placeholder--default"></div>
  }

  <div class="cdk-drag-drop-element-container" [ngClass]="{
  'cdk-drag-drop-element-container--has-drag-indicator': !!dragIndicator?.template?.elementRef,
  'cdk-drag-drop-element-container--has-drag-meta': !!dragMeta?.template?.elementRef,
  'cdk-drag-drop-element-container--has-no-drag-indicator': !dragIndicator?.template?.elementRef,
  'cdk-drag-drop-element-container--has-no-drag-meta': !dragMeta?.template?.elementRef,
  }">
    @if (dragIndicator?.template) {
      <ng-container *ngTemplateOutlet="dragIndicatorTmpl" />
    }
    <div class="cdk-drag-drop-element-container__content">
      @if (dragContent?.template) {
        <ng-container *ngTemplateOutlet="dragDropContentTmpl" />
      }
    </div>
    @if (dragMeta?.template) {
      <ng-container *ngTemplateOutlet="dragMetaTmpl" />
    }
  </div>
}

<ng-template #dragIndicatorTmpl>
  <div class="cdk-drag-drop-element-container__drag-indicator">
    <ng-container *ngTemplateOutlet="dragIndicator.template" />
  </div>
</ng-template>

<ng-template #dragMetaTmpl>
  <div class="cdk-drag-drop-element-container__drag-meta">
    <ng-container *ngTemplateOutlet="dragMeta.template" />
  </div>
</ng-template>

<ng-template #dragDropContentTmpl>
  <ng-container *ngTemplateOutlet="dragContent.template" />
</ng-template>

<ng-template #dragDropPlaceholderTmpl>
  <ng-container *ngTemplateOutlet="dragPlaceholder.template" />
</ng-template>
