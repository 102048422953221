import { Component } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { AbstractTableEngineCellComponent } from '../abstract-table-engine-cell.component';

@Component({
  selector: 'i4b-table-engine-button-cell',
  imports: [FlexLayoutModule, MatButtonModule, MatIconModule, MatTooltipModule, TranslateModule],
  template: `
    @if (cellOptions()?.svgIcon) {
      <button
        class="button-cell"
        mat-icon-button
        fxLayout="row"
        fxLayoutAlign="center center"
        [matTooltipDisabled]="cellOptions()?.tooltipDisabled"
        [matTooltip]="cellOptions()?.tooltip || '' | translate"
        matTooltipPosition="above"
        matTooltipClass="regular-tooltip"
        (click)="$event.preventDefault(); $event.stopPropagation(); handleClick()"
      >
        @if (cellOptions()?.svgIcon) {
          <mat-icon [svgIcon]="cellOptions()?.svgIcon" />
        }
      </button>
    }
    @if (!cellOptions()?.svgIcon && (cellOptions()?.label || data())) {
      <button
        class="button-cell"
        mat-stroked-button
        fxLayout="row"
        fxLayoutAlign="center center"
        [matTooltipDisabled]="cellOptions()?.tooltipDisabled"
        [matTooltip]="cellOptions()?.tooltip || data() || '' | translate"
        matTooltipPosition="above"
        matTooltipClass="regular-tooltip"
        (click)="$event.preventDefault(); $event.stopPropagation(); handleClick()"
      >
        @if (cellOptions()?.label || data()) {
          <span class="label" [style.width]="(cellOptions()?.labelWidth || 5) + 'rem'">{{ cellOptions()?.label || data() | translate }}</span>
        }
      </button>
    }
    @if (!cellOptions()?.svgIcon && !cellOptions()?.label && !data()) {
      <span>-</span>
    }
  `,
  styles: [
    `
      .button-cell {
        height: 30px;
        padding: 0 0.5rem;
      }

      .label {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: block;
        font-size: 0.8rem;
      }
    `
  ]
})
export class ButtonCellComponent extends AbstractTableEngineCellComponent<ButtonCellComponent> {
  handleClick(): void {
    const clickEvent = this.clickEvent();
    const options = this.cellOptions();
    const rawData = this.rawData();
    this.dispatchCellEvent({
      type: clickEvent.type,
      options,
      rawData
    });
  }
}
