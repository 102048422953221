import { Component, input, output } from '@angular/core';

import { Site } from '@iot-platform/models/i4b';
import { InfoDisplayPipe } from '@iot-platform/pipes';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    imports: [InfoDisplayPipe, TranslateModule],
    providers: [InfoDisplayPipe],
    selector: 'iot-platform-ui-detail-site-card',
    templateUrl: './detail-site-card.component.html',
    styleUrls: ['./detail-site-card.component.scss']
})
export class DetailSiteCardComponent {
  site = input<Site>();
  extraFill = input<boolean>(false);

  selectSite = output<Site>();
}
