import { Component, input, output } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    imports: [FlexLayoutModule, TranslateModule, MatIcon, MatIconButton],
    selector: 'iot-platform-ui-detail-panel',
    templateUrl: './detail-panel.component.html',
    styleUrls: ['./detail-panel.component.scss']
})
export class DetailPanelComponent {
  title = input<string>();
  hasButton = input<boolean>(false);
  clickEvent = output<void>();

  onClick() {
    this.clickEvent.emit();
  }
}
