import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { Contact } from '@iot-platform/models/common';
import { ContactNotification, ExportSpreadsheet } from '@iot-platform/models/i4b';

@Component({
    imports: [MatCheckboxModule, FlexLayoutModule],
    selector: 'iot4bos-ui-notifications-exports-tabs-checkbox-list',
    templateUrl: './notifications-exports-tabs-checkbox-list.component.html',
    styleUrls: ['./notifications-exports-tabs-checkbox-list.component.scss'],
    encapsulation: ViewEncapsulation.Emulated
})
export class NotificationsExportsTabsCheckboxListComponent implements OnChanges {
  @Input() list: ContactNotification[] | ExportSpreadsheet[] = [];
  @Input() selectedItem?: ContactNotification | ExportSpreadsheet;
  @Input() displayCheckbox = false;
  @Input() currentContact?: Contact;

  @Output() selectItem = new EventEmitter<ContactNotification | ExportSpreadsheet>();
  @Output() checkItem = new EventEmitter<{ checked: boolean; item: ContactNotification | ExportSpreadsheet }>();

  checkboxStatusByItem: { [key: string]: boolean } = {};

  ngOnChanges(changes: SimpleChanges) {
    if (this.displayCheckbox && changes.hasOwnProperty('list') && changes.list.currentValue) {
      this.checkboxStatusByItem = changes.list.currentValue.reduce(
        (
          acc: {
            [key: string]: boolean;
          },
          item: ContactNotification | ExportSpreadsheet
        ) => {
          acc[item.id as string] = item.contactIds.includes(this.currentContact?.id as string);
          return acc;
        },
        {}
      );
    }
  }

  onItemSelection(item: ContactNotification | ExportSpreadsheet, change: MatCheckboxChange): void {
    this.checkItem.emit({ checked: change.checked, item });
  }
}
