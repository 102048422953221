import { Component, input } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { Contact } from '@iot-platform/models/common';
import { InfoDisplayPipe } from '@iot-platform/pipes';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    imports: [FlexLayoutModule, TranslateModule, InfoDisplayPipe],
    selector: 'iot-platform-ui-detail-contact-card',
    templateUrl: './detail-contact-card.component.html',
    styleUrls: ['./detail-contact-card.component.scss']
})
export class DetailContactCardComponent {
  contacts = input([], {
    transform: (contacts: Contact[]) =>
      contacts?.sort((a, b) =>
        a.lastname.toLowerCase() + a.firstname.toLowerCase() + a.id > b.lastname.toLowerCase() + b.firstname.toLowerCase() + b.id ? 1 : -1
      )
  });
}
