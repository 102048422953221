import { Component } from '@angular/core';
import { EventSeverityDisplayComponent } from '@iot-platform/iot-platform-ui';
import { AbstractTableEngineCellComponent } from '../abstract-table-engine-cell.component';

@Component({
  selector: 'i4b-table-engine-severity-cell',
  templateUrl: './severity-cell.component.html',
  imports: [EventSeverityDisplayComponent]
})
export class SeverityCellComponent extends AbstractTableEngineCellComponent<SeverityCellComponent> {}
