import { BusinessProfile } from '@iot-platform/models/common';
import { VariableChart } from '../models/variable-chart';

export const getVariable = (variable: any): VariableChart => {
  // eslint-disable-next-line no-prototype-builtins
  const variableType = variable.hasOwnProperty('device') ? 'device' : 'asset';
  return {
    ...variable,
    parentType: variableType,
    parentId: variable[variableType]?.id,
    parentName: variable[variableType]?.name,
    unit: !variable.unit ? '' : variable.unit[0] === '/' ? '' : variable.unit
  };
};

export const getTimezone = (businessProfile: BusinessProfile): string => (businessProfile ? businessProfile.timezoneDetails.offset : '+00:00');

export const parseTimezone = (timezone: string): string => {
  const sign = timezone.substring(0, 1);
  const tz = timezone.substring(1).split(':');
  const absoluteOffset = parseInt(tz[0], 10) + parseInt(tz[1], 10) / 60;
  return sign.concat(absoluteOffset.toString());
};

export const getChartPeriod = (businessProfile: BusinessProfile): number =>
  businessProfile && businessProfile.chartPeriod === 1 ? 0 : businessProfile && businessProfile.chartPeriod === 7 ? 1 : 0;

export const getTotalDays = (from: string, to: string): number => {
  const total = Math.ceil(Math.abs(Date.parse(to) - Date.parse(from)) / (1000 * 60 * 60 * 24));
  return total === 0 ? total + 1 : total;
};
