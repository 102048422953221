import { AssetGroup, AssetGroupCreate } from '@iot-platform/models/i4b';
import { createActionGroup, props } from '@ngrx/store';

export const AssetGroupsActions = createActionGroup({
  source: 'AssetGroups',
  events: {
    'Get Asset Group': props<{ assetGroupId: string }>(),
    'Get Asset Group Success': props<{ assetGroup: AssetGroup }>(),
    'Get Asset Group Failure': props<{ error: unknown }>(),

    'Add Asset Group': props<{ assetGroup: AssetGroupCreate }>(),
    'Add Asset Group Success': props<{ assetGroup: AssetGroup }>(),
    'Add Asset Group Failure': props<{ error: unknown }>(),

    'Edit Asset Group': props<{ assetGroup: AssetGroup }>(),
    'Edit Asset Group Success': props<{ assetGroup: AssetGroup }>(),
    'Edit Asset Group Failure': props<{ error: unknown }>(),

    'Update Related Assets': props<{ assetGroupId: string; assetIds: string[] }>(),
    'Update Related Assets Success': props<{ assetGroup: AssetGroup }>(),
    'Update Related Assets Failure': props<{ error: unknown }>()
  }
});
