import { I4BCellType, I4BColumnConfiguration, I4BColumnRowGroupConfiguration } from '../configurations/i4b-column-configuration';
import { HeaderType, I4BColumnHeader } from '../models/i4b-column-header.model';
import { I4BVariableColumnOptions } from '../options/i4b-variable-column-options';
import { I4BBasicColumn } from './i4b-basic.column';

interface UpdatedGroupColumnConfiguration extends I4BColumnConfiguration {
  rowGroupParams: Partial<{
    updatedAt: Partial<I4BColumnRowGroupConfiguration>;
    updatedBy: Partial<I4BColumnRowGroupConfiguration>;
  }>;
}

export class UpdatedGroupColumn extends I4BBasicColumn {
  columnId = 'e1f820e6-9f86-11ec-9da8-0023a40210d0-updated-group';
  className = UpdatedGroupColumn;

  constructor(
    customHeader?: Partial<I4BColumnHeader>,
    customConfig?: Partial<UpdatedGroupColumnConfiguration>,
    customOptions?: Partial<I4BVariableColumnOptions>
  ) {
    super(
      {
        displayName: customOptions?.overrideHeaderName
          ? customOptions?.overrideHeaderName
          : customHeader?.displayName
            ? customHeader?.displayName
            : 'UPDATED FULL',
        type: HeaderType.BASIC
      },
      {
        cell: { type: I4BCellType.BASIC },
        id: customConfig.id,
        concept: customConfig.concept,
        isDefault: false,
        marryChildren: true,
        rowGroupParams: customConfig?.rowGroupParams,
        children: [
          new I4BBasicColumn(
            { displayName: 'UPDATED AT', type: HeaderType.BASIC },
            {
              translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.UPDATE_AT',
              id: 'updatedAt',
              isDefault: false,
              sortProperty: 'updatedAt',
              concept: customConfig.concept,
              width: null,
              cell: { type: I4BCellType.DATE },
              rowGroupParams: customConfig?.rowGroupParams?.updatedAt
            },
            {
              order: 980
            }
          ),
          new I4BBasicColumn(
            { displayName: 'UPDATED BY', type: HeaderType.BASIC },
            {
              translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.UPDATED_BY',
              id: 'updatedBy',
              isDefault: false,
              sortProperty: 'updatedBy',
              concept: customConfig.concept,
              width: 165,
              cell: {
                type: I4BCellType.USER_CELL
              },
              rowGroupParams: customConfig?.rowGroupParams?.updatedBy
            },
            {
              order: 981
            }
          )
        ]
      },
      { ...customOptions, customId: customConfig.id }
    );
  }
}
