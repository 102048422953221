import { Component, Input } from '@angular/core';

@Component({
    template: '',
    standalone: false
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export abstract class AbstractMasterViewDrawerDetailsComponent {
  @Input() toggleDrawerDetails: (open: boolean) => void;
}
