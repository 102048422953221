import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { FormulaType } from '@iot-platform/models/common';
import {
  AssetTemplateVariableFormula,
  AssetVariableThresholds,
  DeviceVariable,
  Formula,
  FormulaSourceVariable,
  TemplateSuggestedVariableMatchingType
} from '@iot-platform/models/i4b';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'iot4bos-ui-asset-variable-formula-configuration',
    templateUrl: './asset-variable-formula-configuration.component.html',
    styleUrls: ['./asset-variable-formula-configuration.component.scss'],
    standalone: false
})
export class AssetVariableFormulaConfigurationComponent implements OnInit, OnChanges, OnDestroy {
  @Input() readonly = true;
  @Input() disableMatching = true;
  @Input() assetTemplateVariableFormula!: AssetTemplateVariableFormula;
  @Input() thresholds!: AssetVariableThresholds | null;
  @Input() unit?: string;
  @Input() unitList: { name: string; units: string[] }[] = [];

  @Output() assignSuggestedVariable: EventEmitter<void> = new EventEmitter<void>();
  @Output() selectMatchingVariable: EventEmitter<DeviceVariable> = new EventEmitter();
  @Output() removeSourcedVariable: EventEmitter<void> = new EventEmitter<void>();
  @Output() updateAssetVariableThresholds: EventEmitter<AssetVariableThresholds> = new EventEmitter();

  formulaTypes: string[] = [
    FormulaType.EQUAL,
    FormulaType.MIN_MAX,
    FormulaType.MIN_MAX_ADVANCED,
    FormulaType.SLOPE_SHIFT,
    FormulaType.LINEARIZATION,
    FormulaType.CONSTANT,
    FormulaType.CONSUMPTION_FOR_PG,
    FormulaType.SHORTFALL
  ];
  formula!: Formula;

  assetVariableFormulaForm!: UntypedFormGroup;
  isSourceVariableValid = false;
  areThresholdsCorrect = false;

  protected readonly FormulaType = FormulaType;
  protected readonly TemplateSuggestedVariableMatchingType = TemplateSuggestedVariableMatchingType;

  destroy$ = new Subject<void>();

  get formulaTypeControl(): AbstractControl {
    return this.assetVariableFormulaForm.get('formulaType') as AbstractControl;
  }

  get sourceVariableControl(): AbstractControl {
    return this.assetVariableFormulaForm.get('sourceVariable') as AbstractControl;
  }

  get thresholdsControl(): AbstractControl {
    return this.assetVariableFormulaForm.get('thresholds') as AbstractControl;
  }

  ngOnInit(): void {
    this.assetVariableFormulaForm?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe();
    this.formula = {
      model: this.assetTemplateVariableFormula?.model,
      parameters: { ...this.assetTemplateVariableFormula?.parameters },
      srcVariables: { ...(this.assetTemplateVariableFormula?.srcVariables as { [key: string]: FormulaSourceVariable }) },
      isActive: false,
      version: null
    };
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('assetTemplateVariableFormula') && changes.assetTemplateVariableFormula.currentValue) {
      this.initForm(changes.assetTemplateVariableFormula.currentValue);
      this.setIsSourceVariableValid(changes.assetTemplateVariableFormula.currentValue);
    }
  }

  initForm(formula: AssetTemplateVariableFormula): void {
    this.assetVariableFormulaForm = new UntypedFormGroup({
      formulaType: new UntypedFormControl(formula.model ?? FormulaType.EQUAL, [Validators.required]),
      sourceVariable: new UntypedFormControl(formula.evaluation?.sourceVariable, [Validators.required]),
      thresholds: new UntypedFormControl(this.thresholds)
    });

    if (this.readonly) {
      this.formulaTypeControl.disable();
    }
  }

  setIsSourceVariableValid(formula: AssetTemplateVariableFormula): void {
    if (formula.evaluation?.sourceVariable?.lastValue) {
      this.isSourceVariableValid =
        (formula.evaluation.sourceVariable.lastValue?.value !== null && formula.evaluation.sourceVariable.lastValue?.value !== undefined) ?? false;
    } else {
      this.isSourceVariableValid = false;
    }
  }

  onThresholdsChange(thresholds: AssetVariableThresholds | null): void {
    this.areThresholdsCorrect = !!thresholds;

    if (this.areThresholdsCorrect) {
      this.thresholdsControl.setValue(thresholds);
    }

    this.updateAssetVariableThresholds.emit(this.thresholdsControl.value);
  }

  onMatchingVariableSelection(event: MatSelectChange): void {
    this.selectMatchingVariable.emit(event.value);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
