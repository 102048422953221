import { Clipboard } from '@angular/cdk/clipboard';
import { Component, inject } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatToolbar } from '@angular/material/toolbar';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    imports: [FlexLayoutModule, TranslateModule, MatButtonModule, MatIcon, MatCardModule, MatToolbar],
    selector: 'iot-platform-users-user-profile-api-keys-info-popup',
    templateUrl: './user-profile-api-keys-info-popup.component.html',
    styleUrls: ['./user-profile-api-keys-info-popup.component.scss']
})
export class UserProfileApiKeysInfoPopupComponent {
  public clipboard = inject(Clipboard);
  public dialogRef: MatDialogRef<UserProfileApiKeysInfoPopupComponent> = inject(MatDialogRef<UserProfileApiKeysInfoPopupComponent>);
  public data: { apiKeyConsumerKey: string } = inject(MAT_DIALOG_DATA);

  copy(): void {
    this.clipboard.copy(this.data.apiKeyConsumerKey);
  }

  exit() {
    this.dialogRef.close();
  }
}
