import { Injectable } from '@angular/core';
import { ContactsFacade as SharedContactsFacade } from '@iot-platform/data-access/contacts';
import * as fromContacts from '../reducers';

@Injectable({
  providedIn: 'root'
})
export class ContactsFacade extends SharedContactsFacade {
  public contacts$ = this.store.select(fromContacts.selectContactsWithNotifications);
  public contacts = this.store.selectSignal(fromContacts.selectContactsWithNotifications);
}
