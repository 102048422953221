import { NgComponentOutlet } from '@angular/common';
import { Component, computed, inject, input, output, Signal, Type } from '@angular/core';

import { MasterViewEngineEvent } from '@iot-platform/models/common';
import { I4BCellType } from '@iot-platform/models/grid-engine';
import { MasterViewComponentFactory } from '../../master-view-engine/master-view-table/master-view-factory';

@Component({
  selector: 'i4b-table-engine-master-view-cell-container',
  templateUrl: './master-view-cell-container.component.html',
  imports: [NgComponentOutlet],
  styleUrls: ['./master-view-cell-container.component.scss']
})
export class MasterViewCellContainerComponent {
  private readonly masterViewComponentFactory: MasterViewComponentFactory = inject(MasterViewComponentFactory);

  column = input<any>();
  element = input<any>();
  userPermissions = input<{ key: string; value: boolean }[]>();
  dispatchEvent = output<MasterViewEngineEvent>();
  componentRef: Signal<{
    ref: Type<any>;
    inputs: any;
  } | null> = computed(() => {
    const element = this.element();
    if (element) {
      return this.createCell();
    }
    return null;
  });

  createCell() {
    const column = this.column();
    const element = this.element();
    const userPermissions = this.userPermissions();
    const componentRef: any = {
      ref: this.masterViewComponentFactory.createCellComponent(column.cellType),
      inputs: {
        onDispatchEvent: (event: MasterViewEngineEvent) => {
          if (event) {
            this.dispatchEvent.emit(event);
          }
        }
      }
    };
    const elementProperties = column.id.split('&');
    let dataToDisplay = '';

    if (elementProperties.length === 1) {
      dataToDisplay = this.getElementPropertyByPath(elementProperties[0]);
    }
    if (elementProperties.length > 1) {
      elementProperties.forEach((property) => {
        const subData = this.getElementPropertyByPath(property);

        if (subData !== null) {
          dataToDisplay = dataToDisplay.concat(' ', subData).trim();
        }
      });
    }

    if (column.cellTypeOptions) {
      componentRef.inputs.cellOptions = column.cellTypeOptions;
    }

    componentRef.inputs.data = dataToDisplay;
    componentRef.inputs.rawData = element;
    componentRef.inputs.elementId = element.id;

    if (column.isLink || column.cellType === I4BCellType.BUTTON_CELL) {
      componentRef.inputs.clickEvent = column.clickEvent;
    }

    if (column.cellType === 'assetVariableCell') {
      componentRef.inputs.columnId = column.id;
      componentRef.inputs.userPermissions = userPermissions;
    }

    return componentRef;
  }

  getElementPropertyByPath(path: string): string {
    const clonedElement = Object.assign({}, this.element());
    return path.split('.').reduce((acc, value) => {
      if (acc) {
        return (acc = acc[value]);
      } else {
        return acc;
      }
    }, clonedElement);
  }
}
