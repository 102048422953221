import { Component, input } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TagCategory } from '@iot-platform/models/common';
import { TranslateModule } from '@ngx-translate/core';
import { CardLoaderComponent } from '../../card-loader/card-loader.component';
import { ChipComponent } from '../../chip/chip.component';
import { DetailPopupSectionHeaderComponent } from '../../detail-popup/detail-popup-section-header/detail-popup-section-header.component';

@Component({
  imports: [FlexLayoutModule, TranslateModule, DetailPopupSectionHeaderComponent, CardLoaderComponent, ChipComponent],
  selector: 'iot-platform-ui-site-detail-tags',
  templateUrl: './site-detail-tags.component.html',
  styleUrls: ['./site-detail-tags.component.scss']
})
export class SiteDetailTagsComponent {
  tags = input<TagCategory[]>([]);
  title = input<string | null>(null);
  loaded = input<boolean>(false);
}
