import { NgClass } from '@angular/common';
import { Component, computed, DestroyRef, effect, inject, model, Signal, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIcon } from '@angular/material/icon';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatTooltip } from '@angular/material/tooltip';
import {
  DeviceCommandsStatuses,
  DeviceCommandStatus,
  DeviceLastCommandErrorStatus,
  DeviceLastCommandStepStatus,
  LastCommandStatusStates
} from '@iot-platform/models/common';
import { Device } from '@iot-platform/models/i4b';
import { DateFormatPipe } from '@iot-platform/pipes';
import { DevicesService } from '@iot-platform/shared/services';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { of, Subject, switchMap } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';

interface CustomCommandStatus {
  deviceId: string;
  commandName: string;
  commandStatus: DeviceCommandStatus;
  iconToDisplay?: { name: string; className: string };
  state?: string;
}

@Component({
    selector: 'iot-platform-ui-device-latest-command-status',
    imports: [DateFormatPipe, TranslateModule, FlexLayoutModule, MatTooltip, NgClass, MatProgressSpinner, MatIcon],
    templateUrl: './device-latest-command-status.component.html',
    styleUrls: ['./device-latest-command-status.component.scss']
})
export class DeviceLatestCommandStatusComponent {
  device = model<Device>();

  devicesService = inject(DevicesService);
  translateService = inject(TranslateService);
  destroyRef = inject(DestroyRef);

  mostRecentCommand: WritableSignal<CustomCommandStatus | null> = signal(null);
  errorList: WritableSignal<string[]> = signal(Object.values(DeviceLastCommandErrorStatus));
  tooltipMessage: Signal<string> = computed(() => {
    const device = this.device();
    return this.getTooltipMessage(device);
  });
  readonly COMMAND_REFRESH_TIMER = 5000;
  commandsStatusTrigger$: Subject<string> = new Subject();

  constructor() {
    this.initDeviceEffect();

    this.commandsStatusTrigger$
      .pipe(
        debounceTime(this.COMMAND_REFRESH_TIMER),
        switchMap((deviceId) => {
          if (deviceId === this.mostRecentCommand()?.deviceId) {
            return this.devicesService.getById(deviceId).pipe(tap((d) => this.device.set(d)));
          } else {
            return of(null);
          }
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((device) => {
        this.onCommandStatusChange(device);
      });
  }

  static getIconToDisplayByState(state: string): { name: string; className: string } | null {
    let icon: { name: string; className: string } | null = { name: '', className: '' };
    switch (state) {
      case LastCommandStatusStates.ONGOING:
        icon = null;
        break;
      case LastCommandStatusStates.ERROR:
        icon = { name: 'warning', className: 'error' };
        break;
      case LastCommandStatusStates.COMPLETED:
        icon = { name: 'done', className: 'completed' };
        break;
      default:
        break;
    }
    return icon;
  }

  setMostRecentCommand(commands: DeviceCommandsStatuses): void {
    if (commands) {
      this.mostRecentCommand.set(null);
      const commandsEntries: [string, DeviceCommandStatus][] = Object.entries(commands);
      const cmd = commandsEntries.reduce((currentMostRecentCommand: CustomCommandStatus | null, value: [string, DeviceCommandStatus]) => {
        if (
          (!currentMostRecentCommand && value[1]?.status && value[1]?.timestamp) ||
          value[1]?.timestamp > currentMostRecentCommand?.commandStatus?.timestamp
        ) {
          currentMostRecentCommand = {
            deviceId: this.device()?.id as string,
            commandName: value[0],
            commandStatus: { ...value[1] }
          };

          if (this.errorList().includes(currentMostRecentCommand.commandStatus?.status)) {
            currentMostRecentCommand.state = LastCommandStatusStates.ERROR;
          } else if (currentMostRecentCommand.commandStatus?.status === DeviceLastCommandStepStatus.COMPLETED_COMMAND_PROCESSING) {
            currentMostRecentCommand.state = LastCommandStatusStates.COMPLETED;
          } else {
            currentMostRecentCommand.state = LastCommandStatusStates.ONGOING;
          }

          currentMostRecentCommand.iconToDisplay = DeviceLatestCommandStatusComponent.getIconToDisplayByState(currentMostRecentCommand.state);
        }
        return currentMostRecentCommand;
      }, null);
      this.mostRecentCommand.set(cmd);
    }
  }

  onCommandStatusChange(device: Device | null): void {
    this.setMostRecentCommand(device?.commandsStatuses ?? {});

    if (
      this.mostRecentCommand() &&
      !this.errorList().includes(this.mostRecentCommand().commandStatus.status) &&
      this.mostRecentCommand().commandStatus.status !== DeviceLastCommandStepStatus.COMPLETED_COMMAND_PROCESSING
    ) {
      this.commandsStatusTrigger$.next(device.id);
    }
  }

  getTooltipMessage(device: Device): string {
    if (device.lastCommandStatus?.name === 'failure') {
      const translationKey = 'DEVICES.COMMANDS_STATUSES.';
      if ((this.translateService.instant(translationKey + device.lastCommandStatus.commandErrorReason) as string).includes(translationKey)) {
        return device.lastCommandStatus.commandErrorReason ?? device.lastCommandStatus.status;
      } else {
        return this.translateService.instant(translationKey + device.lastCommandStatus.commandErrorReason);
      }
    }
    return '';
  }

  private initDeviceEffect(): void {
    effect(
      () => {
        const device = this.device();

        if (device) {
          if (device.commandsStatuses) {
            this.onCommandStatusChange(device);
          }
        } else {
          this.mostRecentCommand.set(null);
        }
      }
    );
  }
}
