@if (navBlock()?.display) {
  <div class="iot-platform-ui-sidenav-block" data-cy="iot-platform-ui-sidenav-block">
    @if (navBlock().title) {
      <header class="title fw-600">
        <span>{{ navBlock().title | translate }}</span>
        <hr class="regular-1px-line flex-1" />
      </header>
    }
    <section>
      @for (item of navBlock().items; track $index) {
        <iot-platform-ui-sidenav-items [item]="item" />
      }
    </section>
  </div>
}
