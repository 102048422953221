import { Component, effect, model } from '@angular/core';
import { HashUtils } from '@iot-platform/iot-platform-utils';
import { SidenavMenuBlock, SidenavMenuItem } from '@iot-platform/models/i4b';

@Component({
  selector: 'iot-platform-ui-sidenav-block',
  templateUrl: './sidenav-block.component.html',
  styleUrls: ['./sidenav-block.component.scss'],
  standalone: false
})
export class SidenavBlockComponent {
  navBlock = model.required<SidenavMenuBlock>();

  initEffect = effect(() => {
    const navBlock = this.navBlock();
    if (navBlock) {
      navBlock.items = navBlock?.items?.map((item: SidenavMenuItem) => ({
        ...item,
        id: HashUtils.simpleHashFromObj(item),
        hasChildren: !!item.submenu?.length
      }));

      this.navBlock.set(navBlock);
    }
  });
}
