import { I4BCellType } from '../configurations/i4b-column-configuration';
import { I4bCellConcept } from '../enums/i4b-cell-concept.enum';
import { HeaderType } from '../models/i4b-column-header.model';
import { I4BButtonColumn } from './i4b-button.column';

export class I4BCalendarsButtonColumn extends I4BButtonColumn {
  constructor() {
    super(
      {
        type: HeaderType.CALL_TO_ACTION,
        displayName: 'BUTTON',
        icon: ''
      },
      {
        concept: I4bCellConcept.CALENDARS,
        isConfigurable: false,
        isDefault: true,
        isLink: false,
        position: '',
        sortProperty: '',
        width: 10,
        id: 'button',
        cell: {
          type: I4BCellType.CALL_TO_ACTION,
          enableConditions: [],
          visibleConditions: [
            [
              { key: 'canUpdateCalendar', value: true },
              { key: 'canDeleteCalendar', value: true }
            ]
          ],
          singleActions: [
            {
              order: 10,
              key: 'edit',
              label: 'IOT_DICTIONARY.EDIT',
              enableConditions: [],
              disableConditions: [],
              visibleConditions: [[{ key: 'canUpdateCalendar', value: true }]]
            },
            {
              order: 20,
              key: 'navigate',
              label: 'IOT_DICTIONARY.CONFIGURE',
              enableConditions: [],
              disableConditions: [],
              visibleConditions: [[{ key: 'canUpdateCalendar', value: true }]]
            },
            {
              order: 30,
              key: 'delete',
              label: 'IOT_DICTIONARY.DELETE',
              enableConditions: [],
              disableConditions: [],
              visibleConditions: [[{ key: 'canDeleteCalendar', value: true }]]
            }
          ]
        }
      },
      { order: 1000 }
    );
  }
}
