import { Component, OnInit } from '@angular/core';

import { FlexLayoutModule } from '@angular/flex-layout';
import { AbstractControl, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { FormulaType } from '@iot-platform/models/common';
import { AssetVariable, ConsumptionForPGSrcVariables, Formula } from '@iot-platform/models/i4b';
import { TranslateModule } from '@ngx-translate/core';
import { MultipleVariablesParametersComponent } from '../multiple-variables-parameters/multiple-variables-parameters.component';
import { VariableParameterComponent } from '../variable-parameter/variable-parameter.component';

interface ConsumptionParameters {
  name: string;
  unit: string;
}

@Component({
    imports: [FlexLayoutModule, TranslateModule, ReactiveFormsModule, MatInputModule, VariableParameterComponent],
    selector: 'iot4bos-ui-asset-consumption-parameters-form',
    templateUrl: '../multiple-variables-parameters/multiple-variables-parameters.component.html'
})
export class ConsumptionParametersFormComponent extends MultipleVariablesParametersComponent implements OnInit {
  formulaParameters: ConsumptionParameters[] = [
    { name: 'leftPressure', unit: 'bar' },
    { name: 'leftVolume', unit: 'm3' },
    { name: 'rightPressure', unit: 'bar' },
    { name: 'rightVolume', unit: 'm3' }
  ];

  assetVariableFormulaSrcVariables: ConsumptionForPGSrcVariables = {
    'left-pressure': {
      name: 'left-pressure',
      variableId: '',
      type: 'asset-variable',
      originId: ''
    },
    'right-pressure': {
      name: 'right-pressure',
      variableId: '',
      type: 'asset-variable',
      originId: ''
    },
    'left-volume': {
      name: 'left-volume',
      variableId: '',
      type: 'asset-variable',
      originId: ''
    },
    'right-volume': {
      name: 'right-volume',
      variableId: '',
      type: 'asset-variable',
      originId: ''
    }
  };

  assetVariableFormula: Formula = {
    model: FormulaType.CONSUMPTION_FOR_PG,
    parameters: {},
    srcVariables: this.assetVariableFormulaSrcVariables
  };

  invalidUnitTooltip = 'ASSETS.VARIABLE_FORM.CONSUMPTION_FORM.INVALID_UNIT_TOOLTIP';
  placeholder = 'ASSETS.VARIABLE_FORM.CONSUMPTION_FORM.PLACEHOLDERS.';

  get leftPressure(): AbstractControl {
    return this.parametersForm.get('leftPressure');
  }

  get leftVolume(): AbstractControl {
    return this.parametersForm.get('leftVolume');
  }

  get rightPressure(): AbstractControl {
    return this.parametersForm.get('rightPressure');
  }

  get rightVolume(): AbstractControl {
    return this.parametersForm.get('rightVolume');
  }

  ngOnInit() {
    const srcVariables = this.initialFormula?.srcVariables as ConsumptionForPGSrcVariables;

    this.parametersForm = new UntypedFormGroup({
      leftPressure: new UntypedFormControl(
        this.initialFormula ? this.initialSelectedVariables?.find((assetVar) => assetVar.id === srcVariables['left-pressure']?.variableId) : null,
        [Validators.required, this.checkVariableUnitPressure]
      ),
      leftVolume: new UntypedFormControl(
        this.initialFormula ? this.initialSelectedVariables?.find((assetVar) => assetVar.id === srcVariables['left-volume']?.variableId) : null,
        [Validators.required, this.checkVariableUnitVolume]
      ),
      rightPressure: new UntypedFormControl(
        this.initialFormula ? this.initialSelectedVariables?.find((assetVar) => assetVar.id === srcVariables['right-pressure']?.variableId) : null,
        [Validators.required, this.checkVariableUnitPressure]
      ),
      rightVolume: new UntypedFormControl(
        this.initialFormula ? this.initialSelectedVariables?.find((assetVar) => assetVar.id === srcVariables['right-volume']?.variableId) : null,
        [Validators.required, this.checkVariableUnitVolume]
      )
    });
    this.parametersForm.markAsUntouched();

    this.setFirstEmptyParameter();

    this.subscriptions.push(
      this.parametersForm.valueChanges.subscribe(() => {
        this.sendFormula();
      })
    );
    this.sendFormula();
  }

  checkVariableUnitPressure(parameter: AbstractControl): { invalidUnit: boolean } | null {
    if (parameter.value?.unit !== 'bar') {
      return { invalidUnit: true };
    }
    return null;
  }

  checkVariableUnitVolume(parameter: AbstractControl): { invalidUnit: boolean } | null {
    if (parameter.value?.unit !== 'm3') {
      return { invalidUnit: true };
    }
    return null;
  }

  assignAssetVariableToFirstEmptyParameter(assetVariable: AssetVariable): void {
    if (!!this.firstEmptyParameter && !this.formulaParameters.find((parameter) => this[parameter.name].value?.id === assetVariable.id)) {
      this[this.firstEmptyParameter.name].setValue(assetVariable);
    }
  }

  createFormula(): void {
    this.assetVariableFormulaSrcVariables = {
      'left-pressure': {
        ...this.assetVariableFormulaSrcVariables['left-pressure'],
        variableId: this.leftPressure.value.id,
        originId: this.leftPressure.value.asset.id
      },
      'right-pressure': {
        ...this.assetVariableFormulaSrcVariables['right-pressure'],
        variableId: this.rightPressure.value.id,
        originId: this.rightPressure.value.asset.id
      },
      'left-volume': {
        ...this.assetVariableFormulaSrcVariables['left-volume'],
        variableId: this.leftVolume.value.id,
        originId: this.leftVolume.value.asset.id
      },
      'right-volume': {
        ...this.assetVariableFormulaSrcVariables['right-volume'],
        variableId: this.rightVolume.value.id,
        originId: this.rightVolume.value.asset.id
      }
    };
    this.assetVariableFormula = { ...this.assetVariableFormula, srcVariables: this.assetVariableFormulaSrcVariables };
  }
}
