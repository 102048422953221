import { NgClass } from '@angular/common';
import { Component } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { DateFormatPipe } from '@iot-platform/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { AbstractTableEngineCellComponent } from '../abstract-table-engine-cell.component';

@Component({
  selector: 'i4b-table-engine-last-command-status-cell',
  templateUrl: './last-command-status-cell.component.html',
  styleUrls: ['./last-command-status-cell.component.scss'],
  imports: [NgClass, MatTooltip, DateFormatPipe, TranslateModule]
})
export class LastCommandStatusCellComponent extends AbstractTableEngineCellComponent<LastCommandStatusCellComponent> {}
