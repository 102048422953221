import { SelectionModel } from '@angular/cdk/collections';
import { Component, DestroyRef, inject, Inject, OnInit, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { SortUtil } from '@iot-platform/iot-platform-utils';
import { FormulaType, LastValue } from '@iot-platform/models/common';
import {
  Asset,
  AssetTemplate,
  AssetTemplateVariable,
  AssetTemplateVariableFormula,
  AssetVariable,
  AssetVariableThresholds,
  ConstantParameters,
  Device,
  DeviceVariable,
  TemplateSuggestedVariable,
  TemplateSuggestedVariableMatchingType
} from '@iot-platform/models/i4b';
import { AssetsService, AssetVariablesService } from '@iot-platform/shared/services';
import { get } from 'lodash';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Component({
    selector: 'iot4bos-ui-asset-template-variables-configuration-form',
    templateUrl: './asset-template-variables-configuration-form.component.html',
    styleUrls: ['./asset-template-variables-configuration-form.component.scss'],
    standalone: false
})
export class AssetTemplateVariablesConfigurationFormComponent implements OnInit {
  configureForm = new UntypedFormGroup({});

  assetTemplate!: AssetTemplate;
  selectedAssetVariables = new SelectionModel<AssetTemplateVariable>(true, []);

  assetTemplateLoading: WritableSignal<boolean> = signal(true);

  sourceTypes: string[] = ['asset', 'device'];

  assetList$: Observable<Asset[]> = of([]);
  assetVariablesByAssetId: { assetId: string; assetVariables: AssetVariable[] }[] = [];
  assetVariablesToDisplay: AssetVariable[] = [];

  deviceList$: Observable<Device[]> = of([]);
  deviceVariablesByDeviceId: { deviceId: string; deviceVariables: DeviceVariable[] }[] = [];
  deviceVariablesToDisplay: DeviceVariable[] = [];

  displayLoader = false;
  globalCheckboxDefaultState = true;

  variablesToBeEstimated = 0;
  variablesWithCorrectConfigurationTotal = 0;

  step = 1;
  creationBufferProgress = 0;
  createdProgress = 0;
  creationInProgress = false;
  hasError = false;
  selectedVariablesCount = 0;
  linkedVariablesCount = 0;
  countNewVariables = 0;
  creationErrorVariables: AssetVariable[] = [];

  unitList: { name: string; units: string[] }[] = [];

  destroy: DestroyRef = inject(DestroyRef);

  constructor(
    private readonly assetsService: AssetsService,
    private readonly assetVariableService: AssetVariablesService,
    public dialogRef: MatDialogRef<AssetTemplateVariablesConfigurationFormComponent>,
    @Inject(MAT_DIALOG_DATA)
    readonly data: {
      siteId: string;
      assetTemplateId: string;
      assetVariables: AssetVariable[];
      asset?: Asset;
    }
  ) {}

  get sourceType(): AbstractControl {
    return this.configureForm.get('sourceType') as AbstractControl;
  }

  get deviceSource(): AbstractControl {
    return this.configureForm.get('deviceSource') as AbstractControl;
  }

  get assetSource(): AbstractControl {
    return this.configureForm.get('assetSource') as AbstractControl;
  }

  get deviceVariable(): AbstractControl {
    return this.configureForm.get('deviceVariable') as AbstractControl;
  }

  get assetVariable(): AbstractControl {
    return this.configureForm.get('assetVariable') as AbstractControl;
  }

  static isConfigurationReadyForEvaluation(formula: AssetTemplateVariableFormula, lastRecord?: LastValue[]): boolean {
    if (formula.model === FormulaType.CONSTANT) {
      return AssetTemplateVariablesConfigurationFormComponent.isConstantFormulaCorrect(formula);
    }
    return !!formula.model && !!Object.values(formula.srcVariables)[0]?.variableId && !!lastRecord?.length;
  }

  static isConstantFormulaCorrect(formula: AssetTemplateVariableFormula): boolean {
    return (
      formula &&
      formula.model === FormulaType.CONSTANT &&
      (formula.parameters as ConstantParameters)?.constantValue !== null &&
      (formula.parameters as ConstantParameters)?.constantValue !== undefined
    );
  }

  ngOnInit(): void {
    this.assetVariableService
      .getUnits()
      .pipe(takeUntilDestroyed(this.destroy))
      .subscribe((units) => (this.unitList = units));

    this.assetsService
      .getAssetTemplateById(this.data.assetTemplateId)
      .pipe(takeUntilDestroyed(this.destroy))
      .subscribe({
        next: (template) => {
          this.assetTemplate = template;

          if (this.data.assetVariables) {
            this.assetTemplate.template.variables = this.assetTemplate.template.variables
              .filter((v) =>
                this.data.assetVariables?.find((assetVariable) => assetVariable.name?.toLowerCase() === v.name.toLowerCase() && !assetVariable.formula.isActive)
              )
              .sort(SortUtil.sortByName);
          }
          this.initForm();
          this.evaluateConstantFormulas();

          if (this.globalCheckboxDefaultState) {
            this.toggleAllSelection();
          }
          this.assetTemplateLoading.set(false);
        },
        error: () => {
          this.assetTemplateLoading.set(false);
        }
      });

    this.assetVariableService.formulaResultForTemplateConfiguration$.pipe(takeUntilDestroyed(this.destroy)).subscribe((results) => {
      if (results?.estimationRecord) {
        this.addEstimationInVariable(results.assetVariable, results.estimationRecord);
      }
      this.variablesToBeEstimated -= 1;

      if (this.variablesToBeEstimated < 1) {
        this.displayLoader = false;
      }
      this.setVariablesWithCorrectConfigurationTotal();
    });
  }

  addEstimationInVariable(variable: AssetTemplateVariable, estimationRecord: LastValue[]): void {
    const variableToUpdate = this.assetTemplate.template.variables.find((v) => v.name === variable.name);
    if (variableToUpdate) {
      const variableIndex = this.assetTemplate.template.variables.indexOf(variableToUpdate);

      this.assetTemplate.template.variables[variableIndex].formula = {
        ...this.assetTemplate.template.variables[variableIndex].formula,
        evaluation: {
          ...this.assetTemplate.template.variables[variableIndex].formula.evaluation,
          lastRecords: estimationRecord
        }
      };
    }
  }

  evaluateConstantFormulas(): void {
    this.assetTemplate.template.variables
      .filter((v) => v.formula?.model === FormulaType.CONSTANT)
      .forEach((v) => {
        this.variablesToBeEstimated += 1;
        this.assetVariableService.calculateFormulaResultForTemplate({ formula: { ...v.formula, isActive: true }, records: [] }, v);
      });
  }

  setVariablesWithCorrectConfigurationTotal(): void {
    this.variablesWithCorrectConfigurationTotal = this.assetTemplate.template.variables.filter((v) => this.isConfigurationCorrect(v)).length;
  }

  initForm(): void {
    this.configureForm = new UntypedFormGroup({
      sourceType: new UntypedFormControl('devices'),
      deviceSource: new UntypedFormControl(),
      deviceVariable: new UntypedFormControl(),
      assetVariable: new UntypedFormControl(),
      assetSource: new UntypedFormControl()
    });

    this.onSourceTypeChange({ source: {}, value: this.sourceType.value } as MatRadioChange);
  }

  toggleAllSelection(): void {
    if (this.isAllSelected()) {
      this.selectedAssetVariables.clear();
    } else {
      this.assetTemplate.template.variables.forEach((v) => {
        if (!!v.formula) {
          this.selectedAssetVariables.select(v);
        }
      });
    }
  }

  isChecked(variable: AssetTemplateVariable): boolean {
    if (!variable.formula) {
      this.selectedAssetVariables.deselect(variable);
    }
    return this.selectedAssetVariables.isSelected(variable);
  }

  toggleSelection(variable: AssetTemplateVariable): void {
    this.selectedAssetVariables.toggle(variable);
  }

  isAllSelected(): boolean {
    return this.selectedAssetVariables.selected.length === this.assetTemplate.template.variables.length;
  }

  onSourceTypeChange(change: MatRadioChange): void {
    this.assetSource.reset();
    this.deviceSource.reset();
    this.deviceVariablesToDisplay = [];
    this.assetVariablesToDisplay = [];

    if (change.value === 'devices') {
      this.setDeviceList();
    }
  }

  onDeviceSelection(deviceId: string): void {
    this.clearMatchingVariables();
    const deviceVariables:
      | {
          deviceId: string;
          deviceVariables: DeviceVariable[];
        }
      | undefined = this.deviceVariablesByDeviceId.find((item) => item.deviceId === deviceId);

    if (!deviceVariables) {
      this.displayLoader = true;
      this.assetVariableService
        .getDeviceVariablesByDeviceId(deviceId, 3000)
        .pipe(takeUntilDestroyed(this.destroy))
        .subscribe((deviceVars) => {
          this.deviceVariablesByDeviceId.push({ deviceId, deviceVariables: deviceVars });
          this.deviceVariablesToDisplay = deviceVars;
          this.displayLoader = false;
        });
    } else {
      this.deviceVariablesToDisplay = deviceVariables.deviceVariables;
    }
  }

  clearMatchingVariables(): void {
    this.assetTemplate.template.variables.forEach((templateVariable: AssetTemplateVariable) => {
      if (templateVariable.formula) {
        const srcVariablesKeys = Object.keys(templateVariable.formula.srcVariables);
        srcVariablesKeys.forEach((key: string) => {
          if (!!templateVariable.formula.srcVariables[key].match) {
            templateVariable.formula.srcVariables[key].match.matchingVariables = undefined;
          } else {
            templateVariable.formula.srcVariables[key].match = null;
          }
        });
      }
      return templateVariable;
    });
  }

  setDeviceList(): void {
    if (!!this.data.siteId) {
      this.deviceList$ = this.assetVariableService
        .getDevicesBySiteId(this.data.siteId)
        .pipe(map((devices) => devices.filter((d) => d.status?.name !== 'decommissioned')));
    } else {
      this.deviceList$ = of([]);
    }
  }

  loadDeviceVariableList$(deviceId: string | undefined): Observable<DeviceVariable[]> {
    if (!!deviceId) {
      return this.assetVariableService.getDeviceVariablesByDeviceId(deviceId);
    } else {
      return of([]);
    }
  }

  onAssignSuggestedFormulasClicked(variable?: AssetTemplateVariable): void {
    this.displayLoader = true;
    this.variablesToBeEstimated = !!variable ? 1 : this.selectedAssetVariables.selected.length;

    if (variable) {
      if (!!variable.formula) {
        const variableToConfigure: AssetTemplateVariable | undefined = this.assetTemplate.template.variables.find(
          (v) => v.name.toLowerCase() === variable.name.toLowerCase()
        );

        if (variableToConfigure) {
          const variableToConfigureIndex = this.assetTemplate.template.variables.indexOf(variableToConfigure);
          const variableWithConfiguration = this.getVariableWithConfiguration(variableToConfigure);

          this.assetTemplate.template.variables[variableToConfigureIndex].formula = {
            ...variableToConfigure.formula,
            srcVariables: variableWithConfiguration.formula.srcVariables,
            evaluation: variableWithConfiguration.formula.evaluation
          };
          this.sendEvaluationRequest(this.assetTemplate.template.variables[variableToConfigureIndex]);
        }
      } else {
        this.variablesToBeEstimated--;
      }
    } else {
      this.selectedAssetVariables.selected.forEach((selectedVariable) => {
        if (!!selectedVariable.formula) {
          selectedVariable = this.getVariableWithConfiguration(selectedVariable);
          this.sendEvaluationRequest(selectedVariable);
        } else {
          this.variablesToBeEstimated--;
        }
      });
    }

    if (this.variablesToBeEstimated < 1) {
      this.displayLoader = false;
    }
  }

  getVariableWithConfiguration(
    variable: AssetTemplateVariable,
    matchedVariable?: {
      [name: string]: DeviceVariable;
    }
  ): AssetTemplateVariable {
    if (variable.formula?.model !== FormulaType.CONSTANT) {
      // find matching variables for every source variables needed
      const srcVariablesKeys = Object.keys(variable.formula?.srcVariables);
      const configuredSrcVariables: { [name: string]: TemplateSuggestedVariable } = {};

      srcVariablesKeys.forEach((key) => {
        let matchingVariables: DeviceVariable[];

        if (!!matchedVariable && matchedVariable[key]) {
          matchingVariables = [matchedVariable[key]];
        } else {
          matchingVariables = this.findMatchingVariablesInArray(
            this.deviceVariablesToDisplay,
            variable.formula?.srcVariables[key]?.match?.suggestedNames,
            variable.formula?.srcVariables[key]?.match?.type
          );
        }
        if (matchingVariables.length === 1) {
          configuredSrcVariables[key] = {
            ...variable.formula.srcVariables[key],
            variableId: matchingVariables[0].id,
            lastRecords: variable.formula.model === FormulaType.CONSTANT ? [] : matchingVariables[0].lastValue ? [matchingVariables[0].lastValue] : [],
            originId: this.deviceSource.value.id
          } as TemplateSuggestedVariable;

          variable.formula = {
            ...variable.formula,
            parameters: { ...variable.formula.parameters },
            evaluation: { sourceVariable: matchingVariables[0], lastRecords: [] },
            srcVariables: configuredSrcVariables
          };
        } else if (matchingVariables.length > 1) {
          variable.formula.srcVariables[key].match.matchingVariables = matchingVariables;
        }
      });
    }
    return variable;
  }

  sendEvaluationRequest(variable: AssetTemplateVariable): void {
    if (
      AssetTemplateVariablesConfigurationFormComponent.isConfigurationReadyForEvaluation(
        variable.formula,
        Object.values(variable.formula.srcVariables)[0]?.lastRecords as LastValue[] | undefined
      )
    ) {
      const formula = {
        model: variable.formula.model,
        parameters: { ...variable.formula.parameters },
        srcVariables: { ...variable.formula.srcVariables },
        isActive: true
      };
      const records = variable.formula?.srcVariables['0']?.lastRecords ?? [];
      this.assetVariableService.calculateFormulaResultForTemplate({ formula, records }, variable);
    } else {
      this.variablesToBeEstimated -= 1;
    }
  }

  findMatchingVariablesInArray(
    sourceVariables: DeviceVariable[],
    suggestedNames: string[] = [],
    type: TemplateSuggestedVariableMatchingType = TemplateSuggestedVariableMatchingType.NONE
  ): DeviceVariable[] {
    let matchingVariables: DeviceVariable[] = [];
    switch (type) {
      case TemplateSuggestedVariableMatchingType.BEGINS_WITH:
        matchingVariables = sourceVariables.filter(
          (sourceVariable: DeviceVariable) =>
            !!suggestedNames.filter((suggestedName: string) => sourceVariable.name?.toLowerCase().startsWith(suggestedName.toLowerCase())).length
        );
        break;
      case TemplateSuggestedVariableMatchingType.EXACT:
        matchingVariables = sourceVariables.filter(
          (sourceVariable: DeviceVariable) =>
            !!suggestedNames.filter((suggestedName: string) => suggestedName.toLowerCase() === sourceVariable.name?.toLowerCase()).length
        );
        break;
      case TemplateSuggestedVariableMatchingType.NONE:
      default:
        break;
    }
    return matchingVariables;
  }

  isConfigurationCorrect(variable?: AssetTemplateVariable): boolean {
    if (variable?.formula?.model === FormulaType.CONSTANT) {
      return AssetTemplateVariablesConfigurationFormComponent.isConstantFormulaCorrect(variable?.formula);
    } else return !!variable?.formula?.evaluation?.lastRecords.length;
  }

  isVariableMatchingSourcePending(variable: AssetTemplateVariable): boolean {
    const srcVariablesKeys = Object.keys(get(variable, ['formula', 'srcVariables'], {}));
    let pendingVariablesTotal = 0;
    srcVariablesKeys.forEach((key) => {
      if (!!variable.formula.srcVariables[key].match?.matchingVariables) {
        pendingVariablesTotal += 1;
      }
    });
    return pendingVariablesTotal > 0;
  }

  getIconByVariable(variable?: AssetTemplateVariable): string {
    if (variable?.formula?.model === FormulaType.CONSTANT) {
      return 'swap_horiz';
    } else return !!variable?.formula?.evaluation?.lastRecords.length ? 'link' : 'link_off';
  }

  onResetFormulasClicked(variable?: AssetTemplateVariable): void {
    if (variable) {
      const variableToReset: AssetTemplateVariable | undefined = this.assetTemplate.template.variables.find(
        (v) => v.name.toLowerCase() === variable.name.toLowerCase()
      );

      if (variableToReset) {
        const variableToResetIndex = this.assetTemplate.template.variables.indexOf(variableToReset);
        const resetVariable = this.getVariableWithResetFormula(variableToReset);
        this.assetTemplate.template.variables[variableToResetIndex].formula = {
          ...resetVariable.formula,
          srcVariables: resetVariable.formula.srcVariables,
          evaluation: resetVariable.formula.evaluation
        };
      }
    } else {
      this.selectedAssetVariables.selected.forEach((v) => this.getVariableWithResetFormula(v));
    }

    this.setVariablesWithCorrectConfigurationTotal();
  }

  getVariableWithResetFormula(variable: AssetTemplateVariable): AssetTemplateVariable {
    if (variable.formula.model !== FormulaType.CONSTANT) {
      const srcVariablesKeys = Object.keys(variable.formula.srcVariables);
      const resetSrcVariables: { [name: string]: TemplateSuggestedVariable } = {};
      srcVariablesKeys.forEach((key) => {
        resetSrcVariables[key] = {
          ...variable.formula.srcVariables[key],
          variableId: null,
          lastRecords: null,
          originId: null
        } as TemplateSuggestedVariable;
      });
      variable.formula = { ...variable.formula, evaluation: undefined, srcVariables: resetSrcVariables };
    }
    return variable;
  }

  onMatchingVariableSelection(variable: AssetTemplateVariable, matchedVariable: DeviceVariable): void {
    this.variablesToBeEstimated = 1;

    const variableToConfigure: AssetTemplateVariable | undefined = this.assetTemplate.template.variables.find(
      (v) => v.name.toLowerCase() === variable.name.toLowerCase()
    );
    if (variableToConfigure) {
      const variableToConfigureIndex = this.assetTemplate.template.variables.indexOf(variableToConfigure);
      const variableWithConfiguration = this.getVariableWithConfiguration(variableToConfigure, { '0': matchedVariable });

      this.assetTemplate.template.variables[variableToConfigureIndex].formula = {
        ...variableToConfigure.formula,
        srcVariables: variableWithConfiguration.formula.srcVariables,
        evaluation: variableWithConfiguration.formula.evaluation
      };
      this.sendEvaluationRequest(this.assetTemplate.template.variables[variableToConfigureIndex]);
    }

    if (this.variablesToBeEstimated < 1) {
      this.displayLoader = false;
    }
  }

  onUpdateThresholds(variable: AssetTemplateVariable, thresholds: AssetVariableThresholds): void {
    const variableToUpdate: AssetTemplateVariable | undefined = this.assetTemplate.template.variables.find(
      (v) => v.name.toLowerCase() === variable.name.toLowerCase()
    );

    if (variableToUpdate) {
      const variableToUpdateIndex = this.assetTemplate.template.variables.indexOf(variableToUpdate);
      this.assetTemplate.template.variables[variableToUpdateIndex].thresholds = thresholds;
    }
  }

  submit(): void {
    switch (this.step) {
      case 1:
        this.step = 2;
        break;
      case 2:
        {
          this.save();
          this.step = 3;
        }
        break;
      case 3: {
        this.dialogRef.close(this.data.assetVariables[0]?.asset.id);
        break;
      }

      default:
        break;
    }
  }

  backToConfiguration(): void {
    this.step = 1;
  }

  save(): void {
    this.creationInProgress = true;
    this.createdProgress = 0;
    this.creationBufferProgress = 0;
    this.creationErrorVariables = [];

    let variablesToConfigure = this.data.assetVariables.filter((v) =>
      this.assetTemplate.template.variables.find(
        (templateVar) => templateVar.name.toLowerCase() === v.name?.toLowerCase() && this.isConfigurationCorrect(templateVar)
      )
    );

    variablesToConfigure = this.addFullConfigurationInAssetVariable(variablesToConfigure);

    combineLatest(
      variablesToConfigure.map((v) => {
        this.creationBufferProgress++;
        return this.assetVariableService.put(v).pipe(
          tap(() => this.createdProgress++),
          catchError(() => {
            this.creationErrorVariables.push(v);
            this.createdProgress++;
            return of();
          })
        );
      })
    )
      .pipe(
        catchError(() => {
          this.creationInProgress = false;
          return [];
        })
      )
      .subscribe(() => {
        if (this.creationErrorVariables.length === 0 && this.data.assetVariables[0]?.asset.id) {
          this.dialogRef.close(this.data.assetVariables[0]?.asset.id);
        }
        this.creationInProgress = false;
      });
  }

  addFullConfigurationInAssetVariable(assetVariableToConfigure: AssetVariable[]): AssetVariable[] {
    return assetVariableToConfigure.reduce((acc: AssetVariable[], value: AssetVariable) => {
      const correspondingTemplateVariable = this.assetTemplate.template.variables.find(
        (templateVar) => templateVar.name.toLowerCase() === value.name.toLowerCase()
      );
      if (correspondingTemplateVariable) {
        const configuredVariable: AssetVariable = {
          ...value,
          formula: {
            model: correspondingTemplateVariable.formula.model,
            parameters: correspondingTemplateVariable.formula.parameters,
            srcVariables: correspondingTemplateVariable.formula.srcVariables,
            version: null,
            isActive: true
          },
          lastValue: {
            datetime: correspondingTemplateVariable.formula.evaluation?.lastRecords[0].datetime,
            value: correspondingTemplateVariable.formula.evaluation?.lastRecords[0].value
          },
          thresholds: correspondingTemplateVariable.thresholds
        } as AssetVariable;
        acc.push(configuredVariable);
      }
      return acc;
    }, []);
  }

  close(): void {
    this.dialogRef.close();
  }
}
