import { computed, Injectable, Signal } from '@angular/core';
import { BaseFacade, BusinessProfile, CommonApiRequest, Filter, Pagination } from '@iot-platform/models/common';
import { Store } from '@ngrx/store';
import { noop } from 'rxjs';
import { AdminBusinessProfilesActions } from '../actions/business-profiles.actions';
import { AdminBusinessProfilesSelectors } from '../selectors/admin-business-profiles.selectors';

@Injectable({
  providedIn: 'root'
})
export class AdminBusinessProfilesFacade extends BaseFacade<BusinessProfile, Pagination, Filter> {
  constructor(
    protected override store: Store,
    protected override selector: AdminBusinessProfilesSelectors
  ) {
    super(store, selector);
  }

  searchString: Signal<string> = this.store.selectSignal(this.selector.selectSearchString);

  filteredProfiles = computed(() => {
    const profiles = this.all();
    const searchString = this.searchString();
    return profiles.filter((profile) => profile.name.toLowerCase().includes(searchString.toLowerCase()));
  });

  getAll(request: CommonApiRequest) {
    noop();
  }

  setFilters() {
    noop();
  }

  loadBusinessProfiles() {
    this.store.dispatch(AdminBusinessProfilesActions.loadBusinessProfiles());
  }

  selectBusinessProfile(toSelect: BusinessProfile) {
    this.store.dispatch(AdminBusinessProfilesActions.selectOne({ toSelect }));
  }

  navigateToSelectedOrganization(organizationId: string) {
    this.store.dispatch(AdminBusinessProfilesActions.navigateToSelectedOrganization({ organizationId }));
  }

  filterBusinessProfiles(searchString: string) {
    this.store.dispatch(AdminBusinessProfilesActions.filterBusinessProfiles({ searchString }));
  }

  addBusinessProfileToOrganization(toAdd: BusinessProfile) {
    this.store.dispatch(AdminBusinessProfilesActions.addOne({ toAdd }));
  }

  updateBusinessProfile(toUpdate: BusinessProfile) {
    this.store.dispatch(AdminBusinessProfilesActions.updateOne({ toUpdate }));
  }

  deleteBusinessProfile(toDelete: BusinessProfile) {
    this.store.dispatch(AdminBusinessProfilesActions.deleteOne({ toDelete }));
  }
}
