import { Component, computed, input, Signal } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

enum ComputedLayoutDirection {
  ABOVE = 'ABOVE',
  RIGHT = 'RIGHT',
  BENEATH = 'BENEATH',
  LEFT = 'LEFT',
  NONE = 'NONE'
}

@Component({
    imports: [FlexLayoutModule, MatProgressSpinner],
    selector: 'iot-platform-ui-detail-popup-section-header',
    templateUrl: './detail-popup-section-header.component.html',
    styleUrls: ['./detail-popup-section-header.component.scss']
})
export class DetailPopupSectionHeaderComponent {
  headerTitle = input<string>('');
  separatorLinePosition = input<string>('RIGHT');
  color = input<string>();
  loading = input<boolean>(false);

  computedLayoutDirection: Signal<string> = computed(() => {
    const separatorLinePosition = this.separatorLinePosition();
    return ComputedLayoutDirection[separatorLinePosition?.toUpperCase()];
  });
}
