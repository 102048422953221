import { Component, inject } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AbstractControl, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatStepperModule } from '@angular/material/stepper';
import { MatToolbarModule } from '@angular/material/toolbar';
import { CardLoaderModule, ManageTagsFormComponent } from '@iot-platform/iot-platform-ui';
import { Entity, TagCategory } from '@iot-platform/models/common';
import { Asset, AssetModel, Site } from '@iot-platform/models/i4b';
import { TranslateModule } from '@ngx-translate/core';
import { AssetInfoFormComponent } from '../asset-info-form/asset-info-form.component';
import { AssetModelFormComponent } from '../asset-model-form/asset-model-form.component';
import { TitleCasePipe } from '@angular/common';

@Component({
    imports: [
        TranslateModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        CardLoaderModule,
        TitleCasePipe,
        MatCardModule,
        MatIconModule,
        MatToolbarModule,
        MatStepperModule,
        MatButtonModule,
        AssetModelFormComponent,
        AssetInfoFormComponent,
        ManageTagsFormComponent
    ],
    selector: 'iot4bos-ui-create-asset-from-scratch-dialog',
    templateUrl: './create-asset-from-scratch-dialog.component.html',
    styleUrl: './create-asset-from-scratch-dialog.component.scss'
})
export class CreateAssetFromScratchDialogComponent {
  readonly data: { site: Site } = inject(MAT_DIALOG_DATA);
  readonly dialogRef: MatDialogRef<CreateAssetFromScratchDialogComponent> = inject(MatDialogRef<CreateAssetFromScratchDialogComponent>);

  firstStepForm!: UntypedFormGroup;
  secondStepForm!: UntypedFormGroup;
  thirdStepForm!: UntypedFormGroup;

  constructor() {
    this.initFirstStepForm();
    this.initSecondStepForm();
    this.initThirdStepForm();
  }

  get model(): AbstractControl {
    return this.firstStepForm.get('model') as AbstractControl;
  }

  get entity(): AbstractControl {
    return this.firstStepForm.get('entity') as AbstractControl;
  }

  get site(): AbstractControl {
    return this.firstStepForm.get('site') as AbstractControl;
  }

  get asset(): AbstractControl {
    return this.secondStepForm.get('asset') as AbstractControl;
  }

  get tags(): AbstractControl {
    return this.thirdStepForm.get('tags') as AbstractControl;
  }

  onAssetModelFilled(model: { model: AssetModel; entity: Entity; site: Site } | null): void {
    this.model.setValue(model?.model);
    this.entity.setValue(model?.entity);
    this.site.setValue(model?.site);
  }

  onAssetInfosFilled(asset: Asset | null): void {
    this.asset.setValue(asset);
  }

  onAssetTagsFilled(tags: TagCategory[] | null): void {
    this.tags.setValue(tags?.map((tag) => tag.labels?.map((label) => label.id)).flat());
  }

  save(): void {
    this.dialogRef.close({
      asset: { ...this.asset.getRawValue(), site: { ...this.site.getRawValue(), entity: { ...this.entity.getRawValue() } }, tags: this.tags.getRawValue() }
    });
  }

  private initFirstStepForm(): void {
    this.firstStepForm = new UntypedFormGroup({
      model: new UntypedFormControl(null, [Validators.required]),
      entity: new UntypedFormControl(null, [Validators.required]),
      site: new UntypedFormControl(null, [Validators.required])
    });
  }

  private initSecondStepForm(): void {
    this.secondStepForm = new UntypedFormGroup({
      asset: new UntypedFormControl(null, [Validators.required])
    });
  }

  private initThirdStepForm(): void {
    this.thirdStepForm = new UntypedFormGroup({
      tags: new UntypedFormControl(null, [Validators.required])
    });
  }
}
