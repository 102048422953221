import { Component, computed, Signal } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { IconModule } from '@iot-platform/iot-platform-ui';
import { AbstractTableEngineCellComponent } from '../abstract-table-engine-cell.component';

@Component({
  selector: 'i4b-table-engine-value-to-icon-cell',
  templateUrl: './value-to-icon-cell.component.html',
  styleUrls: ['./value-to-icon-cell.component.scss'],
  imports: [MatTooltip, IconModule]
})
export class ValueToIconCellComponent extends AbstractTableEngineCellComponent<ValueToIconCellComponent> {
  icon: Signal<string> = computed(() => {
    const cellOptions = this.cellOptions();
    const data = this.data();
    return cellOptions?.options?.find((option) => option.value === data)?.icon;
  });
}
