import { FlatTreeControl } from '@angular/cdk/tree';
import { NgClass } from '@angular/common';
import { Component, computed, effect, input, output, untracked } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeFlatDataSource, MatTreeFlattener, MatTreeModule } from '@angular/material/tree';

import { SortEntitiesUtil } from '@iot-platform/iot-platform-utils';

import { Organization } from '@iot-platform/models/common';
import { TranslateModule } from '@ngx-translate/core';

interface OrganizationNode extends Organization {
  children?: OrganizationNode[];
}

interface OrganizationFlatNode extends OrganizationNode {
  expandable: boolean;
  treeLevel: number;
}

@Component({
    imports: [FlexLayoutModule, MatTreeModule, MatIcon, TranslateModule, MatTooltipModule, MatButtonModule, NgClass],
    selector: 'iot4bos-ui-backoffice-organizations-list',
    templateUrl: './organizations-list.component.html',
    styleUrls: ['./organizations-list.component.scss', '../../../../style/admin.style.scss']
})
export class OrganizationsListComponent {
  organizations = input<Organization[]>([]);
  selectedOrganization = input<Organization>();
  canCreateOrganization = input<boolean>(false);
  canUpdateOrganization = input<boolean>(false);
  isTopLevelAdmin = input<boolean>(false);

  selectOrganization = output<Organization>();
  addOrganization = output<Organization>();
  lockUnlockOrganization = output<{ organization: Organization; isLocked: boolean }>();

  // Tree
  transformer = (node: OrganizationNode, treeLevel: number) => ({
    expandable: !!node.children && node.children.length > 0,
    treeLevel,
    ...node
  });
  treeControl = new FlatTreeControl<OrganizationFlatNode>(
    (node) => node.treeLevel,
    (node) => node.expandable
  );
  // eslint-disable-next-line @typescript-eslint/member-ordering
  dataSource = new MatTreeFlatDataSource(
    this.treeControl,
    new MatTreeFlattener(
      this.transformer,
      (node) => node.treeLevel,
      (node) => node.expandable,
      (node) => node.children
    )
  );

  selectedOrganizationId = computed(() => {
    const selectedOrganization = this.selectedOrganization();
    return selectedOrganization ? selectedOrganization.id : null;
  });

  expandedNodes;

  hasChild = (_: number, node: OrganizationFlatNode) => node.expandable;

  organizationsEffect = effect(() => {
    const organizations = this.organizations();
    this.dataSource.data = SortEntitiesUtil.arrayToTree(organizations);

    const selectedOrganizationId = untracked(this.selectedOrganizationId);
    if (selectedOrganizationId) {
      this.restoreExpandedNodes();
      this.expandParents(this.treeControl.dataNodes.find((node) => node.id === selectedOrganizationId));
    }
  });

  selectedOrganizationIdEffect = effect(() => {
    const selectedOrganizationId = this.selectedOrganizationId();
    if (selectedOrganizationId) {
      this.expandParents(this.treeControl.dataNodes.find((node) => node.id === selectedOrganizationId));
    }
  });

  expandParents(node: OrganizationFlatNode) {
    const parent = this.getParent(node);
    this.treeControl.expand(parent);
    this.treeControl.expand(node);

    if (parent && parent.level > 0) {
      this.expandParents(parent);
    }
  }

  getParent(node: OrganizationFlatNode) {
    const currentLevel = this.treeControl.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.treeControl.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
  }

  onSelectOrganization(organization: any) {
    this.selectOrganization.emit(this.organizations().find((el) => el.id === organization.id));
    if (this.selectedOrganizationId() !== organization.id) {
      this.treeControl.expand(organization);
      this.saveExpandedNodes();
    }
  }

  onAddOrganization(organizationId: string) {
    this.addOrganization.emit(this.organizations().find((el) => el.id === organizationId));
  }

  saveExpandedNodes() {
    this.expandedNodes = new Array<OrganizationFlatNode>();
    this.treeControl.dataNodes.forEach((node) => {
      if (node.children.length && this.treeControl.isExpanded(node)) {
        this.expandedNodes.push(node);
      }
    });
  }

  restoreExpandedNodes() {
    if (this.expandedNodes) {
      this.expandedNodes.forEach((node) => {
        this.treeControl.expand(this.treeControl.dataNodes.find((n) => n.id === node.id));
      });
    }
  }

  onLockUnlockOrganization(organization: Organization, isLocked: boolean): void {
    if (this.isTopLevelAdmin()) {
      this.lockUnlockOrganization.emit({
        organization,
        isLocked
      });
    }
  }
}
