import { DeviceStatusName } from '@iot-platform/models/i4b';
import { get } from 'lodash';
import { CommandType } from '../../../i4b/enums/command-type.enum';
import { I4BCellType } from '../configurations';
import { I4bCellConcept } from '../enums';
import { HeaderType } from '../models';
import { I4BButtonColumn } from './i4b-button.column';

export class DaliaDeviceButtonColumn extends I4BButtonColumn {
  constructor() {
    super(
      {
        type: HeaderType.CALL_TO_ACTION,
        displayName: 'BUTTON',
        icon: ''
      },
      {
        concept: I4bCellConcept.DEVICES,
        isConfigurable: false,
        isDefault: true,
        isLink: false,
        position: '',
        sortProperty: '',
        width: 10,
        id: 'button',
        cell: {
          type: I4BCellType.CALL_TO_ACTION,
          singleActions: [
            {
              order: 0,
              key: CommandType.SENDFCFG,
              label: `DEVICES.DETAIL_DEVICE_CARD.${CommandType.SENDFCFG}`,
              enableConditions: [],
              visibleConditions: [[{ key: 'canCreateDevice', value: true }]]
            },
            {
              order: 1,
              key: CommandType.GPS,
              label: `DEVICES.DETAIL_DEVICE_CARD.${CommandType.GPS}`,
              enableConditions: [],
              visibleConditions: [[{ key: 'canCreateDevice', value: true }]]
            },
            {
              order: 2,
              key: CommandType.TRANSPORT,
              label: `DEVICES.DETAIL_DEVICE_CARD.${CommandType.TRANSPORT}`,
              enableConditions: [],
              disableConditions: [[{ key: 'status.name', value: DeviceStatusName.running }]],
              visibleConditions: [[{ key: 'canCreateDevice', value: true }]],
              valueMeta: {
                visibleExpression: (data) => get(data, ['status', 'name']) === DeviceStatusName.running,
                icon: 'info_outline',
                tooltip: 'DEVICES.INFO_PAGE.SEND_TRANSPORT_COMMAND_TOOLTIP'
              }
            },
            {
              order: 3,
              key: CommandType.REFRESH,
              label: `DEVICES.DETAIL_DEVICE_CARD.${CommandType.REFRESH}`,
              enableConditions: [
                ['Orange France', 'Vodafone WW'].map((value) => ({
                  key: 'communication.operator',
                  value
                }))
              ],
              disableConditions: [
                [
                  { key: 'expandedVariables.em.lastValue.value', value: 1 },
                  { key: 'expandedVariables.em.lastValue.value', value: 2 },
                  { key: 'expandedVariables.em.lastValue.value', value: 3 },
                  { key: 'expandedVariables.em.lastValue.value', value: 4 }
                ]
              ],
              visibleConditions: [[{ key: 'canCreateDevice', value: true }]],
              valueMeta: {
                visibleExpression: (data) => get(data, 'expandedVariables.em.lastValue.value') !== 0,
                icon: 'info_outline',
                tooltip: 'DEVICES.INFO_PAGE.REFRESH_COMMAND_DISABLE_TOOLTIP'
              }
            }
          ],
          bulkActions: [],
          visibleConditions: [[{ key: 'canCreateDevice', value: true }]],
          enableConditions: []
        }
      },
      { order: 1000 }
    );
  }
}
