import { Component } from '@angular/core';
import { IconModule } from '@iot-platform/iot-platform-ui';
import { AbstractTableEngineCellComponent } from '../abstract-table-engine-cell.component';
import { NgClass } from '@angular/common';

@Component({
  selector: 'i4b-table-engine-icon-link-cell',
  templateUrl: './icon-link-cell.component.html',
  styleUrls: ['./icon-link-cell.component.scss'],
  imports: [IconModule, NgClass]
})
export class IconLinkCellComponent extends AbstractTableEngineCellComponent<IconLinkCellComponent> {
  onClick(event: MouseEvent): void {
    event.stopPropagation();
    const clickEvent = this.clickEvent();
    const rawData = this.rawData();
    this.dispatchCellEvent({ type: clickEvent.type, options: clickEvent.options, rawData });
  }
}
