import { UpperCasePipe } from '@angular/common';
import { Component, effect, input, output, signal, WritableSignal } from '@angular/core';
import { MatMenuItem } from '@angular/material/menu';
import { ConditionProcessorUtil } from '@iot-platform/iot-platform-utils';

import { MasterViewEngineEvent } from '@iot-platform/models/common';
import { TranslateModule } from '@ngx-translate/core';
import { AbstractTableEngineCellComponent } from '../abstract-table-engine-cell.component';

@Component({
  selector: 'i4b-table-engine-call-to-action-menu-item',
  templateUrl: './call-to-action-menu-item.component.html',
  styleUrls: ['./call-to-action-menu-item.component.scss'],
  imports: [MatMenuItem, UpperCasePipe, TranslateModule]
})
export class CallToActionMenuItemComponent extends AbstractTableEngineCellComponent<CallToActionMenuItemComponent> {
  element = input<any>();
  action = input<any>();

  actionClicked = output<MasterViewEngineEvent>();
  sendIsVisible = output<{ action: any; isVisible: boolean }>();

  isVisible: WritableSignal<boolean> = signal(false);
  isEnabled: WritableSignal<boolean> = signal(false);
  isDisabled: WritableSignal<boolean> = signal(false);

  constructor() {
    super();
    effect(() => {
      const element = this.element();
      const action = this.action();
      const userPermissions = this.userPermissions();
      if (element && action) {
        const isVisible = ConditionProcessorUtil.processConditionsWithPermission(action.visibleConditions, userPermissions);
        this.isVisible.set(isVisible);
        this.isEnabled.set(ConditionProcessorUtil.processConditionsWithElement(action.enableConditions, element, true));
        this.isDisabled.set(ConditionProcessorUtil.processConditionsWithElement(action.disableConditions, element, false));
        this.sendIsVisible.emit({ action, isVisible });
      }
    });
  }

  onSingleActionClick(element: any, actionKey: string): void {
    this.actionClicked.emit({
      type: actionKey,
      options: { elementId: element.id, actionKey, element },
      rawData: element
    });
  }
}
