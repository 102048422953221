import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { DeviceCommentsEffects } from '../effects/device-comments.effects';
import { deviceCommentsFeature } from './device-comments.reducer';
import { devicesFeature } from './devices.reducer';
import { DevicesEffects } from '../effects/devices.effects';
import { deviceCallLogsFeature } from './device-call-logs.reducer';
import { DeviceCallLogsEffects } from '../effects/device-call-logs.effects';

export function provideDevicesState(): EnvironmentProviders {
  return makeEnvironmentProviders([provideState(devicesFeature.name, devicesFeature.reducer), provideEffects([DevicesEffects])]);
}

export function provideDeviceCommentsState(): EnvironmentProviders {
  return makeEnvironmentProviders([provideState(deviceCommentsFeature.name, deviceCommentsFeature.reducer), provideEffects(DeviceCommentsEffects)]);
}

export function provideDeviceCallLogsState(): EnvironmentProviders {
  return makeEnvironmentProviders([provideState(deviceCallLogsFeature.name, deviceCallLogsFeature.reducer), provideEffects(DeviceCallLogsEffects)]);
}
