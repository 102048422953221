<div
  [class]="cssClassName()"
  [style]="styles()"
  class="iot-platform-ui-section-header"
  data-cy="iot-platform-ui-section-header"
  fxLayout="row wrap"
  fxLayoutAlign="start center"
  fxLayoutGap="10px"
>
  <div
    [matTooltip]="title()"
    class="iot-platform-ui-section-header__title-section"
    fxLayoutAlign="start center"
    fxLayoutGap="10px"
    matTooltipClass="regular-tooltip"
    matTooltipPosition="below"
  >
    @if (!!svgIcon()) {
      <mat-icon data-cy="iot-platform-ui-section-header-icon" class="iot-platform-ui-section-header__icon" [svgIcon]="svgIcon()!"></mat-icon>
    } @else if (!!icon()) {
      <mat-icon data-cy="iot-platform-ui-section-header-icon" class="iot-platform-ui-section-header__icon">{{ icon() }}</mat-icon>
    }
    @if (titleActions().length) {
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
        <ng-container [ngTemplateOutlet]="sectionHeaderTitle" [ngTemplateOutletContext]="{ title: title() }" />

        <button class="light-button iot-platform-ui-section-header__action" mat-mini-fab [matMenuTriggerFor]="titleMenu">
          <mat-icon data-cy="iot-platform-ui-section-header-icon" class="iot-platform-ui-section-header__action-icon">arrow_drop_down</mat-icon>
        </button>
      </div>

      <mat-menu #titleMenu="matMenu">
        @for (action of titleActions(); track action) {
          <ng-container [ngTemplateOutlet]="sectionHeaderActionMenu" [ngTemplateOutletContext]="{ action: action }" />
        }
      </mat-menu>
    } @else if (title()) {
      <ng-container [ngTemplateOutlet]="sectionHeaderTitle" [ngTemplateOutletContext]="{ title: title() }" />
    }
  </div>

  @if (showLine()) {
    <hr class="iot-platform-ui-section-header__line regular-1px-line" fxFlex />
  }

  @if (actionsType() === SectionHeaderActionsType.ICONS) {
    <div fxLayout="row wrap" fxLayoutGap="10px">
      @for (action of actions(); track action) {
        @if (!!action?.visible?.()) {
          <ng-container [ngTemplateOutlet]="sectionHeaderActionIcon" [ngTemplateOutletContext]="{ action: action }" />
        }
      }
    </div>
  } @else if (actionsType() === SectionHeaderActionsType.MENU) {
    <button class="light-button iot-platform-ui-section-header__action" mat-mini-fab [matMenuTriggerFor]="actionsMenu">
      <mat-icon data-cy="iot-platform-ui-section-header-icon" class="iot-platform-ui-section-header__action-icon">add</mat-icon>
    </button>

    <mat-menu #actionsMenu="matMenu">
      @for (action of actions(); track action) {
        <ng-container [ngTemplateOutlet]="sectionHeaderActionMenu" [ngTemplateOutletContext]="{ action: action }" />
      }
    </mat-menu>
  }
</div>

<ng-template #sectionHeaderActionIcon let-action="action">
  <button
    (click)="onDispatchEvent(action)"
    [class]="
      action?.cssClassName?.() +
      ' light-button regular-round-button iot-platform-ui-section-header__action iot-platform-ui-section-header__action_' +
      action.action
    "
    [disabled]="!!action?.disabled?.()"
    [matTooltip]="action?.tooltip?.()! | translate"
    [style]="action?.styles?.()"
    data-cy="iot-platform-ui-section-header-action"
    mat-mini-fab
    matTooltipClass="regular-tooltip"
    matTooltipPosition="below"
  >
    @if (!!action?.svgIcon?.()) {
      <mat-icon class="iot-platform-ui-section-header__action-icon" [svgIcon]="action.svgIcon()!"></mat-icon>
    } @else if (!!action?.icon?.()) {
      <mat-icon class="iot-platform-ui-section-header__action-icon">{{ action.icon() }}</mat-icon>
    }
  </button>
</ng-template>

<ng-template #sectionHeaderActionMenu let-action="action">
  <button (click)="onDispatchEvent(action)" mat-menu-item>
    {{ action.text() | translate }}
  </button>
</ng-template>

<ng-template #sectionHeaderTitle let-title="title">
  <span class="iot-platform-ui-section-header__title iot-platform-ui-section-header__title--ellipsis" data-cy="iot-platform-ui-section-header-title">{{
    title
  }}</span>
</ng-template>
