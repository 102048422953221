import { CdkDrag, CdkDragDrop, CdkDragHandle, CdkDropList, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { NgClass, UpperCasePipe } from '@angular/common';
import { Component, computed, DestroyRef, inject, OnInit, Signal, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AbstractControl, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatError, MatHint, MatInput } from '@angular/material/input';
import { MatRadioButton, MatRadioChange, MatRadioGroup } from '@angular/material/radio';

import { MatLabel, MatOption, MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { MatToolbar } from '@angular/material/toolbar';
import { AuthFacade, AuthorizationConcept, AuthorizationService, AuthorizationType } from '@iot-platform/auth';
import { PopupComponent } from '@iot-platform/iot-platform-ui';
import { BusinessProfile, TagCategory, UserAccount } from '@iot-platform/models/common';
import {
  AssetVariableColumn,
  AssetVariableGroupColumn,
  ColumnFactory,
  DaliaFirmwareVersionColumn,
  DefaultAssetEventsGrid,
  DefaultAssetsGrid,
  DefaultDaliaDevicesGrid,
  DefaultDaliaDeviceTemplatesGrid,
  DefaultDaliaFirmwaresGrid,
  DefaultDaliaSensorsGrid,
  DefaultDeviceEventsGrid,
  DefaultDevicesGrid,
  defaultGridsDefinitions,
  DefaultKercomDevicesGrid,
  DefaultSitesGrid,
  DefaultXMQTTDevicesGrid,
  ExpandedVariableColumn,
  getAllColumnsByConcept,
  I4BBasicColumn,
  I4bCellConcept,
  I4BCellType,
  I4BColumn,
  I4BColumnConfiguration,
  I4BColumnHeader,
  I4BColumnOptions,
  I4BGrid,
  I4BGridData,
  I4BGridOptions,
  I4BGridSort,
  I4BTagColumn,
  I4BVariableColumnOptions
} from '@iot-platform/models/grid-engine';
import { TranslateModule } from '@ngx-translate/core';
import { cloneDeep, get, isEqual, orderBy } from 'lodash';
import { combineLatest, Observable } from 'rxjs';
import { DiagnosticVariableCellHelper } from '../../../helpers/diagnostic-variable-cell.helper';
import { GridsService } from '../../../services/grids.service';

@Component({
  imports: [
    FlexLayoutModule,
    TranslateModule,
    MatCardModule,
    MatToolbar,
    MatIcon,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatOption,
    MatRadioGroup,
    MatLabel,
    MatRadioButton,
    CdkDropList,
    CdkDrag,
    UpperCasePipe,
    MatButton,
    MatExpansionModule,
    MatInput,
    MatIconButton,
    MatSlideToggle,
    MatError,
    CdkDragHandle,
    MatHint,
    NgClass
  ],
  selector: 'grid-engine-grid-manager-user-popup',
  templateUrl: './grid-manager-user-popup.component.html',
  styleUrls: ['./grid-manager-user-popup.component.scss']
})
export class GridManagerUserPopupComponent implements OnInit {
  private readonly gridService: GridsService = inject(GridsService);
  private readonly authorizationService: AuthorizationService = inject(AuthorizationService);
  private readonly dialog: MatDialog = inject(MatDialog);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  private readonly authFacade: AuthFacade = inject(AuthFacade);
  columnsForm: UntypedFormGroup;
  initialFormState: string;
  defaultGridsDefinitions = defaultGridsDefinitions;
  delayList = [120, 300, 600, 1800];
  gridPageSizeList = [10, 20, 30, 50, 100, 500, 1000, 3000];
  selectedViewDef: WritableSignal<any> = signal(null);
  isDefaultViewClicked: WritableSignal<boolean> = signal(false);
  selectedLibrary: WritableSignal<{
    concept: I4bCellConcept;
    columns: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[];
    libraryName: string;
  }> = signal({
    concept: null,
    columns: [],
    libraryName: null
  });
  availableColumns: WritableSignal<I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[]> = signal([]);
  selectedColumns: WritableSignal<I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[]> = signal([]);
  gridSort: WritableSignal<I4BGridSort[]> = signal([]);
  clonedGrid: WritableSignal<I4BGrid<I4BGridOptions, I4BGridData>> = signal(null);
  maximumSortPerGrid = 3;
  allGrids: WritableSignal<I4BGrid<I4BGridOptions, I4BGridData>[]> = signal([]);
  I4bCellConcept = I4bCellConcept;
  canUpdateBp: WritableSignal<boolean> = signal(this.authorizationService.applyAuthorization(AuthorizationConcept.BUSINESS_PROFILE, AuthorizationType.UPDATE));
  defaultGrid: WritableSignal<I4BGrid<I4BGridOptions, I4BGridData>> = signal(null);
  isNewGrid: WritableSignal<boolean> = signal(true);
  thresholdList = [1, 2, 3, 4, 5];
  public readonly dialogRef: MatDialogRef<GridManagerUserPopupComponent> = inject(MatDialogRef<GridManagerUserPopupComponent>);
  public data: {
    grid: I4BGrid<I4BGridOptions, I4BGridData>;
    isAdminMode: boolean;
    selectedBusinessProfile?: BusinessProfile;
  } = inject(MAT_DIALOG_DATA);
  currentUser: Signal<UserAccount> = this.authFacade.currentUser;
  currentBusinessProfile: Signal<BusinessProfile> = this.authFacade.selectedBusinessProfile;
  selectedBusinessProfile: WritableSignal<BusinessProfile> = signal(null);
  currentUser$: Observable<UserAccount> = toObservable(this.currentUser);
  currentBusinessProfile$: Observable<BusinessProfile> = toObservable(this.currentBusinessProfile);

  actionButtonLabel: Signal<string> = computed(() => {
    const isNewGrid = this.isNewGrid();
    return !isNewGrid ? 'IOT_DICTIONARY.UPDATE' : 'IOT_DICTIONARY.CREATE';
  });

  get gridToUpdate(): AbstractControl {
    return this.columnsForm.get('gridToUpdate');
  }

  get name(): AbstractControl {
    return this.columnsForm.get('name');
  }

  get viewType(): AbstractControl {
    return this.columnsForm.get('viewType');
  }

  get isShared(): AbstractControl {
    return this.columnsForm.get('isShared');
  }

  get isDefault(): AbstractControl {
    return this.columnsForm.get('isDefault');
  }

  get autoRefreshDelay(): AbstractControl {
    return this.columnsForm.get('autoRefreshDelay');
  }

  get selectedLibraryControl(): AbstractControl {
    return this.columnsForm.get('selectedLibrary');
  }

  static getVariableNamesForRequest(selectedColumns: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[]): string[] {
    const allVariables: string[] = [];
    selectedColumns
      .filter(
        (col) =>
          col.columnId === 'e24f0f50-5ce7-11ec-9577-acde48001122-asset-variable' ||
          col.columnId === '7z348742-6b09-11ec-87d5-acda48401122-expanded-variable' ||
          col.columnId === '19bcb62c-724b-11ec-a844-acde48001122-asset-variable-group' ||
          col.columnId === '9374044f-8451-444e-8a16-6044eec9c112-diagnostic-variable-group'
      )
      .forEach((col) => {
        switch (col.columnId) {
          case '19bcb62c-724b-11ec-a844-acde48001122-asset-variable-group':
            // eslint-disable-next-line no-case-declarations
            const richCellId = col.configuration.children.filter((c) => c?.columnId === '7a788742-6f09-11ec-87d5-acde48001122-rich-variable')[0].configuration
              .id;
            allVariables.push(richCellId.substring(richCellId.indexOf('.') + 1, richCellId.length));
            break;
          case '7z348742-6b09-11ec-87d5-acda48401122-expanded-variable':
          case 'e24f0f50-5ce7-11ec-9577-acde48001122-asset-variable':
            allVariables.push(col.configuration.id.substring(col.configuration.id.indexOf('.') + 1, col.configuration.id.indexOf('.lastValue')));
            break;
          case '9374044f-8451-444e-8a16-6044eec9c112-diagnostic-variable-group':
            allVariables.push(...DiagnosticVariableCellHelper.getVariableNamesByGridColumns([col]));
            break;
          default:
            break;
        }
      });

    return allVariables;
  }

  static cleanUpSorts(sorts: I4BGridSort[]): I4BGridSort[] {
    return sorts.filter((sort) => !!sort.sort && !!sort.colId && typeof sort.sortIndex === 'number');
  }

  ngOnInit(): void {
    this.clonedGrid.set(cloneDeep(this.data?.grid));
    this.isNewGrid.set(this.data.grid.isAppDefault);
    this.initForm(this.data.grid);
    const grids$: Observable<I4BGrid<I4BGridOptions, I4BGridData>[]> = this.gridService.loadGridsByConceptAndBusinessProfile(
      this.data.grid.masterview.toLowerCase(),
      this.data.grid.businessProfileId
    );
    combineLatest([this.currentUser$, this.currentBusinessProfile$, grids$])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([user, businessProfile, grids]) => {
        this.selectedBusinessProfile.set(this.data.isAdminMode && !!this.data.selectedBusinessProfile ? this.data.selectedBusinessProfile : businessProfile);
        this.selectedLibraryControl.enable();
        this.gridToUpdate.patchValue(grids.find((g: I4BGrid<I4BGridOptions, I4BGridData>) => g.id === this.data.grid.id));
        const allGrids = orderBy(
          grids.filter((grid: I4BGrid<I4BGridOptions, I4BGridData>) => !grid.isAppDefault),
          ['name'],
          ['asc']
        );
        this.allGrids.set([...allGrids]);
        const defaultGrid = grids.find((g: I4BGrid<I4BGridOptions, I4BGridData>) => g.userId === user?.id && g.isUserDefault);
        this.defaultGrid.set(defaultGrid);
        if (!defaultGrid && this.data.grid.isAppDefault && !this.data.isAdminMode) {
          this.isDefault.setValue(true);
        }
      });
  }

  initForm(grid: I4BGrid<I4BGridOptions, I4BGridData>): void {
    this.columnsForm = new UntypedFormGroup({
      gridToUpdate: new UntypedFormControl(grid, this.isNewGrid() ? [] : [Validators.required]),
      name: new UntypedFormControl(this.getGridDefaultName(grid), [Validators.required, Validators.maxLength(50)]),
      viewType: new UntypedFormControl(grid.masterview ?? null, [Validators.required]),
      isDefault: new UntypedFormControl(grid.isUserDefault, []),
      autoRefreshDelay: new UntypedFormControl(get(grid, 'gridOptions.autoRefresh.delay') ?? 0, []),
      selectedLibrary: new UntypedFormControl(null, []),
      gridSort: new UntypedFormControl(GridManagerUserPopupComponent.cleanUpSorts(get(grid, 'gridOptions.gridSort', [])), [
        Validators.maxLength(this.maximumSortPerGrid)
      ]),
      pageSize: new UntypedFormControl(get(grid, 'gridOptions.pageSize', 100), []),
      isShared: new UntypedFormControl(grid.businessProfileId ?? null)
    });

    this.viewType.disable();
    this.selectedLibraryControl.disable();

    if (!!grid) {
      this.gridToUpdate.patchValue(this.allGrids().find((g) => g.id === grid.id));
      this.clonedGrid.set(cloneDeep(grid));
      this.initColumnsDragAndDrop(grid);
      this.initThresholdFormControls();
    }

    if (this.data.isAdminMode) {
      this.isShared.setValue(true);
      this.isDefault.setValue(false);
      this.isShared.disable();
      this.isDefault.disable();
    }
  }

  getGridDefaultName(grid: I4BGrid<I4BGridOptions, I4BGridData>): string {
    let defaultName = '';
    if (this.data.isAdminMode) {
      if (grid.isAppDefault) {
        defaultName = `${this.data.grid.masterview
          .toLowerCase()
          .replace(/^\w/, (c) => c.toUpperCase())
          .slice(0, -1)} grid`;
      } else {
        defaultName = grid.name;
      }
    } else {
      defaultName = grid.isAppDefault ? 'My default' : grid.name;
    }

    return defaultName;
  }

  initColumnsDragAndDrop(grid: I4BGrid<I4BGridOptions, I4BGridData>): void {
    const clonedGrid = this.clonedGrid();
    const vDef = this.defaultGridsDefinitions.find((view) => view.viewType === grid?.masterview?.toUpperCase());
    if (vDef) {
      const selectedViewDef = cloneDeep({ ...vDef });
      this.selectedViewDef.set(selectedViewDef);
      this.columnsForm.get('selectedLibrary')?.setValue(selectedViewDef.dataLibraries[0]);
      const selectedColumns = clonedGrid.columns.sort((colA, colB) => (colA.options.order > colB.options.order ? 1 : -1));
      this.selectedColumns.set([...selectedColumns]);
      this.onLibraryChange({ value: selectedViewDef.dataLibraries[0], source: null });
    }
    this.initGridSort(grid.gridOptions.gridSort);
  }

  initThresholdFormControls() {
    const selectedColumns = this.selectedColumns();
    const columnsWithThresholdOptions = selectedColumns.filter(
      (col) => col.configuration.concept === I4bCellConcept.GROUPED_VARIABLES || col.configuration?.concept === I4bCellConcept.FOLLOWED_VARIABLES
    ) as I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BVariableColumnOptions>[];

    columnsWithThresholdOptions.forEach((column) => {
      const formControlName = `${column.columnId}${column.header.displayName}-displayThresholds`;
      const thresholdsToDisplay = this.thresholdList.reduce((acc, value) => {
        if (column.options?.displayThresholds?.[value]) {
          acc.push(value);
        }
        return acc;
      }, []);

      if (!this.columnsForm.contains(formControlName)) {
        this.columnsForm.addControl(formControlName, new UntypedFormControl(thresholdsToDisplay));
      } else {
        this.columnsForm.controls[formControlName].setValue(thresholdsToDisplay);
      }
    });
  }

  initGridSort(initialSort: I4BGridSort[]): void {
    const gridSort = [...initialSort];
    this.gridSort.set(gridSort);
    gridSort.forEach((sort) => {
      this.selectedColumns.update((selectedColumns) => [
        ...selectedColumns.map((col) => {
          if (col.configuration.id === sort.colId) {
            col.options.sort = sort;
          }
          return col;
        })
      ]);
    });
  }

  drop(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
      this.initThresholdFormControls();
    }
  }

  onNewGridChange(event: MatRadioChange): void {
    this.isNewGrid.set(event.value);
    if (this.isNewGrid()) {
      this.addGrid();
      if (!this.defaultGrid()) {
        this.isDefault.setValue(true);
      }
    } else {
      this.initForm(this.data.grid);
      if (!this.gridToUpdate?.value) {
        this.isDefault.setValue(false);
      } else if (!this.gridToUpdate?.value?.businessProfileId && !this.defaultGrid()) {
        this.isDefault.setValue(true);
      }
    }
    this.selectedLibraryControl.enable();
  }

  addGrid() {
    let newGrid: I4BGrid<I4BGridOptions, I4BGridData>;
    switch (this.data.grid.masterview.toLowerCase()) {
      case 'sites':
        newGrid = new DefaultSitesGrid();
        break;
      case 'assets':
        newGrid = new DefaultAssetsGrid();
        break;
      case 'devices':
        newGrid = new DefaultDevicesGrid();
        break;
      case 'dalia-devices':
        newGrid = new DefaultDaliaDevicesGrid();
        break;
      case 'xmqtt-devices':
        newGrid = new DefaultXMQTTDevicesGrid();
        break;
      case 'kercom-devices':
        newGrid = new DefaultKercomDevicesGrid();
        break;
      case 'dalia-firmwares':
        newGrid = new DefaultDaliaFirmwaresGrid();
        break;
      case 'dalia-device-templates':
        newGrid = new DefaultDaliaDeviceTemplatesGrid();
        break;
      case 'dalia-sensors':
        newGrid = new DefaultDaliaSensorsGrid();
        break;
      case 'device-events':
        newGrid = new DefaultDeviceEventsGrid();
        break;
      case 'asset-events':
        newGrid = new DefaultAssetEventsGrid();
        break;
    }
    this.initForm(newGrid);
  }

  onSelectGridToUpdate(event: MatSelectChange): void {
    this.initForm(event.value);
    this.selectedLibraryControl.enable();
  }

  onLibraryChange(event: MatSelectChange) {
    const selectedLibrary = event.value;
    const selectedViewDef = this.selectedViewDef();
    const selectedBusinessProfile = this.selectedBusinessProfile();

    this.availableColumns.set([]);
    this.selectedLibrary.set(selectedLibrary);

    if (event.value.libraryName !== 'TAGS') {
      this.setAvailableColumns(selectedLibrary.columns);
    } else {
      const concepts: string[] = this.getConceptsFromViewType(selectedViewDef.viewType);
      this.gridService
        .loadTagsByConceptsAndEntity(concepts, selectedBusinessProfile.entityId)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((tagCategories: TagCategory[]) => {
          const availableColumns = [
            ...tagCategories.map(
              (tagCategory: TagCategory) =>
                new I4BTagColumn(
                  { displayName: tagCategory.name },
                  {
                    id: tagCategory.id,
                    concept: I4bCellConcept[tagCategory.concept + '_TAGS'],
                    cell: {
                      type: I4BCellType.TAG_CELL,
                      valueGetter: this.getValueGetter(selectedViewDef.viewType, tagCategory.id)
                    }
                  }
                )
            )
          ];
          this.setAvailableColumns(availableColumns);
        });
    }
  }

  getConceptsFromViewType(masterView: string): string[] {
    if (masterView.toLowerCase() === 'asset-events') {
      return ['SITE', 'ASSET', 'EVENT'];
    } else if (masterView.toLowerCase() === 'device-events') {
      return ['SITE', 'DEVICE', 'EVENT'];
    } else {
      return [masterView.slice(0, -1).toUpperCase()];
    }
  }

  getValueGetter(masterView: string, tagCategoryId: string): string {
    return (masterView.toLowerCase().includes('event') ? 'tags.' : 'expandedTagCategories.') + tagCategoryId + '.labels.0.name';
  }

  setAvailableColumns(columns: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[]): void {
    const selectedColumns = this.selectedColumns();
    const availableColumns = [...columns].filter(
      (available) => !selectedColumns.find((selected) => selected.columnId === available.columnId && selected.configuration.id === available.configuration.id)
    );
    this.availableColumns.set(availableColumns);
  }

  setDefaultView() {
    const selectedViewDef = this.selectedViewDef();
    const selectedLibrary = this.selectedLibrary();
    this.gridService
      .getAppDefaultGridByConcept(selectedViewDef.viewType.toLowerCase())
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((defaultGrid) => {
        this.clonedGrid.set(cloneDeep(defaultGrid));
        this.selectedColumns.set(defaultGrid.columns);
        this.setAvailableColumns(selectedLibrary?.columns ?? []);
        this.columnsForm.get('pageSize').setValue(defaultGrid.gridOptions.pageSize);
        const gridSort = [...defaultGrid.gridOptions.gridSort];
        this.gridSort.set(gridSort);
        this.initGridSort(gridSort);
        this.initThresholdFormControls();
      });
  }

  applyNewHeader(column: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>, value: string) {
    column.options.overrideHeaderName = value;
  }

  resetHeaderName(column: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>) {
    const selectedViewDef = this.selectedViewDef();
    const defaultColumn: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions> = getAllColumnsByConcept(selectedViewDef.viewType).find(
      (col) => col.columnId === column.columnId && col.configuration.id === column.configuration.id
    );
    const columnToUpdate = this.selectedColumns().find((col) => col.configuration.id === column.configuration.id);
    columnToUpdate.header.displayName = defaultColumn?.header?.displayName;
    columnToUpdate.options.overrideHeaderName = null;
    this.selectedColumns.update((selectedColumns) => {
      const index = selectedColumns.findIndex((col) => col.configuration.id === column.configuration.id);
      const arr = [...selectedColumns];
      arr.splice(index, 1, columnToUpdate);
      return [...arr];
    });
  }

  getHeaderValue(column: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>) {
    return column.options.overrideHeaderName ?? column.header.displayName;
  }

  applyNewProperty(column: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>, value: string) {
    column.configuration.id = value;
  }

  applyNewVariableName(column: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>, value: string) {
    column.options.overrideHeaderName = value.toUpperCase();
    column.configuration.id = `expandedVariables.${value}.lastValue.value`;
  }

  addBasicColumn() {
    this.selectedColumns.update((selectedColumns) => [...selectedColumns, new I4BBasicColumn({}, {}, {})]);
  }

  addVariableColumn() {
    this.selectedColumns.update((selectedColumns) => [...selectedColumns, new AssetVariableColumn({}, {}, {})]);
  }

  disableSelectedColumnsForSort(sortIndex: number): boolean {
    return !!this.gridSort().find((s) => s.sortIndex === sortIndex);
  }

  resetGridSort(column: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>): void {
    column.options.sort = undefined;
    const colIndex = this.gridSort().findIndex((s) => s.colId === column.configuration.id);
    if (colIndex !== -1) {
      this.gridSort.update((gridSort) => {
        const arr = [...gridSort];
        arr.splice(colIndex, 1);
        return [...arr];
      });
    }
  }

  applySortPreferences(column: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>, sortIndex: number, sort: string) {
    column.options.sort = {
      ...column.options.sort,
      colId: column.configuration.id,
      sortIndex,
      sort
    };
    const colIndex = this.gridSort().findIndex((s) => s.colId === column.configuration.id);
    if (colIndex !== -1) {
      this.gridSort.update((gridSort) => {
        const arr = [...gridSort];
        arr.splice(colIndex, 1, column.options.sort);
        return [...arr];
      });
    } else {
      this.gridSort.update((gridSort) => [...gridSort, column.options.sort]);
    }
  }

  openConfirmPopup() {
    const dialogConfirm = this.dialog.open(PopupComponent, {
      width: '500px',
      disableClose: true,
      data: { type: 'delete', value: this.name.value }
    });

    dialogConfirm
      .afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((confirmation: boolean) => {
        if (confirmation) {
          this.dialogRef.close({ action: 'DELETE', grid: this.gridToUpdate.value });
        }
      });
  }

  notEnoughRights(): boolean {
    return !!this.gridToUpdate?.value?.businessProfileId && !this.canUpdateBp();
  }

  isSubmissionDisabled(): boolean {
    return (
      this.notEnoughRights() ||
      !this.columnsForm.valid ||
      this.initialFormState === JSON.stringify(this.columnsForm.value) ||
      this.selectedColumns().length === 0
    );
  }

  isDeleteDisabled() {
    return this.notEnoughRights() || this.isNewGrid() || !this.gridToUpdate?.value || this.gridToUpdate?.value?.isDefault;
  }

  save() {
    const selectedColumns = this.selectedColumns();
    const gridSort = this.gridSort();
    const selectedBusinessProfile = this.selectedBusinessProfile();
    const currentUser = this.currentUser();
    const isNewGrid = this.isNewGrid();
    const selectedViewDef = this.selectedViewDef();
    const clonedGrid = this.clonedGrid();

    const variableNames = GridManagerUserPopupComponent.getVariableNamesForRequest(selectedColumns);

    const toSave: Partial<I4BGrid<I4BGridOptions, I4BGridData>> = {
      ...clonedGrid,
      id: !isNewGrid ? this.gridToUpdate.value.id : null,
      name: this.name.value.trim(),
      masterview: selectedViewDef.viewType.toLowerCase(),
      columns: selectedColumns.map((selected, idx) => {
        const overrideConfig =
          ColumnFactory.getUserColumnClass(selected.columnId).class === I4BBasicColumn ||
          ColumnFactory.getUserColumnClass(selected.columnId).class === AssetVariableColumn ||
          ColumnFactory.getUserColumnClass(selected.columnId).class === ExpandedVariableColumn ||
          ColumnFactory.getUserColumnClass(selected.columnId).class === AssetVariableGroupColumn ||
          ColumnFactory.getUserColumnClass(selected.columnId).class === I4BTagColumn ||
          ColumnFactory.getUserColumnClass(selected.columnId).class === DaliaFirmwareVersionColumn;
        const col: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions> = {
          columnId: selected.columnId,
          className: selected.className,
          options: { ...selected.options, order: idx, sort: selected.options.sort },
          dataLibs: selected.dataLibs,
          configuration: overrideConfig ? selected.configuration : null,
          header: overrideConfig ? selected.header : null
        };
        return col;
      }),
      isDefault: this.isDefault.value,
      isUserDefault: this.isDefault.value,
      gridOptions: {
        ...clonedGrid?.gridOptions,
        renderer: 'grid',
        autoRefresh: { enabled: this.autoRefreshDelay.value !== 0, delay: this.autoRefreshDelay.value },
        multipleSelection: false,
        pageSize: this.columnsForm.get('pageSize').value,
        filters: [],
        variableNames: [...variableNames],
        tagIds: [
          ...selectedColumns.filter((c: I4BBasicColumn) => c.configuration.cell.type === I4BCellType.TAG_CELL).map((c: I4BBasicColumn) => c.configuration.id)
        ],
        gridSort: [...gridSort]
      },
      businessProfileId: this.isShared.value ? selectedBusinessProfile.id : null,
      userId: currentUser.id
    };
    const payload = this.data.isAdminMode ? toSave : { action: isNewGrid ? 'ADD' : 'UPDATE', grid: toSave };
    this.dialogRef.close(payload);
  }

  close() {
    this.dialogRef.close();
  }

  onRemoveSelectedColumn(event, column: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>): void {
    event?.preventDefault?.();
    event?.stopPropagation?.();
    // Reset thresholdForm
    if (this.columnsForm.contains(`${column.columnId}${column.header.displayName}-displayThresholds`)) {
      this.columnsForm.controls[`${column.columnId}${column.header.displayName}-displayThresholds`].reset();
    }
    // Remove current column from selected list
    this.selectedColumns.update((selectedColumns) => {
      const index = selectedColumns.findIndex((c) => isEqual(c, column));
      const arr = [...selectedColumns];
      arr.splice(index, 1);
      return [...arr];
    });
    // Add current column in available list if it has the same concept
    // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
    if (get(column, 'configuration.concept') === get(this.selectedLibrary(), 'libraryName')) {
      this.availableColumns.update((availableColumns) => {
        availableColumns.unshift(column);
        return [...availableColumns];
      });
    }

    this.gridSort.update((gridSortList) => [
      ...gridSortList
        .filter((sort) => column.configuration.id !== sort.colId)
        .sort((sortA: I4BGridSort, sortB: I4BGridSort) => (sortA.sortIndex < sortB.sortIndex ? -1 : 1))
        .map((gridSort, index) => ({
          ...gridSort,
          sortIndex: index
        }))
    ]);
  }

  onAddSelectedColumn(event, column: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>): void {
    event?.preventDefault?.();
    event?.stopPropagation?.();
    this.availableColumns.update((selectedColumns) => {
      const index = selectedColumns.findIndex((c) => isEqual(c, column));
      const arr = [...selectedColumns];
      arr.splice(index, 1);
      return [...arr];
    });
    this.selectedColumns.update((availableColumns) => {
      availableColumns.unshift(column);
      return [...availableColumns];
    });
    this.initThresholdFormControls();
  }

  getSortValue(sort: I4BGridSort) {
    if (sort) {
      return this.gridSort().find((found) => found.colId === sort.colId)?.sortIndex ?? null;
    } else {
      return null;
    }
  }
}
