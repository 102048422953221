import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { CustomEncoder, ENVIRONMENT } from '@iot-platform/core';
import { BusinessProfile, CommonApiListResponse, CommonGenericModel, Environment, FavoriteView } from '@iot-platform/models/common';
import { I4BGrid, I4BGridData, I4BGridOptions } from '@iot-platform/models/grid-engine';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiHelpers } from '@iot-platform/iot-platform-utils';

@Injectable({
  providedIn: 'root'
})
export class FavoriteViewsService {
  protected readonly environment: Environment = inject(ENVIRONMENT);
  protected readonly httpClient: HttpClient = inject(HttpClient);

  getFavoriteViews(): Observable<FavoriteView[]> {
    return this.httpClient
      .get<CommonApiListResponse<FavoriteView>>(`${this.environment.api.url_v2}${this.environment.api.endpoints?.favoriteViews}`)
      .pipe(map((data: CommonApiListResponse<FavoriteView>) => data.content));
  }

  isNameUnique(name: string, businessProfileId: string, masterview: string, shared: boolean): Observable<boolean> {
    let params: HttpParams = new HttpParams();
    params = params.set('businessProfileId', businessProfileId);
    params = params.set('shared', shared);
    params = params.set('masterview', masterview);
    return this.httpClient
      .get<CommonApiListResponse<FavoriteView>>(`${this.environment.api.url_v2}${this.environment.api.endpoints?.favoriteViews}`, { params })
      .pipe(
        map((data: CommonApiListResponse<FavoriteView>) => data.content.filter((favoriteView) => !!favoriteView.name)),
        map((favoriteViews: FavoriteView[]) => favoriteViews.map((favoriteView) => favoriteView.name?.toLowerCase() as string)),
        map((names: string[]) => !names.includes(name.toLowerCase()))
      );
  }

  saveFavoriteView(favoriteView: FavoriteView): Observable<FavoriteView> {
    return this.httpClient.post<FavoriteView>(`${this.environment.api.url_v2}${this.environment.api.endpoints?.favoriteViews}`, favoriteView);
  }

  updateFavoriteView(favoriteView: FavoriteView): Observable<FavoriteView> {
    return this.httpClient.patch<FavoriteView>(`${this.environment.api.url_v2}${this.environment.api.endpoints?.favoriteViews}/${favoriteView.id}`, {
      name: favoriteView.name,
      pinned: favoriteView.pinned,
      shared: favoriteView.shared,
      color: favoriteView.color,
      filters: favoriteView.filters,
      description: favoriteView.description,
      businessProfileId: favoriteView.businessProfileId,
      gridId: favoriteView.gridId
    });
  }

  deleteFavoriteView(favoriteView: FavoriteView): Observable<FavoriteView> {
    return this.httpClient
      .delete<void>(`${this.environment.api.url_v2}${this.environment.api.endpoints?.favoriteViews}/${favoriteView.id}`)
      .pipe(map((_) => favoriteView));
  }

  getFavoriteViewCount(favoriteView: FavoriteView): Observable<FavoriteView> {
    const params: HttpParams = ApiHelpers.getHttpParams({ page: 0, limit: 0, filters: favoriteView.filters });

    return this.httpClient
      .get<CommonApiListResponse<CommonGenericModel>>(`${this.environment.api.url}/${favoriteView.masterView}`, { params })
      .pipe(map((response: CommonApiListResponse<CommonGenericModel>) => ({ ...favoriteView, count: response?.page?.total ?? 0 })));
  }

  getBusinessProfiles(): Observable<BusinessProfile[]> {
    return this.httpClient
      .get<{
        page: any;
        content: BusinessProfile[];
      }>(`${this.environment.api.url}${this.environment.api.endpoints?.businessProfiles}`)
      .pipe(map((response) => response.content));
  }

  getGridsByMasterViewAndBusinessProfileId(masterView: string, businessProfileId: string): Observable<I4BGrid<I4BGridOptions, I4BGridData>[]> {
    let params: HttpParams = new HttpParams({ encoder: new CustomEncoder() });
    params = params.set('businessProfileId', businessProfileId);

    return this.httpClient
      .get<{
        page: any;
        content: I4BGrid<I4BGridOptions, I4BGridData>[];
      }>(`${this.environment.api.url}${this.environment.api.endpoints?.grids}/${masterView}`, {
        params
      })
      .pipe(map((response) => response.content));
  }

  getPersonalGridByUserId(masterView: string, userId: string): Observable<I4BGrid<I4BGridOptions, I4BGridData>[]> {
    let params: HttpParams = new HttpParams({ encoder: new CustomEncoder() });
    params = params.set('userId', userId);

    return this.httpClient
      .get<{
        page: any;
        content: I4BGrid<I4BGridOptions, I4BGridData>[];
      }>(`${this.environment.api.url}${this.environment.api.endpoints?.grids}/${masterView}`, {
        params
      })
      .pipe(map((response) => response.content));
  }

  getGrids(masterView: string, businessProfileId: string, userId: string): Observable<I4BGrid<I4BGridOptions, I4BGridData>[]> {
    return forkJoin([this.getGridsByMasterViewAndBusinessProfileId(masterView, businessProfileId), this.getPersonalGridByUserId(masterView, userId)]).pipe(
      map(([sharedGrids, userGrids]) => sharedGrids.concat(userGrids))
    );
  }

  duplicateAndShareGrid(
    gridToDuplicate: I4BGrid<I4BGridOptions, I4BGridData>,
    favoriteView: FavoriteView
  ): Observable<{ grid: I4BGrid<I4BGridOptions, I4BGridData>; favoriteView: FavoriteView }> {
    gridToDuplicate = { ...gridToDuplicate, businessProfileId: favoriteView.businessProfileId, id: undefined };
    return this.httpClient
      .post<I4BGrid<I4BGridOptions, I4BGridData>>(`${this.environment.api.url}${this.environment.api.endpoints?.grids}/${favoriteView.masterView}`, {
        ...gridToDuplicate,
        data: null
      })
      .pipe(map((grid) => ({ grid, favoriteView })));
  }

  shareGrid(
    grid: I4BGrid<I4BGridOptions, I4BGridData>,
    favoriteView: FavoriteView
  ): Observable<{ grid: I4BGrid<I4BGridOptions, I4BGridData>; favoriteView: FavoriteView }> {
    grid = { ...grid, businessProfileId: favoriteView.businessProfileId };
    return this.httpClient
      .put<I4BGrid<I4BGridOptions, I4BGridData>>(`${this.environment.api.url}/grids/${favoriteView.masterView}/${grid.id}`, {
        ...grid,
        data: null
      })
      .pipe(map((result) => ({ grid: result, favoriteView })));
  }
}
