import { Injectable } from '@angular/core';
import { ApiFeatureSelector, Filter, Pagination } from '@iot-platform/models/common';
import { Event } from '@iot-platform/models/i4b';
import * as fromAssetEvents from '../reducers/asset-events.reducer';

@Injectable({
  providedIn: 'root'
})
export class AssetEventsSelectors extends ApiFeatureSelector<fromAssetEvents.State, Event, Pagination, Filter> {
  constructor() {
    super(fromAssetEvents.assetEventsFeature.selectAssetEventsState, fromAssetEvents.adapter);
  }
}
