<mat-card class="dialog-card">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar class="dialog-toolbar" color="accent" fxLayout="row" fxLayoutAlign="space-between center">
      <h1 class="dialog-card-title">{{ data.assetGroup ? ('ASSET_GROUPS.EDIT_ASSET_GROUP' | translate) : ('ASSET_GROUPS.CREATE_ASSET_GROUP' | translate) }}</h1>
      <button (click)="close()" class="regular-round-button" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar>
  </mat-card-title>

  <mat-card-content class="dialog-card-content">
    <div class="p-20" fxLayout="column" fxLayoutGap="space-between">
      <iot-platform-ui-form-group [fields]="fields()" [group]="form" />
      <p class="dialog-form-tips">{{ 'IOT_DICTIONARY.MANDATORY_FIELDS' | translate }}</p>
    </div>
  </mat-card-content>

  <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
    <button (click)="close()" class="button-regular" mat-button>{{ 'IOT_DICTIONARY.CANCEL' | translate }}</button>
    <button (click)="save()" [disabled]="!canSubmit" class="button-regular" color="accent" mat-raised-button>
      {{ data.assetGroup ? ('IOT_DICTIONARY.UPDATE' | translate) : ('IOT_DICTIONARY.CREATE' | translate) }}
    </button>
  </mat-card-actions>
</mat-card>
