import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { FollowedVariableCard, FollowedVariableCardType } from '@iot-platform/models/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    imports: [FlexLayoutModule, MatIconModule, TranslateModule],
    selector: 'iot4bos-ui-asset-followed-variable-card',
    templateUrl: './followed-variable-card.component.html',
    styleUrls: ['./followed-variable-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FollowedVariableCardComponent {
  FollowedVariableCardType = FollowedVariableCardType;

  @Input() canUpdate = false;
  @Input() followedVariableCard!: FollowedVariableCard;

  @Output() addFollowedVariable: EventEmitter<void> = new EventEmitter<void>();
  @Output() removeFollowedVariable: EventEmitter<void> = new EventEmitter<void>();
}
