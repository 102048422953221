import { Component, computed, inject, input, output, Signal } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDialog } from '@angular/material/dialog';
import { LogsPopupComponent } from '@iot-platform/audit-trail';
import { AnalyticsService } from '@iot-platform/core';
import {
  ChipComponent,
  DeviceInfoStatusModule,
  DeviceLatestCommandStatusComponent,
  EventSeverityDisplayModule,
  ManageTagsDialogComponent,
  OverviewCardComponent,
  PopupComponent
} from '@iot-platform/iot-platform-ui';
import { DeviceIccidComponent } from '@iot-platform/iot4bos/ui/device';
import { OverviewCard, OverviewCardActions, TagCategory } from '@iot-platform/models/common';
import { CommandType, Device, DeviceStatusName, Site } from '@iot-platform/models/i4b';
import { DateFormatPipe } from '@iot-platform/pipes';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';
import { EndMaintenanceDialogComponent } from '../../../../components/maintenance-dialogs/end-maintenance-dialog/end-maintenance-dialog.component';
import { StartMaintenanceDialogComponent } from '../../../../components/maintenance-dialogs/start-maintenance-dialog/start-maintenance-dialog.component';
import { NavigationApi } from '../../../../containers/+state/navigation.api';
import { DevicesFacade } from '../../state/facades/devices.facade';
import { DeviceInfoFormComponent } from '../device-info-form/device-info-form.component';
import { DeviceMoveToFormComponent } from '../device-move-to-form/device-move-to-form.component';

@Component({
  selector: 'iot4bos-ui-device-info',
  imports: [OverviewCardComponent, DeviceLatestCommandStatusComponent, DeviceInfoStatusModule, EventSeverityDisplayModule, FlexLayoutModule],
  templateUrl: './device-info.component.html',
  styleUrls: ['./device-info.component.scss']
})
export class DeviceInfoComponent {
  public readonly dialog: MatDialog = inject(MatDialog);
  private readonly devicesFacade: DevicesFacade = inject(DevicesFacade);
  private readonly navigationApi: NavigationApi = inject(NavigationApi);
  private readonly translateService: TranslateService = inject(TranslateService);
  private readonly dateFormatPipe: DateFormatPipe = inject(DateFormatPipe);

  analytic: AnalyticsService = new AnalyticsService('device_info_page');

  site = input<Site>();
  canRead = input<boolean>(false);
  canCreate = input<boolean>(false);
  canUpdate = input<boolean>(false);
  canDelete = input<boolean>(false);
  canReadAuditTrail = input<boolean>(false);
  configureDeviceConnector = output<Device>();

  device: Signal<Device> = this.navigationApi.selectedDevice;
  deviceTags: Signal<TagCategory[]> = this.navigationApi.deviceTags;
  deviceTagsLoaded: Signal<boolean> = this.navigationApi.deviceTagsLoaded;
  width = '50%';

  overviewCard: Signal<OverviewCard> = computed(() => {
    const device = this.device();
    const tags = this.deviceTags();
    return this.getCardMetadata(device, tags);
  });

  statusTooltip: Signal<string | null> = computed(() => {
    const device = this.device();
    const updatedAt = device?.status?.datetime ? this.dateFormatPipe.transform(device.status?.datetime) : null;
    return updatedAt ? this.translateService.instant('IOT_DICTIONARY.UPDATED_AT') + ' : ' + updatedAt : null;
  });

  endpointUrl: Signal<string> = computed(() => {
    const device = this.device();
    const endpoint = device?.communication?.endpoint;
    const httpport = device?.communication?.httpport;
    return !!httpport && httpport !== 80 && httpport !== 443 ? `http://${endpoint}:${httpport}` : `http://${endpoint}`;
  });

  isCommandDisabled: Signal<boolean> = computed(() => {
    const device = this.device();
    return device.outgoingConnector?.requestConfiguration?.authentication === 'login' && (!device.credential?.login || !device.credential?.password);
  });

  onCardActions(actionType: OverviewCardActions): void {
    switch (actionType) {
      case OverviewCardActions.ACTIVATE:
        this.onActivate();
        break;
      case OverviewCardActions.MOVE_TO:
        this.onMoveDevice();
        break;
      case OverviewCardActions.RESET:
        this.onResetDevice();
        break;
      case OverviewCardActions.DECOMMISSION:
        this.onDecommissionDevice();
        break;
      case OverviewCardActions.DELETE:
        this.onDeleteDevice();
        break;
      case OverviewCardActions.CONFIGURE:
        this.onConfigureDevice();
        break;
      case OverviewCardActions.START_MAINTENANCE:
      case OverviewCardActions.EDIT_MAINTENANCE:
        this.onStartOrEditMaintenance();
        break;
      case OverviewCardActions.END_MAINTENANCE:
        this.onEndMaintenance();
        break;
      case OverviewCardActions.SELFCONF_COMMAND:
        this.onDeviceCommand(CommandType.SELFCONF);
        break;
      case OverviewCardActions.REFRESH_COMMAND:
        this.onDeviceCommand(CommandType.REFRESH);
        break;
      case OverviewCardActions.UNBLOCK_COMMAND:
        this.onDeviceCommand(CommandType.UNBLOCK);
        break;
      case OverviewCardActions.GET_ALARM_COMMAND:
        this.onDeviceCommand(CommandType.GET_ALARM);
        break;
      case OverviewCardActions.DISPLAY_HISTORY:
        this.onOpenAuditTrail();
        break;
      default:
        break;
    }
  }

  onEditDevice(): void {
    this.analytic.log('tab_device_info_actions', 'open_edit_device');
    this.dialog
      .open(DeviceInfoFormComponent, {
        width: '990px',
        data: { device: this.device() },
        disableClose: true
      })
      .afterClosed()
      .subscribe((device: Device) => {
        if (device) {
          this.analytic.log('tab_device_info_actions', 'update_device');
          this.devicesFacade.updateDevice(device);
        }
      });
  }

  onManageTags(): void {
    this.analytic.log('tab_device_info_actions', 'open_manage_tags');
    this.dialog
      .open(ManageTagsDialogComponent, {
        width: '1230px',
        disableClose: true,
        data: {
          concepts: ['device'],
          selectedTags: this.deviceTags(),
          objectName: this.device()?.name,
          currentEntityId: this.device()?.entity?.id,
          multiSelection: false,
          editable: false,
          withChildren: false,
          joinable: true,
          withParents: true,
          enforceMandatoryCategories: true
        }
      })
      .afterClosed()
      .subscribe((tags: TagCategory[]) => {
        if (tags) {
          this.analytic.log('tab_device_info_actions', 'manage_tags', `${tags.length} tags`);
          this.devicesFacade.updateDeviceTags(this.device()?.id as string, tags);
        }
      });
  }

  onActivate(): void {
    this.analytic.log('tab_device_info_actions', 'open_activate_device');
    this.dialog
      .open(PopupComponent, {
        width: '500px',
        data: { type: 'confirm', value: this.translateService.instant('DEVICES.CARD.ACTIVATE_MESSAGE') },
        disableClose: true
      })
      .afterClosed()
      .pipe(filter((confirmation) => confirmation))
      .subscribe((_) => {
        this.analytic.log('tab_device_info_actions', 'activate_device');
        this.devicesFacade.activateDevice(this.device());
      });
  }

  onMoveDevice(): void {
    this.analytic.log('tab_device_info_actions', 'open_move_device');
    this.dialog
      .open(DeviceMoveToFormComponent, {
        width: '800px',
        data: { devices: [this.device()] },
        disableClose: true
      })
      .afterClosed()
      .subscribe((updatedDevices: Device[]) => {
        if (updatedDevices) {
          this.analytic.log('tab_device_info_actions', 'move_device');
          this.devicesFacade.moveDevices(updatedDevices, this.site());
        }
      });
  }

  onResetDevice(): void {
    this.analytic.log('tab_device_info_actions', 'open_reset_device');
    this.dialog
      .open(PopupComponent, {
        width: '500px',
        data: { type: 'reset', value: this.device()?.name },
        disableClose: true
      })
      .afterClosed()
      .subscribe((validation) => {
        if (validation) {
          this.analytic.log('tab_device_info_actions', 'reset_device');
          this.devicesFacade.resetDevice(this.device());
        }
      });
  }

  onDecommissionDevice(): void {
    this.analytic.log('tab_device_info_actions', 'open_decommission_device');
    this.dialog
      .open(PopupComponent, {
        width: '500px',
        data: { type: 'confirm', value: 'DEVICES.CARD.DECOMMISSION_CONFIRMATION_MESSAGE' },
        disableClose: true
      })
      .afterClosed()
      .subscribe((validation) => {
        if (validation) {
          this.analytic.log('tab_device_info_actions', 'decommission_device');
          this.devicesFacade.updateDevice({
            ...this.device(),
            status: { ...this.device()?.status, name: DeviceStatusName.decommissioned }
          });
        }
      });
  }

  onDeleteDevice(): void {
    this.analytic.log('tab_device_info_actions', 'open_delete_device');
    this.dialog
      .open(PopupComponent, {
        width: '500px',
        data: { type: 'delete', value: this.device()?.name },
        disableClose: true
      })
      .afterClosed()
      .subscribe((validation) => {
        if (validation) {
          this.analytic.log('tab_device_info_actions', 'delete_device');
          this.devicesFacade.deleteDevice(this.device());
        }
      });
  }

  onConfigureDevice(): void {
    this.configureDeviceConnector.emit(this.device());
  }

  onStartOrEditMaintenance(): void {
    this.analytic.log('tab_device_info_actions', 'open_schedule_maintenance');
    this.dialog
      .open(StartMaintenanceDialogComponent, {
        width: '600px',
        disableClose: true,
        data: { elementGoingIntoMaintenance: this.device(), type: 'DEVICE' }
      })
      .afterClosed()
      .pipe(filter((deviceToUpdate: Device) => !!deviceToUpdate))
      .subscribe((deviceToUpdate: Device) => {
        this.analytic.log('tab_device_info_actions', 'schedule_maintenance');
        this.devicesFacade.updateDevice({
          ...deviceToUpdate,
          status: { ...deviceToUpdate.status, name: DeviceStatusName.maintenance }
        } as Device);
      });
  }

  onEndMaintenance(): void {
    this.analytic.log('tab_device_info_actions', 'open_end_maintenance');
    this.dialog
      .open(EndMaintenanceDialogComponent, {
        width: '600px',
        disableClose: true,
        data: { elementEndingMaintenance: this.device(), type: 'DEVICE' }
      })
      .afterClosed()
      .pipe(filter((deviceToUpdate: Device) => !!deviceToUpdate))
      .subscribe((deviceToUpdate: Device) => {
        this.analytic.log('tab_device_info_actions', 'end_maintenance');
        this.devicesFacade.updateDevice(deviceToUpdate);
      });
  }

  onDeviceCommand(command: CommandType): void {
    this.analytic.log('tab_device_info_actions', 'send_command', `${command}`);
    this.devicesFacade.sendCommand(this.device(), { command });
  }

  onIccidClick(): void {
    this.analytic.log('tab_device_info_actions', 'open_device_iccid');
    this.dialog.open(DeviceIccidComponent, {
      data: { deviceId: this.device()?.id },
      disableClose: false,
      width: '900px'
    });
  }

  onEndpointClick(): void {
    window.open(this.endpointUrl(), '_blank');
  }

  onOpenAuditTrail(): void {
    this.analytic.log('tab_device_info_actions', 'open_history');
    this.dialog.open(LogsPopupComponent, {
      data: { concept: 'device', elementId: this.device()?.id, elementName: this.device()?.name },
      disableClose: true,
      width: '900px'
    });
  }

  private getCardMetadata(device: Device, tags: TagCategory[]): OverviewCard {
    return {
      name: device.name as string,
      image: device.imageUrl ?? 'assets/images/device/Device_Default.png',
      justifyContent: true,
      actions: [
        {
          label: 'DEVICES.CARD.ACTIVATE',
          type: OverviewCardActions.ACTIVATE,
          visible: this.canCreate() && this.device()?.status?.name === DeviceStatusName.test_mode && !!this.device()?.affiliate
        },
        {
          label: 'DEVICES.CARD.MOVE_TO',
          type: OverviewCardActions.MOVE_TO,
          visible: this.canUpdate() && this.device()?.status?.name !== DeviceStatusName.test_mode
        },
        {
          label: 'DEVICES.CARD.RESET',
          type: OverviewCardActions.RESET,
          visible:
            this.canUpdate() && this.device()?.status?.name !== DeviceStatusName.decommissioned && this.device()?.status?.name !== DeviceStatusName.test_mode
        },
        {
          label: 'DEVICES.CARD.DECOMMISSION',
          type: OverviewCardActions.DECOMMISSION,
          visible:
            this.canDelete() && this.device()?.status?.name !== DeviceStatusName.decommissioned && this.device()?.status?.name !== DeviceStatusName.test_mode
        },
        {
          label: 'DEVICES.CARD.DELETE',
          type: OverviewCardActions.DELETE,
          disabled: this.device()?.status?.name !== DeviceStatusName.decommissioned,
          visible: this.canDelete()
        },
        {
          label: 'DEVICES.CARD.CONFIGURE',
          type: OverviewCardActions.CONFIGURE,
          visible: this.canCreate() && !!this.device()?.incomingConnector?.configuration?.url && this.device()?.status?.name !== DeviceStatusName.decommissioned
        },
        {
          label: 'DEVICES.CARD.START_MAINTENANCE',
          type: OverviewCardActions.START_MAINTENANCE,
          visible: this.canUpdate() && (this.device()?.status?.name === DeviceStatusName.standby || this.device()?.status?.name === DeviceStatusName.running)
        },
        {
          label: 'DEVICES.CARD.EDIT_MAINTENANCE',
          type: OverviewCardActions.EDIT_MAINTENANCE,
          visible: this.canUpdate() && this.device()?.status?.name === DeviceStatusName.maintenance
        },
        {
          label: 'DEVICES.CARD.END_MAINTENANCE',
          type: OverviewCardActions.END_MAINTENANCE,
          visible: this.canUpdate() && this.device()?.status?.name === DeviceStatusName.maintenance
        },
        {
          label: 'DEVICES.CARD.SELFCONF',
          type: OverviewCardActions.SELFCONF_COMMAND,
          disabled: this.isCommandDisabled(),
          visible: this.canUpdate() && this.hasCommand(CommandType.SELFCONF)
        },
        {
          label: 'DEVICES.CARD.REFRESH',
          type: OverviewCardActions.REFRESH_COMMAND,
          disabled: this.isCommandDisabled(),
          visible: this.canRead() && this.hasCommand(CommandType.REFRESH)
        },
        {
          label: 'DEVICES.CARD.UNBLOCK',
          type: OverviewCardActions.UNBLOCK_COMMAND,
          disabled: this.isCommandDisabled(),
          visible: this.canUpdate() && this.hasCommand(CommandType.UNBLOCK)
        },
        {
          label: 'DEVICES.CARD.GET_ALARM',
          type: OverviewCardActions.GET_ALARM_COMMAND,
          disabled: this.isCommandDisabled(),
          visible: this.canUpdate() && this.hasCommand(CommandType.GET_ALARM)
        },
        {
          label: 'AUDIT_TRAIL.CTA.HISTORY',
          type: OverviewCardActions.DISPLAY_HISTORY,
          visible: this.canReadAuditTrail()
        }
      ],
      sections: [
        //  GENERAL SECTION
        {
          title: 'IOT_DICTIONARY.GENERAL',
          collapsible: true,
          expanded: true,
          action: {
            type: OverviewCardActions.EDIT,
            icon: 'edit',
            tooltip: 'IOT_DICTIONARY.EDIT',
            disabled:
              !this.canUpdate() ||
              this.device()?.status?.name === DeviceStatusName.decommissioned ||
              this.device()?.status?.name === DeviceStatusName.test_mode,
            dispatchEvent: () => this.onEditDevice()
          },
          properties: [
            { label: 'DEVICES.CARD.IDENTIFIER', value: device.identifier, width: this.width },
            { label: 'DEVICES.CARD.FAMILY', value: device.type?.family, width: this.width },
            { label: 'DEVICES.CARD.MODEL', value: device.type?.model, width: this.width },
            { label: 'DEVICES.CARD.FIRMWARE', value: device.type?.firmware, width: this.width },
            { label: 'DEVICES.CARD.SUPPORT', value: device.communication?.support, width: this.width },
            { label: 'DEVICES.CARD.OPERATOR', value: device.communication?.operator, width: this.width },
            { label: 'DEVICES.CARD.INCOMING_CONNECTOR', value: device.incomingConnector?.name, width: this.width },
            { label: 'DEVICES.CARD.OUTGOING_CONNECTOR', value: device.outgoingConnector?.name, width: this.width },
            {
              ...{ label: 'DEVICES.CARD.ENDPOINT', value: device?.communication?.endpoint, width: this.width },
              ...(device.communication?.support === 'IP' && { onClick: () => this.onEndpointClick() })
            },
            {
              ...{ label: 'DEVICES.CARD.ICCID', value: device.communication?.iccid, width: this.width },
              ...(device.communication?.operator && device.communication?.iccid && { onClick: () => this.onIccidClick() })
            },
            ...(device.outgoingConnector?.requestConfiguration?.authentication === 'login'
              ? [
                  { label: 'DEVICES.CARD.LOGIN', value: device.credential?.login, width: this.width },
                  { label: 'DEVICES.CARD.PASSWORD', value: device.credential?.password ? '•••••' : '-', width: this.width }
                ]
              : []),
            { label: 'DEVICES.CARD.AFFILIATE', value: device.affiliate, width: this.width },
            { label: 'IOT_DICTIONARY.DESCRIPTION', value: device.description, width: this.width }
          ]
        },
        //  TAGS SECTION
        {
          title: 'IOT_DICTIONARY.TAGS',
          collapsible: true,
          expanded: true,
          style: { 'margin-bottom': '10px' },
          action: {
            type: OverviewCardActions.EDIT,
            icon: 'edit',
            tooltip: 'IOT_DICTIONARY.EDIT',
            disabled:
              !this.canUpdate() ||
              this.device()?.status?.name === DeviceStatusName.decommissioned ||
              this.device()?.status?.name === DeviceStatusName.test_mode,
            dispatchEvent: () => this.onManageTags()
          },
          properties: this.deviceTagsLoaded()
            ? tags?.length > 0
              ? tags?.map((tag) => ({
                  componentRef: {
                    ref: ChipComponent,
                    inputs: {
                      chip: tag,
                      chipButton: { display: false },
                      isTag: true
                    }
                  },
                  width: 'none'
                }))
              : [{ value: 'SITES.TABLE_CONTENT.EXPANDED_PANEL.NO_TAG', width: '100%' }]
            : undefined
        }
      ]
    };
  }

  private hasCommand(command: CommandType): boolean {
    return !!this.device()?.outgoingConnector?.requestConfiguration?.commands?.includes(command);
  }
}
