<i4b-table-engine-master-view-table (dispatchCellEvent)="onDispatchCellEvent($event)"
                                    (pageChange)="onPageChanged($event)"
                                    (sortChange)="onSortChanged($event)"
                                    [bluePrint]="bluePrint()"
                                    [checkedElements]="checkedElements()"
                                    [loading]="loading()"
                                    [metadata]="metadata()"
                                    [observerSelectors]="observerSelectors()"
                                    [pageSizeOptions]="pageSizeOptions()"
                                    [pendingRequest]="pendingRequest()"
                                    [selectedElement]="selectedElement()"
                                    [sticky]="sticky()"
                                    [tableData]="tableData()"
                                    [useFullyLoadedDataset]="useFullyLoadedDataset()"
                                    [userPermissions]="userPermissions()"
                                    [withLoadMoreButton]="withLoadMoreButton()"
                                    data-cy='i4b-table-engine-master-view-engine'
/>
