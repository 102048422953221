import { I4BCellType, I4BColumnConfiguration, I4BColumnRowGroupConfiguration } from '../configurations';
import { I4bCellConcept } from '../enums';
import { HeaderType, I4BColumnHeader } from '../models';
import { I4BColumnOptions } from '../options';
import { I4BBasicColumn } from './i4b-basic.column';

interface LatLongGroupColumnConfiguration extends I4BColumnConfiguration {
  rowGroupParams: Partial<{
    longitude: Partial<I4BColumnRowGroupConfiguration>;
    latitude: Partial<I4BColumnRowGroupConfiguration>;
  }>;
}

export class LatLongGroupColumn extends I4BBasicColumn {
  columnId = '3c4822fa-4674-4260-8e24-9091b79b7ef8-lat-long-group';
  className = LatLongGroupColumn;

  constructor(customHeader?: Partial<I4BColumnHeader>, customConfig?: Partial<LatLongGroupColumnConfiguration>, customOptions?: Partial<I4BColumnOptions>) {
    super(
      {
        displayName: customOptions?.overrideHeaderName
          ? customOptions?.overrideHeaderName
          : customHeader?.displayName
            ? customHeader?.displayName
            : 'GEOLOCATION FULL',
        type: HeaderType.BASIC
      },
      {
        cell: { type: I4BCellType.BASIC },
        id: customConfig?.id ?? 'latLongInfo',
        concept: customConfig?.concept ?? I4bCellConcept.GEOLOCATION,
        isDefault: false,
        marryChildren: true,
        rowGroupParams: customConfig?.rowGroupParams,
        children: [
          new I4BBasicColumn(
            { displayName: 'LATITUDE', type: HeaderType.BASIC },
            {
              translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.CREATED_AT',
              id: 'geolocation.latitude',
              isDefault: false,
              sortProperty: 'geolocation.latitude',
              concept: customConfig?.concept,
              width: 130,
              cell: { type: I4BCellType.BASIC },
              rowGroupParams: customConfig?.rowGroupParams?.latitude
            },
            {
              order: 980
            }
          ),
          new I4BBasicColumn(
            { displayName: 'LONGITUDE', type: HeaderType.BASIC },
            {
              translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.CREATED_BY',
              id: 'geolocation.longitude',
              isDefault: false,
              sortProperty: 'geolocation.longitude',
              concept: customConfig?.concept,
              width: 130,
              cell: { type: I4BCellType.BASIC },
              rowGroupParams: customConfig?.rowGroupParams?.longitude
            },
            {
              order: 981
            }
          )
        ]
      },
      { ...customOptions, customId: customConfig.id }
    );
  }
}
