import { inject } from '@angular/core';
import { GridsDbActions } from '@iot-platform/grid-engine';
import { NotificationService } from '@iot-platform/notification';
import { FavoriteViewsActions, FavoriteViewsService } from '@iot-platform/shared/components';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action, Store } from '@ngrx/store';

import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators';
import { BusinessProfilesService } from '../../services/business-profiles.service';
import { AdminBusinessProfilesFavoriteViewActions } from '../actions/business-profiles-favorite-views.actions';
import { AdminBusinessProfilesFacade } from '../facades/admin-business-profiles.facade';

const loadFavoriteViews$ = createEffect(
  (actions$ = inject(Actions), businessProfilesService = inject(BusinessProfilesService)) =>
    actions$.pipe(
      ofType(AdminBusinessProfilesFavoriteViewActions.loadFavoriteViewsByBusinessProfile),
      concatMap((action) =>
        businessProfilesService.loadFavoriteViewsByBusinessProfileId(action.businessProfileId).pipe(
          map((response) => AdminBusinessProfilesFavoriteViewActions.loadFavoriteViewsByBusinessProfileSuccess({ response })),
          catchError((error) => of(AdminBusinessProfilesFavoriteViewActions.loadFavoriteViewsByBusinessProfileFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const addFavoriteView$ = createEffect(
  (actions$ = inject(Actions), favoriteViewsService = inject(FavoriteViewsService), facade = inject(AdminBusinessProfilesFacade)) =>
    actions$.pipe(
      ofType(AdminBusinessProfilesFavoriteViewActions.addOne),
      concatMap((action) =>
        favoriteViewsService.saveFavoriteView(action.toAdd).pipe(
          concatLatestFrom((added) => facade.currentEntity$),
          map(([added, selectedBP]) => {
            if (added.businessProfileId === selectedBP?.id) {
              return AdminBusinessProfilesFavoriteViewActions.addOneSuccess({ added });
            }
            return AdminBusinessProfilesFavoriteViewActions.addFavoriteViewInAnotherBusinessProfileSuccess();
          }),
          catchError((error) => of(AdminBusinessProfilesFavoriteViewActions.addOneFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const updateFavoriteView$ = createEffect(
  (actions$ = inject(Actions), favoriteViewsService = inject(FavoriteViewsService)) =>
    actions$.pipe(
      ofType(AdminBusinessProfilesFavoriteViewActions.updateOne),
      concatMap((action) =>
        favoriteViewsService.updateFavoriteView(action.toUpdate).pipe(
          map((updated) => AdminBusinessProfilesFavoriteViewActions.updateOneSuccess({ updated })),
          catchError((error) => of(AdminBusinessProfilesFavoriteViewActions.updateOneFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const deleteFavoriteView$ = createEffect(
  (actions$ = inject(Actions), favoriteViewsService = inject(FavoriteViewsService)) =>
    actions$.pipe(
      ofType(AdminBusinessProfilesFavoriteViewActions.deleteOne),
      concatMap((action) =>
        favoriteViewsService.deleteFavoriteView(action.toDelete).pipe(
          map((deleted) => AdminBusinessProfilesFavoriteViewActions.deleteOneSuccess({ deleted })),
          catchError((error) => of(AdminBusinessProfilesFavoriteViewActions.deleteOneFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const duplicateFavoriteView$ = createEffect(
  (actions$ = inject(Actions), favoriteViewsService = inject(FavoriteViewsService), facade = inject(AdminBusinessProfilesFacade)) =>
    actions$.pipe(
      ofType(AdminBusinessProfilesFavoriteViewActions.duplicateFavoriteView),
      switchMap((action) =>
        favoriteViewsService.saveFavoriteView(action.favoriteViewToDuplicate).pipe(
          concatLatestFrom((added) => facade.currentEntity$),
          map(([duplicatedFavoriteView, selectedBP]) => {
            if (duplicatedFavoriteView.businessProfileId === selectedBP?.id) {
              return AdminBusinessProfilesFavoriteViewActions.duplicateFavoriteViewSuccess({ duplicatedFavoriteView });
            }
            return AdminBusinessProfilesFavoriteViewActions.duplicateFavoriteViewInAnotherBusinessProfileSuccess();
          }),
          catchError((error) => of(AdminBusinessProfilesFavoriteViewActions.duplicateFavoriteViewFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const duplicateGridThenAddFavoriteView$ = createEffect(
  (actions$ = inject(Actions), favoriteViewsService = inject(FavoriteViewsService), facade = inject(AdminBusinessProfilesFacade)) =>
    actions$.pipe(
      ofType(AdminBusinessProfilesFavoriteViewActions.duplicateGridThenAddFavoriteView),
      concatMap((action) =>
        favoriteViewsService.duplicateAndShareGrid(action.grid, action.favoriteViewToAdd).pipe(
          switchMap(({ grid, favoriteView }) =>
            favoriteViewsService.saveFavoriteView({ ...favoriteView, gridId: grid.id }).pipe(
              map((added) => AdminBusinessProfilesFavoriteViewActions.addOneSuccess({ added })),
              catchError((error) => of(AdminBusinessProfilesFavoriteViewActions.addOneFailure({ error })))
            )
          ),
          catchError((error) => of(AdminBusinessProfilesFavoriteViewActions.duplicateGridThenAddFavoriteViewFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const duplicateGridThenUpdateFavoriteView$ = createEffect(
  (actions$ = inject(Actions), favoriteViewsService = inject(FavoriteViewsService), store = inject(Store)) =>
    actions$.pipe(
      ofType(AdminBusinessProfilesFavoriteViewActions.duplicateGridThenUpdateFavoriteView),
      concatMap((action) =>
        favoriteViewsService.duplicateAndShareGrid(action.grid, action.favoriteViewToUpdate).pipe(
          switchMap(({ grid, favoriteView }) =>
            favoriteViewsService.updateFavoriteView({ ...favoriteView, gridId: grid.id }).pipe(
              map((updatedFavoriteView) => AdminBusinessProfilesFavoriteViewActions.updateOneSuccess({ updated: updatedFavoriteView })),
              catchError((error) => of(AdminBusinessProfilesFavoriteViewActions.updateOneFailure({ error })))
            )
          ),
          catchError((error) => of(AdminBusinessProfilesFavoriteViewActions.duplicateGridThenUpdateFavoriteViewFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const duplicateGridAndFavoriteView$ = createEffect(
  (actions$ = inject(Actions), favoriteViewsService = inject(FavoriteViewsService)) =>
    actions$.pipe(
      ofType(AdminBusinessProfilesFavoriteViewActions.duplicateGridAndFavoriteView),
      switchMap((action) =>
        favoriteViewsService.duplicateAndShareGrid(action.gridToDuplicate, action.favoriteViewToDuplicate).pipe(
          switchMap(({ grid, favoriteView }) =>
            favoriteViewsService.saveFavoriteView({ ...favoriteView, gridId: grid.id }).pipe(
              map(() => AdminBusinessProfilesFavoriteViewActions.duplicateFavoriteViewInAnotherBusinessProfileSuccess()),
              catchError((error) => of(AdminBusinessProfilesFavoriteViewActions.duplicateFavoriteViewFailure({ error })))
            )
          ),
          catchError((error) => of(AdminBusinessProfilesFavoriteViewActions.duplicateFavoriteViewFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const addFavoriteViewSuccess$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(AdminBusinessProfilesFavoriteViewActions.addOneSuccess),
      concatMap(({ added }) =>
        !added.gridId
          ? [FavoriteViewsActions.addFavoriteViewSuccess({ favoriteView: added })]
          : [FavoriteViewsActions.addFavoriteViewSuccess({ favoriteView: added }), GridsDbActions.loadGrids({ concept: added.concept })]
      )
    ),
  { functional: true }
);

const synchronizeFavoriteViewAfterSave$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(FavoriteViewsActions.synchronizeFavoriteViewAfterSave),
      map(({ favoriteView }) => AdminBusinessProfilesFavoriteViewActions.addOneSuccess({ added: favoriteView }))
    ),
  { functional: true }
);

const updateFavoriteViewSuccess$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(AdminBusinessProfilesFavoriteViewActions.updateOneSuccess),
      concatMap(({ updated }) =>
        !updated.gridId
          ? [FavoriteViewsActions.updateFavoriteViewSuccess({ favoriteView: updated })]
          : [FavoriteViewsActions.updateFavoriteViewSuccess({ favoriteView: updated }), GridsDbActions.loadGrids({ concept: updated.concept })]
      )
    ),
  { functional: true }
);

const deleteFavoriteViewSuccess$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(AdminBusinessProfilesFavoriteViewActions.deleteOneSuccess),
      map(({ deleted }) => FavoriteViewsActions.deleteFavoriteViewSuccess({ deletedFavoriteView: deleted }))
    ),
  { functional: true }
);

const duplicateFavoriteViewSuccess$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(AdminBusinessProfilesFavoriteViewActions.duplicateFavoriteViewSuccess),
      map(({ duplicatedFavoriteView }) => FavoriteViewsActions.addFavoriteViewSuccess({ favoriteView: duplicatedFavoriteView }))
    ),
  { functional: true }
);

const showLoader$ = createEffect(
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(
        AdminBusinessProfilesFavoriteViewActions.loadFavoriteViewsByBusinessProfile,
        AdminBusinessProfilesFavoriteViewActions.addOne,
        AdminBusinessProfilesFavoriteViewActions.updateOne,
        AdminBusinessProfilesFavoriteViewActions.deleteOne,
        AdminBusinessProfilesFavoriteViewActions.duplicateFavoriteView,
        AdminBusinessProfilesFavoriteViewActions.duplicateGridThenAddFavoriteView,
        AdminBusinessProfilesFavoriteViewActions.duplicateGridThenUpdateFavoriteView,
        AdminBusinessProfilesFavoriteViewActions.duplicateGridAndFavoriteView
      ),
      tap(() => notificationService.showLoader())
    ),
  { functional: true, dispatch: false }
);

const hideLoader$ = createEffect(
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(
        AdminBusinessProfilesFavoriteViewActions.loadFavoriteViewsByBusinessProfileSuccess,
        AdminBusinessProfilesFavoriteViewActions.loadFavoriteViewsByBusinessProfileFailure,
        AdminBusinessProfilesFavoriteViewActions.addOneSuccess,
        AdminBusinessProfilesFavoriteViewActions.addOneFailure,
        AdminBusinessProfilesFavoriteViewActions.updateOneSuccess,
        AdminBusinessProfilesFavoriteViewActions.updateOneFailure,
        AdminBusinessProfilesFavoriteViewActions.deleteOneSuccess,
        AdminBusinessProfilesFavoriteViewActions.deleteOneFailure,
        AdminBusinessProfilesFavoriteViewActions.duplicateFavoriteViewSuccess,
        AdminBusinessProfilesFavoriteViewActions.duplicateFavoriteViewFailure,
        AdminBusinessProfilesFavoriteViewActions.duplicateGridThenAddFavoriteViewSuccess,
        AdminBusinessProfilesFavoriteViewActions.duplicateGridThenAddFavoriteViewFailure,
        AdminBusinessProfilesFavoriteViewActions.duplicateGridThenUpdateFavoriteViewSuccess,
        AdminBusinessProfilesFavoriteViewActions.duplicateGridThenUpdateFavoriteViewFailure,
        AdminBusinessProfilesFavoriteViewActions.addFavoriteViewInAnotherBusinessProfileSuccess
      ),
      tap(() => notificationService.hideLoader())
    ),
  { functional: true, dispatch: false }
);

const displaySuccess$ = createEffect(
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(
        AdminBusinessProfilesFavoriteViewActions.addOneSuccess,
        AdminBusinessProfilesFavoriteViewActions.updateOneSuccess,
        AdminBusinessProfilesFavoriteViewActions.deleteOneSuccess,
        AdminBusinessProfilesFavoriteViewActions.duplicateFavoriteViewSuccess,
        AdminBusinessProfilesFavoriteViewActions.duplicateGridThenAddFavoriteViewSuccess,
        AdminBusinessProfilesFavoriteViewActions.duplicateGridThenUpdateFavoriteViewSuccess,
        AdminBusinessProfilesFavoriteViewActions.addFavoriteViewInAnotherBusinessProfileSuccess,
        AdminBusinessProfilesFavoriteViewActions.duplicateFavoriteViewInAnotherBusinessProfileSuccess
      ),
      tap((action: Action) => notificationService.displaySuccess(action.type))
    ),
  { functional: true, dispatch: false }
);

const displayError$ = createEffect(
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(
        AdminBusinessProfilesFavoriteViewActions.loadFavoriteViewsByBusinessProfileFailure,
        AdminBusinessProfilesFavoriteViewActions.addOneFailure,
        AdminBusinessProfilesFavoriteViewActions.updateOneFailure,
        AdminBusinessProfilesFavoriteViewActions.deleteOneFailure,
        AdminBusinessProfilesFavoriteViewActions.duplicateFavoriteViewFailure,
        AdminBusinessProfilesFavoriteViewActions.duplicateGridThenAddFavoriteViewFailure,
        AdminBusinessProfilesFavoriteViewActions.duplicateGridThenUpdateFavoriteViewFailure
      ),
      tap((action) => notificationService.displayError(action))
    ),
  { functional: true, dispatch: false }
);

export const adminBusinessProfilesFavoriteViewsEffects = {
  addFavoriteView$,
  updateFavoriteView$,
  deleteFavoriteView$,
  duplicateFavoriteView$,
  duplicateGridThenAddFavoriteView$,
  duplicateGridThenUpdateFavoriteView$,
  duplicateGridAndFavoriteView$,
  addFavoriteViewSuccess$,
  synchronizeFavoriteViewAfterSave$,
  updateFavoriteViewSuccess$,
  deleteFavoriteViewSuccess$,
  duplicateFavoriteViewSuccess$,
  loadFavoriteViews$,
  showLoader$,
  hideLoader$,
  displaySuccess$,
  displayError$
};
