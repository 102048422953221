import { Component, inject } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AbstractControl, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Organization } from '@iot-platform/models/common';
import { TranslateModule } from '@ngx-translate/core';
import { OrganizationConceptsSelectionModule } from '../../organization-concepts-selection/organization-concepts-selection.module';

@Component({
    imports: [
        FlexLayoutModule,
        MatCardModule,
        MatToolbarModule,
        MatIcon,
        MatButtonModule,
        TranslateModule,
        ReactiveFormsModule,
        OrganizationConceptsSelectionModule
    ],
    selector: 'iot4bos-ui-backoffice-manage-concepts-dialog',
    templateUrl: './manage-concepts-dialog.component.html',
    styleUrls: ['./manage-concepts-dialog.component.scss']
})
export class ManageConceptsDialogComponent {
  public data: { organization: Organization; adminConceptsFromEntitySession: string[] } = inject(MAT_DIALOG_DATA);
  private dialogRef: MatDialogRef<ManageConceptsDialogComponent> = inject(MatDialogRef<ManageConceptsDialogComponent>);

  conceptsForm: UntypedFormGroup = new UntypedFormGroup({ conceptsToUpdate: new UntypedFormControl(this.data.organization.adminConcepts) });

  get conceptsToUpdate(): AbstractControl {
    return this.conceptsForm.get('conceptsToUpdate');
  }

  onChangeConcepts(newConcepts: string[]) {
    this.conceptsToUpdate.setValue(Object.assign([], newConcepts));
    this.conceptsForm.markAsDirty();
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    const organizationToUpdate: Organization = {
      ...this.data.organization,
      adminConcepts: this.conceptsToUpdate.value
    };
    this.dialogRef.close(organizationToUpdate);
  }
}
