import { Injectable } from '@angular/core';
import { BaseFacade, CommonApiRequest, Filter, Pagination, Role } from '@iot-platform/models/common';
import { Store } from '@ngrx/store';
import { noop } from 'rxjs';
import { AdminBusinessProfilesRolesActions } from '../actions/business-profiles-roles.actions';
import { AdminBusinessProfilesRolesSelectors } from '../selectors/admin-business-profiles-roles.selectors';

@Injectable({
  providedIn: 'root'
})
export class AdminBusinessProfilesRolesFacade extends BaseFacade<Role, Pagination, Filter> {
  constructor(
    protected override store: Store,
    protected override selector: AdminBusinessProfilesRolesSelectors
  ) {
    super(store, selector);
  }

  getAll(request: CommonApiRequest) {
    noop();
  }

  setFilters() {
    noop();
  }

  removeRoleFromBusinessProfile(businessProfileId: string, roleToRemove: Role) {
    this.store.dispatch(
      AdminBusinessProfilesRolesActions.removeRoleFromBusinessProfile({
        businessProfileId,
        roleToRemove
      })
    );
  }

  addRoleToBusinessProfile(businessProfileId: string, roleToAdd: Role) {
    this.store.dispatch(AdminBusinessProfilesRolesActions.addRoleToBusinessProfile({ businessProfileId, roleToAdd }));
  }
}
