<div [ngClass]="{ 'table-container--sticky': sticky() }" class="table-container">
  <table
    (matSortChange)="onSortChange($event)"
    [dataSource]="masterViewDataSource"
    [data]="tableData()"
    [observerSelectors]="observerSelectors()"
    [pagination]="pagination()"
    [sticky]="sticky()"
    i4b-table-engine-master-view-table-full-height
    mat-table
    matSort
    multiTemplateDataRows
  >
    @if (bluePrint()?.multipleSelection) {
      <ng-container [matColumnDef]="bluePrint()?.selectionColumn?.id">
        <th *matHeaderCellDef [fxFlex]="bluePrint()?.selectionColumn?.width"
            [fxLayoutAlign]="bluePrint()?.selectionColumn?.type" mat-header-cell>
          <mat-checkbox
            (change)="toggleAllSelection()"
            [checked]="isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
            class="mve-select-checkbox"
          />
          @if (totalSelected > 1) {
            <span [textContent]="totalSelected" class="table-total-selected fw-600"></span>
          }
        </th>
        <td *matCellDef="let element" [fxFlex]="bluePrint()?.selectionColumn?.width"
            [fxLayoutAlign]="bluePrint()?.selectionColumn?.type" mat-cell>
          <mat-checkbox (change)="toggleSelection(element)" (click)="$event.stopPropagation()"
                        [checked]="isChecked(element)" />
        </td>
      </ng-container>
    }
    @for (column of columnsToDisplay(); track column) {
      <ng-container [matColumnDef]="column.id">
        <th
          *matHeaderCellDef
          [disabled]="!column.sortable"
          [fxFlex]="column.width"
          [fxLayoutAlign]="column.type"
          [mat-sort-header]="column.sortProperty"
          mat-header-cell
        >
          <i4b-table-engine-master-view-header-container [column]="column" />
        </th>
        <td *matCellDef="let element" [fxFlex]="column.width" [fxLayoutAlign]="column.type"
            data-cy="i4b-table-engine-master-view-cell-container" mat-cell>
          <i4b-table-engine-master-view-cell-container
            (dispatchEvent)="onDispatchEvent($event)"
            [column]="column"
            [element]="element"
            [userPermissions]="userPermissions()"
          />
        </td>
      </ng-container>
    }
    @if (bluePrint()?.multipleSelection) {
      <ng-container [matColumnDef]="bluePrint()?.buttonColumn?.id">
        <th *matHeaderCellDef [fxFlex]="bluePrint()?.buttonColumn?.width"
            [fxLayoutAlign]="bluePrint()?.buttonColumn?.type"
            class="table-buttons" mat-header-cell>
          @if (totalSelected > 1) {
            <div class="app-round-button-mv action">
              @if (isCallToActionVisible()) {
                <button [matMenuTriggerFor]="menu" color="accent" mat-icon-button>
                  <mat-icon>more_vert</mat-icon>
                </button>
              }
              <mat-menu #menu="matMenu">
                @for (action of bluePrint()?.buttonColumn?.bulkActions; track action) {
                  <div>
                    @let bulkActionsVisibility = bulkActionVisibility();
                    @if (bulkActionsVisibility && bulkActionsVisibility[action.key]) {
                      <button (click)="onBulkActionClick(action.key)" mat-menu-item>
                        {{ action.label | translate | uppercase }}
                      </button>
                    }
                  </div>
                }
              </mat-menu>
            </div>
          }
        </th>
        <td
          *matCellDef="let element"
          [fxFlex]="bluePrint()?.buttonColumn?.width"
          [fxLayoutAlign]="bluePrint()?.buttonColumn?.type"
          class="table-buttons"
          data-cy="i4b-table-engine-call-to-action-cell"
          mat-cell
        >
          @if (isCallToActionVisible()) {
            <i4b-table-engine-call-to-action-cell
              (actionClicked)="onSingleActionClick($event)"
              [actions]="bluePrint()?.buttonColumn?.singleActions"
              [element]="element"
              [userPermissions]="userPermissions()"
            />
          }
        </td>
      </ng-container>
    }
    @if (!bluePrint()?.multipleSelection && bluePrint()?.buttonColumn) {
      <ng-container [matColumnDef]="bluePrint()?.buttonColumn?.id">
        <th
          *matHeaderCellDef
          [fxFlex]="bluePrint()?.buttonColumn?.width"
          [fxLayoutAlign]="bluePrint()?.buttonColumn?.type"
          class="table-buttons"
          mat-header-cell
        ></th>
        <td
          *matCellDef="let element"
          [fxFlex]="bluePrint()?.buttonColumn?.width"
          [fxLayoutAlign]="bluePrint()?.buttonColumn?.type"
          class="table-buttons"
          data-cy="i4b-table-engine-call-to-action-cell"
          mat-cell
        >
          @if (isCallToActionVisible()) {
            <i4b-table-engine-call-to-action-cell
              (actionClicked)="onSingleActionClick($event)"
              [actions]="bluePrint()?.buttonColumn?.singleActions"
              [element]="element"
              [userPermissions]="userPermissions()"
            />
          }
        </td>
      </ng-container>
    }
    <tr *matHeaderRowDef="columnsToDisplayIds()" class="table-row-header" mat-header-row></tr>
    <tr
      (click)="openDetail(element)"
      *matRowDef="let element; columns: columnsToDisplayIds()"
      [class.checked]="isChecked(element)"
      [class.selected]="isRowSelected(element)"
      class="table-row-content"
      data-cy="i4b-table-engine-row"
      mat-row
    ></tr>
    <!--    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="table-row-detail"></tr>-->
  </table>
  @if (withLoadMoreButton()) {
    <div class="load-more" fxLayout="column" fxLayoutAlign="center center">
      @if (pagination()?.hasMore) {
        <button (click)="onLoadMore()" [disabled]="loading()" class="button-regular" mat-button>
          <div fxLayout="row" fxLayoutAlign="space-around center">
            @if (loading()) {
              <mat-progress-spinner [diameter]="20" color="accent" mode="indeterminate" />
            }
            {{ 'IOT_DICTIONARY.LOAD_MORE' | translate }}
          </div>
        </button>
      }
    </div>
  } @else {
    <ng-container *ngTemplateOutlet="paginatorTmpl" />
  }

  <ng-template #paginatorTmpl>
    @if (useFullyLoadedDataset()) {
      <mat-paginator
        #fullDatasetPaginator
        (page)="onPageChange($event)"
        [length]="pagination()?.total"
        [pageIndex]="pagination()?.currentPage"
        [pageSizeOptions]="pageSizeOptions()"
        [pageSize]="pagination()?.limit"
        [showFirstLastButtons]="true"
      />
    } @else {
      <mat-paginator
        #onePageDatasetPaginator
        (page)="onPageChange($event)"
        [disabled]="pendingRequest()"
        [length]="pagination()?.total"
        [pageIndex]="pagination()?.currentPage"
        [pageSizeOptions]="pageSizeOptions()"
        [pageSize]="pagination()?.limit"
        [showFirstLastButtons]="true"
      />
    }
  </ng-template>
</div>
<ng-template #exportViewRef />
