import { NgModule } from '@angular/core';
import { MAT_PAGINATOR_DEFAULT_OPTIONS } from '@angular/material/paginator';
import { MasterViewEngineService } from '../master-view-engine.service';
import { MasterViewComponentFactory } from './master-view-factory';
import { MasterViewTableComponent } from './master-view-table.component';

@NgModule({
  providers: [
    MasterViewComponentFactory,
    MasterViewEngineService,
    {
      provide: MAT_PAGINATOR_DEFAULT_OPTIONS,
      useValue: {
        formFieldAppearance: 'fill'
      }
    }
  ],
  imports: [MasterViewTableComponent],
  exports: [MasterViewTableComponent]
})
export class MasterViewTableModule {}
