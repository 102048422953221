import { IHeaderAngularComp } from '@ag-grid-community/angular';
import { IHeaderParams } from '@ag-grid-community/core';
import { UpperCasePipe } from '@angular/common';
import { Component, computed, Signal, signal, WritableSignal } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MatDivider } from '@angular/material/divider';
import { MatIcon } from '@angular/material/icon';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { ConditionProcessorUtil } from '@iot-platform/iot-platform-utils';
import { TranslateModule } from '@ngx-translate/core';
import { get } from 'lodash';

type CallToActionHeaderParams = IHeaderParams & {
  visibleConditions: [];
  bulkActions: [];
  userPermissions: [];
  // eslint-disable-next-line @typescript-eslint/ban-types
  dispatchEvent: Function;
};

@Component({
  selector: 'grid-engine-call-to-action-header',
  templateUrl: './call-to-action-header.component.html',
  imports: [MatIcon, MatMenuTrigger, MatIconButton, MatMenu, MatDivider, UpperCasePipe, MatMenuItem, TranslateModule],
  styleUrls: ['./call-to-action-header.component.scss']
})
export class CallToActionHeaderComponent implements IHeaderAngularComp {
  params: WritableSignal<CallToActionHeaderParams> = signal(null);
  canShowBulkButton: Signal<boolean> = computed(() => {
    const params = this.params();
    return !!params?.api?.getSelectedRows()?.length;
  });
  bulkActions: Signal<any[]> = computed(() => {
    const params = this.params();
    return get(params, ['bulkActions'], []);
  });
  visibleConditions: Signal<any[]> = computed(() => {
    const params = this.params();
    return get(params, ['visibleConditions'], []);
  });
  userPermissions: Signal<any[]> = computed(() => {
    const params = this.params();
    return get(params, ['userPermissions'], []);
  });
  bulkActionVisibility: Signal<any[]> = computed(() => {
    const bulkActions = this.bulkActions();
    const userPermissions = this.userPermissions();
    return bulkActions?.reduce((acc, value) => {
      acc[value.key] = ConditionProcessorUtil.processConditionsWithPermission(value.visibleConditions, userPermissions);
      return acc;
    }, {});
  });
  isCallToActionVisible: Signal<boolean> = computed(() => {
    const visibleConditions = this.visibleConditions();
    const userPermissions = this.userPermissions();
    return ConditionProcessorUtil.processConditionsWithPermission(visibleConditions, userPermissions);
  });

  agInit(params: CallToActionHeaderParams): void {
    this.params.set(params);
  }

  refresh(): boolean {
    return false;
  }

  onBulkActionClick(type: string): void {
    const params = this.params();
    params.dispatchEvent({ type, rawData: params.api.getSelectedRows(), options: {} });
  }
}
