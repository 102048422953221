import { NgModule } from '@angular/core';
import { BreadcrumbItemComponent } from './breadcrumb-item/breadcrumb-item.component';
import { BreadcrumbComponent } from './breadcrumb.component';
import { DeviceBreadcrumbItemComponent } from './device-breadcrumb-item/device-breadcrumb-item.component';
import { SiteBreadcrumbItemComponent } from './site-breadcrumb-item/site-breadcrumb-item.component';

@NgModule({
  imports: [BreadcrumbComponent, SiteBreadcrumbItemComponent, BreadcrumbItemComponent, DeviceBreadcrumbItemComponent],
  exports: [BreadcrumbComponent, SiteBreadcrumbItemComponent, BreadcrumbItemComponent, DeviceBreadcrumbItemComponent]
})
export class BreadcrumbModule {}
