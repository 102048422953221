import { BaseState, Filter, INITIAL_BASE_STATE, Pagination } from '@iot-platform/models/common';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createFeature, createReducer } from '@ngrx/store';
import { Event } from '@iot-platform/models/i4b';

export type State = BaseState<Event, Pagination, Filter>;

export const adapter: EntityAdapter<Event> = createEntityAdapter<Event>({
  selectId: (entity: Event) => entity.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  ...INITIAL_BASE_STATE
});

export const assetEventsFeature = createFeature({
  name: 'assetEvents',
  reducer: createReducer(initialState)
});
