import { Component, DestroyRef, effect, inject, Injector, input, model, OnInit, output, signal, untracked, WritableSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Sort } from '@angular/material/sort';
import { Pagination } from '@iot-platform/models/common';
import { MasterView } from '@iot-platform/models/i4b';
import { NotificationService } from '@iot-platform/notification';
import { of } from 'rxjs';
import { finalize, switchMap, tap } from 'rxjs/operators';
import { MasterViewEngineService } from './master-view-engine.service';
import { MasterViewTableModule } from './master-view-table/master-view-table.module';

@Component({
  selector: 'i4b-table-engine-master-view-engine',
  templateUrl: './master-view-engine.component.html',
  styleUrls: ['./master-view-engine.component.scss'],
  imports: [MasterViewTableModule],
  providers: [MasterViewEngineService]
})
export class MasterViewEngineComponent implements OnInit {
  private readonly injector: Injector = inject(Injector);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  private readonly masterViewEngineService: MasterViewEngineService = inject(MasterViewEngineService);
  private readonly notificationService: NotificationService = inject(NotificationService);
  masterViewType = input<{
    name: string;
    isPath: boolean;
    useExternalMetadata: boolean;
  }>();
  sticky = input<boolean>(false);
  metadata = input<any>();
  data = input<any>();
  contextElement = input<any>();
  userPermissions = input<{ key: string; value: boolean }[]>([]);
  extraParam = input<any>();
  useExternalData = input<boolean>(true);
  selectedElement = model<any>();
  checkedElements = input<any[]>([]);
  useFullyLoadedDataset = input<boolean>(false);
  pageSizeOptions = input<number[]>([]);
  withLoadMoreButton = input<boolean>(false);
  loading = input<boolean>(false);
  observerSelectors = input<string[]>([]);

  dispatchMasterViewEngineEvent = output<any>();
  pageChange = output<Pagination>();
  sortChange = output<Sort>();

  masterViewMetadata: WritableSignal<any> = signal<any>(null);
  bluePrint: WritableSignal<any> = signal<any>(null);
  tableData: WritableSignal<any> = signal({ data: [] });
  pendingRequest: WritableSignal<boolean> = signal(false);

  ngOnInit() {
    effect(
      () => {
        const masterViewType = this.masterViewType();
        untracked(() => {
          if (masterViewType) {
            let pathToMasterView: { name; isPath: boolean };
            if (!masterViewType?.name) {
              pathToMasterView = { name: masterViewType, isPath: false };
            } else {
              pathToMasterView = {
                name: masterViewType.name,
                isPath: masterViewType.isPath
              };
            }

            if (!masterViewType.useExternalMetadata) {
              this.notificationService.showLoader();
              this.masterViewEngineService
                .getMasterView(pathToMasterView.name, pathToMasterView.isPath)
                .pipe(
                  tap((masterView: MasterView) => {
                    this.masterViewMetadata.set(masterView.metadata);
                    this.bluePrint.set(masterView.masterViewTable.bluePrint);
                  }),
                  switchMap(() => {
                    if (!this.useExternalData()) {
                      return this.masterViewEngineService.getData(
                        masterViewType.name,
                        this.contextElement().name,
                        this.masterViewMetadata(),
                        this.contextElement(),
                        this.extraParam()
                      );
                    }
                    return of(null);
                  }),
                  finalize(() => this.notificationService.hideLoader()),
                  takeUntilDestroyed(this.destroyRef)
                )
                .subscribe((data) => {
                  if (data) {
                    this.tableData.set(data);
                  }
                });
            }
          }
        });
      },
      { injector: this.injector }
    );

    effect(
      () => {
        const metadata = this.metadata();
        if (metadata) {
          this.bluePrint.set(metadata.masterViewTable.bluePrint);
        }
      },
      { injector: this.injector }
    );
    effect(
      () => {
        const data = this.data();
        if (data) {
          this.tableData.set(data);
        }
      },
      { injector: this.injector }
    );
  }

  onDispatchCellEvent(event: any) {
    this.dispatchMasterViewEngineEvent.emit(event);
  }

  onPageChanged(pagination: Pagination) {
    if (!this.useExternalData()) {
      this.notificationService.showLoader();
      this.pendingRequest.set(true);
      const metadata = this.masterViewMetadata();
      metadata.params.page = pagination.currentPage;
      this.masterViewEngineService
        .getData(
          this.masterViewType()?.name,
          this.contextElement?.name ? this.contextElement().name : this.contextElement(),
          metadata,
          this.contextElement(),
          this.extraParam()
        )
        .pipe(
          finalize(() => this.notificationService.hideLoader()),
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe((data) => {
          this.pendingRequest.set(false);
          this.tableData.set(data);
        });
    } else {
      this.pageChange.emit(pagination);
    }
  }

  onSortChanged(sort: Sort) {
    this.sortChange.emit(sort);
  }
}
