import { Signal } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ApiFeatureSelector } from './api-feature-selector.model';
import { ApiSelector } from './api-selector.model';

/* eslint-disable  @typescript-eslint/no-explicit-any */

/* eslint-disable  @typescript-eslint/ban-types */
export abstract class BaseFacade<T, P, F> {
  all$: Observable<T[]>;
  all: Signal<T[]>;
  total$: Observable<number>;
  total: Signal<number>;
  entities$: Observable<{ [key: string]: T }>;
  entities: Signal<{ [key: string]: T }>;
  currentEntity$: Observable<T | null>;
  currentEntity: Signal<T>;
  pagination$: Observable<P | null>;
  pagination: Signal<P>;
  platformResponse$: Observable<{
    data: T[];
    currentPage: number;
    hasMore: boolean;
    limit: number;
    maxPage: number;
    total: number;
  }>;
  platformResponse: Signal<{
    data: T[];
    currentPage: number;
    hasMore: boolean;
    limit: number;
    maxPage: number;
    total: number;
  }>;
  loading$: Observable<boolean>;
  loading: Signal<boolean>;
  loaded$: Observable<boolean>;
  loaded: Signal<boolean>;
  filters$: Observable<F[]>;
  filters: Signal<F[]>;
  error$: Observable<unknown>;
  error: Signal<unknown>;

  protected constructor(
    protected store: Store,
    protected selector: ApiSelector<any, T, P, F> | ApiFeatureSelector<any, T, P, F>
  ) {
    this.all$ = this.store.select(this.selector.selectAll);
    this.all = this.store.selectSignal(this.selector.selectAll);
    this.entities$ = this.store.select(this.selector.selectEntities);
    this.entities = this.store.selectSignal(this.selector.selectEntities);
    this.currentEntity$ = this.store.select(this.selector.selectCurrentEntity);
    this.currentEntity = this.store.selectSignal(this.selector.selectCurrentEntity);
    this.total$ = this.store.select(this.selector.selectTotal);
    this.total = this.store.selectSignal(this.selector.selectTotal);
    this.pagination$ = this.store.select(this.selector.selectPagination);
    this.pagination = this.store.selectSignal(this.selector.selectPagination);
    this.platformResponse$ = this.store.select(this.selector.selectPlatformResponse);
    this.platformResponse = this.store.selectSignal(this.selector.selectPlatformResponse);
    this.loading$ = this.store.select(this.selector.selectLoading);
    this.loading = this.store.selectSignal(this.selector.selectLoading);
    this.loaded$ = this.store.select(this.selector.selectLoaded);
    this.loaded = this.store.selectSignal(this.selector.selectLoaded);
    this.filters$ = this.store.select(this.selector.selectFilters);
    this.filters = this.store.selectSignal(this.selector.selectFilters);
    this.error$ = this.store.select(this.selector.selectError);
    this.error = this.store.selectSignal(this.selector.selectError);
  }

  abstract setFilters(filters: F[]): void;

  abstract getAll(params?: any): void;
}
