import { Component, input } from '@angular/core';

import { Site } from '@iot-platform/models/i4b';
import { InfoDisplayPipe } from '@iot-platform/pipes';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    imports: [InfoDisplayPipe, TranslateModule],
    providers: [InfoDisplayPipe],
    selector: 'iot-platform-ui-detail-card',
    templateUrl: './detail-card.component.html',
    styleUrls: ['./detail-card.component.scss']
})
export class DetailCardComponent {
  site = input<Site>();
  extraFill = input<boolean>(false);
}
