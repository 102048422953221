import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Component, computed, inject, Signal, signal, WritableSignal } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'grid-engine-variable-dispatch-cell',
    templateUrl: './variable-dispatch-cell.component.html',
    imports: [MatTooltip, MatIcon],
    styleUrls: ['./variable-dispatch-cell.component.scss']
})
export class VariableDispatchCellComponent implements ICellRendererAngularComp {
  private translateService: TranslateService = inject(TranslateService);
  params: WritableSignal<ICellRendererParams> = signal(null);
  tooltip: Signal<string> = computed(() => {
    const params = this.params();
    return params?.value?.dispatchDestination
      ? this.translateService.instant('ASSETS.VARIABLE.DISPATCH_TOOLTIP', { dispatchDestination: params?.value?.dispatchDestination })
      : '';
  });

  agInit(params: ICellRendererParams) {
    this.params.set(params);
  }

  refresh(): boolean {
    return false;
  }
}
