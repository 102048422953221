import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component, DestroyRef, effect, inject, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { I4BGrid, I4BGridData, I4BGridOptions } from '@iot-platform/models/grid-engine';
import { InfoDisplayPipe } from '@iot-platform/pipes';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';
import { CustomCellParams } from '../../../models/custom-cell.params';
import { GridNameCellService } from './grid-name-cell.service';

@Component({
  selector: 'grid-engine-grid-name-cell',
  templateUrl: './grid-name-cell.component.html',
  imports: [MatProgressSpinner, InfoDisplayPipe],
  providers: [InfoDisplayPipe, TranslateService],
  styleUrls: ['./grid-name-cell.component.scss']
})
export class GridNameCellComponent implements ICellRendererAngularComp {
  private readonly gridNameCellService: GridNameCellService = inject(GridNameCellService);
  private readonly translateService: TranslateService = inject(TranslateService);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  params: WritableSignal<CustomCellParams> = signal(null);
  loading: WritableSignal<boolean> = signal(false);
  dataToDisplay: WritableSignal<string> = signal('');
  displayErrorMessage: WritableSignal<boolean> = signal(false);
  errorMessage: WritableSignal<string> = signal('N/A');

  constructor() {
    effect(() => {
      const params = this.params();
      if (!!params?.value) {
        this.loading.set(true);
        this.gridNameCellService
          .getGridById(params.value)
          .pipe(
            finalize(() => this.loading.set(false)),
            takeUntilDestroyed(this.destroyRef)
          )
          .subscribe({
            next: (grid: I4BGrid<I4BGridOptions, I4BGridData>) => {
              this.loading.set(false);
              this.dataToDisplay.set(grid.name);
            },
            error: () => {
              this.displayErrorMessage.set(true);
              this.errorMessage.set('N/A');
            }
          });
      } else {
        this.dataToDisplay.set(this.translateService.instant('FAVORITE_VIEW.DETAIL.DEFAULT_GRID'));
        this.loading.set(false);
      }
    });
  }

  agInit(params: CustomCellParams): void {
    this.params.set(params);
  }

  refresh(): boolean {
    return false;
  }
}
