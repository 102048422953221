import { Component, computed, effect, inject, Injector, input, OnInit, signal, Signal } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { LogsPopupComponent } from '@iot-platform/audit-trail';
import { AnalyticsService } from '@iot-platform/core';
import {
  ChipComponent,
  IotToolbarDispatchActionType,
  ManageTagsDialogComponent,
  OverviewCardComponent,
  PopupComponent,
  SectionHeaderAction,
  SectionHeaderActionsType,
  SectionHeaderComponent,
  SectionHeaderEvent
} from '@iot-platform/iot-platform-ui';
import { OverviewCard, OverviewCardActions, TagCategory, UserPreferences } from '@iot-platform/models/common';
import { Asset, AssetGroup, AssociationByAsset, AssociationByDevice, Site } from '@iot-platform/models/i4b';
import { PreferencesFacade } from '@iot-platform/users';
import { TranslateModule } from '@ngx-translate/core';
import { combineLatest } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';
import { SitesFacade } from '../../+state/facades/sites.facade';
import { CreateAssetGroupDialogComponent } from '../../../../components/create-asset-group-dialog/create-asset-group-dialog.component';
import { NavigationApi } from '../../../../containers/+state/navigation.api';
import { AssetsFacade } from '../../../assets/+state/facades/assets.facade';
import { AssetTemplateVariablesConfigurationFormComponent } from '../../../assets/components/asset-template-variables-configuration-form/asset-template-variables-configuration-form.component';
import { CreateAssetFromScratchDialogComponent } from '../../../assets/components/create-asset-from-scratch-dialog/create-asset-from-scratch-dialog.component';
import { CreateAssetFromTemplateDialogComponent } from '../../../assets/components/create-asset-from-template-dialog/create-asset-from-template-dialog.component';
import { AssociationByAssetComponent } from '../association-by-asset/association-by-asset.component';
import { AssociationByDeviceComponent } from '../association-by-device/association-by-device.component';
import { SiteInfoFormDialogComponent } from '../site-info-form-dialog/site-info-form-dialog.component';

@Component({
  selector: 'iot4bos-ui-site-info',
  imports: [
    OverviewCardComponent,
    SectionHeaderComponent,
    AssociationByAssetComponent,
    AssociationByDeviceComponent,
    RouterModule,
    TranslateModule,
    FlexLayoutModule
  ],
  templateUrl: './site-info.component.html',
  styleUrls: ['./site-info.component.scss']
})
export class SiteInfoComponent implements OnInit {
  private readonly navigationApi: NavigationApi = inject(NavigationApi);
  private readonly assetsFacade: AssetsFacade = inject(AssetsFacade);
  private readonly preferencesFacade: PreferencesFacade = inject(PreferencesFacade);
  private readonly sitesFacade: SitesFacade = inject(SitesFacade);
  private readonly activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private readonly router: Router = inject(Router);
  private readonly injector: Injector = inject(Injector);
  public readonly dialog: MatDialog = inject(MatDialog);

  analytic: AnalyticsService = new AnalyticsService('site_info_page');

  canUpdateSite = input<boolean>(false);
  canDeleteSite = input<boolean>(false);
  canCreateAsset = input<boolean>(false);
  canCreateDevice = input<boolean>(false);
  canReadAuditTrail = input<boolean>(false);
  canDisplayTags = input<boolean>(false);

  site: Signal<Site> = this.navigationApi.site;
  siteTags: Signal<TagCategory[]> = this.navigationApi.siteTags;
  isTagsLoaded: Signal<boolean | undefined> = this.navigationApi.siteTagsLoaded;
  preferences: Signal<UserPreferences> = this.preferencesFacade.preferences;
  siteIdFromUrl = this.activatedRoute.snapshot.paramMap.get('siteId');
  width = '50%';

  overviewCard: Signal<OverviewCard> = computed(() => {
    const site = this.site();
    const tags = this.siteTags();
    return this.getCardMetadata(site, tags);
  });

  viewByAssetAction: SectionHeaderAction = new SectionHeaderAction({
    action: IotToolbarDispatchActionType.VIEW_BY_ASSET,
    text: signal('IOT_DICTIONARY.VIEW_BY_ASSET')
  });

  viewByDeviceAction: SectionHeaderAction = new SectionHeaderAction({
    action: IotToolbarDispatchActionType.VIEW_BY_DEVICE,
    text: signal('IOT_DICTIONARY.VIEW_BY_DEVICE')
  });

  sectionHeaderTitle: Signal<string> = computed(() => {
    const preferences = this.preferences();
    return preferences.siteAssociationDisplay === 'assets' ? 'IOT_DICTIONARY.VIEW_BY_ASSET' : 'IOT_DICTIONARY.VIEW_BY_DEVICE';
  });

  sectionHeaderTitleActions: Signal<SectionHeaderAction[]> = computed(() => {
    const preferences = this.preferences();
    return preferences.siteAssociationDisplay === 'assets' ? [this.viewByDeviceAction] : [this.viewByAssetAction];
  });

  createAssetFromScratchAction: SectionHeaderAction = new SectionHeaderAction({
    action: IotToolbarDispatchActionType.CREATE,
    text: signal('ASSETS.SHELL.CREATE_ASSET'),
    disabled: computed(() => !this.canCreateAsset())
  });

  createAssetFromTemplateAction: SectionHeaderAction = new SectionHeaderAction({
    action: IotToolbarDispatchActionType.CREATE_FROM_TEMPLATE,
    text: signal('ASSETS.SHELL.CREATE_ASSET_FROM_TEMPLATE'),
    disabled: computed(() => !this.canCreateAsset())
  });

  createAssetGroupAction: SectionHeaderAction = new SectionHeaderAction({
    action: IotToolbarDispatchActionType.CREATE_ASSET_GROUP,
    text: signal('SITES.ASSOCIATIONS.CREATE_ASSET_GROUP'),
    disabled: computed(() => !this.canCreateAsset())
  });

  sectionHeaderActions: Signal<SectionHeaderAction[]> = signal([
    this.createAssetFromScratchAction,
    this.createAssetFromTemplateAction,
    this.createAssetGroupAction
  ]);

  SectionHeaderActionsType = SectionHeaderActionsType;

  associationByAsset: Signal<AssociationByAsset | null> = this.sitesFacade.associationByAsset;
  associationByAssetLoading: Signal<boolean> = this.sitesFacade.associationByAssetLoading;
  associationByDevice: Signal<AssociationByDevice | null> = this.sitesFacade.associationByDevice;
  associationByDeviceLoading: Signal<boolean> = this.sitesFacade.associationByDeviceLoading;

  ngOnInit() {
    effect(
      () => {
        this.sitesFacade.loadAssociationByAsset(this.siteIdFromUrl as string);
        this.sitesFacade.loadAssociationByDevice(this.siteIdFromUrl as string);
      },
      { injector: this.injector }
    );
  }

  onCardActions(actionType: OverviewCardActions): void {
    switch (actionType) {
      case OverviewCardActions.DELETE:
        this.onDeleteSite();
        break;
      case OverviewCardActions.DISPLAY_HISTORY:
        this.onOpenAuditTrail();
        break;
      default:
        break;
    }
  }

  onEditSite() {
    this.analytic.log('tab_site_info_actions', 'open_edit_site');
    this.dialog
      .open(SiteInfoFormDialogComponent, {
        width: '990px',
        disableClose: true,
        data: { site: this.site() }
      })
      .afterClosed()
      .subscribe((result: Site) => {
        if (result) {
          this.analytic.log('tab_site_info_actions', 'update_site');
          this.navigationApi.updateSite(result);
        }
      });
  }

  onDeleteSite() {
    this.analytic.log('tab_site_info_actions', 'open_delete_site');
    this.dialog
      .open(PopupComponent, {
        width: '500px',
        disableClose: true,
        data: { type: 'delete', value: this.site()?.name }
      })
      .afterClosed()
      .subscribe((result: boolean) => {
        if (result) {
          this.analytic.log('tab_site_info_actions', 'delete_site');
          this.navigationApi.deleteSite(this.site());
        }
      });
  }

  onOpenAuditTrail(): void {
    this.analytic.log('tab_site_info_actions', 'open_history');
    this.dialog.open(LogsPopupComponent, {
      data: { concept: 'site', elementId: this.site()?.id as string, elementName: this.site()?.name },
      disableClose: true,
      width: '900px'
    });
  }

  onManageTags() {
    this.analytic.log('tab_site_info_actions', 'open_manage_tags');
    this.dialog
      .open(ManageTagsDialogComponent, {
        width: '1230px',
        disableClose: true,
        data: {
          concepts: ['site'],
          selectedTags: this.siteTags,
          objectName: this.site()?.name,
          currentEntityId: this.site()?.entity?.id,
          withChildren: false,
          joinable: true,
          withParents: true,
          enforceMandatoryCategories: true
        }
      })
      .afterClosed()
      .subscribe((tags: TagCategory[]) => {
        if (tags) {
          this.analytic.log('tab_site_info_actions', 'manage_tags', `${tags.length} tags`);
          this.navigationApi.updateTagsBySiteId(this.site()?.id as string, tags);
        }
      });
  }

  onDispatchSectionHeaderEvent(event: SectionHeaderEvent): void {
    if (event.type === this.createAssetFromScratchAction.action) {
      this.onCreateAssetFromScratch();
    } else if (event.type === this.createAssetFromTemplateAction.action) {
      this.onCreateAssetFromTemplate();
    } else if (event.type === this.createAssetGroupAction.action) {
      this.onCreateAssetGroup();
    } else if (event.type === this.viewByAssetAction.action) {
      this.updateSiteAssociationDisplay('assets');
    } else if (event.type === this.viewByDeviceAction.action) {
      this.updateSiteAssociationDisplay('devices');
    }
  }

  onCreateAssetFromScratch(): void {
    // CREATE ASSET FROM SCRATCH
    this.dialog
      .open(CreateAssetFromScratchDialogComponent, {
        disableClose: true,
        data: { site: this.site() },
        width: '1100px'
      })
      .afterClosed()
      .pipe(filter((result) => result?.asset))
      .subscribe((result: { asset: Asset }) => {
        this.analytic.log('tab_site_info_actions', 'add_asset', 'classic');
        this.navigationApi.addAsset(result.asset);
      });
  }

  onCreateAssetFromTemplate(): void {
    const creationDialog = this.dialog
      .open(CreateAssetFromTemplateDialogComponent, {
        disableClose: true,
        data: { site: this.site() },
        width: '1100px'
      })
      .afterClosed()
      .pipe(filter((result) => result));

    // CREATE ASSET FROM TEMPLATE
    creationDialog.subscribe((result: { siteId: string; templateId: string; configureVariablesAfterCreation: boolean }) => {
      this.analytic.log('tab_site_info_actions', 'add_asset', 'from template');
      this.navigationApi.addAssetByTemplateId(result.templateId, result.siteId);
    });

    // CONFIGURE ASSET VARIABLES FROM TEMPLATE AFTER CREATION
    combineLatest([
      creationDialog.pipe(filter((result) => result.configureVariablesAfterCreation)),
      this.assetsFacade.assetCreationByTemplateStatuses$,
      this.navigationApi.assetVariables$,
      this.navigationApi.selectedAsset$
    ])
      .pipe(
        filter(
          ([_, assetCreationByTemplateStatuses, assetVariables, selectedAsset]) =>
            assetCreationByTemplateStatuses?.completed && !!assetVariables.length && selectedAsset?.id === assetVariables[0].asset?.id
        ),
        take(1),
        switchMap(([result, _, assetVariables, __]) =>
          this.dialog
            .open(AssetTemplateVariablesConfigurationFormComponent, {
              minWidth: '1300px',
              disableClose: true,
              data: { siteId: result.siteId, assetTemplateId: result.templateId, assetVariables }
            })
            .afterClosed()
        )
      )
      .subscribe((assetId: string) => {
        if (assetId) {
          this.navigationApi.loadAssetById(assetId);
          this.navigationApi.loadVariablesByAssetId(assetId);
        }
      });
  }

  onCreateAssetGroup(): void {
    this.dialog
      .open(CreateAssetGroupDialogComponent, {
        width: '600px',
        disableClose: true,
        data: { assetGroup: null, siteId: this.site()?.id as string }
      })
      .afterClosed()
      .pipe(filter((result) => result))
      .subscribe((result: AssetGroup) => {
        this.sitesFacade.addAssetGroup({ ...result, siteId: this.site()?.id as string });
      });
  }

  updateSiteAssociationDisplay(display: string): void {
    this.preferencesFacade.updateSiteAssociationDisplay(display);
  }

  onNavigateToAssetGroup(id: string): void {
    this.router.navigate(['asset-groups', id]);
  }

  onNavigateToAsset(id: string): void {
    this.navigationApi.selectAsset(this.site()?.id as string, id);
  }

  onNavigateToDevice(id: string): void {
    this.navigationApi.selectDevice(this.site()?.id as string, id);
  }

  private getCardMetadata(site: Site, tags: TagCategory[]): OverviewCard {
    return {
      name: site.name2 ? `${site.name} - ${site.name2}` : (site.name as string),
      image: site.imageUrl ?? 'assets/images/site/site_default.png',
      justifyContent: true,
      actions: [
        {
          label: 'SITES.CARD.DELETE',
          type: OverviewCardActions.DELETE,
          disabled: !this.canDeleteSite() || !this.site()?.allAssetsDecommissioned || !this.site()?.allDevicesDecommissioned
        },
        {
          label: 'AUDIT_TRAIL.CTA.HISTORY',
          type: OverviewCardActions.DISPLAY_HISTORY,
          disabled: !this.canReadAuditTrail()
        }
      ],
      sections: [
        //  GENERAL SECTION
        {
          title: 'IOT_DICTIONARY.GENERAL',
          collapsible: true,
          expanded: true,
          action: {
            type: OverviewCardActions.EDIT,
            icon: 'edit',
            tooltip: 'IOT_DICTIONARY.EDIT',
            disabled: !this.canUpdateSite(),
            dispatchEvent: () => this.onEditSite()
          },
          properties: [
            { label: 'IOT_DICTIONARY.ENTITY', value: site.entity?.name, width: this.width },
            { label: 'SITES.CARD.BUSINESS_ID', value: site.businessId, width: this.width },
            { label: 'SITES.CARD.TYPE', value: 'SITES.CARD.TYPES.' + site.type, width: this.width },
            { label: 'USERS.PROFILE_INFO_FORM.ADDRESS_COUNTRY', value: site.address?.country, width: this.width },
            { label: 'USERS.PROFILE_INFO_FORM.ADDRESS_STATE', value: site.address?.state, width: this.width },
            { label: 'USERS.PROFILE_INFO_FORM.ADDRESS_CITY', value: site.address?.city, width: this.width },
            { label: 'DETAIL_CARD_COMPONENT.ADDRESS_1', value: site.address?.address1, width: this.width },
            { label: 'DETAIL_CARD_COMPONENT.ADDRESS_2', value: site.address?.address2, width: this.width },
            { label: 'USERS.PROFILE_INFO_FORM.ADDRESS_ZIP_CODE', value: site.address?.zipCode, width: this.width },
            { label: 'IOT_DICTIONARY.DESCRIPTION', value: site.description, width: this.width }
          ]
        },
        //  TAGS SECTION
        {
          title: 'IOT_DICTIONARY.TAGS',
          collapsible: true,
          expanded: true,
          style: { 'margin-bottom': '10px' },
          action: {
            type: OverviewCardActions.EDIT,
            icon: 'edit',
            tooltip: 'IOT_DICTIONARY.EDIT',
            disabled: !this.canUpdateSite(),
            dispatchEvent: () => this.onManageTags()
          },
          properties: this.isTagsLoaded()
            ? tags?.length > 0
              ? tags?.map((tag) => ({
                  componentRef: {
                    ref: ChipComponent,
                    inputs: {
                      chip: tag,
                      chipButton: { display: false },
                      isTag: true
                    }
                  },
                  width: 'none'
                }))
              : [{ value: 'SITES.TABLE_CONTENT.EXPANDED_PANEL.NO_TAG', width: '100%' }]
            : undefined
        }
      ]
    };
  }
}
