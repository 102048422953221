import { Component, effect, inject, OnInit, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { InfoDisplayPipe } from '@iot-platform/pipes';
import { get } from 'lodash';
import { AbstractTableEngineCellComponent } from '../abstract-table-engine-cell.component';
import { ProductCellService } from './product-cell.service';

@Component({
  selector: 'i4b-table-engine-product-cell',
  templateUrl: './product-cell.component.html',
  styleUrls: ['./product-cell.component.scss'],
  imports: [InfoDisplayPipe, MatProgressSpinner]
})
export class ProductCellComponent extends AbstractTableEngineCellComponent<ProductCellComponent> implements OnInit {
  private readonly productCellService: ProductCellService = inject(ProductCellService);
  isDataLoaded: WritableSignal<boolean> = signal(false);
  dataToDisplay: WritableSignal<any> = signal(null);

  ngOnInit() {
    effect(
      () => {
        const data = this.data();
        const cellOptions = this.cellOptions();
        if (data) {
          this.productCellService
            .getProductById(data)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((product) => {
              this.isDataLoaded.set(true);
              if (product) {
                this.dataToDisplay.set(get(product, cellOptions.display));
              }
            });
        }
      },
      { injector: this.injector }
    );
  }
}
