import { Component, input } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIcon } from '@angular/material/icon';

import { FollowedVariable } from '@iot-platform/models/i4b';
import { DateFormatPipe, InfoDisplayPipe, NumberFormatPipe, ValueUnitFormatPipe } from '@iot-platform/pipes';

@Component({
  imports: [FlexLayoutModule, InfoDisplayPipe, DateFormatPipe, MatIcon, ValueUnitFormatPipe],
  providers: [InfoDisplayPipe, NumberFormatPipe, DateFormatPipe],
  selector: 'iot-platform-ui-detail-followed-variable',
  templateUrl: './detail-followed-variable.component.html',
  styleUrls: ['./detail-followed-variable.component.scss']
})
export class DetailFollowedVariableComponent {
  followedVariable = input<FollowedVariable>();
  followedNumber = input<number>();
}
