import { Component, inject } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AbstractControl, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TagLabel } from '@iot-platform/models/common';
import { TranslateModule } from '@ngx-translate/core';
import { NameValidators } from '@iot-platform/iot-platform-utils';

@Component({
    imports: [
        MatCardModule,
        MatToolbarModule,
        MatIconModule,
        MatFormFieldModule,
        TranslateModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        MatButtonModule,
        MatInputModule
    ],
    selector: 'iot-platform-ui-edit-tag-label-dialog',
    templateUrl: './edit-tag-label-dialog.component.html'
})
export class EditTagLabelDialogComponent {
  public matDialogRef: MatDialogRef<EditTagLabelDialogComponent> = inject(MatDialogRef);
  public data: { label: TagLabel; tagNameMaxLength: number; categoryLabels: TagLabel[] } = inject(MAT_DIALOG_DATA);

  form: UntypedFormGroup = new UntypedFormGroup({
    label: new UntypedFormControl(this.data.label.name, {
      validators: [
        Validators.maxLength(this.data.tagNameMaxLength),
        Validators.required,
        NameValidators.syncUniqueValueValidator(
          this.data.categoryLabels.map((l) => l.name),
          this.data.label.name
        )
      ]
    })
  });

  get label(): AbstractControl {
    return this.form.get('label') as AbstractControl;
  }

  save() {
    this.matDialogRef.close({ id: this.data.label.id, name: this.label.getRawValue()?.trim() });
  }
}
