import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { NgClass } from '@angular/common';
import { Component, signal, WritableSignal } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'grid-engine-last-command-status-cell',
    templateUrl: './last-command-status-cell.component.html',
    imports: [NgClass, TranslateModule],
    styleUrls: ['./last-command-status-cell.component.scss']
})
export class LastCommandStatusCellComponent implements ICellRendererAngularComp {
  params: WritableSignal<ICellRendererParams> = signal(null);

  agInit(params: ICellRendererParams): void {
    this.params.set(params);
  }

  refresh(): boolean {
    return false;
  }
}
