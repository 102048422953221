import { CdkDragPlaceholder } from '@angular/cdk/drag-drop';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, ContentChild } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DragDropContentComponent } from '../drag-drop-content/drag-drop-content.component';
import { DragDropIndicatorComponent } from '../drag-drop-indicator/drag-drop-indicator.component';
import { DragDropMetaComponent } from '../drag-drop-meta/drag-drop-meta.component';
import { DragDropPlaceholderComponent } from '../drag-drop-placeholder/drag-drop-placeholder.component';

@Component({
  selector: 'iot-platform-ui-drag-drop-element',
  imports: [FlexLayoutModule, CdkDragPlaceholder, NgTemplateOutlet, NgClass],
  templateUrl: './drag-drop-element.component.html',
  styleUrl: './drag-drop-element.component.scss'
})
export class DragDropElementComponent {
  @ContentChild(DragDropPlaceholderComponent) public dragPlaceholder!: DragDropPlaceholderComponent;
  @ContentChild(DragDropMetaComponent) public dragMeta!: DragDropMetaComponent;
  @ContentChild(DragDropIndicatorComponent) public dragIndicator!: DragDropIndicatorComponent;
  @ContentChild(DragDropContentComponent) public dragContent!: DragDropContentComponent;
}
