import { Component, computed, inject, Signal } from '@angular/core';
import { InfoDisplayPipe } from '@iot-platform/pipes';
import { TranslateService } from '@ngx-translate/core';
import { get } from 'lodash';
import { ArrayOfObjectsCellComponent } from '../array-of-objects-cell/array-of-objects-cell.component';

@Component({
    imports: [InfoDisplayPipe],
    providers: [InfoDisplayPipe],
    selector: 'grid-engine-audit-trail-attributes-cell',
    templateUrl: './audit-trail-attributes-cell.component.html',
    styleUrls: ['./audit-trail-attributes-cell.component.scss']
})
export class AuditTrailAttributesCellComponent extends ArrayOfObjectsCellComponent {
  private readonly translateService: TranslateService = inject(TranslateService);
  value: Signal<any> = computed(() => {
    const params = this.params();
    const concept = get(params, ['data', 'concept']);
    if (!params?.value?.length && concept) {
      return this.translateService.instant(`AUDIT_TRAIL.HISTORY_LOG_POPUP.TITLE_CONCEPTS.${concept?.toUpperCase()}`);
    }
    return this.dataToDisplay();
  });
}
