import { Component, effect, input, output, signal, WritableSignal } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { Pagination } from '@iot-platform/models/common';
import { NumberFormatPipe } from '@iot-platform/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { get } from 'lodash';

@Component({
  selector: 'grid-engine-paginator',
  templateUrl: './paginator.component.html',
  imports: [FlexLayoutModule, MatIcon, MatIconButton, TranslateModule, NumberFormatPipe],
  providers: [NumberFormatPipe],
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent {
  pagination = input<any>();

  pageChange = output<Pagination>();

  currentPage: WritableSignal<number> = signal(0);

  constructor() {
    effect(() => {
      const pagination = this.pagination();
      this.currentPage.set(get(pagination, ['currentPage'], 0));
    });
  }

  navigateFirst() {
    const pagination = this.pagination();
    this.changePagination({ ...pagination, currentPage: 0 });
  }

  navigatePrevious() {
    const pagination = this.pagination();
    const currentPage = this.currentPage();
    this.changePagination({ ...pagination, currentPage: currentPage - 1 });
  }

  navigateNext() {
    const pagination = this.pagination();
    const currentPage = this.currentPage();
    this.changePagination({ ...pagination, currentPage: currentPage + 1 });
  }

  navigateLast() {
    const pagination = this.pagination();
    this.changePagination({ ...pagination, currentPage: pagination?.maxPage });
  }

  changePagination(pagination: Pagination) {
    this.currentPage.set(pagination.currentPage);
    this.pageChange.emit(pagination);
  }

  getTotalElements(): number {
    const pagination = this.pagination();
    if (pagination && !pagination.hasMore) {
      return pagination.total < pagination.limit * (pagination.currentPage + 1) ? pagination.total : pagination.limit;
    } else {
      return this.getRealLimit();
    }
  }

  getRealLimit() {
    const pagination = this.pagination();
    return (pagination.currentPage + 1) * pagination.limit;
  }

  getPaginationFooter() {
    const pagination = this.pagination();
    return pagination ? `${pagination.currentPage * pagination.limit + 1} - ${this.getTotalElements()} of ${pagination.total}` : '';
  }
}
