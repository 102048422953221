import { Component, computed, inject, Signal } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateService } from '@ngx-translate/core';
import { AbstractTableEngineCellComponent } from '../abstract-table-engine-cell.component';

@Component({
  selector: 'i4b-table-engine-active-events-cell',
  templateUrl: './active-events-cell.component.html',
  imports: [MatTooltip, MatIcon]
})
export class ActiveEventsCellComponent extends AbstractTableEngineCellComponent<ActiveEventsCellComponent> {
  private readonly translateService: TranslateService = inject(TranslateService);
  tooltip: Signal<string> = computed(() => {
    const data = this.data();
    const key = data && data > 1 ? 'ASSETS.VARIABLE.TOOLTIPS.ACTIVE_EVENTS' : 'ASSETS.VARIABLE.TOOLTIPS.ACTIVE_EVENT';
    return this.translateService.instant(key, {
      totalEvents: data
    });
  });
}
