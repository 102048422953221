import { BaseState, Filter, INITIAL_BASE_STATE, Pagination, TagCategory } from '@iot-platform/models/common';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createFeature, createReducer, on } from '@ngrx/store';
import { AdminBusinessProfilesTagsActions } from '../actions/business-profiles-tags.actions';
import { AdminBusinessProfilesActions } from '../actions/business-profiles.actions';

export type State = BaseState<TagCategory, Pagination, Filter>;

function sortByName(a: TagCategory, b: TagCategory): number {
  return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0;
}

export const adapter: EntityAdapter<TagCategory> = createEntityAdapter<TagCategory>({
  selectId: (tagCategory: TagCategory) => tagCategory.id as string,
  sortComparer: sortByName
});

export const initialState: State = adapter.getInitialState({
  ...INITIAL_BASE_STATE,
  pagination: { currentPage: 0, total: 0, maxPage: 0, hasMore: false, limit: 1000 }
});

export const adminBusinessProfilesTagsFeature = createFeature({
  name: 'adminBusinessProfilesTags',
  reducer: createReducer(
    initialState,
    on(
      AdminBusinessProfilesActions.selectOne,
      AdminBusinessProfilesTagsActions.loadTagsByBusinessProfile,
      AdminBusinessProfilesTagsActions.updateTagsForBusinessProfile,
      (state) => adapter.removeAll(state)
    ),
    on(AdminBusinessProfilesTagsActions.loadTagsByBusinessProfileSuccess, (state, { tags }) => adapter.setAll(tags, state)),
    on(AdminBusinessProfilesTagsActions.updateTagsForBusinessProfileSuccess, (state, { addedTags }) => adapter.setAll(addedTags, state)),

    on(AdminBusinessProfilesTagsActions.loadTagsByBusinessProfile, AdminBusinessProfilesTagsActions.updateTagsForBusinessProfile, (state) => ({
      ...state,
      loading: true,
      loaded: false
    })),
    on(AdminBusinessProfilesTagsActions.loadTagsByBusinessProfileSuccess, AdminBusinessProfilesTagsActions.updateTagsForBusinessProfileSuccess, (state) => ({
      ...state,
      loading: false,
      loaded: true
    })),
    on(
      AdminBusinessProfilesTagsActions.loadTagsByBusinessProfileFailure,
      AdminBusinessProfilesTagsActions.updateTagsForBusinessProfileFailure,
      (state, { error }) => ({ ...state, error, loading: false, loaded: false })
    )
  )
});
