import { I4BCellType, I4BColumnConfiguration } from './configurations';
import { getDefaultColumnsByConcept } from './default-grids.definitions';
import {
  AssetFollowedVariableGroupColumn,
  AssetFollowedVariableWithCommentGroupColumn,
  AssetNameColumn,
  AssetVariableGroupColumn,
  CreatedGroupColumn,
  DescriptionColumn,
  I4BAssetButtonColumn,
  I4BBasicColumn,
  I4BColumn,
  I4BSelectionColumn,
  I4BStatusColumn,
  IdColumn,
  NameColumn,
  SiteAddressColumn,
  TotalColumn,
  TotalEventsColumn,
  TotalTagsColumn,
  UpdatedGroupColumn
} from './definitions';
import { I4bCellConcept } from './enums';
import { AssetsGridData, HeaderType, I4BColumnHeader, I4BGrid, I4BGridOptions } from './models';
import { I4BColumnOptions } from './options';

export const allVariableNames: string[] = [
  'LEVEL',
  'PRESSURE',
  'CUMUL_LEVEL',
  'VAPORIZATION_TEMPERATURE',
  'LEFT_PRESSURE',
  'RIGHT_PRESSURE',
  'LEFT_VOLUME',
  'RIGHT_VOLUME',
  'CUMUL_PRESSURE',
  'LEFT_WEIGHT',
  'RIGHT_WEIGHT',
  'CUMUL_WEIGHT',
  'SHUTDOWN_STATUS',
  'MONTHLY_SHUTDOWN_COUNT',
  'MONTHLY_SHUTDOWN_TIME',
  'PRODUCTION_VALVE',
  'MONTHLY_PRODUCTION_TIME',
  'MONTHLY_PRODUCTION_DOWNTIME',
  'CONTRACTUAL_FLOW',
  'PRODUCTION_FLOW',
  'TOTAL_FLOW',
  'SHORTFALL',
  'MONTHLY_SHORTFALL',
  'BACKUP_TANK_1',
  'BACKUP_TANK_2',
  'BACKUP_TANK_3',
  'BACKUP_TANK_4',
  'BACKUP_TANK_5',
  'BACKUP_TANK_6',
  'CONSUMPTION',
  'CO2_RATE',
  'CO_RATE',
  'AUTONOMY',
  'NETWORK_PRESSURE',
  'DEW_POINT',
  'EMERGENCY_PRESSURE',
  'BACKUP_PG_1',
  'BACKUP_PG_2',
  'PRODUCT_BL_PRESSURE',
  'PRODUCT_BL_PURITY',
  'PRODUCT_BL_TEMPERATURE',
  'PERSONNEL_ON_SITE',
  'RUN_STEP',
  'PRODUCTION_PURITY_A',
  'PRODUCTION_PURITY_B',
  'COMPRESSED_AIR_FLOW',
  'LEFT_AUTONOMY',
  'RIGHT_AUTONOMY',
  'ASSET_AUTONOMY',
  'ASSET_AUTONOMY_STATUS',
  'LEFT_MINIMUM_PRESSURE',
  'RIGHT_MINIMUM_PRESSURE',
  'LEVEL_DP'
].sort((a, b) => (a > b ? 1 : -1));

export const FOLLOWED_VARIABLES_COLUMNS_FOR_ASSETS_VIEW: AssetFollowedVariableGroupColumn[] = [
  new AssetFollowedVariableGroupColumn(
    {},
    {
      id: '1',
      isDefault: true,
      rowGroupParams: {
        name: {
          isConfigurable: false,
          enabled: true,
          rowGroup: false,
          hiddenWhenGrouping: true
        },
        value: {
          isConfigurable: false,
          enabled: true,
          rowGroup: false,
          hiddenWhenGrouping: true
        },
        dateTime: {
          isConfigurable: false,
          enabled: true,
          rowGroup: false,
          hiddenWhenGrouping: true
        },
        threshold1: {
          isConfigurable: false,
          enabled: true,
          rowGroup: false,
          hiddenWhenGrouping: true
        },
        threshold2: {
          isConfigurable: false,
          enabled: true,
          rowGroup: false,
          hiddenWhenGrouping: true
        },
        threshold3: {
          isConfigurable: false,
          enabled: true,
          rowGroup: false,
          hiddenWhenGrouping: true
        },
        threshold4: {
          isConfigurable: false,
          enabled: true,
          rowGroup: false,
          hiddenWhenGrouping: true
        },
        threshold5: {
          isConfigurable: false,
          enabled: true,
          rowGroup: false,
          hiddenWhenGrouping: true
        }
      }
    },
    { order: 20 }
  ),
  new AssetFollowedVariableGroupColumn(
    {},
    {
      id: '2',
      rowGroupParams: {
        name: {
          isConfigurable: false,
          enabled: true,
          rowGroup: false,
          hiddenWhenGrouping: true
        },
        value: {
          isConfigurable: false,
          enabled: true,
          rowGroup: false,
          hiddenWhenGrouping: true
        },
        dateTime: {
          isConfigurable: false,
          enabled: true,
          rowGroup: false,
          hiddenWhenGrouping: true
        },
        threshold1: {
          isConfigurable: false,
          enabled: true,
          rowGroup: false,
          hiddenWhenGrouping: true
        },
        threshold2: {
          isConfigurable: false,
          enabled: true,
          rowGroup: false,
          hiddenWhenGrouping: true
        },
        threshold3: {
          isConfigurable: false,
          enabled: true,
          rowGroup: false,
          hiddenWhenGrouping: true
        },
        threshold4: {
          isConfigurable: false,
          enabled: true,
          rowGroup: false,
          hiddenWhenGrouping: true
        },
        threshold5: {
          isConfigurable: false,
          enabled: true,
          rowGroup: false,
          hiddenWhenGrouping: true
        }
      }
    },
    {}
  ),
  new AssetFollowedVariableGroupColumn(
    {},
    {
      id: '3',
      rowGroupParams: {
        name: {
          isConfigurable: false,
          enabled: true,
          rowGroup: false,
          hiddenWhenGrouping: true
        },
        value: {
          isConfigurable: false,
          enabled: true,
          rowGroup: false,
          hiddenWhenGrouping: true
        },
        dateTime: {
          isConfigurable: false,
          enabled: true,
          rowGroup: false,
          hiddenWhenGrouping: true
        },
        threshold1: {
          isConfigurable: false,
          enabled: true,
          rowGroup: false,
          hiddenWhenGrouping: true
        },
        threshold2: {
          isConfigurable: false,
          enabled: true,
          rowGroup: false,
          hiddenWhenGrouping: true
        },
        threshold3: {
          isConfigurable: false,
          enabled: true,
          rowGroup: false,
          hiddenWhenGrouping: true
        },
        threshold4: {
          isConfigurable: false,
          enabled: true,
          rowGroup: false,
          hiddenWhenGrouping: true
        },
        threshold5: {
          isConfigurable: false,
          enabled: true,
          rowGroup: false,
          hiddenWhenGrouping: true
        }
      }
    },
    {}
  ),
  new AssetFollowedVariableWithCommentGroupColumn(
    {},
    {
      id: '1',
      rowGroupParams: {
        name: {
          isConfigurable: false,
          enabled: true,
          rowGroup: false,
          hiddenWhenGrouping: true
        },
        value: {
          isConfigurable: false,
          enabled: true,
          rowGroup: false,
          hiddenWhenGrouping: true
        },
        dateTime: {
          isConfigurable: false,
          enabled: true,
          rowGroup: false,
          hiddenWhenGrouping: true
        },
        threshold1: {
          isConfigurable: false,
          enabled: true,
          rowGroup: false,
          hiddenWhenGrouping: true
        },
        threshold2: {
          isConfigurable: false,
          enabled: true,
          rowGroup: false,
          hiddenWhenGrouping: true
        },
        threshold3: {
          isConfigurable: false,
          enabled: true,
          rowGroup: false,
          hiddenWhenGrouping: true
        },
        threshold4: {
          isConfigurable: false,
          enabled: true,
          rowGroup: false,
          hiddenWhenGrouping: true
        },
        threshold5: {
          isConfigurable: false,
          enabled: true,
          rowGroup: false,
          hiddenWhenGrouping: true
        }
      }
    },
    {}
  ),
  new AssetFollowedVariableWithCommentGroupColumn(
    {},
    {
      id: '2',
      rowGroupParams: {
        name: {
          isConfigurable: false,
          enabled: true,
          rowGroup: false,
          hiddenWhenGrouping: true
        },
        value: {
          isConfigurable: false,
          enabled: true,
          rowGroup: false,
          hiddenWhenGrouping: true
        },
        dateTime: {
          isConfigurable: false,
          enabled: true,
          rowGroup: false,
          hiddenWhenGrouping: true
        },
        threshold1: {
          isConfigurable: false,
          enabled: true,
          rowGroup: false,
          hiddenWhenGrouping: true
        },
        threshold2: {
          isConfigurable: false,
          enabled: true,
          rowGroup: false,
          hiddenWhenGrouping: true
        },
        threshold3: {
          isConfigurable: false,
          enabled: true,
          rowGroup: false,
          hiddenWhenGrouping: true
        },
        threshold4: {
          isConfigurable: false,
          enabled: true,
          rowGroup: false,
          hiddenWhenGrouping: true
        },
        threshold5: {
          isConfigurable: false,
          enabled: true,
          rowGroup: false,
          hiddenWhenGrouping: true
        }
      }
    },
    {}
  ),
  new AssetFollowedVariableWithCommentGroupColumn(
    {},
    {
      id: '3',
      rowGroupParams: {
        name: {
          isConfigurable: false,
          enabled: true,
          rowGroup: false,
          hiddenWhenGrouping: true
        },
        value: {
          isConfigurable: false,
          enabled: true,
          rowGroup: false,
          hiddenWhenGrouping: true
        },
        dateTime: {
          isConfigurable: false,
          enabled: true,
          rowGroup: false,
          hiddenWhenGrouping: true
        },
        threshold1: {
          isConfigurable: false,
          enabled: true,
          rowGroup: false,
          hiddenWhenGrouping: true
        },
        threshold2: {
          isConfigurable: false,
          enabled: true,
          rowGroup: false,
          hiddenWhenGrouping: true
        },
        threshold3: {
          isConfigurable: false,
          enabled: true,
          rowGroup: false,
          hiddenWhenGrouping: true
        },
        threshold4: {
          isConfigurable: false,
          enabled: true,
          rowGroup: false,
          hiddenWhenGrouping: true
        },
        threshold5: {
          isConfigurable: false,
          enabled: true,
          rowGroup: false,
          hiddenWhenGrouping: true
        }
      }
    },
    {}
  )
];

export const VARIABLES_GROUPED_COLUMNS_FOR_ASSETS_VIEW: AssetVariableGroupColumn[] = allVariableNames.map(
  (varName) =>
    new AssetVariableGroupColumn(
      { displayName: varName },
      {
        id: varName.toLowerCase(),
        isConfigurable: false,
        width: 80,
        rowGroupParams: {
          value: {
            isConfigurable: false,
            enabled: true,
            rowGroup: false,
            hiddenWhenGrouping: true
          },
          dateTime: {
            isConfigurable: false,
            enabled: true,
            rowGroup: false,
            hiddenWhenGrouping: true
          },
          threshold1: {
            isConfigurable: false,
            enabled: true,
            rowGroup: false,
            hiddenWhenGrouping: true
          },
          threshold2: {
            isConfigurable: false,
            enabled: true,
            rowGroup: false,
            hiddenWhenGrouping: true
          },
          threshold3: {
            isConfigurable: false,
            enabled: true,
            rowGroup: false,
            hiddenWhenGrouping: true
          },
          threshold4: {
            isConfigurable: false,
            enabled: true,
            rowGroup: false,
            hiddenWhenGrouping: true
          },
          threshold5: {
            isConfigurable: false,
            enabled: true,
            rowGroup: false,
            hiddenWhenGrouping: true
          }
        }
      },
      { sortable: true }
    )
);

export const DEFAULT_SITES_COLUMNS_FOR_ASSETS_VIEW = [
  new NameColumn(
    { displayName: 'SITE NAME', type: HeaderType.ICON_AND_TEXT, icon: 'site' },
    {
      id: 'site.name',
      sortProperty: 'site.name',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.SITE_NAME',
      concept: I4bCellConcept.SITES,
      isDefault: true,
      isConfigurable: true,
      isLink: true,
      cell: { type: I4BCellType.BASIC_LINK, event: { type: 'navigateToSite' } },
      rowGroupParams: {
        isConfigurable: false,
        enabled: true,
        rowGroup: false,
        hiddenWhenGrouping: true
      }
    },
    { order: 4 }
  ),
  new NameColumn(
    { displayName: 'SITE NAME 2', type: HeaderType.ICON_AND_TEXT, icon: 'site' },
    {
      id: 'site.name2',
      sortProperty: 'site.name2',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.SITE_NAME',
      concept: I4bCellConcept.SITES,
      isDefault: false,
      isLink: true,
      cell: { type: I4BCellType.BASIC_LINK, event: { type: 'navigateToSite' } },
      rowGroupParams: {
        isConfigurable: false,
        enabled: true,
        rowGroup: false,
        hiddenWhenGrouping: true
      }
    },
    { order: 5 }
  ),
  new IdColumn(
    { displayName: 'SITE BUSINESS ID' },
    {
      id: 'site.businessId',
      concept: I4bCellConcept.SITES,
      width: 170,
      rowGroupParams: {
        isConfigurable: false,
        enabled: true,
        rowGroup: false,
        hiddenWhenGrouping: true
      }
    }
  ),
  new SiteAddressColumn(
    {},
    {
      id: 'site.address',
      concept: I4bCellConcept.SITES,
      rowGroupParams: {
        address1: {
          isConfigurable: false,
          enabled: true,
          rowGroup: false,
          hiddenWhenGrouping: true
        },
        address2: {
          isConfigurable: false,
          enabled: true,
          rowGroup: false,
          hiddenWhenGrouping: true
        },
        zipCode: {
          isConfigurable: false,
          enabled: true,
          rowGroup: false,
          hiddenWhenGrouping: true
        },
        state: {
          isConfigurable: false,
          enabled: true,
          rowGroup: false,
          hiddenWhenGrouping: true
        },
        city: {
          isConfigurable: false,
          enabled: true,
          rowGroup: false,
          hiddenWhenGrouping: true
        },
        country: {
          isConfigurable: false,
          enabled: true,
          rowGroup: false,
          hiddenWhenGrouping: true
        }
      }
    },
    {}
  ),
  new I4BBasicColumn(
    { displayName: 'ADDRESS1' },
    {
      id: 'site.address.address1',
      width: 140,
      concept: I4bCellConcept.SITES,
      isDefault: false,
      rowGroupParams: {
        isConfigurable: false,
        enabled: true,
        rowGroup: false,
        hiddenWhenGrouping: true
      }
    },
    {}
  ),
  new I4BBasicColumn(
    { displayName: 'ADDRESS2' },
    {
      id: 'site.address.address2',
      width: 140,
      concept: I4bCellConcept.SITES,
      isDefault: false,
      rowGroupParams: {
        isConfigurable: false,
        enabled: true,
        rowGroup: false,
        hiddenWhenGrouping: true
      }
    },
    {}
  ),
  new I4BBasicColumn(
    { displayName: 'ZIP CODE' },
    {
      id: 'site.address.zipCode',
      width: 120,
      concept: I4bCellConcept.SITES,
      isDefault: false,
      rowGroupParams: {
        isConfigurable: false,
        enabled: true,
        rowGroup: false,
        hiddenWhenGrouping: true
      }
    },
    {}
  ),
  new I4BBasicColumn(
    { displayName: 'STATE' },
    {
      id: 'site.address.state',
      width: 120,
      concept: I4bCellConcept.SITES,
      isDefault: false,
      rowGroupParams: {
        isConfigurable: false,
        enabled: true,
        rowGroup: false,
        hiddenWhenGrouping: true
      }
    },
    {}
  ),
  new I4BBasicColumn(
    { displayName: 'CITY' },
    {
      id: 'site.address.city',
      width: 120,
      concept: I4bCellConcept.SITES,
      isDefault: false,
      rowGroupParams: {
        isConfigurable: false,
        enabled: true,
        rowGroup: false,
        hiddenWhenGrouping: true
      }
    },
    {}
  ),
  new I4BBasicColumn(
    { displayName: 'COUNTRY' },
    {
      id: 'site.address.country',
      width: 120,
      concept: I4bCellConcept.SITES,
      isDefault: false,
      cell: { type: I4BCellType.TRANSLATED_CELL, options: { translateKey: 'SITES.CARD.COUNTRIES.' } },
      rowGroupParams: {
        isConfigurable: false,
        enabled: true,
        rowGroup: false,
        hiddenWhenGrouping: true
      }
    },
    {}
  ),
  new I4BBasicColumn(
    { displayName: 'SITE TYPE' },
    {
      id: 'site.type',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.SITE_TYPE',
      cell: { type: I4BCellType.TRANSLATED_CELL, options: { translateKey: 'SITES.CARD.TYPES.' } },
      isDefault: false,
      sortProperty: 'site.type',
      concept: I4bCellConcept.SITES,
      width: 120,
      rowGroupParams: {
        isConfigurable: false,
        enabled: true,
        rowGroup: false,
        hiddenWhenGrouping: true
      }
    },
    {
      order: 12
    }
  ),
  new I4BBasicColumn(
    { displayName: 'DESCRIPTION' },
    {
      id: 'site.description',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DESCRIPTION',
      cell: { type: I4BCellType.BASIC },
      isDefault: false,
      sortProperty: 'site.description',
      concept: I4bCellConcept.SITES,
      width: 140,
      rowGroupParams: {
        isConfigurable: false,
        enabled: true,
        rowGroup: false,
        hiddenWhenGrouping: true
      }
    },
    {
      order: 12
    }
  )
];

export const DEFAULT_ASSETS_COLUMNS_FOR_ASSETS_VIEW: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] = [
  new AssetNameColumn(
    {},
    {
      width: 160,
      rowGroupParams: {
        isConfigurable: false,
        enabled: true,
        rowGroup: false,
        hiddenWhenGrouping: true
      }
    },
    { order: 0 }
  ),
  new I4BBasicColumn(
    { displayName: 'ASSET GROUP' },
    {
      id: 'assetGroup.name',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.ASSET_GROUP',
      isDefault: true,
      isConfigurable: true,
      concept: I4bCellConcept.ASSETS,
      cell: { type: I4BCellType.BASIC_LINK, event: { type: 'navigateToAssetGroup' } },
      autoSize: true,
      rowGroupParams: {
        isConfigurable: false,
        enabled: true,
        rowGroup: false,
        hiddenWhenGrouping: true,
        blankLabel: 'MASTER_VIEW_ENGINE.TABLE.ROW_GROUP.NO_ASSET_GROUPS'
      }
    },
    {
      order: 1
    }
  ),
  new I4BBasicColumn(
    { displayName: 'SHIPTO' },
    {
      id: 'erpReference.shipTo',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.SHIP_TO',
      isDefault: true,
      isConfigurable: true,
      concept: I4bCellConcept.ASSETS,
      width: 110,
      rowGroupParams: {
        isConfigurable: false,
        enabled: true,
        rowGroup: false,
        hiddenWhenGrouping: true
      }
    },
    {
      order: 2
    }
  ),
  new I4BStatusColumn(
    { displayName: 'STATUS' },
    {
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.STATUS',
      id: 'status.name',
      isDefault: true,
      concept: I4bCellConcept.ASSETS,
      width: 150,
      cell: {
        type: I4BCellType.TRANSLATED_CELL,
        options: {
          withStatusPadding: true,
          class: {
            production: { color: '#262836', 'background-color': 'rgba(164, 255, 0, 0.1)', padding: '9px' },
            maintenance: { color: '#262836', 'background-color': 'rgba(0, 121, 255, 0.1)', padding: '9px' },
            decommissioned: { color: '#262836', 'background-color': 'rgba(255, 0, 99, 0.1)', padding: '9px' }
          }
        }
      },
      rowGroupParams: {
        isConfigurable: false,
        enabled: true,
        rowGroup: false,
        hiddenWhenGrouping: true
      }
    },
    {
      order: 3
    }
  ),
  new I4BBasicColumn(
    { displayName: 'ASSET BUSINESS ID' },
    {
      id: 'businessId',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.BUSINESS_ID',
      cell: { type: I4BCellType.BASIC_LINK, event: { type: 'navigateToAsset' } },
      isDefault: false,
      concept: I4bCellConcept.ASSETS,
      width: 170,
      rowGroupParams: {
        isConfigurable: false,
        enabled: true,
        rowGroup: false,
        hiddenWhenGrouping: true
      }
    }
  ),
  new DescriptionColumn(
    {},
    {
      concept: I4bCellConcept.ASSETS,
      isDefault: false,
      width: 140,
      rowGroupParams: {
        isConfigurable: false,
        enabled: true,
        rowGroup: false,
        hiddenWhenGrouping: true
      }
    },
    {}
  ),
  new I4BBasicColumn(
    { displayName: 'ASSET STATUS DATETIME' },
    {
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.STATUS_DATETIME',
      id: 'status.datetime',
      cell: { type: I4BCellType.DATE },
      isDefault: false,
      concept: I4bCellConcept.ASSETS,
      width: 200,
      rowGroupParams: {
        isConfigurable: false,
        enabled: true,
        rowGroup: false,
        hiddenWhenGrouping: true
      }
    }
  ),
  new I4BBasicColumn(
    {
      displayName: 'PRODUCT ID 1'
    },
    {
      id: 'product1.identifier',
      sortProperty: 'product1.id',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.PRODUCT_ID_1',
      concept: I4bCellConcept.ASSETS,
      width: 150,
      isDefault: false,
      rowGroupParams: {
        isConfigurable: false,
        enabled: true,
        rowGroup: false,
        hiddenWhenGrouping: true
      }
    },
    {}
  ),
  new I4BBasicColumn(
    {
      displayName: 'PRODUCT NAME 1'
    },
    {
      id: 'product1.name',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.PRODUCT_NAME_1',
      concept: I4bCellConcept.ASSETS,
      width: 160,
      isDefault: false,
      rowGroupParams: {
        isConfigurable: false,
        enabled: true,
        rowGroup: false,
        hiddenWhenGrouping: true
      }
    },
    {}
  ),
  new I4BBasicColumn(
    {
      displayName: 'PRODUCT CATALOG 1'
    },
    {
      id: 'product1.catalog.name',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.PRODUCT_CATALOG_NAME_1',
      concept: I4bCellConcept.ASSETS,
      width: 170,
      isDefault: false,
      rowGroupParams: {
        isConfigurable: false,
        enabled: true,
        rowGroup: false,
        hiddenWhenGrouping: true
      }
    },
    {}
  ),
  new I4BBasicColumn(
    {
      displayName: 'PRODUCT ID 2'
    },
    {
      id: 'product2.identifier',
      sortProperty: 'product2.id',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.PRODUCT_ID_2',
      concept: I4bCellConcept.ASSETS,
      width: 150,
      isDefault: false,
      rowGroupParams: {
        isConfigurable: false,
        enabled: true,
        rowGroup: false,
        hiddenWhenGrouping: true
      }
    },
    {}
  ),
  new I4BBasicColumn(
    {
      displayName: 'PRODUCT NAME 2'
    },
    {
      id: 'product2.name',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.PRODUCT_NAME_2',
      concept: I4bCellConcept.ASSETS,
      width: 160,
      isDefault: false,
      rowGroupParams: {
        isConfigurable: false,
        enabled: true,
        rowGroup: false,
        hiddenWhenGrouping: true
      }
    },
    {}
  ),
  new I4BBasicColumn(
    {
      displayName: 'PRODUCT CATALOG 2'
    },
    {
      id: 'product2.catalog.name',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.PRODUCT_CATALOG_NAME_2',
      concept: I4bCellConcept.ASSETS,
      width: 170,
      isDefault: false,
      rowGroupParams: {
        isConfigurable: false,
        enabled: true,
        rowGroup: false,
        hiddenWhenGrouping: true
      }
    },
    {}
  ),
  new I4BBasicColumn(
    {
      displayName: 'DELIVERY DATE'
    },
    {
      id: 'deliveryDate',
      sortProperty: 'deliveryDate',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DELIVERY_DATE',
      concept: I4bCellConcept.ASSETS,
      isDefault: false,
      cell: {
        type: I4BCellType.DATE
      },
      rowGroupParams: {
        isConfigurable: false,
        enabled: true,
        rowGroup: false,
        hiddenWhenGrouping: true
      }
    },
    {}
  ),
  new I4BBasicColumn(
    {
      displayName: 'QUANTITY 1'
    },
    {
      id: 'quantity1',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.QUANTITY_1',
      concept: I4bCellConcept.ASSETS,
      width: 130,
      isDefault: false,
      rowGroupParams: {
        isConfigurable: false,
        enabled: true,
        rowGroup: false,
        hiddenWhenGrouping: true
      }
    },
    {}
  ),
  new I4BBasicColumn(
    {
      displayName: 'QUANTITY 2'
    },
    {
      id: 'quantity2',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.QUANTITY_2',
      concept: I4bCellConcept.ASSETS,
      width: 130,
      isDefault: false,
      rowGroupParams: {
        isConfigurable: false,
        enabled: true,
        rowGroup: false,
        hiddenWhenGrouping: true
      }
    },
    {}
  ),
  new I4BBasicColumn(
    {
      displayName: 'ENTITY NAME'
    },
    {
      id: 'entity.name',
      sortProperty: 'entity.name',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.ENTITY_NAME',
      concept: I4bCellConcept.ASSETS,
      width: 140,
      isDefault: true,
      rowGroupParams: {
        isConfigurable: false,
        enabled: true,
        rowGroup: false,
        hiddenWhenGrouping: true
      }
    },
    { order: 5 }
  ),
  new TotalEventsColumn(
    { displayName: 'TOTAL ASSET EVENTS' },
    {
      id: 'totalActiveEvents',
      concept: I4bCellConcept.ASSETS,
      isDefault: true,
      rowGroupParams: {
        isConfigurable: false,
        enabled: true,
        rowGroup: false,
        hiddenWhenGrouping: true
      }
    },
    { order: 21 }
  ),
  new TotalTagsColumn(
    {},
    {
      concept: I4bCellConcept.ASSETS,
      isDefault: true,
      rowGroupParams: {
        isConfigurable: false,
        enabled: true,
        rowGroup: false,
        hiddenWhenGrouping: true
      }
    },
    { order: 22 }
  ),
  new TotalColumn(
    { displayName: 'TOTAL VARIABLES', icon: 'variable' },
    {
      id: 'totalVariables',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.QUANTITY_2',
      concept: I4bCellConcept.ASSETS,
      isDefault: false,
      rowGroupParams: {
        isConfigurable: false,
        enabled: true,
        rowGroup: false,
        hiddenWhenGrouping: true
      }
    },
    {}
  ),
  new I4BBasicColumn(
    { displayName: 'ASSET TEMPLATE NAME' },
    {
      id: 'assetTemplate.id',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.ASSET_TEMPLATE',
      cell: { type: I4BCellType.ASSET_TEMPLATE },
      isDefault: false,
      concept: I4bCellConcept.ASSETS,
      rowGroupParams: {
        isConfigurable: false,
        enabled: true,
        rowGroup: false,
        hiddenWhenGrouping: true
      }
    }
  ),
  new TotalColumn(
    {
      displayName: 'COMMENTS',
      type: HeaderType.EMPTY
    },
    {
      id: 'totalComments',
      isDefault: true,
      isConfigurable: false,
      cell: { type: I4BCellType.COMMENT },
      concept: I4bCellConcept.ASSETS,
      rowGroupParams: {
        isConfigurable: false,
        enabled: false,
        rowGroup: false
      }
    },
    {
      order: 140
    }
  )
];

export const DEFAULT_TECHNICAL_COLUMNS_FOR_ASSETS_VIEW = [
  new IdColumn(
    { displayName: 'SITE TECHNICAL ID' },
    {
      id: 'site.id',
      concept: I4bCellConcept.SITES,
      isDefault: false,
      cell: {
        type: I4BCellType.BASIC_LINK,
        event: { type: 'navigateToSite' }
      },
      width: 170,
      rowGroupParams: {
        isConfigurable: false,
        enabled: true,
        rowGroup: false,
        hiddenWhenGrouping: true
      }
    },
    { order: 50 }
  ),
  new IdColumn(
    { displayName: 'ASSET TECHNICAL ID' },
    {
      concept: I4bCellConcept.ASSETS,
      isDefault: false,
      cell: {
        type: I4BCellType.BASIC_LINK,
        event: { type: 'navigateToAsset' }
      },
      width: 170,
      rowGroupParams: {
        isConfigurable: false,
        enabled: true,
        rowGroup: false,
        hiddenWhenGrouping: true
      }
    },
    { order: 51 }
  ),
  new IdColumn(
    { displayName: 'ASSET TEMPLATE TECHNICAL ID' },
    {
      id: 'assetTemplate.id',
      concept: I4bCellConcept.ASSET_TEMPLATES,
      isDefault: false,
      cell: { type: I4BCellType.BASIC },
      width: 230,
      rowGroupParams: {
        isConfigurable: false,
        enabled: true,
        rowGroup: false,
        hiddenWhenGrouping: true
      }
    },
    { order: 54 }
  ),
  new CreatedGroupColumn(
    { displayName: 'CREATED FULL' },
    {
      id: 'createdInfo',
      concept: I4bCellConcept.ASSETS,
      rowGroupParams: {
        createdAt: {
          isConfigurable: false,
          enabled: true,
          rowGroup: false,
          hiddenWhenGrouping: true
        },
        createdBy: {
          isConfigurable: false,
          enabled: true,
          rowGroup: false,
          hiddenWhenGrouping: true
        }
      }
    },
    { order: 52 }
  ),
  new UpdatedGroupColumn(
    { displayName: 'UPDATED FULL' },
    {
      id: 'updatedInfo',
      concept: I4bCellConcept.ASSETS,
      rowGroupParams: {
        updatedAt: {
          isConfigurable: false,
          enabled: true,
          rowGroup: false,
          hiddenWhenGrouping: true
        },
        updatedBy: {
          isConfigurable: false,
          enabled: true,
          rowGroup: false,
          hiddenWhenGrouping: true
        }
      }
    },
    { order: 53 }
  )
];

export class DefaultAssetsGrid implements I4BGrid<I4BGridOptions, AssetsGridData> {
  masterview = 'assets';
  isDefault = false;
  isUserDefault = false;
  isAppDefault = false;
  name: string | null = null;
  userId: string | null = null;
  data!: AssetsGridData;
  gridOptions: I4BGridOptions = {
    pageSize: 100,
    multipleSelection: true,
    autoRefresh: { enabled: false },
    gridSort: [
      { colId: 'entity.name', sort: 'asc', sortIndex: 0 },
      { colId: 'site.name', sort: 'asc', sortIndex: 1 },
      { colId: 'name', sort: 'asc', sortIndex: 2 }
    ],
    buttonColumn: { enabled: true, className: new I4BAssetButtonColumn() },
    selectionColumn: { enabled: true, className: new I4BSelectionColumn() },
    rowGroup: {
      enabled: true,
      headerName: 'MASTER_VIEW_ENGINE.TABLE.ROW_GROUP.DEFAULT_HEADER_NAME',
      pinned: 'left',
      groupDisplayType: 'singleColumn',
      rowGroupPanelShow: 'always',
      groupAllowUnbalanced: false,
      groupDefaultExpanded: -1,
      color: '#99C7F3',
      displayBlanksFirst: true
    }
  };
  columns: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] = [...getDefaultColumnsByConcept(this.masterview)];

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  export(): void {}
}
