import { Component, input, OnInit } from '@angular/core';
import { Filter } from '@iot-platform/models/common';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncAutocompleteMultipleSelectsComponent } from '../async-autocomplete-multiple-selects/async-autocomplete-multiple-selects.component';
import { TimezoneAutocompleteComponent } from '../timezone-autocomplete/timezone-autocomplete.component';

@Component({
  imports: [TranslateModule, AsyncAutocompleteMultipleSelectsComponent],
  selector: 'iot-platform-ui-timezone-autocomplete-multiple-selects',
  templateUrl: './timezone-autocomplete-multiple-selects.component.html',
  styleUrls: ['./timezone-autocomplete-multiple-selects.component.scss']
})
export class TimezoneAutocompleteMultipleSelectsComponent extends TimezoneAutocompleteComponent implements OnInit {
  data = input<any>();
  currentFilters = input<Filter[]>([]);
  currentFiltersSize = input<number>(0);
  maxFilters = input<number>(20);

  ngOnInit(): void {
    super.ngOnInit();
  }
}
