import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component, signal, WritableSignal } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatTooltip } from '@angular/material/tooltip';
import { NumberFormatPipe } from '@iot-platform/pipes';
import { CustomNumberCellParams } from '../../../models/custom-number-cell.params';

@Component({
    selector: 'grid-engine-number-cell',
    templateUrl: './number-cell.component.html',
    imports: [FlexLayoutModule, NumberFormatPipe, MatTooltip],
    providers: [NumberFormatPipe],
    styleUrls: ['./number-cell.component.scss']
})
export class NumberCellComponent implements ICellRendererAngularComp {
  params: WritableSignal<CustomNumberCellParams> = signal(null);

  agInit(params: CustomNumberCellParams): void {
    this.params.set(params);
  }

  refresh(): boolean {
    return false;
  }
}
