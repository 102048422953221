import { Component, DestroyRef, effect, OnDestroy, signal, ViewChild, WritableSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { AuthorizationConcept, AuthorizationService, AuthorizationType, fromAuth } from '@iot-platform/auth';
import { AnalyticsService, LocalStorageKeys, LocalStorageService } from '@iot-platform/core';
import { ChatEvent, IotAction, UserAccount } from '@iot-platform/models/common';
import { Asset, CommentContext, Concept, Device, Log, Site } from '@iot-platform/models/i4b';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { delay, map, mergeMap } from 'rxjs/operators';
import { NavigationApi } from '../+state/navigation.api';

@Component({
  selector: 'iot4bos-ui-info-display',
  templateUrl: './info-display.component.html',
  styleUrls: ['./info-display.component.scss'],
  standalone: false
})
export class InfoDisplayComponent implements OnDestroy {
  analytic: AnalyticsService = new AnalyticsService('site_asset_device_toolbar');
  site: Site;
  assets: Asset[];
  devices: Device[];
  activeItemId: string;
  canReadDisplayedConcept = false;
  canReadSite = false;
  mvorigin = this.storage.get(LocalStorageKeys.STORAGE_MV_ORIGIN_KEY);
  activeSiteId = localStorage.getItem(LocalStorageKeys.STORAGE_ACTIVE_SITE_ID_KEY);

  siteFullAssociations$;
  siteFullAssociationsLoaded$;
  selectedItem$: Observable<{ type: string; id: string }>;
  currentUser$: Observable<UserAccount | null>;

  // Side panel
  displayAssociation: WritableSignal<boolean> = signal(false);
  displayComments: WritableSignal<boolean> = signal(false);

  // Comments
  @ViewChild('sidePanel', { static: true }) sidenav!: MatSidenav;
  comments: WritableSignal<Log[]> = signal([]);
  commentsLoading: WritableSignal<boolean> = signal(true);
  commentsContexts!: CommentContext[];
  currentConceptAndElement: WritableSignal<{
    concept: 'site' | 'asset' | 'device';
    element: Site | Asset | Device;
  } | null> = signal(null);

  constructor(
    private readonly store: Store,
    public readonly navigationApi: NavigationApi,
    private readonly router: Router,
    private readonly authorizationService: AuthorizationService,
    private readonly storage: LocalStorageService,
    private readonly destroyRef: DestroyRef
  ) {
    this.setDevices();
    this.currentUser$ = this.store.select(fromAuth.selectCurrentUser);
    this.canReadSite = this.authorizationService.applyAuthorization(AuthorizationConcept.SITE, AuthorizationType.READ);
    this.navigationApi.site$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((site: Site) => {
      this.site = site;
      if (this.site) {
        this.canReadDisplayedConcept = this.authorizationService.applyAuthorization(AuthorizationConcept.SITE, AuthorizationType.READ);
      }
    });
    this.siteFullAssociations$ = this.navigationApi.siteFullAssociations$;
    this.siteFullAssociationsLoaded$ = this.navigationApi.siteFullAssociationsLoaded$;
    this.setSelectedItem$();
    this.loadDataIfNotLoaded();
    this.navigationApi.selectedAsset$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((asset) => {
      if (asset) {
        this.canReadDisplayedConcept = this.authorizationService.applyAuthorization(AuthorizationConcept.ASSET, AuthorizationType.READ);
      }
    });
    this.navigationApi.selectedDevice$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((device) => {
      if (device) {
        this.canReadDisplayedConcept = this.authorizationService.applyAuthorization(AuthorizationConcept.DEVICE, AuthorizationType.READ);
      }
    });

    effect(() => {
      const currentConceptAndElement = this.currentConceptAndElement();
      if (currentConceptAndElement?.concept === Concept.SITE) {
        this.comments.set([...this.navigationApi.siteComments()]);
        this.commentsLoading.set(this.navigationApi.siteCommentsLoading());
      } else if (currentConceptAndElement?.concept === Concept.ASSET) {
        this.comments.set([...this.navigationApi.assetComments()]);
        this.commentsLoading.set(this.navigationApi.assetCommentsLoading());
      } else if (currentConceptAndElement?.concept === Concept.DEVICE) {
        this.comments.set([...this.navigationApi.deviceComments()]);
        this.commentsLoading.set(this.navigationApi.deviceCommentsLoading());
      }
    });
  }

  onReturnToOrigin(): void {
    this.analytic.log('toolbar_actions', 'return_to_mv');
    const origin: string = this.storage.get(LocalStorageKeys.STORAGE_MV_ORIGIN_KEY);
    this.router.navigateByUrl(origin.split('/').length > 1 ? `/${origin}` : `/${origin}s`, { state: { keepScrollPosition: true } });
  }

  onClose() {
    this.navigationApi.clearSelectedItem(this.site);
  }

  navigateToAsset(id: string) {
    this.navigationApi.selectAsset(this.site.id, id);
  }

  navigateToDevice(id: string) {
    this.navigationApi.selectDevice(this.site.id, id);
  }

  refreshAsset(asset: Asset) {
    this.analytic.log('toolbar_actions', 'refresh_asset');
    this.navigationApi.selectAssetAvecLeSite(asset);
  }

  refreshSite(site: Site) {
    this.analytic.log('toolbar_actions', 'refresh_site');
    this.navigationApi.selectLeSite(site);
  }

  refreshDevice(device: Device) {
    this.analytic.log('toolbar_actions', 'refresh_device');
    this.navigationApi.selectDeviceAvecLeSite(device);
  }

  closeAllPanels(): void {
    this.displayAssociation.set(false);
    this.displayComments.set(false);
    this.navigationApi.toggleDataGuard(false);
  }

  onToggleAssociationPanel(): void {
    this.analytic.log('toolbar_actions', 'open_association_panel');
    this.displayAssociation.set(true);
  }

  onToggleDataGuard(forcedStatus?: boolean) {
    this.analytic.log('toolbar_actions', `open_data_guard`);
    this.navigationApi.toggleDataGuard(forcedStatus);
  }

  setCommentContext(payload: { concept: 'site' | 'asset' | 'device'; element: Site | Asset | Device }): void {
    if (payload.concept === 'asset') {
      this.commentsContexts = [
        { name: Concept.ASSET, checked: true, disabled: false },
        { name: Concept.SITE, checked: false, disabled: !this.canReadSite }
      ];
    } else if (payload.concept === 'device') {
      this.commentsContexts = [
        { name: Concept.DEVICE, checked: true, disabled: false },
        { name: Concept.SITE, checked: false, disabled: !this.canReadSite }
      ];
    } else {
      this.commentsContexts = [];
    }
  }

  onAssetDeviceAssociationAction(event: { action: string; data?: Asset | Device }) {
    switch (event.action) {
      case 'open-asset':
        this.analytic.log('toolbar_actions', 'navigate_to_asset_via_association_panel');
        this.navigateToAsset(event.data.id);
        this.displayAssociation.set(false);
        break;
      case 'open-device':
        this.analytic.log('toolbar_actions', 'navigate_to_device_via_association_panel');
        this.navigateToDevice(event.data.id);
        this.displayAssociation.set(false);
        break;
      default:
        break;
    }
  }

  onToggleCommentPanel(payload: { concept: 'site' | 'asset' | 'device'; element: Site | Asset | Device }): void {
    this.analytic.log('toolbar_actions', 'open_comment_panel', payload.concept);
    this.navigationApi.loadLogsByConcept(payload.concept, payload.element);
    this.currentConceptAndElement.set(payload);
    this.setCommentContext(payload);
    this.displayComments.set(true);
  }

  onCommentsEvent(event: ChatEvent): void {
    switch (event.name) {
      case IotAction.ADD:
        this.onAddComment(event.value as string);
        break;
      case IotAction.EDIT:
        this.onEditComment(event.value as Log);
        break;
      case IotAction.DELETE:
        this.onDeleteComment(event.value as string);
        break;
      case IotAction.CLOSE:
        this.onCloseComments();
        break;
      default:
        break;
    }
  }

  onAddComment(comment: string) {
    this.navigationApi.addComment(this.currentConceptAndElement()?.concept, this.currentConceptAndElement()?.element.id as string, comment);
  }

  onEditComment(comment: Log) {
    this.navigationApi.editComment(this.currentConceptAndElement()?.concept, this.currentConceptAndElement()?.element.id as string, comment);
  }

  onDeleteComment(commentId: string) {
    this.navigationApi.deleteComment(this.currentConceptAndElement()?.concept, this.currentConceptAndElement()?.element.id as string, commentId);
  }

  onCloseComments() {
    this.displayComments.set(false);
    this.sidenav.close();
  }

  ngOnDestroy() {
    this.navigationApi.clearData();
  }

  private setSelectedItem$() {
    this.selectedItem$ = combineLatest([this.navigationApi.selectedAsset$, this.navigationApi.selectedDevice$]).pipe(
      map(([asset, device]) => {
        if (asset) {
          return { type: 'assets', id: asset.id };
        }
        if (device) {
          return { type: 'devices', id: device.id };
        }
        return null;
      })
    );
  }

  private setDevices() {
    this.navigationApi.devices$
      .pipe(
        mergeMap((devices: Device[]) =>
          this.navigationApi.site$.pipe(
            map((site: Site) => (site && site.type === 'stock' ? devices.filter((device: Device) => device.id === this.activeItemId) : devices))
          )
        ),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((devices: Device[]) => (this.devices = devices));
  }

  private loadDataIfNotLoaded() {
    this.navigationApi.siteLoaded.pipe(delay(200), takeUntilDestroyed(this.destroyRef)).subscribe((loaded) => {
      if (loaded === false && this.site === null) {
        this.navigationApi.selectLeSite({
          id: this.activeSiteId,
          name: '',
          address: '',
          type: 'info'
        } as any);
      }
    });
  }
}
