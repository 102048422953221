import { Injectable } from '@angular/core';
import { ApiFeatureSelector, Filter, Pagination, DeviceCallLog } from '@iot-platform/models/common';
import * as fromDeviceCallLogs from '../reducers/device-call-logs.reducer';

@Injectable({
  providedIn: 'root'
})
export class DeviceCallLogsSelectors extends ApiFeatureSelector<fromDeviceCallLogs.State, DeviceCallLog, Pagination, Filter> {
  constructor() {
    super(fromDeviceCallLogs.deviceCallLogsFeature.selectDeviceCallLogsState, fromDeviceCallLogs.adapter);
  }
}
