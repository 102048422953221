import { Component, input } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIcon } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DataGuardCheckError } from '@iot-platform/models/data-guard';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'iot-platform-feature-data-guard-check-error',
  imports: [FlexLayoutModule, TranslateModule, MatIcon, MatTooltipModule],
  templateUrl: './check-error.component.html',
  styleUrl: './check-error.component.scss'
})
export class CheckErrorComponent {
  checkError = input<DataGuardCheckError>();
}
