import { inject } from '@angular/core';
import { NotificationService } from '@iot-platform/notification';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { DeviceCallLogsActions } from '../actions/device-call-logs.actions';
import { DevicesService } from '@iot-platform/shared/services';
import { DeviceCallLog } from '@iot-platform/models/common';

const loadDeviceCallLogs$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), deviceService = inject(DevicesService)) =>
    actions$.pipe(
      ofType(DeviceCallLogsActions.loadDeviceCallLogs),
      switchMap(({ device }) =>
        deviceService.getCallLogById(device.id as string).pipe(
          map((response: DeviceCallLog[]) => DeviceCallLogsActions.loadDeviceCallLogsSuccess({ response })),
          catchError((error) => of(DeviceCallLogsActions.loadDeviceCallLogsFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const loadDeviceCallLogMessage$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), deviceService = inject(DevicesService)) =>
    actions$.pipe(
      ofType(DeviceCallLogsActions.loadDeviceCallLogMessage),
      switchMap(({ device, deviceCallLog }) =>
        deviceService.getCallLogMessage(deviceCallLog.callDirection, device.identifier, deviceCallLog.timestamp).pipe(
          map((response: string) => DeviceCallLogsActions.loadDeviceCallLogMessageSuccess({ deviceCallLogMessage: response })),
          catchError((error) => of(DeviceCallLogsActions.loadDeviceCallLogMessageFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const showLoader$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(DeviceCallLogsActions.loadDeviceCallLogs),
      tap(() => notificationService.showLoader())
    ),
  { functional: true, dispatch: false }
);

const hideLoader$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(DeviceCallLogsActions.loadDeviceCallLogsSuccess, DeviceCallLogsActions.loadDeviceCallLogsFailure),
      tap(() => notificationService.hideLoader())
    ),
  { functional: true, dispatch: false }
);

const displayError$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(DeviceCallLogsActions.loadDeviceCallLogsFailure, DeviceCallLogsActions.loadDeviceCallLogMessageFailure),
      tap((action: Action) => notificationService.displayError(action.type))
    ),
  { functional: true, dispatch: false }
);

export const DeviceCallLogsEffects = {
  loadDeviceCallLogs$,
  loadDeviceCallLogMessage$,
  showLoader$,
  hideLoader$,
  displayError$
};
