import { NgClass } from '@angular/common';
import { Component, computed, Signal } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { IconModule } from '@iot-platform/iot-platform-ui';
import { AbstractTableEngineCellComponent } from '../abstract-table-engine-cell.component';

@Component({
  selector: 'i4b-table-engine-rating-cell',
  templateUrl: './rating-cell.component.html',
  styleUrls: ['./rating-cell.component.scss'],
  imports: [MatTooltip, IconModule, NgClass]
})
export class RatingCellComponent extends AbstractTableEngineCellComponent<RatingCellComponent> {
  icons: Signal<string[]> = computed(() => {
    const data = this.data();
    const cellOptions = this.cellOptions();
    const rating = Number(data);
    const icons = [];
    for (let index = 0; index < cellOptions.max; index++) {
      icons[index] = rating > index ? cellOptions.highlight : cellOptions.icon;
    }
    return icons;
  });
}
