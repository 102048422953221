import { Component, input, output } from '@angular/core';
import { CardLoaderModule } from '@iot-platform/iot-platform-ui';
import { DataGuardCheck, DataGuardEvent, DataGuardEventType } from '@iot-platform/models/data-guard';
import { OverviewCheckComponent } from '../overview-check/overview-check.component';

@Component({
    selector: 'iot-platform-feature-data-guard-overview-tab',
    imports: [CardLoaderModule, OverviewCheckComponent],
    templateUrl: './overview-tab.component.html',
    styleUrl: './overview-tab.component.scss'
})
export class OverviewTabComponent {
  overviewChecks = input<DataGuardCheck[]>([]);
  loading = input<boolean>(false);
  dispatchEvent = output<DataGuardEvent>();

  onCheckClick(check: DataGuardCheck): void {
    this.dispatchEvent.emit({ type: DataGuardEventType.CLICK_ON_CHECK, check });
  }
}
