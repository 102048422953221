@if (data()) {
  <div
    [ngClass]="{
      'last-command-status-cell': true,
      'capitalize-first-letter': true,
      'last-command-status-cell-success': data() === 'success',
      'last-command-status-cell-failure': data() === 'failure'
    }"
    [matTooltip]="rawData().lastCommandStatus.command + ' - ' + (rawData().lastCommandStatus.datetime | dateFormat)"
    matTooltipPosition="above"
    matTooltipClass="regular-tooltip"
  >
    <span>
      {{ 'IOT_DICTIONARY.' + data() | translate }}
    </span>
  </div>
} @else {
  <div class="last-command-status-cell">-</div>
}
