import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CustomEncoder } from '@iot-platform/core';
import {
  CommonApiListResponse,
  CommonApiRequest,
  CommonApiResponse,
  CommonCRUDService,
  CommonIndexedPagination,
  CommonPagination,
  Filter
} from '@iot-platform/models/common';
import { Calendar, OCMDayOff, OCMDayOffType } from '@iot-platform/models/ocm';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CalendarsService implements CommonCRUDService<Calendar, CommonPagination> {
  constructor(
    @Inject('environment') private environment: any,
    private http: HttpClient
  ) {}

  getAll(request: CommonApiRequest): Observable<CommonApiResponse<Calendar, CommonIndexedPagination>> {
    let params: HttpParams = new HttpParams({ encoder: new CustomEncoder() });
    params = params.set('limit', request.limit.toString(10));
    params = params.set('page', request.page.toString(10));

    if (request.filters) {
      request.filters.forEach((filter: Filter) => {
        params = params.append(filter.criteriaKey, filter.value);
      });
    }

    return this.http.get<Calendar>(`${this.environment.api.url}${this.environment.api.endpoints.calendars}`, { params }).pipe(
      map((response: any) => ({
        data: response.content,
        pagination: {
          currentPage: response.page.curPage,
          hasMore: response.page.hasMore,
          limit: response.page.limit,
          maxPage: response.page.maxPage,
          total: response.page.total
        }
      }))
    );
  }

  addOne(toAdd: Calendar): Observable<Calendar> {
    return this.http.post<Calendar>(`${this.environment.api.url}${this.environment.api.endpoints.calendars}`, toAdd);
  }

  updateOne(toUpdate: Calendar): Observable<Calendar> {
    return this.http.put<Calendar>(`${this.environment.api.url}${this.environment.api.endpoints.calendars}/${toUpdate.id}`, toUpdate);
  }

  deleteOne(toDelete: Calendar): Observable<Calendar> {
    return this.http.delete<Calendar>(`${this.environment.api.url}${this.environment.api.endpoints.calendars}/${toDelete.id}`).pipe(map(() => toDelete));
  }

  getOne(calendarId: string): Observable<Calendar> {
    return this.http.get<Calendar>(`${this.environment.api.url}${this.environment.api.endpoints.calendars}/${calendarId}`);
  }

  isNameUnique(calendarName: string, entityId: string): Observable<boolean> {
    let params: HttpParams = new HttpParams({ encoder: new CustomEncoder() });
    params = params.set('page', '0');
    params = params.set('limit', '0');
    params = params.append('name', calendarName);
    params = params.append('entityId', entityId);
    params = params.append('exactParam', 'name');

    return this.http
      .get<CommonApiListResponse<Calendar>>(`${this.environment.api.url}${this.environment.api.endpoints.calendars}`, { params })
      .pipe(map((response) => response.page.total === 0));
  }

  getDaysOffByCalendarId(calendarId: string): Observable<OCMDayOff[]> {
    return this.http
      .get<
        CommonApiListResponse<OCMDayOff>
      >(`${this.environment.api.url}${this.environment.api.endpoints.calendars}/${calendarId}${this.environment.api.endpoints.daysOff}`)
      .pipe(map((response: CommonApiListResponse<OCMDayOff>) => response.content));
  }

  addDayOff(toAdd: OCMDayOff, calendarId: string): Observable<OCMDayOff> {
    return this.http.post<OCMDayOff>(
      `${this.environment.api.url}${this.environment.api.endpoints.calendars}/${calendarId}${this.environment.api.endpoints.daysOff}`,
      toAdd
    );
  }

  updateDayOff(toUpdate: OCMDayOff, calendarId: string): Observable<OCMDayOff> {
    return this.http.put<OCMDayOff>(
      `${this.environment.api.url}${this.environment.api.endpoints.calendars}/${calendarId}${this.environment.api.endpoints.daysOff}/${toUpdate.id}`,
      toUpdate
    );
  }

  deleteDayOff(toDelete: OCMDayOff, calendarId: string): Observable<OCMDayOff> {
    return this.http
      .delete<OCMDayOff>(
        `${this.environment.api.url}${this.environment.api.endpoints.calendars}/${calendarId}${this.environment.api.endpoints.daysOff}/${toDelete.id}`
      )
      .pipe(map(() => toDelete));
  }

  importDaysOffByCalendarId(sourceCalendarId: string, calendarId: string, type: OCMDayOffType): Observable<OCMDayOff[]> {
    return this.http.put<OCMDayOff[]>(
      `${this.environment.api.url}${this.environment.api.endpoints.calendars}/${calendarId}${this.environment.api.endpoints.importDaysOff}`,
      { sourceCalendarId, type }
    );
  }
}
