import { NgTemplateOutlet } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { FormField } from '../form-field.model';
import { FormFieldComponent } from '../form-field/form-field.component';

@Component({
    selector: 'iot-platform-ui-form-group',
    imports: [FlexLayoutModule, FormFieldComponent, ReactiveFormsModule, NgTemplateOutlet],
    templateUrl: './form-group.component.html'
})
export class FormGroupComponent {
  @Input() group!: UntypedFormGroup;
  @Input() fields!: Partial<FormField>[];
}
