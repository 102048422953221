import { ChangeDetectionStrategy, Component, computed, effect, inject, signal, Signal, ViewChild, WritableSignal } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AppName, BusinessProfile } from '@iot-platform/models/common';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { AUTH_CONFIG, AuthConfig } from '../../auth.config';

// eslint-disable-next-line max-len
import { LoginChangeTemporaryPasswordFormComponent } from '../../components/login-change-temporary-password-form/login-change-temporary-password-form.component';
import { LoginFormComponent } from '../../components/login-form/login-form.component';
import { LoginResetPasswordFormComponent } from '../../components/login-reset-password-form/login-reset-password-form.component';
import { LoginSelectAuthenticationComponent } from '../../components/login-select-authentication/login-select-authentication.component';
import { LoginSelectProfileComponent } from '../../components/login-select-profile/login-select-profile.component';
import { LoginSendCodeFormComponent } from '../../components/login-send-code-form/login-send-code-form.component';
import { AuthService } from '../../services/auth.service';
import { AuthBusinessProfilesPageActions } from '../../state/actions/auth-business-profiles-page.actions';
import { AuthPageActions } from '../../state/actions/auth-page.actions';
import { AuthFacade } from '../../state/facades';

@Component({
  imports: [
    FlexLayoutModule,
    TranslateModule,
    LoginSelectAuthenticationComponent,
    LoginFormComponent,
    LoginChangeTemporaryPasswordFormComponent,
    LoginSendCodeFormComponent,
    LoginResetPasswordFormComponent,
    LoginSelectProfileComponent
  ],
  selector: 'iot-platform-login-shell',
  templateUrl: './login-shell.component.html',
  styleUrls: ['./login-shell.component.scss', '../../scss/style.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginShellComponent {
  authConfig: Signal<AuthConfig>;
  loginWithEmailPassword: Signal<boolean>;
  loggedIn: Signal<boolean>;
  pending: Signal<boolean>;
  businessProfiles: Signal<BusinessProfile[]>;
  username: WritableSignal<string> = signal('');
  stepEmail: Signal<boolean>;
  stepPassword: Signal<boolean>;
  requireNewPassword: Signal<boolean>;
  errorSignIn: Signal<any>;
  errorEmail: Signal<any>;
  errorPassword: Signal<any>;
  cognitoUser: Signal<CognitoUser>;
  successMessage: Signal<string>;
  AppName = AppName;

  @ViewChild(LoginFormComponent) loginFormComponent!: LoginFormComponent;
  @ViewChild(LoginResetPasswordFormComponent) loginResetPasswordFormComponent!: LoginResetPasswordFormComponent;
  @ViewChild(LoginChangeTemporaryPasswordFormComponent) loginChangePasswordFormComponent!: LoginChangeTemporaryPasswordFormComponent;

  protected authFacade: AuthFacade = inject(AuthFacade);
  protected authService: AuthService = inject(AuthService);
  protected store: Store = inject(Store);
  protected translateService: TranslateService = inject(TranslateService);
  protected config: AuthConfig = inject(AUTH_CONFIG);

  constructor() {
    this.authConfig = computed(() => this.config);
    this.successMessage = computed(() => this.translateService.instant('LOGIN.CHANGE_PASSWORD.UPDATED_PASSWORD'));
    this.loggedIn = this.authFacade.loggedIn;
    this.cognitoUser = this.authFacade.cognitoUser;
    this.pending = this.authFacade.authPending;
    this.businessProfiles = this.authFacade.businessProfiles;
    this.stepPassword = this.authFacade.authPageStepPassword;
    this.stepEmail = this.authFacade.authPageStepEmail;
    this.requireNewPassword = this.authFacade.authPageRequireNewPassword;
    this.errorSignIn = this.authFacade.authPageErrorSignIn;
    this.errorEmail = this.authFacade.authPageErrorEmail;
    this.errorPassword = this.authFacade.authPageErrorPassword;
    this.loginWithEmailPassword = this.authFacade.displayEmailPasswordLogin;

    effect(() => {
      const errorPassword = this.errorPassword();
      if (this.loginResetPasswordFormComponent && errorPassword) {
        this.loginResetPasswordFormComponent.enableGauge.set(false);
      }
    });

    effect(() => {
      const errorSignIn = this.errorSignIn();
      if (this.loginFormComponent && errorSignIn) {
        this.loginFormComponent.enableGauge.set(false);
      }
    });
  }

  onResetPassword() {
    this.store.dispatch(AuthPageActions.resetPassword());
  }

  onCancel() {
    this.store.dispatch(AuthPageActions.cancel());
  }

  onSendCode(username: string) {
    this.username.set(username);
    this.store.dispatch(AuthPageActions.forgotPassword({ username }));
  }

  onChangeForgottenPassword(userData: { username: string; code: string; password: string }) {
    if (this.loginResetPasswordFormComponent) {
      this.loginResetPasswordFormComponent.enableGauge.set(true);
    }
    this.store.dispatch(
      AuthPageActions.updatePasswordWithCode({
        username: userData.username,
        code: userData.code,
        password: userData.password
      })
    );
  }

  onSignIn(userData) {
    if (this.loginFormComponent) {
      this.loginFormComponent.enableGauge.set(true);
    }
    this.store.dispatch(AuthPageActions.signIn({ username: userData.username, password: userData.password }));
  }

  onSignInWithSSO(): void {
    this.authService.signInWithSso();
  }

  onSignInWithEmailPassword(): void {
    this.store.dispatch(AuthPageActions.displayEmailPasswordLogin());
  }

  onChangeTemporaryPassword(newPwd: string): void {
    this.store.dispatch(AuthPageActions.changePassword({ user: this.cognitoUser(), newPassword: newPwd }));
  }

  onSelectProfile(profile) {
    this.store.dispatch(
      AuthBusinessProfilesPageActions.selectBusinessProfile({
        selectedBusinessProfile: profile,
        withRedirect: true
      })
    );
  }
}
