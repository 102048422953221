import { Injectable } from '@angular/core';
import { BaseFacade, BaseUser, CommonApiRequest, Filter, Pagination } from '@iot-platform/models/common';
import { Store } from '@ngrx/store';
import { noop } from 'rxjs';
import { AdminBusinessProfilesMembersActions } from '../actions/business-profiles-members.actions';
import { AdminBusinessProfilesMembersSelectors } from '../selectors/admin-business-profiles-members.selectors';

@Injectable({
  providedIn: 'root'
})
export class AdminBusinessProfilesMembersFacade extends BaseFacade<BaseUser, Pagination, Filter> {
  constructor(
    protected override store: Store,
    protected override selector: AdminBusinessProfilesMembersSelectors
  ) {
    super(store, selector);
  }

  getAll(request: CommonApiRequest) {
    noop();
  }

  setFilters() {
    noop();
  }

  removeMemberFromBusinessProfile(businessProfileId: string, memberToRemove: BaseUser) {
    this.store.dispatch(
      AdminBusinessProfilesMembersActions.removeMemberFromBusinessProfile({
        businessProfileId,
        memberToRemove
      })
    );
  }

  addMemberToBusinessProfile(businessProfileId: string, memberToAdd: BaseUser) {
    this.store.dispatch(
      AdminBusinessProfilesMembersActions.addMemberToBusinessProfile({
        businessProfileId,
        memberToAdd
      })
    );
  }
}
