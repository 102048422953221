import { Component, DestroyRef, inject, input, OnInit, output, Signal, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AbstractControl, ReactiveFormsModule, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ImageSelectorModule } from '@iot-platform/iot-platform-ui';
import { NameValidators } from '@iot-platform/iot-platform-utils';
import { Asset, AssetModel, AssetModelName, AssetStatusName, QUANTITIES } from '@iot-platform/models/i4b';
import { AssetsService } from '@iot-platform/shared/services';
import { TranslateModule } from '@ngx-translate/core';
import * as moment from 'moment';
import { startWith } from 'rxjs';

@Component({
    imports: [
        TranslateModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        ImageSelectorModule,
        MatFormFieldModule,
        MatInputModule,
        MatOptionModule,
        MatDatepickerModule,
        MatIconModule,
        MatSelectModule,
        MatCheckboxModule
    ],
    selector: 'iot4bos-ui-asset-info-form',
    templateUrl: './asset-info-form.component.html',
    styleUrls: ['./asset-info-form.component.scss']
})
export class AssetInfoFormComponent implements OnInit {
  private readonly assetsService: AssetsService = inject(AssetsService);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  asset = input<Asset>();
  siteId = input<string>();
  optionalPropertiesInput = input<string[]>();
  assetModel = input<AssetModel>();
  canUpdateAsset = input<boolean>(false);
  canUpdateAssetContent = input<boolean>(false);
  fillOutAsset = output<Asset | null>();

  form!: UntypedFormGroup;
  excludedImages: WritableSignal<string[]> = signal([]);
  imageCategory: WritableSignal<string | undefined> = signal(undefined);
  allStatus: Signal<AssetStatusName[]> = signal([AssetStatusName.production, AssetStatusName.maintenance]);
  quantities: Signal<(string | null)[]> = signal(QUANTITIES);
  optionalProperties: WritableSignal<{ key: string; value: boolean }[]> = signal([]);
  optionalPropertiesKeys: string[] = ['businessId', 'deliveryDate', 'product1', 'product2', 'quantity1', 'quantity2', 'shipTo'];

  get name(): AbstractControl {
    return this.form.get('name') as AbstractControl;
  }

  get businessId(): AbstractControl {
    return this.form.get('businessId') as AbstractControl;
  }

  get status(): AbstractControl {
    return this.form.get('status') as AbstractControl;
  }

  get shipTo(): AbstractControl {
    return this.form.get('shipTo') as AbstractControl;
  }

  get deliveryDate(): AbstractControl {
    return this.form.get('deliveryDate') as AbstractControl;
  }

  get quantity1(): AbstractControl {
    return this.form.get('quantity1') as AbstractControl;
  }

  get quantity2(): AbstractControl {
    return this.form.get('quantity2') as AbstractControl;
  }

  get description(): AbstractControl {
    return this.form.get('description') as AbstractControl;
  }

  get properties(): UntypedFormArray {
    return this.form.get('properties') as UntypedFormArray;
  }

  get imageUrl(): AbstractControl {
    return this.form.get('imageUrl') as AbstractControl;
  }

  ngOnInit() {
    this.initForm();
    this.setOptionalProperties();
    this.setExcludedImages();
    this.setImageCategory();
    this.formChangesListener();
    this.disableForm();
  }

  onSelectOptionalPropertiesChange(event: MatCheckboxChange): void {
    if (event.checked) {
      this.properties.push(new UntypedFormControl(event.source.value));
    } else {
      this.properties.controls.forEach((control: AbstractControl, index: number) => {
        if (control.value === event.source.value) {
          this.properties.removeAt(index);
        }
      });
    }
  }

  setExcludedImages(): void {
    if (this.assetModel()?.name === AssetModelName.BLANK || this.assetModel()?.name === 'asset' || this.assetModel()?.name === undefined) {
      this.excludedImages.set([]);
    } else {
      this.excludedImages.set(['default']);
    }
  }

  setImageCategory(): void {
    if (this.assetModel()?.name !== AssetModelName.BLANK && this.assetModel()?.name !== 'asset') {
      this.imageCategory.set(this.assetModel()?.name);
    }
  }

  getImageUrl(): string | null {
    if (this.imageUrl.getRawValue()) {
      return this.imageUrl.getRawValue();
    } else if (this.assetModel()?.name !== AssetModelName.BLANK && this.assetModel()?.name !== undefined) {
      return `assets/images/asset/${this.assetModel()?.name}_general.png`;
    }
    return null;
  }

  private initForm(): void {
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(this.asset() ? this.asset()?.name?.trim() : null, {
        validators: [Validators.required, Validators.maxLength(50)],
        asyncValidators: [NameValidators.asyncUniqueNameValidator(this.assetsService, this.asset()?.name ?? '', this.siteId())]
      }),
      businessId: new UntypedFormControl(this.asset() ? this.asset()?.businessId : null, [Validators.maxLength(30)]),
      status: new UntypedFormControl(this.asset() ? this.asset()?.status?.name : null, [Validators.required]),
      shipTo: new UntypedFormControl(this.asset() ? this.asset()?.erpReference.shipTo : null, [Validators.maxLength(50)]),
      deliveryDate: new UntypedFormControl(this.asset() ? this.asset()?.deliveryDate : null),
      quantity1: new UntypedFormControl(this.asset() ? this.asset()?.quantity1 : null),
      quantity2: new UntypedFormControl(this.asset() ? this.asset()?.quantity2 : null),
      description: new UntypedFormControl(this.asset() ? this.asset()?.description : null, [Validators.maxLength(300)]),
      properties: new UntypedFormArray([]),
      imageUrl: new UntypedFormControl(this.asset() ? this.asset()?.imageUrl : null)
    });
  }

  private setOptionalProperties(): void {
    this.optionalProperties.set(this.optionalPropertiesKeys.sort().map((key: string) => ({ key, value: !!this.optionalPropertiesInput()?.includes(key) }), []));
    this.optionalPropertiesInput()?.forEach((p) => this.properties.push(new UntypedFormControl(p)));
  }

  private formChangesListener(): void {
    this.form.events.pipe(startWith(null), takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      if (this.form.valid) {
        this.fillOutAsset.emit(this.getAsset());
      } else {
        this.fillOutAsset.emit(null);
      }
    });
  }

  private disableForm() {
    if (this.canUpdateAssetContent() && !this.canUpdateAsset()) {
      this.form.disable();
      if (this.asset()?.optionalProperties?.deliveryDate) {
        this.deliveryDate.enable();
      }
    }
  }

  private getAsset(): Asset {
    return {
      ...this.asset(),
      name: this.name.getRawValue().trim(),
      businessId: this.businessId.getRawValue() ? this.businessId.getRawValue() : null,
      status: { ...this.asset()?.status, name: this.status.getRawValue() },
      erpReference: { shipTo: this.shipTo.getRawValue() ? this.shipTo.getRawValue() : null },
      description: this.description.getRawValue() ? this.description.getRawValue() : null,
      imageUrl: this.getImageUrl(),
      deliveryDate: this.deliveryDate.getRawValue() ? moment(this.deliveryDate.getRawValue()).format('yyyy-MM-DD') : null,
      quantity1: this.quantity1.getRawValue(),
      quantity2: this.quantity2.getRawValue(),
      optionalProperties: this.getOptionalProperties(),
      expandedVariables: {},
      expandedTagCategories: {},
      tags: this.asset()?.tags ?? []
    };
  }

  private getOptionalProperties(): { [key: string]: boolean } | undefined {
    return this.asset()
      ? { ...this.asset()?.optionalProperties }
      : this.optionalPropertiesKeys.reduce(
          (acc: { [key: string]: boolean }, key: string) => ({ ...acc, [key]: this.properties.getRawValue().includes(key) }),
          {}
        );
  }
}
