import { Component, inject, Input, signal, Signal, WritableSignal } from '@angular/core';
import { fromGrids } from '@iot-platform/grid-engine';
import { Filter } from '@iot-platform/models/common';
import { DeviceEventsGridData, I4BGrid, I4BGridOptions } from '@iot-platform/models/grid-engine';
import { Device } from '@iot-platform/models/i4b';
import { Store } from '@ngrx/store';

@Component({
    selector: 'iot4bos-ui-device-events-tabs',
    templateUrl: './device-events-tab.component.html',
    styleUrl: './device-events-tab.component.scss',
    standalone: false
})
export class DeviceEventsTabComponent {
  private readonly store = inject(Store);
  grid: Signal<I4BGrid<I4BGridOptions, DeviceEventsGridData> | undefined> = this.store.selectSignal(fromGrids.getDefaultDeviceEventsByDeviceGrid);
  eventType = 'device-events';
  masterviewName = 'device-events-by-device';
  mandatoryFilters: Filter[] = [];

  origin!: string;
  device: WritableSignal<Device | undefined> = signal(undefined);

  @Input() userPermissions: { key: string; value: boolean }[] = [];
  @Input() set selectedDevice(value: Device) {
    this.device.set(value);
    if (this.device()) {
      this.mandatoryFilters = [{ criteriaKey: 'deviceId', value: this.device().id, isHidden: true }];
      this.origin = `sites/${this.device().site.id}/device/${this.device().id}`;
    }
  }
}
