import { JsonPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, signal, Signal } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { AbstractMasterViewDrawerDetailsComponent } from '@iot-platform/feature/master-view';
import { CardLoaderModule, SectionHeaderAction, SectionHeaderComponent, SectionHeaderEvent } from '@iot-platform/iot-platform-ui';
import { NotificationService } from '@iot-platform/notification';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AbstractDeviceCallLogsFacade } from '../../services/abstract-device-call-logs-facade.service';
import { CommonGenericModel, DeviceCallLog } from '@iot-platform/models/common';
import { DateFormatPipe } from '@iot-platform/pipes';

@Component({
  imports: [
    FlexLayoutModule,
    TranslateModule,
    MatCardModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    CardLoaderModule,
    JsonPipe,
    DateFormatPipe,
    SectionHeaderComponent
  ],
  selector: 'iot-platform-feature-device-call-logs-details',
  templateUrl: './device-call-log-details.component.html',
  styleUrls: ['./device-call-log-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceCallLogDetailsComponent<T extends CommonGenericModel> extends AbstractMasterViewDrawerDetailsComponent {
  protected readonly devicCallLogsFacade: AbstractDeviceCallLogsFacade<T> = inject(AbstractDeviceCallLogsFacade);
  protected readonly notificationService: NotificationService = inject(NotificationService);
  protected readonly translateService: TranslateService = inject(TranslateService);

  deviceCallLog: Signal<DeviceCallLog> = this.devicCallLogsFacade.currentEntity;
  deviceCallLogMessage: Signal<string> = this.devicCallLogsFacade.deviceCallLogMessage;
  deviceCallLogMessageLoading: Signal<boolean> = this.devicCallLogsFacade.loading;

  copyMessageAction: SectionHeaderAction = new SectionHeaderAction({
    action: 'copy-message',
    tooltip: signal('IOT_DICTIONARY.COPY_MESSAGE'),
    icon: signal('content_copy')
  });

  sectionHeaderActions: Signal<SectionHeaderAction[]> = signal([this.copyMessageAction]);

  constructor() {
    super();
  }

  onDispatchSectionHeaderEvent(event: SectionHeaderEvent): void {
    if (event.type === this.copyMessageAction.action) {
      this.copyMessage();
    }
  }

  copyMessage(): void {
    navigator.clipboard
      .writeText(JSON.stringify(this.deviceCallLogMessage(), null, 2))
      .then(() =>
        this.notificationService.displaySuccess('[Devices] ' + (this.translateService.instant('IOT_DICTIONARY.MESSAGE_COPIED_TO_CLIPBOARD') as string))
      );
  }
}
