import { I4BCellType, I4BColumnConfiguration, I4BColumnRowGroupConfiguration } from '../configurations';
import { I4bCellConcept } from '../enums';
import { HeaderType, I4BColumnHeader } from '../models';
import { I4BVariableColumnOptions } from '../options';
import { I4BBasicColumn } from './i4b-basic.column';
import { RichVariableColumn } from './i4b-rich-variable.column';
import { I4BThresholdColumn } from './i4b-threshold.column';

const defaultDisplayThresholds = { 1: false, 2: false, 3: false, 4: false, 5: false };

interface AssetFollowedVariableGroupColumnConfiguration extends I4BColumnConfiguration {
  rowGroupParams: Partial<{
    name: Partial<I4BColumnRowGroupConfiguration>;
    value: Partial<I4BColumnRowGroupConfiguration>;
    dateTime: Partial<I4BColumnRowGroupConfiguration>;
    threshold1: Partial<I4BColumnRowGroupConfiguration>;
    threshold2: Partial<I4BColumnRowGroupConfiguration>;
    threshold3: Partial<I4BColumnRowGroupConfiguration>;
    threshold4: Partial<I4BColumnRowGroupConfiguration>;
    threshold5: Partial<I4BColumnRowGroupConfiguration>;
  }>;
}

export class AssetFollowedVariableGroupColumn extends I4BBasicColumn {
  columnId = '3f58dd72-5f3e-11ec-9de0-acde48001122-asset-followed-variable-group';
  className = AssetFollowedVariableGroupColumn;

  constructor(
    customHeader?: Partial<I4BColumnHeader>,
    customConfig?: Partial<AssetFollowedVariableGroupColumnConfiguration>,
    customOptions?: Partial<I4BVariableColumnOptions>
  ) {
    super(
      {
        displayName: customOptions?.overrideHeaderName
          ? customOptions?.overrideHeaderName
          : customHeader?.displayName
            ? customHeader?.displayName
            : 'FOLLOWED VARIABLE (' + customConfig.id + ')',
        type: HeaderType.BASIC
      },
      {
        id: customConfig.id,
        cell: { type: I4BCellType.BASIC },
        concept: I4bCellConcept.FOLLOWED_VARIABLES,
        isDefault: customConfig.isDefault ? customConfig.isDefault : false,
        marryChildren: true,
        rowGroupParams: customConfig?.rowGroupParams,
        children: [
          new I4BBasicColumn(
            { displayName: 'NAME' },
            {
              id: 'followedVariables.' + customConfig.id + '.name',
              isDefault: true,
              translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.FOLLOWED_VARIABLE_' + customConfig.id,
              sortProperty: 'followedVariables.' + customConfig.id + '.name',
              cell: {
                type: I4BCellType.ASSET_VARIABLE_CELL,
                options: { datatype: 'asset' },
                event: { type: 'openChart', options: { followedNumber: customConfig.id } }
              },
              rowGroupParams: customConfig?.rowGroupParams?.name
            },
            { order: 0, customId: 'followedVariables.' + customConfig.id + '.name', isDisplayed: true }
          ),
          new RichVariableColumn(
            { displayName: 'VALUE' },
            {
              id: 'followedVariables.' + customConfig.id,
              isDefault: true,
              translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.FOLLOWED_VARIABLE_' + customConfig.id,
              sortProperty: 'followedVariables.' + customConfig.id + '.lastValue.value',
              cell: {
                type: I4BCellType.RICH_VARIABLE,
                options: { dataType: 'lastValue', unit: 'unit', value: 'value', tooltip: 'datetime' },
                valueGetter: 'followedVariables.' + customConfig.id + '.lastValue.value',
                event: { type: 'openChart', options: { followedNumber: customConfig.id } }
              },
              rowGroupParams: customConfig?.rowGroupParams?.value
            },
            { order: 1, customId: 'followedVariables.' + customConfig.id, isDisplayed: true }
          ),
          new I4BBasicColumn(
            { displayName: 'DATETIME' },
            {
              id: 'followedVariables.' + customConfig.id + '.lastValue.datetime',
              isDefault: true,
              translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.FOLLOWED_VARIABLE_' + customConfig.id,
              sortProperty: 'followedVariables.' + customConfig.id + '.lastValue.datetime',
              cell: { type: I4BCellType.DATE },
              rowGroupParams: customConfig?.rowGroupParams?.dateTime
            },
            {
              order: 2,
              customId: 'followedVariables.' + customConfig.id + '.lastValue.datetime',
              isDisplayed: customOptions.displayDateTime
            }
          ),
          new I4BThresholdColumn(
            {},
            {
              id: customConfig.id,
              cell: { type: I4BCellType.ASSET_VARIABLE_THRESHOLD_CELL, options: { position: 1 } },
              isFollowedVariable: true,
              rowGroupParams: customConfig?.rowGroupParams?.threshold1
            },
            {
              order: 3,
              displayThresholds: customOptions.displayThresholds
            }
          ),
          new I4BThresholdColumn(
            {},
            {
              id: customConfig.id,
              cell: { type: I4BCellType.ASSET_VARIABLE_THRESHOLD_CELL, options: { position: 2 } },
              isFollowedVariable: true,
              rowGroupParams: customConfig?.rowGroupParams?.threshold2
            },
            {
              order: 4,
              displayThresholds: customOptions.displayThresholds
            }
          ),
          new I4BThresholdColumn(
            {},
            {
              id: customConfig.id,
              cell: { type: I4BCellType.ASSET_VARIABLE_THRESHOLD_CELL, options: { position: 3 } },
              isFollowedVariable: true,
              rowGroupParams: customConfig?.rowGroupParams?.threshold3
            },
            {
              order: 5,
              displayThresholds: customOptions.displayThresholds
            }
          ),
          new I4BThresholdColumn(
            {},
            {
              id: customConfig.id,
              cell: { type: I4BCellType.ASSET_VARIABLE_THRESHOLD_CELL, options: { position: 4 } },
              isFollowedVariable: true,
              rowGroupParams: customConfig?.rowGroupParams?.threshold4
            },
            {
              order: 6,
              displayThresholds: customOptions.displayThresholds
            }
          ),
          new I4BThresholdColumn(
            {},
            {
              id: customConfig.id,
              cell: { type: I4BCellType.ASSET_VARIABLE_THRESHOLD_CELL, options: { position: 5 } },
              isFollowedVariable: true,
              rowGroupParams: customConfig?.rowGroupParams?.threshold5
            },
            {
              order: 7,
              displayThresholds: customOptions.displayThresholds
            }
          )
        ]
      },
      {
        customId: customConfig.id,
        displayDateTime: true,
        displayThresholds: defaultDisplayThresholds,
        order: customOptions.order,
        ...customOptions
      }
    );
  }
}
