import { Injectable } from '@angular/core';
import { ApiFeatureSelector, Filter, Pagination, Role } from '@iot-platform/models/common';
import * as fromBusinessProfilesRoles from '../reducers/business-profiles-roles.reducer';

@Injectable({
  providedIn: 'root'
})
export class AdminBusinessProfilesRolesSelectors extends ApiFeatureSelector<fromBusinessProfilesRoles.State, Role, Pagination, Filter> {
  constructor() {
    super(fromBusinessProfilesRoles.adminBusinessProfilesRolesFeature.selectAdminBusinessProfilesRolesState, fromBusinessProfilesRoles.adapter);
  }
}
