@if (isDataLoaded()) {
  @if (displayErrorMessage()) {
    <span class="dynamic-data-cell--error-message">{{ errorMessage() }}</span>
  } @else {
    <span
      [matTooltip]="dataToDisplay()"
      [matTooltipPosition]="cellOptions()?.tooltipPosition ? cellOptions()?.tooltipPosition : 'above'"
      matTooltipClass="regular-tooltip"
      [ngClass]="cssClasses()"
      class="dynamic-data-cell"
      (click)="onClick($event)"
    >{{ dataToDisplay() | infoDisplay }}</span
    >
  }
} @else {
  <mat-progress-spinner color="accent" mode="indeterminate" [diameter]="20" />
}
