import { UpperCasePipe } from '@angular/common';
import { Component, input, output, ViewEncapsulation } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FeatureMasterViewComponent } from '@iot-platform/feature/master-view';
import { TranslateModule } from '@ngx-translate/core';
import { BUSINESS_PROFILE_FAVORITE_VIEWS_MASTER_VIEW_NAME } from './business-profile-favorite-views-grid-definition';

@Component({
  imports: [
    FlexLayoutModule,
    MatCardModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    TranslateModule,
    UpperCasePipe,
    MatTooltipModule,
    FeatureMasterViewComponent
  ],
  selector: 'iot4bos-ui-backoffice-business-profile-favorite-views-tab',
  templateUrl: './business-profile-favorite-views-tab.component.html',
  styleUrls: ['./business-profile-favorite-views-tab.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class BusinessProfileFavoriteViewsTabComponent {
  canUpdateBusinessProfile = input<boolean>(false);
  masterViewList = input<string[]>([]);

  addFavoriteView = output<string>();

  readonly businessProfileFavoriteViewsMasterViewName = BUSINESS_PROFILE_FAVORITE_VIEWS_MASTER_VIEW_NAME;
}
