import { NgClass, UpperCasePipe } from '@angular/common';
import { Component, computed, DestroyRef, inject, input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AuthFacade, AuthorizationConcept, AuthorizationService, AuthorizationType } from '@iot-platform/auth';
import { AnalyticsService } from '@iot-platform/core';
import { GridManagerUserPopupComponent, GridsService } from '@iot-platform/grid-engine';
import {
  ADD_BUTTON_CONFIG,
  FavoriteViewFormComponent,
  IotToolbarDefaultButton,
  IotToolbarDispatchActionType,
  ManageTagsDialogComponent,
  PopupComponent,
  ToolbarV2Module
} from '@iot-platform/iot-platform-ui';
import { BusinessProfile, FavoriteView, IotToolbarEvent, TagCategory, ToolbarSize } from '@iot-platform/models/common';
import { I4BGrid, I4BGridData, I4BGridOptions } from '@iot-platform/models/grid-engine';
import { TranslateModule } from '@ngx-translate/core';
import { switchMap } from 'rxjs/operators';
import { BusinessProfilesListComponent } from '../../../components/business-profiles-list/business-profiles-list.component';
import { AddBusinessProfileDialogComponent } from '../../../components/dialogs/add-business-profile-dialog/add-business-profile-dialog.component';
// tslint:disable-next-line:max-line-length
import { ManageBusinessProfileMembersDialogComponent } from '../../../components/dialogs/manage-business-profile-members-dialog/manage-business-profile-members-dialog.component';
// tslint:disable-next-line:max-line-length
import { ManageBusinessProfileRolesDialogComponent } from '../../../components/dialogs/manage-business-profile-roles-dialog/manage-business-profile-roles-dialog.component';
import { BusinessProfileFavoriteViewsTabComponent } from '../components/business-profile-favorite-views-tab/business-profile-favorite-views-tab.component';
import { BusinessProfileGridsTabComponent } from '../components/business-profile-grids-tab/business-profile-grids-tab.component';
import { BusinessProfileTagsComponent } from '../components/business-profile-tags/business-profile-tags.component';
import { BusinessProfilesDetailComponent } from '../components/business-profiles-detail/business-profiles-detail.component';
import { FilterComponent } from '../components/filter/filter.component';

import { AdminBusinessProfilesFavoriteViewsFacade } from '../state/facades/admin-business-profiles-favorite-views.facade';
import { AdminBusinessProfilesGridsFacade } from '../state/facades/admin-business-profiles-grids.facade';
import { AdminBusinessProfilesMembersFacade } from '../state/facades/admin-business-profiles-members.facade';
import { AdminBusinessProfilesRolesFacade } from '../state/facades/admin-business-profiles-roles.facade';
import { AdminBusinessProfilesTagsFacade } from '../state/facades/admin-business-profiles-tags.facade';
import { AdminBusinessProfilesFacade } from '../state/facades/admin-business-profiles.facade';

@Component({
  imports: [
    FlexLayoutModule,
    BusinessProfilesListComponent,
    FilterComponent,
    MatToolbarModule,
    NgClass,
    MatTooltipModule,
    TranslateModule,
    UpperCasePipe,
    MatTooltipModule,
    MatIconModule,
    MatButtonModule,
    MatTabsModule,
    BusinessProfilesDetailComponent,
    BusinessProfileTagsComponent,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatCardModule,
    BusinessProfileGridsTabComponent,
    ToolbarV2Module,
    BusinessProfileFavoriteViewsTabComponent
  ],
  selector: 'iot4bos-ui-backoffice-admin-business-profiles',
  templateUrl: './admin-business-profiles.component.html',
  styleUrls: ['../../../style/admin.style.scss', './admin-business-profiles.component.scss']
})
export class AdminBusinessProfilesComponent implements OnInit {
  private readonly dialog: MatDialog = inject(MatDialog);
  private readonly authz: AuthorizationService = inject(AuthorizationService);
  private readonly gridsService: GridsService = inject(GridsService);
  private readonly authFacade: AuthFacade = inject(AuthFacade);
  private readonly adminBusinessProfilesFacade: AdminBusinessProfilesFacade = inject(AdminBusinessProfilesFacade);
  private readonly adminBusinessProfilesMembersFacade: AdminBusinessProfilesMembersFacade = inject(AdminBusinessProfilesMembersFacade);
  private readonly adminBusinessProfilesFavoriteViewsFacade: AdminBusinessProfilesFavoriteViewsFacade = inject(AdminBusinessProfilesFavoriteViewsFacade);
  private readonly adminBusinessProfilesRolesFacade: AdminBusinessProfilesRolesFacade = inject(AdminBusinessProfilesRolesFacade);
  private readonly adminBusinessProfilesTagsFacade: AdminBusinessProfilesTagsFacade = inject(AdminBusinessProfilesTagsFacade);
  private readonly adminBusinessProfilesGridsFacade: AdminBusinessProfilesGridsFacade = inject(AdminBusinessProfilesGridsFacade);
  private readonly destroy: DestroyRef = inject(DestroyRef);

  displayGridsTab = input<boolean>(true);
  displayFavoriteViewsTab = input<boolean>(true);

  analytics: AnalyticsService = new AnalyticsService('admin_business_profiles_shell');
  businessProfilesButtonList!: IotToolbarDefaultButton[];

  total = this.adminBusinessProfilesFacade.total;
  filteredProfiles = this.adminBusinessProfilesFacade.filteredProfiles;
  selectedBusinessProfile = this.adminBusinessProfilesFacade.currentEntity;
  searchString = this.adminBusinessProfilesFacade.searchString;
  businessProfilesLoading = this.adminBusinessProfilesFacade.loading;

  members = this.adminBusinessProfilesMembersFacade.all;
  membersLoading = this.adminBusinessProfilesMembersFacade.loading;
  roles = this.adminBusinessProfilesRolesFacade.all;
  rolesLoading = this.adminBusinessProfilesRolesFacade.loading;
  tags = this.adminBusinessProfilesTagsFacade.allWithSortedLabels;
  tagsLoading = this.adminBusinessProfilesTagsFacade.loading;
  favoriteViewsLoading = this.adminBusinessProfilesFavoriteViewsFacade.loading;

  canCreateBusinessProfile: boolean;
  canUpdateBusinessProfile: boolean;
  canDeleteBusinessProfile: boolean;
  canReadOrganizations: boolean;

  userPermissions: any;
  masterViews = ['site', 'asset', 'device', 'asset-event', 'device-event'];
  userId = this.authFacade.userId;

  toolbarSize: string = ToolbarSize.SMALL;

  bpDeleteTooltip = computed(() => {
    const members = this.members();
    const roles = this.roles();

    return members.length !== 0 && roles.length !== 0
      ? `Business Profile is linked to ${members.length} users and ${roles.length} roles`
      : roles.length !== 0
        ? `Business Profile is linked to ${roles.length} roles`
        : members.length !== 0
          ? `Business Profile is linked to ${members.length} users`
          : 'Delete';
  });

  bpDeleteButtonDisabled = computed(() => {
    const members = this.members();
    const roles = this.roles();
    return !!members.length || !!roles.length;
  });

  constructor() {
    this.canCreateBusinessProfile = this.authz.applyAuthorization(AuthorizationConcept.BUSINESS_PROFILE, AuthorizationType.CREATE);
    this.canUpdateBusinessProfile = this.authz.applyAuthorization(AuthorizationConcept.BUSINESS_PROFILE, AuthorizationType.UPDATE);
    this.canDeleteBusinessProfile = this.authz.applyAuthorization(AuthorizationConcept.BUSINESS_PROFILE, AuthorizationType.DELETE);
    this.canReadOrganizations = this.authz.applyAuthorization(AuthorizationConcept.ORGANIZATION_TREE, AuthorizationType.READ);
    this.userPermissions = [{ key: 'canUpdateBusinessProfile', value: this.canUpdateBusinessProfile }];

    this.initToolbarButtonList();
  }

  ngOnInit() {
    this.adminBusinessProfilesFacade.loadBusinessProfiles();
  }

  initToolbarButtonList(): void {
    this.businessProfilesButtonList = [
      new IotToolbarDefaultButton(
        {
          ...ADD_BUTTON_CONFIG,
          displayButton: this.canCreateBusinessProfile,
          tooltip: 'ADMIN.BUSINESS_PROFILES.TOOLBAR_BUTTON_TOOLTIP'
        },
        0
      )
    ];
  }

  selectBusinessProfile(businessProfile: BusinessProfile) {
    this.adminBusinessProfilesFacade.selectBusinessProfile(businessProfile);
  }

  navigateToSelectedOrganization(organizationId: string) {
    if (this.canReadOrganizations) {
      this.analytics.log('toolbar_actions', 'navigate_to_organization');
      this.adminBusinessProfilesFacade.navigateToSelectedOrganization(organizationId);
    }
  }

  filter(searchString: string) {
    this.analytics.log('filter_actions', 'filter_bp_list');
    this.adminBusinessProfilesFacade.filterBusinessProfiles(searchString);
  }

  onToolbarEvent(event: IotToolbarEvent) {
    switch (event.type) {
      case IotToolbarDispatchActionType.ADD_ELEMENT:
        this.onAddBusinessProfile();
        break;
      default:
        break;
    }
  }

  onAddBusinessProfile() {
    this.analytics.log('toolbar_actions', 'open_add_bp');
    this.dialog
      .open(AddBusinessProfileDialogComponent, {
        width: '550px',
        disableClose: true,
        data: {}
      })
      .afterClosed()
      .pipe(takeUntilDestroyed(this.destroy))
      .subscribe((newProfile: BusinessProfile) => {
        if (newProfile) {
          this.analytics.log('toolbar_actions', 'add_bp');
          this.adminBusinessProfilesFacade.addBusinessProfileToOrganization(newProfile);
        }
      });
  }

  editBusinessProfile(businessProfile: BusinessProfile) {
    this.analytics.log('toolbar_actions', 'open_edit_bp');
    this.dialog
      .open(AddBusinessProfileDialogComponent, {
        width: '550px',
        disableClose: true,
        data: { businessProfile }
      })
      .afterClosed()
      .pipe(takeUntilDestroyed(this.destroy))
      .subscribe((updated: BusinessProfile) => {
        if (updated) {
          this.analytics.log('toolbar_actions', 'update_bp');
          this.adminBusinessProfilesFacade.updateBusinessProfile({ ...updated, id: businessProfile.id });
        }
      });
  }

  onAddMembersToProfile() {
    this.analytics.log('info_tab_actions', 'open_add_members_to_bp');
    this.dialog.open(ManageBusinessProfileMembersDialogComponent, {
      width: '900px',
      data: { businessProfile: this.selectedBusinessProfile() }
    });
  }

  onAddRolesToProfile() {
    this.analytics.log('info_tab_actions', 'open_add_roles_to_bp');
    this.dialog.open(ManageBusinessProfileRolesDialogComponent, {
      width: '900px',
      data: { businessProfile: this.selectedBusinessProfile() }
    });
  }

  onAddTagsToProfile(concept: string) {
    this.analytics.log('tag_tab_actions', 'open_manage_bp_tags');
    this.dialog
      .open(ManageTagsDialogComponent, {
        width: '1230px',
        disableClose: true,
        data: {
          concepts: ['site', 'asset', 'device', 'event'],
          openOnConcept: concept,
          selectedTags: this.tags(),
          objectName: this.selectedBusinessProfile().name,
          currentEntityId: this.selectedBusinessProfile().entityId,
          multiSelection: true,
          withChildren: true,
          joinable: false,
          withParents: true
        }
      })
      .afterClosed()
      .pipe(takeUntilDestroyed(this.destroy))
      .subscribe((tags: TagCategory[]) => {
        if (tags) {
          this.analytics.log('tag_tab_actions', 'manage_bp_tags');
          this.adminBusinessProfilesTagsFacade.updateTags(this.selectedBusinessProfile().id, tags);
        }
      });
  }

  deleteBusinessProfile(businessProfile: BusinessProfile) {
    this.analytics.log('toolbar_actions', 'open_delete_bp');
    this.dialog
      .open(PopupComponent, {
        width: '500px',
        disableClose: true,
        data: {
          type: 'delete',
          value: businessProfile.name
        }
      })
      .afterClosed()
      .pipe(takeUntilDestroyed(this.destroy))
      .subscribe((value: boolean) => {
        if (value) {
          this.analytics.log('toolbar_actions', 'delete_bp');
          this.adminBusinessProfilesFacade.deleteBusinessProfile(businessProfile);
        }
      });
  }

  onAddFavoriteView(masterView: string) {
    this.analytics.log('fv_tab_actions', 'open_add_fv');
    const mv = masterView === 'asset-event' || masterView === 'device-event' ? 'EVENT' : masterView.toUpperCase();
    const favoriteView: FavoriteView = {
      masterView: masterView + 's',
      concept: mv,
      filters: [],
      businessProfileId: this.selectedBusinessProfile().id
    };
    this.dialog
      .open(FavoriteViewFormComponent, {
        width: '1100px',
        disableClose: true,
        data: {
          favoriteView,
          canUpdateBusinessProfile: this.canUpdateBusinessProfile,
          forcedShare: true,
          adminMode: true,
          userId: this.userId()
        }
      })
      .afterClosed()
      .pipe(takeUntilDestroyed(this.destroy))
      .subscribe((data: { grid: I4BGrid<I4BGridOptions, I4BGridData>; favoriteView: FavoriteView }) => {
        if (data) {
          if (data.grid) {
            this.analytics.log('fv_tab_actions', 'add_fv_with_a_grid');
            this.adminBusinessProfilesFavoriteViewsFacade.duplicateGridThenAddFavoriteView(data.grid, data.favoriteView);
          } else {
            this.analytics.log('fv_tab_actions', 'add_fv');
            this.adminBusinessProfilesFavoriteViewsFacade.addFavoriteView(data.favoriteView);
          }
        }
      });
  }

  onAddGrid(masterView: string): void {
    this.analytics.log('grid_tab_actions', 'open_add_grid');
    this.gridsService
      .getAppDefaultGridByConcept(masterView + 's')
      .pipe(
        switchMap((appDefaultGrid) =>
          this.dialog
            .open(GridManagerUserPopupComponent, {
              width: '1350px',
              maxWidth: '1350px',
              disableClose: true,
              data: { grid: appDefaultGrid, isAdminMode: true, selectedBusinessProfile: this.selectedBusinessProfile() }
            })
            .afterClosed()
        ),
        takeUntilDestroyed(this.destroy)
      )
      .subscribe((gridToAdd) => {
        if (gridToAdd) {
          this.analytics.log('grid_tab_actions', 'add_grid');
          this.adminBusinessProfilesGridsFacade.addGrid(gridToAdd);
        }
      });
  }
}
