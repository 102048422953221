import { NgClass, UpperCasePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconButton } from '@angular/material/button';
import { MatDivider } from '@angular/material/divider';
import { MatIcon } from '@angular/material/icon';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { ExtendedModule } from '../../../../../../../deprecated/flex-layout/src/lib/extended/module';
import { TranslationManagementService } from '../../services/translation-management.service';

@Component({
    selector: 'i18n-translation-toolbar',
    templateUrl: './translation-toolbar.component.html',
    styleUrls: ['./translation-toolbar.component.scss'],
    imports: [
        FlexLayoutModule,
        MatIconButton,
        MatTooltip,
        NgClass,
        ExtendedModule,
        MatIcon,
        MatMenuTrigger,
        MatMenu,
        MatMenuItem,
        MatDivider,
        UpperCasePipe,
        TranslateModule
    ]
})
export class TranslationToolbarComponent {
  @Input() public isEditMode: boolean;
  @Input() public keyCount: number;
  @Input() public showClearSelectedButton: boolean;
  @Input() public languages: string[] = [];

  @Output() public editMode: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public clear: EventEmitter<void> = new EventEmitter<void>();
  @Output() public export: EventEmitter<string> = new EventEmitter<string>();
  @Output() public addLanguage: EventEmitter<string> = new EventEmitter<string>();
  @Output() public deleteLanguage: EventEmitter<string> = new EventEmitter<string>();

  constructor(private readonly translationManagementService: TranslationManagementService) {}

  public get languagesToRemove(): string[] {
    const defaultLanguages = this.translationManagementService.getLanguages();
    return this.languages.filter((l) => !defaultLanguages.includes(l));
  }

  public toggleEditMode(): void {
    this.isEditMode = !this.isEditMode;
    this.editMode.emit(this.isEditMode);
  }

  public onExport(lang: string): void {
    this.export.emit(lang);
  }

  public onClear(): void {
    this.clear.emit();
  }

  public onAddLanguage(): void {
    this.addLanguage.emit();
  }

  public onDeleteLanguage(lang: string): void {
    this.deleteLanguage.emit(lang);
  }
}
