import { Injectable, Signal } from '@angular/core';
import { Store } from '@ngrx/store';
import { noop } from 'rxjs';
import { DeviceCallLogsActions } from '../actions';
import { DeviceCallLogsSelectors } from '../selectors/device-call-logs.selectors';
import { Device } from '@iot-platform/models/i4b';
import { DeviceCallLog } from '@iot-platform/models/common';
import { AbstractDeviceCallLogsFacade } from '@iot-platform/feature/device-call-logs';
import * as fromDeviceCallLogs from '../reducers/device-call-logs.reducer';

@Injectable({
  providedIn: 'root'
})
export class DeviceCallLogsFacade extends AbstractDeviceCallLogsFacade<Device> {
  deviceCallLogMessage: Signal<string> = this.store.selectSignal(fromDeviceCallLogs.deviceCallLogsFeature.selectDeviceCallLogMessage);

  constructor(
    protected override store: Store,
    protected override selector: DeviceCallLogsSelectors
  ) {
    super(store, selector);
  }

  getAll() {
    noop();
  }

  setFilters() {
    noop();
  }

  loadDeviceCallLogs(device: Device): void {
    this.store.dispatch(DeviceCallLogsActions.loadDeviceCallLogs({ device }));
  }

  loadDeviceCallLogMessage(device: Device, deviceCallLog: DeviceCallLog): void {
    this.store.dispatch(DeviceCallLogsActions.loadDeviceCallLogMessage({ device, deviceCallLog }));
  }
}
