import { BaseState, FavoriteView, Filter, INITIAL_BASE_STATE, Pagination } from '@iot-platform/models/common';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createFeature, createReducer, on } from '@ngrx/store';
import { AdminBusinessProfilesFavoriteViewActions } from '../actions/business-profiles-favorite-views.actions';
import { AdminBusinessProfilesActions } from '../actions/business-profiles.actions';

export type State = BaseState<FavoriteView, Pagination, Filter>;

export const adapter: EntityAdapter<FavoriteView> = createEntityAdapter<FavoriteView>({
  selectId: (favoriteView: FavoriteView) => favoriteView.id as string,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  ...INITIAL_BASE_STATE,
  pagination: { currentPage: 0, total: 0, maxPage: 0, hasMore: false, limit: 10 }
});

export const adminBusinessProfilesFavoriteViewsFeature = createFeature({
  name: 'adminBusinessProfilesFavoriteViews',
  reducer: createReducer(
    initialState,
    on(AdminBusinessProfilesActions.selectOne, AdminBusinessProfilesFavoriteViewActions.loadFavoriteViewsByBusinessProfile, (state) =>
      adapter.removeAll(state)
    ),
    on(AdminBusinessProfilesFavoriteViewActions.loadFavoriteViewsByBusinessProfileSuccess, (state, { response }) =>
      adapter.setAll(response.data, { ...state, pagination: response.pagination })
    ),

    on(AdminBusinessProfilesFavoriteViewActions.addOneSuccess, (state, { added }) => adapter.addOne(added, state)),
    on(AdminBusinessProfilesFavoriteViewActions.updateOneSuccess, (state, { updated }) =>
      adapter.updateOne(
        {
          id: updated.id,
          changes: updated
        },
        state
      )
    ),
    on(AdminBusinessProfilesFavoriteViewActions.deleteOneSuccess, (state, { deleted }) => adapter.removeOne(deleted.id, state)),
    on(AdminBusinessProfilesFavoriteViewActions.duplicateFavoriteViewSuccess, (state, { duplicatedFavoriteView }) =>
      adapter.addOne(duplicatedFavoriteView, state)
    ),

    on(
      AdminBusinessProfilesFavoriteViewActions.loadFavoriteViewsByBusinessProfile,
      AdminBusinessProfilesFavoriteViewActions.addOne,
      AdminBusinessProfilesFavoriteViewActions.updateOne,
      AdminBusinessProfilesFavoriteViewActions.deleteOne,
      AdminBusinessProfilesFavoriteViewActions.duplicateFavoriteView,
      (state) => ({ ...state, loading: true, loaded: false })
    ),
    on(
      AdminBusinessProfilesFavoriteViewActions.loadFavoriteViewsByBusinessProfileSuccess,
      AdminBusinessProfilesFavoriteViewActions.addOneSuccess,
      AdminBusinessProfilesFavoriteViewActions.updateOneSuccess,
      AdminBusinessProfilesFavoriteViewActions.deleteOneSuccess,
      AdminBusinessProfilesFavoriteViewActions.duplicateFavoriteViewSuccess,
      (state) => ({ ...state, loading: false, loaded: true })
    ),
    on(
      AdminBusinessProfilesFavoriteViewActions.loadFavoriteViewsByBusinessProfileFailure,
      AdminBusinessProfilesFavoriteViewActions.addOneFailure,
      AdminBusinessProfilesFavoriteViewActions.updateOneFailure,
      AdminBusinessProfilesFavoriteViewActions.deleteOneFailure,
      AdminBusinessProfilesFavoriteViewActions.duplicateFavoriteViewFailure,
      (state, { error }) => ({ ...state, error, loading: false, loaded: false })
    )
  )
});
