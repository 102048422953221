import { UpperCasePipe } from '@angular/common';
import { Component, input, output } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { ChatEvent, ChatHeader } from '@iot-platform/models/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    imports: [MatIcon, FlexLayoutModule, MatProgressSpinner, TranslateModule, UpperCasePipe, MatButtonModule],
    selector: 'iot-platform-ui-chat-header',
    templateUrl: './chat-header.component.html',
    styleUrls: ['./chat-header.component.scss']
})
export class ChatHeaderComponent {
  header = input.required<ChatHeader>();
  dispatchAction = output<ChatEvent>();
}
