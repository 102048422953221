<mat-card class="dialog-card">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar class="dialog-toolbar" color="accent" fxLayout="row" fxLayoutAlign="space-between center">
      <h1 class="dialog-card-title">{{ 'ASSETS.SHELL.CREATE_ASSET_FROM_TEMPLATE' | translate }}</h1>
      <button (click)="dialogRef.close()" class="regular-round-button" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar>
  </mat-card-title>

  <mat-card-content class="dialog-card-content create-asset-from-template-dialog--content" fxLayout="column">
    <mat-stepper
      #stepper
      (selectionChange)="onStepChange($event.selectedIndex)"
      [animationDuration]="'500'"
      [linear]="true"
      color="accent"
      fxFlex
      headerPosition="top"
      labelPosition="end"
    >
      <!-- STEP 1 : ASSET MODEL -->
      <mat-step #step1 [stepControl]="firstStepForm" fxFlex>
        <ng-template matStepLabel>{{ 'IOT_DICTIONARY.MODEL' | translate }}</ng-template>

        <form [formGroup]="firstStepForm" fxLayout="column" fxLayoutAlign="space-between stretch">
          <iot4bos-ui-asset-model-form
            [site]="data.site"
            [withBlankModel]="false"
            (fillOutAssetModel)="onAssetModelFilled($event)"
          ></iot4bos-ui-asset-model-form>
        </form>
      </mat-step>

      <!-- STEP 2 : ASSET TEMPLATE -->
      <mat-step #step2 [stepControl]="secondStepForm" fxFlex>
        <ng-template matStepLabel>{{ 'IOT_DICTIONARY.TEMPLATE' | translate }}</ng-template>
        @if (stepper.selectedIndex === 1) {
          <div fxLayout="column" fxLayoutAlign="space-between">
            <div class="full-width" fxLayout="row" fxLayoutGap="6px">
              <section fxFlex="33%" fxLayout="column">
                <form [formGroup]="secondStepForm">
                  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
                    <mat-form-field color="accent" fxFlex>
                      <mat-label>{{ 'ASSETS.TEMPLATE_FORM.FILTER_TEMPLATES' | translate }}</mat-label>
                      <input matInput formControlName="filter" />
                    </mat-form-field>

                    <button (click)="filter.reset()" mat-icon-button color="warn">
                      <mat-icon>close</mat-icon>
                    </button>
                  </div>

                  <div class="asset-template-popup--template-list-container">
                    @if (templatesLoading()) {
                      <iot-platform-ui-card-loader fxFlexFill fxLayoutAlign="center center"></iot-platform-ui-card-loader>
                    } @else {
                      @for (assetTemplate of filteredTemplates(); track assetTemplate) {
                        <div
                          (click)="onTemplateSelectionChange(assetTemplate)"
                          [ngClass]="{ 'selected-template': assetTemplate.id === template.getRawValue()?.id }"
                          class="asset-template-popup--template-list-item p-10"
                          fxLayout="row"
                          fxLayoutAlign="start center"
                          matRipple
                        >
                          <mat-icon class="asset-template-popup--template-list-item-icon">file_copy</mat-icon>
                          <div class="asset-template-popup--template-list-item-info" fxFlex fxLayout="column" fxLayoutGap="4px">
                            <span class="fw-600">{{ assetTemplate.name }}</span>
                            <span>{{ assetTemplate.category }} | {{ assetTemplate.entity.name }}</span>
                            <span>{{ assetTemplate.description }}</span>
                          </div>
                        </div>
                      }
                      @if (filteredTemplates()?.length === 0) {
                        <span class="fw-600 asset-template-popup--select-template-placeholder" fxFlexFill fxLayoutAlign="center center">
                          {{ 'ASSETS.TEMPLATE_FORM.NO_TEMPLATE_AVAILABLE_FOR_THIS_SITE' | translate }}
                        </span>
                      }
                    }
                  </div>
                </form>
              </section>

              <section class="asset-template-popup--summary-section p-20" fxFlex="67%" fxLayout="column">
                @if (template.getRawValue(); as selectedTemplate) {
                  <div class="asset-template-popup--summary-section-template-info" fxLayout="column" fxLayoutGap="6px">
                    <iot-platform-ui-detail-popup-display-property
                      [property]="'ASSETS.TEMPLATE_FORM.TEMPLATE_NAME' | translate"
                      [value]="selectedTemplate.name"
                      class="asset-template-popup--summary-section-template-info-title"
                    ></iot-platform-ui-detail-popup-display-property>

                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
                      <iot-platform-ui-detail-popup-display-property
                        [property]="'ASSETS.TEMPLATE_FORM.CATEGORY' | translate"
                        [value]="selectedTemplate.category"
                      ></iot-platform-ui-detail-popup-display-property>
                      <iot-platform-ui-detail-popup-display-property
                        [property]="'IOT_DICTIONARY.ENTITY' | translate"
                        [value]="selectedTemplate.entity.name"
                      ></iot-platform-ui-detail-popup-display-property>
                      <iot-platform-ui-detail-popup-display-property
                        [property]="'IOT_DICTIONARY.DESCRIPTION' | translate"
                        [value]="selectedTemplate.description"
                      ></iot-platform-ui-detail-popup-display-property>
                    </div>
                  </div>

                  <div fxLayoutGap="6px">
                    <div class="asset-template-popup--summary-section-column" fxFlex="50%" fxLayout="column" fxLayoutGap="10px">
                      <div class="asset-template-popup--summary-section-template-sub-section">
                        <iot-platform-ui-detail-popup-section-header
                          [color]="'#394c5a'"
                          [headerTitle]="'IOT_DICTIONARY.IMAGE' | translate | uppercase"
                          [separatorLinePosition]="'beneath'"
                        ></iot-platform-ui-detail-popup-section-header>
                        <img [src]="selectedTemplate.template.imageUrl" alt="" class="asset-template-popup--summary-section-template-image" />
                      </div>

                      <div class="asset-template-popup--summary-section-template-sub-section">
                        <iot-platform-ui-detail-popup-section-header
                          [color]="'#394c5a'"
                          [headerTitle]="'ASSETS.TEMPLATE_FORM.ASSET_INFORMATION' | translate | uppercase"
                          [separatorLinePosition]="'beneath'"
                        ></iot-platform-ui-detail-popup-section-header>
                        <iot-platform-ui-detail-popup-display-property
                          [property]="'IOT_DICTIONARY.NAME' | translate"
                          [value]="selectedTemplate.template.name | truncate: [20, '...'] | infoDisplay"
                        ></iot-platform-ui-detail-popup-display-property>
                        <iot-platform-ui-detail-popup-display-property
                          [property]="'ASSETS.TEMPLATE_FORM.ASSET_STATUS' | translate"
                          [value]="'ASSETS.CARD.STATUS_LIST.production' | translate"
                        ></iot-platform-ui-detail-popup-display-property>
                        <iot-platform-ui-detail-popup-display-property
                          [property]="'ASSETS.TEMPLATE_FORM.ASSET_DESCRIPTION' | translate"
                          [value]="selectedTemplate.template.description | truncate: [20, '...'] | infoDisplay"
                        ></iot-platform-ui-detail-popup-display-property>
                        @if (selectedTemplate.template.product1) {
                          <iot-platform-ui-detail-popup-display-property
                            [property]="'ASSETS.TEMPLATE_FORM.PRODUCT_1' | translate"
                            [value]="selectedTemplate.template.product1.identifier + ' : ' + selectedTemplate.template.product1.name"
                          ></iot-platform-ui-detail-popup-display-property>
                        }
                        @if (selectedTemplate.template.product2) {
                          <iot-platform-ui-detail-popup-display-property
                            [property]="'ASSETS.TEMPLATE_FORM.PRODUCT_2' | translate"
                            [value]="selectedTemplate.template.product2.identifier + ' : ' + selectedTemplate.template.product2.name"
                          ></iot-platform-ui-detail-popup-display-property>
                        }
                        @if (selectedTemplate.template.businessId) {
                          <iot-platform-ui-detail-popup-display-property
                            [property]="'ASSETS.TEMPLATE_FORM.BUSINESS_ID' | translate"
                            [value]="selectedTemplate.template.businessId | truncate: [20, '...'] | infoDisplay"
                          ></iot-platform-ui-detail-popup-display-property>
                        }
                        @if (selectedTemplate.template.quantity1) {
                          <iot-platform-ui-detail-popup-display-property
                            [property]="'ASSETS.TEMPLATE_FORM.QUANTITY_1' | translate"
                            [value]="selectedTemplate.template.quantity1"
                          ></iot-platform-ui-detail-popup-display-property>
                        }
                        @if (selectedTemplate.template.quantity2) {
                          <iot-platform-ui-detail-popup-display-property
                            [property]="'ASSETS.TEMPLATE_FORM.QUANTITY_2' | translate"
                            [value]="selectedTemplate.template.quantity2"
                          ></iot-platform-ui-detail-popup-display-property>
                        }
                        @if (selectedTemplate.template.shipTo) {
                          <iot-platform-ui-detail-popup-display-property
                            [property]="'ASSETS.TEMPLATE_FORM.SHIP_TO' | translate"
                            [value]="selectedTemplate.template.shipTo | truncate: [20, '...'] | infoDisplay"
                          ></iot-platform-ui-detail-popup-display-property>
                        }
                        @if (selectedTemplate.template.deliveryDate) {
                          <iot-platform-ui-detail-popup-display-property
                            [property]="'ASSETS.TEMPLATE_FORM.DELIVERY_DATE' | translate"
                            [value]="selectedTemplate.template.deliveryDate | dateFormat: dateFormat()"
                          ></iot-platform-ui-detail-popup-display-property>
                        }
                      </div>

                      <div class="asset-template-popup--summary-section-template-sub-section">
                        <iot-platform-ui-detail-popup-section-header
                          [color]="'#394c5a'"
                          [headerTitle]="'IOT_DICTIONARY.TAGS' | translate | uppercase"
                          [separatorLinePosition]="'beneath'"
                        ></iot-platform-ui-detail-popup-section-header>

                        <mat-chip-set>
                          @for (tag of selectedTemplate.template.tags; track $index) {
                            <iot-platform-ui-chip [chipButton]="{ display: false }" [chip]="tag" [isTag]="true"></iot-platform-ui-chip>
                          }
                        </mat-chip-set>
                      </div>
                    </div>

                    <div class="asset-template-popup--summary-section-column" fxFlex="50%" fxLayout="column" fxLayoutGap="6px">
                      <div class="asset-template-popup--summary-section-template-sub-section">
                        <iot-platform-ui-detail-popup-section-header
                          [color]="'#394c5a'"
                          [headerTitle]="'ASSETS.TEMPLATE_FORM.ASSET_FOLLOWED_VARIABLES' | translate | uppercase"
                          [separatorLinePosition]="'beneath'"
                        ></iot-platform-ui-detail-popup-section-header>

                        @for (followedVariable of templateFollowedVariables(); track followedVariable) {
                          <div class="asset-template-popup--variable-info-container">
                            #{{ followedVariable.followedNumber }} - {{ followedVariable.name }} -
                            <span
                              [matTooltipDisabled]="followedVariable.unit.length <= 20"
                              [matTooltip]="followedVariable.unit"
                              matTooltipClass="regular-tooltip"
                              matTooltipPosition="above"
                              >{{ followedVariable.unit | truncate: [20, '...'] }}</span
                            >
                            - {{ followedVariable.comment }}
                          </div>
                        }
                      </div>

                      <div class="asset-template-popup--summary-section-template-sub-section">
                        <iot-platform-ui-detail-popup-section-header
                          [color]="'#394c5a'"
                          [headerTitle]="
                            'ASSETS.TEMPLATE_FORM.ASSET_RELATED_VARS' | translate: { totalVariables: selectedTemplate.template?.variables?.length } | uppercase
                          "
                          [separatorLinePosition]="'beneath'"
                        ></iot-platform-ui-detail-popup-section-header>

                        @for (variable of selectedTemplate.template?.variables; track variable) {
                          <ul class="asset-template-popup--variable-info-container">
                            {{
                              variable.name
                            }}
                            -
                            <span
                              [matTooltipDisabled]="variable.unit.length <= 20"
                              [matTooltip]="variable.unit"
                              matTooltipClass="regular-tooltip"
                              matTooltipPosition="above"
                              >{{ variable.unit | truncate: [20, '...'] }}</span
                            >
                            -
                            {{
                              variable.comment
                            }}
                          </ul>
                        }
                      </div>
                    </div>
                  </div>
                } @else {
                  <span class="fw-600 asset-template-popup--select-template-placeholder" fxFlexFill fxLayoutAlign="center center">
                    {{ 'ASSETS.TEMPLATE_FORM.SELECT_A_TEMPLATE' | translate }}
                  </span>
                }
              </section>
            </div>

            @if (templateMissingMandatoryTags()) {
              <span class="asset-template-popup--error-message">{{ 'ASSETS.TEMPLATE_FORM.MISSING_MANDATORY_TAGS' | translate }}</span>
            } @else if (duplicatedAsset()) {
              <span class="asset-template-popup--error-message">{{ 'ASSETS.TEMPLATE_FORM.DUPLICATE_ASSET_NAME_MESSAGE' | translate }}</span>
            }
          </div>
        }
      </mat-step>
    </mat-stepper>
  </mat-card-content>

  <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
    @if (stepper.selectedIndex === 0) {
      <button (click)="stepper.next()" [disabled]="firstStepForm.invalid" class="button-regular" color="accent" mat-raised-button>
        {{ 'ASSETS.CREATION_DIALOG.NEXT' | translate }}
      </button>
    } @else {
      <button (click)="stepper.previous()" class="button-regular" color="accent" mat-button>
        {{ 'ASSETS.CREATION_DIALOG.BACK' | translate }}
      </button>
      <button
        (click)="save()"
        [disabled]="secondStepForm.invalid || duplicatedAsset() || templateMissingMandatoryTags()"
        class="button-regular"
        color="accent"
        mat-raised-button
      >
        {{ 'IOT_DICTIONARY.CREATE' | translate }}
      </button>
    }
  </mat-card-actions>
</mat-card>
