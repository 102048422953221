import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component, DestroyRef, effect, inject, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { AssetTemplate } from '@iot-platform/models/i4b';
import { InfoDisplayPipe } from '@iot-platform/pipes';
import { finalize } from 'rxjs/operators';
import { CustomCellParams } from '../../../models/custom-cell.params';
import { AssetTemplateCellService } from './asset-template-cell.service';

@Component({
  imports: [MatProgressSpinner, InfoDisplayPipe],
  providers: [InfoDisplayPipe],
  selector: 'grid-engine-asset-template-cell',
  templateUrl: './asset-template-cell.component.html',
  styleUrls: ['./asset-template-cell.component.scss']
})
export class AssetTemplateCellComponent implements ICellRendererAngularComp {
  private readonly assetTemplateCellService: AssetTemplateCellService = inject(AssetTemplateCellService);
  params: WritableSignal<CustomCellParams> = signal(null);
  templateLoading: WritableSignal<boolean> = signal(false);
  assetTemplate: WritableSignal<AssetTemplate> = signal(null);
  destroyRef: DestroyRef = inject(DestroyRef);

  constructor() {
    effect(() => {
      const params = this.params();
      if (!!params?.value) {
        this.templateLoading.set(true);
        this.assetTemplateCellService
          .getAssetTemplateById(params.value)
          .pipe(
            finalize(() => this.templateLoading.set(false)),
            takeUntilDestroyed(this.destroyRef)
          )
          .subscribe((template: AssetTemplate) => {
            this.assetTemplate.set(template);
          });
      }
    });
  }

  agInit(params: CustomCellParams): void {
    this.params.set(params);
  }

  refresh(): boolean {
    return false;
  }
}
