import { Component, computed, input, output, Signal } from '@angular/core';
import { MatListItem, MatNavList } from '@angular/material/list';
import { SortUtil } from '@iot-platform/iot-platform-utils';
import { BaseUser } from '@iot-platform/models/common';

@Component({
    imports: [MatNavList, MatListItem],
    selector: 'iot4bos-ui-backoffice-users-list',
    templateUrl: './users-list.component.html',
    styleUrls: ['./users-list.component.scss', '../../style/admin.style.scss']
})
export class UsersListComponent {
  users = input<BaseUser[]>([]);

  selectUser = output<BaseUser>();

  sortedUsers: Signal<BaseUser[]> = computed(() => {
    const users = this.users();
    return users?.sort(SortUtil.sortByProperty('lastname'));
  });

  onSelectUser(selected: BaseUser) {
    this.selectUser.emit(selected);
  }
}
