import { Component, input, output, signal, WritableSignal } from '@angular/core';
import { AbstractControl, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
// FIXME this dependency should be reviewed it causes an app crash
import { ProgressBarButtonModule } from 'libs/iot-platform/iot-platform-ui/src/lib/components/progress-bar-button/progress-bar-button.module';
import { AuthValidators } from '../../validators/auth.validator';
import { LoginPasswordRulesComponent } from '../login-password-rules/login-password-rules.component';

@Component({
  imports: [
    TranslateModule,
    MatIconModule,
    ReactiveFormsModule,
    MatTooltip,
    MatInput,
    LoginPasswordRulesComponent,
    ProgressBarButtonModule,
    MatButtonModule,
    MatFormFieldModule
  ],
  selector: 'iot-platform-login-reset-password-form',
  templateUrl: './login-reset-password-form.component.html',
  styleUrls: ['./login-reset-password-form.component.scss', '../../scss/style.scss']
})
export class LoginResetPasswordFormComponent {
  form: UntypedFormGroup;
  enableGauge: WritableSignal<boolean> = signal(false);
  password: WritableSignal<string | null> = signal(null);

  username = input<string>();
  hasError = input<{ message: string }>();

  // eslint-disable-next-line  @angular-eslint/no-output-native
  cancel = output();
  sendCode = output<string>();
  changePassword = output<{ username: string; code: string; password: string }>();

  constructor() {
    this.form = new UntypedFormGroup({
      code: new UntypedFormControl(null, [Validators.required]),
      password: new UntypedFormControl(null, [Validators.required, AuthValidators.checkPassword, this.checkConfirmPassword.bind(this)]),
      confirmPassword: new UntypedFormControl(null, [Validators.required, this.checkConfirmPassword.bind(this)])
    });
  }

  get codeControl(): AbstractControl {
    return this.form.get('code');
  }

  get passwordControl(): AbstractControl {
    return this.form.get('password');
  }

  get confirmPasswordControl(): AbstractControl {
    return this.form.get('confirmPassword');
  }

  get errorPassword(): any {
    return this.form.getError('format', ['password']);
  }

  sendPasswordToRules(value: string): void {
    this.password.set(value);
  }

  checkConfirmPassword(control: UntypedFormControl): ValidationErrors | null {
    if (!this.form) {
      return null;
    }
    const password = this.form.controls.password;
    const confirmPassword = this.form.controls.confirmPassword;
    const errorsOrNull = confirmPassword.value === password.value ? null : { different: true };

    if (control === this.passwordControl) {
      this.confirmPasswordControl.setErrors(errorsOrNull);
      this.confirmPasswordControl.markAsDirty();
      return null;
    }

    return errorsOrNull;
  }

  onCancel() {
    this.cancel.emit();
  }

  onSendCode($event) {
    $event.stopPropagation();
    this.sendCode.emit(this.username());
  }

  onChangePassword() {
    this.changePassword.emit({
      username: this.username(),
      code: this.codeControl.value,
      password: this.passwordControl.value
    });
  }
}
