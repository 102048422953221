import { Component, DestroyRef, inject, signal, Signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { map, Observable, of } from 'rxjs';

export type PopupType = 'delete' | 'error' | 'reset' | 'info' | 'confirm' | 'subscribe' | 'unsubscribe';
export type PopupValue =
  | string
  | { errorType: string; errorMessage: string; errorCode: string }
  | {
      code: number;
      error: string;
    };
export type PopupData = { type: PopupType; value: PopupValue; cssClass?: string };

@Component({
    imports: [TranslateModule, MatIcon, MatButtonModule, FlexLayoutModule],
    selector: 'iot-platform-ui-popup',
    templateUrl: './popup.component.html',
    styleUrls: ['./popup.component.scss']
})
export class PopupComponent {
  public data: PopupData = inject(MAT_DIALOG_DATA);
  public dialogRef: MatDialogRef<PopupComponent> = inject(MatDialogRef<PopupComponent>);
  private readonly translateService: TranslateService = inject(TranslateService);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  //
  popupInfo = toSignal(
    this.getPopupInfo(this.data.type).pipe(
      map((data) => (data.value ? data.value : data)),
      takeUntilDestroyed(this.destroyRef)
    )
  );
  popupMessage: Signal<string> = signal(this.getMessage(this.data));

  get color() {
    return {
      important:
        this.data.type === 'error' ||
        this.data.type === 'delete' ||
        this.data.type === 'reset' ||
        this.data.type === 'confirm' ||
        this.data.type === 'unsubscribe'
    };
  }

  close(value: boolean): void {
    this.dialogRef.close(value);
  }

  private getMessage(data: any): string {
    let message = '';
    if (data.value.error?.error?.error) {
      message = data.value?.type + ' ' + this.translateService.instant(data.value.error.error.error as string);
    } else if (data.type === 'error' && data.value.hasOwnProperty('error')) {
      message = 'Unable to reach the service';
    } else if (data.value) {
      message = data.value;
    } else {
      message = 'An error has occurred, please try again later';
    }
    return message;
  }

  private getPopupInfo(type: string): Observable<any> {
    switch (type) {
      case 'delete':
        return this.translateService.get('POPUP.DELETE_TYPE');
      case 'subscribe':
        return this.translateService.get('POPUP.SUBSCRIBE_TYPE');
      case 'unsubscribe':
        return this.translateService.get('POPUP.UNSUBSCRIBE_TYPE');
      case 'error':
        return this.translateService.get('POPUP.ERROR_TYPE');
      case 'reset':
        return this.translateService.get('POPUP.RESET_TYPE');
      case 'info':
        return this.translateService.get('POPUP.INFO_TYPE');
      case 'confirm':
        return this.translateService.get('POPUP.CONFIRM_TYPE');
      default:
        return of(this.data);
    }
  }
}
