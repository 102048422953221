import { Component } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { AbstractTableEngineHeaderCellComponent } from '../abstract-table-engine-header-cell.component';

@Component({
  selector: 'i4b-table-engine-icon-header',
  templateUrl: './icon-header.component.html',
  styleUrls: ['./icon-header.component.scss'],
  imports: [FlexLayoutModule, TranslateModule, MatIcon, MatTooltip]
})
export class IconHeaderComponent extends AbstractTableEngineHeaderCellComponent<IconHeaderComponent> {}
