<div class="association-by-asset" fxLayout="column" fxLayoutGap="16px">
  @if (laoding()) {
    <iot-platform-ui-card-loader [backgroundClass]="'default-card h-82'" fxFlex />
  } @else {
    @if (association()?.assetGroups?.length || association()?.assets?.length) {
      @for (assetGroup of association()?.assetGroups; track assetGroup.id; let i = $index) {
        <div class="association-by-asset__asset-group" fxLayout="column" fxLayoutGap="16px">
          <span class="association-by-asset__name" (click)="navigateToAssetGroup.emit(assetGroup.id)"
            >{{ i + 1 }}. {{ assetGroup.name | uppercase }} ({{ assetGroup.assets.length }})</span
          >
          @for (asset of assetGroup?.assets; track asset.id) {
            <ng-container [ngTemplateOutlet]="assetTmpl" [ngTemplateOutletContext]="{ asset: asset }"></ng-container>
          } @empty {
            <ng-container [ngTemplateOutlet]="emptyAssetGroupTmpl"></ng-container>
          }
        </div>
      }
      @for (asset of association()?.assets; track asset.id) {
        <ng-container [ngTemplateOutlet]="assetTmpl" [ngTemplateOutletContext]="{ asset: asset }"></ng-container>
      }
    } @else {
      <iot-platform-ui-default-card fxFlex [backgroundClass]="'h-82'" [title]="'SITES.ASSOCIATIONS.NO_ASSOCIATED_ASSET' | translate" />
    }
  }
</div>

<ng-template #assetTmpl let-asset="asset">
  <div class="association-by-asset__asset" fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="16px" fxFlex>
    <section fxFlex="50%" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="10px">
      <iot4bos-ui-asset-card [asset]="asset" (clicked)="navigateToAsset.emit($event.id)" />
    </section>

    <section fxFlex="50%" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="10px">
      @for (device of asset.devices; track device.id) {
        <iot4bos-ui-device-card class="association-by-asset__device" [device]="device" (clicked)="navigateToDevice.emit($event.id)" />
      } @empty {
        <iot-platform-ui-default-card
          fxFlex="50%"
          fxFlex.lt-md="100%"
          [backgroundClass]="'h-82'"
          [title]="'SITES.ASSOCIATIONS.NO_ASSOCIATED_DEVICE' | translate"
        />
      }
    </section>
  </div>
</ng-template>

<ng-template #emptyAssetGroupTmpl>
  <div class="association-by-asset__asset" fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="16px" fxFlex>
    <section fxFlex="50%" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="10px">
      <iot-platform-ui-default-card
        fxFlex="50%"
        fxFlex.lt-md="100%"
        [backgroundClass]="'h-82'"
        [title]="'SITES.ASSOCIATIONS.NO_ASSOCIATED_ASSET' | translate"
      />
    </section>

    <section fxFlex="50%" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="10px">
      <iot-platform-ui-default-card
        fxFlex="50%"
        fxFlex.lt-md="100%"
        [backgroundClass]="'h-82'"
        [title]="'SITES.ASSOCIATIONS.NO_ASSOCIATED_DEVICE' | translate"
      />
    </section>
  </div>
</ng-template>
