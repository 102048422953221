import { Component, inject, Signal, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Site } from '@iot-platform/models/i4b';
import { TranslateModule } from '@ngx-translate/core';
import { isEqual } from 'lodash';
import { map, take } from 'rxjs/operators';
import { SiteInfoFormComponent } from '../site-info-form/site-info-form.component';

@Component({
    selector: 'iot4bos-ui-site-info-form-dialog',
    templateUrl: './site-info-form-dialog.component.html',
    styleUrls: ['./site-info-form-dialog.component.scss'],
    imports: [FlexLayoutModule, SiteInfoFormComponent, MatCardModule, MatIcon, TranslateModule, MatToolbarModule, MatButtonModule]
})
export class SiteInfoFormDialogComponent {
  data: { site?: Site } = inject(MAT_DIALOG_DATA);
  public dialogRef: MatDialogRef<SiteInfoFormDialogComponent> = inject(MatDialogRef<SiteInfoFormDialogComponent>);

  form = new FormGroup({ site: new FormControl(this.data.site, [Validators.required]) });

  isSameSite = signal(true);

  initialFormState: Signal<string> = toSignal(
    this.form.valueChanges.pipe(
      take(1),
      map((changes) => JSON.stringify(changes))
    )
  );

  get siteControl() {
    return this.form.get('site');
  }

  onChangedForm(event: Site | null): void {
    this.siteControl.patchValue(event);
    this.isSameSite.set(isEqual(JSON.stringify(this.form.getRawValue()), this.initialFormState()));
  }

  close(): void {
    this.dialogRef.close();
  }

  save(): void {
    this.dialogRef.close(this.siteControl.value);
  }
}
