import { Injectable } from '@angular/core';
import { ApiFeatureSelector, BusinessProfile, Filter, Pagination } from '@iot-platform/models/common';
import { createSelector } from '@ngrx/store';
import * as fromBusinessProfiles from '../reducers/business-profiles.reducer';

@Injectable({
  providedIn: 'root'
})
export class AdminBusinessProfilesSelectors extends ApiFeatureSelector<fromBusinessProfiles.State, BusinessProfile, Pagination, Filter> {
  constructor() {
    super(fromBusinessProfiles.adminBusinessProfilesFeature.selectAdminBusinessProfilesState, fromBusinessProfiles.adapter);
  }

  selectSearchString = createSelector(this.selectState, (state) => state.searchString);
}
