import { UpperCasePipe } from '@angular/common';
import { Component, computed, inject, input, output } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { BaseUser, BusinessProfile, FavoriteView } from '@iot-platform/models/common';
import { TranslateModule } from '@ngx-translate/core';
import * as moment from 'moment';

// tslint:disable-next-line:max-line-length
import { RolesListComponent } from '../../../../components/roles-list/roles-list.component';
import { UsersListComponent } from '../../../../components/users-list/users-list.component';

@Component({
  imports: [
    FlexLayoutModule,
    TranslateModule,
    UpperCasePipe,
    MatCardModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatTooltipModule,
    MatIcon,
    UsersListComponent,
    MatToolbarModule,
    RolesListComponent
  ],
  selector: 'iot4bos-ui-backoffice-business-profiles-detail',
  templateUrl: './business-profiles-detail.component.html',
  styleUrls: ['./business-profiles-detail.component.scss', '../../../../style/admin.style.scss']
})
export class BusinessProfilesDetailComponent {
  private dialog: MatDialog = inject(MatDialog);

  businessProfile = input<BusinessProfile>();
  users = input<BaseUser[]>([]);
  roles = input<any[]>([]);
  favoriteViews = input<FavoriteView[]>([]);
  canCreateBusinessProfile = input<boolean>(false);
  canUpdateBusinessProfile = input<boolean>(false);
  rolesPendingStatus = input<boolean>(false);
  membersPendingStatus = input<boolean>(false);

  addMemberToProfile = output<true>();
  addRoleToProfile = output<true>();
  timezoneName = computed(() => {
    const bp = this.businessProfile();
    if (bp.timezoneDetails) {
      return `${bp.timezoneDetails.name} ${moment().tz(bp.timezoneDetails.name).format('Z')}`;
    } else {
      return 'PLEASE UPDATE THE TIMEZONE';
    }
  });

  onManageProfileMembers() {
    this.addMemberToProfile.emit(true);
  }

  onManageProfileRoles() {
    this.addRoleToProfile.emit(true);
  }
}
