import { UpperCasePipe } from '@angular/common';
import { Component, computed, inject, input, output, Signal } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AuthorizationConcept, AuthorizationService, AuthorizationType } from '@iot-platform/auth';
import { PopupComponent, TagCategoryListByConceptModule } from '@iot-platform/iot-platform-ui';

import { BaseUser, BusinessProfile, Organization, Role, TagCategory } from '@iot-platform/models/common';
import { TranslateModule } from '@ngx-translate/core';
import { BusinessProfilesListComponent } from '../../../../components/business-profiles-list/business-profiles-list.component';
import { EditOrganizationDialogComponent } from '../../../../components/dialogs/edit-organization-dialog/edit-organization-dialog.component';
import { EditRoleDialogComponent } from '../../../../components/dialogs/edit-role-dialog/edit-role-dialog.component';
import { OrganizationConceptsSelectionComponent } from '../../../../components/organization-concepts-selection/organization-concepts-selection.component';
import { RolesCrudComponent } from '../../../../components/roles-crud/roles-crud.component';
import { RolesListComponent } from '../../../../components/roles-list/roles-list.component';
import { UsersListComponent } from '../../../../components/users-list/users-list.component';

@Component({
  imports: [
    FlexLayoutModule,
    TranslateModule,
    MatToolbarModule,
    MatButtonModule,
    MatIcon,
    MatTooltipModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    BusinessProfilesListComponent,
    RolesListComponent,
    RolesCrudComponent,
    UsersListComponent,
    OrganizationConceptsSelectionComponent,
    TagCategoryListByConceptModule,
    UpperCasePipe
  ],
  selector: 'iot4bos-ui-backoffice-organization-detail',
  templateUrl: './organization-detail.component.html',
  styleUrls: ['./organization-detail.component.scss', '../../../../style/admin.style.scss']
})
export class OrganizationDetailComponent {
  // Injections
  private readonly dialog: MatDialog = inject(MatDialog);
  private readonly authz: AuthorizationService = inject(AuthorizationService);
  // Inputs
  selectedOrganization = input<Organization>();
  roles = input<Role[]>([]);
  businessProfiles = input<BusinessProfile[]>([]);
  tags = input<TagCategory[]>([]);
  administrators = input<BaseUser[]>([]);
  isTopLevelAdmin = input<boolean>(false);
  isCurrentUserAdmin = input<boolean>(false);
  adminConceptsFromEntitySession = input<string[]>();
  selectedRole = input<Role>();
  businessProfilesPendingStatus = input<boolean>();
  rolesPendingStatus = input<boolean>();
  tagsPendingStatus = input<boolean>();
  administratorsPendingStatus = input<boolean>();
  // Outputs
  authorizationsChanged = output<Role>();
  selectRole = output<Role>();
  renameRole = output<Role>();
  addBusinessProfile = output<Organization>();
  addRole = output<Organization>();
  addAdministratorToOrganization = output<boolean>();
  updateOrganization = output<Organization>();
  addOrganization = output<Organization>();
  deleteOrganization = output<Organization>();
  deleteBusinessProfile = output<BusinessProfile>();
  selectBusinessProfile = output<BusinessProfile>();
  deleteRole = output<Role>();
  manageTags = output<{ concept: string; organization: Organization; conceptList: string[] }>();
  manageConcepts = output<{ organization: Organization; adminConceptsFromEntitySession: any[] }>();
  lockUnlockOrganization = output<{ organization: Organization; isLocked: boolean }>();
  // Component variables
  topLevelAdmin: Signal<{ isAdmin: boolean; topLevelEntitySelected: boolean }> = computed(() => {
    const isAdmin = this.isTopLevelAdmin();
    const organization = this.selectedOrganization();
    return {
      isAdmin,
      topLevelEntitySelected: organization?.parentId === null || (organization?.level === 2 && organization?.name === 'Air Liquide')
    };
  });
  tagCategoryConcepts: Signal<string[]> = computed(() => {
    const topLevelAdmin = this.topLevelAdmin();
    if (topLevelAdmin.isAdmin && topLevelAdmin.topLevelEntitySelected) {
      return ['site', 'asset', 'device', 'event'];
    } else {
      return ['site', 'asset', 'device'];
    }
  });
  canCreateOrganization: boolean;
  canUpdateOrganization: boolean;
  canDeleteOrganization: boolean;
  canCreateBusinessProfile: boolean;
  canUpdateBusinessProfile: boolean;
  canDeleteBusinessProfile: boolean;
  canReadBusinessProfile: boolean;
  canCreateTags: boolean;
  canReadTags: boolean;
  canUpdateTags: boolean;
  canDeleteTags: boolean;

  constructor() {
    this.canCreateOrganization = this.authz.applyAuthorization(AuthorizationConcept.ORGANIZATION_TREE, AuthorizationType.CREATE);
    this.canUpdateOrganization = this.authz.applyAuthorization(AuthorizationConcept.ORGANIZATION_TREE, AuthorizationType.UPDATE);
    this.canDeleteOrganization = this.authz.applyAuthorization(AuthorizationConcept.ORGANIZATION_TREE, AuthorizationType.DELETE);
    this.canCreateBusinessProfile = this.authz.applyAuthorization(AuthorizationConcept.BUSINESS_PROFILE, AuthorizationType.CREATE);
    this.canUpdateBusinessProfile = this.authz.applyAuthorization(AuthorizationConcept.BUSINESS_PROFILE, AuthorizationType.UPDATE);
    this.canDeleteBusinessProfile = this.authz.applyAuthorization(AuthorizationConcept.BUSINESS_PROFILE, AuthorizationType.DELETE);
    this.canReadBusinessProfile = this.authz.applyAuthorization(AuthorizationConcept.BUSINESS_PROFILE, AuthorizationType.READ);
    this.canCreateTags = this.authz.applyAuthorization(AuthorizationConcept.TAG, AuthorizationType.CREATE);
    this.canReadTags = this.authz.applyAuthorization(AuthorizationConcept.TAG, AuthorizationType.READ);
    this.canUpdateTags = this.authz.applyAuthorization(AuthorizationConcept.TAG, AuthorizationType.UPDATE);
    this.canDeleteTags = this.authz.applyAuthorization(AuthorizationConcept.TAG, AuthorizationType.DELETE);
  }

  onEditRoleAuthorizations(role: Role) {
    this.dialog
      .open(EditRoleDialogComponent, {
        data: {
          role: Object.assign({}, role, { rights: { ...role.rights } }),
          adminConceptsFromEntitySession: this.adminConceptsFromEntitySession(),
          isAdmin: this.isCurrentUserAdmin()
        },
        disableClose: true,
        width: '550px'
      })
      .afterClosed()
      .subscribe((roleToUpdate) => {
        if (roleToUpdate) {
          this.authorizationsChanged.emit(roleToUpdate);
        }
      });
  }

  editOrganizationName(organization: Organization) {
    this.dialog
      .open(EditOrganizationDialogComponent, {
        data: {
          organization,
          parentOrganizationId: this.selectedOrganization().parentId
        },
        width: '500px'
      })
      .afterClosed()
      .subscribe((organizationToUpdate) => {
        if (organizationToUpdate) {
          this.updateOrganization.emit(organizationToUpdate);
        }
      });
  }

  onDeleteOrganizationButtonClick(organization: Organization) {
    this.confirmDeletion(organization);
  }

  onManageTags(concept: string) {
    this.manageTags.emit({
      concept,
      organization: this.selectedOrganization(),
      conceptList: this.tagCategoryConcepts()
    });
  }

  getTagCategoriesByConcept(concept: string): TagCategory[] {
    return this.tags().filter((t) => t.concept.toLowerCase() === concept);
  }

  getTagsTotal(tags: TagCategory[]) {
    return tags.reduce((acc, value) => (acc = acc + value.labels.length), 0);
  }

  onLockUnlockOrganization(isLocked: boolean): void {
    if (this.isTopLevelAdmin()) {
      this.lockUnlockOrganization.emit({
        organization: this.selectedOrganization(),
        isLocked
      });
    }
  }

  private confirmDeletion(organizationToDelete: Organization) {
    const dialogRef = this.dialog.open(PopupComponent, {
      width: '500px',
      disableClose: true,
      data: { type: 'delete', value: organizationToDelete.name }
    });

    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.deleteOrganization.emit(organizationToDelete);
      }
    });
  }
}
