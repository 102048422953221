import { Component, effect, inject, input, output, Signal, signal, untracked, WritableSignal } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { combineLatest, switchMap } from 'rxjs';

import { ImageSelectorService } from './services/image-selector.service';

@Component({
  imports: [FlexLayoutModule, MatButtonModule, MatIcon],
  selector: 'iot-platform-ui-image-selector',
  templateUrl: './image-selector.component.html',
  styleUrls: ['./image-selector.component.scss']
})
export class ImageSelectorComponent {
  private readonly imageSelectorService: ImageSelectorService = inject(ImageSelectorService);
  galleryPath = input<string>();
  lastImageSaved = input<string>();
  showSlider = input<boolean>(true);
  imageCategory = input<string>();
  excludedImages = input<string[]>([]);
  selectImage = output<string>();
  imagesReady = output<string[]>();
  imagesDisplayed: WritableSignal<string[]> = signal([]);
  imagePreview: WritableSignal<string | null> = signal(null);
  index: WritableSignal<number> = signal(0);
  galleryPath$ = toObservable(this.galleryPath);
  imageCategory$ = toObservable(this.imageCategory);
  excludedImages$ = toObservable(this.excludedImages);
  images: Signal<string[]> = toSignal(
    combineLatest([this.galleryPath$, this.imageCategory$, this.excludedImages$]).pipe(
      switchMap(([path, cat, excluded]: [string, string, string[]]) => this.imageSelectorService.getGalleryByImageCategory(path, cat, excluded))
    )
  );

  constructor() {
    this.initLastImageSavedEffect();
    this.initGalleryEffect();
  }

  onSelect(photo: string): void {
    this.imagePreview.set(photo);
    this.selectImage.emit(photo);
  }

  showNext(): void {
    if (this.index() === this.images().length - 4) {
      this.imagesDisplayed.set(this.images().slice(this.index()));
    } else {
      this.index.update((value) => {
        value++;
        return value;
      });
      this.imagesDisplayed.set(this.images().slice(this.index()));
    }
  }

  showPrevious(): void {
    if (this.index() === 0) {
      this.imagesDisplayed.set(this.images().slice(this.index()));
    } else {
      this.index.update((value) => {
        value--;
        return value;
      });
      this.imagesDisplayed.set(this.images().slice(this.index()));
    }
  }

  private initLastImageSavedEffect(): void {
    effect(() => {
      const lastImage: string = this.lastImageSaved();
      this.imagePreview.set(lastImage);
    });
  }

  private initGalleryEffect(): void {
    effect(() => {
      const images: string[] = this.images();
      this.imagesReady.emit(images ?? []);

      if (images) {
        untracked(() => {
          this.imagePreview.set(this.lastImageSaved() ?? images[0]);

          const index = this.lastImageSaved() && images.indexOf(this.lastImageSaved()) > -1 ? images.indexOf(this.lastImageSaved()) : 0;

          if (images.length > 4) {
            if (index <= images.length - 4) {
              this.index.set(index);
              this.imagesDisplayed.set(images.slice(index));
            } else {
              this.index.set(images.length - 4);
              this.imagesDisplayed.set(images.slice(images.length - 4));
            }
          } else {
            this.imagesDisplayed.set(images);
          }
        });
      }
    });
  }
}
