import { IFilterAngularComp } from '@ag-grid-community/angular';
import { IDoesFilterPassParams, IFilterParams } from '@ag-grid-community/core';
import { Component, computed, ElementRef, inject, Injector, Signal, signal, WritableSignal } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FormControlType, FormField } from '@iot-platform/iot-platform-ui';
import { I4BGridFilterOptions } from '@iot-platform/models/grid-engine';
import { get } from 'lodash';

export interface FilterCellOperator {
  label: string;
  value: string;
}

export enum FilterCellFormControlNames {
  OPERATORS = 'operators',
  SEARCH_TERM = 'searchTerm'
}

export const AG_GRID_FILTER_SELECT_INPUT_CSS_CLASS_NAME =
  'ag-filter-select ag-labeled ag-label-align-left ag-select ag-wrapper ag-picker-field-wrapper ag-picker-expanded ag-has-popup-positioned-under';
export const AG_GRID_FILTER_SELECT_INPUT_OPTION_CSS_CLASS_NAME = 'ag-custom-component-popup'; // this is important to prevent event default
export const AG_GRID_FILTER_TEXT_INPUT_CSS_CLASS_NAME = 'ag-filter-from ag-filter-filter ag-labeled ag-label-align-left ag-text-field ag-input-field';

@Component({
    template: '',
    standalone: false
})
export abstract class AbstractCellFilterComponent implements IFilterAngularComp {
  public filterParams!: IFilterParams;
  public lastValue: any;
  public elementRef!: ElementRef;
  public operators: WritableSignal<FilterCellOperator[]> = signal([]);
  public fields: Signal<Partial<FormField>[]>;
  public form = new FormGroup({
    [FilterCellFormControlNames.OPERATORS]: new FormControl<FilterCellOperator>(null),
    [FilterCellFormControlNames.SEARCH_TERM]: new FormControl<any>(null)
  });
  public operatorField = {
    type: FormControlType.DROP_DOWN_LIST,
    name: signal(FilterCellFormControlNames.OPERATORS),
    // label: signal('AG_GRID.ariaFilteringOperator'),
    items: this.operators,
    inputCssClassName: signal(AG_GRID_FILTER_SELECT_INPUT_CSS_CLASS_NAME),
    optionCssClassName: signal(AG_GRID_FILTER_SELECT_INPUT_OPTION_CSS_CLASS_NAME), // this is important to prevent event default
    fxFlex: signal('100%'),
    selectionChange: () => this.onChangeOperator(),
    trackBy: (item: FilterCellOperator) => item?.value,
    displayBy: (item: FilterCellOperator) => `AG_GRID.${item?.label}`,
    initialItem: computed(() => {
      const operators = this.operators();
      return get(operators, '[0]');
    })
  };
  public searchTermField = {
    type: FormControlType.TEXT,
    name: signal(FilterCellFormControlNames.SEARCH_TERM),
    label: signal('AG_GRID.filterOoo'),
    inputCssClassName: signal(AG_GRID_FILTER_TEXT_INPUT_CSS_CLASS_NAME),
    fxFlex: signal('100%'),
    ready: ({ ref }) => {
      this.elementRef = ref;
    },
    valueChange: (value: string) => this.onChange(value)
  };
  protected readonly injector: Injector = inject(Injector);

  constructor() {
    this.fields = computed(() => [this.operatorField, this.searchTermField]);
  }

  get operatorControl() {
    return this.form.get(FilterCellFormControlNames.OPERATORS);
  }

  get searchTermControl() {
    return this.form.get(FilterCellFormControlNames.SEARCH_TERM);
  }

  abstract handleDoesFilterPass(params: IDoesFilterPassParams): boolean;

  agInit(params: IFilterParams & { filterOptions: I4BGridFilterOptions[] }): void {
    this.filterParams = params;
    const operators = params.filterOptions.map((value) => ({ label: value, value }));
    this.operators.set(operators);
    this.operatorControl.setValue(operators[0]);
  }

  isFilterActive(): boolean {
    return this.lastValue != null && this.lastValue !== undefined;
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    return this.handleDoesFilterPass(params);
  }

  getModel(): any {
    if (!this.isFilterActive()) {
      return null;
    }
    return { value: this.lastValue };
  }

  setModel(model: any): void {
    this.lastValue = model ? model.value : null;
  }

  afterGuiAttached(/* params: IAfterGuiAttachedParams */): void {
    window.setTimeout(() => {
      this.elementRef?.nativeElement?.focus();
    });
  }

  onChange(newValue: any): void {
    if (this.lastValue !== newValue) {
      this.lastValue = newValue;
      this.filterParams.filterChangedCallback();
    }
  }

  onChangeOperator() {
    this.filterParams.filterChangedCallback();
  }

  reset() {
    this.lastValue = null;
    this.operatorControl.reset(this.operators()[0]);
    this.searchTermControl.reset();
    this.filterParams.filterChangedCallback();
  }
}
