<form [formGroup]="form" fxLayout="row" fxLayoutGap="20px">
  @if (!data()?.multiSelect) {
    @for (input of data()?.inputs; track inputIndex; let inputIndex = $index) {
      <div>
        @if (input.display && !showLoader()) {
          <mat-form-field class="select-field" color="accent">
            <mat-label>{{ 'FILTER_ENGINE.TAG_PLACEHOLDER_CATEGORY' | translate }}</mat-label>
            <mat-select
              (selectionChange)="onSelectionChange(input, form.get(input.id).value)"
              [formControlName]="input.id"
              data-cy="iot-platform-ui-dynamic-list-field-multiple-select"
            >
              @for (option of input.display; track optionIndex1; let optionIndex1 = $index) {
                <mat-option [value]="option" [disabled]="disabled()" data-cy="iot-platform-ui-dynamic-list-field-multiple-select-option"
                >{{ option[input.selectByProperty] }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        }
        @if (showLoader() && !input.parentListId) {
          <mat-progress-spinner [diameter]="20" color="accent" mode="indeterminate" />
        }
      </div>
    }
  } @else {
    <mat-form-field class="select-field" color="accent">
      <mat-label fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="5px">
        <span class="filter-label" fxFlex="90">{{ data()?.criteriaLabel | translate }}</span>
        @if (showLoader() && !categoryList()?.parentListId) {
          <mat-progress-spinner [diameter]="16" color="accent" fxFlex="10" mode="indeterminate" />
        }
      </mat-label>
      <mat-select
        (selectionChange)="onSelectionChange(categoryList(), form.get(categoryList()?.id).value)"
        [formControlName]="categoryList()?.id"
        data-cy="iot-platform-ui-dynamic-list-field-multiple-select"
      >
        @if (categoryList()?.display && !showLoader()) {
          @for (option of categoryList()?.display; track optionIndex2; let optionIndex2 = $index) {
            <mat-option [value]="option" [disabled]="disabled()" data-cy="iot-platform-ui-dynamic-list-field-multiple-select-option"
            >{{ option[categoryList()?.selectByProperty] }}
            </mat-option>
          }
        }
      </mat-select>
    </mat-form-field>
    @if (labelList()?.display) {
      <iot-platform-ui-async-autocomplete-multiple-selects
        (selectionChanged)="onMultiSelectionChange($event)"
        [currentFilters]="currentFilters()"
        [criteriaKey]="data()?.criteriaKey"
        [data]="labelList()?.display"
        [displayKey]="labelList()?.selectByProperty"
        [displaySearchIcon]="false"
        [filterKey]="labelList()?.skipAutoCompleteFilterCriteria ? null : labelList()?.selectByProperty"
        [filterBy]="data()?.filterBy"
        [minLength]="1"
        [placeholder]="labelList()?.placeholder"
        [showSpinner]="showLoader()"
        [clearOnSelect]="true"
        [multiSelect]="data()?.multiSelect"
        [disabled]="disabled()"
        [disableOnlyOptions]="true"
      />
    }
  }
</form>
