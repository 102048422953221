import { Role } from '@iot-platform/models/common';
import { createActionGroup, props } from '@ngrx/store';

export const AdminBusinessProfilesRolesActions = createActionGroup({
  source: 'Admin Business Profiles Roles',
  events: {
    'Load Roles by Business Profile': props<{ businessProfileId: string }>(),
    'Load Roles by Business Profile Success': props<{ roles: Role[] }>(),
    'Load Roles by Business Profile Failure': props<{ error: any }>(),
    'Add Role To Business Profile': props<{ businessProfileId: string; roleToAdd: Role }>(),
    'Add Role To Business Profile Success': props<{ addedRole: Role }>(),
    'Add Role To Business Profile Failure': props<{ error: any }>(),
    'Remove Role From Business Profile': props<{ businessProfileId: string; roleToRemove: Role }>(),
    'Remove Role From Business Profile Success': props<{ removedRole: Role }>(),
    'Remove Role From Business Profile Failure': props<{ error: any }>()
  }
});
