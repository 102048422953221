import { Component, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'iot-platform-ui-drag-drop-placeholder',
  template: `
    <ng-template>
      <ng-content />
    </ng-template>
  `
})
export class DragDropPlaceholderComponent {
  @ViewChild(TemplateRef) public template!: TemplateRef<any>;
}
