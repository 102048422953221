import { Component, computed, Signal } from '@angular/core';
import { InfoDisplayPipe, NumberFormatPipe } from '@iot-platform/pipes';
import { AbstractTableEngineCellComponent } from '../abstract-table-engine-cell.component';

@Component({
  selector: 'i4b-table-engine-asset-variable-threshold-cell',
  templateUrl: './asset-variable-threshold-cell.component.html',
  styleUrls: ['./asset-variable-threshold-cell.component.scss'],
  imports: [NumberFormatPipe, InfoDisplayPipe]
})
export class AssetVariableThresholdCellComponent extends AbstractTableEngineCellComponent<AssetVariableThresholdCellComponent> {
  thresholdValue: Signal<number | null> = computed(() => {
    const data = this.data();
    const cellOptions = this.cellOptions();
    if (!!data.length && cellOptions?.position > 0) {
      return data[cellOptions?.position - 1]?.value;
    }
    return null;
  });
}
