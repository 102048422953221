import { Component } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { NumberFormatPipe } from '@iot-platform/pipes';
import { AbstractTableEngineCellComponent } from '../abstract-table-engine-cell.component';

@Component({
  selector: 'i4b-table-engine-comment-cell',
  templateUrl: './comment-cell.component.html',
  styleUrls: ['./comment-cell.component.scss'],
  imports: [MatIcon, NumberFormatPipe]
})
export class CommentCellComponent extends AbstractTableEngineCellComponent<CommentCellComponent> {
  onClick(event: MouseEvent): void {
    event?.stopPropagation();
    this.dispatchCellEvent({ type: 'openComments', options: {}, rawData: this.rawData() });
  }
}
