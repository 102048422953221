import { Component, DestroyRef, effect, inject, signal, Signal, untracked, WritableSignal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AbstractControl, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule, MatPrefix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatProgressBar } from '@angular/material/progress-bar';
import { MatOption, MatSelect } from '@angular/material/select';
import { MatToolbar } from '@angular/material/toolbar';
import { ExportType } from '@iot-platform/models/grid-engine';
import { DateFormatPipe } from '@iot-platform/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { GridExportService } from '../../../services/grid-export.service';

@Component({
  selector: 'grid-engine-grid-export-dialog',
  templateUrl: './grid-export-dialog.component.html',
  styleUrls: ['./grid-export-dialog.component.scss'],
  imports: [
    FlexLayoutModule,
    TranslateModule,
    MatCardModule,
    MatToolbar,
    MatProgressBar,
    MatFormFieldModule,
    MatSelect,
    MatOption,
    MatPrefix,
    MatInput,
    ReactiveFormsModule,
    MatButton
  ],
  providers: [DateFormatPipe]
})
export class GridExportDialogComponent {
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  private readonly gridExportService: GridExportService = inject(GridExportService);
  private readonly dateFormatPipe: DateFormatPipe = inject(DateFormatPipe);
  private readonly dialogRef: MatDialogRef<GridExportDialogComponent> = inject(MatDialogRef<GridExportDialogComponent>);
  progressBarValue: Signal<number> = toSignal(this.gridExportService.progressBarValue$.pipe(takeUntilDestroyed(this.destroyRef)));
  loading: Signal<boolean> = toSignal(this.gridExportService.loading$.pipe(takeUntilDestroyed(this.destroyRef)));
  form: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl(null, [Validators.required, Validators.pattern('\\S.*')]),
    type: new UntypedFormControl(this.gridExportService.currentType, [Validators.required])
  });
  types: ExportType[] = [ExportType.CSV, ExportType.XLSX];
  formState: WritableSignal<string> = signal('');
  canDownload: WritableSignal<boolean> = signal(true);
  valueChanges: Signal<any> = toSignal(
    this.form.valueChanges.pipe(
      map(() => ({
        name: this.name?.getRawValue(),
        type: this.type?.getRawValue()
      })),
      takeUntilDestroyed(this.destroyRef)
    )
  );

  data: { totalElements: number } = inject(MAT_DIALOG_DATA);
  onExportComplete: Signal<any> = toSignal(
    this.gridExportService.onExportComplete$.pipe(
      // trigger change detection
      map((complete) => ({ ...this.data, complete })),
      takeUntilDestroyed(this.destroyRef)
    )
  );

  constructor() {
    effect(() => {
      const valueChanges = this.valueChanges();
      untracked(() => {
        const formState = this.formState();
        const newState: string = JSON.stringify(valueChanges);
        const canDownload = formState !== newState;
        this.canDownload.set(canDownload);
        if (canDownload) {
          this.formState.set(newState);
        }
      });
    });

    effect(() => {
      const onExportComplete = this.onExportComplete();
      this.canDownload.set(!!onExportComplete?.complete);
      this.name.enable();
      this.type.enable();
    });
  }

  get name(): AbstractControl {
    return this.form.get('name');
  }

  get type(): AbstractControl {
    return this.form.get('type');
  }

  get time(): string {
    return this.dateFormatPipe.transform(Date.now(), 'yyyyMMDD');
  }

  onTypeChange({ value }): void {
    this.gridExportService.currentType = value;
  }

  submit(): void {
    if (!this.canDownload()) {
      this.close();
    } else if (this.form.valid) {
      this.name.disable();
      this.type.disable();
      const fileName = `${this.time}-${this.name.value}`;
      this.gridExportService.loadData(fileName);
    }
  }

  close(): void {
    this.gridExportService.destroy();
    this.dialogRef.close();
  }
}
