import { GridsDbActions } from '@iot-platform/grid-engine';
import { BaseState, Filter, INITIAL_BASE_STATE, Pagination } from '@iot-platform/models/common';
import { I4BGrid, I4BGridData, I4BGridOptions } from '@iot-platform/models/grid-engine';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createFeature, createReducer, on } from '@ngrx/store';

import { AdminBusinessProfilesGridsActions } from '../actions/business-profiles-grids.actions';
import { AdminBusinessProfilesActions } from '../actions/business-profiles.actions';

export type State = BaseState<I4BGrid<I4BGridOptions, I4BGridData>, Pagination, Filter>;

export const adapter: EntityAdapter<I4BGrid<I4BGridOptions, I4BGridData>> = createEntityAdapter<I4BGrid<I4BGridOptions, I4BGridData>>({
  selectId: (grid: I4BGrid<I4BGridOptions, I4BGridData>) => grid.id as string,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  ...INITIAL_BASE_STATE,
  pagination: { currentPage: 0, total: 0, maxPage: 0, hasMore: false, limit: 10 }
});

export const adminBusinessProfilesGridsFeature = createFeature({
  name: 'adminBusinessProfilesGrids',
  reducer: createReducer(
    initialState,
    on(AdminBusinessProfilesActions.selectOne, AdminBusinessProfilesGridsActions.loadBusinessProfileGrids, (state) => adapter.removeAll(state)),
    on(AdminBusinessProfilesGridsActions.loadBusinessProfileGridsSuccess, (state, { response }) =>
      adapter.setAll(response.data, {
        ...state,
        pagination: response.pagination
      })
    ),

    on(AdminBusinessProfilesGridsActions.addOneSuccess, (state, { added }) => adapter.addOne(added, state)),
    on(GridsDbActions.addGridSuccess, (state, { grid }) => adapter.addOne(grid as I4BGrid<I4BGridOptions, I4BGridData>, state)),

    on(AdminBusinessProfilesGridsActions.updateOneSuccess, (state, { updated }) =>
      adapter.updateOne(
        {
          id: updated.id,
          changes: updated
        },
        state
      )
    ),
    on(GridsDbActions.updateGridSuccess, (state, { grid }) =>
      adapter.updateOne(
        {
          id: grid.id,
          changes: grid as I4BGrid<I4BGridOptions, I4BGridData>
        },
        state
      )
    ),
    //
    on(AdminBusinessProfilesGridsActions.deleteOneSuccess, (state, { deleted }) =>
      adapter.removeOne(deleted.id, {
        ...state
        //   pagination: { ...state.pagination, total: state.pagination.total - 1 }
      })
    ),
    on(GridsDbActions.removeGridSuccess, (state, { removed }) =>
      adapter.removeOne(removed.id, {
        ...state
        // pagination: { ...state.pagination, total: state.pagination.total - 1 }
      })
    ),
    on(AdminBusinessProfilesGridsActions.deleteOneFailure, (state, { error }) => ({ ...state, error })),
    //
    on(
      AdminBusinessProfilesGridsActions.loadBusinessProfileGrids,
      AdminBusinessProfilesGridsActions.addOne,
      AdminBusinessProfilesGridsActions.updateOne,
      AdminBusinessProfilesGridsActions.deleteOne,
      (state) => ({ ...state, loading: true, loaded: false })
    ),
    on(
      AdminBusinessProfilesGridsActions.loadBusinessProfileGridsSuccess,
      AdminBusinessProfilesGridsActions.addOneSuccess,
      AdminBusinessProfilesGridsActions.updateOneSuccess,
      AdminBusinessProfilesGridsActions.deleteOneSuccess,
      (state) => ({ ...state, loading: false, loaded: true })
    ),
    on(
      AdminBusinessProfilesGridsActions.loadBusinessProfileGridsFailure,
      AdminBusinessProfilesGridsActions.addOneFailure,
      AdminBusinessProfilesGridsActions.updateOneFailure,
      AdminBusinessProfilesGridsActions.deleteOneFailure,
      (state, { error }) => ({ ...state, loading: false, loaded: false, error })
    ),
    on(AdminBusinessProfilesGridsActions.clearState, () => ({ ...initialState }))
  )
});
