import { Component, input } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIcon } from '@angular/material/icon';

@Component({
    imports: [FlexLayoutModule, MatIcon],
    selector: 'iot-platform-ui-breadcrumb-item',
    templateUrl: './breadcrumb-item.component.html',
    styleUrls: ['./breadcrumb-item.component.scss']
})
export class BreadcrumbItemComponent {
  entityName = input<string | null>(null);
  name = input<string | null>(null);
  icon = input<string>('');
}
