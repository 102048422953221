import { NgStyle } from '@angular/common';
import { Component, computed, effect, inject, OnInit, Signal, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatChipOption, MatChipSet } from '@angular/material/chips';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

import { AuthFacade } from '@iot-platform/auth';
import { TranslateModule } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';
import { AbstractTableEngineCellComponent } from '../abstract-table-engine-cell.component';
import { TopicSelfSubscriptionCellService } from './topic-self-subscription-cell.service';

@Component({
  selector: 'i4b-table-engine-topic-self-subscription-cell',
  templateUrl: './topic-self-subscription-cell.component.html',
  imports: [FlexLayoutModule, TranslateModule, MatChipSet, MatChipOption, MatProgressSpinner, NgStyle]
})
export class TopicSelfSubscriptionCellComponent extends AbstractTableEngineCellComponent<TopicSelfSubscriptionCellComponent> implements OnInit {
  private authFacade: AuthFacade = inject(AuthFacade);
  private topicSelfSubscriptionService: TopicSelfSubscriptionCellService = inject(TopicSelfSubscriptionCellService);
  subscribersLoaded: WritableSignal<boolean> = signal(false);
  subscribers: WritableSignal<string[]> = signal([]);
  dataToDisplay: Signal<string> = computed(() => {
    const currentUser = this.authFacade.currentUser();
    const subscribers = this.subscribers();
    return subscribers?.find((userId) => userId === currentUser?.id) ? 'TOPICS.MASTER_VIEW.SUBSCRIBED' : null;
  });

  ngOnInit(): void {
    effect(
      () => {
        const rawData = this.rawData();
        if (rawData) {
          this.topicSelfSubscriptionService
            .getSubscribersByTopic(rawData)
            .pipe(
              finalize(() => this.subscribersLoaded.set(true)),
              takeUntilDestroyed(this.destroyRef)
            )
            .subscribe((subscribers) => {
              this.subscribers.set(subscribers);
            });
        }
      },
      { injector: this.injector }
    );
  }
}
