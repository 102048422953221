import { Component, computed, Signal } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { AbstractTableEngineCellComponent } from '../abstract-table-engine-cell.component';

@Component({
  selector: 'i4b-table-engine-threshold-icon-cell',
  templateUrl: './threshold-icon-cell.component.html',
  imports: [MatTooltip, MatIcon, TranslateModule]
})
export class ThresholdIconCellComponent extends AbstractTableEngineCellComponent<ThresholdIconCellComponent> {
  maxThresholds = 5;
  hasThreshold: Signal<boolean> = computed(() => {
    const rawData = this.rawData();
    let hasThreshold = false;
    if (rawData?.thresholds) {
      hasThreshold = !!rawData.thresholds.operator && !!rawData.thresholds.values.length;
    } else {
      for (let i = 1; i <= this.maxThresholds; i++) {
        hasThreshold = hasThreshold || !!rawData['threshold' + i];
      }
    }
    return hasThreshold;
  });
}
