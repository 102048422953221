import { Component, inject } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AbstractControl, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';

import { Role } from '@iot-platform/models/common';
import { TranslateModule } from '@ngx-translate/core';
import { isEqual } from 'lodash';
import { RolesCrudModule } from '../../roles-crud/roles-crud.module';

@Component({
  imports: [
    FlexLayoutModule,
    MatCardModule,
    ReactiveFormsModule,
    MatToolbarModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    TranslateModule,
    MatIcon,
    RolesCrudModule
  ],
  selector: 'iot4bos-ui-backoffice-edit-role-dialog',
  templateUrl: './edit-role-dialog.component.html',
  styleUrls: ['./edit-role-dialog.component.scss']
})
export class EditRoleDialogComponent {
  public dialogRef: MatDialogRef<EditRoleDialogComponent> = inject(MatDialogRef<EditRoleDialogComponent>);
  public data: { role: Role; isAdmin: boolean; adminConceptsFromEntitySession: [string] } = inject(MAT_DIALOG_DATA);

  roleForm: UntypedFormGroup = new UntypedFormGroup({ roleToUpdate: new UntypedFormControl(this.data.role ?? null) });

  get roleToUpdate(): AbstractControl {
    return this.roleForm.get('roleToUpdate');
  }

  onAuthorizationsChanged(authorizationsToUpdate: any[]) {
    this.roleToUpdate.setValue(Object.assign({}, this.roleToUpdate.value, { rights: { ...authorizationsToUpdate } }));
    if (!isEqual(this.data.role.rights, this.roleToUpdate.value.rights)) {
      this.roleForm.markAsDirty();
    } else {
      this.roleForm.markAsPristine();
    }
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    this.dialogRef.close(this.roleToUpdate.value);
  }
}
