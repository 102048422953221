import { inject } from '@angular/core';
import { GridsDbActions } from '@iot-platform/grid-engine';
import { NotificationService } from '@iot-platform/notification';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { catchError, concatMap, map, of, tap } from 'rxjs';

import { BusinessProfilesService } from '../../services/business-profiles.service';
import { AdminBusinessProfilesGridsActions } from '../actions/business-profiles-grids.actions';

const addGrid$ = createEffect(
  (actions$ = inject(Actions), businessProfilesService = inject(BusinessProfilesService)) =>
    actions$.pipe(
      ofType(AdminBusinessProfilesGridsActions.addOne),
      concatMap((action) =>
        businessProfilesService.addGrid(action.toAdd).pipe(
          map((added) => AdminBusinessProfilesGridsActions.addOneSuccess({ added })),
          catchError((error) => of(AdminBusinessProfilesGridsActions.addOneFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const updateGrid$ = createEffect(
  (actions$ = inject(Actions), businessProfilesService = inject(BusinessProfilesService)) =>
    actions$.pipe(
      ofType(AdminBusinessProfilesGridsActions.updateOne),
      concatMap((action) =>
        businessProfilesService.updateGrid(action.toUpdate).pipe(
          map((updated) => AdminBusinessProfilesGridsActions.updateOneSuccess({ updated })),
          catchError((error) => of(AdminBusinessProfilesGridsActions.updateOneFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const deleteGrid$ = createEffect(
  (actions$ = inject(Actions), businessProfilesService = inject(BusinessProfilesService)) =>
    actions$.pipe(
      ofType(AdminBusinessProfilesGridsActions.deleteOne),
      concatMap((action) =>
        businessProfilesService.deleteGrid(action.toDelete).pipe(
          map((deleted) => AdminBusinessProfilesGridsActions.deleteOneSuccess({ deleted })),
          catchError((error) => of(AdminBusinessProfilesGridsActions.deleteOneFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const loadGrids$ = createEffect(
  (actions$ = inject(Actions), businessProfilesService = inject(BusinessProfilesService)) =>
    actions$.pipe(
      ofType(AdminBusinessProfilesGridsActions.loadBusinessProfileGrids),
      concatMap((action) =>
        businessProfilesService.loadGridsByBusinessProfileId(action.request).pipe(
          map((response) => AdminBusinessProfilesGridsActions.loadBusinessProfileGridsSuccess({ response })),
          catchError((error) => of(AdminBusinessProfilesGridsActions.loadBusinessProfileGridsFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const dispatchGridDbActionsAfterAddGridSuccess$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(AdminBusinessProfilesGridsActions.addOneSuccess),
      concatMap((action) => [GridsDbActions.loadGrids({ concept: action.added.masterview }), GridsDbActions.addGridSuccess({ grid: action.added })])
    ),
  { functional: true }
);

const dispatchGridDbActionsAfterUpdateGridSuccess$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(AdminBusinessProfilesGridsActions.updateOneSuccess),
      concatMap((action) => [GridsDbActions.loadGrids({ concept: action.updated.masterview }), GridsDbActions.updateGridSuccess({ grid: action.updated })])
    ),
  { functional: true }
);

const dispatchGridDbActionsAfterDeleteGridSuccess$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(AdminBusinessProfilesGridsActions.deleteOneSuccess),
      concatMap((action) => [GridsDbActions.loadGrids({ concept: action.deleted.masterview }), GridsDbActions.removeGridSuccess({ removed: action.deleted })])
    ),
  { functional: true }
);

const displayLoader$ = createEffect(
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(
        AdminBusinessProfilesGridsActions.loadBusinessProfileGrids,
        AdminBusinessProfilesGridsActions.addOne,
        AdminBusinessProfilesGridsActions.updateOne,
        AdminBusinessProfilesGridsActions.deleteOne
      ),
      tap(() => notificationService.showLoader())
    ),
  { functional: true, dispatch: false }
);

const hideLoader$ = createEffect(
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(
        AdminBusinessProfilesGridsActions.loadBusinessProfileGridsSuccess,
        AdminBusinessProfilesGridsActions.loadBusinessProfileGridsFailure,
        AdminBusinessProfilesGridsActions.addOneSuccess,
        AdminBusinessProfilesGridsActions.addOneFailure,
        AdminBusinessProfilesGridsActions.updateOneSuccess,
        AdminBusinessProfilesGridsActions.updateOneFailure,
        AdminBusinessProfilesGridsActions.deleteOneSuccess,
        AdminBusinessProfilesGridsActions.deleteOneFailure
      ),
      tap(() => notificationService.hideLoader())
    ),
  { functional: true, dispatch: false }
);

const displaySuccess$ = createEffect(
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(
        AdminBusinessProfilesGridsActions.addOneSuccess,
        AdminBusinessProfilesGridsActions.updateOneSuccess,
        AdminBusinessProfilesGridsActions.deleteOneSuccess
      ),
      tap((action: Action) => notificationService.displaySuccess(action.type))
    ),
  { functional: true, dispatch: false }
);

const displayError$ = createEffect(
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(
        AdminBusinessProfilesGridsActions.loadBusinessProfileGridsFailure,
        AdminBusinessProfilesGridsActions.addOneFailure,
        AdminBusinessProfilesGridsActions.updateOneFailure,
        AdminBusinessProfilesGridsActions.deleteOneFailure
      ),
      tap((action) => notificationService.displayError(action))
    ),
  { functional: true, dispatch: false }
);

export const adminBusinessProfilesGridsEffects = {
  addGrid$,
  updateGrid$,
  deleteGrid$,
  loadGrids$,
  dispatchGridDbActionsAfterAddGridSuccess$,
  dispatchGridDbActionsAfterUpdateGridSuccess$,
  dispatchGridDbActionsAfterDeleteGridSuccess$,
  displayError$,
  displayLoader$,
  displaySuccess$,
  hideLoader$
};
