@if (data(); as data) {
  @let options = cellOptions();
  <div
    class="cell"
    [matTooltip]="data[options?.tooltip] | dateFormat"
    matTooltipClass="regular-tooltip"
    matTooltipPosition="above"
    [matTooltipDisabled]="!options?.tooltip"
  >
    @let unit = processedUnit();
    @if (!unit && data[options?.value] !== (undefined || null)) {
      <span> {{ data[options?.value] | numberFormat | infoDisplay }} {{ data[options?.unit] }} </span>
    }
    @if (unit) {
      <span>{{ unit }}</span>
    }
    @if (data[options?.value] === (undefined || null)) {
      <span>-</span>
    }
  </div>
} @else {
  <div class="cell no-value">-</div>
}
