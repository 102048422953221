import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { NgClass } from '@angular/common';
import { Component, computed, effect, Signal, signal, WritableSignal } from '@angular/core';
import { DateIntervalUtils } from '@iot-platform/iot-platform-utils';
import { DateFormatPipe } from '@iot-platform/pipes';
import * as moment from 'moment';
import { Moment } from 'moment-timezone';
import { CustomDateCellParams } from '../../../models/custom-date-cell.params';

@Component({
  selector: 'grid-engine-date-cell',
  templateUrl: './date-cell.component.html',
  imports: [NgClass, DateFormatPipe],
  providers: [DateFormatPipe],
  styleUrls: ['./date-cell.component.scss']
})
export class DateCellComponent implements ICellRendererAngularComp {
  params: WritableSignal<CustomDateCellParams> = signal(null);

  dateFormat: Signal<string> = computed(() => {
    const params = this.params();
    return params?.cellOptions?.columnFormat ?? '';
  });

  dateTimezone: Signal<string> = computed(() => {
    const params = this.params();
    if (params?.cellOptions?.columnTimezone) {
      return params.value[params.cellOptions.columnTimezone];
    }
    return null;
  });

  removeTimezone: Signal<boolean> = computed(() => {
    const params = this.params();
    return params?.cellOptions?.removeTimezone === true;
  });

  isRecentDay: WritableSignal<boolean> = signal(false);
  isRecentHour: WritableSignal<boolean> = signal(false);
  isFutureDay: WritableSignal<boolean> = signal(false);
  isNextDay: WritableSignal<boolean> = signal(false);

  constructor() {
    effect(() => {
      const params = this.params();
      this.processDateIntervals(moment(), params?.value);
    });
  }

  agInit(params: CustomDateCellParams): void {
    this.params.set(params);
  }

  refresh(): boolean {
    return false;
  }

  private processDateIntervals(now: Moment, date: string): void {
    this.isRecentDay.set(DateIntervalUtils.isDateInThePastDay(now, date));
    this.isRecentHour.set(DateIntervalUtils.isDateInThePastHour(now, date));
    this.isNextDay.set(DateIntervalUtils.isDateInTheNextDay(now, date));
    this.isFutureDay.set(DateIntervalUtils.isDateInTheFutureDays(now, date));
  }
}
