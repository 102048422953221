import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Component, computed, inject, Signal, signal, WritableSignal } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateService } from '@ngx-translate/core';
import { get } from 'lodash';

@Component({
    selector: 'grid-engine-active-events-cell',
    templateUrl: './active-events-cell.component.html',
    imports: [MatTooltip, MatIcon],
    styleUrls: ['./active-events-cell.component.scss']
})
export class ActiveEventsCellComponent implements ICellRendererAngularComp {
  private readonly translateService: TranslateService = inject(TranslateService);
  params: WritableSignal<ICellRendererParams> = signal(null);
  tooltip: Signal<string> = computed(() => {
    const params = this.params();
    const value = get(params, ['value'], 0);
    return this.translateService.instant(value > 1 ? 'ASSETS.VARIABLE.TOOLTIPS.ACTIVE_EVENTS' : 'ASSETS.VARIABLE.TOOLTIPS.ACTIVE_EVENT', {
      totalEvents: value
    });
  });

  agInit(params: ICellRendererParams) {
    this.params.set(params);
  }

  refresh(): boolean {
    return false;
  }
}
