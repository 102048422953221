import { Component } from '@angular/core';

@Component({
  selector: 'iot-platform-ui-association-card-container',
  template: `
    <div class="iot-platform-ui-association-card-container">
      <ng-content />
    </div>
  `
})
export class AssociationCardContainerComponent {}
