import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Component, signal, WritableSignal } from '@angular/core';
import { EventSeverityDisplayComponent } from '@iot-platform/iot-platform-ui';

@Component({
    selector: 'grid-engine-severity-cell',
    templateUrl: './severity-cell.component.html',
    imports: [EventSeverityDisplayComponent],
    styleUrls: ['./severity-cell.component.scss']
})
export class SeverityCellComponent implements ICellRendererAngularComp {
  params: WritableSignal<ICellRendererParams> = signal(null);

  agInit(params: ICellRendererParams): void {
    this.params.set(params);
  }

  refresh(): boolean {
    return false;
  }
}
