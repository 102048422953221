import { NgClass } from '@angular/common';
import { Component, input, output } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIcon } from '@angular/material/icon';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatToolbar } from '@angular/material/toolbar';
import { ToolbarSize } from '@iot-platform/models/common';

@Component({
    imports: [MatIcon, MatProgressSpinner, MatToolbar, NgClass, FlexLayoutModule],
    selector: 'iot-platform-ui-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent {
  data = input<any>();
  icon = input<string>('');
  title = input<string>('');
  label = input<string>('');
  passive = input<boolean>(false);
  loading = input<boolean>(false);
  backButtonHidden = input<boolean>(false);
  size = input<ToolbarSize>(ToolbarSize.REGULAR);

  back = output();

  toolbarSize = ToolbarSize;
}
