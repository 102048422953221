import { HeaderType, I4bCellConcept, I4BCellType } from '@iot-platform/models/grid-engine';
import { ActiveEventsCellComponent } from '../components/cells/active-events-cell/active-events-cell.component';
import { AddressAccuracyCellComponent } from '../components/cells/address-accuracy-cell/address-accuracy-cell.component';
import { ArrayOfObjectsCellComponent } from '../components/cells/array-of-objects-cell/array-of-objects-cell.component';
import { AssetTemplateCellComponent } from '../components/cells/asset-template-cell/asset-template-cell.component';
import { AssetVariableCellComponent } from '../components/cells/asset-variable-cell/asset-variable-cell.component';
import { AssetVariableThresholdCellComponent } from '../components/cells/asset-variable-threshold-cell/asset-variable-threshold-cell.component';
import { AuditTrailAttributesCellComponent } from '../components/cells/audit-trail-attributes-cell/audit-trail-attributes-cell.component';
import { BasicCellComponent } from '../components/cells/basic-cell/basic-cell.component';
import { BasicLinkCellComponent } from '../components/cells/basic-link-cell/basic-link-cell.component';
import { BooleanCellComponent } from '../components/cells/boolean-cell/boolean-cell.component';
import { CallToActionCellComponent } from '../components/cells/call-to-action-cell/call-to-action-cell.component';
import { CallToActionHeaderComponent } from '../components/cells/call-to-action-header/call-to-action-header.component';
import { ColorCellComponent } from '../components/cells/color-cell/color-cell.component';
import { CommentCellComponent } from '../components/cells/comment-cell/comment-cell.component';
import { CountCellComponent } from '../components/cells/count-cell/count-cell.component';
import { CronReadableCellComponent } from '../components/cells/cron-readable-cell/cron-readable-cell.component';
import { CyclicalCallFaultCellComponent } from '../components/cells/cyclical-call-fault-cell/cyclical-call-fault-cell.component';
import { DateCellComponent } from '../components/cells/date-cell/date-cell.component';
import { DiagnosticVariableCellComponent } from '../components/cells/diagnostic-variable-cell/diagnostic-variable-cell.component';
import { DynamicDataCellComponent } from '../components/cells/dynamic-data-cell/dynamic-data-cell.component';
import { DynamicIconCellComponent } from '../components/cells/dynamic-icon-cell/dynamic-icon-cell.component';
import { EmailTemplateCellComponent } from '../components/cells/email-template-cell/email-template-cell.component';
import { EntityCellComponent } from '../components/cells/entity-cell/entity-cell.component';
import { EventDurationCellComponent } from '../components/cells/event-duration-cell/event-duration-cell.component';
import { FollowedNumberCellComponent } from '../components/cells/followed-number-cell/followed-number-cell.component';
import { GridNameCellComponent } from '../components/cells/grid-name-cell/grid-name-cell.component';
import { IconCellComponent } from '../components/cells/icon-cell/icon-cell.component';
import { LastCommandStatusCellComponent } from '../components/cells/last-command-status-cell/last-command-status-cell.component';
import { LinkedCellComponent } from '../components/cells/linked-cell/linked-cell.component';
import { NumberCellComponent } from '../components/cells/number-cell/number-cell.component';
import { OutgoingConnectorCellComponent } from '../components/cells/outgoing-connector-cell/outgoing-connector-cell.component';
import { RichVariableValueCellComponent } from '../components/cells/rich-variable-value-cell/rich-variable-value-cell.component';
import { SeverityCellComponent } from '../components/cells/severity-cell/severity-cell.component';
import { TagCellComponent } from '../components/cells/tag-cell/tag-cell.component';
import { TopicSelfSubscriptionCellComponent } from '../components/cells/topic-self-subscription-cell/topic-self-subscription-cell.component';
import { TranslatedCellComponent } from '../components/cells/translated-cell/translated-cell.component';
import { UserCellComponent } from '../components/cells/user-cell/user-cell.component';
import { VariableDispatchCellComponent } from '../components/cells/variable-dispatch-cell/variable-dispatch-cell.component';
import { BasicHeaderCellComponent } from '../components/header-cells/basic-header-cell/basic-header-cell.component';
import { EmptyHeaderCellComponent } from '../components/header-cells/empty-header-cell/empty-header-cell.component';
import { GroupHeaderCellComponent } from '../components/header-cells/group-header-cell/group-header-cell.component';
import { IconAndTextHeaderCellComponent } from '../components/header-cells/icon-and-text-header-cell/icon-and-text-header-cell.component';
import { IconHeaderCellComponent } from '../components/header-cells/icon-header-cell/icon-header-cell.component';
import { RowGroupInnerRendererCellComponent } from '../components/row-group-cells/row-group-inner-renderer-cell/row-group-inner-renderer-cell.component';

export const GRID_HEADER_HEIGHT = 38;
export const GRID_GROUP_HEADER_HEIGHT = 38;
export const GRID_ROW_HEIGHT = 38;
export const CELL_CONCEPTS_WITH_IS_DISPLAY_OPTION: string[] = [
  I4bCellConcept.FOLLOWED_VARIABLES,
  I4bCellConcept.GROUPED_VARIABLES,
  I4bCellConcept.DIAGNOSTIC_VARIABLES
];
export const FRAMEWORK_COMPONENTS = {
  [HeaderType.BASIC]: BasicHeaderCellComponent,
  [HeaderType.CALL_TO_ACTION]: CallToActionHeaderComponent,
  [HeaderType.CUSTOM_HEADER_GROUP]: GroupHeaderCellComponent,
  [HeaderType.EMPTY]: EmptyHeaderCellComponent,
  [HeaderType.ICON]: IconHeaderCellComponent,
  [HeaderType.ICON_AND_TEXT]: IconAndTextHeaderCellComponent,
  // End headers
  [I4BCellType.CUSTOM_ROW_GROUP]: RowGroupInnerRendererCellComponent,
  [I4BCellType.ACTIVE_EVENTS_CELL]: ActiveEventsCellComponent,
  [I4BCellType.ADDRESS_ACCURACY]: AddressAccuracyCellComponent,
  [I4BCellType.ARRAY_OF_OBJECTS]: ArrayOfObjectsCellComponent,
  [I4BCellType.AUDIT_TRAIL_ATTRIBUTES_CELL]: AuditTrailAttributesCellComponent,
  [I4BCellType.ASSET_TEMPLATE]: AssetTemplateCellComponent,
  [I4BCellType.ASSET_VARIABLE_CELL]: AssetVariableCellComponent,
  [I4BCellType.ASSET_VARIABLE_THRESHOLD_CELL]: AssetVariableThresholdCellComponent,
  [I4BCellType.BASIC]: BasicCellComponent,
  [I4BCellType.BASIC_LINK]: BasicLinkCellComponent,
  [I4BCellType.BOOLEAN_CELL]: BooleanCellComponent,
  [I4BCellType.CALL_TO_ACTION]: CallToActionCellComponent,
  [I4BCellType.COLOR_CELL]: ColorCellComponent,
  [I4BCellType.COMMENT]: CommentCellComponent,
  [I4BCellType.COUNT]: CountCellComponent,
  [I4BCellType.CRON_READABLE_CELL]: CronReadableCellComponent,
  [I4BCellType.CYCLICAL_CALL_FAULT]: CyclicalCallFaultCellComponent,
  [I4BCellType.DATE]: DateCellComponent,
  [I4BCellType.DIAGNOSTIC_VARIABLE]: DiagnosticVariableCellComponent,
  [I4BCellType.DYNAMIC_DATA]: DynamicDataCellComponent,
  [I4BCellType.DYNAMIC_ICON_CELL]: DynamicIconCellComponent,
  [I4BCellType.EMAIL_TEMPLATE]: EmailTemplateCellComponent,
  [I4BCellType.ENTITY_CELL]: EntityCellComponent,
  [I4BCellType.EVENT_DURATION_CELL]: EventDurationCellComponent,
  [I4BCellType.FOLLOWED_NUMBER_CELL]: FollowedNumberCellComponent,
  [I4BCellType.GRID_NAME_CELL]: GridNameCellComponent,
  [I4BCellType.ICON]: IconCellComponent,
  [I4BCellType.LAST_COMMAND_STATUS_CELL]: LastCommandStatusCellComponent,
  [I4BCellType.LINKED_CELL]: LinkedCellComponent,
  [I4BCellType.NUMBER]: NumberCellComponent,
  [I4BCellType.OUTGOING_CONNECTOR]: OutgoingConnectorCellComponent,
  [I4BCellType.RICH_VARIABLE]: RichVariableValueCellComponent,
  [I4BCellType.SEVERITY_CELL]: SeverityCellComponent,
  [I4BCellType.TAG_CELL]: TagCellComponent,
  [I4BCellType.TOPIC_SELF_SUBSCRIPTION_CELL]: TopicSelfSubscriptionCellComponent,
  [I4BCellType.TRANSLATED_CELL]: TranslatedCellComponent,
  [I4BCellType.USER_CELL]: UserCellComponent,
  [I4BCellType.VARIABLE_DISPATCH_CELL]: VariableDispatchCellComponent,
  [I4BCellType.VARIABLE_VALUE_CELL]: BasicCellComponent
};
