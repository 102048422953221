import { Component } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { AbstractTableEngineHeaderCellComponent } from '../abstract-table-engine-header-cell.component';

@Component({
  selector: 'i4b-table-engine-icon-and-text-header',
  templateUrl: './icon-and-text-header.component.html',
  styleUrls: ['./icon-and-text-header.component.scss'],
  imports: [MatIcon, TranslateModule, FlexLayoutModule]
})
export class IconAndTextHeaderComponent extends AbstractTableEngineHeaderCellComponent<IconAndTextHeaderComponent> {}
