import { Component, input, output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  imports: [MatIconModule, TranslateModule, MatInputModule, FormsModule],
  selector: 'iot4bos-ui-backoffice-filter-bp-by-name',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent {
  searchString = input<string>();

  filter = output<string>();
}
