<mat-card class="dialog-card">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar class="dialog-toolbar" color="accent">
      <h1 class="dialog-card-title">
        {{
          'MAINTENANCE_DIALOGS.START_MAINTENANCE_FORM.' + (data.elementGoingIntoMaintenance.status?.name === 'maintenance' ? 'EDIT_TITLE' : 'START_TITLE')
            | translate: { elementName: data.elementGoingIntoMaintenance.name }
        }}
      </h1>
      <button (click)="close()" class="regular-round-button" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar>
  </mat-card-title>

  <mat-card-content class="dialog-card-content">
    <form [formGroup]="scheduleMaintenanceForm" class="p-20 fs-12" fxFlex fxLayout="column" fxLayoutGap="20px">
      <div>
        {{
          'MAINTENANCE_DIALOGS.START_MAINTENANCE_FORM.EXPLANATIONS.' + data.type
            | translate: { maintenance: 'ASSETS.CARD.STATUS_LIST.maintenance' | translate, production: 'ASSETS.CARD.STATUS_LIST.production' | translate }
        }}
      </div>

      @if (data.type === 'ASSET') {
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
          <mat-radio-group
            (change)="resetSwitchbackDate()"
            aria-labelledby="is-main-stock-radio-group-label"
            color="accent"
            formControlName="delayType"
            fxFlex="40"
            fxLayout="column"
            fxLayoutGap="10px"
          >
            @for (option of switchbackOptions | keyvalue: originalOrder; track option.key) {
              <mat-radio-button [value]="option.value">{{ 'MAINTENANCE_DIALOGS.START_MAINTENANCE_FORM.' + option.value | translate }}</mat-radio-button>
            }
          </mat-radio-group>

          <div fxFlex="60" fxLayout="column" fxLayoutGap="20px">
            @switch (delayType.value) {
              @case (switchbackOptions.HOURS) {
                <mat-form-field color="accent" fxFlex="80">
                  <mat-label>{{ 'MAINTENANCE_DIALOGS.START_MAINTENANCE_FORM.SELECT_DELAY_HOURS' | translate }}</mat-label>
                  <mat-select (selectionChange)="computeDelay($event)">
                    @for (hour of hourList; track hour; let i = $index) {
                      <mat-option [value]="i + 1">{{ i + 1 }}</mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              }
              @case (switchbackOptions.DAYS) {
                <mat-form-field color="accent" fxFlex="80">
                  <mat-label>{{ 'MAINTENANCE_DIALOGS.START_MAINTENANCE_FORM.SELECT_DELAY_DAYS' | translate }}</mat-label>
                  <mat-select (selectionChange)="computeDelay($event)">
                    @for (day of dayList; track day; let i = $index) {
                      <mat-option [value]="i + 1">{{ i + 1 }}</mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              }
              @case (switchbackOptions.DATE) {
                <mat-form-field class="datepicker" color="accent">
                  <mat-label>{{ 'MAINTENANCE_DIALOGS.START_MAINTENANCE_FORM.SELECT_DATE' | translate }}</mat-label>
                  <input
                    formControlName="switchbackDate"
                    (dateChange)="computeDelay($event)"
                    (focus)="toPicker.open()"
                    [matDatepicker]="toPicker"
                    [max]="maxDate"
                    [min]="minDate"
                    matInput
                  />
                  <mat-datepicker-toggle [disableRipple]="true" [for]="toPicker" matTextSuffix>
                    <mat-icon matDatepickerToggleIcon>event</mat-icon>
                  </mat-datepicker-toggle>
                  <mat-datepicker #toPicker></mat-datepicker>
                </mat-form-field>
              }
            }

            @if (delayType.value !== SwitchbackOptions.NONE) {
              <div>
                <span>{{ 'MAINTENANCE_DIALOGS.START_MAINTENANCE_FORM.SWITCHBACK_DATE.' + data.type | translate }}</span>
                <span class="asset-schedule-maintenance-switchback-date fw-600">{{ switchbackDate.value | dateFormat | infoDisplay }}</span>
              </div>
            }
          </div>
        </div>
      }

      <div fxLayout="column" fxLayoutGap="4px">
        <div fxLayout="row" fxLayoutAlign="start center">
          <mat-icon fxLayout="row" fxLayoutAlign="start center" class="fs-16">info_outlined</mat-icon>
          <span>{{ 'IOT_DICTIONARY.OPTIONAL' | translate }}</span>
        </div>
        <span>{{ 'MAINTENANCE_DIALOGS.START_MAINTENANCE_FORM.SERVICE_ID_MESSAGE' | translate }}</span>
        <div fxLayout="row" fxFlex="50">
          <mat-form-field color="accent" class="half-width">
            <mat-label>{{ 'MAINTENANCE_DIALOGS.START_MAINTENANCE_FORM.SERVICE_ID_PLACEHOLDER' | translate }}</mat-label>
            <input matInput formControlName="serviceId" />
          </mat-form-field>
        </div>
      </div>
    </form>
  </mat-card-content>

  <mat-card-actions class="dialog-card-actions">
    <button (click)="close()" class="button-regular" mat-button>{{ 'IOT_DICTIONARY.CANCEL' | translate }}</button>
    <button (click)="confirm()" [disabled]="!scheduleMaintenanceForm.valid" class="button-regular" color="accent" mat-raised-button>
      {{ 'IOT_DICTIONARY.' + (data.elementGoingIntoMaintenance.status?.name === 'maintenance' ? 'CONFIRM' : 'SAVE') | translate }}
    </button>
  </mat-card-actions>
</mat-card>
