import { UpperCasePipe } from '@angular/common';
import { Component, DestroyRef, effect, inject, signal, Signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed, toObservable, toSignal } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { AsyncAutocompleteModule } from '@iot-platform/iot-platform-ui';
import { SortUtil } from '@iot-platform/iot-platform-utils';

import { BaseUser, BusinessProfile, CommonApiRequest } from '@iot-platform/models/common';
import { DateFormatPipe } from '@iot-platform/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { uniqBy } from 'lodash';
import { filter, forkJoin, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';

import { AdminBusinessProfilesMembersFacade } from '../../../features/admin-business-profiles/state/facades/admin-business-profiles-members.facade';
import { UsersService } from '../../../features/users/services/users.service';

@Component({
  imports: [
    FlexLayoutModule,
    MatCardModule,
    MatToolbarModule,
    MatIcon,
    AsyncAutocompleteModule,
    MatListModule,
    MatFormFieldModule,
    MatButtonModule,
    TranslateModule,
    DateFormatPipe,
    UpperCasePipe,
    MatInputModule
  ],
  selector: 'iot4bos-ui-backoffice-manage-business-profile-members-dialog',
  templateUrl: './manage-business-profile-members-dialog.component.html',
  styleUrls: ['./manage-business-profile-members-dialog.component.scss', '../../../style/admin.style.scss']
})
export class ManageBusinessProfileMembersDialogComponent {
  public data: { businessProfile: BusinessProfile } = inject(MAT_DIALOG_DATA);
  private dialogRef: MatDialogRef<ManageBusinessProfileMembersDialogComponent> = inject(MatDialogRef<ManageBusinessProfileMembersDialogComponent>);
  private readonly adminBusinessProfilesMembersFacade: AdminBusinessProfilesMembersFacade = inject(AdminBusinessProfilesMembersFacade);
  private usersService: UsersService = inject(UsersService);
  private destroy: DestroyRef = inject(DestroyRef);

  filteredCurrentMembers: WritableSignal<BaseUser[]> = signal([]);
  getUsersRequests: WritableSignal<CommonApiRequest[] | null> = signal(null);
  usersLoading: WritableSignal<boolean> = signal(false);
  unselectedUsers: WritableSignal<BaseUser[]> = signal([]);
  filteredUnselectedUsers: WritableSignal<BaseUser[]> = signal([]);
  currentMembers: Signal<BaseUser[]> = this.adminBusinessProfilesMembersFacade.all;
  allUsers: Signal<BaseUser[]> = toSignal(
    toObservable(this.getUsersRequests).pipe(
      filter((requests) => !!requests),
      switchMap((requests: CommonApiRequest[]) => forkJoin(requests.map((r) => this.usersService.getUsers(r).pipe(map((response) => response.data))))),
      map((responses) =>
        uniqBy(
          responses.reduce((acc, arr) => acc.concat(arr), []),
          'id'
        )
      ),
      takeUntilDestroyed(this.destroy)
    )
  );
  private filterUnselected;
  private filterSelected;
  currentMembersEffect = effect(() => {
    const currentMembers = this.currentMembers();
    const allUsers = this.allUsers();

    this.unselectedUsers.set(this.checkAvailableUsers(allUsers).sort(SortUtil.sortByProperty('lastname')));
    this.filteredUnselectedUsers.set(this.checkAvailableUsers(allUsers).sort(SortUtil.sortByProperty('lastname')));

    if (this.filterSelected) {
      this.filterUsers(this.filterSelected, currentMembers, true);
    } else {
      this.filteredCurrentMembers.set(currentMembers);
    }

    if (this.filterUnselected) {
      this.filterUsers(this.filterUnselected, this.unselectedUsers(), false);
    }
  });

  onSearchUsers(term: string): void {
    const request1: CommonApiRequest = {
      limit: 1000,
      filters: [
        { criteriaKey: 'userEmail', value: term },
        { criteriaKey: 'targetBusinessProfileId', value: this.data.businessProfile.id }
      ]
    };
    const request2: CommonApiRequest = {
      limit: 1000,
      filters: [
        { criteriaKey: 'userFirstName', value: term },
        { criteriaKey: 'targetBusinessProfileId', value: this.data.businessProfile.id }
      ]
    };
    const request3: CommonApiRequest = {
      limit: 1000,
      filters: [
        { criteriaKey: 'userLastName', value: term },
        { criteriaKey: 'targetBusinessProfileId', value: this.data.businessProfile.id }
      ]
    };
    this.getUsersRequests.set([request1, request2, request3]);
  }

  displayUserWrapper(user: BaseUser): string {
    return user ? `${user.firstname} ${user.lastname}` : '';
  }

  removeMemberFromProfile(memberToRemove: BaseUser) {
    this.adminBusinessProfilesMembersFacade.removeMemberFromBusinessProfile(this.data.businessProfile.id, memberToRemove);
  }

  addMemberToProfile(memberToAdd: BaseUser) {
    this.adminBusinessProfilesMembersFacade.addMemberToBusinessProfile(this.data.businessProfile.id, memberToAdd);
  }

  filterUsers(event, listToFilter: BaseUser[], areUsersSelected: boolean): void {
    if (areUsersSelected) {
      this.filterSelected = event;
      this.filteredCurrentMembers.set(this.getFilteredUsers(listToFilter, this.filterSelected));
    } else {
      this.filterUnselected = event;
      this.filteredUnselectedUsers.set(this.getFilteredUsers(listToFilter, this.filterUnselected));
    }
  }

  getFilteredUsers(listToFilter: BaseUser[], filterEvent): BaseUser[] {
    return listToFilter.filter(
      (user) =>
        user.lastname.toLowerCase().includes(filterEvent.target.value.toLowerCase()) ||
        user.firstname.toLowerCase().includes(filterEvent.target.value.toLowerCase()) ||
        user.email.toLowerCase().includes(filterEvent.target.value.toLowerCase())
    );
  }

  close(): void {
    this.dialogRef.close(true);
  }

  private checkAvailableUsers(usersByOrganization: BaseUser[]): BaseUser[] {
    const available: BaseUser[] = [];
    usersByOrganization?.forEach((user) => {
      if (this.currentMembers().indexOf(this.currentMembers().find((u) => user.id === u.id)) === -1) {
        available.push(user);
      }
    });
    return available;
  }
}
