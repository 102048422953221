import { HttpClient } from '@angular/common/http';
import { inject, Injectable, signal, WritableSignal } from '@angular/core';
import { ENVIRONMENT } from '@iot-platform/core';
import { Environment } from '@iot-platform/models/common';
import { I4BGrid, I4BGridData, I4BGridOptions } from '@iot-platform/models/grid-engine';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GridNameCellService {
  private readonly environment: Environment = inject(ENVIRONMENT);
  private readonly httpClient: HttpClient = inject(HttpClient);

  cachedGrids: WritableSignal<I4BGrid<I4BGridOptions, I4BGridData>[]> = signal([]);
  gridIds = new Set<string>();

  getGridById(gridId: string): Observable<I4BGrid<I4BGridOptions, I4BGridData>> {
    if (this.gridIds.has(gridId)) {
      return of(this.cachedGrids().find((g) => g.id === gridId));
    } else {
      return this.httpClient.get<I4BGrid<I4BGridOptions, I4BGridData>>(`${this.environment.api.url}${this.environment.api.endpoints.grid}/${gridId}`).pipe(
        tap((grid) => {
          this.gridIds.add(gridId);
          this.cachedGrids.update((cachedGrids) => [...cachedGrids, grid]);
        })
      );
    }
  }
}
