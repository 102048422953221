import { Component, input, output } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatRippleModule } from '@angular/material/core';
import { MatIcon } from '@angular/material/icon';

import { Asset } from '@iot-platform/models/i4b';
import { InfoDisplayPipe } from '@iot-platform/pipes';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    imports: [FlexLayoutModule, TranslateModule, MatIcon, MatRippleModule, InfoDisplayPipe],
    providers: [InfoDisplayPipe],
    selector: 'iot-platform-ui-detail-assets-card',
    templateUrl: './detail-assets-card.component.html',
    styleUrls: ['./detail-assets-card.component.scss']
})
export class DetailAssetsCardComponent {
  total = input<number>(0);
  assets = input<Asset[]>([]);
  selectItem = output<Asset>();

  onClick(asset: Asset): void {
    this.selectItem.emit(asset);
  }
}
