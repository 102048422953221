import { computed, inject, Injectable } from '@angular/core';
import { AuthFacade, AuthorizationConcept, AuthorizationService, AuthorizationType } from '@iot-platform/auth';
import { BaseFacade, CommonApiRequest, Filter, Pagination } from '@iot-platform/models/common';
import { I4BGrid, I4BGridData, I4BGridOptions } from '@iot-platform/models/grid-engine';
import { Store } from '@ngrx/store';
import { noop } from 'rxjs';
import { AdminBusinessProfilesGridsActions } from '../actions/business-profiles-grids.actions';
import { AdminBusinessProfilesGridsSelectors } from '../selectors/admin-business-profiles-grids.selectors';
import { AdminBusinessProfilesFacade } from './admin-business-profiles.facade';

@Injectable({
  providedIn: 'root'
})
export class AdminBusinessProfilesGridsFacade extends BaseFacade<I4BGrid<I4BGridOptions, I4BGridData>, Pagination, Filter> {
  protected readonly authFacade: AuthFacade = inject(AuthFacade);
  protected readonly authorizationService: AuthorizationService = inject(AuthorizationService);

  canCreate = computed(() => {
    this.authFacade.privileges();
    return this.authorizationService.applyAuthorization(AuthorizationConcept.BUSINESS_PROFILE, AuthorizationType.CREATE);
  });
  canRead = computed(() => {
    this.authFacade.privileges();
    return this.authorizationService.applyAuthorization(AuthorizationConcept.BUSINESS_PROFILE, AuthorizationType.READ);
  });
  canUpdate = computed(() => {
    this.authFacade.privileges();
    return this.authorizationService.applyAuthorization(AuthorizationConcept.BUSINESS_PROFILE, AuthorizationType.UPDATE);
  });
  canDelete = computed(() => {
    this.authFacade.privileges();
    return this.authorizationService.applyAuthorization(AuthorizationConcept.BUSINESS_PROFILE, AuthorizationType.DELETE);
  });

  constructor(
    protected override store: Store,
    protected override selector: AdminBusinessProfilesGridsSelectors,
    private readonly adminBusinessProfilesFacade: AdminBusinessProfilesFacade
  ) {
    super(store, selector);
  }

  getAll(request: CommonApiRequest) {
    if (this.adminBusinessProfilesFacade.currentEntity()?.id) {
      this.store.dispatch(
        AdminBusinessProfilesGridsActions.loadBusinessProfileGrids({
          request: {
            ...request,
            filters: [{ criteriaKey: 'businessProfileId', value: this.adminBusinessProfilesFacade.currentEntity()?.id }]
          }
        })
      );
    }
  }

  clearState() {
    this.store.dispatch(AdminBusinessProfilesGridsActions.clearState());
  }

  setFilters() {
    noop();
  }

  addGrid(toAdd: I4BGrid<I4BGridOptions, I4BGridData>) {
    this.store.dispatch(AdminBusinessProfilesGridsActions.addOne({ toAdd }));
  }

  updateGrid(toUpdate: I4BGrid<I4BGridOptions, I4BGridData>) {
    this.store.dispatch(AdminBusinessProfilesGridsActions.updateOne({ toUpdate }));
  }

  deleteGrid(toDelete: I4BGrid<I4BGridOptions, I4BGridData>) {
    this.store.dispatch(AdminBusinessProfilesGridsActions.deleteOne({ toDelete }));
  }
}
