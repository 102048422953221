import { AsyncPipe } from '@angular/common';
import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AbstractControl, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { AsyncAutocompleteModule } from '@iot-platform/iot-platform-ui';
import { NameValidators } from '@iot-platform/iot-platform-utils';

import { BusinessProfile, Entity, Organization } from '@iot-platform/models/common';

import { EntitiesService } from '@iot-platform/shared';

import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { Observable } from 'rxjs';

@Component({
    imports: [
        FlexLayoutModule,
        MatCardModule,
        ReactiveFormsModule,
        MatToolbarModule,
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatInputModule,
        TranslateModule,
        AsyncPipe,
        AsyncAutocompleteModule,
        MatIcon
    ],
    selector: 'iot4bos-ui-backoffice-add-organization-dialog',
    templateUrl: './add-organization-dialog.component.html',
    styleUrls: ['./add-organization-dialog.component.scss']
})
export class AddOrganizationDialogComponent implements OnInit {
  public dialogRef: MatDialogRef<AddOrganizationDialogComponent> = inject(MatDialogRef<AddOrganizationDialogComponent>);
  public data: {
    organization: Organization;
    businessProfile: BusinessProfile;
    type?: string;
  } = inject(MAT_DIALOG_DATA);
  organisationForm: UntypedFormGroup;
  sortedEntities: Entity[] = [];
  initialEntity?: Entity;
  entitiesLoading = true;
  initialName = '';
  private entitiesService: EntitiesService = inject(EntitiesService);
  private translateService: TranslateService = inject(TranslateService);
  private readonly destroy: DestroyRef = inject(DestroyRef);

  get title$(): Observable<string> {
    if (this.data.type === 'organization') {
      return this.translateService.get('ADMIN.DIALOG.ADD_ORGANIZATION.TITLE_CREATE');
    }
  }

  get nameControl(): AbstractControl {
    return this.organisationForm.get('nameControl');
  }

  get organizationControl(): AbstractControl {
    return this.organisationForm.get('organizationControl');
  }

  get withSiteStockControl(): AbstractControl {
    return this.organisationForm.get('withSiteStockControl');
  }

  get withSiteMobileFleetControl(): AbstractControl {
    return this.organisationForm.get('withMobileFleetControl') as AbstractControl;
  }

  ngOnInit() {
    this.organisationForm = new UntypedFormGroup({
      organizationControl: new UntypedFormControl(
        this.data.organization
          ? { value: '', disabled: true }
          : {
              value: '',
              disabled: false
            },
        [Validators.required]
      ),
      nameControl: new UntypedFormControl(this.data.businessProfile ? this.data.businessProfile.name : '', {
        validators: [Validators.required, Validators.maxLength(50), Validators.pattern('\\S.*')],
        asyncValidators: [NameValidators.asyncUniqueNameValidator(this.entitiesService, '')]
      }),
      withSiteStockControl: new UntypedFormControl(true),
      withMobileFleetControl: new UntypedFormControl(true)
    });

    this.entitiesService
      .getHierarchicallySortedEntities()
      .pipe(takeUntilDestroyed(this.destroy))
      .subscribe((entities) => {
        this.sortedEntities = entities;
        this.entitiesLoading = false;
        this.initialEntity = this.sortedEntities.find((e) => e.id === this.data.organization?.id);
        this.organizationControl.setValue(this.initialEntity);
      });
  }

  onEntitySelection(entity: Entity) {
    if (entity) {
      this.organizationControl.setValue(entity);
      this.nameControl.updateValueAndValidity({ onlySelf: true, emitEvent: true });
    }
  }

  resetEntity() {
    this.organizationControl.reset();
    this.nameControl.updateValueAndValidity({ onlySelf: true, emitEvent: true });
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    const newOrga: Organization = {
      name: this.nameControl.value.trim(),
      parentId: this.organizationControl.value.id,
      withSiteStock: this.withSiteStockControl.value,
      withSiteMobileFleet: this.withSiteMobileFleetControl.value
    };
    this.dialogRef.close(newOrga);
  }
}
