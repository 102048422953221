import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component, signal, WritableSignal } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { NumberFormatPipe } from '@iot-platform/pipes';
import { CustomOptionsCellParams } from '../../../models/custom-options-cell.params';

@Component({
    selector: 'grid-engine-comment-cell',
    templateUrl: './comment-cell.component.html',
    imports: [MatIcon, NumberFormatPipe],
    providers: [NumberFormatPipe],
    styleUrls: ['./comment-cell.component.scss']
})
export class CommentCellComponent implements ICellRendererAngularComp {
  params: WritableSignal<CustomOptionsCellParams> = signal(null);

  agInit(params: CustomOptionsCellParams): void {
    this.params.set(params);
  }

  refresh(): boolean {
    return false;
  }

  onDispatchEvent(event: MouseEvent): void {
    event.stopPropagation();
    const params = this.params();
    params.dispatchEvent({ type: 'openComments', options: {}, rawData: params.data });
  }
}
