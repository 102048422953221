import { Component, input } from '@angular/core';
import { NumberFormatPipe } from '@iot-platform/pipes';
import { AbstractTableEngineCellComponent } from '../abstract-table-engine-cell.component';

@Component({
  selector: 'i4b-table-engine-number-cell',
  templateUrl: './number-cell.component.html',
  styleUrls: ['./number-cell.component.scss'],
  imports: [NumberFormatPipe]
})
export class NumberCellComponent extends AbstractTableEngineCellComponent<NumberCellComponent> {
  unit = input<string>();
  isLink = input<boolean>(false);
}
