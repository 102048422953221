import { Component, input, output } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { Chat, ChatEvent } from '@iot-platform/models/common';
import { ChatBodyComponent } from '../chat-body/chat-body.component';
import { ChatFooterComponent } from '../chat-footer/chat-footer.component';
import { ChatHeaderComponent } from '../chat-header/chat-header.component';

@Component({
  imports: [ChatHeaderComponent, ChatBodyComponent, ChatFooterComponent, FlexLayoutModule],
  selector: 'iot-platform-ui-chat',
  templateUrl: './chat.component.html'
})
export class ChatComponent {
  chat = input.required<Chat>();
  dispatchAction = output<ChatEvent>();
}
