import { NgClass } from '@angular/common';
import { Component, input, output } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AbstractControl, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { EmailValidators } from '@iot-platform/iot-platform-utils';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  imports: [FlexLayoutModule, TranslateModule, NgClass, ReactiveFormsModule, MatInput, MatFormFieldModule, MatButtonModule, MatIcon],
  selector: 'iot-platform-login-send-code-form',
  templateUrl: './login-send-code-form.component.html',
  styleUrls: ['./login-send-code-form.component.scss', '../../scss/style.scss']
})
export class LoginSendCodeFormComponent {
  form: UntypedFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl(null, [Validators.required, EmailValidators.isValid()])
  });

  hasError = input<{ message: string }>();
  isI4BLogin = input<boolean>(false);

  // eslint-disable-next-line  @angular-eslint/no-output-native
  cancel = output();
  sendCode = output<string>();

  get email(): AbstractControl {
    return this.form.get('email');
  }

  onChangeEmail(value: string) {
    this.email.setValue(value.toLowerCase());
  }

  onCancel() {
    this.cancel.emit();
  }

  onSendCode() {
    this.sendCode.emit(this.email.value);
  }
}
