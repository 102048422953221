import { Component, computed, Signal } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { InfoDisplayPipe } from '@iot-platform/pipes';
import { AbstractTableEngineCellComponent } from '../abstract-table-engine-cell.component';

@Component({
  selector: 'i4b-table-engine-array-of-objects-cell',
  templateUrl: './array-of-objects-cell.component.html',
  styleUrls: ['./array-of-objects-cell.component.scss'],
  imports: [MatTooltip, InfoDisplayPipe]
})
export class ArrayOfObjectsCellComponent extends AbstractTableEngineCellComponent<ArrayOfObjectsCellComponent> {
  dataToDisplay: Signal<any> = computed(() => {
    const data = this.data();
    const cellOptions = this.cellOptions();
    return data.map((content) => (cellOptions && cellOptions.attribute ? data[cellOptions.attribute] : content)).join(', ');
  });
}
