import { Component, input, output } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { Site, SiteType } from '@iot-platform/models/i4b';
import { InfoDisplayPipe } from '@iot-platform/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { DetailPopupDisplayPropertyComponent } from '../../detail-popup/detail-popup-display-property/detail-popup-display-property.component';

@Component({
  imports: [FlexLayoutModule, TranslateModule, DetailPopupDisplayPropertyComponent, InfoDisplayPipe],
  selector: 'iot-platform-ui-site-detail-basic-info',
  templateUrl: './site-detail-basic-info.component.html',
  styleUrls: ['./site-detail-basic-info.component.scss']
})
export class SiteDetailBasicInfoComponent {
  site = input<Site | null>(null);
  displayMode = input<'vertical' | 'horizontal' | 'default'>('default');

  selectSite = output<Site>();

  protected readonly SiteType = SiteType;
}
