<mat-toolbar class="breadcrumb-container" color="primary" fxLayoutAlign="start center">
  <div (click)="onReturnToOriginClick()" class="origin" fxFlex fxLayoutAlign="center center">
    <mat-icon>keyboard_backspace</mat-icon>
  </div>

  @if (site()) {
    <iot4bos-ui-site-breadcrumb-item [site]="site()" (navigate)="navigate('site', $event)" />
  }
  @if (selectedAsset()) {
    <iot4bos-ui-breadcrumb-item [name]="selectedAsset()?.name" [icon]="'asset'" (navigate)="close.emit()" />
  }
  @if (selectedDevice()) {
    <iot4bos-ui-device-breadcrumb-item [device]="selectedDevice()" (navigate)="close.emit()" />
  }

  <span fxFlex></span>

  @if (canReadDisplayedConcept() && (selectedDevice() || selectedAsset() || (!selectedDevice() && !selectedAsset() && site()?.type !== SiteType.STOCK))) {
    <button
      mat-mini-fab
      class="reverse-accent-button"
      [matTooltip]="'BREADCRUMB.TOOLTIPS.OPEN_CONFIGURATION_CONTROL_PANEL' | translate"
      matTooltipClass="regular-tooltip"
      (click)="onDataGuardClick()"
    >
      <mat-icon color="accent" svgIcon="data_guard" [matBadge]="displayDataGuardBadge() ? '&#8288;' : ''" matBadgeSize="small" matBadgeColor="warn"></mat-icon>
    </button>
  }

  @if (!selectedDevice() && !selectedAsset() && canReadDisplayedConcept()) {
    <button
      mat-mini-fab
      class="reverse-accent-button"
      [matTooltip]="'BREADCRUMB.TOOLTIPS.REFRESH_SITE_PAGE' | translate"
      matTooltipClass="regular-tooltip"
      (click)="onRefreshSiteClick()"
    >
      <mat-icon>refresh</mat-icon>
    </button>
  } @else if (selectedDevice() && canReadDisplayedConcept()) {
    <button
      mat-mini-fab
      class="reverse-accent-button"
      [matTooltip]="'BREADCRUMB.TOOLTIPS.REFRESH_DEVICE_PAGE' | translate"
      matTooltipClass="regular-tooltip"
      (click)="onRefreshDeviceClick()"
    >
      <mat-icon>refresh</mat-icon>
    </button>
  } @else if (selectedAsset() && canReadDisplayedConcept()) {
    <button
      mat-mini-fab
      class="reverse-accent-button"
      [matTooltip]="'BREADCRUMB.TOOLTIPS.REFRESH_ASSET_PAGE' | translate"
      matTooltipClass="regular-tooltip"
      (click)="onRefreshAssetClick()"
    >
      <mat-icon>refresh</mat-icon>
    </button>
  }

  @if (canReadDisplayedConcept() && site()?.type !== SiteType.STOCK) {
    <button
      mat-mini-fab
      class="reverse-accent-button"
      [matTooltip]="'BREADCRUMB.TOOLTIPS.DISPLAY_COMMENTS' | translate"
      matTooltipClass="regular-tooltip"
      (click)="onCommentClicked()"
    >
      <mat-icon>comment</mat-icon>
    </button>
  }

  @if (site()?.type !== SiteType.STOCK) {}
  <button
    (click)="onOpenAssociations()"
    [matTooltip]="'SITES.ASSOCIATIONS.BUTTON_TOOLTIP' | translate"
    class="reverse-accent-button"
    mat-mini-fab
    matTooltipClass="regular-tooltip"
  >
    <mat-icon>account_tree</mat-icon>
  </button>
</mat-toolbar>
