import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { CustomEncoder, LocalStorageKeys, LocalStorageService } from '@iot-platform/core';
import { SortEntitiesUtil, SortUtil } from '@iot-platform/iot-platform-utils';
import { get } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FilterEngineDynamicRequestOptions } from '../models/filter-engine-dynamic-request-options.model';

@Injectable({ providedIn: 'root' })
export class DynamicListFieldService {
  private readonly httpClient: HttpClient = inject(HttpClient);
  private readonly storage: LocalStorageService = inject(LocalStorageService);

  getQueryParams(options: FilterEngineDynamicRequestOptions): HttpParams {
    let params: HttpParams = new HttpParams({ encoder: new CustomEncoder() });
    if (get(options, ['mapBusinessProfileEntityId'])) {
      try {
        const bpEntityId = get(JSON.parse(this.storage.get(LocalStorageKeys.STORAGE_BUSINESS_PROFILE_KEY)), 'entityId');
        if (bpEntityId) {
          params = params.set('entityId', bpEntityId);
        }
      } catch (ex) {
        /* do nothing */
      }
    }
    return params;
  }

  getDynamicList(options: FilterEngineDynamicRequestOptions): Observable<any[]> {
    const arrayOrObject = get(options, ['arrayOrObject'], 'object');
    return this.httpClient.get(options.url, { params: this.getQueryParams(options) }).pipe(
      map((result: any) => {
        if (result.content) {
          return this.sortDynamicList(result.content, options.sortMethod, arrayOrObject);
        } else {
          return this.sortDynamicList(result, options.sortMethod, arrayOrObject);
        }
      })
    );
  }

  sortDynamicList(data: any[], sortMethod: { type: string; property?: string }, arrayOrObject?: string): any[] {
    switch (sortMethod.type) {
      case 'alphabetically':
      case 'asc':
      case 'desc': {
        if (arrayOrObject === 'object') {
          return data.sort(SortUtil.sortBy(get(sortMethod, ['property'], 'name'), sortMethod.type !== 'desc'));
        }
        return data.sort();
      }
      case 'entityHierarchically':
        return SortEntitiesUtil.sortHierarchically(data);
      case '':
        return data.sort();
      default:
        return data;
    }
  }
}
