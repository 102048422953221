import { UpperCasePipe } from '@angular/common';
import { Component, computed, DestroyRef, inject, Injector, input, OnInit, output, signal, Signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AbstractControl, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatToolbarModule } from '@angular/material/toolbar';
import { AsyncAutocompleteModule } from '@iot-platform/iot-platform-ui';
import { Entity } from '@iot-platform/models/common';
import { AssetCategoryName, AssetModel, AssetModelName, FIXED_ASSET_MODELS, MOBILE_ASSET_MODELS, Site, SiteType } from '@iot-platform/models/i4b';
import { EntitiesService } from '@iot-platform/shared';
import { AssetsService } from '@iot-platform/shared/services';
import { TranslateModule } from '@ngx-translate/core';
import { combineLatest, EMPTY, map, startWith, switchMap, tap } from 'rxjs';

@Component({
    imports: [
        TranslateModule,
        FlexLayoutModule,
        UpperCasePipe,
        AsyncAutocompleteModule,
        ReactiveFormsModule,
        MatIconModule,
        MatToolbarModule,
        MatRadioModule,
        MatFormFieldModule,
        MatOptionModule,
        MatButtonModule,
        MatInputModule
    ],
    providers: [UpperCasePipe],
    selector: 'iot4bos-ui-asset-model-form',
    templateUrl: './asset-model-form.component.html',
    styleUrl: './asset-model-form.component.scss'
})
export class AssetModelFormComponent implements OnInit {
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  private readonly injector: Injector = inject(Injector);
  private readonly assetsService: AssetsService = inject(AssetsService);
  private readonly entitiesService: EntitiesService = inject(EntitiesService);

  site = input<Site>();
  withBlankModel = input.required<boolean>();
  fillOutAssetModel = output<{ model: AssetModel; entity: Entity; site: Site } | null>();

  form!: UntypedFormGroup;
  assetModels: WritableSignal<AssetModel[]> = signal([]);
  entities!: Signal<Entity[] | undefined>;
  initialEntity!: Signal<Entity | undefined>;
  entityValueChanges!: Signal<Entity | undefined>;
  sites!: Signal<Site[] | undefined>;
  sitesLoading: WritableSignal<boolean> = signal(false);
  siteDisabled!: Signal<boolean>;

  ngOnInit(): void {
    this.initModels();
    this.initForm();
    this.initEntities();
    this.initSites();
    this.formChangesListener();
  }

  get modelControl(): AbstractControl {
    return this.form.get('model') as AbstractControl;
  }

  get entityControl(): AbstractControl {
    return this.form.get('entity') as AbstractControl;
  }

  get siteControl(): AbstractControl {
    return this.form.get('site') as AbstractControl;
  }

  private initForm(): void {
    this.form = new UntypedFormGroup({
      model: new UntypedFormControl(this.initModelControl(), [Validators.required]),
      entity: new UntypedFormControl(this.site() ? this.site()?.entity : null, [Validators.required]),
      site: new UntypedFormControl(this.site() ? this.site() : null, [Validators.required])
    });
  }

  private initModels(): void {
    const FIXED_MODELS = this.withBlankModel() ? FIXED_ASSET_MODELS : FIXED_ASSET_MODELS.slice(1);
    if (!!this.site()) {
      if (this.site()?.type === SiteType.MOBILE_FLEET) {
        this.assetModels.set([...MOBILE_ASSET_MODELS]);
      } else {
        this.assetModels.set([...FIXED_MODELS]);
      }
    } else {
      this.assetModels.set([...FIXED_MODELS, ...MOBILE_ASSET_MODELS]);
    }
  }

  private initModelControl(): AssetModel {
    const modelName = this.site()?.type === SiteType.MOBILE_FLEET ? AssetModelName.TRAILER : AssetModelName.BULK;
    return this.assetModels().find((m) => m.name === modelName) as AssetModel;
  }

  private initEntities(): void {
    this.entities = toSignal(this.entitiesService.getHierarchicallySortedEntities().pipe(takeUntilDestroyed(this.destroyRef)), { injector: this.injector });
    this.entityValueChanges = toSignal(this.entityControl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)), { injector: this.injector });
    this.initialEntity = computed(() => {
      const entities = this.entities();
      return entities?.find((entity: Entity) => entity.id === this.site()?.entity?.id);
    });
  }

  private initSites(): void {
    this.sites = this.site()
      ? signal([])
      : toSignal(
          combineLatest([
            this.entityControl.valueChanges.pipe(
              switchMap((entity) => {
                if (entity) {
                  this.sitesLoading.set(true);
                  return this.assetsService.getSites(entity.id as string).pipe(tap(() => this.sitesLoading.set(false)));
                }
                return EMPTY;
              })
            ),
            this.modelControl.valueChanges.pipe(startWith(this.modelControl.getRawValue()))
          ]).pipe(
            map(([sites, model]) => {
              if (model.category.name === AssetCategoryName.FIXED_ASSET) {
                return sites.filter((site) => site.type === SiteType.CUSTOMER_SITE || site.type === SiteType.PRODUCTION_SITE);
              } else {
                return sites.filter((site) => site.type === SiteType.MOBILE_FLEET);
              }
            }),
            takeUntilDestroyed(this.destroyRef)
          ),
          { injector: this.injector }
        );

    this.siteDisabled = computed(() => {
      const sitesLoading = this.sitesLoading();
      const entityNotSelected = !this.entityValueChanges();
      const readonlySite = !!this.site();
      return sitesLoading || entityNotSelected || readonlySite;
    });
  }

  private formChangesListener(): void {
    this.form.events.pipe(startWith(null), takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      if (this.form.valid) {
        this.fillOutAssetModel.emit({ model: this.modelControl.getRawValue(), entity: this.entityControl.getRawValue(), site: this.siteControl.getRawValue() });
      } else {
        this.fillOutAssetModel.emit(null);
      }
    });
  }
}
