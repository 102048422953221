import { Event, Log } from '@iot-platform/models/i4b';
import { createActionGroup, props } from '@ngrx/store';

export const DeviceEventsCommentsActions = createActionGroup({
  source: 'Device Events Comments',
  events: {
    'Load comments': props<{ event: Event }>(),
    'Load comments Success': props<{ comments: Log[] }>(),
    'Load comments Failure': props<{ error: unknown }>(),

    'Add comment': props<{ event: Event; comment: string }>(),
    'Add comment Success': props<{ comment: Log; event: Event }>(),
    'Add comment Failure': props<{ error: unknown }>(),

    'Edit comment': props<{ eventId: string; comment: Log }>(),
    'Edit comment Success': props<{ comment: Log }>(),
    'Edit comment Failure': props<{ error: unknown }>(),

    'Delete comment': props<{ event: Event; commentId: string }>(),
    'Delete comment Success': props<{ commentId: string; event: Event }>(),
    'Delete comment Failure': props<{ error: unknown }>()
  }
});
