import { AssetGroup } from '@iot-platform/models/i4b';
import { createFeature, createReducer, on } from '@ngrx/store';
import { AssetGroupsActions } from '../actions';

export interface State {
  selectedAssetGroup: AssetGroup | null;
}

export const initialState: State = {
  selectedAssetGroup: null
};

export const assetGroupsFeature = createFeature({
  name: 'assetGroups',
  reducer: createReducer(
    initialState,
    on(
      AssetGroupsActions.getAssetGroupSuccess,
      AssetGroupsActions.addAssetGroupSuccess,
      AssetGroupsActions.editAssetGroupSuccess,
      AssetGroupsActions.updateRelatedAssetsSuccess,
      (state: State, { assetGroup: entity }): State => ({ ...state, selectedAssetGroup: entity })
    )
  )
});
