import { Component } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { Filter, FilterDefaultOptions } from '@iot-platform/models/common';
import { Subject } from 'rxjs';
import { DateRangeElement } from '../../../date-range';
import { DateRangeComponent } from '../../../date-range/date-range.component';
import { FilterComponent } from '../../models/filter.component';
import { AbstractFilterEngineFieldComponent } from '../abstract-filter-engine-field.component';

@Component({
    imports: [FlexLayoutModule, DateRangeComponent, MatIconButton, MatIcon],
    selector: 'iot-platform-ui-date-interval-field',
    templateUrl: './date-interval-field.component.html'
})
export class DateIntervalFieldComponent extends AbstractFilterEngineFieldComponent<FilterDefaultOptions> implements FilterComponent {
  startDate: DateRangeElement;
  endDate: DateRangeElement;
  clearDateRangeFilters$: Subject<boolean> = new Subject<boolean>();

  onDateRangeChange({ startDate, endDate }): void {
    this.startDate = startDate;
    this.endDate = endDate;
  }

  onValidation(): void {
    const data = this.data();
    const filterStart: Filter = {};
    const filterEnd: Filter = {};
    const filters: Filter[] = [];
    filterStart.criteriaKey = data.criteriaKey + 'Start';
    filterStart.criteriaLabel = data.criteriaLabel + '_START';
    filterEnd.criteriaKey = data.criteriaKey + 'End';
    filterEnd.criteriaLabel = data.criteriaLabel + '_END';
    if (this.startDate) {
      filterStart.value = this.startDate.value;
      filterStart.label = this.startDate.label;
      filters.push(filterStart);
    }
    if (this.endDate) {
      filterEnd.value = this.endDate.value;
      filterEnd.label = this.endDate.label;
      filters.push(filterEnd);
    }
    this.dispatchFilterEvent(filters);
    this.clearDateRangeFilters$.next(true);
  }
}
