import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthPageActions } from '@iot-platform/auth';
import { LocalStorageKeys, LocalStorageService } from '@iot-platform/core';
import { BusinessProfile } from '@iot-platform/models/common';
import { NotificationService } from '@iot-platform/notification';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { catchError, concatMap, map, of, tap } from 'rxjs';

import { AdminOrganizationsPageActions } from '../../../admin-organizations/state/actions';
import { BusinessProfilesService } from '../../services/business-profiles.service';
import { AdminBusinessProfilesMembersActions } from '../actions/business-profiles-members.actions';
import { AdminBusinessProfilesRolesActions } from '../actions/business-profiles-roles.actions';
import { AdminBusinessProfilesTagsActions } from '../actions/business-profiles-tags.actions';
import { AdminBusinessProfilesActions } from '../actions/business-profiles.actions';

const addOne$ = createEffect(
  (actions$ = inject(Actions), businessProfilesService = inject(BusinessProfilesService)) =>
    actions$.pipe(
      ofType(AdminBusinessProfilesActions.addOne),
      concatMap((action) =>
        businessProfilesService.saveBusinessProfile(action.toAdd).pipe(
          map((added) => AdminBusinessProfilesActions.addOneSuccess({ added })),
          catchError((error) => of(AdminBusinessProfilesActions.addOneFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const selectOneAfterAddOneSuccess$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(AdminBusinessProfilesActions.addOneSuccess),
      map((action) => AdminBusinessProfilesActions.selectOne({ toSelect: action.added }))
    ),
  { functional: true }
);

const updateOne$ = createEffect(
  (actions$ = inject(Actions), businessProfilesService = inject(BusinessProfilesService)) =>
    actions$.pipe(
      ofType(AdminBusinessProfilesActions.updateOne),
      concatMap((action) =>
        businessProfilesService.updateBusinessProfile(action.toUpdate).pipe(
          map((updated) => AdminBusinessProfilesActions.updateOneSuccess({ updated })),
          catchError((error) => of(AdminBusinessProfilesActions.updateOneFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const updateTimezoneAfterUpdateOneSuccess$ = createEffect(
  (actions$ = inject(Actions), storage = inject(LocalStorageService)) =>
    actions$.pipe(
      ofType(AdminBusinessProfilesActions.updateOneSuccess),
      tap((action: { updated: BusinessProfile }) => {
        const upd = { ...action.updated, timezone: action.updated.timezoneDetails?.offset };
        storage.set(LocalStorageKeys.STORAGE_BUSINESS_PROFILE_KEY, JSON.stringify(upd));
      })
    ),
  { functional: true, dispatch: false }
);

const deleteOne$ = createEffect(
  (actions$ = inject(Actions), businessProfilesService = inject(BusinessProfilesService)) =>
    actions$.pipe(
      ofType(AdminBusinessProfilesActions.deleteOne),
      concatMap((action) =>
        businessProfilesService.deleteBusinessProfile(action.toDelete).pipe(
          map((deleted) => AdminBusinessProfilesActions.deleteOneSuccess({ deleted })),
          catchError((error) => of(AdminBusinessProfilesActions.deleteOneFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const loadBusinessProfiles$ = createEffect(
  (actions$ = inject(Actions), businessProfilesService = inject(BusinessProfilesService)) =>
    actions$.pipe(
      ofType(AdminBusinessProfilesActions.loadBusinessProfiles),
      concatMap(() =>
        businessProfilesService.getBusinessProfiles().pipe(
          map((businessProfiles) => AdminBusinessProfilesActions.loadBusinessProfilesSuccess({ businessProfiles })),
          catchError((error) => of(AdminBusinessProfilesActions.loadBusinessProfilesFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const loadRelatedObjectsAfterSelectOne$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(AdminBusinessProfilesActions.selectOne),
      concatMap((action) => [
        AdminBusinessProfilesMembersActions.loadMembersByBusinessProfile({ businessProfileId: action.toSelect.id }),
        AdminBusinessProfilesRolesActions.loadRolesByBusinessProfile({ businessProfileId: action.toSelect.id }),
        AdminBusinessProfilesTagsActions.loadTagsByBusinessProfile({ businessProfileId: action.toSelect.id })
      ])
    ),
  { functional: true }
);

const reloadAccountOnBusinessProfileOrMembersUpdated$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(
        AdminBusinessProfilesActions.updateOneSuccess,
        AdminBusinessProfilesMembersActions.addMemberToBusinessProfileSuccess,
        AdminBusinessProfilesMembersActions.removeMemberFromBusinessProfileSuccess
      ),
      map(() => AuthPageActions.loadAccount())
    ),
  { functional: true }
);

const navigateToSelectedOrganization$ = createEffect(
  (actions$ = inject(Actions), router = inject(Router)) =>
    actions$.pipe(
      ofType(AdminBusinessProfilesActions.navigateToSelectedOrganization),
      map((action) => AdminOrganizationsPageActions.selectOrganization({ selectedOrganizationId: action.organizationId })),
      tap(() => router.navigate(['admin', 'organizations']))
    ),
  { functional: true }
);

const showLoader$ = createEffect(
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(
        AdminBusinessProfilesActions.loadBusinessProfiles,
        AdminBusinessProfilesActions.addOne,
        AdminBusinessProfilesActions.updateOne,
        AdminBusinessProfilesActions.deleteOne
      ),
      tap(() => notificationService.showLoader())
    ),
  { functional: true, dispatch: false }
);

const hideLoader$ = createEffect(
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(
        AdminBusinessProfilesActions.loadBusinessProfilesSuccess,
        AdminBusinessProfilesActions.loadBusinessProfilesFailure,
        AdminBusinessProfilesActions.addOneSuccess,
        AdminBusinessProfilesActions.addOneFailure,
        AdminBusinessProfilesActions.updateOneSuccess,
        AdminBusinessProfilesActions.updateOneFailure,
        AdminBusinessProfilesActions.deleteOneSuccess,
        AdminBusinessProfilesActions.deleteOneFailure
      ),
      tap(() => notificationService.hideLoader())
    ),
  { functional: true, dispatch: false }
);

const displaySuccess$ = createEffect(
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(AdminBusinessProfilesActions.addOneSuccess, AdminBusinessProfilesActions.updateOneSuccess, AdminBusinessProfilesActions.deleteOneSuccess),
      tap((action: Action) => notificationService.displaySuccess(action.type))
    ),
  { functional: true, dispatch: false }
);

const displayError$ = createEffect(
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(
        AdminBusinessProfilesActions.loadBusinessProfilesFailure,
        AdminBusinessProfilesActions.addOneFailure,
        AdminBusinessProfilesActions.updateOneFailure,
        AdminBusinessProfilesActions.deleteOneFailure
      ),
      tap((action: Action) => notificationService.displayError(action))
    ),
  { functional: true, dispatch: false }
);

export const adminBusinessProfilesEffects = {
  addOne$,
  selectOneAfterAddOneSuccess$,
  updateOne$,
  updateTimezoneAfterUpdateOneSuccess$,
  deleteOne$,
  loadBusinessProfiles$,
  loadRelatedObjectsAfterSelectOne$,
  reloadAccountOnBusinessProfileOrMembersUpdated$,
  navigateToSelectedOrganization$,
  showLoader$,
  hideLoader$,
  displaySuccess$,
  displayError$
};

// listFavoriteViews$ = createEffect(() =>
//   this.actions$.pipe(
//     ofType(BusinessProfilesPageActions.listFavoriteViewsByBusinessProfile),
//     switchMap((action) =>
//       this.bpService.loadFavoriteViewsByBusinessProfileId(action.businessProfileId).pipe(
//         map((response) => BusinessProfilesFavoriteViewsApiActions.listFavoriteViewsByBusinessProfileSuccess({ response })),
//         catchError((error) => of(BusinessProfilesFavoriteViewsApiActions.listFavoriteViewsByBusinessProfileFailure({ error })))
//       )
//     )
//   )
// );

//
// selectBusinessProfileThenListFavoriteViews$ = createEffect(() =>
//   this.actions$.pipe(
//     ofType(BusinessProfilesApiActions.selectBusinessProfile),
//     map((action) => BusinessProfilesPageActions.listFavoriteViewsByBusinessProfile({ businessProfileId: action.selectedBusinessProfileId }))
//   )
// );
//
// selectBusinessProfileThenListGrids$ = createEffect(() =>
//   this.actions$.pipe(
//     ofType(BusinessProfilesApiActions.selectBusinessProfile),
//     map((action) => BusinessProfilesPageActions.listGridsByBusinessProfile({ businessProfileId: action.selectedBusinessProfileId }))
//   )
// );
