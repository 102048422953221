import { Event, I4BBulkOperationApiResponse } from '@iot-platform/models/i4b';
import { createActionGroup, props } from '@ngrx/store';

export const DeviceEventsActions = createActionGroup({
  source: 'Device Events',
  events: {
    'Update Device Event Status': props<{ eventId: string; status: string }>(),
    'Update Device Event Status Success': props<{ event: Event }>(),
    'Update Device Event Status Failure': props<{ error: unknown }>(),

    'Bulk Update Device Events Status': props<{ eventsIds: string[]; status: string }>(),
    'Bulk Update Device Events Status Success': props<{ response: I4BBulkOperationApiResponse }>(),
    'Bulk Update Device Events Status Failure': props<{ error: unknown }>()
  }
});
