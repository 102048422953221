import { NgClass } from '@angular/common';
import { Component, input, output, ViewChild } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { MatTooltip } from '@angular/material/tooltip';
import { Contact } from '@iot-platform/models/common';
import { ExportSpreadsheet } from '@iot-platform/models/i4b';
import { InfoDisplayPipe } from '@iot-platform/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
    imports: [TranslateModule, MatIcon, MatTooltip, FlexLayoutModule, MatMenuModule, MatButtonModule, InfoDisplayPipe, NgClass],
    selector: 'iot-platform-ui-contact-card',
    templateUrl: './contact-card.component.html',
    styleUrls: ['./contact-card.component.scss']
})
export class ContactCardComponent {
  contact = input<Contact | null>(null);
  canUpdate = input<boolean>(false);
  canDelete = input<boolean>(false);
  minimalDisplay = input<boolean>(true); // display only fullname, job, phone and email

  toEdit = output<void>();
  delete = output<void>();
  configNotif = output<void>();
  configExport = output<any>();
  openNotificationDetail = output<void>();
  openExportSpreadsheetDetail = output<void>();

  @ViewChild('contactCardMenuTrigger') contactCardMenu: MatMenuTrigger;

  spreadsheetExport$ = new BehaviorSubject<ExportSpreadsheet | NonNullable<unknown>>({});
  loading$!: Observable<boolean>;
  loaded$!: Observable<boolean>;

  get initials(): string {
    return this.contact()?.firstname?.[0]?.toUpperCase() + this.contact()?.lastname?.[0]?.toUpperCase();
  }

  getFilterByKey(key: string): string[] {
    return (
      this.contact()
        ?.notificationFilter?.filter((item) => item.criteriaKey === key)
        ?.map((i) => i.value) ?? []
    );
  }

  edit() {
    this.toEdit.emit();
  }

  configureNotif() {
    this.configNotif.emit();
  }

  configureExport() {
    this.configExport.emit(this.spreadsheetExport$.value);
  }

  onDelete() {
    this.delete.emit();
  }

  onNotificationsClicked() {
    if (this.contact()?.associatedNotifications?.length) {
      this.openNotificationDetail.emit();
    }
  }

  onExportSpreadsheetsClicked() {
    if (this.contact()?.associatedExportSpreadsheets?.length) {
      this.openExportSpreadsheetDetail.emit();
    }
  }
}
