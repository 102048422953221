import { computed, inject, Injectable, Signal } from '@angular/core';
import { AuthFacade, AuthorizationConcept, AuthorizationService, AuthorizationType } from '@iot-platform/auth';
import { BusinessProfile } from '@iot-platform/models/common';
import { AssetGroup, AssetGroupCreate } from '@iot-platform/models/i4b';
import { Store } from '@ngrx/store';
import { AssetGroupsActions } from '../actions';
import { assetGroupsFeature } from '../reducers/asset-groups.reducer';

@Injectable({
  providedIn: 'root'
})
export class AssetGroupsFacade {
  private readonly store: Store = inject(Store);
  private readonly authFacade: AuthFacade = inject(AuthFacade);
  private readonly authorizationService: AuthorizationService = inject(AuthorizationService);

  selectedAssetGroup: Signal<AssetGroup | null> = this.store.selectSignal(assetGroupsFeature.selectSelectedAssetGroup);
  selectedBusinessProfile: Signal<BusinessProfile> = this.authFacade.selectedBusinessProfile;

  canUpdate: Signal<boolean> = computed(() => {
    this.authFacade.privileges();
    return this.authorizationService.applyAuthorization(AuthorizationConcept.ASSET, AuthorizationType.UPDATE);
  });

  canDelete: Signal<boolean> = computed(() => {
    this.authFacade.privileges();
    return this.authorizationService.applyAuthorization(AuthorizationConcept.ASSET, AuthorizationType.DELETE);
  });

  disableAction: Signal<boolean> = computed(() => {
    const canUpdate = this.canUpdate();
    return !canUpdate;
  });

  getAssetGroup(assetGroupId: string): void {
    this.store.dispatch(AssetGroupsActions.getAssetGroup({ assetGroupId }));
  }

  addAssetGroup(assetGroup: AssetGroupCreate): void {
    this.store.dispatch(AssetGroupsActions.addAssetGroup({ assetGroup }));
  }

  editAssetGroup(assetGroup: AssetGroup): void {
    this.store.dispatch(AssetGroupsActions.editAssetGroup({ assetGroup }));
  }

  updateRelatedAssets(assetGroupId: string, assetIds: string[]): void {
    this.store.dispatch(AssetGroupsActions.updateRelatedAssets({ assetGroupId, assetIds }));
  }
}
