import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component, computed, Signal, signal, WritableSignal } from '@angular/core';
import { GetUtils } from '@iot-platform/iot-platform-utils';
import { InfoDisplayPipe } from '@iot-platform/pipes';

import { CustomCellParams } from '../../../models/custom-cell.params';

@Component({
    imports: [InfoDisplayPipe],
    providers: [InfoDisplayPipe],
    selector: 'grid-engine-array-of-objects-cell',
    templateUrl: './array-of-objects-cell.component.html',
    styleUrls: ['./array-of-objects-cell.component.scss']
})
export class ArrayOfObjectsCellComponent implements ICellRendererAngularComp {
  params: WritableSignal<CustomCellParams> = signal(null);
  dataToDisplay: Signal<any> = computed(() => {
    const params = this.params();
    return params.value
      ?.filter((data) => {
        if (this.hasExpressions()) {
          return this.handleExpressions(data);
        }
        return data;
      })
      ?.map((data) => (params.cellOptions && params.cellOptions.attributes ? params.cellOptions.attributes.map((key: string) => data[key]).join(' ') : data))
      ?.join(params.cellOptions?.separator ?? ', ');
  });

  agInit(params: CustomCellParams) {
    this.params.set(params);
  }

  refresh(): boolean {
    return false;
  }

  private applyExpression(elem: any, expression: any): boolean {
    const { key, value } = expression;
    if (expression.hasOwnProperty('key') && expression.hasOwnProperty('value')) {
      const v = GetUtils.get(elem, key, null);
      if (v !== null) {
        return v === value;
      }
      console.error('Invalid expression : ', expression);
      return true;
    }
    return false;
  }

  private hasExpressions(): boolean {
    const params = this.params();
    return params.cellOptions && params.cellOptions.expressions && !!params.cellOptions.expressions.length;
  }

  private handleExpressions(data: any): boolean {
    const params = this.params();
    return params.cellOptions.expressions.map((expression) => this.applyExpression(data, expression)).some((b: boolean) => !!b);
  }
}
