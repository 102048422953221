import { Component, DestroyRef, effect, inject, Injector, input, output, Signal, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { CardLoaderModule, DeviceDetailsModule, SiteDetailsModule } from '@iot-platform/iot-platform-ui';
import { BasicInfoComponent, FollowedVariablesDetailsComponent } from '@iot-platform/iot4bos/ui/asset';
import { Contact, TagCategory } from '@iot-platform/models/common';
import { Asset, Device, Site } from '@iot-platform/models/i4b';
import { TranslateModule } from '@ngx-translate/core';
import { Layer } from 'leaflet';
import * as moment from 'moment/moment';
import { finalize } from 'rxjs/operators';
import { IotGeoJsonFeature } from '../../models/iot-geo-json-object.model';
import { MapNavigationEvent, MapNavigationModelType } from '../../models/iot-map-navigation-event.model';
import { MapPanelInfoService } from '../../services/map-panel-info.service';
import { MapFacade } from '../../state/facades/map.facade';
import { MapPanelInfoPopupComponent } from '../map-panel-info-popup/map-panel-info-popup.component';
import { MapPanelInfoToolboxComponent } from '../map-panel-info-toolbox/map-panel-info-toolbox.component';

@Component({
  imports: [
    BasicInfoComponent,
    MapPanelInfoToolboxComponent,
    FollowedVariablesDetailsComponent,
    MapPanelInfoPopupComponent,
    CardLoaderModule,
    SiteDetailsModule,
    DeviceDetailsModule,
    TranslateModule,
    FlexLayoutModule,
    MatIconModule,
    MatButtonModule
  ],
  selector: 'iot-platform-maps-map-panel-info',
  templateUrl: './map-panel-info.component.html',
  styleUrls: ['./map-panel-info.component.scss']
})
export class MapPanelInfoComponent {
  private readonly mapPanelInfoService: MapPanelInfoService = inject(MapPanelInfoService);
  private readonly mapFacade: MapFacade = inject(MapFacade);
  private readonly injector: Injector = inject(Injector);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  feature = input<IotGeoJsonFeature>();

  close = output<boolean>();
  selectElement = output<MapNavigationEvent<MapNavigationModelType>>();
  displayRoute = output<{ asset: Asset; daysToDisplay: string }>();
  displaySegments = output<{ layers: Layer[]; action: 'add' | 'remove' }>();

  site: WritableSignal<Site | null> = signal(null);
  asset: WritableSignal<Asset | null> = signal(null);
  device: WritableSignal<Device | null> = signal(null);
  tags: WritableSignal<TagCategory[]> = signal([]);
  assetsBySite: WritableSignal<Asset[]> = signal([]);
  devicesBySite: WritableSignal<Device[]> = signal([]);
  contacts: WritableSignal<Contact[]> = signal([]);
  complementarySite: WritableSignal<Site | null> = signal(null);
  loading: WritableSignal<boolean> = signal(false);
  concept: WritableSignal<string | null> = signal(null);
  canReadContacts: Signal<boolean> = this.mapFacade.canReadContacts;

  featureChange = effect(
    () => {
      const feature = this.feature();
      const concept = feature?.properties?.['concept'];
      const id = feature?.properties?.['id'];
      this.concept.set(concept);

      if (concept === 'site') {
        this.initSiteInfo(id as string);
      } else if (concept === 'asset') {
        this.initAssetInfo(id as string);
      } else if (concept === 'device') {
        this.initDeviceInfo(id as string);
      }
    },
    { injector: this.injector }
  );

  loadRoutes(event: { asset: Asset; daysToDisplay: string; minutesBetweenSegment: number; limit: number }) {
    const now = new Date();
    const startDate = moment(now).subtract(event.daysToDisplay, 'minutes').toDate();
    this.mapFacade.loadRoute(event.asset?.id as string, event.limit, startDate, now, event.minutesBetweenSegment, 'desc');
  }

  closeSidebar() {
    this.mapFacade.clearRoutes();
    this.close.emit(true);
  }

  private initSiteInfo(id: string) {
    this.loading.set(true);
    this.mapPanelInfoService
      .getSite(id)
      .pipe(
        finalize(() => this.loading.set(false)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(([site, tags, assets, devices, contacts]: [Site, TagCategory[], Asset[], Device[], Contact[]]) => {
        this.site.set(site);
        this.tags.set(tags);
        this.assetsBySite.set(assets);
        this.devicesBySite.set(devices);
        this.contacts.set(contacts);
      });
  }

  private initAssetInfo(id: string) {
    this.loading.set(true);
    this.mapPanelInfoService
      .getAsset(id)
      .pipe(
        finalize(() => this.loading.set(false)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(([asset, site, tags]: [Asset, Site, TagCategory[]]) => {
        this.asset.set(asset);
        this.complementarySite.set(site);
        this.tags.set(tags);
      });
  }

  private initDeviceInfo(id: string) {
    this.loading.set(true);
    this.mapPanelInfoService
      .getDevice(id)
      .pipe(
        finalize(() => this.loading.set(false)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(([device, site, tags]: [Device, Site, TagCategory[]]) => {
        this.device.set(device);
        this.complementarySite.set(site);
        this.tags.set(tags);
      });
  }
}
