import { KeyValue, KeyValuePipe } from '@angular/common';
import { Component, computed, input, output, Signal } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { CRUD } from '@iot-platform/models/common';

@Component({
    imports: [FlexLayoutModule, MatCheckboxModule, KeyValuePipe],
    selector: 'iot4bos-ui-backoffice-roles-crud-checkbox',
    templateUrl: './roles-crud-checkbox.component.html',
    styleUrls: ['./roles-crud-checkbox.component.scss']
})
export class RolesCrudCheckboxComponent {
  protected readonly CRUD = CRUD;
  authorizations = input<CRUD[]>();
  disabled = input<boolean>(false);

  toggleAuthorizations = output<string[]>();

  innerAuthorizations: Signal<{ [right in CRUD]: boolean }> = computed(() => {
    const authorizations = this.authorizations() ?? [];
    return Object.keys(CRUD).reduce(
      (acc, right) => {
        acc[right] = authorizations.includes(CRUD[right]);
        return acc;
      },
      {} as { [right in CRUD]: boolean }
    );
  });

  defaultOrder = (a: KeyValue<CRUD, number>, b: KeyValue<CRUD, number>): number =>
    a.key === 'read' || (a.key === 'create' && b.key !== 'read') || (a.key === 'update' && b.key === 'delete') ? -1 : 1;

  getNewAuthorizations(authorization: string, checked: boolean): string[] {
    const currentAuthorizations = this.innerAuthorizations();
    const newAuthorizations = { ...currentAuthorizations, [CRUD[authorization]]: checked };
    return Object.keys(newAuthorizations).filter((key) => newAuthorizations[key]);
  }

  onAuthorizationChange(authorization: string, event: MatCheckboxChange) {
    this.toggleAuthorizations.emit(this.getNewAuthorizations(authorization, event.checked));
  }
}
