import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AsyncAutocompleteModule, PhoneNumberInputComponent, TimezoneAutocompleteModule } from '@iot-platform/iot-platform-ui';
import {
  provideConfigureProductsFormSettings,
  provideFollowedVariableAddPopupSettings,
  provideVariableCreateFormSettings
} from '@iot-platform/iot4bos/ui/asset';
import { DateFormatPipe, UserNamePipe } from '@iot-platform/pipes';
import { SharedModule } from '@iot-platform/shared';
import { AddBusinessProfileDialogComponent } from './components/dialogs/add-business-profile-dialog/add-business-profile-dialog.component';
import { AddOrganizationDialogComponent } from './components/dialogs/add-organization-dialog/add-organization-dialog.component';
import { AddRoleDialogComponent } from './components/dialogs/add-role-dialog/add-role-dialog.component';
import { AddUserDialogComponent } from './components/dialogs/add-user-dialog/add-user-dialog.component';
import { EditOrganizationDialogComponent } from './components/dialogs/edit-organization-dialog/edit-organization-dialog.component';
import { EditRoleDialogComponent } from './components/dialogs/edit-role-dialog/edit-role-dialog.component';
import { ManageAdministratorsDialogComponent } from './components/dialogs/manage-administrators-dialog/manage-administrators-dialog.component';
// eslint-disable-next-line max-len
import { ManageBusinessProfileMembersDialogComponent } from './components/dialogs/manage-business-profile-members-dialog/manage-business-profile-members-dialog.component';
// eslint-disable-next-line max-len
import { ManageBusinessProfileRolesDialogComponent } from './components/dialogs/manage-business-profile-roles-dialog/manage-business-profile-roles-dialog.component';
import { ManageConceptsDialogComponent } from './components/dialogs/manage-concepts-dialog/manage-concepts-dialog.component';
import { OrganizationConceptsSelectionModule } from './components/organization-concepts-selection/organization-concepts-selection.module';
import { RolesCrudModule } from './components/roles-crud/roles-crud.module';
import { AccountValidationComponent } from './features/account-validation/account-validation.component';
import { AdminOrganizationsModule } from './features/admin-organizations/admin-organizations.module';
import { AdminOrganizationsComponent } from './features/admin-organizations/containers/admin-organizations.component';
import { AdminProductCatalogsModule } from './features/admin-product-catalogs/admin-product-catalogs.module';
import { AdminProductCatalogsShellComponent } from './features/admin-product-catalogs/containers/admin-product-catalogs-shell/admin-product-catalogs-shell.component';
import { ConfigureProductsFormService } from './features/asset-templates/services/configure-products-form.service';
import { FollowedVariableAddPopupService } from './features/asset-templates/services/followed-variable-add-popup.service';
import { VariableCreateFormService } from './features/asset-templates/services/variable-create-form.service';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild([
      {
        path: 'business-profiles',
        loadChildren: () => import('./features/admin-business-profiles/admin-business-profiles.routes')
      },
      {
        path: 'organizations',
        component: AdminOrganizationsComponent
      },
      {
        path: 'product-catalogs',
        component: AdminProductCatalogsShellComponent
      },
      {
        path: 'i18n',
        loadChildren: () => import('@iot-platform/i18n').then((m) => m.TranslationManagementModule)
      },
      {
        path: 'email-templates',
        loadChildren: () => import('./features/email-templates/email-templates.routes')
      },
      {
        path: 'asset-templates',
        loadChildren: () => import('./features/asset-templates/asset-templates.routes')
      },
      {
        path: 'users',
        loadChildren: () => import('./features/users/users.routes')
      }
    ]),
    // AdminBusinessProfilesModule,
    AdminOrganizationsModule,
    RolesCrudModule,
    DateFormatPipe,
    OrganizationConceptsSelectionModule,
    AdminProductCatalogsModule,
    AsyncAutocompleteModule,
    TimezoneAutocompleteModule,
    PhoneNumberInputComponent,
    ManageAdministratorsDialogComponent,
    AddBusinessProfileDialogComponent,
    ManageBusinessProfileMembersDialogComponent,
    AccountValidationComponent,
    AddRoleDialogComponent,
    ManageBusinessProfileRolesDialogComponent,
    AddUserDialogComponent,
    AddOrganizationDialogComponent,
    EditRoleDialogComponent,
    EditOrganizationDialogComponent,
    ManageConceptsDialogComponent
  ],
  exports: [RouterModule],
  providers: [
    UserNamePipe,
    provideConfigureProductsFormSettings({
      configureProductsFormService: ConfigureProductsFormService
    }),
    provideVariableCreateFormSettings({
      variableCreateFormService: VariableCreateFormService
    }),
    provideFollowedVariableAddPopupSettings({
      followedVariableAddPopupService: FollowedVariableAddPopupService
    })
  ]
})
export class Iot4bosBackofficeUiModule {}
