import { Injectable, Signal } from '@angular/core';
import { Asset, Device, Event, Log, Site } from '@iot-platform/models/i4b';
import { AbstractTopicsService } from '@iot-platform/on-call-management';
import { NavigationApi } from '../containers/+state/navigation.api';
import { DeviceEventsFacade } from '../features/device-events/+state/facades/device-events.facade';
import { AssetEventsFacade } from '../features/asset-events/+state/facades/asset-events.facade';

@Injectable({
  providedIn: 'root'
})
export class TopicsService extends AbstractTopicsService {
  assetEventComments: Signal<Log[]> = this.assetEventsFacade.eventComments;
  assetEventCommentsLoading: Signal<boolean> = this.assetEventsFacade.eventCommentsLoading;
  deviceEventComments: Signal<Log[]> = this.deviceEventsFacade.eventComments;
  deviceEventCommentsLoading: Signal<boolean> = this.deviceEventsFacade.eventCommentsLoading;

  constructor(
    private readonly assetEventsFacade: AssetEventsFacade,
    private readonly deviceEventsFacade: DeviceEventsFacade,
    private readonly navigationApi: NavigationApi
  ) {
    super();
  }

  selectAssetAvecLeSite(selectedAsset: Asset): void {
    this.navigationApi.selectAssetAvecLeSite(selectedAsset);
  }

  selectDeviceAvecLeSite(selectedDevice: Device): void {
    this.navigationApi.selectDeviceAvecLeSite(selectedDevice);
  }

  openAssetDetail(asset: Asset, origin: string): void {
    this.navigationApi.openAssetDetail(asset, origin);
  }

  openDeviceDetail(device: Device, origin: string): void {
    this.navigationApi.openDeviceDetail(device, origin);
  }

  openSiteDetail(site: Site, origin: string): void {
    this.navigationApi.openSiteDetail(site, origin);
  }

  loadAssetEventComments(assetEvent: Event): void {
    this.assetEventsFacade.loadComments(assetEvent);
  }

  addAssetEventComment(assetEvent: Event, comment: string): void {
    this.assetEventsFacade.addComment(assetEvent, comment);
  }

  editAssetEventComment(assetEventId: string, comment: Log): void {
    this.assetEventsFacade.editComment(assetEventId, comment);
  }

  deleteAssetEventComment(assetEvent: Event, commentId: string): void {
    this.assetEventsFacade.deleteComment(assetEvent, commentId);
  }

  updateStatusByAssetEventId(assetEventIds: string[], status: string): void {
    this.assetEventsFacade.bulkUpdateEventsStatus(assetEventIds, status);
  }

  loadDeviceEventComments(deviceEvent: Event): void {
    this.deviceEventsFacade.loadComments(deviceEvent);
  }

  addDeviceEventComment(deviceEvent: Event, comment: string): void {
    this.deviceEventsFacade.addComment(deviceEvent, comment);
  }

  editDeviceEventComment(deviceEventId: string, comment: Log): void {
    this.deviceEventsFacade.editComment(deviceEventId, comment);
  }

  deleteDeviceEventComment(deviceEvent: Event, commentId: string): void {
    this.deviceEventsFacade.deleteComment(deviceEvent, commentId);
  }

  updateStatusByDeviceEventId(deviceEventIds: string[], status: string): void {
    this.deviceEventsFacade.bulkUpdateEventsStatus(deviceEventIds, status);
  }
}
