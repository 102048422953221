import { Injectable, Signal, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromPreferences from '../reducers';
import { PreferencesActions } from '../actions';
import { UserAccount, UserPreferences } from '@iot-platform/models/common';

@Injectable({
  providedIn: 'root'
})
export class PreferencesFacade {
  private readonly store: Store = inject(Store);

  currentUser: Signal<UserAccount> = this.store.selectSignal(fromPreferences.getCurrentUser);
  preferences: Signal<UserPreferences> = this.store.selectSignal(fromPreferences.getPreferences);

  updateSiteAssociationDisplay(display: string) {
    this.store.dispatch(
      PreferencesActions.saveUserPreferences({
        user: { ...this.currentUser(), preferences: { ...this.currentUser().preferences, siteAssociationDisplay: display } },
        preferences: { ...this.currentUser().preferences, siteAssociationDisplay: display }
      })
    );
  }
}
