import { ChangeDetectionStrategy, Component, effect, inject, input } from '@angular/core';
import { FeatureMasterViewComponent } from '@iot-platform/feature/master-view';
import { CommonGenericModel } from '@iot-platform/models/common';
import { AbstractDeviceCallLogsFacade } from '../../services/abstract-device-call-logs-facade.service';
import { FeatureDeviceCallLogsSettings } from '../../models/device-call-logs-settings.model';
import { FEATURE_DEVICE_CALL_LOGS_SETTINGS } from '../../settings/device-call-logs.provider';

@Component({
  selector: 'iot-platform-feature-device-call-logs-tab',
  imports: [FeatureMasterViewComponent],
  template: ` <iot-platform-feature-master-view [masterView]="masterViewName" /> `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceCallLogsTabComponent<T extends CommonGenericModel> {
  protected readonly deviceCallLogsFacade: AbstractDeviceCallLogsFacade<T> = inject(AbstractDeviceCallLogsFacade);
  protected readonly deviceCallLogsSettings: FeatureDeviceCallLogsSettings = inject(FEATURE_DEVICE_CALL_LOGS_SETTINGS);

  device = input<T>();

  readonly masterViewName = this.deviceCallLogsSettings.masterViewName;

  loadDeviceCallLogsEffect = effect(() => {
    const device = this.device();
    if (device?.id) {
      this.deviceCallLogsFacade.loadDeviceCallLogs(device);
    }
  });
}
