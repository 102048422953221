import { UpperCasePipe } from '@angular/common';
import { Component, input, output } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    imports: [FlexLayoutModule, MatCheckboxModule, TranslateModule, UpperCasePipe, FormsModule, ReactiveFormsModule],
    selector: 'iot4bos-ui-backoffice-organization-concepts-selection-checkbox',
    templateUrl: './organization-concepts-selection-checkbox.component.html',
    styleUrls: ['./organization-concepts-selection-checkbox.component.scss']
})
export class OrganizationConceptsSelectionCheckboxComponent {
  concept = input<{ name: string; isSelected: boolean }>();
  disabled = input<boolean>(false);

  toggleConceptSelection = output<{ name: string; isSelected: boolean }>();

  onConceptSelectionChange(event: MatCheckboxChange): void {
    this.toggleConceptSelection.emit({ name: this.concept().name, isSelected: event.checked });
  }
}
