import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    imports: [FlexLayoutModule, TranslateModule, MatToolbarModule, MatCardModule, MatButtonModule, MatIconModule],
    selector: 'iot4bos-ui-delete-comment-dialog',
    templateUrl: './delete-comment-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeleteCommentDialogComponent {
  public dialogRef: MatDialogRef<DeleteCommentDialogComponent> = inject(MatDialogRef<DeleteCommentDialogComponent>);
  public data: { comment: string } = inject(MAT_DIALOG_DATA);

  confirm(): void {
    this.dialogRef.close(true);
  }

  close(): void {
    this.dialogRef.close(false);
  }
}
