import { I4BCellType, I4BColumnConfiguration, I4BColumnRowGroupConfiguration } from '../configurations';
import { I4bCellConcept } from '../enums';
import { HeaderType, I4BColumnHeader } from '../models';
import { I4BVariableColumnOptions } from '../options';
import { I4BBasicColumn } from './i4b-basic.column';
import { DiagnosticVariableColumn } from './i4b-diagnostic-variable.column';

interface DiagnosticVariableGroupColumnConfiguration extends I4BColumnConfiguration {
  rowGroupParams: Partial<{
    value: Partial<I4BColumnRowGroupConfiguration>;
    dateTime: Partial<I4BColumnRowGroupConfiguration>;
  }>;
}

export class DiagnosticVariableGroupColumn extends I4BBasicColumn {
  columnId = '9374044f-8451-444e-8a16-6044eec9c112-diagnostic-variable-group';
  className = DiagnosticVariableGroupColumn;

  constructor(
    customHeader?: Partial<I4BColumnHeader>,
    customConfig?: Partial<DiagnosticVariableGroupColumnConfiguration>,
    customOptions?: Partial<I4BVariableColumnOptions>
  ) {
    super(
      {
        displayName: customOptions?.overrideHeaderName ? customOptions?.overrideHeaderName : customHeader?.displayName ? customHeader?.displayName : 'VARIABLE',
        type: HeaderType.BASIC
      },
      {
        cell: { ...customConfig?.cell, type: I4BCellType.BASIC },
        id: customConfig?.id,
        concept: I4bCellConcept.DIAGNOSTIC_VARIABLES,
        isDefault: !!customConfig?.isDefault,
        marryChildren: true,
        rowGroupParams: customConfig?.rowGroupParams,
        children: [
          new DiagnosticVariableColumn(
            { displayName: 'VALUE' },
            {
              id: 'expandedVariables',
              isDefault: true,
              cell: {
                ...customConfig?.cell,
                type: I4BCellType.DIAGNOSTIC_VARIABLE,
                options: {
                  ...customConfig?.cell?.options,
                  variableCategory: customConfig?.id ?? '',
                  displayMode: 'value'
                },
                event: { type: 'openChartByVariable', options: { variableName: customConfig?.id } }
              },
              rowGroupParams: customConfig?.rowGroupParams?.value
            },
            { order: 0, isDisplayed: true }
          ),
          new DiagnosticVariableColumn(
            { displayName: 'DATETIME' },
            {
              id: 'expandedVariables',
              isDefault: true,
              cell: {
                type: I4BCellType.DIAGNOSTIC_VARIABLE,
                options: { variableCategory: customConfig?.id ?? '', displayMode: 'datetime' }
              },
              width: 170,
              rowGroupParams: customConfig?.rowGroupParams?.dateTime
            },
            { order: 1, isDisplayed: customOptions?.displayDateTime }
          )
        ]
      },
      { displayDateTime: true, ...customOptions, customId: customConfig?.id }
    );
  }
}
