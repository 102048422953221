import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { IRowNode } from '@ag-grid-community/core';
import { NgClass, NgStyle } from '@angular/common';
import { Component, computed, inject, signal, Signal, WritableSignal } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { TranslateService } from '@ngx-translate/core';
import { get, isEmpty } from 'lodash';
import { GroupRowCellParams } from '../../../models/group-row-cell.params';

@Component({
  templateUrl: './row-group-inner-renderer-cell.component.html',
  imports: [NgClass, NgStyle, MatIcon],
  styleUrl: './row-group-inner-renderer-cell.component.scss'
})
export class RowGroupInnerRendererCellComponent implements ICellRendererAngularComp {
  private readonly translateService: TranslateService = inject(TranslateService);

  params: WritableSignal<GroupRowCellParams> = signal(null);
  node: Signal<IRowNode> = computed(() => this.params()?.node);
  value: Signal<string> = computed(() => {
    const params = this.params();
    const emptyValue = params?.value === null || params?.value === undefined || !`${params?.value}`?.length;
    if (!!params?.node?.group && emptyValue) {
      const blankLabel: string = get(params?.node?.rowGroupColumn?.getColDef(), ['cellRendererParams', 'blankLabel'], 'AG_GRID.blanks');
      return this.translateService.instant(blankLabel);
    }
    return emptyValue ? '' : params?.value;
  });
  rowGroupColors: Signal<any[]> = computed(() => this.params()?.rowGroupColors?.());
  color: Signal<any> = computed(() => {
    const node = this.node();
    const rowGroupColors = this.rowGroupColors();
    if (!!node?.group) {
      return get(rowGroupColors, [node?.rowGroupIndex]);
    }
    return null;
  });
  isLink: Signal<boolean> = computed(() => {
    const params = this.params();
    const colDef = params?.node?.rowGroupColumn?.getColDef?.();
    return !isEmpty(colDef?.cellRendererParams?.eventConfiguration?.type);
  });

  agInit(params: GroupRowCellParams): void {
    this.params.set(params);
  }

  refresh(): boolean {
    return false;
  }

  onClick(): void {
    const params = this.params();
    if (params?.node?.group) {
      const colDef = params.node.rowGroupColumn.getColDef();
      const isEmptyValue = params?.value === null || params?.value === undefined || !`${params?.value}`?.length;
      const children = params?.node?.allLeafChildren?.map((item) => item.data);
      const firstLeafChild = get(children, '[0]');
      if (!isEmptyValue && firstLeafChild) {
        params?.dispatchEvent?.({
          type: colDef?.cellRendererParams?.eventConfiguration?.type,
          options: {
            ...colDef?.cellRendererParams?.eventConfiguration?.options,
            value: params.value,
            group: params?.node?.group,
            children
          },
          rawData: firstLeafChild
        });
      }
    }
  }
}
