import { KeyValue, KeyValuePipe } from '@angular/common';
import { Component, input } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { StatusIconComponent } from '@iot-platform/iot-platform-ui';
import { DataGuardCheckDetail, DataGuardCheckStatus } from '@iot-platform/models/data-guard';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'iot-platform-feature-data-guard-check-detail-category-header',
  imports: [FlexLayoutModule, TranslateModule, KeyValuePipe, StatusIconComponent],
  templateUrl: './check-detail-category-header.component.html',
  styleUrl: './check-detail-category-header.component.scss'
})
export class CheckDetailCategoryHeaderComponent {
  category = input<string>();
  statusesCounts = input<DataGuardCheckDetail['statusCount']>();
  customOrder = (a: KeyValue<DataGuardCheckStatus, number>, b: KeyValue<DataGuardCheckStatus, number>) =>
    a.key === 'error' || (a.key === 'warning' && b.key !== 'error') || (a.key === 'ok' && b.key === 'na') ? -1 : 1;
}
