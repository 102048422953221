import { inject } from '@angular/core';
import { NotificationService } from '@iot-platform/notification';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';

import { BusinessProfilesService } from '../../services/business-profiles.service';
import { AdminBusinessProfilesRolesActions } from '../actions/business-profiles-roles.actions';

const loadRolesByBusinessProfile$ = createEffect(
  (actions$ = inject(Actions), businessProfilesService = inject(BusinessProfilesService)) =>
    actions$.pipe(
      ofType(AdminBusinessProfilesRolesActions.loadRolesByBusinessProfile),
      mergeMap((action) =>
        businessProfilesService.loadRolesByBusinessProfileId(action.businessProfileId).pipe(
          map((roles) => AdminBusinessProfilesRolesActions.loadRolesByBusinessProfileSuccess({ roles })),
          catchError((error) => of(AdminBusinessProfilesRolesActions.loadRolesByBusinessProfileFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const addRoleToBusinessProfile$ = createEffect(
  (actions$ = inject(Actions), businessProfilesService = inject(BusinessProfilesService)) =>
    actions$.pipe(
      ofType(AdminBusinessProfilesRolesActions.addRoleToBusinessProfile),
      mergeMap((action) =>
        businessProfilesService.addRoleToBusinessProfile(action.businessProfileId, action.roleToAdd).pipe(
          map((addedRole) => AdminBusinessProfilesRolesActions.addRoleToBusinessProfileSuccess({ addedRole })),
          catchError((error) => of(AdminBusinessProfilesRolesActions.addRoleToBusinessProfileFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const removeRoleFromBusinessProfile$ = createEffect(
  (actions$ = inject(Actions), businessProfilesService = inject(BusinessProfilesService)) =>
    actions$.pipe(
      ofType(AdminBusinessProfilesRolesActions.removeRoleFromBusinessProfile),
      mergeMap((action) =>
        businessProfilesService.removeRoleFromBusinessProfile(action.businessProfileId, action.roleToRemove).pipe(
          map((removedRole) => AdminBusinessProfilesRolesActions.removeRoleFromBusinessProfileSuccess({ removedRole })),
          catchError((error) => of(AdminBusinessProfilesRolesActions.removeRoleFromBusinessProfileFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const showLoader$ = createEffect(
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(
        AdminBusinessProfilesRolesActions.addRoleToBusinessProfile,
        AdminBusinessProfilesRolesActions.removeRoleFromBusinessProfile,
        AdminBusinessProfilesRolesActions.loadRolesByBusinessProfile
      ),
      tap(() => notificationService.showLoader())
    ),
  { functional: true, dispatch: false }
);

const hideLoader$ = createEffect(
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(
        AdminBusinessProfilesRolesActions.addRoleToBusinessProfileSuccess,
        AdminBusinessProfilesRolesActions.addRoleToBusinessProfileFailure,
        AdminBusinessProfilesRolesActions.removeRoleFromBusinessProfileSuccess,
        AdminBusinessProfilesRolesActions.removeRoleFromBusinessProfileFailure,
        AdminBusinessProfilesRolesActions.loadRolesByBusinessProfileSuccess,
        AdminBusinessProfilesRolesActions.loadRolesByBusinessProfileFailure
      ),
      tap(() => notificationService.hideLoader())
    ),
  { functional: true, dispatch: false }
);

const displayError$ = createEffect(
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(
        AdminBusinessProfilesRolesActions.addRoleToBusinessProfileFailure,
        AdminBusinessProfilesRolesActions.removeRoleFromBusinessProfileFailure,
        AdminBusinessProfilesRolesActions.loadRolesByBusinessProfileFailure
      ),
      tap(({ error }) => notificationService.displayError(error))
    ),
  { functional: true, dispatch: false }
);

const displaySuccess$ = createEffect(
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(AdminBusinessProfilesRolesActions.addRoleToBusinessProfileSuccess, AdminBusinessProfilesRolesActions.removeRoleFromBusinessProfileSuccess),
      tap((action: Action) => notificationService.displaySuccess(action.type))
    ),
  { functional: true, dispatch: false }
);

export const adminBusinessProfilesRolesEffects = {
  loadRolesByBusinessProfile$,
  addRoleToBusinessProfile$,
  removeRoleFromBusinessProfile$,
  showLoader$,
  hideLoader$,
  displayError$,
  displaySuccess$
};
