import { Component, input, output } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';

import { Device } from '@iot-platform/models/i4b';

@Component({
    imports: [MatIconModule, FlexLayoutModule],
    selector: 'iot4bos-ui-device-breadcrumb-item',
    templateUrl: './device-breadcrumb-item.component.html',
    styleUrls: ['./device-breadcrumb-item.component.scss', '../breadcrumb-item.scss']
})
export class DeviceBreadcrumbItemComponent {
  device = input<Device>();
  active = input<boolean>(true);

  navigate = output<{ activeId: string; requestedSiteId: string }>();

  onClick() {
    this.navigate.emit({ activeId: this.device().id, requestedSiteId: this.device().site.id });
  }
}
