import { Component, computed, inject } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateService } from '@ngx-translate/core';
import { AbstractTableEngineCellComponent } from '../abstract-table-engine-cell.component';

@Component({
  selector: 'i4b-table-engine-basic-cell',
  templateUrl: './basic-cell.component.html',
  styleUrls: ['./basic-cell.component.scss'],
  imports: [MatTooltip]
})
export class BasicCellComponent extends AbstractTableEngineCellComponent<BasicCellComponent> {
  private readonly translate: TranslateService = inject(TranslateService);
  value = computed(() => {
    const data = this.data();
    const cellOptions = this.cellOptions();
    if (!data && cellOptions && cellOptions.default) {
      return this.translate.instant(cellOptions.default);
    }
    return data;
  });
}
