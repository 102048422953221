import { BaseState, Filter, INITIAL_BASE_STATE, Pagination, Role } from '@iot-platform/models/common';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createFeature, createReducer, on } from '@ngrx/store';
import { AdminBusinessProfilesRolesActions } from '../actions/business-profiles-roles.actions';
import { AdminBusinessProfilesActions } from '../actions/business-profiles.actions';

export type State = BaseState<Role, Pagination, Filter>;

export const adapter: EntityAdapter<Role> = createEntityAdapter<Role>({
  selectId: (role: Role) => role.id as string,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  ...INITIAL_BASE_STATE,
  pagination: { currentPage: 0, total: 0, maxPage: 0, hasMore: false, limit: 1000 }
});

export const adminBusinessProfilesRolesFeature = createFeature({
  name: 'adminBusinessProfilesRoles',
  reducer: createReducer(
    initialState,
    on(AdminBusinessProfilesActions.selectOne, AdminBusinessProfilesRolesActions.loadRolesByBusinessProfile, (state) => adapter.removeAll(state)),
    on(AdminBusinessProfilesRolesActions.loadRolesByBusinessProfileSuccess, (state, { roles }) => adapter.setAll(roles, state)),
    on(AdminBusinessProfilesRolesActions.addRoleToBusinessProfileSuccess, (state, { addedRole }) => adapter.addOne(addedRole, state)),
    on(AdminBusinessProfilesRolesActions.removeRoleFromBusinessProfileSuccess, (state, { removedRole }) => adapter.removeOne(removedRole.id, state)),

    on(
      AdminBusinessProfilesRolesActions.loadRolesByBusinessProfile,
      AdminBusinessProfilesRolesActions.addRoleToBusinessProfile,
      AdminBusinessProfilesRolesActions.removeRoleFromBusinessProfile,
      (state) => ({ ...state, loading: true, loaded: false })
    ),
    on(
      AdminBusinessProfilesRolesActions.loadRolesByBusinessProfileSuccess,
      AdminBusinessProfilesRolesActions.addRoleToBusinessProfileSuccess,
      AdminBusinessProfilesRolesActions.removeRoleFromBusinessProfileSuccess,
      (state) => ({ ...state, loading: false, loaded: true })
    ),
    on(
      AdminBusinessProfilesRolesActions.loadRolesByBusinessProfileFailure,
      AdminBusinessProfilesRolesActions.addRoleToBusinessProfileFailure,
      AdminBusinessProfilesRolesActions.removeRoleFromBusinessProfileFailure,
      (state, { error }) => ({ ...state, error, loading: false, loaded: false })
    )
  )
});
