import { ChangeDetectionStrategy, Component, computed, inject, input, output, Signal } from '@angular/core';
import { AssociationCardContainerComponent, OverviewCardComponent } from '@iot-platform/iot-platform-ui';
import { OverviewCard } from '@iot-platform/models/common';
import { Device } from '@iot-platform/models/i4b';
import { InfoDisplayPipe } from '@iot-platform/pipes';

@Component({
  selector: 'iot4bos-ui-device-card',
  imports: [OverviewCardComponent, AssociationCardContainerComponent],
  templateUrl: './device-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [InfoDisplayPipe]
})
export class DeviceCardComponent {
  device = input<Device>();
  navigationMode = input<boolean>();
  selectedItemId = input<string>();
  clicked = output<Device>();

  card: Signal<OverviewCard | null> = computed(() => {
    const device = this.device();
    return device ? this.getCardMetadata(device) : null;
  });

  infoDisplayPipe: InfoDisplayPipe = inject(InfoDisplayPipe);

  private onClick() {
    this.clicked.emit(this.device());
  }

  private getCardMetadata(device: Device): OverviewCard {
    const width = '100%';
    const navigationMode = this.navigationMode();
    return {
      name: `${device?.name}`,
      justifyContent: false,
      image: navigationMode ? null : device?.imageUrl,
      defaultImage: navigationMode ? null : 'assets/images/device/Device_Default.png',
      cssClass: this.selectedItemId() === device.id ? 'overview-card--selected' : '',
      onClick: () => this.onClick(),
      sections: [
        {
          properties: [
            {
              label: 'SITES.ASSOCIATIONS.TYPE',
              value: this.infoDisplayPipe.transform(device?.type, 'hyphen', 'family', 'model'),
              width
            },
            { label: 'SITES.ASSOCIATIONS.STATUS', value: device?.status?.name, width }
          ]
        }
      ]
    };
  }
}
