import { Component, computed, inject, Signal } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateService } from '@ngx-translate/core';
import { AbstractTableEngineCellComponent } from '../abstract-table-engine-cell.component';

@Component({
  selector: 'i4b-table-engine-followed-number-cell',
  templateUrl: './followed-number-cell.component.html',
  styleUrls: ['./followed-number-cell.component.scss'],
  imports: [MatTooltip, MatIcon]
})
export class FollowedNumberCellComponent extends AbstractTableEngineCellComponent<FollowedNumberCellComponent> {
  private readonly translateService: TranslateService = inject(TranslateService);
  tooltip: Signal<string> = computed(() => {
    const data = this.data();
    if (data !== null) {
      return this.translateService.instant('ASSETS.VARIABLE.FOLLOWED.CARD_TITLE', { followedRank: data });
    }
    return null;
  });
}
