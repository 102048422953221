import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { MasterViewEngineEvent, PlatformResponse } from '@iot-platform/models/common';
import { Asset, AssetVariable, Device, DeviceVariable, VariablesTableFilters } from '@iot-platform/models/i4b';
import { UserPreferencesService } from '@iot-platform/users';
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
    selector: 'iot4bos-ui-asset-variable-list-for-asset-variable-configuration',
    templateUrl: './multiple-variable-list-for-asset-variable-configuration.component.html',
    styleUrls: ['./multiple-variable-list-for-asset-variable-configuration.component.scss'],
    standalone: false
})
export class MultipleVariableListForAssetVariableConfigurationComponent implements OnInit, OnChanges, OnDestroy {
  @Input() asset?: Asset;
  @Input() device?: Device;
  @Input() variables: (AssetVariable | DeviceVariable)[] = [];
  @Input() selectedVariables?: AssetVariable[];
  @Input() selectedVariable?: DeviceVariable;

  @Input() dataType = '';

  @Output() selectVariable: EventEmitter<AssetVariable | DeviceVariable> = new EventEmitter();

  variablesSettings$ = this.userPrefService.loadActiveSettings('deviceVariablesConfigurationPopup');

  variablesAsPlatformResponse$: BehaviorSubject<PlatformResponse> = new BehaviorSubject<PlatformResponse>({
    total: 0,
    maxPage: 0,
    limit: 0,
    hasMore: false,
    data: [],
    currentPage: 0
  });
  totalVariables = 0;
  totalVariablesFiltered = 0;
  variableUnits: string[] = [];
  filters?: VariablesTableFilters;
  filters$: BehaviorSubject<Partial<VariablesTableFilters>> = new BehaviorSubject({ name: null, unit: null });

  assetVariableType = 'assetVariable';
  deviceVariableType = 'deviceVariable';

  masterViewType?: {
    name: string;
    isPath: boolean;
    useExternalMetadata: boolean;
  } = {
    name: 'deviceVariablesConfigurationPopup',
    isPath: false,
    useExternalMetadata: true
  };

  subs: Subscription[] = [];

  constructor(private readonly userPrefService: UserPreferencesService) {
    this.setSettings();
  }

  ngOnInit() {
    this.subs.push(
      this.filters$.subscribe((filters) => {
        const filteredVars = this.variables
          ?.filter((variable: AssetVariable | DeviceVariable) =>
            !!variable['name'] && !!filters['name']
              ? variable.name.toLowerCase().includes(filters['name'].toLowerCase())
              : !(!variable['name'] && filters['name'])
          )
          .filter((variable: AssetVariable | DeviceVariable) =>
            !!variable['unit'] && filters['unit'] !== null && filters['unit'] !== undefined
              ? variable['unit'] === filters['unit']
              : !(!variable['unit'] && filters['unit'])
          );
        this.totalVariablesFiltered = filteredVars?.length ?? 0;
        this.variablesAsPlatformResponse$.next(this.transformArrayIntoPlatformResponse(filteredVars));
      })
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('variables') && changes.variables.currentValue) {
      this.variablesAsPlatformResponse$.next(this.transformArrayIntoPlatformResponse(changes.variables.currentValue));
      this.totalVariables = changes.variables.currentValue.length;
      this.totalVariablesFiltered = changes.variables.currentValue.length;
      this.setUnitList(changes.variables.currentValue);
    }
  }

  setSettings(): void {
    if (this.dataType === this.assetVariableType) {
      this.masterViewType = { name: 'assetVariablesConfigurationPopup', isPath: false, useExternalMetadata: true };
      this.variablesSettings$ = this.userPrefService.loadActiveSettings('assetVariablesConfigurationPopup');
    } else if (this.dataType === this.deviceVariableType) {
      this.masterViewType = { name: 'deviceVariablesConfigurationPopup', isPath: false, useExternalMetadata: true };
      this.variablesSettings$ = this.userPrefService.loadActiveSettings('deviceVariablesConfigurationPopup');
    }
  }

  transformArrayIntoPlatformResponse(array?: (AssetVariable | DeviceVariable)[], pageSize = 5): PlatformResponse {
    return {
      currentPage: 0,
      data: array,
      hasMore: false,
      limit: pageSize,
      maxPage: array?.length ? Math.ceil(array.length / pageSize) : 0,
      total: array?.length ?? 0
    };
  }

  setUnitList(variables: (AssetVariable | DeviceVariable)[]): void {
    if (variables) {
      const unitSet: Set<string> = new Set(variables.map((v) => v.unit ?? ''));
      this.variableUnits = Array.from(unitSet);
    }
  }

  applyFilters(filters: { name: string; unit: string }) {
    this.filters$.next(filters);
  }

  clearFilters() {
    this.filters$.next({ name: undefined, unit: undefined });
  }

  onMasterViewEngineEvent(event: MasterViewEngineEvent) {
    switch (event.type) {
      case 'open':
        this.selectVariable.emit(event.rawData);
        break;
      default:
        break;
    }
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
