import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component, computed, Signal, signal, WritableSignal } from '@angular/core';
import { SubscriptionButtonModule } from '@iot-platform/iot-platform-ui';
import { CustomCellParams } from '../../../models/custom-cell.params';

@Component({
    selector: 'grid-engine-topic-self-subscription-cell',
    templateUrl: './topic-self-subscription-cell.component.html',
    imports: [SubscriptionButtonModule],
    styleUrls: ['./topic-self-subscription-cell.component.scss']
})
export class TopicSelfSubscriptionCellComponent implements ICellRendererAngularComp {
  params: WritableSignal<CustomCellParams> = signal(null);
  subscribed: Signal<boolean> = computed(() => {
    const params = this.params();
    return !!params?.data?.subscribedAt;
  });

  agInit(params: CustomCellParams): void {
    this.params.set(params);
  }

  refresh(): boolean {
    return false;
  }

  onDispatchEvent(event: MouseEvent): void {
    if (event) {
      event.stopPropagation();
      const params = this.params();
      const subscribed = this.subscribed();
      params.dispatchEvent({
        type: params.eventConfiguration.type,
        options: { ...params.eventConfiguration.options, subscribed },
        rawData: params.data
      });
    }
  }
}
