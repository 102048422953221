import { Component } from '@angular/core';
import { NumberFormatPipe } from '@iot-platform/pipes';
import { AbstractTableEngineCellComponent } from '../abstract-table-engine-cell.component';

@Component({
  selector: 'i4b-table-engine-count-cell',
  templateUrl: './count-cell.component.html',
  styleUrls: ['./count-cell.component.scss'],
  imports: [NumberFormatPipe]
})
export class CountCellComponent extends AbstractTableEngineCellComponent<CountCellComponent> {}
