import { NgClass } from '@angular/common';
import { Component, inject, OnInit, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { InfoDisplayPipe } from '@iot-platform/pipes';
import { TranslateService } from '@ngx-translate/core';
import { catchError, of } from 'rxjs';
import { AbstractTableEngineCellComponent } from '../abstract-table-engine-cell.component';
import { GridNameCellService } from './grid-name-cell.service';

@Component({
  selector: 'i4b-table-engine-grid-name-cell',
  templateUrl: './grid-name-cell.component.html',
  styleUrls: ['./grid-name-cell.component.scss'],
  imports: [MatProgressSpinner, InfoDisplayPipe, NgClass]
})
export class GridNameCellComponent extends AbstractTableEngineCellComponent<GridNameCellComponent> implements OnInit {
  private readonly gridNameCellService: GridNameCellService = inject(GridNameCellService);
  private readonly translateService: TranslateService = inject(TranslateService);
  isDataLoaded: WritableSignal<boolean> = signal(false);
  dataToDisplay: WritableSignal<any> = signal(null);
  displayErrorMessage: WritableSignal<boolean> = signal(false);
  errorMessage: WritableSignal<string> = signal('N/A');

  ngOnInit() {
    if (this.data()) {
      this.gridNameCellService
        .getGridNameById(this.data())
        .pipe(
          catchError((error) => {
            this.displayErrorMessage.set(true);
            this.isDataLoaded.set(true);
            return of(error);
          }),
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe((grid) => {
          this.isDataLoaded.set(true);
          this.dataToDisplay.set(grid.name);
        });
    } else {
      this.isDataLoaded.set(true);
      this.dataToDisplay.set(this.translateService.instant('FAVORITE_VIEW.DETAIL.DEFAULT_GRID'));
    }
  }
}
