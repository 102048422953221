import { HeaderType, I4BColumnHeader } from '../models/i4b-column-header.model';

export class I4BBasicColumnHeader {
  type = HeaderType.BASIC;
  icon = '';
  displayName = '';
  showHeaderName = true;
  enableSorting = true;

  constructor(customHeader?: Partial<I4BColumnHeader>) {
    if (customHeader) {
      this.displayName = customHeader.displayName ?? this.displayName;
      this.icon = customHeader.icon ?? '';
      this.type = customHeader.type ?? HeaderType.BASIC;
      this.showHeaderName = customHeader.showHeaderName !== undefined && customHeader.showHeaderName !== null ? customHeader.showHeaderName : true;
      this.enableSorting = customHeader.enableSorting !== undefined && customHeader.enableSorting !== null ? customHeader.enableSorting : true;
    }
  }
}
