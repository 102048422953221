import { props } from '@ngrx/store';

export class BaseActions<T> {
  getDefaultEvents() {
    return {
      'Add One': props<{ toAdd: T }>(),
      'Add One Success': props<{ added: T }>(),
      'Add One Failure': props<{ error: unknown }>(),
      'Update One': props<{ toUpdate: T }>(),
      'Update One Success': props<{ updated: T }>(),
      'Update One Failure': props<{ error: unknown }>(),
      'Delete One': props<{ toDelete: T }>(),
      'Delete One Success': props<{ deleted: T }>(),
      'Delete One Failure': props<{ error: unknown }>(),
      'Get One': props<{ id: string }>(),
      'Get One Success': props<{ element: T }>(),
      'Get One Failure': props<{ error: unknown }>(),
      'Select One': props<{ toSelect: T }>(),
      'Select One Success': props<{ selected: T }>(),
      'Select One Failure': props<{ error: any }>()
    };
  }
}
