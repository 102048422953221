import { Pagination, PlatformResponse } from '@iot-platform/models/common';
import { Topic } from '@iot-platform/models/ocm';
import { fromUserPreferences } from '@iot-platform/users';
import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
//
import * as fromTopicsDb from './topics-db.reducer';
import * as fromTopicsSubscribedProtocolsDb from './topics-subscribed-protocols-db.reducer';
import * as fromTopicsSubscribedProtocolsUi from './topics-subscribed-protocols-ui.reducer';
import * as fromTopicsSubscribedUsersDb from './topics-subscribed-users-db.reducer';
import * as fromTopicsSubscribedUsersUi from './topics-subscribed-users-ui.reducer';
import * as fromTopicsUi from './topics-ui.reducer';

export const topicsFeatureKey = 'topics';

export interface TopicsState {
  [fromTopicsDb.topicsDbFeatureKey]: fromTopicsDb.State;
  [fromTopicsUi.topicUiFeatureKey]: fromTopicsUi.State;
  [fromTopicsSubscribedProtocolsDb.topicsSubscribedProtocolsDbFeatureKey]: fromTopicsSubscribedProtocolsDb.State;
  [fromTopicsSubscribedProtocolsUi.topicsSubscribedProtocolsUiFeatureKey]: fromTopicsSubscribedProtocolsUi.State;
  [fromTopicsSubscribedUsersDb.topicsSubscribedUsersDbFeatureKey]: fromTopicsSubscribedUsersDb.State;
  [fromTopicsSubscribedUsersUi.topicsSubscribedUsersUiFeatureKey]: fromTopicsSubscribedUsersUi.State;
}

export interface State {
  [topicsFeatureKey]: TopicsState;
}

export function reducers(state: TopicsState | undefined, action: Action) {
  return combineReducers({
    [fromTopicsDb.topicsDbFeatureKey]: fromTopicsDb.reducer,
    [fromTopicsUi.topicUiFeatureKey]: fromTopicsUi.reducer,
    [fromTopicsSubscribedProtocolsDb.topicsSubscribedProtocolsDbFeatureKey]: fromTopicsSubscribedProtocolsDb.reducer,
    [fromTopicsSubscribedProtocolsUi.topicsSubscribedProtocolsUiFeatureKey]: fromTopicsSubscribedProtocolsUi.reducer,
    [fromTopicsSubscribedUsersDb.topicsSubscribedUsersDbFeatureKey]: fromTopicsSubscribedUsersDb.reducer,
    [fromTopicsSubscribedUsersUi.topicsSubscribedUsersUiFeatureKey]: fromTopicsSubscribedUsersUi.reducer
  })(state, action);
}

export const selectTopicsState = createFeatureSelector<TopicsState>(topicsFeatureKey);
const getUserPreferencesState = createFeatureSelector<fromUserPreferences.UserPreferencesState>(fromUserPreferences.userPreferencesFeatureKey);

// ****** //
// Topics //
// ****** //

export const selectTopicsDbState = createSelector(selectTopicsState, (state: TopicsState) => state[fromTopicsDb.topicsDbFeatureKey]);

export const selectTopicsUiState = createSelector(selectTopicsState, (state: TopicsState) => state[fromTopicsUi.topicUiFeatureKey]);

export const {
  selectIds: getTopicsIds,
  selectEntities: getTopicsEntities,
  selectAll: getAllTopics,
  selectTotal: getTotalTopics
} = fromTopicsDb.adapter.getSelectors(selectTopicsDbState);

export const getSelectedTopicId = createSelector(selectTopicsDbState, fromTopicsDb.getSelectedTopicId);
export const getCurrentFilters = createSelector(selectTopicsDbState, fromTopicsDb.getCurrentFilters);
export const getSelectedTopic = createSelector(
  getTopicsEntities,
  getSelectedTopicId,
  (entities, selectedTopicId) => selectedTopicId && entities[selectedTopicId]
);

export const getTopicsLoading = createSelector(selectTopicsUiState, fromTopicsUi.getTopicsLoading);
export const getTopicsLoaded = createSelector(selectTopicsUiState, fromTopicsUi.getTopicsLoaded);
export const getTopicsError = createSelector(selectTopicsUiState, fromTopicsUi.getTopicsError);

export const getPagination = createSelector(selectTopicsDbState, getUserPreferencesState, fromUserPreferences.getCurrentUser, (state: fromTopicsDb.State) => ({
  ...state.pagination,
  limit: 100
}));

export const getAddedTopicForConfig = createSelector(selectTopicsDbState, fromTopicsDb.getAddedTopicForConfig);
export const getConfigurePopupStatus = createSelector(selectTopicsUiState, fromTopicsUi.getOpenConfigurePopup);

export const getFormattedData = createSelector(getAllTopics, getPagination, (data: Topic[], pagination: Pagination) => {
  const response: PlatformResponse = {
    data,
    currentPage: pagination.currentPage,
    hasMore: pagination.hasMore,
    limit: pagination.limit,
    maxPage: pagination.maxPage,
    total: pagination.total
  };
  return response;
});

export const getTotal = createSelector(selectTopicsDbState, getPagination, (state, pagination) => pagination.total);

export const getConfigurePopupData = createSelector(getAddedTopicForConfig, getConfigurePopupStatus, (topic, status) => ({ canOpen: status, topic }));

// ******************* //
// Subscribed Protocol //
// ******************* //

export const selectTopicsSubscribedProtocolsDbState = createSelector(selectTopicsState, (state: TopicsState) =>
  state ? state[fromTopicsSubscribedProtocolsDb.topicsSubscribedProtocolsDbFeatureKey] : null
);

export const selectTopicsSubscribedProtocolsUiState = createSelector(selectTopicsState, (state: TopicsState) =>
  state ? state[fromTopicsSubscribedProtocolsUi.topicsSubscribedProtocolsUiFeatureKey] : null
);

export const {
  selectIds: getTopicsSubscribedProtocolsIds,
  selectEntities: getTopicsSubscribedProtocolsEntities,
  selectAll: getAllTopicsSubscribedProtocols,
  selectTotal: getTotalTopicsSubscribedProtocols
} = fromTopicsSubscribedProtocolsDb.adapter.getSelectors(selectTopicsSubscribedProtocolsDbState);

export const getSubscribedProtocols = createSelector(getAllTopicsSubscribedProtocols, (subscribers) => subscribers);
export const getSubscribedProtocolsPending = createSelector(selectTopicsSubscribedProtocolsUiState, fromTopicsSubscribedProtocolsUi.getPending);

// ******************* //
//   Subscribed Users  //
// ******************* //

export const selectTopicSubscribedUsersDbState = createSelector(selectTopicsState, (state: TopicsState) =>
  state ? state[fromTopicsSubscribedUsersDb.topicsSubscribedUsersDbFeatureKey] : null
);

export const selectTopicsSubscribedUsersUiState = createSelector(selectTopicsState, (state: TopicsState) =>
  state ? state[fromTopicsSubscribedUsersUi.topicsSubscribedUsersUiFeatureKey] : null
);

export const {
  selectIds: getTopicsSubscribedUserIds,
  selectEntities: getTopicsSubscribedUserEntities,
  selectAll: getAllTopicsSubscribedUsers,
  selectTotal: getTotalTopicsSubscribedUsers
} = fromTopicsSubscribedUsersDb.adapter.getSelectors(selectTopicSubscribedUsersDbState);

export const getSubscribedUsers = createSelector(getAllTopicsSubscribedUsers, (subscribers) => subscribers);
export const getSubscribedUsersPending = createSelector(selectTopicsSubscribedUsersUiState, fromTopicsSubscribedUsersUi.getPending);
