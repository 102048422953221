<form [formGroup]="form" fxLayout="column" fxFlex style="width: 100%">
  <div fxLayout="row ">
    <div class="create-asset-from-template-dialog--model-radio-button-group" fxFlex="60">
      <mat-radio-group color="accent" formControlName="model" fxLayout="row wrap" labelPosition="before">
        @for (model of assetModels(); track model.name) {
          <mat-radio-button [value]="model" fxFlex="25%">
            <div class="create-asset-from-template-dialog--model-radio-button" fxLayout="column" fxLayoutAlign=" center" fxLayoutGap="4px">
              <div class="create-asset-from-template-dialog--category" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="4px">
                <mat-icon [svgIcon]="model.category.icon"></mat-icon>
                <span class="create-asset-from-template-dialog--category-name" fxFlex>{{
                  'ASSETS.CREATION_DIALOG.ASSET_MODEL_CATEGORY_NAMES.' + model.category.name | translate
                }}</span>
              </div>

              <span class="fw-600">{{ model.name | uppercase }}</span>
              <img [alt]="model.name" [src]="model.icon" />
            </div>
          </mat-radio-button>
        }
      </mat-radio-group>
    </div>

    <div fxFlex="40" fxLayout="column" fxLayoutGap="16px">
      @defer {
        <iot-platform-ui-async-autocomplete
          (reset)="entityControl.reset()"
          (selectionChanged)="entityControl.setValue($event)"
          [data]="entities()"
          [disabled]="!entities() || !!site()"
          [displayKey]="'label'"
          [displaySearchIcon]="false"
          [filterKey]="'label'"
          [initialItem]="initialEntity()"
          [minLength]="1"
          [placeholder]="'IOT_DICTIONARY.ENTITY'"
          [required]="true"
          [showSpinner]="!entities()"
        />

        <iot-platform-ui-async-autocomplete
          (reset)="siteControl.reset()"
          (selectionChanged)="siteControl.setValue($event)"
          [data]="sites()"
          [disabled]="siteDisabled()"
          [displayKey]="'name'"
          [displaySearchIcon]="false"
          [filterKey]="'name'"
          [initialItem]="site()"
          [minLength]="1"
          [placeholder]="'ASSETS.CREATION_DIALOG.SITE_PLACEHOLDER'"
          [required]="true"
          [showSpinner]="sitesLoading()"
        />
      }
    </div>
  </div>
</form>
