import { Component, DestroyRef, inject, input, signal, Signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { MatMenuTrigger } from '@angular/material/menu';
import { NavigationEnd, Router } from '@angular/router';
import { SidenavMenuItem } from '@iot-platform/models/i4b';
import { filter, map } from 'rxjs/operators';

@Component({
    selector: 'iot-platform-ui-sidenav-items',
    templateUrl: './sidenav-items.component.html',
    styleUrls: ['./sidenav-items.component.scss'],
    standalone: false
})
export class SidenavItemsComponent {
  static currentMenuTrigger: WritableSignal<MatMenuTrigger | null> = signal(null);
  static timeoutIdMouseLeave: WritableSignal<number | null> = signal(null);
  static timeoutIdMouseEnter: WritableSignal<number | null> = signal(null);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  public readonly router: Router = inject(Router);
  item = input.required<SidenavMenuItem>();
  url: Signal<string | undefined> = toSignal(
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map((event: NavigationEnd) => event.url),
      takeUntilDestroyed(this.destroyRef)
    )
  );

  handleClick(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();
    this.item().handleClick?.();
    SidenavItemsComponent.currentMenuTrigger()?.closeMenu();
  }

  navigate(): void {
    if (!this.item().externalLink) {
      this.router.navigate([this.item().path]);
    } else {
      window.open(this.item().path, '_blank');
    }
  }

  mouseEnter(event: MouseEvent, trigger?: MatMenuTrigger): void {
    event.stopPropagation();
    window.clearTimeout(SidenavItemsComponent.timeoutIdMouseEnter() as number);
    window.clearTimeout(SidenavItemsComponent.timeoutIdMouseLeave() as number);

    if (SidenavItemsComponent.currentMenuTrigger && this.item().level === 0) {
      SidenavItemsComponent.currentMenuTrigger()?.closeMenu();
      SidenavItemsComponent.currentMenuTrigger.set(null);
    }

    if (trigger && this.item().hasChildren) {
      SidenavItemsComponent.currentMenuTrigger.set(trigger);
      SidenavItemsComponent.timeoutIdMouseEnter.set(
        window.setTimeout(() => {
          trigger.openMenu();
        }, 400)
      );
    }
  }

  mouseLeave(event: MouseEvent): void {
    event.stopPropagation();

    if (SidenavItemsComponent.currentMenuTrigger) {
      window.clearTimeout(SidenavItemsComponent.timeoutIdMouseEnter() as number);
      SidenavItemsComponent.timeoutIdMouseLeave.set(
        window.setTimeout(() => {
          SidenavItemsComponent.currentMenuTrigger()?.closeMenu();
          SidenavItemsComponent.currentMenuTrigger.set(null);
        }, 2000)
      );
    }
  }
}
