<div
  (click)="$event?.preventDefault(); onClick()"
  [ngClass]="{
    'ag-row-group-inner-renderer-cell__group': node()?.group,
    'ag-row-group-inner-renderer-cell__group_leaf': !node()?.group,
    'ag-row-group-inner-renderer-cell__link': isLink(),
    'ag-row-group-inner-renderer-cell--not-readable': !color()?.isReadable,
  }"
  [ngStyle]="{ backgroundColor: color()?.hex, borderColor: color()?.hex }"
>
  @if (node()?.group) {
    <span class="ag-row-group-inner-renderer-cell__group_value">{{ value() }}</span>
  } @else {
    <mat-icon class="ag-row-group-inner-renderer-cell__group_icon">arrow_right_alt</mat-icon>
  }
</div>
