import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { NgClass } from '@angular/common';
import { Component, computed, DestroyRef, effect, inject, Signal, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DynamicDataService } from '@iot-platform/core';
import { GetUtils } from '@iot-platform/iot-platform-utils';
import { InfoDisplayPipe } from '@iot-platform/pipes';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { get } from 'lodash';

import { CustomCellParams } from '../../../models/custom-cell.params';
import { ExpressionType } from './expression-type.enum';
import { FunctionType } from './function-type.enum';
import { MethodType } from './method-type.enum';
import { OperatorType } from './operator-type.enum';

@Component({
  imports: [NgClass, TranslateModule, InfoDisplayPipe, MatProgressSpinner, MatTooltipModule],
  providers: [InfoDisplayPipe],
  selector: 'grid-engine-dynamic-data-cell',
  templateUrl: './dynamic-data-cell.component.html',
  styleUrls: ['./dynamic-data-cell.component.scss']
})
export class DynamicDataCellComponent implements ICellRendererAngularComp {
  private readonly dynamicDataService: DynamicDataService = inject(DynamicDataService);
  private readonly translateService: TranslateService = inject(TranslateService);
  private readonly destroy = inject(DestroyRef);
  //
  dataToDisplay: WritableSignal<any> = signal('-');
  loaded: WritableSignal<boolean> = signal(false);
  loading: WritableSignal<boolean> = signal(false);

  cssClasses: WritableSignal<{ [key: string]: boolean }> = signal({});
  displayErrorMessage: WritableSignal<boolean> = signal(false);
  errorMessage: WritableSignal<string> = signal('N/A');

  // sanitizer: DomSanitizer = inject(DomSanitizer);
  params: WritableSignal<CustomCellParams> = signal(null);
  isEmpty: Signal<boolean> = computed(() => {
    const params = this.params();
    return !params?.value && params?.value !== 0 && params?.value !== false;
  });

  cellOptions = computed(() => {
    const params = this.params();
    return params?.cellOptions;
  });

  clickEvent = computed(() => {
    const params = this.params();
    return params?.eventConfiguration;
  });

  rawData = computed(() => {
    const params = this.params();
    return params?.data;
  });

  isClickAllowed = computed(() => {
    const clickEvent = this.clickEvent();
    const rawData = this.rawData();
    const allowConditions = get(clickEvent, 'allowConditions') ?? [];
    if (allowConditions.length) {
      return allowConditions.some((c: { fieldId: string; value: string }) => get(rawData, c.fieldId) === c.value);
    } else {
      return true;
    }
  });

  agInit(params: CustomCellParams): void {
    this.params.set(params);
  }

  refresh(): boolean {
    return false;
  }

  initCssClasses = effect(() => {
    const cellOptions = this.cellOptions();
    const clickEvent = this.clickEvent();
    const isClickAllowed = this.isClickAllowed();
    this.cssClasses.set({
      visited: cellOptions && cellOptions.linkStyling && isClickAllowed,
      link: !!clickEvent && !!clickEvent.type,
      link_padding: cellOptions && cellOptions.padding
    });
  });

  cellOptionsEffect = effect(() => {
    const params = this.params();
    const loading = this.loading();
    const loaded = this.loaded();

    if (!params?.cellOptions?.api) {
      this.processData(params?.data);
    } else if (!loading && !loaded) {
      this.loading.set(true);
      this.loaded.set(false);
      this.dynamicDataService
        .getDynamicData(params?.cellOptions.api.endpoint, {
          ...params?.cellOptions.api,
          rawData: params?.data
        })
        .pipe(takeUntilDestroyed(this.destroy))
        .subscribe({
          next: (data: any) => {
            this.loaded.set(true);
            this.loading.set(false);
            this.processData(data);
          },
          error: () => {
            this.displayErrorMessage.set(true);
            this.loaded.set(false);
            this.loading.set(false);
          }
        });
    }
  });

  onClick(event: MouseEvent): void {
    event.stopPropagation();
    if (this.clickEvent() && this.clickEvent().type && this.isClickAllowed()) {
      this.params().dispatchEvent({
        type: this.clickEvent().type,
        options: this.clickEvent().options,
        rawData: this.params().data,
        cachedData: this.dynamicDataService.cachedData$.value
      });
    }
  }

  private processData(data: any): void {
    // In case there's no expressions
    if (!this.cellOptions().expressions) {
      this.handleDefault(data);
    } else {
      // Expressions found
      this.cellOptions().expressions.forEach((expression: any) => {
        switch (expression.type) {
          case ExpressionType.FUNCTION:
            this.handleFunctionExpression(expression, data);
            break;
          case ExpressionType.ALERT:
            this.handleAlertExpression(expression, data);
            break;
          default:
            this.handleDefault(data);
            break;
        }
      });
      // In case there's no functions
      if (!this.cellOptions().expressions.find((exp) => exp.type === ExpressionType.FUNCTION)) {
        this.handleDefault(data);
      }
    }
  }

  private handleDefault(data: any): void {
    this.dataToDisplay.set(this.transformDataToBeDisplayed(data));
  }

  // Default behavior to display data
  private transformDataToBeDisplayed(data: any): any {
    if (this.cellOptions().displayProperties) {
      const dataToDisplayed = this.cellOptions().displayProperties.reduce((acc: string, value: string) => {
        acc = acc.concat(' ', GetUtils.get(data, value, ''));
        return acc;
      }, '');
      return dataToDisplayed.trim();
    }
    return data;
  }

  // Apply predicates
  private processPredicates(elem: any, predicates): boolean {
    const result: boolean[] = predicates.map((predicate) => this.processOperator(elem, predicate));
    // Return false if at least we found one falsy item
    return !result.some((b) => !b);
  }

  // Apply join predicates
  private processJoinPredicates(elem: any, predicates, separator = ', '): string {
    return predicates
      .reduce((acc, p) => (this.processOperator(elem, p) ? [...acc, this.translateService.instant(p.normalizedValue)] : [...acc]), [])
      .join(separator);
  }

  private handleOperator(operator: OperatorType, value1, value2): boolean {
    let v1: any = value1;
    let v2: any = value2;
    if (value1 !== null && typeof value1 === 'string') {
      v1 = this.translateService.instant(value1);
    }
    if (value2 !== null && typeof value2 === 'string') {
      v2 = this.translateService.instant(value2);
    }
    if (operator === OperatorType.EQUAL) {
      return v1 === v2;
    } else if (operator === OperatorType.DIFFERENT) {
      return v1 !== v2;
    }
    return false;
  }

  private processOperator(elem: any, predicate: any): boolean {
    const { operator } = predicate;
    const { key, value, field1, field2, notNull } = predicate;
    if (predicate.hasOwnProperty('key') && predicate.hasOwnProperty('value')) {
      const v = GetUtils.get(elem, key, null);
      if ((notNull && v !== null) || !notNull) {
        return this.handleOperator(operator, v, value);
      }
    } else if (predicate.hasOwnProperty('field1') && predicate.hasOwnProperty('field2')) {
      const v1 = GetUtils.get(elem, field1, null);
      const v2 = GetUtils.get(elem, field2, null);
      if ((notNull && v1 !== null && v2 !== null) || !notNull) {
        return this.handleOperator(operator, v1, v2);
      }
    }
    return false;
  }

  // Apply methods
  private processMethode(data: any, predicates, method: MethodType): boolean {
    if (method === MethodType.SOME && DynamicDataCellComponent.isArray(data)) {
      return data.some((elem: any) => this.processPredicates(elem, predicates));
    } else if (method === MethodType.MISMATCH) {
      return this.processPredicates(data, predicates);
    }
    return false;
  }

  private static isArray(data): boolean {
    return data && data instanceof Array;
  }

  // handle alerts expressions and set dynamic css classes
  private handleAlertExpression({ predicates, cssClass, method, field }, data: any): void {
    // eslint-disable-next-line no-underscore-dangle
    const _data: any = field ? GetUtils.get(data, field) : data;
    this.cssClasses.update((value) => {
      const cssClasses = { ...value };
      if (!cssClasses[cssClass]) {
        if (method) {
          cssClasses[cssClass] = this.processMethode(_data, predicates, method);
        } else {
          cssClasses[cssClass] = this.processPredicates(_data, predicates);
        }
      }

      return cssClasses;
    });
  }

  private handleSimpleJoin(data, field): string {
    return data.map((elem) => elem[field]).join(', ');
  }

  // Handle functions expressions, function are applied on api response data
  private handleFunctionExpression(expression, data: any): void {
    const { value, field, predicates, normalizedValue, defaultProperty } = expression;
    // eslint-disable-next-line no-underscore-dangle
    const _data: any = field ? GetUtils.get(data, field, null) : data;
    switch (value) {
      case FunctionType.COUNT:
        this.dataToDisplay.set(_data !== null ? _data.length : 0);
        break;
      case FunctionType.SIMPLE_JOIN:
        this.dataToDisplay.set(data !== null ? this.handleSimpleJoin(data, field) : '-');
        break;
      case FunctionType.NORMALIZE:
        this.dataToDisplay.set(
          this.processPredicates(_data, predicates) ? this.translateService.instant(normalizedValue) : GetUtils.get(_data, defaultProperty, null)
        );
        break;
      case FunctionType.NORMALIZE_JOIN:
        this.dataToDisplay.set(this.processJoinPredicates(_data, predicates));
        break;
      default:
        break;
    }
  }
}
