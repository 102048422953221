import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component, signal, WritableSignal } from '@angular/core';
import { InfoDisplayPipe } from '@iot-platform/pipes';

import { CustomCellParams } from '../../../models/custom-cell.params';

@Component({
    selector: 'grid-engine-color-cell',
    templateUrl: './tag-cell.component.html',
    imports: [InfoDisplayPipe],
    providers: [InfoDisplayPipe],
    styleUrls: ['./tag-cell.component.scss']
})
export class TagCellComponent implements ICellRendererAngularComp {
  params: WritableSignal<CustomCellParams> = signal(null);

  agInit(params: CustomCellParams): void {
    this.params.set(params);
  }

  refresh(): boolean {
    return false;
  }
}
