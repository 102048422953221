import { inject, Injectable, Signal } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AbstractDeviceCallLogsService } from '@iot-platform/feature/device-call-logs';
import { I4BGrid, I4BGridData, I4BGridOptions } from '@iot-platform/models/grid-engine';
import { Device } from '@iot-platform/models/i4b';
import { DeviceCallLog } from '@iot-platform/models/common';
import { DeviceCallLogsFacade } from '../state/facades/device-call-logs.facade';
import { DevicesFacade } from '../state/facades/devices.facade';
import { DateFormatPipe } from '@iot-platform/pipes';
import { DeviceCallLogsGrid } from '../grids/device-call-logs-grid-definition';

@Injectable({ providedIn: 'root' })
export class DeviceCallLogsCtaService extends AbstractDeviceCallLogsService {
  private readonly deviceCallLogsFacade: DeviceCallLogsFacade = inject(DeviceCallLogsFacade);
  private readonly devicesFacade: DevicesFacade = inject(DevicesFacade);
  private readonly dateFormatPipe: DateFormatPipe = inject(DateFormatPipe);

  selectedDevice: Signal<Device> = this.devicesFacade.currentEntity;
  selectedDeviceCallLog: Signal<DeviceCallLog> = this.deviceCallLogsFacade.currentEntity;
  selectedDeviceCallLogMessage: Signal<string> = this.deviceCallLogsFacade.deviceCallLogMessage;

  getStaticGridDefinition(): Observable<I4BGrid<I4BGridOptions, I4BGridData> | null> {
    return of(new DeviceCallLogsGrid());
  }

  onOpen(deviceCallLog: DeviceCallLog): void {
    this.deviceCallLogsFacade.loadDeviceCallLogMessage(this.selectedDevice(), deviceCallLog);
  }

  onDownloadMessage(): void {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(new Blob([JSON.stringify(this.selectedDeviceCallLogMessage(), null, 2)], { type: 'application/json; charset=utf-8' }));
    link.download = `${this.selectedDevice()?.name}_${this.dateFormatPipe.transform(this.selectedDeviceCallLog()?.timestamp, 'yyyy-MM-dd_HH-mm-ss')}.json`;
    link.click();
  }
}
