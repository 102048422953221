import { BaseActions, BusinessProfile } from '@iot-platform/models/common';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

class BusinessProfileBaseActions extends BaseActions<BusinessProfile> {}

const baseActions = new BusinessProfileBaseActions();

export const AdminBusinessProfilesActions = createActionGroup({
  source: 'Admin Business Profiles',
  events: {
    ...baseActions.getDefaultEvents(),
    'Load Business Profiles': emptyProps(),
    'Load Business Profiles Success': props<{ businessProfiles: BusinessProfile[] }>(),
    'Load Business Profiles Failure': props<{ error: unknown }>(),
    'Filter Business Profiles': props<{ searchString: string }>(),
    'List Roles By Business Profile': props<{ businessProfileId: string }>(),
    'List Members By Business Profile': props<{ businessProfileId: string }>(),
    'List Tags By Business Profile': props<{ businessProfileId: string }>(),
    'List Favorite Views By Business Profile': props<{ businessProfileId: string }>(),
    'List Grids By Business Profile': props<{ businessProfileId: string }>(),
    'Navigate To Selected Organization': props<{ organizationId: string }>()
  }
});
