import { Injectable } from '@angular/core';
import { SortUtil } from '@iot-platform/iot-platform-utils';
import { ApiFeatureSelector, Filter, Pagination, TagCategory } from '@iot-platform/models/common';
import { createSelector } from '@ngrx/store';
import * as fromBusinessProfilesTags from '../reducers/business-profiles-tags.reducer';

@Injectable({
  providedIn: 'root'
})
export class AdminBusinessProfilesTagsSelectors extends ApiFeatureSelector<fromBusinessProfilesTags.State, TagCategory, Pagination, Filter> {
  constructor() {
    super(fromBusinessProfilesTags.adminBusinessProfilesTagsFeature.selectAdminBusinessProfilesTagsState, fromBusinessProfilesTags.adapter);
  }

  selectAllWithSortedLabels = createSelector(this.selectAll, (tagCategories) => {
    tagCategories.forEach((category: TagCategory) => {
      category.labels.sort(SortUtil.sortByName);
    });
    return tagCategories;
  });
}
