<mat-card class="dialog-card">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar class="dialog-toolbar" color="accent" fxLayout="row" fxLayoutAlign="space-between center">
      <h1 class="dialog-card-title">{{ 'ASSETS.TEMPLATE_VARIABLES_CONFIGURATION.TITLE' | translate }}</h1>
      <button (click)="close()" class="regular-round-button" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar>
  </mat-card-title>
  @if (displayLoader) {
    <mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar>
  } @else {
    <div class="progress-bar-placeholder"></div>
  }

  <mat-card-content class="dialog-card-content" fxLayout="row">
    @switch (step) {
      @case (1) {
        @if (assetTemplateLoading()) {
          <div class="asset-template-configure-form--loader" fxLayout="column" fxLayoutAlign="center ">
            <iot-platform-ui-card-loader></iot-platform-ui-card-loader>
          </div>
        } @else if (!!assetTemplate && !!data.assetVariables.length && !!assetTemplate.template?.variables?.length) {
          <form [formGroup]="configureForm" class="p-20" fxFlex fxLayout="row" fxLayoutGap="20px">
            <div class="asset-template-configure-form--section" fxFlex="50" fxLayout="column">
              <iot-platform-ui-detail-popup-section-header
                [color]="'#394c5a'"
                [headerTitle]="
                  'ASSETS.TEMPLATE_VARIABLES_CONFIGURATION.ASSET_VARIABLES_TO_CONFIGURE'
                    | translate: { totalSelected: variablesWithCorrectConfigurationTotal, totalVariables: assetTemplate.template.variables.length }
                "
                [separatorLinePosition]="'beneath'"
              ></iot-platform-ui-detail-popup-section-header>
              <div fxLayoutAlign="space-between center">
                <mat-checkbox
                  (change)="toggleAllSelection()"
                  [checked]="isAllSelected()"
                  [disabled]="!!variablesToBeEstimated"
                  [indeterminate]="selectedAssetVariables.hasValue() && !isAllSelected()"
                  class="asset-template-configure-form--global-checkbox"
                >
                  <span [textContent]="selectedAssetVariables.selected.length" class="table-total-selected fw-600"></span>
                </mat-checkbox>

                <div class="">
                  <span
                    [matTooltipClass]="selectedAssetVariables.isEmpty() || !deviceSource.value ? 'warning-tooltip' : 'regular-tooltip'"
                    [matTooltip]="
                      (selectedAssetVariables.isEmpty()
                        ? 'ASSETS.TEMPLATE_VARIABLES_CONFIGURATION.NO_VARIABLE_CHECKED'
                        : !deviceSource.value
                          ? 'ASSETS.TEMPLATE_VARIABLES_CONFIGURATION.SELECT_SOURCE_FIRST'
                          : 'ASSETS.TEMPLATE_VARIABLES_CONFIGURATION.LINK_VARIABLES'
                      ) | translate
                    "
                  >
                    <button
                      (click)="onAssignSuggestedFormulasClicked()"
                      [disabled]="selectedAssetVariables.isEmpty() || !deviceSource.value || !!variablesToBeEstimated"
                      class="asset-template-configure-form--match-button"
                      color="accent"
                      mat-icon-button
                    >
                      <mat-icon [svgIcon]="'variables_matching'"></mat-icon>
                    </button>
                  </span>
                  <button
                    (click)="onResetFormulasClicked()"
                    [disabled]="selectedAssetVariables.isEmpty() || !!variablesToBeEstimated"
                    [matTooltip]="'ASSETS.TEMPLATE_VARIABLES_CONFIGURATION.UNLINK_VARIABLES' | translate"
                    color="warn"
                    mat-icon-button
                    matTooltipClass="warning-tooltip"
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                </div>
              </div>
              <mat-accordion class="full-height-with-overflow">
                @for (variable of assetTemplate.template.variables; track variable) {
                  <mat-expansion-panel
                    [disabled]="!!variablesToBeEstimated || !variable.formula"
                    [ngClass]="{
              'unconfigured-variable': !isConfigurationCorrect(variable),
              'configuration-error': isVariableMatchingSourcePending(variable),
              'configured-variable': isConfigurationCorrect(variable)
            }"
                    class="asset-template-configure-form--panel"
                  >
                    <mat-expansion-panel-header class="asset-template-configure-form--panel-header">
                      <div
                        class="asset-template-configure-form--panel-header-content full-width"
                        fxLayout="row"
                        fxLayoutAlign="space-between center"
                        fxLayoutGap="6px"
                      >
                        <mat-checkbox
                          (change)="toggleSelection(variable)"
                          (click)="$event.stopPropagation()"
                          [checked]="isChecked(variable)"
                          [disabled]="!!variablesToBeEstimated || !variable?.formula"
                        ></mat-checkbox>
                        <mat-icon [color]="isConfigurationCorrect(variable) ? 'accent' : 'warn'">{{ getIconByVariable(variable) }}</mat-icon>
                        <span>{{ variable.name }}</span>
                        <span> -</span>
                        <span fxFlex>{{
                          variable?.formula ? '[' + variable.formula.model + ']' : ('ASSETS.TEMPLATE_VARIABLES_CONFIGURATION.NO_FORMULA' | translate)
                        }}</span>
                        @if (variable?.formula?.evaluation) {
                          <div>
                            {{ variable.formula.evaluation.lastRecords[0]?.value | numberFormat }}&nbsp;{{ variable.unit }}
                            -
                            {{ variable.formula.evaluation.lastRecords[0]?.datetime | dateFormat }}
                          </div>
                        }
                        <mat-icon
                          [ngClass]="{ 'layout-invisible': !variable.thresholds?.values?.length, 'layout-visible': variable.thresholds?.values?.length }"
                          [svgIcon]="'thresholds'"
                          class="threshold-icon"
                        ></mat-icon>
                      </div>
                    </mat-expansion-panel-header>
                    <iot4bos-ui-asset-variable-formula-configuration
                      (assignSuggestedVariable)="onAssignSuggestedFormulasClicked(variable)"
                      (removeSourcedVariable)="onResetFormulasClicked(variable)"
                      (selectMatchingVariable)="onMatchingVariableSelection(variable, $event)"
                      (updateAssetVariableThresholds)="onUpdateThresholds(variable, $event)"
                      [assetTemplateVariableFormula]="variable?.formula"
                      [disableMatching]="!deviceSource.value"
                      [readonly]="true"
                      [thresholds]="variable?.thresholds"
                      [unitList]="unitList"
                      [unit]="variable?.unit"
                    ></iot4bos-ui-asset-variable-formula-configuration>
                  </mat-expansion-panel>
                }
              </mat-accordion>
            </div>
            <div fxFlex="50" fxLayout="column">
              <iot-platform-ui-detail-popup-section-header
                [color]="'#394c5a'"
                [headerTitle]="'ASSETS.TEMPLATE_VARIABLES_CONFIGURATION.SOURCES_AVAILABLE' | translate"
                [separatorLinePosition]="'beneath'"
              ></iot-platform-ui-detail-popup-section-header>
              <div fxLayoutAlign="start center">
                @if (sourceType.value === 'devices' && (deviceList$ | async)?.length !== 0) {
                  <mat-form-field class="asset-variable-device" color="accent" fxFlex="40">
                    <mat-label>{{ 'ASSETS.VARIABLE_FORM.SELECT_DEVICE' | translate }}</mat-label>
                    <mat-select
                      (selectionChange)="onDeviceSelection(deviceSource.value.id)"
                      [disabled]="!!variablesToBeEstimated"
                      formControlName="deviceSource"
                      placeholder=""
                    >
                      @for (d of deviceList$ | async; track d.id) {
                        <mat-option [value]="d">{{ d.name }}</mat-option>
                      }
                    </mat-select>
                  </mat-form-field>
                }
              </div>
              @if (deviceSource.value && sourceType.value === 'devices') {
                <iot4bos-ui-asset-variable-list-for-asset-variable-configuration
                  [dataType]="'deviceVariable'"
                  [device]="deviceSource.value"
                  [variables]="deviceVariablesToDisplay"
                  class="asset-variable-configure-source-var-list-container"
                ></iot4bos-ui-asset-variable-list-for-asset-variable-configuration>
              } @else if (assetSource.value && sourceType.value === 'assets') {
                <iot4bos-ui-asset-variable-list-for-asset-variable-configuration
                  [asset]="assetSource.value"
                  [dataType]="'assetVariable'"
                  [variables]="assetVariablesToDisplay"
                  class="asset-variable-configure-source-var-list-container"
                ></iot4bos-ui-asset-variable-list-for-asset-variable-configuration>
              }
            </div>
          </form>
        } @else if (!assetTemplate) {
          <div class="full-height-width">
            <span class="fw-600 asset-variable-configure-no-template-loaded" fxFlexFill fxLayoutAlign="center center">
              {{ 'ASSETS.TEMPLATE_VARIABLES_CONFIGURATION.NO_TEMPLATE_LOADED' | translate }}
            </span>
          </div>
        } @else if (!data.assetVariables.length || !assetTemplate.template.variables.length) {
          <div class="full-height-width">
            <span class="fw-600 asset-variable-configure-no-template-loaded" fxFlexFill fxLayoutAlign="center center">
              {{ 'ASSETS.TEMPLATE_VARIABLES_CONFIGURATION.NO_VARIABLE_FOUND' | translate }}
            </span>
          </div>
        }
      }
      @default {
        <section class="full-height-width p-20">
          <div fxFlexFill fxLayout="column" fxLayoutAlign="center center">
            <span class="fw-600 asset-variable-configure-no-template-loaded">{{
              'ASSETS.TEMPLATE_VARIABLES_CONFIGURATION.CONFIGURATION_MESSAGE'
                | translate
                  : {
                      totalVariables: variablesWithCorrectConfigurationTotal.toString(10),
                      assetName: data.assetVariables[0]?.asset.name
                    }
            }}</span>
            @if (creationBufferProgress > 0) {
              <mat-progress-bar
                [bufferValue]="(creationBufferProgress / countNewVariables) * 100"
                [value]="(createdProgress / countNewVariables) * 100"
                class="progress"
                color="accent"
                mode="buffer"
              >
              </mat-progress-bar>
            }
            @if (creationErrorVariables.length > 0) {
              <div class="errors">
                <span class="message">
                  {{ 'ASSETS.VARIABLE.ASSOCIATE_VARIABLES.ERROR_DURING_CREATION' | translate }}
                </span>
                <ul>
                  @for (item of creationErrorVariables; track item.id) {
                    <li>
                      {{ item.name }}
                    </li>
                  }
                </ul>
              </div>
            }
          </div>
        </section>
      }
    }
  </mat-card-content>
  <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
    <button (click)="close()" class="button-regular" mat-button>{{ 'IOT_DICTIONARY.CANCEL' | translate }}</button>
    @if (step === 2) {
      <button (click)="backToConfiguration()" class="button-regular" mat-button>{{ 'IOT_DICTIONARY.BACK' | translate }}</button>
    }
    <button (click)="submit()" [disabled]="variablesWithCorrectConfigurationTotal < 1" class="button-regular" color="accent" mat-raised-button>
      {{ 'IOT_DICTIONARY.' + (step === 1 ? 'CONFIGURE' : step === 2 ? 'CONFIRM' : 'CLOSE') | translate }}
    </button>
  </mat-card-actions>
</mat-card>
