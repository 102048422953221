import { computed, inject, Injectable } from '@angular/core';
import { AuthFacade, AuthorizationConcept, AuthorizationService, AuthorizationType } from '@iot-platform/auth';
import { BaseFacade, CommonApiRequest, FavoriteView, Filter, Pagination } from '@iot-platform/models/common';
import { Store } from '@ngrx/store';
import { noop } from 'rxjs';
import { AdminBusinessProfilesFavoriteViewActions } from '../actions/business-profiles-favorite-views.actions';
import { AdminBusinessProfilesFavoriteViewsSelectors } from '../selectors/admin-business-profiles-favorite-views.selectors';
import { AdminBusinessProfilesFacade } from './admin-business-profiles.facade';

@Injectable({
  providedIn: 'root'
})
export class AdminBusinessProfilesFavoriteViewsFacade extends BaseFacade<FavoriteView, Pagination, Filter> {
  protected readonly authFacade: AuthFacade = inject(AuthFacade);
  protected readonly authorizationService: AuthorizationService = inject(AuthorizationService);

  canCreate = computed(() => {
    this.authFacade.privileges();
    return this.authorizationService.applyAuthorization(AuthorizationConcept.BUSINESS_PROFILE, AuthorizationType.CREATE);
  });
  canRead = computed(() => {
    this.authFacade.privileges();
    return this.authorizationService.applyAuthorization(AuthorizationConcept.BUSINESS_PROFILE, AuthorizationType.READ);
  });
  canUpdate = computed(() => {
    this.authFacade.privileges();
    return this.authorizationService.applyAuthorization(AuthorizationConcept.BUSINESS_PROFILE, AuthorizationType.UPDATE);
  });
  canDelete = computed(() => {
    this.authFacade.privileges();
    return this.authorizationService.applyAuthorization(AuthorizationConcept.BUSINESS_PROFILE, AuthorizationType.DELETE);
  });

  constructor(
    protected override store: Store,
    protected override selector: AdminBusinessProfilesFavoriteViewsSelectors,
    private readonly adminBusinessProfilesFacade: AdminBusinessProfilesFacade
  ) {
    super(store, selector);
  }

  getAll(request: CommonApiRequest) {
    if (this.adminBusinessProfilesFacade.currentEntity()?.id) {
      this.store.dispatch(
        AdminBusinessProfilesFavoriteViewActions.loadFavoriteViewsByBusinessProfile({
          businessProfileId: this.adminBusinessProfilesFacade.currentEntity()?.id as string
        })
      );
    }
  }

  setFilters() {
    noop();
  }

  addFavoriteView(toAdd: FavoriteView) {
    this.store.dispatch(AdminBusinessProfilesFavoriteViewActions.addOne({ toAdd }));
  }

  updateFavoriteView(toUpdate: FavoriteView) {
    this.store.dispatch(AdminBusinessProfilesFavoriteViewActions.updateOne({ toUpdate }));
  }

  deleteFavoriteView(toDelete: FavoriteView) {
    this.store.dispatch(AdminBusinessProfilesFavoriteViewActions.deleteOne({ toDelete }));
  }

  duplicateFavoriteView(favoriteViewToDuplicate: FavoriteView) {
    this.store.dispatch(AdminBusinessProfilesFavoriteViewActions.duplicateFavoriteView({ favoriteViewToDuplicate }));
  }

  duplicateGridAndFavoriteView(gridToDuplicate, favoriteViewToDuplicate: FavoriteView) {
    this.store.dispatch(
      AdminBusinessProfilesFavoriteViewActions.duplicateGridAndFavoriteView({
        gridToDuplicate,
        favoriteViewToDuplicate
      })
    );
  }

  duplicateGridThenAddFavoriteView(grid, favoriteViewToAdd: FavoriteView) {
    this.store.dispatch(
      AdminBusinessProfilesFavoriteViewActions.duplicateGridThenAddFavoriteView({
        grid,
        favoriteViewToAdd
      })
    );
  }

  duplicateGridThenUpdateFavoriteView(grid, favoriteViewToUpdate: FavoriteView) {
    this.store.dispatch(
      AdminBusinessProfilesFavoriteViewActions.duplicateGridThenUpdateFavoriteView({
        grid,
        favoriteViewToUpdate
      })
    );
  }
}
