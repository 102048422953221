import { Component } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { DateFormatPipe } from '@iot-platform/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { AbstractTableEngineCellComponent } from '../abstract-table-engine-cell.component';

@Component({
  selector: 'i4b-table-engine-cyclical-call-fault-cell',
  templateUrl: './cyclical-call-fault-cell.component.html',
  styleUrls: ['./cyclical-call-fault-cell.component.scss'],
  imports: [MatTooltip, TranslateModule, DateFormatPipe, MatIcon],
  standalone: true
})
export class CyclicalCallFaultCellComponent extends AbstractTableEngineCellComponent<CyclicalCallFaultCellComponent> {}
