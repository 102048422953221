@switch (settings?.drawerDetails?.layout()) {
  @case (FeatureMasterViewDrawerDetailsLayout.GRID) {
    <div fxFlex fxLayout="column" fxLayoutGap="10px">
      <ng-container *ngTemplateOutlet="sectionHeader" />

      <div class="feature-master-view" data-cy="feature-master-view" fxFlex fxLayout="column">
        <div class="sticky">
          <ng-container *ngTemplateOutlet="toolbar" />
          <ng-container *ngTemplateOutlet="filterEngine" />
        </div>

        <div class="feature-master-view__engine-container" fxFlex>
          <mat-sidenav-container
            class="feature-master-view__drawer-container"
            [hasBackdrop]="settings?.drawerDetails?.hasBackdrop()"
            (backdropClick)="onBackdropClick()"
          >
            <mat-sidenav-content fxLayout="column">
              <ng-container *ngTemplateOutlet="pageContent" />
            </mat-sidenav-content>
            <mat-sidenav
              #sidenav
              [disableClose]="settings?.drawerDetails?.closeOnBackdropClick && !settings?.drawerDetails?.closeOnBackdropClick?.()"
              [mode]="settings?.drawerDetails?.mode() || 'side'"
              [opened]="settings?.drawerDetails?.opened()"
              [position]="settings?.drawerDetails?.position() || 'start'"
            >
              <ng-container *ngTemplateOutlet="drawerDetails" />
            </mat-sidenav>
          </mat-sidenav-container>
        </div>
      </div>
    </div>
  }
  @case (FeatureMasterViewDrawerDetailsLayout.FILTER_ENGINE) {
    <div fxFlex fxLayout="column" fxLayoutGap="10px">
      <ng-container *ngTemplateOutlet="sectionHeader" />

      <div class="feature-master-view" data-cy="feature-master-view" fxFlex fxLayout="column">
        <div class="sticky">
          <ng-container *ngTemplateOutlet="toolbar" />
        </div>

        <div class="feature-master-view__engine-container" fxFlex>
          <mat-sidenav-container
            class="feature-master-view__drawer-container"
            [hasBackdrop]="settings?.drawerDetails?.hasBackdrop()"
            (backdropClick)="onBackdropClick()"
          >
            <mat-sidenav-content fxLayout="column">
              <ng-container *ngTemplateOutlet="filterEngine" />
              <ng-container *ngTemplateOutlet="pageContent" />
            </mat-sidenav-content>
            <mat-sidenav
              #sidenav
              [disableClose]="settings?.drawerDetails?.closeOnBackdropClick && !settings?.drawerDetails?.closeOnBackdropClick?.()"
              [mode]="settings?.drawerDetails?.mode() || 'over'"
              [opened]="settings?.drawerDetails?.opened()"
              [position]="settings?.drawerDetails?.position() || 'start'"
            >
              <ng-container *ngTemplateOutlet="drawerDetails" />
            </mat-sidenav>
          </mat-sidenav-container>
        </div>
      </div>
    </div>
  }
  @case (FeatureMasterViewDrawerDetailsLayout.TOOLBAR) {
    <div fxFlex fxLayout="column" fxLayoutGap="10px">
      <ng-container *ngTemplateOutlet="sectionHeader" />

      <div class="feature-master-view" data-cy="feature-master-view" fxFlex fxLayout="column">
        <div class="sticky"></div>

        <div class="feature-master-view__engine-container" fxFlex>
          <mat-sidenav-container
            class="feature-master-view__drawer-container"
            [hasBackdrop]="settings?.drawerDetails?.hasBackdrop?.()"
            (backdropClick)="onBackdropClick($event)"
          >
            <mat-sidenav-content fxLayout="column">
              <ng-container *ngTemplateOutlet="toolbar" />
              <ng-container *ngTemplateOutlet="filterEngine" />
              <ng-container *ngTemplateOutlet="pageContent" />
            </mat-sidenav-content>
            <mat-sidenav
              #sidenav
              [disableClose]="settings?.drawerDetails?.closeOnBackdropClick && !settings?.drawerDetails?.closeOnBackdropClick?.()"
              [mode]="settings?.drawerDetails?.mode() || 'over'"
              [opened]="settings?.drawerDetails?.opened()"
              [position]="settings?.drawerDetails?.position() || 'start'"
            >
              <ng-container *ngTemplateOutlet="drawerDetails" />
            </mat-sidenav>
          </mat-sidenav-container>
        </div>
      </div>
    </div>
  }
  @case (FeatureMasterViewDrawerDetailsLayout.ALL) {
    <div fxFlex fxLayout="column" fxLayoutGap="10px">
      <div class="feature-master-view" data-cy="feature-master-view" fxFlex fxLayout="column">
        <div class="sticky"></div>

        <div class="feature-master-view__engine-container" fxFlex>
          <mat-sidenav-container
            class="feature-master-view__drawer-container"
            [hasBackdrop]="settings?.drawerDetails?.hasBackdrop()"
            (backdropClick)="onBackdropClick()"
          >
            <mat-sidenav-content fxLayout="column">
              <ng-container *ngTemplateOutlet="sectionHeader" />
              <ng-container *ngTemplateOutlet="toolbar" />
              <ng-container *ngTemplateOutlet="filterEngine" />
              <ng-container *ngTemplateOutlet="pageContent" />
            </mat-sidenav-content>
            <mat-sidenav
              #sidenav
              [disableClose]="settings?.drawerDetails?.closeOnBackdropClick && !settings?.drawerDetails?.closeOnBackdropClick?.()"
              [mode]="settings?.drawerDetails?.mode() || 'over'"
              [opened]="settings?.drawerDetails?.opened()"
              [position]="settings?.drawerDetails?.position() || 'start'"
            >
              <ng-container *ngTemplateOutlet="drawerDetails" />
            </mat-sidenav>
          </mat-sidenav-container>
        </div>
      </div>
    </div>
  }
  @default {
    <div fxFlex fxLayout="column" fxLayoutGap="10px">
      <ng-container *ngTemplateOutlet="sectionHeader" />

      <div class="feature-master-view" data-cy="feature-master-view" fxFlex fxLayout="column">
        <div class="sticky">
          <ng-container *ngTemplateOutlet="toolbar" />
          <ng-container *ngTemplateOutlet="filterEngine" />
        </div>

        <div class="feature-master-view__engine-container" fxFlex>
          <mat-sidenav-container
            class="feature-master-view__drawer-container"
            [hasBackdrop]="settings?.drawerDetails?.hasBackdrop()"
            (backdropClick)="onBackdropClick()"
          >
            <mat-sidenav-content fxLayout="column">
              <ng-container *ngTemplateOutlet="pageContent" />
            </mat-sidenav-content>
            <mat-sidenav
              #sidenav
              [disableClose]="settings?.drawerDetails?.closeOnBackdropClick && !settings?.drawerDetails?.closeOnBackdropClick?.()"
              [mode]="settings?.drawerDetails?.mode() || 'side'"
              [opened]="settings?.drawerDetails?.opened()"
              [position]="settings?.drawerDetails?.position() || 'start'"
            >
              <ng-container *ngTemplateOutlet="drawerDetails" />
            </mat-sidenav>
          </mat-sidenav-container>
        </div>
      </div>
    </div>
  }
}

<ng-template #sectionHeader>
  @if (settings?.sectionHeader?.enabled?.()) {
    <iot-platform-ui-section-header
      (dispatchEvent)="onMasterViewEngineEvent($event)"
      [actions]="settings?.sectionHeader?.actions"
      [cssClassName]="settings?.sectionHeader?.cssClassName?.()"
      [icon]="settings?.sectionHeader?.icon?.()"
      [styles]="settings?.sectionHeader?.styles?.()"
      [svgIcon]="settings?.sectionHeader?.svgIcon?.()"
      [title]="settings?.sectionHeader?.title?.() | translate"
    />
  }
</ng-template>

<ng-template #toolbar>
  @if (settings?.toolbar?.enabled()) {
    <iot-platform-ui-toolbar-v2
      data-cy="feature-master-view-toolbar"
      (dispatchToolbarEvent)="onToolbarEvent($event)"
      [autoRefresh]="{
        counter: 100 - (timer() / grid()?.gridOptions?.autoRefresh?.delay) * 100,
        timeLeft: grid()?.gridOptions?.autoRefresh?.delay - timer(),
        displaySpinner: grid()?.gridOptions?.autoRefresh?.enabled
      }"
      [buttonList]="toolbarButtonList()"
      [withFavoriteViews]="settings?.toolbar?.withFavoriteViews?.()"
      [withGrids]="settings?.toolbar?.withGrids?.()"
      [dashboardsConfiguration]="dashboardsConfiguration?.()"
      [favoriteViewConfiguration]="favoriteViewsConfiguration()"
      [gridsConfiguration]="gridsConfiguration()"
      [isDataLoaded]="loaded()"
      [name]="settings?.toolbar?.toolbarName() | translate"
      [pageTypeOptions]="{
        visible: settings?.toolbar?.pageTypeOptions?.visible(),
        pageType: pageType(),
        options: settings?.toolbar?.pageTypeOptions?.options()
      }"
      [size]="toolbarSize()"
      [total]="totalItems()"
    />
  }
</ng-template>

<ng-template #filterEngine>
  @if (settings?.filterEngine?.enabled() && businessProfile()) {
    <iot-platform-ui-filter-engine
      data-cy="feature-master-view-filter-engine"
      (applyFilters)="onApplyFilters($event)"
      [clearAppliedFilters]="clearAppliedFilters$ | async"
      [currentFavoriteView]="currentFavoriteView()"
      [currentFilters]="currentFilters()"
      [displayActionButtons]="settings?.filterEngine?.displayActionButtons()"
      [expanded]="filterEngineOpened()"
      [masterView]="settings?.masterViewName()"
      [withFavoriteFilters]="settings?.filterEngine?.withFavoriteFilters()"
      [mode]="settings?.filterEngine?.mode()"
    />
  }
</ng-template>

<ng-template #gridEngines>
  @if (settings?.grid?.staticDefinitionType()) {
    <grid-engine-grid-page
      data-cy="feature-master-view-grid-page"
      (dispatchGridEvent)="onDispatchGridEvent($event)"
      (dispatchMasterViewEngineEvent)="onMasterViewEngineEvent($event)"
      (pageChange)="onPageChange($event)"
      [grid]="grid()"
      [gridSort]="gridSort()"
      [hidePaginator]="settings.grid.hidePaginator()"
      [style]="settings?.grid?.style()"
      [userPermissions]="userPermissions()"
    />
  } @else {
    <grid-engine-grid-manager
      data-cy="feature-master-view-grid-manager"
      (dispatchGridEvent)="onDispatchGridEvent($event)"
      (dispatchMasterViewEngineEvent)="onMasterViewEngineEvent($event)"
      (pageChange)="onPageChange($event)"
      (timerValueChanged)="onTimerValueChanged($event)"
      [currentFilters]="currentFilters()"
      [grid]="grid()"
      [gridSort]="gridSort()"
      [hidePaginator]="settings?.grid?.hidePaginator()"
      [style]="settings?.grid?.style()"
      [userPermissions]="userPermissions()"
      [visibleNodeId]="selectedRowId()"
      fxFlex
    />
  }
</ng-template>

<ng-template #pageContent>
  <div fxFlex fxLayout="row" fxLayoutAlign="center center">
    @switch (pageType()) {
      @case (ToolbarPageType.GRID) {
        <ng-container *ngTemplateOutlet="gridEngines" />
      }
      @case (ToolbarPageType.MAP) {
        @if (businessProfile()) {
          <iot-platform-maps-map
            data-cy="iot-platform-maps-map"
            fxFlexFill
            (dispatchEvent)="onMapEvent($event)"
            (dispatchMapNavigationEvent)="onMapNavigationEvent($event)"
            [concept]="mapConcept()"
            [displayMode]="mapDisplayMode()"
            [filters]="currentFilters()"
            [zoom]="3"
          />
        }
      }
      @case (ToolbarPageType.DASHBOARD) {
        <iot-platform-dashboards-dashboards-shell
          fxFlexFill
          [dashboard]="dashboardsConfiguration?.()?.currentDashboard"
          [filters]="currentFilters()"
        />
      }
      @default {
        <ng-container *ngTemplateOutlet="gridEngines" />
      }
    }
  </div>
</ng-template>

<ng-template #drawerDetails>
  @if (settings?.drawerDetails?.componentRef(); as componentRef) {
    <ng-container *ngComponentOutlet="componentRef.ref; inputs: componentRef.inputs" />
  }
</ng-template>
