<div class="association-by-device" fxLayout="column" fxLayoutGap="16px">
  @if (laoding()) {
    <iot-platform-ui-card-loader [backgroundClass]="'default-card h-82'" fxFlex />
  } @else {
    @if (association()?.devices?.length) {
      @for (device of association()?.devices; track device.id) {
        <ng-container [ngTemplateOutlet]="deviceTmpl" [ngTemplateOutletContext]="{ device: device }"></ng-container>
      }
    } @else {
      <iot-platform-ui-default-card fxFlex [backgroundClass]="'h-82'" [title]="'SITES.ASSOCIATIONS.NO_ASSOCIATED_DEVICE' | translate" />
    }
  }
</div>

<ng-template #deviceTmpl let-device="device">
  <div class="association-by-device__device" fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="16px" fxFlex>
    <section fxFlex="50%" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="10px">
      <iot4bos-ui-device-card [device]="device" (clicked)="navigateToDevice.emit($event.id)" />
    </section>

    <section fxFlex="50%" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="10px">
      @for (asset of device.assets; track asset.id) {
        <iot4bos-ui-asset-card class="association-by-device__asset" [asset]="asset" (clicked)="navigateToAsset.emit($event.id)" />
      } @empty {
        <iot-platform-ui-default-card
          fxFlex="50%"
          fxFlex.lt-md="100%"
          [backgroundClass]="'h-82'"
          [title]="'SITES.ASSOCIATIONS.NO_ASSOCIATED_ASSET' | translate"
        />
      }
    </section>
  </div>
</ng-template>
