import { EnvironmentProviders } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { adminBusinessProfilesFavoriteViewsEffects } from '../effects/admin-business-profiles-favorite-views.effects';
import { adminBusinessProfilesGridsEffects } from '../effects/admin-business-profiles-grids.effects';
import { adminBusinessProfilesMembersEffects } from '../effects/admin-business-profiles-members.effects';
import { adminBusinessProfilesRolesEffects } from '../effects/admin-business-profiles-roles.effects';
import { adminBusinessProfilesTagsEffects } from '../effects/admin-business-profiles-tags.effects';
import { adminBusinessProfilesEffects } from '../effects/admin-business-profiles.effects';
import { adminBusinessProfilesFavoriteViewsFeature } from './business-profiles-favorite-views.reducer';
import { adminBusinessProfilesGridsFeature } from './business-profiles-grids.reducer';
import { adminBusinessProfilesMembersFeature } from './business-profiles-members.reducer';
import { adminBusinessProfilesRolesFeature } from './business-profiles-roles.reducer';
import { adminBusinessProfilesTagsFeature } from './business-profiles-tags.reducer';
import { adminBusinessProfilesFeature } from './business-profiles.reducer';

export function provideBusinessProfilesState(): EnvironmentProviders[] {
  return [provideState(adminBusinessProfilesFeature.name, adminBusinessProfilesFeature.reducer), provideEffects(adminBusinessProfilesEffects)];
}

export function provideBusinessProfilesGridsState(): EnvironmentProviders[] {
  return [provideState(adminBusinessProfilesGridsFeature.name, adminBusinessProfilesGridsFeature.reducer), provideEffects(adminBusinessProfilesGridsEffects)];
}

export function provideBusinessProfilesFavoriteViewsState(): EnvironmentProviders[] {
  return [
    provideState(adminBusinessProfilesFavoriteViewsFeature.name, adminBusinessProfilesFavoriteViewsFeature.reducer),
    provideEffects(adminBusinessProfilesFavoriteViewsEffects)
  ];
}

export function provideBusinessProfilesRolesState(): EnvironmentProviders[] {
  return [provideState(adminBusinessProfilesRolesFeature.name, adminBusinessProfilesRolesFeature.reducer), provideEffects(adminBusinessProfilesRolesEffects)];
}

export function provideBusinessProfilesMembersState(): EnvironmentProviders[] {
  return [
    provideState(adminBusinessProfilesMembersFeature.name, adminBusinessProfilesMembersFeature.reducer),
    provideEffects(adminBusinessProfilesMembersEffects)
  ];
}

export function provideBusinessProfilesTagsState(): EnvironmentProviders[] {
  return [provideState(adminBusinessProfilesTagsFeature.name, adminBusinessProfilesTagsFeature.reducer), provideEffects(adminBusinessProfilesTagsEffects)];
}
