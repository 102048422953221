import { IDoesFilterPassParams } from '@ag-grid-community/core';
import { Component, inject } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FormGroupComponent } from '@iot-platform/iot-platform-ui';
import { I4BGridFilterOptions } from '@iot-platform/models/grid-engine';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { get } from 'lodash';
import { AbstractCellFilterComponent } from '../abstract-filter.component';

@Component({
  selector: 'grid-engine-audit-trail-attributes-text-filter',
  imports: [FormsModule, MatFormFieldModule, MatSelectModule, MatInputModule, TranslateModule, FlexLayoutModule, MatButtonModule, FormGroupComponent],
  templateUrl: './audit-trail-attributes-text-filter.component.html'
})
export class AuditTrailAttributesTextFilterComponent extends AbstractCellFilterComponent {
  private readonly translateService: TranslateService = inject(TranslateService);

  handleDoesFilterPass(params: IDoesFilterPassParams): boolean {
    const field = get(this.filterParams, ['colDef', 'filterParams', 'field'], get(this.filterParams, ['colDef', 'field']));
    const value = get(params.data, field);
    if (!value?.length) {
      const concept = this.translateService.instant(`AUDIT_TRAIL.HISTORY_LOG_POPUP.TITLE_CONCEPTS.${get(params, ['data', 'concept'], '')?.toUpperCase()}`);
      return this.compare(concept);
    }
    return this.compare(value);
  }

  private compare = (v): boolean => {
    const selectedOperator = this.operatorControl?.getRawValue();
    if (selectedOperator?.value === I4BGridFilterOptions.NOT_CONTAINS) {
      return `${v}`.indexOf(`${this.lastValue?.toString()?.toLowerCase().trim()}`) === -1;
    } else if (selectedOperator?.value === I4BGridFilterOptions.START_WITH) {
      return `${v}`.startsWith(`${this.lastValue?.toString()?.toLowerCase().trim()}`);
    } else if (selectedOperator?.value === I4BGridFilterOptions.END_WITH) {
      return `${v}`.endsWith(`${this.lastValue?.toString()?.toLowerCase().trim()}`);
    }
    return `${v}`.indexOf(`${this.lastValue?.toString()?.toLowerCase()}`) !== -1;
  };
}
