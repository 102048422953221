import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AuthorizationConcept, AuthorizationService, AuthorizationType } from '@iot-platform/auth';
import { CustomEncoder, ENVIRONMENT } from '@iot-platform/core';
import { CommonApiListResponse, Environment } from '@iot-platform/models/common';
import { Concept, Event, Log } from '@iot-platform/models/i4b';
import * as moment from 'moment';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { EventsService } from './events.service';

@Injectable({
  providedIn: 'root'
})
export class DeviceEventsService extends EventsService {
  protected readonly environment: Environment = inject(ENVIRONMENT);
  protected readonly httpClient: HttpClient = inject(HttpClient);
  private readonly authorizationService: AuthorizationService = inject(AuthorizationService);

  getDeviceEventById(eventId: string): Observable<Event> {
    return this.httpClient.get<Event>(`${this.environment.api.url}${this.environment.api.endpoints?.deviceEvents}/${eventId}`);
  }

  loadComments(deviceEvent: Event): Observable<Log[]> {
    let params: HttpParams = new HttpParams({ encoder: new CustomEncoder() });
    params = params.set('startDate', moment(deviceEvent.receptionTime).subtract(1, 'months').toISOString());

    const canReadDevice = this.authorizationService.applyAuthorization(AuthorizationConcept.DEVICE, AuthorizationType.READ);
    const canReadSite = this.authorizationService.applyAuthorization(AuthorizationConcept.SITE, AuthorizationType.READ);

    return forkJoin([
      this.httpClient.get<CommonApiListResponse<Log>>(
        `${this.environment.api.url}${this.environment.api.endpoints?.deviceEvents}/${deviceEvent.id}${this.environment.api.endpoints?.logs}`
      ),
      canReadDevice
        ? this.httpClient.get<Log[]>(
            `${this.environment.api.url}${this.environment.api.endpoints?.devices}/${deviceEvent.context?.device?.id}${this.environment.api.endpoints?.logs}`,
            { params }
          )
        : of([]),
      canReadSite
        ? this.httpClient.get<Log[]>(
            `${this.environment.api.url}${this.environment.api.endpoints?.sites}/${deviceEvent.context?.site?.id}${this.environment.api.endpoints?.logs}`,
            { params }
          )
        : of([])
    ]).pipe(
      map(([eventLogs, deviceLogs, siteLogs]) => [
        ...eventLogs.content.map((log: Log) => ({ ...log, concept: Concept.EVENT, icon: 'device-event' })),
        ...deviceLogs.map((log: Log) => ({ ...log, concept: Concept.DEVICE, icon: 'device' })),
        ...siteLogs.map((log: Log) => ({ ...log, concept: Concept.SITE, icon: 'site' }))
      ])
    );
  }

  addComment(deviceEventId: string, comment: string): Observable<Log> {
    return this.httpClient
      .put<Log>(`${this.environment.api.url}${this.environment.api.endpoints?.deviceEvents}/${deviceEventId}/comments`, { comment })
      .pipe(map((log: Log) => ({ ...log, concept: Concept.EVENT, icon: 'device-event' })));
  }

  editComment(deviceEventId: string, comment: Log): Observable<Log> {
    return this.httpClient
      .put<Log>(
        `${this.environment.api.url}${this.environment.api.endpoints?.deviceEvents}/${deviceEventId}${this.environment.api.endpoints?.logs}/${comment.id}`,
        {
          comment: comment.comment
        }
      )
      .pipe(map((log: Log) => ({ ...log, concept: Concept.EVENT, icon: 'device-event' })));
  }

  deleteComment(deviceEventId: string, commentId: string): Observable<string> {
    return this.httpClient
      .delete<void>(
        `${this.environment.api.url}${this.environment.api.endpoints?.deviceEvents}/${deviceEventId}${this.environment.api.endpoints?.logs}/${commentId}`
      )
      .pipe(map(() => commentId));
  }

  updateStatus(deviceEventId: string, status: string): Observable<Event> {
    return this.httpClient.put<Event>(`${this.environment.api.url}${this.environment.api.endpoints?.deviceEvents}/${deviceEventId}/${status}`, {
      comment: null
    });
  }
}
