import { Component } from '@angular/core';
import { UserNamePipe } from '@iot-platform/pipes';
import { AbstractTableEngineCellComponent } from '../abstract-table-engine-cell.component';

@Component({
  selector: 'i4b-table-engine-user-cell',
  templateUrl: './user-cell.component.html',
  styleUrls: ['./user-cell.component.scss'],
  imports: [UserNamePipe]
})
export class UserCellComponent extends AbstractTableEngineCellComponent<UserCellComponent> {}
