import { NgClass } from '@angular/common';
import { Component, computed, Signal } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { AbstractTableEngineCellComponent } from '../abstract-table-engine-cell.component';

@Component({
  selector: 'i4b-table-engine-dynamic-icon-cell',
  templateUrl: './dynamic-icon-cell.component.html',
  styleUrls: ['./dynamic-icon-cell.component.scss'],
  imports: [MatTooltip, MatIcon, NgClass]
})
export class DynamicIconCellComponent extends AbstractTableEngineCellComponent<DynamicIconCellComponent> {
  tooltip: Signal<string> = computed(() => {
    const data = this.data();
    const rawData = this.rawData();
    const cellOptions = this.cellOptions();
    return data ? rawData[cellOptions.tooltip.key] : '';
  });
}
