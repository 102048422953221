import { Component } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { HeaderShellComponent } from '../header-shell/header-shell.component';
import { IconHeaderCellComponent } from '../icon-header-cell/icon-header-cell.component';

@Component({
    selector: 'grid-engine-icon-and-text-header-cell',
    templateUrl: './icon-and-text-header-cell.component.html',
    imports: [HeaderShellComponent, MatIcon, TranslateModule],
    styleUrls: ['./icon-and-text-header-cell.component.scss']
})
export class IconAndTextHeaderCellComponent extends IconHeaderCellComponent {}
