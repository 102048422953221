import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthorizationConcept, AuthorizationService, AuthorizationType } from '@iot-platform/auth';
import { AnalyticsService } from '@iot-platform/core';
import { PopupComponent, VariableChartDialogComponent } from '@iot-platform/iot-platform-ui';
import { MasterViewEngineEvent, PlatformResponse } from '@iot-platform/models/common';
import { Device, DeviceVariable, VariablesTableFilters } from '@iot-platform/models/i4b';
import { UserPreferencesService } from '@iot-platform/users';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { NavigationApi } from '../../../../containers/+state/navigation.api';
import { DevicesFacade } from '../../state/facades/devices.facade';

@Component({
    selector: 'iot4bos-ui-device-variables-table',
    templateUrl: './device-variables-table.component.html',
    styleUrls: ['./device-variables-table.component.scss'],
    standalone: false
})
export class DeviceVariablesTableComponent implements OnInit, OnDestroy {
  analytic: AnalyticsService = new AnalyticsService('device_info_page');

  device$: Observable<Device> = this.navigationApi.selectedDevice$;
  variablesLoaded$: Observable<boolean> = this.navigationApi.deviceVariablesLoaded$;
  mvSettings$ = this.userPrefService.loadActiveSettings('deviceVariables');

  variables$: BehaviorSubject<PlatformResponse> = new BehaviorSubject<PlatformResponse>({
    total: 0,
    maxPage: 0,
    limit: 0,
    hasMore: false,
    data: [],
    currentPage: 0
  });

  variableGroups: { key: string; value?: string }[] = [];
  variableFiles: { key: string; value?: string }[] = [];
  totalVariables = 0;
  totalFiltered = 0;
  allVariables: DeviceVariable[] = [];

  filters$ = this.navigationApi.getDeviceVariablesTableFilters$;
  limit$: BehaviorSubject<number> = new BehaviorSubject<number>(10);

  userPermissions$: BehaviorSubject<{ key: string; value: boolean }[]> = new BehaviorSubject<
    {
      key: string;
      value: boolean;
    }[]
  >([
    {
      key: 'canUpdateDevice',
      value: this.authz.applyAuthorization(AuthorizationConcept.DEVICE, AuthorizationType.UPDATE)
    }
  ]);
  subs: Subscription[] = [];

  constructor(
    private readonly userPrefService: UserPreferencesService,
    public readonly navigationApi: NavigationApi,
    private readonly dialog: MatDialog,
    private readonly authz: AuthorizationService,
    private readonly devicesFacade: DevicesFacade,
    private readonly translateService: TranslateService
  ) {
    this.subs.push(
      this.device$.subscribe((device) => {
        if (!!device.id) {
          this.userPermissions$.next([
            ...this.userPermissions$.getValue(),
            {
              key: 'canResetLastValue',
              value: device.status?.name !== 'decommissioned'
            }
          ]);
          this.navigationApi.loadVariablesByDeviceId(device.id);
        }
      })
    );
  }

  ngOnInit() {
    const deviceVariables$ = this.navigationApi.deviceVariables$.pipe(
      map((deviceVariables) => {
        this.totalVariables = deviceVariables.length;
        this.variableGroups = this.getDeviceVariableGroup(deviceVariables);
        const deviceVariablesWithFileInfo = this.addFileInfoToDeviceVariables(deviceVariables);
        this.variableFiles = this.getDeviceVariableFiles(deviceVariablesWithFileInfo);
        return deviceVariablesWithFileInfo;
      })
    );

    const applyFilter$ = combineLatest([deviceVariables$, this.filters$, this.limit$]);

    this.subs.push(
      applyFilter$.subscribe(([deviceVariablesWithFileInfo, filters]) => {
        this.allVariables = deviceVariablesWithFileInfo;
        const filteredVars = this.filterVariables(deviceVariablesWithFileInfo, filters);
        this.totalFiltered = filteredVars.length;
        const response: PlatformResponse = {
          currentPage: 0,
          data: filteredVars,
          hasMore: false,
          limit: 3000,
          maxPage: 0,
          total: filteredVars.length
        };
        this.variables$.next(response);
      })
    );
  }

  addFileInfoToDeviceVariables(deviceVariables: DeviceVariable[]): DeviceVariable[] {
    return deviceVariables.map((deviceVariable) => {
      let name = deviceVariable.name;
      let file = '';

      if (deviceVariable.name?.includes('[FILE]')) {
        name = deviceVariable.name.substring(deviceVariable.name.indexOf('/') + 1);
        file = deviceVariable.name.substring(deviceVariable.name.indexOf(']') + 1, deviceVariable.name.indexOf('/'));
      }
      return {
        ...deviceVariable,
        name,
        file,
        value: deviceVariable.lastValue?.value ?? null,
        time: deviceVariable.lastValue?.datetime ?? null
      };
    });
  }

  getDeviceVariableGroup(deviceVariables: DeviceVariable[]): { key: string; value?: string }[] {
    return deviceVariables.reduce(
      (acc: { key: string; value?: string }[], dVar: DeviceVariable) => {
        if (dVar.group) {
          const dVarGroup: { key: string; value: string } = { key: dVar.group, value: dVar.group };

          if (!acc.find((el) => el.key === dVarGroup.key)) {
            acc.push(dVarGroup);
          }
        }
        return acc;
      },
      [
        { key: 'DEVICES.INFO_TOOLBAR.ALL_GROUPS', value: undefined },
        { key: 'DEVICES.INFO_TOOLBAR.NO_GROUP', value: '' }
      ]
    );
  }

  getDeviceVariableFiles(deviceVariables: DeviceVariable[]): { key: string; value?: string }[] {
    return deviceVariables.reduce(
      (acc: { key: string; value?: string }[], dVar) => {
        if (dVar.file) {
          const dVarFile: { key: string; value: string } = { key: dVar.file, value: dVar.file };

          if (!acc.find((el) => el.key === dVarFile.key)) {
            acc.push(dVarFile);
          }
        }
        return acc;
      },
      [
        { key: 'DEVICES.INFO_TOOLBAR.ALL_FILES', value: undefined },
        { key: 'DEVICES.INFO_TOOLBAR.NO_FILE', value: '' }
      ]
    );
  }

  filterVariables(variables: DeviceVariable[], filters: Partial<VariablesTableFilters>): DeviceVariable[] {
    return variables
      .filter((variable: DeviceVariable) =>
        variable['name'] && filters['name'] ? variable.name.toLowerCase().includes(filters['name'].toLowerCase()) : !(!variable['name'] && filters['name'])
      )
      .filter((variable: DeviceVariable) => (filters['linked'] === true || filters['linked'] === false ? variable['linked'] === filters['linked'] : true))
      .filter((variable: DeviceVariable) =>
        variable['comment'] && filters['description']
          ? variable['comment'].toLowerCase().includes(filters['description'].toLowerCase())
          : !(!variable['comment'] && filters['description'])
      )
      .filter((variable: DeviceVariable) => {
        if (variable['group'] && filters['group'] && filters['group'].value) {
          return variable['group'].toUpperCase() === filters['group'].value.toUpperCase();
        } else if (filters['group']?.value === '') {
          return !variable['group'];
        } else {
          return !(!variable['group'] && filters['group']);
        }
      })
      .filter((variable: DeviceVariable) => {
        if (variable['file'] && filters['file'] && filters['file'].value) {
          return variable['file'].toUpperCase() === filters['file'].value.toUpperCase();
        } else if (filters['file']?.value === '') {
          return !variable['file'];
        } else {
          return !(!variable['file'] && filters['file']);
        }
      });
  }

  onMasterViewEngineEvent(event: MasterViewEngineEvent) {
    switch (event.type) {
      case 'openChart':
        this.openChart(event.rawData);
        break;
      case 'openChartByFile':
        this.openFileOrGroupCharts(event.rawData, 'file');
        break;
      case 'openChartByGroup':
        this.openFileOrGroupCharts(event.rawData, 'group');
        break;
      case 'delete':
        this.analytic.log('tab_variables_actions', 'open_single_delete_variable');
        this.deleteDeviceVariables([event.rawData]);
        break;
      case 'deleteBulk':
        this.analytic.log('tab_variables_actions', 'open_bulk_delete_variable');
        this.deleteDeviceVariables(event.rawData);
        break;
      case 'resetLastValue':
        this.analytic.log('tab_variables_actions', 'open_single_reset_last_value');
        this.resetDeviceVariablesLastValues([event.rawData]);
        break;
      case 'resetLastValueBulk':
        this.analytic.log('tab_variables_actions', 'open_bulk_reset_last_value');
        this.resetDeviceVariablesLastValues(event.rawData);
        break;
      default:
        break;
    }
  }

  resetDeviceVariablesLastValues(deviceVariables: DeviceVariable[]): void {
    const dialogRef = this.dialog.open(PopupComponent, {
      width: '500px',
      data: {
        type: 'reset',
        value:
          deviceVariables.length > 1
            ? this.translateService.instant('DEVICES.VARIABLES_TAB.RESET_LAST_VALUE_CONFIRMATION_MESSAGE', { total: deviceVariables.length })
            : deviceVariables[0].name
      },
      disableClose: true
    });
    this.subs.push(
      dialogRef.afterClosed().subscribe((validation: boolean) => {
        if (validation) {
          this.analytic.log('tab_variables_actions', 'reset_last_value', `${deviceVariables.length} variables`);
          this.devicesFacade.resetDeviceVariablesLastValues(deviceVariables);
        }
      })
    );
  }

  openChart(deviceVariable: DeviceVariable) {
    this.analytic.log('tab_variables_actions', 'open_chart');
    this.dialog.open(VariableChartDialogComponent, {
      width: '990px',
      data: {
        variables: [deviceVariable],
        variableType: 'deviceVariable'
      }
    });
  }

  openFileOrGroupCharts(deviceVariable: DeviceVariable, attribute: string) {
    this.analytic.log('tab_variables_actions', `open_${attribute}`);
    let testAgainst: string | undefined;
    let filteredVariables: DeviceVariable[] = [];

    if (attribute === 'file') {
      testAgainst = deviceVariable.file;
      filteredVariables = this.allVariables.filter((v) => v.file === testAgainst);
    }

    if (attribute === 'group') {
      testAgainst = deviceVariable.group ?? undefined;
      filteredVariables = this.allVariables.filter((v) => v.group === testAgainst);
    }

    if (!!testAgainst) {
      this.dialog.open(VariableChartDialogComponent, {
        width: '990px',
        data: {
          variables: filteredVariables,
          variableType: 'deviceVariable',
          title: testAgainst
        }
      });
    }
  }

  deleteDeviceVariables(deviceVariables: DeviceVariable[]) {
    const dialogRef = this.dialog.open(PopupComponent, {
      width: '500px',
      disableClose: true,
      data: {
        type: 'delete',
        value:
          deviceVariables.length > 1
            ? this.translateService.instant('DEVICES.VARIABLES_TAB.DELETE_CONFIRMATION_MESSAGE', { total: deviceVariables.length })
            : deviceVariables[0].name
      }
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.analytic.log('tab_variables_actions', 'delete_variables', `${deviceVariables.length} variables`);
        this.navigationApi.deleteDeviceVariables(deviceVariables);
      }
    });
  }

  applyFilters(filters: Partial<VariablesTableFilters>) {
    this.analytic.log('tab_variables_actions', 'apply_filters');
    this.navigationApi.applyDeviceVariablesFilters(filters);
  }

  clearFilters() {
    this.analytic.log('tab_variables_actions', 'clear_filters');
    this.navigationApi.applyDeviceVariablesFilters({
      name: null,
      description: null,
      file: null,
      group: null,
      linked: null
    });
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
