import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AbstractTableEngineHeaderCellComponent } from '../abstract-table-engine-header-cell.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'iot-platform-ui-basic-header',
  templateUrl: './basic-header.component.html',
  styleUrls: ['./basic-header.component.scss'],
  imports: [TranslateModule]
})
export class BasicHeaderComponent extends AbstractTableEngineHeaderCellComponent<BasicHeaderComponent> {}
