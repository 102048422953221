<div class="map-panel-info" style="overflow: hidden">
  <button (click)="closeSidebar()" class="regular-round-button" mat-icon-button style="z-index: 999; position: absolute; top: 8px; right: 10px">
    <mat-icon>close</mat-icon>
  </button>
  @if (loading()) {
    <iot-platform-ui-card-loader [backgroundClass]="'mv-detail-card h-140'" style="padding: 20px"></iot-platform-ui-card-loader>
  } @else {
    <div class="map-panel-info-content mve-detail-popup-container">
      @switch (concept()) {
        @case ('site') {
          <div fxLayout="column" fxLayoutGap="8px">
            <iot-platform-ui-site-detail-basic-info
              (selectSite)="selectElement.emit({ element: $event, destination: 'site' })"
              [site]="site()"
              displayMode="vertical"
            ></iot-platform-ui-site-detail-basic-info>
            <iot-platform-ui-site-detail-tags [loaded]="!loading()" [tags]="tags()"></iot-platform-ui-site-detail-tags>
            <iot-platform-ui-site-detail-contacts [canReadContacts]="canReadContacts()" [contacts]="contacts()"></iot-platform-ui-site-detail-contacts>
            <iot-platform-ui-site-detail-assets
              (selectAsset)="selectElement.emit({ element: $event, destination: 'asset' })"
              [assets]="assetsBySite()"
              [isAssetLoaded]="true"
              [totalAssets]="assetsBySite?.()?.length"
            ></iot-platform-ui-site-detail-assets>
            <iot-platform-ui-site-detail-devices
              (selectDevice)="selectElement.emit({ element: $event, destination: 'device' })"
              [devices]="devicesBySite()"
              [isDeviceLoaded]="true"
              [totalDevices]="devicesBySite?.()?.length"
            ></iot-platform-ui-site-detail-devices>
          </div>
        }
        @case ('asset') {
          <div fxLayoutGap="8px">
            <iot4bos-ui-asset-basic-info
              (selectAsset)="selectElement.emit({ element: $event, destination: 'asset' })"
              [asset]="asset()"
              displayMode="vertical"
            ></iot4bos-ui-asset-basic-info>
            @if (asset()?.site?.type === 'mobile_fleet') {
              <iot-platform-maps-map-panel-info-toolbox (loadRoutes)="loadRoutes($event)" [asset]="asset()"></iot-platform-maps-map-panel-info-toolbox>
            }
            @if (asset()?.site?.type === 'mobile_fleet') {
              <iot-platform-maps-map-panel-info-popup
                (displaySegments)="displaySegments.emit($event)"
                style="height: 300px; max-height: 300px"
              ></iot-platform-maps-map-panel-info-popup>
            }
            <iot-platform-ui-site-detail-tags [loaded]="true" [tags]="tags()"></iot-platform-ui-site-detail-tags>
            <iot-platform-ui-site-details-full-info
              (selectSite)="selectElement.emit({ element: $event, destination: 'site' })"
              [isSiteLoaded]="true"
              [site]="complementarySite()"
            ></iot-platform-ui-site-details-full-info>
            <iot4bos-ui-asset-followed-variables-details [asset]="asset()"></iot4bos-ui-asset-followed-variables-details>
          </div>
        }
        @case ('device') {
          <div fxLayoutGap="8px">
            <iot-platform-ui-device-details-basic-info
              (selectDevice)="selectElement.emit({ element: $event, destination: 'device' })"
              [device]="device()"
              displayMode="vertical"
            ></iot-platform-ui-device-details-basic-info>
            <iot-platform-ui-site-detail-tags [loaded]="!loading()" [tags]="tags()"></iot-platform-ui-site-detail-tags>
            <iot-platform-ui-site-details-full-info
              (selectSite)="selectElement.emit({ element: $event, destination: 'site' })"
              [isSiteLoaded]="!loading()"
              [site]="complementarySite()"
            ></iot-platform-ui-site-details-full-info>
            <iot-platform-ui-device-details-connectors
              [device]="device()"
              fxLayoutGap="10px"
              style="padding-top: 10px"
            ></iot-platform-ui-device-details-connectors>
          </div>
        }
        @default {
          <div>
            <p>click on the map</p>
          </div>
        }
      }
    </div>
  }
</div>
