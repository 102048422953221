import { Injectable } from '@angular/core';
import { ApiFeatureSelector, FavoriteView, Filter, Pagination } from '@iot-platform/models/common';
import * as fromBusinessProfilesFavoriteViews from '../reducers/business-profiles-favorite-views.reducer';

@Injectable({
  providedIn: 'root'
})
export class AdminBusinessProfilesFavoriteViewsSelectors extends ApiFeatureSelector<fromBusinessProfilesFavoriteViews.State, FavoriteView, Pagination, Filter> {
  constructor() {
    super(
      fromBusinessProfilesFavoriteViews.adminBusinessProfilesFavoriteViewsFeature.selectAdminBusinessProfilesFavoriteViewsState,
      fromBusinessProfilesFavoriteViews.adapter
    );
  }
}
