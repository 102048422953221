import { Component, input, output } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    imports: [TranslateModule, FlexLayoutModule, MatIcon, MatButtonModule, MatTooltip],
    selector: 'iot-platform-ui-panel',
    templateUrl: './panel.component.html',
    styleUrls: ['./panel.component.scss']
})
export class PanelComponent {
  title = input<string>();
  hasButton = input<boolean>(false);
  hasToolbar = input<boolean>(false);
  buttonTooltip = input<string | undefined>();
  withCustomAction = input<boolean>(false);
  icon = input<string>('add');

  clicked = output<void>();

  onClick() {
    this.clicked.emit();
  }
}
