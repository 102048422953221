import { Injectable, Signal, computed, signal } from '@angular/core';
import { AuthFacade, AuthorizationConcept, AuthorizationService, AuthorizationType } from '@iot-platform/auth';
import { GridsDbActions, fromGrids } from '@iot-platform/grid-engine';
import { CommonIndexedPagination, Filter, Pagination, PlatformRequest } from '@iot-platform/models/common';
import { Asset, Device, Event, Log, Site } from '@iot-platform/models/i4b';
import { FavoriteViewsActions, fromFavoriteViews } from '@iot-platform/shared/components';
import { Store } from '@ngrx/store';
import { noop } from 'rxjs';
import { I4BBaseFacade } from '../../../../models/ngrx/i4b-base-facade.model';
import { DeviceEventsActions, DeviceEventsCommentsActions } from '../actions';
import * as eventCommentsSelectors from '../selectors/device-events-comments.selectors';
import { DeviceEventsSelectors } from '../selectors/device-events.selectors';
import { NavigationApi } from '../../../../containers/+state/navigation.api';
import { LocalStorageKeys, LocalStorageService } from '@iot-platform/core';

@Injectable({
  providedIn: 'root'
})
export class DeviceEventsFacade extends I4BBaseFacade<Event, Pagination, Filter> {
  concept = 'device-events';
  grids$ = this.store.select(fromGrids.getDeviceEventsGrids);
  grids = this.store.selectSignal(fromGrids.getDeviceEventsGrids);
  grid$ = this.store.select(fromGrids.getDefaultDeviceEventsGrid);
  grid = this.store.selectSignal(fromGrids.getDefaultDeviceEventsGrid);
  gridConfiguration$ = this.store.select(fromGrids.selectDeviceEventGridsConfiguration);
  gridConfiguration = this.store.selectSignal(fromGrids.selectDeviceEventGridsConfiguration);

  favoriteViews$ = this.store.select(fromFavoriteViews.getFavoriteViewsForMasterViewDeviceEvents);
  favoriteViews = this.store.selectSignal(fromFavoriteViews.getFavoriteViewsForMasterViewDeviceEvents);
  currentFavoriteView$ = this.store.select(fromFavoriteViews.getSelectedFavoriteViewForMasterViewDeviceEvents);
  currentFavoriteView = this.store.selectSignal(fromFavoriteViews.getSelectedFavoriteViewForMasterViewDeviceEvents);
  favoriteViewConfiguration$ = this.store.select(fromFavoriteViews.selectDeviceEventFavoriteViewsConfiguration);
  favoriteViewConfiguration = this.store.selectSignal(fromFavoriteViews.selectDeviceEventFavoriteViewsConfiguration);
  filters = this.store.selectSignal(fromFavoriteViews.getFiltersForMasterViewDeviceEvents);
  filters$ = this.store.select(fromFavoriteViews.getFiltersForMasterViewDeviceEvents);
  eventComments: Signal<Log[]> = this.store.selectSignal(eventCommentsSelectors.selectAllEventComments);
  eventCommentsLoading: Signal<boolean> = this.store.selectSignal(eventCommentsSelectors.selectEventCommentsLoading);

  canCreate = signal(false);

  canRead: Signal<boolean> = computed(() => {
    this.authFacade.privileges();
    return this.authorizationService.applyAuthorization(AuthorizationConcept.EVENT, AuthorizationType.READ);
  });

  canUpdate: Signal<boolean> = computed(() => {
    this.authFacade.privileges();
    return this.authorizationService.applyAuthorization(AuthorizationConcept.EVENT, AuthorizationType.UPDATE);
  });

  canDelete = signal(false);

  canReadSite: Signal<boolean> = computed(() => {
    this.authFacade.privileges();
    return this.authorizationService.applyAuthorization(AuthorizationConcept.SITE, AuthorizationType.READ);
  });

  canReadDevice: Signal<boolean> = computed(() => {
    this.authFacade.privileges();
    return this.authorizationService.applyAuthorization(AuthorizationConcept.DEVICE, AuthorizationType.READ);
  });

  constructor(
    protected override store: Store,
    protected override selector: DeviceEventsSelectors,
    protected readonly authFacade: AuthFacade,
    protected readonly navigationApi: NavigationApi,
    protected readonly storage: LocalStorageService,
    protected readonly authorizationService: AuthorizationService
  ) {
    super(store, selector);
  }

  getAll(): void {
    noop();
  }

  setFilters(filters: Filter[]): void {
    this.store.dispatch(FavoriteViewsActions.setCurrentFilters({ masterView: 'device-events', filters }));
  }

  updateEventStatus(eventId: string, status: string) {
    this.store.dispatch(DeviceEventsActions.updateDeviceEventStatus({ eventId, status }));
  }

  bulkUpdateEventsStatus(eventsIds: string[], status: string) {
    this.store.dispatch(DeviceEventsActions.bulkUpdateDeviceEventsStatus({ eventsIds, status }));
  }

  loadComments(event: Event) {
    this.store.dispatch(DeviceEventsCommentsActions.loadComments({ event }));
  }

  addComment(event: Event, comment: string) {
    this.store.dispatch(DeviceEventsCommentsActions.addComment({ event, comment }));
  }

  editComment(eventId: string, comment: Log): void {
    this.store.dispatch(DeviceEventsCommentsActions.editComment({ eventId, comment }));
  }

  deleteComment(event: Event, commentId: string): void {
    this.store.dispatch(DeviceEventsCommentsActions.deleteComment({ event, commentId }));
  }

  navigateToSite(site: Site): void {
    this.navigationApi.selectLeSite(site);
  }

  navigateToStock(stock: Site, request: PlatformRequest): void {
    this.navigationApi.selectLeStock(stock, request);
  }

  navigateToAsset(asset: Asset): void {
    this.navigationApi.selectAssetAvecLeSite(asset);
  }

  navigateToDevice(device: Device): void {
    this.navigationApi.selectDeviceAvecLeSite(device);
  }

  storeSelectedRowAndOrigin(rowId: string): void {
    this.storage.set(LocalStorageKeys.STORAGE_MV_ORIGIN_KEY, 'device-event');
    this.storage.set(LocalStorageKeys.STORAGE_SELECTED_ROW_ID, rowId);
  }

  selectEventInCurrentGrid(item: Event): void {
    this.store.dispatch(GridsDbActions.selectItemInGridData({ gridId: this.grid()?.id as string, itemId: item.id }));
  }

  reloadGrid(): void {
    const grid = this.store.selectSignal(fromGrids.getSelectedGrid)();

    if (grid) {
      this.store.dispatch(
        GridsDbActions.loadGridData({
          request: {
            filters: grid?.gridOptions?.filters,
            limit: grid?.data?.response?.pagination?.limit,
            concept: grid?.masterview.toLowerCase(),
            page: (grid?.data?.response?.pagination as CommonIndexedPagination)?.currentPage,
            variables: grid?.gridOptions.variableNames,
            tags: grid?.gridOptions.tagIds,
            endPoint: grid?.gridOptions.endPoint
          }
        })
      );
    }
  }

  updateEventInAllGrids(item: Event): void {
    this.store.dispatch(GridsDbActions.updateItemInAllGridsData({ updatedItem: item, concept: 'device-events' }));
  }
}
